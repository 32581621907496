import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { exceptionsSelectors, exceptionsOperations } from '../../../../state/exceptions';
import AlternativeLoadMore from './AlternativeLoadMore';
import AlterSearchResultsInlineLoader from './AlternativeSearchResultsInlineLoader';

class AlternativeSearchResultsSection extends Component {
  onShowMore = () => {
    const { isLoading, hasMore, loadMore } = this.props;
    if (!isLoading && hasMore) {
      loadMore();
    }
  };

  render() {
    const { isLoading, hasMore, children } = this.props;
    const showLoadMore = hasMore && !isLoading;

    return (
      <div className="AlternativeSearchResults__Section">
        {children}
        <AlternativeLoadMore visible={showLoadMore} onClick={this.onShowMore} />
        <AlterSearchResultsInlineLoader visible={isLoading} />
      </div>
    );
  }
}

const mapStateToProps = (state, { type }) => ({
  hasMore: exceptionsSelectors.getHasMore(type)(state),
  isLoading: exceptionsSelectors.getIsLoading(type)(state)
});

const mapDispatchToProps = (dispatch, { type }) => ({
  loadMore: () => dispatch(exceptionsOperations.loadMoreAlternatives(type))
});

AlternativeSearchResultsSection.propTypes = {
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  loadMore: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AlternativeSearchResultsSection);
