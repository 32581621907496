import config from '../config';
import fetch from './fetch';

const getUrl = (path, id) => {
  const url = `${config.remoteUrl}/${path}`;
  return id ? `${url}/${id}` : url;
};

async function post(bookRequest) {
  const url = getUrl('bookings');
  return fetch(url, 'POST', bookRequest);
}

async function put(bookRequest) {
  const url = getUrl('bookings', bookRequest.globalId);
  return fetch(url, 'PUT', bookRequest);
}

export default { post, put };
