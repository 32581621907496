import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon, Translate } from '../../../../common';

import './_style.css';

function SearchRecurrence({ recurrence, disabled }) {
  const classes = cx('SearchRecurrence', {
    'SearchRecurrence--deactivated': disabled
  });

  return (
    <div className={classes}>
      <div className="SearchRecurrence__Icon">
        <Icon icon="007-backup" />
      </div>
      <div className="SearchRecurrence_Text">
        <div className="SearchRecurrence_Title">
          <Translate path="search.when.recurrence.title" />
        </div>
        <div>
          <div>{recurrence}</div>
        </div>
      </div>
      <div className="SearchRecurrence__RedirectIcon">
        <Icon icon="015-chevron-right" />
      </div>
    </div>
  );
}

SearchRecurrence.propTypes = {
  recurrence: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default SearchRecurrence;
