import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
  changeRequestDetailSelectors,
  changeRequestDetailOperations
} from '../../../state/changeRequestDetail';
import Header from '../../Header';
import CreateResourceDetail from '../CreateResource/CreateResourceDetail';
import MasterDataDetail from '../EditResource/MasterDataForm/MasterDataDetail';
import OwnerDetail from '../EditResource/OwnerForm/OwnerDetail';
import PermissionsDetail from '../EditResource/PermissionsForm/PermissionsDetail';
import DeleteDetail from '../EditResource/DeleteForm/DeleteDetail';
import ResourceDetail from '../EditResource/ResourceDetail';
import ChangeRequestRejectModal from './ChangeRequestRejectModal';
import { FormFieldReadOnly } from '../FormElements';
import { Loader, Button, withTranslation } from '../../common';
import './_style.css';
import { buildingsSelectors } from '../../../state/buildings';

const ChangeRequestTypes = {
  NEW_RESOURCE: 'NewResource',
  DELETE: 'Delete',
  PROFILE: 'Profile',
  MASTERDATA: 'Masterdata',
  PERMISSIONS: 'Permissions'
};

class ChangeRequestDetail extends Component {
  UNSAFE_componentWillMount() {
    const { loadChangeRequest, match } = this.props;
    const { changeRequestId } = match.params;
    loadChangeRequest(changeRequestId);
  }

  close = () => {
    const { history } = this.props;
    history.replace('/changerequests/my');
  };

  renderLoader = () => {
    const { isLoading } = this.props;
    if (!isLoading) {
      return null;
    }
    return (
      <div className="ChangeRequestDetail__Loader">
        <Loader visible />
      </div>
    );
  };

  renderCreateResourceDetail = () => {
    const { changeRequest, isSubmitted } = this.props;
    const { changeRequestType } = changeRequest;
    if (changeRequestType !== ChangeRequestTypes.NEW_RESOURCE) {
      return null;
    }

    const props = {
      changeRequest,
      isSubmitted,
      isDetailView: true
    };

    return <CreateResourceDetail {...props} />;
  };

  renderChangeOwnerDetail = () => {
    const { changeRequest, isSubmitted } = this.props;
    const { changeRequestType } = changeRequest;
    if (changeRequestType !== ChangeRequestTypes.PROFILE) {
      return null;
    }
    return (
      <div className="ChangeRequestDetail__Form">
        <OwnerDetail changeRequest={changeRequest} isSubmitted={isSubmitted} />
      </div>
    );
  };

  renderPermissionsDetail = () => {
    const { changeRequest, isSubmitted, isSubmitting } = this.props;
    const { changeRequestType } = changeRequest;
    if (changeRequestType !== ChangeRequestTypes.PERMISSIONS) {
      return null;
    }
    return (
      <div className="ChangeRequestDetail__Form">
        <PermissionsDetail
          changeRequest={changeRequest}
          isSubmitted={isSubmitted}
          isSubmitting={isSubmitting}
        />
      </div>
    );
  };

  renderDeleteDetail = () => {
    const { changeRequest, isSubmitted } = this.props;
    const { changeRequestType, readOnly } = changeRequest;
    if (changeRequestType !== ChangeRequestTypes.DELETE) {
      return null;
    }

    const props = {
      readOnly,
      changeRequest,
      isSubmitted
    };

    return (
      <div className="ChangeRequestDetail__Form">
        <DeleteDetail {...props} />
      </div>
    );
  };

  renderResourceDetail = () => {
    const { buildings, changeRequest } = this.props;
    const { resource, isResourceDeleted, obsoleteResource } = changeRequest;

    const defined = isResourceDeleted ? obsoleteResource != null : resource != null;
    if (!defined) {
      return null;
    }

    const resourceWithBuilding = isResourceDeleted
      ? obsoleteResource
      : { ...resource, building: buildings.find(building => building.id === resource.buildingId) };

    return (
      <>
        <ResourceDetail resource={resourceWithBuilding} obsolete={isResourceDeleted} />
        <hr className="EditResource__Divider" />
      </>
    );
  };

  renderMasterDataDetail = () => {
    const { changeRequest, isSubmitted, isSubmitting } = this.props;
    const { changeRequestType, readOnly } = changeRequest;
    if (changeRequestType !== ChangeRequestTypes.MASTERDATA) {
      return null;
    }

    const props = {
      readOnly,
      changeRequest,
      isSubmitted,
      isSubmitting
    };

    return (
      <div className="ChangeRequestDetail__Form">
        <MasterDataDetail {...props} />
      </div>
    );
  };

  renderRequester = () => {
    const { changeRequest } = this.props;
    const { requester } = changeRequest;
    if (!requester) {
      return null;
    }
    return (
      <FormFieldReadOnly
        field="requester"
        values={{ new: null, old: null }}
        text={requester.displayName}
      />
    );
  };

  renderAssignee = () => {
    const { changeRequest } = this.props;
    const { assignee } = changeRequest;
    if (!assignee) {
      return null;
    }
    return (
      <FormFieldReadOnly
        field="assignee"
        values={{ new: null, old: null }}
        text={assignee.displayName}
      />
    );
  };

  renderRejectionReason = () => {
    const { changeRequest } = this.props;
    const { status, rejectionReason } = changeRequest;
    if (status !== 'Rejected' || !rejectionReason) {
      return null;
    }

    return (
      <FormFieldReadOnly
        field="rejectionReason"
        values={{ new: null, old: null }}
        text={rejectionReason}
      />
    );
  };

  renderStatus = () => {
    const { changeRequest } = this.props;
    const { statusDisplayName } = changeRequest;
    if (!statusDisplayName) {
      return null;
    }
    return (
      <FormFieldReadOnly
        field="status"
        values={{ new: null, old: null }}
        text={statusDisplayName}
      />
    );
  };

  renderActions = () => {
    const {
      changeRequest,
      discard,
      complete,
      approve,
      reject,
      isSubmitting,
      translate
    } = this.props;
    const { readOnly, status, changeRequestType, isResourceDeleted } = changeRequest;

    const isReadonly = readOnly || status === 'Approved' || status === 'Rejected';
    if (isReadonly || isResourceDeleted) {
      return null;
    }

    const call = (action, callback = this.close) => () => {
      action().then(result => {
        if (result && callback) {
          callback();
        }
      });
    };

    const approveLabel = translate('common.approve');
    const rejectLabel = translate('common.reject');
    const discardLabel = translate('common.discard');
    const completeLabel = translate('common.complete');

    const classes = cx(
      'ChangeRequestDetail__Form',
      `ChangeRequestDetail__Form--${changeRequestType}`
    );

    return (
      <div className={classes}>
        <div className="ChangeRequestDetail__Actions">
          {status === 'New' && (
            <>
              <Button label={rejectLabel} disabled={isSubmitting} onClick={reject} />
              <Button label={approveLabel} disabled={isSubmitting} onClick={call(approve)} />
            </>
          )}

          {(status === 'Failed' || status === 'InProgress') && (
            <>
              <Button
                secondary
                label={discardLabel}
                disabled={isSubmitting}
                onClick={call(discard)}
              />
              <Button label={completeLabel} disabled={isSubmitting} onClick={call(complete)} />
            </>
          )}
        </div>
      </div>
    );
  };

  renderDetailViews = () => {
    const { changeRequest } = this.props;
    const { isResourceDeleted } = changeRequest;
    if (isResourceDeleted) {
      return this.renderResourceDetail();
    }
    return (
      <>
        {this.renderResourceDetail()}
        {this.renderCreateResourceDetail()}
        {this.renderChangeOwnerDetail()}
        {this.renderDeleteDetail()}
        {this.renderMasterDataDetail()}
        {this.renderPermissionsDetail()}
      </>
    );
  };

  renderRequestDetails = () => {
    const { changeRequest } = this.props;
    const { isResourceDeleted } = changeRequest;
    if (isResourceDeleted) {
      return null;
    }
    return (
      <>
        <hr className="EditResource__Divider" />
        <div className="ChangeRequestDetail__Form">
          {this.renderStatus()}
          {this.renderRequester()}
          {this.renderAssignee()}
          {this.renderRejectionReason()}
        </div>
      </>
    );
  };

  render() {
    const { changeRequest, isLoading, translate } = this.props;
    const loaded = !isLoading && changeRequest != null;
    let title = translate('changeRequests.title');

    if (!loaded) {
      return (
        <>
          <Header onClose={this.close} title={title} />
          {this.renderLoader()}
        </>
      );
    }

    const { changeRequestTypeDisplayName } = changeRequest;
    title = `${title} - ${changeRequestTypeDisplayName}`;

    return (
      <>
        <div className="ChangeRequestDetail">
          <Header onClose={this.close} title={title} />
          {this.renderLoader()}
          {this.renderDetailViews()}
          {this.renderRequestDetails()}
          {this.renderActions()}
        </div>
        <ChangeRequestRejectModal />
      </>
    );
  }
}

ChangeRequestDetail.propTypes = {
  changeRequest: PropTypes.shape(),
  buildings: PropTypes.shape().isRequired,
  loadChangeRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  discard: PropTypes.func.isRequired,
  complete: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      changeRequestId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  changeRequest: changeRequestDetailSelectors.getChangeRequest(state),
  buildings: buildingsSelectors.getBuildings(state),
  isLoading: changeRequestDetailSelectors.isLoading(state),
  isSubmitted: changeRequestDetailSelectors.isSubmitted(state),
  isSubmitting: changeRequestDetailSelectors.isSubmitting(state)
});

const mapDispatchToProps = dispatch => ({
  loadChangeRequest: id => dispatch(changeRequestDetailOperations.loadChangeRequest(id)),
  approve: () => dispatch(changeRequestDetailOperations.approve()),
  reject: () => dispatch(changeRequestDetailOperations.reject()),
  discard: () => dispatch(changeRequestDetailOperations.discard()),
  complete: () => dispatch(changeRequestDetailOperations.complete())
});

const ChangeRequestDetailComponent = withTranslation(withRouter(ChangeRequestDetail));
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeRequestDetailComponent);
