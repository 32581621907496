import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Icon } from '../../../../common';

import styles from './RecurrenceFilterEmptyState.module.css';

function RecurrenceFilterEmptyState({ translate }) {
  const text = translate('search.when.wrongTime');
  return (
    <div className={styles.container}>
      <Icon className={styles.icon} icon="232-robot-neutral" />
      {text}
    </div>
  );
}

export default withTranslation(RecurrenceFilterEmptyState);

RecurrenceFilterEmptyState.propTypes = {
  translate: PropTypes.func.isRequired
};
