import { createSelector } from 'reselect';
import { locationsSelectors } from '../locations';
import { buildingsSelectors } from '../buildings';

const getAvailability = state => state.availabilityState.availability.availability;

const hasMore = state => state.availabilityState.availability.hasMore;
const isLoading = state => state.availabilityState.availability.isLoading;
const getSkipCount = state => state.availabilityState.availability.skipCount;
const getTotalCount = state => state.availabilityState.availability.totalCount;
const isRecurrence = state => state.availabilityState.availability.isRecurrence;
const getLocations = state => locationsSelectors.getLocationsByType(state);
const getBuildings = state => buildingsSelectors.getBuildings(state);

const getAvailabilityCount = createSelector(
  getAvailability,
  availability => availability.length
);

const getAvailabilities = createSelector(
  [
    getAvailability,
    getBuildings,
    isRecurrence,
    getLocations
  ],
  (availableRooms, buildings, isRecurrenceSearch, locations) => {
    const mapResource = resource => ({
      ...resource,
      building: buildings.find(building => building.id === resource.buildingId)
    });

    const mappedAvailableRooms = availableRooms.map(a => {
      const showJournal = locations.resourceIds.includes(a.resource.id);
      const resource = {
        ...mapResource(a.resource),
        availabilities: isRecurrenceSearch ? a.availableOccurrences : a.availabilities,
        exceptions: isRecurrenceSearch ? a.nonAvailableOccurrences : null,
        numberOfAvailabilities: isRecurrenceSearch ? a.numberOfAvailabilities : null,
        numberOfNonAvailabilities: isRecurrenceSearch ? a.numberOfNonAvailabilities : null,
        recurring: isRecurrenceSearch,
        showJournal,
        bookings: a.bookings
      };

      return resource;
    });

    return mappedAvailableRooms.filter(({ building }) => building != null);
  }
);

const hasAvailabilities = createSelector(
  getAvailabilities,
  availabilities => availabilities.length > 0
);

const showEmptyState = createSelector(
  [isLoading, getAvailabilityCount],
  (loading, count) => count === 0 && !loading
);

const hasMoreResults = createSelector(
  [hasMore, getAvailabilityCount],
  (moreResults, count) => count > 0 && moreResults
);

export default {
  hasAvailabilities,
  getAvailabilities,
  isLoading,
  getSkipCount,
  getTotalCount,
  showEmptyState,
  hasMoreResults,
  isRecurrence
};
