import React from 'react';
import PropTypes from 'prop-types';

import { ChangeDetector, Input, withTranslation } from '../../common';

function FormFieldNumber({ values, update, field, isSubmitted, max, detectChanges, translate }) {
  const parse = input => parseInt(input, 10);
  const updateNumber = event => {
    const { value } = event.target;
    if (value === '') {
      update(field, value);
      return;
    }

    if (!Number.isNaN(value)) {
      update(field, parse(value));
    }
  };

  const label = translate(`changeRequests.fieldNames.${field}`);

  const value = values.new;
  const hasCorrectValue = value && value !== '' && parse(value) <= max;
  const invalid = isSubmitted && !hasCorrectValue;
  const error = invalid ? translate('changeRequests.validation.number', { max }) : '';

  return (
    <div className="ChangeRequestFormField">
      <ChangeDetector values={values} invalid={invalid} detectChanges={detectChanges}>
        <Input label={label} onChange={updateNumber} value={values.new} error={error} />
      </ChangeDetector>
    </div>
  );
}

FormFieldNumber.propTypes = {
  values: PropTypes.shape({
    old: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    new: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  field: PropTypes.string.isRequired,
  max: PropTypes.number,
  detectChanges: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  update: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

FormFieldNumber.defaultProps = {
  max: Number.MAX_SAFE_INTEGER,
  detectChanges: true
};

export default withTranslation(FormFieldNumber);
