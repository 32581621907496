import types from './types';

const initialState = {
  modalHistory: [],
  offset: 0,
  fakeModalActive: false
};

const modalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PUSH_MODAL: {
      const modalHistory = [...state.modalHistory, action.modalKey];
      return {
        ...state,
        modalHistory
      };
    }

    case types.POP_MODAL: {
      return {
        ...state,
        modalHistory: state.modalHistory.slice(0, -1)
      };
    }

    case types.CLEAR_MODALS: {
      return {
        ...state,
        modalHistory: []
      };
    }

    case types.REMOVE_MODAL: {
      const { modalHistory } = state;
      const { modalKey } = action;
      return {
        ...state,
        modalHistory: modalHistory.filter(key => key !== modalKey)
      };
    }

    case types.SET_OFFSET: {
      return {
        ...state,
        offset: action.offset
      };
    }

    case types.SET_FAKE_MODAL_STATE: {
      return {
        ...state,
        fakeModalActive: action.fakeModalActive
      };
    }

    default:
      return state;
  }
};

export default modalsReducer;
