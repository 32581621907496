import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './_style.css';

function SelectBox({ children, selected, className, onClick }) {
  const classes = cx('SelectBox', className, {
    'SelectBox--selected': selected
  });

  return (
    <div
      className={classes}
      role="checkbox"
      aria-checked={selected}
      tabIndex="0"
      style={{ outline: 'none', userSelect: 'none' }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

SelectBox.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string
};

SelectBox.defaultProps = {
  selected: false
};

export default SelectBox;
