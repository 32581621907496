// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WelcomeIntro_dialog__qB2-N {\n  line-height: 21px;\n  margin-left: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/views/Welcome/WelcomeIntro.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".dialog {\n  line-height: 21px;\n  margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "WelcomeIntro_dialog__qB2-N"
};
export default ___CSS_LOADER_EXPORT___;
