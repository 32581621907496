import store from 'store2';

import types from './types';

const getCompactMode = () => store('CalendarCompactMode') || false;
const toggleCompactMode = () => store('CalendarCompactMode', !getCompactMode());

const initialState = {
  compactMode: getCompactMode(),
  dirty: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_COMPACT_MODE: {
      toggleCompactMode();
      const compactMode = getCompactMode();
      return { ...state, compactMode };
    }

    case types.SET_DIRTY: {
      return { ...state, dirty: true };
    }

    default:
      return state;
  }
};

export default reducer;
