import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import { regionsSelectors, regionsOperations } from '../../state/regions';
import { Loader, withTranslation } from '../common';

import './_style.css';

class StartupLoader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: false,
      isFading: false
    };

    this.startFading = this.startFading.bind(this);
  }

  componentDidMount() {
    const { isLoaded, loadRegions } = this.props;
    const { hidden, isFading } = this.state;

    if (!isLoaded) {
      loadRegions();
    }

    if (!hidden && isLoaded && !isFading) {
      setTimeout(this.startFading, 100);
    }
    this.mounted = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isLoaded } = this.props;
    if (!isLoaded && nextProps.isLoaded) {
      this.startFading();
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  startFading() {
    this.setState({ isFading: true });
    setTimeout(() => {
      if (this.mounted) {
        this.setState({
          hidden: true,
          isFading: false
        });
      }
    }, 1500);
  }

  render() {
    const { translate } = this.props;
    const { hidden, isFading } = this.state;

    if (hidden) {
      return null;
    }

    const text = translate('startup.loaderText');

    const wrapperClasses = cx('StartupLoader__Wrapper', { 'StartupLoader--fadeout': isFading });

    return (
      <div className={wrapperClasses}>
        {!isFading && (
          <Loader
            size="large"
            visible
            className="StartupLoader"
            loaderClass="StartupLoader__Loader"
          >
            <div className="StartupLoader__Text">{text}</div>
          </Loader>
        )}
      </div>
    );
  }
}

StartupLoader.propTypes = {
  isLoaded: PropTypes.bool,
  loadRegions: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ isLoaded: regionsSelectors.isLoaded(state) });

const mapDispatchToProps = dispatch => ({
  loadRegions: () => dispatch(regionsOperations.updateRegions())
});

export default withTranslation(connect(mapStateToProps, mapDispatchToProps)(StartupLoader));
