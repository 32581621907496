import types from './types';

const initialState = {
  values: {
    owners: {
      old: [],
      new: []
    },
    reason: {
      old: '',
      new: ''
    }
  },
  ownerIsLoading: false,
  ownerSuggestions: [],
  submitted: false,
  isSubmitting: false
};

const formatPerson = person => person && {
  name: person.displayName,
  id: person.accountName,
  value: person.accountName,
  email: person.email || person.accountName
};

const changePermissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET: {
      return { ...initialState };
    }

    case types.INITIALIZE_DATA: {
      const { owners, reason } = action.changeRequest;
      const mappedOwners = owners.map(person => ({
        ...person,
        ...formatPerson(person)
      }));
      return {
        ...initialState,
        values: {
          owners: {
            old: [],
            new: mappedOwners
          },
          reason: { old: reason, new: reason }
        }
      };
    }

    case types.UPDATE_FORM_VALUE: {
      const newState = { ...state };
      newState.values[action.field] = {
        ...state.values[action.field],
        new: action.value
      };
      return newState;
    }

    case types.UPDATE_OWNER_ISLOADING: {
      return { ...state, ownerIsLoading: action.isLoading };
    }

    case types.UPDATE_OWNER_SUGGESTIONS: {
      return { ...state, ownerSuggestions: action.suggestions };
    }

    case types.UPDATE_SUBMITTED: {
      return { ...state, submitted: true };
    }

    case types.UPDATE_IS_SUBMITTING: {
      return { ...state, isSubmitting: action.isSubmitting };
    }

    default:
      return state;
  }
};

export default changePermissionsReducer;
