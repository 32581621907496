import debounce from 'debounce-promise';

import actions from '../actions';
import selectors from '../selectors';
import { peopleService } from '../../../services';

import { notificationsSelectors, notificationsOperations } from '../../notifications';
import { encodeSearchTerm } from '../../../util';

const { clearAttendees, setAttendeesSearchTerm } = actions;

const ATTENDEES_COUNT = 8;

const toggleLoader = isLoaderActive => dispatch => {
  dispatch(actions.toggleAttendeesLoader(isLoaderActive));
};

const updateSuggestedAttendees = attendees => dispatch => new Promise(resolve => {
  dispatch(actions.updateSuggestedAttendees(attendees.slice(0, ATTENDEES_COUNT)));
  resolve();
});

const updateSelectedAttendees = selectedAttendees => dispatch => {
  dispatch(actions.updateSelectedAttendees(selectedAttendees));
};

const debounceFetch = debounce(searchTerm => peopleService.get(searchTerm), 200);

const fetchAttendees = () => (dispatch, getState) => {
  const state = getState();

  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const searchTerm = encodeSearchTerm(encodeURIComponent(selectors.getAttendeesSearchTerm(state)));

  if (searchTerm && searchTerm.length > 0) {
    dispatch(toggleLoader(true));
    return debounceFetch(searchTerm)
      .then(response => {
        dispatch(updateSuggestedAttendees(response));
      })
      .catch(serverError => {
        const error = getNotification('peoplepicker.error', serverError);
        dispatch(notificationsOperations.showError(error));
      })
      .then(() => {
        dispatch(toggleLoader(false));
      });
  }
  return null;
};

export default {
  updateSelectedAttendees,
  fetchAttendees,
  clearAttendees,
  setAttendeesSearchTerm,
  toggleLoader
};
