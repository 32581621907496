import get from 'lodash/fp/get';
import set from 'lodash/fp/set';
import cloneDeep from 'lodash/fp/cloneDeep';
import types from './types';
import { getResourceSubTypesObject } from '../../util';

const capacaties = {
  small: {
    key: 'small',
    name: 'small',
    type: 'capacity',
    description: '4-6',
    min: 4,
    max: 6,
    enabled: false
  },
  medium: {
    key: 'medium',
    name: 'medium',
    type: 'capacity',
    description: '6-8',
    min: 6,
    max: 8,
    enabled: false
  },
  large: {
    key: 'large',
    name: 'large',
    type: 'capacity',
    description: '8-14',
    min: 8,
    max: 14,
    enabled: false
  },
  extralarge: {
    key: 'extralarge',
    name: 'extralarge',
    type: 'capacity',
    description: '14+',
    min: 14,
    max: 1000,
    enabled: false
  }
};

const equipment = {
  beamer: {
    key: 'beamer',
    name: 'beamer',
    type: 'equipment',
    enabled: false
  },
  screen: {
    key: 'screen',
    name: 'screen',
    type: 'equipment',
    enabled: false
  },
  whiteboard: {
    key: 'whiteboard',
    name: 'whiteboard',
    type: 'equipment',
    enabled: false
  },
  webexboard: {
    key: 'webexboard',
    name: 'webexboard',
    type: 'equipment',
    enabled: false
  },
  flipchart: {
    key: 'flipchart',
    name: 'flipchart',
    type: 'equipment',
    enabled: false
  },
  audio: {
    key: 'audio',
    name: 'audio',
    type: 'equipment',
    enabled: false
  },
  moderatorCase: {
    key: 'moderatorCase',
    name: 'moderatorCase',
    type: 'equipment',
    enabled: false
  },
  rolly: {
    key: 'rolly',
    name: 'rolly',
    type: 'equipment',
    enabled: false
  },
  mobileEquipment: {
    key: 'mobileEquipment',
    name: 'mobileEquipment',
    type: 'equipment',
    enabled: false
  },
  surfaceHubEquipment: {
    key: 'surfaceHubEquipment',
    name: 'surfaceHub',
    type: 'equipment',
    enabled: false
  },
  telepresenceEquipment: {
    key: 'telepresenceEquipment',
    name: 'telepresence',
    type: 'equipment',
    enabled: false
  },
  clickShare: {
    key: 'clickShare',
    name: 'clickShare',
    type: 'equipment',
    enabled: false
  },
  desktop: {
    key: 'desktop',
    name: 'desktop',
    type: 'equipment',
    enabled: false
  },
  tablet: {
    key: 'tablet',
    name: 'tablet',
    type: 'equipment',
    enabled: false
  },
  mobile: {
    key: 'mobile',
    name: 'mobile',
    type: 'equipment',
    enabled: false
  },
  brenner: {
    key: 'brenner',
    name: 'brenner',
    type: 'equipment',
    enabled: false
  },
  router: {
    key: 'router',
    name: 'router',
    type: 'equipment',
    enabled: false
  },
  printer: {
    key: 'printer',
    name: 'printer',
    type: 'equipment',
    enabled: false
  },
  videocam: {
    key: 'videocam',
    name: 'videocam',
    type: 'equipment',
    enabled: false
  },
  digicam: {
    key: 'digicam',
    name: 'digicam',
    type: 'equipment',
    enabled: false
  },
  diverse: {
    key: 'diverse',
    name: 'diverse',
    type: 'equipment',
    enabled: false
  }
};

const roomTypes = {};
const floors = {};
const sectors = {};
const allTypes = { ...capacaties, ...equipment, ...roomTypes, ...floors, ...sectors };

const initialState = {
  rooms: {
    book: cloneDeep(allTypes),
    calendar: cloneDeep(allTypes),
    bookingCollection: cloneDeep(allTypes)
  },
  dirty: {
    book: false,
    calendar: false,
    bookingCollection: false
  },
  namespace: 'book'
};

const roomFilterReducer = (state = initialState, action) => {
  const { namespace } = state;

  switch (action.type) {
    case types.CHANGE_FILTER: {
      return { ...state, namespace: action.name };
    }

    case types.TOGGLE_FILTER: {
      const filter = action.key;
      const path = `${ namespace }.${ filter }.enabled`;
      const value = get(path, state.rooms);

      return {
        ...state,
        rooms: set(path, !value, state.rooms),
        dirty: set(namespace, true, state.dirty)
      };
    }

    case types.RESET_DIRTY: {
      return {
        ...state,
        dirty: set(namespace, false, state.dirty)
      };
    }

    case types.RESET_FILTER: {
      const newState = { ...state };
      const keys = Object.keys(newState.rooms[action.namespace]);

      keys.forEach(key => {
        newState.rooms[action.namespace][key].enabled = false;
      });
      return newState;
    }

    case types.UPDATE_FLOORS: {
      const newState = { ...state };
      const newFloors = [];

      const oldValues = Object.values(newState.rooms[action.namespace]);
      const oldFloors = oldValues.filter(p => p.type === 'floor');

      oldFloors.forEach(oldFloor => {
        delete newState.rooms[action.namespace][oldFloor.key];
      });

      action.floors.forEach(newFloor => {
        newFloors[newFloor.key] = newFloor;
      });

      newState.rooms[action.namespace] = { ...newState.rooms[action.namespace], ...newFloors };
      return newState;
    }

    case types.UPDATE_SECTORS: {
      const newState = { ...state };
      const newSectors = [];
      const oldValues = Object.values(newState.rooms[action.namespace]);
      const oldSectors = oldValues.filter(p => p.type === 'sector');

      oldSectors.forEach(oldSector => {
        delete newState.rooms[action.namespace][oldSector.key];
      });

      action.sectors.forEach(newSector => {
        newSectors[newSector.key] = newSector;
      });

      newState.rooms[action.namespace] = { ...newState.rooms[action.namespace], ...newSectors };
      return newState;
    }

    case types.UPDATE_ROOMTYPES: {
      const newState = { ...state };
      const newRoomTypes = [];
      const oldValues = Object.values(newState.rooms[action.namespace]);
      const oldRoomTypes = oldValues.filter(p => p.type === 'roomType');

      if (!action.resourcesSubTypes) {
        return newState;
      }

      oldRoomTypes.forEach(oldRoomType => {
        delete newState.rooms[action.namespace][oldRoomType.key];
      });

      action.resourcesSubTypes.forEach(newRoomType => {
        newRoomTypes[newRoomType.key] = newRoomType;
      });

      const resourcesSubTypes = getResourceSubTypesObject(action.resourcesSubTypes, 'Room');
      newState.rooms[action.namespace] = { ...newState.rooms[action.namespace], ...resourcesSubTypes };

      return newState;
    }

    default:
      return state;
  }
};

export default roomFilterReducer;
