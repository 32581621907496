import types from '../types';

const clearAvailabilities = () => ({
  type: types.CLEAR_ALTERNATIVE_AVAILABILITIES
});

const addAvailability = (section, availability) => ({
  type: types.ADD_ALTERNATIVE_AVAILABILITY,
  section,
  availability
});

const updateAvailability = (section, availability) => ({
  type: types.UPDATE_ALTERNATIVE_AVAILABILITY,
  section,
  availability
});

const updateSkipCount = (section, skipCount) => ({
  type: types.UPDATE_ALTERNATIVE_SKIPCOUNT,
  section,
  skipCount
});

const updateIsLoading = (section, isLoading) => ({
  type: types.UPDATE_ALTERNATIVE_LOADING,
  section,
  isLoading
});

const updateHasMoreResults = (section, hasMore) => ({
  type: types.HAS_MORE_ALTERNATIVE_RESULTS,
  section,
  hasMore
});

export default {
  clearAvailabilities,
  addAvailability,
  updateAvailability,
  updateSkipCount,
  updateIsLoading,
  updateHasMoreResults
};
