/* eslint-disable react/no-danger */

import React from 'react';
import cx from 'classnames';
import FeedbackIcon from './FeedbackIcon';
import FeedbackTextArea from './FeedbackTextArea';
import { getPropTypes } from './Feedback';
import { Icon, Button, Translate, withTranslation, Validation, ShowAt } from '../common';

function FeedbackForm(props) {
  const {
    happyComment,
    sadComment,
    updateHappyComment,
    updateSadComment,
    submitted,
    isSubmitting,
    submit,
    translate,
    hasFeedback,
    hasTriedToSubmit,
    hasSucceeded,
    sadCommentNotTooLong,
    happyCommentNotTooLong
  } = props;

  const title = translate('feedback.title');
  const sendFeedback = translate('feedback.send');
  const infoText = translate('feedback.infoText');
  const noChangeRequests = translate('feedback.noChangeRequests');

  return (
    <div>
      <div className="Feedback__Title">{title}</div>
      {submitted && (
        <p>
          <Translate path="feedback.thankyou" />
        </p>
      )}

      {!submitted && (
        <div className={cx({ 'Feedback--succeeded': hasSucceeded })}>
          <div className="Feedback__InfoText">{infoText}</div>

          <div className="Feedback__NoChangeRequests">
            <Icon icon="046-information-circle" className="FeedbackNoChangeRequests__Icon" />
            <span
              dangerouslySetInnerHTML={{
                __html: noChangeRequests
              }}
            />
          </div>

          <div className="Feedback__Section">
            <div className="Feedback__Group">
              <FeedbackIcon happy />
              <FeedbackTextArea
                value={happyComment}
                onChange={event => updateHappyComment(event.target.value)}
                placeholder={translate('feedback.placeholderHappy')}
              />
            </div>
            <div className="Feedback__ValidationError">
              <Validation
                type="error"
                message="feedback.textTooLong"
                visible={!happyCommentNotTooLong}
              />
            </div>
          </div>

          <div className="Feedback__Section">
            <div className="Feedback__Group">
              <FeedbackIcon happy={false} />
              <FeedbackTextArea
                value={sadComment}
                onChange={event => updateSadComment(event.target.value)}
                placeholder={translate('feedback.placeholderSad')}
              />
            </div>

            <div className="Feedback__ValidationError">
              <Validation
                type="error"
                message="feedback.textTooLong"
                visible={!sadCommentNotTooLong}
              />
              <Validation
                type="error"
                message="feedback.feedbackRequired"
                visible={!hasFeedback && hasTriedToSubmit}
              />
            </div>
          </div>

          <ShowAt breakpoint="smallAndAbove">
            <Button
              disabled={isSubmitting}
              label={sendFeedback}
              onClick={submit}
              className="Feedback__SubmitButton"
            />
          </ShowAt>
          <ShowAt breakpoint="smallAndBelow">
            <Button
              disabled={isSubmitting}
              label={sendFeedback}
              onClick={submit}
              className="button--fixed"
            />
          </ShowAt>
        </div>
      )}
    </div>
  );
}

FeedbackForm.propTypes = {
  ...getPropTypes
};

export default withTranslation(FeedbackForm);
