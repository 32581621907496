import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { notificationsSelectors, notificationsOperations } from '../../state/notifications';
import { Error, Confirmation } from '../common';

import './_style.css';

function Notifications({ error, success, hideError, hideSuccess }) {
  return (
    <div className="Notifications">
      {success.active && (
      <Confirmation title={success.title} text={success.text} onClose={hideSuccess} />
    )}
      {error.active && <Error title={error.title} text={error.text} onClose={hideError} />}
    </div>
  );
}

Notifications.propTypes = {
  success: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    title: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  error: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    title: PropTypes.string,
    text: PropTypes.string
  }).isRequired,
  hideError: PropTypes.func.isRequired,
  hideSuccess: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  error: notificationsSelectors.getError(state),
  success: notificationsSelectors.getSuccess(state)
});

const mapDispatchToProps = dispatch => ({
  hideSuccess: () => dispatch(notificationsOperations.hideSuccess()),
  hideError: () => dispatch(notificationsOperations.hideError())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
