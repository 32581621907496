import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Dialog, Button, withTranslation, Spacing } from '../../../common';
import { modalOperations } from '../../../../state/modal';

import Header from '../../../Header';

import keys from './modalKeys';
import { regionsOperations, regionsSelectors } from '../../../../state/regions';

class RegionsDeleteDialog extends Component {
  confirmedDeleteRegion = () => {
    const { clearModals, deleteRegion } = this.props;
    deleteRegion();
    clearModals();
  };

  renderDialogContent = () => {
    const { translate, popDialog, selectedRegion } = this.props;

    const title = `${ translate('admin.settings.regions.delete.title') }${ selectedRegion?.name }`;
    const text = `${ translate('admin.settings.regions.delete.confirm') }
      ${ translate('common.key') } ${ selectedRegion?.key } ?`;
    const yes = translate('common.yes');
    const no = translate('common.no');

    return (
      <div>
        <Header title={title} onClose={popDialog} icon="022-close" iconRight fixed={false} />
        <div className="RegionFormField">
          <Spacing horizontal={1} vertical={1}>
            <div>
              {text}
            </div>
          </Spacing>
          <div className="Delete_Buttons_Container">
            <Button
              className="RegionsDelete__Button"
              label={no}
              onClick={popDialog}
              secondary
            />
            <Button
              className="RegionsDelete__Button"
              label={yes}
              onClick={this.confirmedDeleteRegion}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { popDialog } = this.props;

    return (
      <Dialog dialogKey={keys.REGION_DELETE_DIALOG} onOverlayClick={popDialog}>
        {this.renderDialogContent()}
      </Dialog>
    );
  }
}

RegionsDeleteDialog.propTypes = {
  popDialog: PropTypes.func.isRequired,
  clearModals: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  deleteRegion: PropTypes.func.isRequired,
  selectedRegion: PropTypes.shape({
    key: PropTypes.any,
    name: PropTypes.any
  })
};

const mapStateToProps = state => ({
  selectedRegion: regionsSelectors.getSelectedRegion(state)
});

const mapDispatchToProps = dispatch => ({
  popDialog: () => dispatch(modalOperations.popDialog()),
  clearModals: () => dispatch(modalOperations.clearModals()),
  deleteRegion: () => dispatch(regionsOperations.deleteRegion())
});

const RegionsDeleteDialogComponent = withTranslation(RegionsDeleteDialog);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionsDeleteDialogComponent);
