import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import cx from 'classnames';

import { searchSelectors } from '../../../state/search';
import { bookSelectors, bookOperations } from '../../../state/book';
import { toTimeString, toLocaleDateString, oneHour, emitter } from '../../../util';
import { ShowAt, CustomPropTypes } from '../../common';
import { RoomCard, EquipmentCard } from './SearchResultCard';
import SearchResultJournal from './SearchResultJournal';

import {
  getRelativeStartTime,
  getRelativeEndTime,
  getAbsoluteStartTime,
  getAbsoluteEndTime
} from './timeInput';

class SearchResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: false
    };

    this.resourceBookedSubscription = null;
    this.select = this.select.bind(this);
    this.closeCard = this.closeCard.bind(this);
    this.openCard = this.openCard.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    this.resourceBookedSubscription = emitter.addListener('onResourceBooked', this.closeCard);
  }

  componentWillUnmount() {
    this.resourceBookedSubscription.remove();
  }

  handleClickOutside() {
    const { resource } = this.props;
    this.closeCard(resource.id);
  }

  setTime = ({ availabilities }) => {
    const { updateDate, updateTime, isRelativeTime } = this.props;

    const start = isRelativeTime
      ? getRelativeStartTime(availabilities)
      : getAbsoluteStartTime(availabilities);

    const end = isRelativeTime
      ? getRelativeEndTime(availabilities, oneHour)
      : getAbsoluteEndTime(availabilities);

    const time = {
      from: toTimeString(start),
      to: toTimeString(end)
    };

    updateDate(toLocaleDateString(start));
    updateTime(time);
  };

  hasAvailabilities = resource => resource.availabilities && resource.availabilities.length > 0;

  select(event) {
    const { dataset } = event.target;
    if (dataset.ignore) {
      return;
    }

    const { resource, resetState } = this.props;
    const { selected } = this.state;

    if (!selected && this.hasAvailabilities) {
        resetState();
        this.setTime(resource);
        this.setState({ selected: true });
    }
  }

  openCard() {
    this.setState({ selected: true });
  }

  closeCard(resourceId) {
    const { resource } = this.props;
    const { selected } = this.state;
    if (selected && resource.id === resourceId) {
      this.setState({ selected: false });
    }
  }

  renderCard = () => {
    const { resource, style, openModal, alternativeType } = this.props;
    const { selected } = this.state;

    const isSelected = selected;

    const classes = cx('Book__SearchResult', {
      'Book__SearchResult--selected': isSelected
    });
    const isAlternativeTime = alternativeType && alternativeType === 'time';
    const showAlternative = resource.resourceType === 'Room' || isAlternativeTime;

    const props = {
      resource,
      selected: isSelected,
      openModal,
      alternativeType: showAlternative ? alternativeType : null,
      showJournal: resource.showJournal
    };

    return (
      <div role="button" tabIndex="0" className={classes} style={style} onClick={this.select}>
        {resource.resourceType === 'Room' ? <RoomCard {...props} /> : <EquipmentCard {...props} />}
        {resource.showJournal && !!resource.bookings.length && resource.resourceType === 'Room'
          && this.renderJournal(resource)}
      </div>
    );
  };

  renderJournal(resource) {
    return (
      <div>
        <ShowAt breakpoint="900AndBelow">
          <SearchResultJournal resource={resource} openCard={this.openCard} />
        </ShowAt>
      </div>
    );
  }

  render() {
    return <>{this.renderCard()}</>;
  }
}

const mapStateToProps = state => ({
  isRelativeTime: searchSelectors.isRelativeTime(state),
  time: bookSelectors.getTime(state),
  isValid: bookSelectors.isValidTimeRange(state),
  isSubmitting: bookSelectors.isSubmitting(state),
  resourceType: searchSelectors.getResourceType(state)
});

const mapDispatchToProps = dispatch => ({
  updateTime: time => dispatch(bookOperations.updateTime(time)),
  updateDate: date => dispatch(bookOperations.updateDate(date)),
  resetState: () => dispatch(bookOperations.resetState())
});

SearchResult.propTypes = {
  style: PropTypes.shape(),
  resource: CustomPropTypes.resource,
  openModal: PropTypes.func,
  isRelativeTime: PropTypes.bool.isRequired,
  updateDate: PropTypes.func.isRequired,
  updateTime: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  alternativeType: PropTypes.oneOf(['floor', 'sector', 'time', 'roomequipment', 'roomtype', 'capacity'])
};

const SearchResultComponent = onClickOutside(SearchResult);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultComponent);
