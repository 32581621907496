import React from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/fp/includes';

import { CustomPropTypes, RoomCard } from '../../../common';
import Booking from '../Booking';
import FormSubmit from '../FormSubmit';
import PrivateInformation from '../PrivateInformation';
import ProfileInformation from '../ProfileInformation';
import RecurringInformation from '../RecurringInformation';

function BookingRoomCardMobile({
  booking,
  selected,
  style,
  onOpenOccurrence,
  onOpenMaster,
  isProfile3,
  isProfile2
}) {
  const { resource, isPrivate, isRecurring, roomMeetingResponseType } = booking;

  const renderBookingInformation = () => {
    if (selected) {
      return null;
    }
    return <Booking booking={booking} key="Booking" />;
  };

  const renderPrivateInformation = () => {
    if (!isPrivate || selected) {
      return null;
    }
    return <PrivateInformation key="Private" />;
  };

  const renderProfileInformation = () => {
    if (selected || !(isProfile2 || isProfile3)) {
      return null;
    }
    return (
      <ProfileInformation
        resource={resource}
        responseType={roomMeetingResponseType}
        key="Profile"
      />
    );
  };

  const renderRecurringInformation = () => {
    if (!selected && isRecurring) {
      return <RecurringInformation key="Recurring" />;
    }
    return null;
  };

  const renderFormSubmit = () => {
    if (selected && isRecurring) {
      return (
        <FormSubmit
          openDetailView={onOpenOccurrence}
          openMasterView={onOpenMaster}
          key="BookingSubmit"
        />
      );
    }
    return null;
  };

  const onCardClick = () => {
    const isBookedForMe = !includes('Owner', booking.permissionTypes);

    if (!isRecurring || isBookedForMe) {
      onOpenOccurrence();
    }
  };

  return (
    <RoomCard resourceSubType={resource.resourceSubType} onClick={onCardClick} style={style}>
      {renderBookingInformation()}
      {renderPrivateInformation()}
      {renderRecurringInformation()}
      {renderFormSubmit()}
      {renderProfileInformation()}
    </RoomCard>
  );
}

BookingRoomCardMobile.propTypes = {
  booking: CustomPropTypes.booking,
  selected: PropTypes.bool.isRequired,
  style: PropTypes.shape(),
  onOpenOccurrence: PropTypes.func.isRequired,
  onOpenMaster: PropTypes.func.isRequired,
  isProfile3: PropTypes.bool.isRequired,
  isProfile2: PropTypes.bool.isRequired
};

export default BookingRoomCardMobile;
