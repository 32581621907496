import types from './types';

const updateRoom = room => ({
  type: types.UPDATE_ROOM,
  room
});

const updateIsLoading = isLoading => ({
  type: types.UPDATE_IS_LOADING,
  isLoading
});

const addPhotoId = photoId => ({
  type: types.ADD_PHOTO_ID,
  photoId
});

const removePhotoId = photoId => ({
  type: types.REMOVE_PHOTO_ID,
  photoId
});

const toggleLoadingPhoto = photoId => ({
  type: types.TOGGLE_LOADING_PHOTO,
  photoId
});

export default {
  updateRoom,
  updateIsLoading,
  addPhotoId,
  removePhotoId,
  toggleLoadingPhoto
};
