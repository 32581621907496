import { createSelector } from 'reselect';

const getFloors = state => state.floorsState.floors.floors;
const isLoaded = state => state.buildingsState.buildingsData.isLoaded;
const getAge = state => state.floorsState.floors.age;
const getSelectedFloor = state => state.floorsState.floors.selectedFloor;

const hasFloors = createSelector(
  getFloors,
  floors => {
    const floorIDs = [];
    if (floors) {
      Object.entries(floors).forEach(floor => floorIDs.push(floor));
    }
    return floors && floorIDs.length > 0;
  }
);

export default {
  getFloors,
  hasFloors,
  isLoaded,
  getAge,
  getSelectedFloor
};
