import React from 'react';
import PropTypes from 'prop-types';

import { ChangeRequestFinderPicker, ChangeDetector, withTranslation } from '../../common';

function FormFieldChangeRequestFinder({
  field,
  detectChanges,
  translate,
  ...otherProps
}) {
  const labelText = translate(`changeRequests.fieldNames.${field}`);

  return (
    <div className="ChangeRequestFinder">
      <ChangeDetector values={{}} invalid={false} detectChanges={detectChanges}>
        <label htmlFor={field}>{labelText}</label>
        <ChangeRequestFinderPicker
          id={field}
          label={labelText}
          {...otherProps}
        />
      </ChangeDetector>
    </div>
  );
}

FormFieldChangeRequestFinder.propTypes = {
  field: PropTypes.string.isRequired,
  detectChanges: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

FormFieldChangeRequestFinder.defaultProps = {
  detectChanges: true
};

export default withTranslation(FormFieldChangeRequestFinder);
