import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, EquipmentCard } from '../../../../../common';
import Equipment from '../../Equipment';
import ProfileInformation from '../../ProfileInformation';
import RecurrenceAvailability from '../../RecurrenceAvailability';

function EquipmentCardRecurrenceMobile(props) {
  const { resource, isProfile3, isProfile2 } = props;

  const renderProfileInformation = () => {
    if (isProfile3 || isProfile2) {
      return <ProfileInformation resource={resource} />;
    }
    return null;
  };

  return (
    <div>
      <EquipmentCard resourceSubType={resource.resourceSubType}>
        <Equipment resource={resource} showRelativeTime={false} />
        <RecurrenceAvailability resource={resource} />
        {renderProfileInformation()}
      </EquipmentCard>
    </div>
  );
}

EquipmentCardRecurrenceMobile.propTypes = {
  resource: CustomPropTypes.resource,
  isProfile3: PropTypes.bool.isRequired,
  isProfile2: PropTypes.bool.isRequired
};

export default EquipmentCardRecurrenceMobile;
