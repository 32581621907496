import actions from '../actions';

import loadAvailabilities from './loadAvailabilities';
import loadSingleAvailability from './loadSingleAvailability';

export default {
  removeResourceById: actions.removeResourceById,
  ...loadAvailabilities,
  loadSingleAvailability
};
