import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import RecurrenceFilterEmptyState from './RecurrenceFilterEmptyState';
import RecurrenceFilterSwitch from './RecurrenceFilterSwitch';
import RecurrenceEnd from '../../../Recurrence/RecurrenceEnd';
import RecurrenceWeekly from '../../../Recurrence/RecurrenceWeekly';
import RecurrenceMonthly from '../../../Recurrence/RecurrenceMonthly';
import { Button, withTranslation } from '../../../common';
import { searchSelectors } from '../../../../state/search';
import { emitter } from '../../../../util';
import { recurrenceSelectors, recurrenceOperations } from '../../../../state/recurrence';
import styles from './RecurrenceFilter.module.css';

function RecurrenceFilter({ isActive, isValid, type, translate, setInactive, location }) {
  const isDayCalendar = location.pathname.includes('daily');
  const isWeekCalendar = location.pathname.includes('weekly');
  const isCalendar = isDayCalendar || isWeekCalendar;

  if (!isActive || isCalendar) {
    return null;
  }

  const showAvailableRooms = () => {
    setInactive();
    emitter.emit('onSearchFilterChanged', null);
  };

  if (!isValid) {
    return (
      <div className={`${styles.container} ${styles.empty}`}>
        <RecurrenceFilterEmptyState />
      </div>
    );
  }

  const buttonLabel = translate('search.detail.recurring.showAvailableRooms');

  return (
    <div className={styles.container}>
      <RecurrenceFilterSwitch />

      <div className={styles.panel}>
        {type === 'weekly' && <RecurrenceWeekly inverted />}
        {type === 'monthly' && <RecurrenceMonthly inverted />}
        {type !== 'never' && <RecurrenceEnd />}

        <Button className={styles.button} onClick={showAvailableRooms} label={buttonLabel} />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const time = searchSelectors.getTime(state);
  const isValidTime = searchSelectors.isValidDateWithTime(state);
  return {
    isActive: recurrenceSelectors.isActive(state),
    isValid: time && time.from.length > 0 && isValidTime,
    type: recurrenceSelectors.getRecurrenceType(state)
  };
};

const mapDispatchToProps = dispatch => ({
  setInactive: () => dispatch(recurrenceOperations.setInactive())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(withRouter(RecurrenceFilter)));

RecurrenceFilter.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  isActive: PropTypes.bool,
  isValid: PropTypes.bool,
  type: PropTypes.string,
  translate: PropTypes.func.isRequired,
  setInactive: PropTypes.func.isRequired
};
