import actions from './actions';
import { notificationsSelectors, notificationsOperations } from '../notifications';
import { changeRequestsService } from '../../services';

const { updateChangeRequests, updateIsLoading } = actions;

const loadChangeRequests = () => async (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);

  dispatch(notificationsOperations.hideError());
  dispatch(updateIsLoading(true));

  try {
    const changeRequests = await changeRequestsService.get();
    dispatch(updateChangeRequests(changeRequests));
  } catch (serverError) {
    const error = getNotification('changeRequests.my.error', serverError);
    dispatch(notificationsOperations.showError(error));
  }

  dispatch(updateIsLoading(false));
};

export default {
  loadChangeRequests
};
