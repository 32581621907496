import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PeoplePicker, ChangeDetector, withTranslation } from '../../common';

import './_style.css';

class FormFieldPeople extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term: ''
    };
  }

  updateTerm = term => {
    const { loadSuggestions } = this.props;
    this.setState({ term });
    loadSuggestions(term);
  };

  renderLabel = () => {
    const { field, label, translate } = this.props;
    const text = translate(`changeRequests.fieldNames.${label || field}`);
    return <label htmlFor={field}>{text}</label>;
  };

  renderPeoplePicker = invalid => {
    const { term } = this.state;
    const { field, values, suggestions, loading, update } = this.props;

    const selectedPeople = values.new;
    const updateSelectedPeople = people => {
      update(field, people);
    };

    const classes = invalid ? 'FormFieldPeople--invalid' : '';

    const peoplePickerProps = {
      term,
      updateTerm: this.updateTerm,
      selectedPeople,
      updateSelectedPeople,
      people: suggestions,
      fetchPeople: () => {},
      classes,
      loading
    };

    return <PeoplePicker {...peoplePickerProps} />;
  };

  render() {
    const { values, isSubmitted, detectChanges } = this.props;
    const hasValues = values.new && values.new.length > 0;
    const invalid = isSubmitted && !hasValues;

    return (
      <div className="ChangeRequestFormField">
        <ChangeDetector values={values} invalid={invalid} detectChanges={detectChanges}>
          {this.renderLabel()}
          {this.renderPeoplePicker(invalid)}
        </ChangeDetector>
      </div>
    );
  }
}

const personPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  email: PropTypes.string
});

FormFieldPeople.propTypes = {
  field: PropTypes.string.isRequired,
  label: PropTypes.string,
  values: PropTypes.shape({
    old: PropTypes.arrayOf(personPropType),
    new: PropTypes.arrayOf(personPropType)
  }).isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  detectChanges: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(personPropType),
  loadSuggestions: PropTypes.func.isRequired
};

FormFieldPeople.defaultProps = {
  detectChanges: true
};

export default withTranslation(FormFieldPeople);
