import { createSelector } from 'reselect';
import { searchSelectors } from '../search';
import { locationsSelectors } from '../locations';
import { roomFilterSelectors } from '../roomFilter';
import { availabilitySelectors } from '../availability';
import { buildingsSelectors } from '../buildings';

const getAlternatives = state => state.alternativesState.alternatives.sections;
const getBuildings = createSelector(
  buildingsSelectors.getBuildings,
  buildings => buildings
);

const getHasMore = section => createSelector(
  getAlternatives,
  sections => sections[section].hasMore
);

const getIsLoading = section => createSelector(
  getAlternatives,
  sections => sections[section].isLoading
);

const getSkipCount = section => createSelector(
  getAlternatives,
  sections => sections[section].skipCount
);

const getAvailabilities = section => createSelector(
  [getBuildings, getAlternatives],
  (buildings, sections) => {
    const availabilities = sections[section].availability;
    const mapResource = resource => ({
      ...resource,
      building: buildings.find(building => building.id === resource.buildingId)
    });

    return availabilities.map(availability => ({
      ...mapResource(availability.resource),
      availabilities: availability.availabilities
    }));
  }
);

const hasAlternatives = section => createSelector(
  [getAlternatives],
  sections => sections[section].availability.length > 0
);

const showSection = section => createSelector(
  [
    searchSelectors.getResourceType,
    searchSelectors.isRelativeTime,
    roomFilterSelectors.getSelectedFloorFilters,
    roomFilterSelectors.getSelectedSectorFilters,
    roomFilterSelectors.getSelectedRoomTypeFilters,
    roomFilterSelectors.getSelectedEquipmentFilters,
    roomFilterSelectors.getSelectedCapacityFilters
  ],
  (resourceType, isRelativeTime, floors, sectors, roomTypes, equipments, capacities) => {
    const isRoom = resourceType === 'room';

    switch (section) {
      case 'time':
        return !isRelativeTime;

      case 'floor':
        return isRoom && floors.length > 0;

      case 'sector':
        return isRoom && sectors.length > 0;

      case 'roomtype':
        return isRoom && roomTypes.length > 0;

      case 'roomequipment':
        return isRoom && equipments.length > 0;

      case 'capacity':
        return isRoom && capacities.length > 0;

      default:
        return false;
    }
  }
);

const isEnabled = createSelector(
  [
    availabilitySelectors.isRecurrence,
    locationsSelectors.hasLocations
  ],
  (isRecurrence, hasLocations) => {
    if (isRecurrence || !hasLocations) {
      return false;
    }

    return true;
  }
);

const isVisible = createSelector(
  [
    isEnabled,
    availabilitySelectors.hasAvailabilities,
    availabilitySelectors.hasMoreResults,
    availabilitySelectors.isLoading
  ],
  (enabled, hasAvailabilities, hasMoreAvailabilities, isLoading) => {
    if (!enabled || isLoading) {
      return false;
    }

    return !hasAvailabilities || !hasMoreAvailabilities;
  }
);

export default {
  isEnabled,
  isVisible,
  hasAlternatives,
  showSection,
  getHasMore,
  getIsLoading,
  getSkipCount,
  getAvailabilities
};
