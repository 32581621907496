const { protocol, hostname, port } = window.location;
const origin = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

const config = {
  protocol,
  hostname,
  port,
  origin,
  pageSize: 10,
  disableLogin: false,
  // Days to allow book in the future. Calculation: 1.5 Years = 365 * 1.5 = 548.
  maxDaysInFuture: 548,
  // Show resource data quality warning
  resourceDataQuality: true
};

export default config;
