import types from '../types';

const initialState = {
  time: {
    from: '',
    to: ''
  },
  resources: [],
  seatingId: null
};

const bookingCollectionReducers = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_TIME: {
      return {
        ...state,
        time: action.time
      };
    }

    case types.ADD_RESOURCE: {
      const { resources, seatingId: id } = state;
      const { resource } = action;
      const { seatings } = resource;
      const seatingId = seatings && seatings.length ? id : null;
      return {
        ...state,
        resources: [...resources, { ...resource, seatingId }]
      };
    }

    case types.REMOVE_RESOURCE: {
      const { resourceId } = action;
      const resource = state.resources.find(({ id }) => id === resourceId);
      return {
        ...state,
        resources: state.resources.filter(res => res !== resource)
      };
    }

    case types.UPDATE_SEATING_ID: {
      const { seatingId } = action;
      return { ...state, seatingId };
    }

    case types.RESET_COLLECTION_STATE: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default bookingCollectionReducers;
