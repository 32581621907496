import types from './types';

const switchResourceType = () => ({
  type: types.SWITCH_RESOURCE_TYPE
});

const updateTime = time => ({
  type: types.UPDATE_TIME,
  time
});

const updateDate = date => ({
  type: types.UPDATE_DATE,
  date
});

const resetFilters = () => ({
  type: types.RESET_FILTERS
});

const resetChanged = () => ({
  type: types.RESET_CHANGED
});

const updateSearchedExactTime = searchedExactTime => ({
  type: types.UPDATE_SEARCHED_EXACT_TIME,
  searchedExactTime
});

export default {
  switchResourceType,
  updateDate,
  updateTime,
  resetChanged,
  updateSearchedExactTime,
  resetFilters
};
