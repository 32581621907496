import types from './types';

const updateLanguage = language => ({
  type: types.UPDATE_LANGUAGE,
  language
});

const updateTranslations = translations => ({
  type: types.UPDATE_TRANSLATIONS,
  translations
});

export default {
  updateLanguage,
  updateTranslations
};
