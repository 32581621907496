import React from 'react';
import PropTypes from 'prop-types';

import key from './key';
import { Modal, Translate } from '../common';
import WelcomeImage from './WelcomeImage';
import WelcomeTitle from './WelcomeTitle';
import WelcomeIntro from './WelcomeIntro';
import WelcomeSection from './WelcomeSection';
import WelcomeImprovements from './WelcomeImprovements';
import WelcomeDoNotShow from './WelcomeDoNotShow';
import WelcomeButton from './WelcomeButton';

import styles from './WelcomeModal.module.css';

function WelcomeModal({ close, doNotShow, toggleDoNotShow }) {
  return (
    <div>
      <Modal modalKey={key} mobile>
        <div className={styles.modal}>
          <div className={styles.content}>
            <div className={styles.head}>
              <WelcomeTitle mobile />
              <WelcomeImage mobile />
            </div>
            <WelcomeIntro mobile />

            <div className={styles.sections}>
              <WelcomeSection className={styles.section}>
                <div className={styles.subheader}>
                  <Translate path="welcome.improvements.title" />
                </div>
                <WelcomeImprovements />
              </WelcomeSection>

              <WelcomeSection className={styles.section}>
                <div className={styles.subheader}>
                  <Translate path="welcome.news.title" />
                </div>
                <Translate path="welcome.news.text" />
              </WelcomeSection>

              <WelcomeSection className={styles.section}>
                <div className={styles.subheader}>
                  <Translate path="welcome.spaces.title" />
                </div>
                <Translate path="welcome.spaces.text" />
              </WelcomeSection>
            </div>

            <WelcomeDoNotShow doNotShow={doNotShow} toggleDoNotShow={toggleDoNotShow} />
          </div>
          <WelcomeButton onClick={close} mobile />
        </div>
      </Modal>
    </div>
  );
}

WelcomeModal.propTypes = {
  close: PropTypes.func.isRequired,
  doNotShow: PropTypes.bool.isRequired,
  toggleDoNotShow: PropTypes.func.isRequired
};

export default WelcomeModal;
