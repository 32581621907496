import React from 'react';
import config from 'react-global-configuration';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './_style.css';

function MenuLogo({ history }) {
  const redirect = () => {
    history.push('book');
  };

  return (
    <div className="Menu__Logo" role="button" tabIndex={-1} onClick={redirect}>
      <img src={config.get('logoUrl')} alt="Logo" />
      <span>{config.get('name')}</span>
    </div>
  );
}

MenuLogo.propTypes = { history: PropTypes.shape({ push: PropTypes.func.isRequired }).isRequired };

export default withRouter(MenuLogo);
