import types from './types';

const clearMyBookings = () => ({
  type: types.CLEAR_MYBOOKINGS
});

const addMyBookings = myBookings => ({
  type: types.ADD_MYBOOKINGS,
  myBookings
});

const updateMyBooking = myBooking => ({
  type: types.UPDATE_MYBOOKING,
  myBooking
});

const updateLastDate = date => ({
  type: types.UPDATE_MYBOOKINGS_DATE,
  date
});

const updateIsLoading = isLoading => ({
  type: types.UPDATE_MYBOOKINGS_LOADING,
  isLoading
});

const removeMyBooking = id => ({
  type: types.REMOVE_MYBOOKING,
  id
});

const removeRecurrence = id => ({
  type: types.REMOVE_RECURRENCE,
  id
});

export default {
  clearMyBookings,
  addMyBookings,
  updateMyBooking,
  updateLastDate,
  updateIsLoading,
  removeMyBooking,
  removeRecurrence
};
