import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Measure from 'react-measure';
import moment from 'moment';
import CalendarBooking from './CalendarBooking';

class CalendarBufferedBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 5
    };
  }

  onResize = ({ bounds: { width } }) => {
    const { booking: { start, end } } = this.props;
    const minutes = moment(end).diff(start, 'minutes', true);
    this.setState({ width: width / minutes * 30 });
  };

  renderBuffer = position => {
    const { width } = this.state;
    const style = {
      width: `${width}px`,
      [position]: `-${width - 1}px`
    };

    return <div className="CalendarBufferedBooking__Block" style={style} />;
  };

  render() {
    return (
      <>
        {this.renderBuffer('left')}

        <Measure bounds onResize={this.onResize}>
          {({ measureRef }) => (
            <div ref={measureRef}>
              <CalendarBooking {...this.props} />
            </div>
          )}
        </Measure>

        {this.renderBuffer('right')}
      </>
    );
  }
}

CalendarBufferedBooking.propTypes = {
  booking: PropTypes.shape({
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired
  }).isRequired
};

export default CalendarBufferedBooking;
