import React from 'react';
import PropTypes from 'prop-types';

import key from './key';
import { Dialog, Translate } from '../common';
import WelcomeImage from './WelcomeImage';
import WelcomeTitle from './WelcomeTitle';
import WelcomeIntro from './WelcomeIntro';
import WelcomeSection from './WelcomeSection';
import WelcomeImprovements from './WelcomeImprovements';
import WelcomeDoNotShow from './WelcomeDoNotShow';
import WelcomeButton from './WelcomeButton';

import styles from './WelcomeDialog.module.css';

function WelcomeDialog({ close, doNotShow, toggleDoNotShow }) {
  return (
    <div>
      <Dialog dialogKey={key} onOverlayClick={close}>
        <div className={styles.dialog}>
          <div className={styles.intro}>
            <WelcomeImage />
            <div>
              <WelcomeTitle />
              <WelcomeIntro />
            </div>
          </div>

          <div className={styles.sections}>
            <WelcomeSection className={styles.section}>
              <div className={styles.subheader}>
                <Translate path="welcome.improvements.title" />
              </div>
              <WelcomeImprovements />
            </WelcomeSection>

            <WelcomeSection className={styles.section}>
              <div className={styles.subheader}>
                <Translate path="welcome.news.title" />
              </div>
              <Translate path="welcome.news.text" />
            </WelcomeSection>

            <WelcomeSection className={styles.section}>
              <div className={styles.subheader}>
                <Translate path="welcome.spaces.title" />
              </div>
              <Translate path="welcome.spaces.text" />
            </WelcomeSection>
          </div>

          <WelcomeDoNotShow doNotShow={doNotShow} toggleDoNotShow={toggleDoNotShow} />
          <WelcomeButton onClick={close} absolute />
        </div>
      </Dialog>
    </div>
  );
}

WelcomeDialog.propTypes = {
  close: PropTypes.func.isRequired,
  doNotShow: PropTypes.bool.isRequired,
  toggleDoNotShow: PropTypes.func.isRequired
};

export default WelcomeDialog;
