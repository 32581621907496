import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, EquipmentType } from '../../../common';
import BookingTime from '../BookingTime';
import FormSubmit from '../FormSubmit';
import RecurringInformation from '../RecurringInformation';
import ProfileInformation from '../ProfileInformation';

import './_style.css';

function EquipmentBooking({
  booking,
  onClick,
  selected,
  isRecurring,
  openDetailView,
  isProfile3
}) {
  const { name, building, resourceSubType } = booking.resource;

  return (
    <div className="Booking" tabIndex="0" role="button" onClick={onClick}>
      <div className="Booking__Header">
        <div className="Booking__Name">{name}</div>
        <div className="Booking__Time">
          <BookingTime booking={booking} />
        </div>
      </div>
      <div className="Booking__Body">
        <div className="Booking__Building">{building.key}</div>
      </div>
      <div className="Booking__Information">
        <div className="Booking__Type">
          <EquipmentType resourceSubType={resourceSubType} />
        </div>
      </div>

      <div className="Booking__Footer">
        {selected && (
          <div className="Booking__Submit">
            <FormSubmit openDetailView={openDetailView} />
          </div>
        )}
      </div>
      {!selected && isRecurring && !isProfile3 && (
        <div className="Booking__RecurringInformation">
          <RecurringInformation />
        </div>
      )}
      {!isRecurring && isProfile3 && (
        <div className="Booking__ProfileInformation">
          <ProfileInformation resource={booking.resource} />
        </div>
      )}
      {isRecurring && isProfile3 && !selected && (
        <div>
          <RecurringInformation />
          <ProfileInformation resource={booking.resource} />
        </div>
      )}
    </div>
  );
}

EquipmentBooking.propTypes = {
  booking: CustomPropTypes.booking,
  openDetailView: PropTypes.func,
  onClick: PropTypes.func,
  isRecurring: PropTypes.bool,
  isProfile3: PropTypes.bool,
  selected: PropTypes.bool
};

export default EquipmentBooking;
