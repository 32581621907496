/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { styles, lineStyles } from './styles';

function RollyIcon({ disabled, ...otherProps }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 0 24 30" {...otherProps} style={styles}>
      <path d="M5,27.7H5a1.43,1.43,0,0,1-1.4-1.4h0A1.43,1.43,0,0,1,5,24.9H5a1.43,1.43,0,0,1,1.4,1.4h0A1.43,1.43,0,0,1,5,27.7Z" />
      <path d="M15.3,27.7h0a1.43,1.43,0,0,1-1.4-1.4h0a1.43,1.43,0,0,1,1.4-1.4h0a1.43,1.43,0,0,1,1.4,1.4h0a1.36,1.36,0,0,1-1.32,1.4Z" />
      <path d="M15.8,2.8H4.5a3.21,3.21,0,0,0-3,3.4V22.5a1.2,1.2,0,0,0,1,1.2H17.8a1.2,1.2,0,0,0,1-1.2V6.3A3.27,3.27,0,0,0,15.8,2.8ZM4.5,5.1H15.8a1.13,1.13,0,0,1,1,1.2V9H3.5V6.3C3.5,5.6,3.9,5.1,4.5,5.1ZM16.8,11v4.3H3.5V11ZM3.5,21.4V17.3H16.8v4.1Z" />
      <path d="M11.3,7.2H8.7a.58.58,0,0,1-.6-.56s0,0,0,0h0A.58.58,0,0,1,8.66,6H11.3a.58.58,0,0,1,.6.56s0,0,0,0h0a.58.58,0,0,1-.56.6Z" />
      <path d="M11.3,13H8.7a.58.58,0,0,1-.6-.56s0,0,0,0h0a.58.58,0,0,1,.56-.6H11.3a.58.58,0,0,1,.6.56s0,0,0,0h0a.53.53,0,0,1-.45.6Z" />
      <path d="M11.3,19.3H8.7a.58.58,0,0,1-.6-.56s0,0,0,0h0a.58.58,0,0,1,.56-.6H11.3a.58.58,0,0,1,.6.56s0,0,0,0h0a.53.53,0,0,1-.45.6Z" />
      {disabled && <line style={lineStyles} x1="1.5" y1="1.5" x2="18.8" y2="29.01" />}
    </svg>
  );
}

RollyIcon.propTypes = {
  disabled: PropTypes.bool
};

export default RollyIcon;
