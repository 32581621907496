import React from 'react';
import PropTypes from 'prop-types';

const getUnit = value => `${value * 20}px`;

function Spacing({ vertical, horizontal, top, right, bottom, left, children }) {
  const style = {};

  if (vertical) {
    style.marginLeft = getUnit(vertical);
    style.marginRight = getUnit(vertical);
  }

  if (horizontal) {
    style.marginTop = getUnit(horizontal);
    style.marginBottom = getUnit(horizontal);
  }

  if (top) {
    style.marginTop = getUnit(top);
  }

  if (right) {
    style.marginRight = getUnit(right);
  }

  if (bottom) {
    style.marginBottom = getUnit(bottom);
  }

  if (left) {
    style.marginLeft = getUnit(left);
  }

  return <div style={style}>{children}</div>;
}

Spacing.propTypes = {
  vertical: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  horizontal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired
};

export default Spacing;
