import types from './types';

const initialState = {
  selectedRoom: null,
  isLoading: false,
  loadingPhotos: []
};

const photoAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_ROOM: {
      return { ...state, selectedRoom: action.room };
    }

    case types.UPDATE_IS_LOADING: {
      return { ...state, isLoading: action.isLoading };
    }

    case types.ADD_PHOTO_ID: {
      if (!state.selectedRoom) {
        return { ...state };
      }
      const selectedRoom = {
        ...state.selectedRoom,
        photoIds: [action.photoId, ...state.selectedRoom.photoIds]
      };

      return { ...state, selectedRoom };
    }

    case types.REMOVE_PHOTO_ID: {
      if (!state.selectedRoom) {
        return { ...state };
      }

      const selectedRoom = {
        ...state.selectedRoom,
        photoIds: [...state.selectedRoom.photoIds.filter(id => id !== action.photoId)]
      };

      return { ...state, selectedRoom };
    }

    case types.TOGGLE_LOADING_PHOTO: {
      const isLoading = state.loadingPhotos.includes(action.photoId);

      if (isLoading) {
        return {
          ...state,
          loadingPhotos: [...state.loadingPhotos.filter(id => id !== action.photoId)]
        };
      }

      return { ...state, loadingPhotos: [...state.loadingPhotos, action.photoId] };
    }

    default:
      return state;
  }
};

export default photoAdminReducer;
