import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, EquipmentCard, Favorite } from '../../../../../common';
import EquipmentNameAndType from '../../EquipmentNameAndType';
import RecurrenceAvailability from '../../RecurrenceAvailability';
import ResourceLocation from '../../ResourceLocation';
import ResourceAvailability from '../../ResourceAvailability';
import ProfileInformation from '../../ProfileInformation';

const width = amount => ({ width: amount });

function EquipmentCardRecurrenceDesktop(props) {
  const { resource, isProfile3, isProfile2 } = props;

  const renderProfileInformation = () => {
    if (isProfile3 || isProfile2) {
      return <ProfileInformation resource={resource} />;
    }
    return null;
  };

  return (
    <div>
      <EquipmentCard resourceSubType={resource.resourceSubType}>
        <div style={width('34px')}>
          <Favorite resourceId={resource.id} medium />
        </div>
        <div style={width('calc(40% - 34px)')}>
          <EquipmentNameAndType resource={resource} />
        </div>
        <div style={width('30%')}>
          <ResourceLocation resource={resource} />
        </div>
        <div style={width('30%')}>
          <ResourceAvailability resource={resource} showRelativeTime={false} />
        </div>
        <RecurrenceAvailability resource={resource} />
        {renderProfileInformation()}
      </EquipmentCard>
    </div>
  );
}

EquipmentCardRecurrenceDesktop.propTypes = {
  resource: CustomPropTypes.resource,
  isProfile3: PropTypes.bool.isRequired,
  isProfile2: PropTypes.bool.isRequired
};

export default EquipmentCardRecurrenceDesktop;
