const config = {      
  remoteUrl: 'https://reservationhubapi.switzerlandnorth.cloudapp.azure.com',
  disableLogin: false,
  support: 'admin@swisscomsonic.onmicrosoft.com',
  timePicker: {
    minTime: '08:00',
    maxTime: '20:00',
    interval: '5'
  }
};

export default config;
