import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, withTranslation } from '../../../common';
import '../_style.css';

function NewBuildingButton({ history, translate }) {
    const redirect = () => {
        history.push('/admin/settings/buildings/create');
    };

    return (
      <Button
        icon="054-new"
        label={translate('admin.settings.buildings.add.newBuildingButtonLabel')}
        onClick={redirect} />
    );
}

NewBuildingButton.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    translate: PropTypes.func.isRequired
};

export default withTranslation(withRouter(NewBuildingButton));
