import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import onClickOutside from 'react-onclickoutside';

import { Icon } from '../common';

import './_style.css';

class MenuDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggleDropdown = () => {
    const { open } = this.state;
    this.setState({
      open: !open
    });
  };

  handleClickOutside = () => {
    this.setState({
      open: false
    });
  };

  renderDropdownItems = () => {
    const { children } = this.props;
    const { open } = this.state;

    if (!open) {
      return null;
    }
    const items = children
      .filter(child => child)
      .map(child => <li key={child.props.to}>{child}</li>);
    return <ul className="MenuDropdown__Items ArrowBox ArrowUp">{items}</ul>;
  };

  render() {
    const { icon, label } = this.props;
    const { open } = this.state;

    const renderIcon = () => {
      if (React.isValidElement(icon)) {
        return icon;
      }
      return <Icon className="MenuLink__Icon" icon={icon} />;
    };

    const labelClasses = cx('MenuLink__Label', {
      'MenuLink__Label--offset': React.isValidElement(icon)
    });

    const classes = cx('MenuLink', {
      'MenuLink--active': open
    });

    return (
      <div className="MenuDropdown">
        <div className={classes} onClick={this.toggleDropdown} role="button" tabIndex="-1">
          {renderIcon()}
          <div className={labelClasses}>{label}</div>
        </div>
        {this.renderDropdownItems()}
      </div>
    );
  }
}

MenuDropdown.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  label: PropTypes.string.isRequired
};

export default onClickOutside(MenuDropdown);
