import { getState } from '../state/store';
import { authSelectors } from '../state/auth';
import { i18nSelectors } from '../state/i18n';

const getJson = (method, payload) => (method !== 'GET' ? JSON.stringify(payload) : undefined);

const getLocale = state => i18nSelectors.getLocale(state);

const getToken = state => {
  const user = authSelectors.getUser(state);
  if (user === null) {
    throw new Error('user is not authenticated');
  }

  return user.access_token;
};

const getDefaultHeaders = (token, locale) => ({
  Accept: 'application/json',
  'Accept-Language': locale,
  Authorization: `Bearer ${token}`
});

export const fetchRaw = async (url, method, body, headers = {}) => {
  const state = getState();
  const token = getToken(state);
  const locale = getLocale(state);

  const defaultHeaders = getDefaultHeaders(token, locale);

  const options = {
    method,
    headers: { ...defaultHeaders, ...headers },
    body
  };

  return fetch(url, options);
};

export const fetchJson = async (url, method, payload) => {
  const headers = {
    'Content-Type': 'application/json'
  };

  const requestBody = getJson(method, payload);

  const response = await fetchRaw(url, method, requestBody, headers);
  if (response.status === 204) {
    return {};
  }

  const responseBody = await response.json();

  if (response.status >= 300) {
    const error = new Error(responseBody);
    error.response = responseBody;
    error.status = response.status;
    throw error;
  }

  return responseBody;
};

export const fetchBlob = async (url, method, payload) => {
  const headers = {
    Accept: 'application/octet-stream',
    'Content-Type': 'application/json'
  };

  const requestBody = getJson(method, payload);

  const response = await fetchRaw(url, method, requestBody, headers);
  const responseBody = await response.blob();

  if (response.status >= 300) {
    const error = new Error(responseBody.message);
    error.response = responseBody;
    error.status = response.status;
    throw error;
  }

  return responseBody;
};

export default fetchJson;
