import types from './types';

const updateRegions = regions => ({
  type: types.UPDATE_REGIONS,
  regions
});

const updateIsLoaded = age => ({
  type: types.UPDATE_LOADED,
  age
});

const setSelectedRegion = selectedRegion => ({
  type: types.SET_SELECTED_REGION,
  selectedRegion
});

export default {
  updateRegions,
  updateIsLoaded,
  setSelectedRegion
};
