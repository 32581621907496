import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import cx from 'classnames';

import Icon from '../Icon/Icon';

import './_style.css';

class HelpButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggleInfo = () => {
    const { open } = this.state;
    this.setState({
      open: !open
    });
  };

  handleClickOutside = () => {
    this.setState({
      open: false
    });
  };

  renderInfo = () => {
    const { open } = this.state;
    const { content } = this.props;
    if (!open) {
      return null;
    }

    return <div className="HelpButton__Info ArrowBox ArrowUp">{content}</div>;
  };

  render() {
    const { className } = this.props;
    const styles = { outline: 'none' };
    const classes = cx('HelpButton', className);

    return (
      // <div className="HelpButton Header__Item Header__Item--right">
      <div className={classes}>
        <div aria-label="Toggle info" style={styles} onClick={this.toggleInfo} role="button" tabIndex="-1">
          <Icon className="HelpButton__Icon" icon="046-information-circle" />
        </div>
        {this.renderInfo()}
      </div>
    );
  }
}

HelpButton.propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};

export default onClickOutside(HelpButton);
