import React from 'react';
import PropTypes from 'prop-types';

import { Select, Option, withTranslation, ChangeDetector } from '../../common';

import './_style.css';

const filterSelectedOptions = options => options
  .filter(({ selected, value }) => selected && value)
  .map(({ value }) => value)
  .sort();

function FormFieldSelect({
  values,
  options,
  multiple,
  placeholder,
  field,
  update,
  optional,
  isSubmitted,
  detectChanges,
  translate
}) {
  const renderOptions = () => options.map(option => (
    <Option value={option.key} key={option.key}>
      {option.name}
    </Option>
  ));

  const onChange = event => {
    const { target } = event;

    const newValue = multiple
      ? (target.options && filterSelectedOptions([...target.options])) || []
      : target.value;

    update(field, newValue);
  };

  const labelText = translate(`changeRequests.fieldNames.${field}`);

  const getOptionalLabel = () => {
    const optionalText = translate('changeRequests.optional');
    return (
      <span>
        {labelText}
        {' '}
        <span className="ChangeRequestFormField__OptionalLabel">
          (
          {optionalText}
          )
        </span>
      </span>
    );
  };

  const label = !optional ? labelText : getOptionalLabel();
  const invalid = isSubmitted && !optional && (!values.new || values.new.length === 0);

  return (
    <div className="ChangeRequestFormField">
      <ChangeDetector values={values} invalid={invalid} detectChanges={detectChanges}>
        <Select
          label={label}
          value={values.new}
          multiple={multiple}
          onChange={onChange}
          placeholder={placeholder}
          error={invalid}
        >
          {renderOptions()}
        </Select>
      </ChangeDetector>
    </div>
  );
}

FormFieldSelect.propTypes = {
  values: PropTypes.shape({
    old: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    new: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string
    })
  ),
  multiple: PropTypes.bool,
  optional: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  detectChanges: PropTypes.bool,
  isSubmitted: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

FormFieldSelect.defaultProps = {
  multiple: false,
  optional: false,
  detectChanges: true
};

export default withTranslation(FormFieldSelect);
