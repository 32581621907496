import actions from './actions';

const { hideError, showError, hideSuccess } = actions;

const showSuccess = success => dispatch => {
  dispatch(actions.showSuccess(success));
  setTimeout(() => {
    dispatch(hideSuccess());
  }, 5000);
};

export default {
  hideError,
  showError,
  hideSuccess,
  showSuccess
};
