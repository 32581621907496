import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../../../common';

import './_style.css';

function RecurrenceExceptionModalLoader({ visible }) {
  if (!visible) {
    return null;
  }

  return (
    <div className="RecurrenceExceptionModalLoader">
      <Loader visible />
    </div>
  );
}

RecurrenceExceptionModalLoader.propTypes = {
  visible: PropTypes.bool
};

export default RecurrenceExceptionModalLoader;
