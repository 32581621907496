import types from './types';

const reset = () => ({
  type: types.RESET
});

const initializeData = changeRequest => ({
  type: types.INITIALIZE_DATA,
  changeRequest
});

const updateFormValue = (field, value) => ({
  type: types.UPDATE_FORM_VALUE,
  field,
  value
});

const updateOwnerIsLoading = isLoading => ({
  type: types.UPDATE_OWNER_ISLOADING,
  isLoading
});

const updateOwnerSuggestions = suggestions => ({
  type: types.UPDATE_OWNER_SUGGESTIONS,
  suggestions
});

const updateIsSubmitting = isSubmitting => ({
  type: types.UPDATE_IS_SUBMITTING,
  isSubmitting
});

const updateSubmitted = () => ({
  type: types.UPDATE_SUBMITTED
});

export default {
  reset,
  initializeData,
  updateFormValue,
  updateOwnerIsLoading,
  updateOwnerSuggestions,
  updateIsSubmitting,
  updateSubmitted
};
