import moment from 'moment';
import first from 'lodash/fp/first';

const nearestMinutes = interval => date => {
  const roundedDate = moment(date);
  const roundedMinutes = Math.round(roundedDate.clone().minute() / interval) * interval;
  return roundedDate
    .clone()
    .minute(roundedMinutes)
    .second(0)
    .toDate();
};

const nearest15min = nearestMinutes(15);

export const getRelativeStartTime = availabilities => {
  const startTime = first(availabilities).start;
  return nearest15min(startTime);
};

export const getRelativeEndTime = (availabilities, duration) => {
  const start = getRelativeStartTime(availabilities);
  const endWithAvailabilities = moment(first(availabilities).end).toDate();

  if (!duration) {
    return endWithAvailabilities;
  }

  const endWithDuration = moment(start)
    .add(duration)
    .toDate();

  if (endWithDuration > endWithAvailabilities) {
    return endWithAvailabilities;
  }

  return endWithDuration;
};

export const getAbsoluteStartTime = availabilities => moment(first(availabilities).start).toDate();

export const getAbsoluteEndTime = availabilities => moment(first(availabilities).end).toDate();
