import React, { Component } from 'react';
import { ReactHeight } from 'react-height';
import PropTypes from 'prop-types';

import { CustomPropTypes } from '../common';
import { BookingRoomCard, BookingResourceCard } from './MyBookingsCard';

import './_style.css';

class MyBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      height: null
    };

    this.onClickOutside = this.onClickOutside.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onHeightReady = this.onHeightReady.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutside, true);
  }

  onClickOutside(event) {
    const { domNode } = this;
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ selected: false });
    }
  }

  onClick() {
    this.setState({ selected: true });
  }

  onHeightReady(mountedHeight) {
    const { height } = this.state;
    if (!height) {
      this.setState({
        height: mountedHeight
      });
    }
  }

  render() {
    const { booking, style, onOpenOccurrence, onOpenMaster } = this.props;
    const { selected, height } = this.state;
    const handleOpenOccurrence = () => onOpenOccurrence(booking);
    const handleOpenMaster = () => onOpenMaster(booking.calendarId);

    const cardStyles = {
      minHeight: height
    };

    const domRef = node => {
      this.domNode = node;
    };

    return (
      <ReactHeight onHeightReady={this.onHeightReady}>
        <div
          className="MyBooking"
          style={style}
          role="button"
          onClick={this.onClick}
          tabIndex="0"
          ref={domRef}
        >
          {booking.resource.resourceType === 'Room' && (
            <BookingRoomCard
              booking={booking}
              selected={selected}
              onOpenOccurrence={handleOpenOccurrence}
              onOpenMaster={handleOpenMaster}
              style={cardStyles}
            />
          )}
          {booking.resource.resourceType === 'Equipment' && (
            <BookingResourceCard
              booking={booking}
              selected={selected}
              onOpenOccurrence={handleOpenOccurrence}
              onOpenMaster={handleOpenMaster}
              style={cardStyles}
            />
          )}
        </div>
      </ReactHeight>
    );
  }
}

MyBooking.propTypes = {
  booking: CustomPropTypes.booking,
  style: PropTypes.shape(),
  onOpenOccurrence: PropTypes.func.isRequired,
  onOpenMaster: PropTypes.func.isRequired
};

export default MyBooking;
