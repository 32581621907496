import React from 'react';
import PropTypes from 'prop-types';

import { BookFormSubject, BookFor, BookFormPeople, BookFormMessage, BookFormReminder } from '.';

import './_style.css';

function BookFormMaster({
  selectedMaster,
  updateTerm,
  updateSelectedPeople,
  fetchPeople,
  attendeesSuggestions,
  attendeesSearchTerm,
  selectedAttendees,
  attendeesLoading,
  bookForPerson,
  bookForLoading,
  updatePerson,
  loadSuggestions,
  bookForSuggestions,
  updateMessage,
  updateSubject,
  updateReminder,
  isMessageValid,
  isSubjectValid
}) {
  return (
    <div className="BookFormMaster">
      <BookFormSubject
        updateSubject={updateSubject}
        isSubjectValid={isSubjectValid}
        subject={selectedMaster.subject}
    />
      <BookFor
        updatePerson={updatePerson}
        loadSuggestions={loadSuggestions}
        person={bookForPerson}
        isLoading={bookForLoading}
        suggestions={bookForSuggestions}
    />
      <BookFormPeople
        term={attendeesSearchTerm}
        people={attendeesSuggestions}
        selectedPeople={selectedAttendees}
        isLoading={attendeesLoading}
        updateTerm={updateTerm}
        updateSelectedPeople={updateSelectedPeople}
        fetchPeople={fetchPeople}
    />
      <BookFormMessage
        updateMessage={updateMessage}
        isMessageValid={isMessageValid}
        message={selectedMaster.message}
        update
    />
      <BookFormReminder reminder={selectedMaster.reminder} updateReminder={updateReminder} />
    </div>
  );
}
BookFormMaster.propTypes = {
  selectedMaster: PropTypes.shape({
    subject: PropTypes.any,
    message: PropTypes.any,
    reminder: PropTypes.any
  }),
  isMessageValid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isSubjectValid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  updateSubject: PropTypes.func.isRequired,
  updateMessage: PropTypes.func.isRequired,
  updateReminder: PropTypes.func.isRequired,
  bookForPerson: PropTypes.shape({}),
  bookForLoading: PropTypes.bool.isRequired,
  bookForSuggestions: PropTypes.arrayOf(PropTypes.shape({})),
  loadSuggestions: PropTypes.func.isRequired,
  updatePerson: PropTypes.func.isRequired,
  updateTerm: PropTypes.func.isRequired,
  updateSelectedPeople: PropTypes.func.isRequired,
  fetchPeople: PropTypes.func.isRequired,
  attendeesSearchTerm: PropTypes.string.isRequired,
  attendeesSuggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedAttendees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  attendeesLoading: PropTypes.bool.isRequired
};

export default BookFormMaster;
