import types from './types';

const toggleFilter = equipment => ({
  type: types.TOGGLE_FILTER,
  name: equipment.key
});

const changeFilter = name => ({
  type: types.CHANGE_FILTER,
  name
});

const resetDirty = () => ({
  type: types.RESET_DIRTY
});

const resetFilter = namespace => ({
  type: types.RESET_FILTER,
  namespace
});

const updateEquipmentTypes = (namespace, resourcesSubTypes) => ({
  type: types.UPDATE_EQUIPMENTTYPES,
  namespace,
  resourcesSubTypes
});

export default {
  toggleFilter,
  changeFilter,
  resetDirty,
  resetFilter,
  updateEquipmentTypes
};
