/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

function RoomIcon({ className }) {
  const styles = {
    fill: 'currentcolor',
    verticalAlign: 'baseline',
    width: '30px',
    height: '30px'
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.42 24"
      style={styles}
      className={className}
    >
      <title>room</title>
      <g id="Calque_2" data-name="Calque 2">
        <g id="Calque_1-2" data-name="Calque 1">
          <circle cx="6.95" cy="12" r="1" />
          <path d="M19.42,22H18.17V4a4,4,0,0,0-4-4h-8a4,4,0,0,0-4,4V22H1a1,1,0,0,0,0,2H19.42a1,1,0,1,0,0-2ZM4.17,22V4a2,2,0,0,1,2-2h8a2,2,0,0,1,2,2V22Z" />
        </g>
      </g>
    </svg>
  );
}

RoomIcon.propTypes = {
  className: PropTypes.string
};

export default RoomIcon;
