import config from '../config';
import fetch from './fetch';

const getUrl = path => `${config.remoteUrl}/${path}`;

async function getCurrent() {
  const url = getUrl('maintenancemessages/current');
  return fetch(url, 'GET');
}

async function put(payload) {
  const url = getUrl('maintenancemessages');
  return fetch(url, 'PUT', payload);
}

async function remove(id) {
  const url = getUrl(`maintenancemessages/${id}`);
  return fetch(url, 'DELETE');
}

async function latest() {
  const url = getUrl('maintenancemessages/latest');
  return fetch(url, 'GET');
}

export default {
  getCurrent,
  put,
  remove,
  latest
};
