import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { alternativesSelectors } from '../../../state/alternatives';
import { withTranslation } from '../../common';
import Accordion from './AlternativeSearchResultsAccordion';
import Section from './AlternativeSearchResultsSection';

import './_style.css';

function AlternativeSearchResults(props) {
  const { isVisible, showSection, hasAlternatives, translate } = props;

  const renderSection = section => {
    const shouldRender = showSection(section) && hasAlternatives(section);
    if (!shouldRender) {
      return null;
    }

    const title = translate(`search.alternativeResults.${section}.title`);
    return (
      <Accordion title={title}>
        <Section type={section} />
      </Accordion>
    );
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="AlternativeSearchResults">
      {renderSection('floor')}
      {renderSection('sector')}
      {renderSection('time')}
      {renderSection('roomequipment')}
      {renderSection('roomtype')}
      {renderSection('capacity')}
    </div>
  );
}

const mapStateToProps = state => ({
  isVisible: alternativesSelectors.isVisible(state),
  hasAlternatives: section => alternativesSelectors.hasAlternatives(section)(state),
  showSection: section => alternativesSelectors.showSection(section)(state)
});

AlternativeSearchResults.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  hasAlternatives: PropTypes.func.isRequired,
  showSection: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const AlternativeSearchResultsComponent = withTranslation(AlternativeSearchResults);
export default connect(mapStateToProps)(AlternativeSearchResultsComponent);
