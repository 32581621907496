// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsItem_item__8Rf1t {\n  color: var(--color-black);\n  min-width: 200px;\n  background-color: var(--color-white);\n  padding: 15px;\n  margin-bottom: 10px;\n  border-bottom: var(--color-gray-20) 1px solid;\n}\n\n.NewsItem_header__rgBlk {\n  font-weight: 700;\n}\n\n.NewsItem_dateText__Q0xLh {\n  font-weight: 500;\n  font-size: 13px;\n}", "",{"version":3,"sources":["webpack://./src/views/Search/SearchDesktop/Content/Now/News/NewsItem.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,oCAAoC;EACpC,aAAa;EACb,mBAAmB;EACnB,6CAA6C;AAC/C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB","sourcesContent":[".item {\n  color: var(--color-black);\n  min-width: 200px;\n  background-color: var(--color-white);\n  padding: 15px;\n  margin-bottom: 10px;\n  border-bottom: var(--color-gray-20) 1px solid;\n}\n\n.header {\n  font-weight: 700;\n}\n\n.dateText {\n  font-weight: 500;\n  font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "NewsItem_item__8Rf1t",
	"header": "NewsItem_header__rgBlk",
	"dateText": "NewsItem_dateText__Q0xLh"
};
export default ___CSS_LOADER_EXPORT___;
