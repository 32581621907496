/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { styles, lineStyles } from './styles';

function SurfaceHubIcon({ disabled, ...otherProps }) {
  const styles1 = {
    fill: 'none',
    stroke: '#000',
    strokeMiterlimit: 10
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 0 30 30" {...otherProps} style={styles}>
      <path d="M5.1,20.4H1a1.08,1.08,0,0,1-1-1V5.4a3,3,0,0,1,3-3H23a3.08,3.08,0,0,1,3,3v14a1.08,1.08,0,0,1-1,1H14.9l-1.2-2H24V5.4a1,1,0,0,0-1-1H3a.94.94,0,0,0-1,1v13H4.4Z" />
      <path d="M11.9,6.2a.55.55,0,0,1,.3-.5,5.54,5.54,0,0,1,6.5,4.1.55.55,0,0,1-.3.5.55.55,0,0,1-.5-.3,4.61,4.61,0,0,0-5.5-3.5C12.1,6.6,11.9,6.5,11.9,6.2Z" />
      <path d="M16.7,10.7a.38.38,0,0,0,.32-.43l0-.07a3.8,3.8,0,0,0-4.5-2.9.38.38,0,0,0-.32.43l0,.07a.51.51,0,0,0,.5.4,3,3,0,0,1,3.5,2.2.38.38,0,0,0,.43.32Z" />
      <path d="M12.9,9a.41.41,0,0,0,.2.8,1.33,1.33,0,0,1,1.5,1,.38.38,0,0,0,.43.32l.07,0c.2-.1.4-.3.3-.5A2.21,2.21,0,0,0,12.9,9Z" />
      <path d="M4.9,10.7" />
      <path
        style={styles1}
        d="M12.1,19.5l.8-1.4a1.17,1.17,0,0,0-.47-1.59h0a1.06,1.06,0,0,0-1.2.2l-.8,1.5"
      />
      <path
        style={styles1}
        d="M13.6,21l.8-1.3a1.19,1.19,0,0,0-.5-1.6h0a.75.75,0,0,0-1,.2l-.8,1.3"
      />
      <path
        style={styles1}
        d="M11,26.5c.8-.5,1.8-1.1,1.8-1.1a24.85,24.85,0,0,0,2.5-4.3A.92.92,0,0,0,15,19.84l-.08,0h0c-.2-.1-.6-.1-.7.1L13.6,21"
      />
      <path
        style={styles1}
        d="M10.3,18.2l3.2-5.4A1.17,1.17,0,0,0,13,11.21h0a.83.83,0,0,0-1.16.15l0,.05L6.9,19.6"
      />
      <path
        style={styles1}
        d="M6.9,19.6,5.7,16.5c-.4-1-1.8-.6-1.4.7S6,23.5,6,23.5m5.1,3.8L9.8,29.5a.53.53,0,0,1-.7.2l-5.2-3a.53.53,0,0,1-.2-.7L5,23.8a.71.71,0,0,1,.95-.33l.05,0,4.9,2.8a.78.78,0,0,1,.2,1l-.2.3"
      />
      <circle cx="6.9" cy="19.6" r="0.5" />
      <circle cx="10.35" cy="18.21" r="0.54" transform="translate(-10.6 18.07) rotate(-60)" />
      <circle cx="12.12" cy="19.52" r="0.52" transform="translate(-10.85 20.25) rotate(-60)" />
      <circle cx="13.61" cy="20.97" r="0.5" transform="translate(-11.35 22.27) rotate(-60)" />
      <path d="M4.4,17.7" />
      {disabled && <line style={lineStyles} x1="4.75" y1="1.5" x2="22.05" y2="29.01" />}
    </svg>
  );
}

SurfaceHubIcon.propTypes = {
  disabled: PropTypes.bool
};

export default SurfaceHubIcon;
