import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { NodeToolbar, Position } from 'reactflow';

function ResourceNode({ data }) {
  return (
    <>
      <NodeToolbar position={Position.Top}>
        <div className="tooltiptext">
          <div>{data.resourceType}</div>
          <div>{data.name}</div>
        </div>
      </NodeToolbar>

      {data && data.debug
        && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: '7px', display: 'flex', height: '10px', marginTop: '-5px' }}>{data.position}</div>
            <div style={{ fontSize: '7px', display: 'flex', height: '10px' }}>{data.positionAbsolute}</div>
            <div style={{ fontSize: '7px' }}>{data.width}</div>
            <div style={{ fontSize: '7px' }}>{data.height}</div>
          </div>
        )}
    </>
  );
}

export function getResourceNode() {
  return {
    id: '',
    type: 'ResourceNode',
    position: { x: 0, y: 0 },
    positionAbsolute: { x: 0, y: 0 },
    parentNode: 'floorMap',
    width: 40,
    height: 20,
    extent: 'parent',
    resizing: false,
    connectable: false,
    style: {
      background: '#A2CDF4',
      borderColor: 'none',
      borderRadius: '5px',
      boxShadow: 'none',
      fontSize: 8,
      width: 40,
      height: 20
    }
  };
}

ResourceNode.propTypes = {
  data: PropTypes.objectOf(PropTypes.any)
};

export default memo(ResourceNode);
