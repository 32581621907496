import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';
import Notification from './Notification';

const icon = (
  <Icon
    icon="239-robot-confused"
    className="Notification__Error-Icon Notification__Error-Icon--spinned"
  />
);

function ValidationSummary({ text, title, onClose }) {
  return <Notification icon={icon} type="error" title={title} text={text} onClose={onClose} />;
}

ValidationSummary.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func
};

export default ValidationSummary;
