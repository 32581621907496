import actions from './actions';
import { favoritesService } from '../../services';
import { notificationsSelectors, notificationsOperations } from '../notifications';

const { setFavorite, resetFavorites } = actions;
const { hideError } = notificationsOperations;

const addFavorite = resourceId => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(hideError());
  dispatch(setFavorite(resourceId, true));

  return favoritesService.post(resourceId).catch(serverError => {
    const error = getNotification('favorite.error', serverError);
    dispatch(notificationsOperations.showError(error));
  });
};

const removeFavorite = resourceId => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(hideError());
  dispatch(setFavorite(resourceId, false));

  return favoritesService.remove(resourceId).catch(serverError => {
    const error = getNotification('favorite.error', serverError);
    dispatch(notificationsOperations.showError(error));
  });
};

export default {
  addFavorite,
  removeFavorite,
  resetFavorites
};
