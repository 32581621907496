import { combineReducers } from 'redux';
import types from './types';

const initialState = {
  happyComment: '',
  sadComment: '',
  submitted: false,
  isSubmitting: false,
  triedToSubmit: false
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_HAPPY_COMMENT: {
      return { ...state, happyComment: action.comment };
    }

    case types.UPDATE_SAD_COMMENT: {
      return { ...state, sadComment: action.comment };
    }

    case types.HAS_TRIED_TO_SUBMIT: {
      return { ...state, triedToSubmit: true };
    }

    case types.SUBMITTING: {
      return { ...state, isSubmitting: action.isSubmitting };
    }

    case types.CLEAR: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default combineReducers({
  feedback: feedbackReducer
});
