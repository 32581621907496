import React from 'react';
import PropTypes from 'prop-types';

import { ValidationSummary, withTranslation } from '../../../../common';

function SearchFormDateValidation({ visible, onClose, translate }) {
  if (!visible) {
    return null;
  }

  const title = translate('search.when.validationTitle');
  const text = translate('search.when.validationText');

  return <ValidationSummary title={title} text={text} onClose={onClose} />;
}

SearchFormDateValidation.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(SearchFormDateValidation);
