import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { searchSelectors } from '../../../../../state/search';
import { timelineBookingsSelectors } from '../../../../../state/timelineBookings';
import { locationsSelectors } from '../../../../../state/locations';
import { withTranslation, EmptyState } from '../../../../common';

import styles from './CalendarEmptyState.module.css';

function CalendarEmptyState(props) {
  const { showDefaultEmptyState, showLocationEmptyState, resourceType, translate } = props;

  const commonProps = {
    className: styles.box,
    visible: true
  };

  const renderDefaultEmptyState = () => {
    const path = resourceType === 'equipment' ? 'equipment' : 'room';
    const text = translate(`search.emptyState.${path}`);

    return <EmptyState content={text} {...commonProps} />;
  };

  const renderLocationEmptyState = () => {
    const text = translate('search.emptyState.location');
    return <EmptyState content={text} {...commonProps} />;
  };

  if (showLocationEmptyState) {
    return renderLocationEmptyState();
  }

  if (showDefaultEmptyState) {
    return renderDefaultEmptyState();
  }

  return null;
}

const mapStateToProps = state => {
  const isLoading = timelineBookingsSelectors.isLoading(state);
  const hasRooms = timelineBookingsSelectors.hasRooms(state);
  const hasLocations = locationsSelectors.hasLocations(state);

  const showDefaultEmptyState = !hasRooms && !isLoading;
  const showLocationEmptyState = !timelineBookingsSelectors.getTotalCount && !hasLocations && !isLoading;

  return {
    showDefaultEmptyState,
    showLocationEmptyState,
    resourceType: searchSelectors.getResourceType(state)
  };
};

CalendarEmptyState.propTypes = {
  showDefaultEmptyState: PropTypes.bool.isRequired,
  showLocationEmptyState: PropTypes.bool.isRequired,
  resourceType: PropTypes.string,
  translate: PropTypes.func.isRequired
};

const CalendarEmptyStateComponent = withTranslation(CalendarEmptyState);

export default connect(mapStateToProps)(CalendarEmptyStateComponent);
