import React from 'react';
import PropTypes from 'prop-types';

import { EmptyState, withTranslation } from '../../common';
import './_style.css';

function BookingCollectionEmptyState({ visible, resourceType, translate }) {
  if (!visible) {
    return null;
  }

  const text = translate(`bookingCollection.emptyState.${resourceType.toLowerCase()}`);

  const content = (
    <div className="BookingCollectionEmptyState__Text">{text}</div>
  );

  return (
    <div className="BookingCollectionEmptyState">
      <EmptyState icon="232-robot-neutral" visible centered content={content} />
    </div>
  );
}

BookingCollectionEmptyState.propTypes = {
  visible: PropTypes.bool.isRequired,
  resourceType: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookingCollectionEmptyState);
