import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CreateResourceSwitch from './CreateResourceSwitch';
import {
  FormFieldBuilding,
  FormFieldChangeRequestFinder,
  FormFieldFilteredText,
  FormFieldTextArea,
  FormFieldReadOnly
} from '../FormElements';
import CreateRoom from './CreateRoom';

import ProfileSwitcher from './ProfileSwitcher';
import CreateEquipment from './CreateEquipment';

class CreateResource extends Component {
  switchForm = event => {
    const { updateFormValue } = this.props;
    updateFormValue('resourceType', event.target.value);
  };

  renderRoomForm = () => {
    const { resourceType } = this.props;
    if (resourceType.new.toLowerCase() !== 'room') {
      return null;
    }
    return <CreateRoom {...this.props} />;
  };

  renderEquipmentForm = () => {
    const { resourceType } = this.props;
    if (resourceType.new.toLowerCase() !== 'equipment') {
      return null;
    }
    return <CreateEquipment {...this.props} />;
  };

  renderCommonFields = () => (
    <>
      {this.renderName()}
      {this.renderBuilding()}
    </>
  );

  renderName = () => {
    const { name, updateFormValue, readOnly, isSubmitted } = this.props;
    if (readOnly) {
      return <FormFieldReadOnly values={name} field="name" text={name.new} />;
    }
    return (
      <FormFieldFilteredText
        values={name}
        update={updateFormValue}
        field="name"
        detectChanges={false}
        isSubmitted={isSubmitted}
      />
    );
  };

  renderBuilding = () => {
    const {
      building,
      buildingSuggestions,
      loadBuildingSuggestions,
      isBuildingLoading,
      readOnly,
      updateFormValue,
      isSubmitted
    } = this.props;

    if (readOnly) {
      return (
        <FormFieldReadOnly
          values={building}
          field="building"
          text={building.new && building.new.name}
        />
      );
    }

    return (
      <FormFieldBuilding
        field="building"
        values={building}
        suggestions={buildingSuggestions}
        loadSuggestions={loadBuildingSuggestions}
        update={updateFormValue}
        isSubmitted={isSubmitted}
        isLoading={isBuildingLoading}
        detectChanges={false}
      />
    );
  };

  renderProfile = () => {
    const { readOnly, ownersProfile2, ownersProfile3, ownersProfile4, profile } = this.props;

    if (readOnly) {
      const owners = (profile.new === '2' ? ownersProfile2 : null)
        || (profile.new === '3' ? ownersProfile3 : null)
        || (profile.new === '4' ? ownersProfile4 : null);

      const ownersList = owners && (
        <ul>
          {owners.new.map(owner => (
            <li key={owner.value}>{owner.name}</li>
          ))}
        </ul>
      );
      return (
        <>
          <FormFieldReadOnly values={profile} field="profile" text={profile.new} />
          {profile.new && profile.new !== '1' && (
            <FormFieldReadOnly values={ownersProfile2} field="owners" text={ownersList} />
          )}
        </>
      );
    }
    return <ProfileSwitcher {...this.props} />;
  };

  renderReason = () => {
    const { reason, isSubmitted, updateFormValue, readOnly } = this.props;

    if (readOnly) {
      return <FormFieldReadOnly values={reason} field="reason" text={reason.new} />;
    }
    return (
      <FormFieldTextArea
        field="reason"
        values={reason}
        update={updateFormValue}
        isSubmitted={isSubmitted}
        detectChanges={false}
      />
    );
  };

  renderValidation = () => {
    const { isSubmitted, isValid, translate } = this.props;
    if (isSubmitted) {
      if (!isValid) {
        const validationError = translate('common.validationError');
        return <div className="CreateResource__ValidationError">{validationError}</div>;
      }
    }

    return null;
  };

  renderForm = () => {
    const { resourceType } = this.props;
    if (!resourceType.new) {
      return null;
    }

    return (
      <>
        <div className="CreateResource__Form">
          {this.renderCommonFields()}
          {this.renderRoomForm()}
          {this.renderEquipmentForm()}
        </div>
        <hr className="CreateResource__Divider" />
        <div className="CreateResource__Form">{this.renderProfile()}</div>
        <hr className="CreateResource__Divider" />
        <div className="CreateResource__Form">
          {this.renderReason()}
          {this.renderValidation()}
        </div>
      </>
    );
  };

  render() {
    const {
      resourceType,
      isDetailView,
      changeRequestInfoSuggestions,
      loadChangeRequestInfoSuggestions,
      isChangeRequestInfoLoading
    } = this.props;

    return (
      <>
        {!isDetailView && (
          <>
            <CreateResourceSwitch value={resourceType.new} switchForm={this.switchForm} />
            <FormFieldChangeRequestFinder
              field="changeRequestInfo"
              suggestions={changeRequestInfoSuggestions}
              loadSuggestions={loadChangeRequestInfoSuggestions}
              isLoading={isChangeRequestInfoLoading}
              detectChanges={false}
            />
            <hr className="CreateResource__Divider" />
          </>
        )}
        {this.renderForm()}
      </>
    );
  }
}

CreateResource.propTypes = {
  updateFormValue: PropTypes.func.isRequired,
  resourceType: PropTypes.shape().isRequired,
  reason: PropTypes.shape().isRequired,
  building: PropTypes.shape().isRequired,
  buildingSuggestions: PropTypes.arrayOf(PropTypes.shape()),
  isBuildingLoading: PropTypes.bool,
  loadBuildingSuggestions: PropTypes.func.isRequired,
  profile: PropTypes.shape().isRequired,
  ownersProfile4: PropTypes.shape().isRequired,
  ownersProfile3: PropTypes.shape().isRequired,
  ownersProfile2: PropTypes.shape().isRequired,
  name: PropTypes.shape().isRequired,
  isSubmitted: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  isDetailView: PropTypes.bool,
  changeRequestInfoSuggestions: PropTypes.arrayOf(PropTypes.shape()),
  isChangeRequestInfoLoading: PropTypes.bool,
  loadChangeRequestInfoSuggestions: PropTypes.func,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

CreateResource.defaultProps = {
  readOnly: false,
  isDetailView: false
};

export default CreateResource;
