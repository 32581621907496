import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from '../../../Header';
import RoomFilter from '../../../RoomFilter';
import EquipmentFilter from '../../../EquipmentFilter';
import { Button, withTranslation } from '../../../common';
import { searchSelectors } from '../../../../state/search';
import { availabilityOperations } from '../../../../state/availability';
import { roomFilterSelectors, roomFilterOperations } from '../../../../state/roomFilter';
import {
  equipmentFilterSelectors,
  equipmentFilterOperations
} from '../../../../state/equipmentFilter';

import './_style.css';

function SearchFormFilter({
    loadAvailabilities,
    history,
    resourceType,
    toggleRoomFilter,
    toggleEquipmentFilter,
    roomFilter,
    equipmentFilter,
    translate
  }) {
  const onSave = () => {
    loadAvailabilities();
    history.goBack();
  };

  const onFilterChange = key => {
    if (resourceType === 'room') {
      toggleRoomFilter(key);
    } else {
      toggleEquipmentFilter(key);
    }
  };

  const renderHeader = () => {
    const title = translate('roomFilter.title');
    return <Header onClose={onSave} title={title} />;
  };

  const renderFilter = () => {
    if (resourceType === 'room') {
      return <RoomFilter filter={roomFilter} onChange={onFilterChange} variant="mobile" />;
    }

    return <EquipmentFilter filter={equipmentFilter} onChange={onFilterChange} />;
  };

  const renderButton = () => {
    const label = translate('common.search');
    return <Button label={label} className="button--fixed" onClick={onSave} />;
  };

  return (
    <div className="SearchFormFilter">
      {renderHeader()}
      {renderFilter()}
      {renderButton()}
    </div>
  );
}

const mapStateToProps = state => ({
  resourceType: searchSelectors.getResourceType(state),
  roomFilter: roomFilterSelectors.getRoomFilters(state),
  equipmentFilter: equipmentFilterSelectors.getEquipment(state)
});

const mapDispatchToProps = dispatch => ({
  loadAvailabilities: () => dispatch(availabilityOperations.loadAvailabilities()),
  toggleRoomFilter: item => dispatch(roomFilterOperations.toggleFilter(item)),
  toggleEquipmentFilter: item => dispatch(equipmentFilterOperations.toggleFilter(item))
});

SearchFormFilter.propTypes = {
  resourceType: PropTypes.string.isRequired,
  roomFilter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  equipmentFilter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  toggleRoomFilter: PropTypes.func.isRequired,
  toggleEquipmentFilter: PropTypes.func.isRequired,
  loadAvailabilities: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const SearchFormFilterComponent = withTranslation(withRouter(SearchFormFilter));
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFormFilterComponent);
