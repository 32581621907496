import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { CustomPropTypes } from '../../common';
import RecurrenceExceptionModalHeader from './RecurrenceExceptionModalHeader';
import RecurrenceExceptionModalLoader from './RecurrenceExceptionModalLoader';
import RecurrenceExceptionModalEmptyState from './RecurrenceExceptionModalEmptyState';
import AlternativeSearchResults from './AlternativeSearchResults';
import RecurrenceExceptionSidebar from './RecurrenceExceptionSidebar';

import { getFirstSelected, markOneSelected } from './resourceSelectionUtil';

import './_style.css';
import '../_style.css';

function RecurrenceExceptionModalDesktop(props) {
  const {
    exception,
    isLastException,
    isLoading,
    isInProgress,
    showEmptyState,
    onClose,
    onNext,
    onSelect,
    onBook
  } = props;

  const { start } = exception;

  // eslint-disable-next-line react/destructuring-assignment
  const resources = markOneSelected(props.resources);
  const selectedResource = getFirstSelected(resources);

  return (
    <>
      <RecurrenceExceptionModalHeader date={start} onClose={onClose} />

      <div className="RecurrenceExceptionModalDesktop">
        <RecurrenceExceptionModalLoader visible={isLoading} />
        <RecurrenceExceptionModalEmptyState
          visible={showEmptyState}
          onClick={onNext}
          isLastException={isLastException}
        />

        {resources.length > 0 && (
          <div className="RecurrenceExceptionModalDesktop__Results">
            <div className="RecurrenceExceptionModalDesktop__Panel">
              <Scrollbars style={{ height: '100%' }} autoHide>
                <AlternativeSearchResults resources={resources} onSelect={onSelect} />
              </Scrollbars>
            </div>
            <div className="RecurrenceExceptionModalDesktop__Panel">
              <RecurrenceExceptionSidebar
                resource={selectedResource}
                onBook={onBook}
                isInProgress={isInProgress}
                isLastException={isLastException}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

RecurrenceExceptionModalDesktop.propTypes = {
  exception: CustomPropTypes.exception,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      resource: CustomPropTypes.resource,
      selected: PropTypes.bool
    })
  ),
  isLastException: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  showEmptyState: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onBook: PropTypes.func.isRequired
};

export default RecurrenceExceptionModalDesktop;
