import React from 'react';

import { Profile3, Profile2, CustomPropTypes } from '../../../../common';

import { isProfile2, isProfile3 } from '../../../../../util';

import './_style.css';

function ProfileInformation({ resource }) {
  const getProfile3ResourcePath = () => {
    const { resourceType } = resource;
    return resourceType === 'Room' ? 'search.detail.profile3.room' : 'search.detail.profile3.equipment';
  };

  const renderProfileInformation = () => {
    const profile2 = isProfile2(resource);
    const profile3 = isProfile3(resource);
    if (profile2) {
      return <Profile2 resource={resource} />;
    }
    if (profile3) {
      return <Profile3 resource={resource} path={getProfile3ResourcePath()} />;
    }
    return null;
  };

  return (
    <div>
      <div className="ProfileInformation">
        {renderProfileInformation()}
      </div>
    </div>
  );
}

ProfileInformation.propTypes = {
  resource: CustomPropTypes.resource
};

export default ProfileInformation;
