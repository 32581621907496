import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import omit from 'lodash/fp/omit';
import identifier from '../identifier';

import SelectDropdown from './SelectDropdown';

import './_style.css';

const isOptionEqual = (option, other) => option && other && option.key === other.key;
const selectedOptions = ({ selected }) => selected;
const textProp = ({ text }) => text;

const select = option => {
  option.select();
  return { ...option, selected: true };
};

const unselect = option => {
  option.unselect();
  return { ...option, selected: false };
};

class Select extends Component {
  constructor(props) {
    super(props);

    const { id, label, value, defaultValue, placeholder } = props;
    this.state = {
      options: [],
      value: value || defaultValue,
      placeholder,
      isOpen: false,
      id: id || identifier(label)
    };

    this.addOption = this.addOption.bind(this);
    this.removeOption = this.removeOption.bind(this);
    this.getPlaceholder = this.getPlaceholder.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  getChildContext() {
    return {
      select: {
        addOption: this.addOption,
        removeOption: this.removeOption
      }
    };
  }

  UNSAFE_componentWillReceiveProps({ value: newValue, multiple }) {
    setTimeout(() => {
      const { options: oldOptions, value: oldValue } = this.state;

      if (newValue !== oldValue) {
        const values = multiple ? newValue : [newValue];
        const options = oldOptions.map(option => {
          const selected = values.some(val => val === option.value);
          return selected ? select(option) : unselect(option);
        });
        const placeholder = this.getPlaceholder(options);
        this.setState({ options, placeholder, value: newValue });
      }
    }, 0);
  }

  onSelect(event, selectedOption) {
    event.preventDefault();
    if (!selectedOption) {
      return;
    }

    if (selectedOption.disabled) {
      return;
    }

    const { onChange, multiple } = this.props;
    const { options: prevOptions } = this.state;

    const options = prevOptions.map(option => {
      if (isOptionEqual(option, selectedOption)) {
        if (!option.selected) {
          return select(option);
        }

        if (multiple && option.selected) {
          return unselect(option);
        }
      }

      if (!multiple && option.selected) {
        return unselect(option);
      }

      return option;
    });

    const getValue = () => {
      const selected = options.filter(selectedOptions);
      if (multiple) {
        return selected.map(({ value }) => value);
      }
      return selected.length ? selected[0] : '';
    };

    this.setState({
      placeholder: this.getPlaceholder(options),
      value: getValue(),
      options
    });

    if (onChange) {
      onChange({ ...event, target: this.selectRef, selectedOption });
    }

    this.openByFocus = false;
    if (!multiple) {
      this.toggle();
    }
  }

  onFocus() {
    const { disabled } = this.props;
    if (!disabled) {
      this.openByFocus = true;
      this.setState({ isOpen: true });
      setTimeout(() => {
        this.openByFocus = false;
        return false;
      }, 400);
    }
  }

  onBlur() {
    const { disabled } = this.props;
    const { options: prevOptions } = this.state;
    const options = prevOptions.map(option => ({ ...option, focused: false }));
    if (!disabled) {
      this.setState({ isOpen: false, options });
    }
  }

  onKeyDown(focusedOption) {
    const { options: prevOptions } = this.state;
    const focused = prevOptions.find(o => o.focused);
    const options = prevOptions.map(option => {
      if (isOptionEqual(option, focused)) {
        return { ...option, focused: false };
      }

      if (isOptionEqual(option, focusedOption)) {
        return { ...option, focused: true };
      }

      return option;
    });

    this.setState({
      options
    });
  }

  getPlaceholder(options = []) {
    const { multiple, placeholder: defaultPlaceholder } = this.props;

    const selected = options.filter(selectedOptions);
    if (!selected.length) {
      return defaultPlaceholder;
    }

    return multiple ? selected.map(textProp).join(', ') : selected[0].text;
  }

  toggle() {
    const { isOpen } = this.state;
    const { disabled } = this.props;
    if (!disabled && !this.openByFocus) {
      this.setState({ isOpen: !isOpen });
    }
  }

  addOption(option) {
    const { multiple } = this.props;
    const updateOption = value => {
      if (multiple) {
        const isSelected = option.selected || value.some(val => val === option.value) || option.selected;
        return isSelected ? select(option) : unselect(option);
      }
      return value === option.value ? { ...option, selected: true } : option;
    };

    if (option) {
      this.setState(({ options, value }) => {
        const newOptions = [...options, updateOption(value)];
        const newPlaceholder = this.getPlaceholder(newOptions);
        return { options: newOptions, placeholder: newPlaceholder };
      });
    }
  }

  removeOption(option) {
    if (option) {
      this.setState(previousState => ({
        options: previousState.options.filter(o => o && option && o.value !== option.props.value)
      }));
    }
  }

  renderSelect(id, value, tabIndex, children, props) {
    const selectRef = ref => {
      this.selectRef = ref;
    };
    const optionRef = ref => {
      this.emptyOptionRef = ref;
    };

    return (
      <select id={id} tabIndex={tabIndex} ref={selectRef} {...props}>
        <option value="" ref={optionRef} aria-label={id} />
        {children}
      </select>
    );
  }

  render() {
    const { id, value, options, isOpen, placeholder } = this.state;
    const { label, children, disabled, tabIndex, className, style, error, ...props } = this.props;
    const otherProps = omit(['id', 'placeholder', 'value'], props);

    const classes = cx(
      'select',
      {
        'select--open': isOpen,
        'select--closed': !isOpen,
        'select--disabled': disabled
      },
      className
    );

    // sorted by text asc
    options.sort((a, b) => {
      if (a.text > b.text) return 1;
      if (a.text < b.text) return -1;
      return 0;
    });

    const mergedStyles = { ...style, cursor: 'auto' };
    return (
      <div className={classes} style={mergedStyles}>
        {label && <label htmlFor={id}>{label}</label>}
        {this.renderSelect(id, value, tabIndex, children, otherProps)}

        <SelectDropdown
          value={placeholder}
          options={options}
          disabled={disabled}
          tabIndex={tabIndex}
          error={error}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onClick={this.toggle}
          onSelect={this.onSelect}
          onKeyDown={this.onKeyDown}
        />
      </div>
    );
  }
}

Select.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.objectOf(PropTypes.any)
  ]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  className: PropTypes.string,
  style: PropTypes.shape()
};

Select.childContextTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  select: PropTypes.any
};

Select.defaultProps = {
  style: {},
  multiple: false,
  error: false,
  tabIndex: '-1'
};

export default Select;
