import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function SelectDropdownItem({ text, disabled, selected, focused, onSelect }) {
  const classes = classNames('dropdown-item', {
    'dropdown-item--disabled': disabled,
    'dropdown-item--selected': selected,
    'dropdown-item--focused': focused
  });

  return (
    <button className={classes} onClick={onSelect} type="button">
      {text}
    </button>
  );
}

SelectDropdownItem.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  focused: PropTypes.bool,
  onSelect: PropTypes.func.isRequired
};

export default SelectDropdownItem;
