import store from 'store2';

import actions from './actions';
import { roomFilterOperations } from '../roomFilter';
import { equipmentFilterOperations } from '../equipmentFilter';
import { notificationsOperations, notificationsSelectors } from '../notifications';
import { resourcesSubTypesService } from '../../services';

const persistData = dispatch => resourcesSubTypes => {
  store('resourcesSubTypes', resourcesSubTypes);
  dispatch(actions.updateResourcesSubTypes(resourcesSubTypes));
  dispatch(equipmentFilterOperations.updateEquipmentTypes('book', resourcesSubTypes));
  dispatch(roomFilterOperations.updateRoomTypes('book', resourcesSubTypes));
  dispatch(actions.updateIsLoaded(Date.now()));
};

const loadResourcesSubTypes = () => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const persist = persistData(dispatch);

  return resourcesSubTypesService
    .get()
    .then(response => {
      // Todo: use 'normalizeData' to format as an array (see regions/operations.js line 38)
      persist(response);
    })
    .catch(serverError => {
      const error = getNotification('admin.settings.resourcesSubTypes.get.error', serverError);
      dispatch(notificationsOperations.showError(error));
    });
};

const addResourcesSubType = (
    color,
    resourceType,
    nameEN,
    nameFR,
    nameDE,
    nameIT,
    width,
    height
  ) => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);

  return resourcesSubTypesService
    .addResourcesSubType({
      Color: color,
      ResourceType: resourceType,
      NameEN: nameEN,
      NameFR: nameFR,
      NameDE: nameDE,
      NameIT: nameIT,
      width,
      height
     })
    .then(() => {
      const persist = persistData(dispatch, state);
      return resourcesSubTypesService
        .get()
        .then(response => {
          const success = getNotification('admin.settings.resourcesSubTypes.add.success');
          dispatch(notificationsOperations.showSuccess(success));
          persist(response);
          return 'Ok';
        })
        .catch(serverError => {
          const error = getNotification('admin.settings.resourcesSubTypes.add.error', serverError);
          dispatch(notificationsOperations.showError(error));
          return 'Error';
        });
    })
    .catch(serverError => {
      const error = getNotification('admin.settings.resourcesSubTypes.add.error', serverError);
      dispatch(notificationsOperations.showError(error));
      return 'Error';
    });
};

const updateResourcesSubType = (
    id,
    color,
    resourceType,
    nameEN,
    nameFR,
    nameDE,
    nameIT,
    width,
    height
  ) => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return resourcesSubTypesService
    .updateResourcesSubType(id, {
      Color: color,
      ResourceType: resourceType,
      NameEN: nameEN,
      NameFR: nameFR,
      NameDE: nameDE,
      NameIT: nameIT,
      width,
      height
     })
    .then(() => {
      const persist = persistData(dispatch, state);
      return resourcesSubTypesService
        .get()
        .then(response => {
          const success = getNotification('admin.settings.resourcesSubTypes.update.success');
          dispatch(notificationsOperations.showSuccess(success));
          persist(response);
          return 'Ok';
        })
        .catch(serverError => {
          const error = getNotification('admin.settings.resourcesSubTypes.update.error', serverError);
          dispatch(notificationsOperations.showError(error));
          return 'Error';
        });
    })
    .catch(serverError => {
      const error = getNotification('admin.settings.resourcesSubTypes.update.error', serverError);
      dispatch(notificationsOperations.showError(error));
      return 'Error';
    });
};

const deleteResourcesSubType = () => (dispatch, getState) => {
  const state = getState();
  const { selectedResourcesSubType } = state.resourcesSubTypesState.resourcesSubTypes;

  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return resourcesSubTypesService
    .remove(selectedResourcesSubType.id)
    .then(async () => {
      const success = getNotification('admin.settings.resourcesSubTypes.delete.success');
      dispatch(notificationsOperations.showSuccess(success));
      const resourcesSubTypes = await resourcesSubTypesService.get();
      dispatch(actions.updateResourcesSubTypes(resourcesSubTypes));
      store('resourcesSubTypes', resourcesSubTypes);

      if (selectedResourcesSubType.resourceType === 'Equipment') {
        dispatch(equipmentFilterOperations.updateEquipmentTypes('book', resourcesSubTypes));
      }
      if (selectedResourcesSubType.resourceType === 'Room') {
        dispatch(roomFilterOperations.updateRoomTypes('book', resourcesSubTypes));
      }
      return Promise.resolve();
    })
    .catch(serverError => {
      const error = getNotification('admin.settings.resourcesSubTypes.delete.error', serverError);
      error.text = serverError.message;
      dispatch(notificationsOperations.showError(error));
      return Promise.resolve(serverError);
    });
};

const setSelectedResourcesSubType = resourcesSubType => dispatch => {
  dispatch(actions.setSelectedResourcesSubType(resourcesSubType));
};

export default {
  loadResourcesSubTypes,
  addResourcesSubType,
  updateResourcesSubType,
  deleteResourcesSubType,
  setSelectedResourcesSubType
};
