import { createSelector } from 'reselect';

const isLoading = state => state.myChangeRequestsState.isLoading;
const getChangeRequests = state => state.myChangeRequestsState.changeRequests;

const hasChangeRequests = createSelector(
  getChangeRequests,
  changeRequests => changeRequests != null && changeRequests.length > 0
);

export default {
  isLoading,
  getChangeRequests,
  hasChangeRequests
};
