import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, Button, withTranslation, Spacing } from '../../../common';
import { modalOperations } from '../../../../state/modal';
import Header from '../../../Header';
import keys from './modalKeys';
import { buildingsOperations, buildingsSelectors } from '../../../../state/buildings';

class BuildingsDeleteDialog extends Component {
  confirmedDeleteBuilding = () => {
    const { clearModals, deleteBuilding } = this.props;
    deleteBuilding();
    clearModals();
  };

  renderDialogContent = () => {
    const { translate, popDialog, selectedBuilding } = this.props;

    const title = `${ translate('admin.settings.buildings.delete.title') } ${ selectedBuilding?.name }`;
    const text = `${ translate('admin.settings.buildings.delete.confirm') } ${ selectedBuilding?.key } ?`;
    const yes = translate('common.yes');
    const no = translate('common.no');

    return (
      <div>
        <Header title={title} onClose={popDialog} icon="022-close" iconRight fixed={false} />
        <div className="BuildingFormField">
          <Spacing horizontal={1} vertical={1}>
            <div>
              {text}
            </div>
          </Spacing>
          <div className="Delete_Buttons_Container">
            <Button
              className="BuildingsDelete__Button"
              label={no}
              onClick={popDialog}
              secondary
            />
            <Button
              className="BuildingsDelete__Button"
              label={yes}
              onClick={this.confirmedDeleteBuilding}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { popDialog } = this.props;

    return (
      <Dialog dialogKey={keys.BUILDING_DELETE_DIALOG} onOverlayClick={popDialog}>
        {this.renderDialogContent()}
      </Dialog>
    );
  }
}

BuildingsDeleteDialog.propTypes = {
  popDialog: PropTypes.func.isRequired,
  clearModals: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  selectedBuilding: PropTypes.objectOf({}),
  deleteBuilding: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  selectedBuilding: buildingsSelectors.getSelectedBuilding(state)
});

const mapDispatchToProps = dispatch => ({
  popDialog: () => dispatch(modalOperations.popDialog()),
  clearModals: () => dispatch(modalOperations.clearModals()),
  deleteBuilding: () => dispatch(buildingsOperations.deleteBuilding())
});

const BuildingsDeleteDialogComponent = withTranslation(BuildingsDeleteDialog);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildingsDeleteDialogComponent);
