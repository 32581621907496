import actions from './actions';
import selectors from './selectors';
import { changeRequestsService } from '../../services';

import { notificationsSelectors, notificationsOperations } from '../notifications';
import { modalOperations } from '../modal';

const { updateChangeRequest, updateIsLoading, updateIsProcessing, updateProcessed } = actions;

const loadChangeRequest = id => async (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);

  dispatch(notificationsOperations.hideError());
  dispatch(updateIsLoading(true));

  try {
    const resource = await changeRequestsService.getById(id);
    resource.building = resource.buildingSelection;

    dispatch(updateChangeRequest(resource));
    dispatch(updateIsLoading(false));
    return resource;
  } catch (serverError) {
    const error = getNotification('changeRequests.load.error', serverError);
    dispatch(notificationsOperations.showError(error));
    dispatch(updateIsLoading(false));
    return false;
  }
};

const approve = () => async (dispatch, getState) => {
  const state = getState();

  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  dispatch(updateProcessed('approve', true));

  if (!selectors.isValid(state)) {
    return false;
  }

  dispatch(updateIsProcessing('approve', true));
  let succeeded = false;

  try {
    const changeRequest = selectors.getChangeRequest(state);
    const { changeRequestType, id } = changeRequest;
    const type = changeRequestType === 'NewResource' ? 'new' : changeRequestType;
    const values = selectors.getValues(state);
    if (values.building) {
      values.building = values.building.value;
    }
    await changeRequestsService.approve(type, id, values);
    succeeded = true;
  } catch (serverError) {
    const error = getNotification('changeRequests.approve.error', serverError);
    dispatch(notificationsOperations.showError(error));
    succeeded = false;
  }

  dispatch(updateIsProcessing('approve', false));
  return succeeded;
};

const reject = () => dispatch => {
  dispatch(notificationsOperations.hideError());
  dispatch(modalOperations.pushModal(selectors.getRejectModalKey()));
};

const process = action => () => async (dispatch, getState) => {
  const state = getState();

  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  dispatch(updateProcessed(action, true));
  dispatch(updateIsProcessing(action, true));
  let succeeded = false;

  const changeRequest = selectors.getChangeRequest(state);
  const { changeRequestType, id } = changeRequest;
  const type = changeRequestType === 'NewResource' ? 'new' : changeRequestType;
  const { discard, complete } = changeRequestsService;
  const call = action === 'discard' ? discard : complete;

  try {
    await call(type, id);
    succeeded = true;
  } catch (serverError) {
    const error = getNotification('common.error', serverError);
    dispatch(notificationsOperations.showError(error));
    succeeded = false;
  }

  dispatch(updateIsProcessing(action, false));
  return succeeded;
};

const submitReject = reason => async (dispatch, getState) => {
  const state = getState();

  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  dispatch(updateIsProcessing('reject', true));
  let succeeded = false;

  try {
    const changeRequest = selectors.getChangeRequest(state);
    const { changeRequestType, id } = changeRequest;
    const type = changeRequestType === 'NewResource' ? 'new' : changeRequestType;
    const payload = { rejectionReason: reason };
    await changeRequestsService.reject(type, id, payload);
    dispatch(modalOperations.clearModals());
    succeeded = true;
  } catch (serverError) {
    const error = getNotification('changeRequests.reject.error', serverError);
    dispatch(notificationsOperations.showError(error));
    succeeded = false;
  }

  dispatch(updateIsProcessing('reject', false));
  return succeeded;
};

const abortReject = () => dispatch => {
  dispatch(modalOperations.clearModals());
};

export default {
  loadChangeRequest,
  approve,
  reject,
  discard: process('discard'),
  complete: process('complete'),
  submitReject,
  abortReject
};
