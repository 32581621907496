import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import isEqual from 'lodash/fp/isEqual';

import { ShowAt, CustomPropTypes } from '../common';

import { bookSelectors } from '../../state/book';
import {
  bookingCollectionOperations,
  bookingCollectionSelectors
} from '../../state/bookingCollection';
import { recurrenceOperations } from '../../state/recurrence';
import { roomFilterOperations } from '../../state/roomFilter';
import { equipmentFilterOperations } from '../../state/equipmentFilter';
import { modalOperations } from '../../state/modal';
import { locationsOperations } from '../../state/locations';
import {
  makeSelectable,
  select,
  getSelected,
  isSameResource
} from '../BookModal/RecurrenceExceptionModal/resourceSelectionUtil';

import BookingCollectionDesktop from './BookingCollectionDesktop';

class BookingCollection extends Component {
  constructor(props) {
    super(props);

    const { resources } = this.props;
    this.state = {
      selectedResources: this.filterResources(makeSelectable(resources))
    };
  }

  UNSAFE_componentWillMount() {
    const {
      time,
      resource,
      prepareFilters,
      resourceType,
      setFakeModalActive,
      history
    } = this.props;
    if (!resource || time.from === '' || time.to === '') {
      history.replace('/');
      return;
    }
    setFakeModalActive();
    prepareFilters(time, resourceType);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedResources } = this.state;
    const { resources } = this.props;
    const { resources: nextResources } = nextProps;

    const willResourcesChange = !isEqual(resources, nextResources);
    if (willResourcesChange) {
      const selectedResource = getSelected(selectedResources);
      const filteredResources = this.filterResources(nextResources);
      const updatedResources = makeSelectable(filteredResources);
      this.setState({
        selectedResources: select(updatedResources, selectedResource)
      });
    }
  }

  componentWillUnmount() {
    const { resetFilters } = this.props;
    resetFilters();
  }

  filterResources = resources => {
    const { selectedResources, resource: mainResource } = this.props;

    return resources.filter(resource => {
      const sameId = ({ id }) => resource.id === id;
      const isMainResource = sameId(mainResource);
      if (isMainResource) {
        return false;
      }

      const selectedResource = selectedResources.find(sameId);
      if (!selectedResource) {
        return true;
      }

      return !isSameResource(selectedResource, resource);
    });
  };

  selectResource = resource => {
    const { selectedResources } = this.state;
    this.setState({
      selectedResources: select(selectedResources, resource)
    });
  };

  addResource = resource => {
    const { addResource, disableRecurrence, history } = this.props;
    addResource(resource);
    disableRecurrence();
    history.goBack();
  };

  render() {
    const { selectedResources } = this.state;
    return (
      <div className="BookingCollectionPage">
        <ShowAt breakpoint="900AndAbove">
          <BookingCollectionDesktop
            {...this.props}
            resources={selectedResources}
            selectResource={this.selectResource}
            addResource={this.addResource}
          />
        </ShowAt>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resource: bookSelectors.getSelectedResource(state),
  resources: [
    ...bookingCollectionSelectors.getResources(state),
    ...bookingCollectionSelectors.getAlternativeResources(state)
  ],
  hasMoreResults: bookingCollectionSelectors.hasMoreResults(state),
  time: bookSelectors.getTime(state),
  isLoading: bookingCollectionSelectors.areResourcesLoading(state),
  visibleAlternatives: bookingCollectionSelectors.visibleAlternatives(state),
  selectedResources: bookingCollectionSelectors.getSelectedResources(state),
  date: bookSelectors.getDate(state)
});

const mapDispatchToProps = dispatch => ({
  prepareFilters: (time, resourceType) => {
    dispatch(bookingCollectionOperations.updateTime(time));
    dispatch(equipmentFilterOperations.changeFilter('bookingCollection'));
    dispatch(roomFilterOperations.changeFilter('bookingCollection'));
    dispatch(equipmentFilterOperations.resetFilter('bookingCollection'));
    dispatch(roomFilterOperations.resetFilter('bookingCollection'));
    dispatch(locationsOperations.setToDefault());
    dispatch(locationsOperations.cacheLocationState());
    dispatch(bookingCollectionOperations.loadResources(resourceType));
    dispatch(bookingCollectionOperations.loadAlternatives(resourceType));
  },
  resetFilters: () => dispatch(locationsOperations.resetToCache()),
  addResource: resource => dispatch(bookingCollectionOperations.addResource(resource)),
  loadMore: resourceType => dispatch(bookingCollectionOperations.loadMoreResources(resourceType)),
  disableRecurrence: () => dispatch(recurrenceOperations.disableRecurrence()),
  setFakeModalActive: () => dispatch(modalOperations.setFakeModalState(true))
});

BookingCollection.propTypes = {
  time: CustomPropTypes.time,
  resource: CustomPropTypes.resource,
  resources: PropTypes.arrayOf(CustomPropTypes.resource),
  selectedResources: PropTypes.arrayOf(CustomPropTypes.resource),
  resourceType: PropTypes.string.isRequired,
  prepareFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  addResource: PropTypes.func.isRequired,
  disableRecurrence: PropTypes.func.isRequired,
  setFakeModalActive: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BookingCollection)
);
