import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import BackLink from './BackLink';
import CloseLink from './CloseLink';
import HeaderTitle from './HeaderTitle';

import './_styles.css';

function Header({
  title,
  backlink,
  onClose,
  icon,
  iconRight,
  fixed,
  dynamic,
  fixedMobile,
  children
}) {
  const classes = cx('Header', {
    'Header--fixed': fixed,
    'Header--dynamic': dynamic,
    'HeaderMobile--fixed': fixedMobile
  });
  return (
    <div className={classes}>
      {backlink && <BackLink />}
      {onClose && <CloseLink onClose={onClose} icon={icon} iconRight={iconRight} />}
      {title && <HeaderTitle title={title} />}
      {children}
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.any,
  backlink: PropTypes.bool,
  onClose: PropTypes.func,
  icon: PropTypes.string,
  iconRight: PropTypes.bool,
  fixed: PropTypes.bool,
  dynamic: PropTypes.bool,
  fixedMobile: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
};

Header.defaultProps = {
  backlink: false,
  iconRight: false,
  fixed: true
};

export default Header;
