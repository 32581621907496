import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withTranslation } from '../../../common';
import {
  deleteResourceSelectors,
  deleteResourceOperations
} from '../../../../state/deleteResource';

import DeleteForm from './DeleteForm';

import './_style.css';

class DeleteDetail extends Component {
  UNSAFE_componentWillMount() {
    const { changeRequest, updateReason } = this.props;
    const { reason } = changeRequest;
    updateReason(reason);
  }

  render() {
    const { changeRequest } = this.props;
    const { readOnly, status } = changeRequest;

    const isReadonly = readOnly || status === 'Approved' || status === 'Rejected';

    return (
      <div className="DeleteForm">
        <DeleteForm {...this.props} readOnly={isReadonly} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reason: deleteResourceSelectors.getReason(state),
  isValid: deleteResourceSelectors.isValid(state)
});

const mapDispatchToProps = dispatch => ({
  submit: () => dispatch(deleteResourceOperations.submit()),
  updateReason: value => dispatch(deleteResourceOperations.updateReason(value))
});

DeleteDetail.propTypes = {
  changeRequest: PropTypes.shape().isRequired,
  updateReason: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired,
  submit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const DeleteDetailComponent = withRouter(withTranslation(DeleteDetail));
export default connect(mapStateToProps, mapDispatchToProps)(DeleteDetailComponent);
