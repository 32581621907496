import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../common';
import SidebarFormField from './SidebarFormField';

function SidebarResourceType({ resourceType, translate, type }) {
  const roomTypeName = translate(`${type}.${resourceType.toLowerCase()}`);
  return <SidebarFormField label="equipment.type" element={roomTypeName} />;
}

SidebarResourceType.propTypes = {
  resourceType: PropTypes.string,
  translate: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['equipment', 'roomTypes'])
};

export default withTranslation(SidebarResourceType);
