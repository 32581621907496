import { createSelector } from 'reselect';
import { buildingsSelectors } from '../buildings';

const getBuildings = state => buildingsSelectors.getBuildings(state);
const getResource = state => state.editResourceState.resource;
const isResourceLoading = state => state.editResourceState.loadingResource;

const getResourceWithBuilding = createSelector(
  [getResource, getBuildings],
  (resource, buildings) => {
    if (resource) {
      return { ...resource, building: buildings.find(building => building.id === resource.buildingId) };
    }
    return resource;
  }
);

export default {
  getResource: getResourceWithBuilding,
  isResourceLoading
};
