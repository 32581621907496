// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WelcomeModal_modal__dgdkv {\n  width: 100%;\n  height: 100%;\n  min-height: 100%;\n  position: relative;\n  overflow-x: scroll;\n  -webkit-overflow-scrolling: touch;\n}\n\n.WelcomeModal_content__Kt4fg {\n  padding-top: 20px;\n  width: 80%;\n  margin: 0 auto;\n  min-height: calc(100% - 60px);\n}\n\n.WelcomeModal_head__d9Q-r {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n}\n\n.WelcomeModal_sections__lIqpA {\n  margin-top: 20px;\n  margin-bottom: 30px;\n}\n\n.WelcomeModal_section__eoFml {\n  margin-bottom: 20px;\n}\n\n.WelcomeModal_subheader__qgxLk {\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://./src/views/Welcome/WelcomeModal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,cAAc;EACd,6BAA6B;AAC/B;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".modal {\n  width: 100%;\n  height: 100%;\n  min-height: 100%;\n  position: relative;\n  overflow-x: scroll;\n  -webkit-overflow-scrolling: touch;\n}\n\n.content {\n  padding-top: 20px;\n  width: 80%;\n  margin: 0 auto;\n  min-height: calc(100% - 60px);\n}\n\n.head {\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n}\n\n.sections {\n  margin-top: 20px;\n  margin-bottom: 30px;\n}\n\n.section {\n  margin-bottom: 20px;\n}\n\n.subheader {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "WelcomeModal_modal__dgdkv",
	"content": "WelcomeModal_content__Kt4fg",
	"head": "WelcomeModal_head__d9Q-r",
	"sections": "WelcomeModal_sections__lIqpA",
	"section": "WelcomeModal_section__eoFml",
	"subheader": "WelcomeModal_subheader__qgxLk"
};
export default ___CSS_LOADER_EXPORT___;
