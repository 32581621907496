import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import Header from '../../../../Header';
import { withTranslation, Icon, RoomIcon } from '../../../../common';
import { availabilityOperations } from '../../../../../state/availability';
import { searchSelectors, searchOperations } from '../../../../../state/search';

import './_style.css';

class SearchFormSwitch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false
    };
  }

  toggleVisibility = () => {
    const { isVisible } = this.state;
    this.setState({ isVisible: !isVisible });
  };

  switch = type => {
    const { resourceType, switchResourceType, loadAvailabilities } = this.props;
    if (resourceType !== type) {
      switchResourceType();
      loadAvailabilities();
    }
    this.setState({ isVisible: false });
  };

  render() {
    const { translate, resourceType } = this.props;
    const { isVisible } = this.state;
    const title = translate(`search.mobileTitle.${resourceType}`);
    const classes = cx('SearchFormSwitch', {
      'SearchFormSwitch--closed': !isVisible
    });

    return (
      <div>
        <Header>
          <div className="Header__Title">
            <button onClick={this.toggleVisibility} type="button">
              {title}
              <Icon
                icon={isVisible ? '016-chevron-up' : '013-chevron-down'}
                className="SearchFormSwitchIcon"
              />
            </button>
          </div>
          <div className={classes}>
            <button
              aria-label="Switch room"
              className={cx('SearchFormSwitchRoom', {
                'SearchFormSwitchIcon--active': resourceType === 'room'
              })}
              onClick={() => this.switch('room')}
              type="button"
            >
              <RoomIcon className="SearchFormSwitchRoom--icon" />
            </button>
            <button
              aria-label="Switch equipment"
              className={cx('SearchFormSwitchEquipment', {
                'SearchFormSwitchIcon--active': resourceType === 'equipment'
              })}
              onClick={() => this.switch('equipment')}
              type="button"
            >
              <Icon icon="097-suitcase" className="SearchFormSwitchEquipment--icon" />
            </button>
          </div>
        </Header>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resourceType: searchSelectors.getResourceType(state)
});

const mapDispatchToProps = dispatch => ({
  switchResourceType: () => dispatch(searchOperations.switchResourceType()),
  loadAvailabilities: () => dispatch(availabilityOperations.loadAvailabilities())
});

SearchFormSwitch.propTypes = {
  translate: PropTypes.func.isRequired,
  resourceType: PropTypes.string.isRequired,
  switchResourceType: PropTypes.func.isRequired,
  loadAvailabilities: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(SearchFormSwitch));
