import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ShowAt, CustomPropTypes, withTranslation } from '../../../../common';
import { searchSelectors } from '../../../../../state/search';
import RoomCardMobile from './RoomCardMobile';
import RoomCardDesktop from './RoomCardDesktop';
import RoomCardTablet from './RoomCardTablet';

import { isProfile2, isProfile3 } from '../../../../../util';

import '../_style.css';

function SearchResultCard(props) {
  const { selected, resource, openModal } = props;

  const profile2 = isProfile2(resource);
  const profile3 = isProfile3(resource);

  const onSelect = event => {
    if (!selected && !profile3) {
      return;
    }

    if (event) {
      const { dataset } = event.target;
      if (dataset && dataset.ignore) {
        return;
      }
    }

    openModal(resource);
  };

  const roomCardProps = {
    ...props,
    onSelect,
    isProfile2: profile2,
    isProfile3: profile3,
    showJournal: resource.showJournal
  };

  const style = {};

  if (resource.showJournal && !!resource.bookings.length) {
    const bookingsHeight = (resource.bookings.length + resource.availabilities.length) * 80;
    style.height = `calc(100% - ${bookingsHeight}px)`;
  }

  return (
    <div className="SearchResultCard" style={style}>
      <ShowAt breakpoint="600AndBelow">
        <RoomCardMobile {...roomCardProps} />
      </ShowAt>
      <ShowAt breakpoint="600AndAbove 900AndBelow">
        <RoomCardTablet {...roomCardProps} />
      </ShowAt>
      <ShowAt breakpoint="900AndAbove">
        <RoomCardDesktop {...roomCardProps} />
      </ShowAt>
    </div>
  );
}

const mapStateToProps = state => ({
  showTimeInput: searchSelectors.isRelativeTime(state),
  showRelativeTime: !searchSelectors.getSearchedExactTime(state)
});

SearchResultCard.propTypes = {
  resource: CustomPropTypes.resource,
  selected: PropTypes.bool.isRequired,
  showTimeInput: PropTypes.bool.isRequired,
  showRelativeTime: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(withTranslation(SearchResultCard));
