import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip, EquipmentType, Profile2, Profile3, CustomPropTypes } from '../../../../../common';
import { getProfile } from '../../../../../../util';

function CalendarRoomTooltip({ id, active, equipment }) {
  if (!active) {
    return null;
  }

  const { name, building, resourceSubType } = equipment;

  const profile = getProfile(equipment);
  const isProfile = profile && profile > 1;

  return (
    <Tooltip className="EquipmentTooltip" effect="solid" id={id}>
      <div className="CalendarEquipmentTooltip">
        <div className="CalendarEquipmentTooltip__Header">
          <span className="CalendarEquipmentTooltip__Name">{name}</span>
          <span className="CalendarEquipmentTooltip__Location">{building.key}</span>
        </div>
        <div>
          <EquipmentType resourceSubType={resourceSubType} />
        </div>
        {isProfile && (
          <div className="CalendarEquipmentTooltip__Footer">
            <div className="CalendarEquipmentTooltip__ManagedBy">
              {profile === 3 && (
                <Profile3 path="search.detail.profile3.equipment" resource={equipment} book />
              )}
              {profile === 2 && (
                <Profile2 path="search.detail.profile2.equipment" resource={equipment} />
              )}
            </div>
          </div>
        )}
      </div>
    </Tooltip>
  );
}

CalendarRoomTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  equipment: CustomPropTypes.equipment
};

export default CalendarRoomTooltip;
