import React, { Component } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/fp/groupBy';

import { CustomPropTypes, withTranslation } from '../../common';
import CollectionEmptyState from './CollectionEmptyState';
import CollectionSearchResult from './CollectionSearchResult';
import CollectionAlternativeResult from './CollectionAlternativeResult';
import AlternativeSearchResultAccordion from '../../Search/AlternativeSearchResults/AlternativeSearchResultsAccordion';
import CollectionSearchResultSection from './CollectionSearchResultSection';
import CollectionLoadMore from './CollectionLoadMore';
import BookingCollectionInlineLoader from './CollectionSearchResultsInlineLoader';

import './_style.css';

const isOrdinaryResource = ({ resource }) => resource.alternativeType == null;
const isAlternativeResource = ({ resource }) => resource.alternativeType != null;
const groupBySection = groupBy(({ resource }) => resource.alternativeType);
const forEachSection = (fn, sections) => Object.keys(sections).map(section => fn(section, sections[section]));

class CollectionSearchResults extends Component {
  renderSearchResult = selectableResource => {
    const { onBook, onSelect, isMobile, isInProgress } = this.props;
    const { resource, selected } = selectableResource;
    const { alternativeType } = resource;

    const select = () => onSelect(resource);
    const book = onBook ? () => onBook(resource) : undefined;

    const commonProps = {
      key: resource.id,
      resource,
      alternativeType
    };

    if (isMobile && selected) {
      return (
        <CollectionAlternativeResult {...commonProps} onClick={book} isInProgress={isInProgress} />
      );
    }

    if (!isMobile && selected) {
      return <CollectionSearchResult {...commonProps} onSelect={select} selected={selected} />;
    }

    return <CollectionSearchResult {...commonProps} onSelect={select} />;
  };

  renderAlternativeSection = (type, resources) => {
    const { visibleAlternatives, translate } = this.props;
    const title = translate(`search.alternativeResults.${type}.title`);
    if (!visibleAlternatives[type]) {
      return null;
    }

    return (
      <AlternativeSearchResultAccordion key={type} title={title}>
        <CollectionSearchResultSection type={type}>
          {resources.map(resource => this.renderSearchResult(resource))}
        </CollectionSearchResultSection>
      </AlternativeSearchResultAccordion>
    );
  };

  renderLoadMore = () => {
    const { isLoading, hasMoreResults, resources, loadMore } = this.props;
    const hasOrdinaryResources = resources.filter(isOrdinaryResource).length > 0;
    return (
      <>
        <CollectionLoadMore
          visible={hasMoreResults && !isLoading}
          onClick={loadMore}
          className="CollectionSearchResults__Loader--framed"
        />
        <BookingCollectionInlineLoader visible={isLoading && hasOrdinaryResources} />
      </>
    );
  };

  render() {
    const { resources, isLoading, className } = this.props;

    const ordinaryResources = resources.filter(isOrdinaryResource);
    const alternativeResources = resources.filter(isAlternativeResource);
    const sections = groupBySection(alternativeResources);

    const showAlternativeEmptyState = ordinaryResources.length < 1 && alternativeResources.length > 0;

    return (
      <div className={className}>
        <div className="CollectionSearchResults__Resources">
          {ordinaryResources.map(resource => this.renderSearchResult(resource))}
        </div>
        {this.renderLoadMore()}
        {!isLoading && (
          <>
            <CollectionEmptyState visible={showAlternativeEmptyState} />
            {forEachSection(this.renderAlternativeSection, sections)}
          </>
        )}
      </div>
    );
  }
}

CollectionSearchResults.propTypes = {
  isMobile: PropTypes.bool,
  isInProgress: PropTypes.bool,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      resource: CustomPropTypes.resource,
      selected: PropTypes.bool
    })
  ),
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onBook: PropTypes.func,
  hasMoreResults: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  visibleAlternatives: PropTypes.shape({
    roomtype: PropTypes.bool,
    roomequipment: PropTypes.bool,
    capacity: PropTypes.bool
  }),
  translate: PropTypes.func.isRequired
};

CollectionSearchResults.defaultProps = {
  isMobile: false
};

export default withTranslation(CollectionSearchResults);
