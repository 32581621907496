import { createSelector } from 'reselect';

const getAttendeesSearchTerm = state => state.recurrenceMasterState.attendees.searchTerm;
const getSuggestedAttendees = state => state.recurrenceMasterState.attendees.suggestions;
const areAttendeesLoading = state => state.recurrenceMasterState.attendees.isLoading;
const getSelectedAttendees = state => state.recurrenceMasterState.attendees.selected;

const getAttendeesSuggestions = createSelector(
  getSuggestedAttendees,
  suggestedAttendees => suggestedAttendees.map(attendee => ({
    name: `${attendee.givenname} ${attendee.surname}, ${attendee.department}`,
    value: attendee.accountName,
    id: attendee.accountName,
    email: attendee.email
  }))
);

const getMappedSelectedAttendees = createSelector(
  getSelectedAttendees,
  selectedAttendees => selectedAttendees.map(attendee => ({
    ...attendee,
    value: attendee.email
  }))
);

export default {
  getAttendeesSearchTerm,
  getAttendeesSuggestions,
  getSelectedAttendees: getMappedSelectedAttendees,
  areAttendeesLoading
};
