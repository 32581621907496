/* eslint-disable react/no-did-update-set-state */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import mapValues from 'lodash/fp/mapValues';
import isEmpty from 'lodash/fp/isEmpty';
import isEqual from 'lodash/fp/isEqual';
import Icon from '../Icon/Icon';
import { Select, Option } from '..';
import config from '../../../config';

import './_style.css';

const parse = value => moment(value, ['HH', 'Hmm', 'HHmm']);
const convert = value => moment(value, ['HHmm', 'HH:mm'], true);
const isValidTime = time => convert(time).isValid();
const isValidRange = (from, to) => convert(from).isBefore(convert(to));
const format = time => moment(time).format('HH:mm');

const isAfterMidnight = time => {
  if (!moment.isMoment(time)) {
    throw new Error('not a moment');
  }
  const endOfDay = moment().endOf('day');
  return time.isAfter(endOfDay);
};

const addHour = value => {
  const time = convert(value);
  const updatedTime = moment(time).add(1, 'hours');
  const resultingTime = isAfterMidnight(updatedTime) ? time : updatedTime;
  return format(resultingTime);
};

const addDuration = (value, duration) => {
  const time = convert(value);
  const updatedTime = moment(time).add(duration.asMilliseconds(), 'ms');
  const resultingTime = isAfterMidnight(updatedTime) ? time : updatedTime;
  return format(resultingTime);
};

const getDuration = (from, to) => {
  const start = convert(from);
  const end = convert(to);
  return moment.duration(start.diff(end));
};

const isSameOrAfter = (from, to) => {
  const start = convert(from);
  const end = convert(to);
  return start.isValid() && end.isValid() && (start.isSame(end) || start.isAfter(end));
};

const renderTimeOptions = () => {
  const options = [];
  for (let time = moment(config.timePicker.minTime, 'hh:mm');
    time <= moment(config.timePicker.maxTime, 'hh:mm');
    time.add(config.timePicker.interval, 'minutes')) {
    options.push(<Option key={format(time)} value={format(time)}>{format(time)}</Option>);
  }
  return options;
};

class TimeInputRange extends PureComponent {
  constructor(props) {
    super(props);
    const { values } = props;
    this.state = {
      values: values || { from: '', to: '' },
      changed: { from: false, to: false },
      valid: { from: true, to: true },
      error: ''
    };
    this.from = React.createRef();
  }

  componentDidMount() {
    const { updateIsValidTimeRange } = this.props;
    if (typeof updateIsValidTimeRange === 'function') {
      updateIsValidTimeRange(true);
    }
  }

  componentDidUpdate(prevProps) {
    const { values } = this.props;
    if (!isEqual(values, prevProps.values)) {
      this.setState({ values });
    }
  }

  onChange = event => {
    const { name, value } = event.target;
    const { values } = this.state;
    this.onBlur(event);
    this.setState({ values: { ...values, [name]: value } }, () => this.validate(name, false));
  };

  onBlur = event => {
    const { onBlur } = this.props;
    const { name } = event.target;
    const { changed, values } = this.state;
    this.setState({ changed: { ...changed, [name]: true } }, () => this.autoFormat(name));
    if (onBlur) {
      onBlur({
        ...event,
        target: {
          ...event.target,
          value: values
        }
      });
    }
  };

  autoFormat = name => {
    const { values } = this.state;
    let value = values[name];
    if (!isValidTime(value)) {
      const parsed = parse(value);
      if (parsed.isValid()) {
        value = format(parsed);
      }
    }
    this.setState({ values: { ...values, [name]: value } }, () => this.validate(name, true));
  };

  validate = (name, autoUpdate) => {
    const { values, changed, valid } = this.state;
    const isValid = isValidTime(values[name]);
    const isChanged = changed[name];
    const empty = !values.from && !values.to;
    if (empty || isChanged) {
      this.setState(
        empty ? { valid: { from: true, to: true } } : { valid: { ...valid, [name]: isValid } },
        () => autoUpdate && this.autoUpdate()
      );
    }
  };

  autoUpdate = () => {
    const { values, changed, valid, duration } = this.state;
    const empty = isEmpty(values.to);
    const after = isSameOrAfter(values.from, values.to);
    const canUpdate = valid.from && changed.from && !isEmpty(values.from) && (empty || after);
    if (!canUpdate) {
      this.validateRange();
      return;
    }

    const updatedValues = { ...values };
    if (empty) {
      updatedValues.to = addHour(values.from);
    }

    if (after && duration) {
      updatedValues.to = addDuration(values.from, duration);
    }

    this.setState({ values: updatedValues }, this.validateRange);
  };

  validateRange = () => {
    const { error, invalid } = this.props;
    const { values, changed, valid } = this.state;
    const allValid = valid.from && valid.to;
    const someChanged = changed.from || changed.to;
    const rangeValid = allValid && isValidRange(values.from, values.to);
    if (rangeValid) this.updateDuration();
    if (someChanged && !invalid) {
      const rangeEmpty = values.from === '' && values.to === '';
      this.setState({ error: rangeValid || rangeEmpty ? '' : error });
    }
    this.maskInput();
  };

  updateDuration = () => {
    const { values } = this.state;
    this.setState({
      duration: getDuration(values.to, values.from)
    });
  };

  maskInput = () => {
    const { values } = this.state;

    const isSeparatedByColon = value => value[2] === ':';
    const shouldBeMasked = value => isValidTime(value) && !isSeparatedByColon(value);
    const maskValue = value => `${value.slice(0, 2)}:${value.slice(2)}`;

    const maskedValues = mapValues(
      value => (shouldBeMasked(value) ? maskValue(value) : value),
      values
    );

    this.setState({ values: maskedValues }, this.update);
  };

  update = () => {
    const { onChange } = this.props;
    const { updateIsValidTimeRange } = this.props;
    const validTimeRangeFunctionAvailable = typeof updateIsValidTimeRange === 'function';
    const { values, valid } = this.state;
    const allValid = valid.from && valid.to;
    const rangeValid = allValid && isValidRange(values.from, values.to);
    const rangeEmpty = values.from === '' && values.to === '';
    if (rangeValid || rangeEmpty) {
      onChange(values);
    }
    if (validTimeRangeFunctionAvailable) {
      updateIsValidTimeRange((rangeValid || rangeEmpty));
    }
  };

  renderTime = type => {
    const { invalid } = this.props;
    const { error } = this.state;
    const hasError = error !== '';
    const { values, valid } = this.state;
    const props = {
      name: type,
      placeholder: type,
      value: values[type],
      error: !valid[type] || invalid || hasError,
      onChange: this.onChange,
      onBlur: this.onBlur,
      ref: type === 'from' ? this.from : undefined
    };
    return (
      <Select {...props}>
        {renderTimeOptions()}
      </Select>
    );
  };

  render() {
    const { error } = this.state;
    return (
      <div>
        <div className="TimeInputRange">
          {this.renderTime('from')}
          <div className="TimeInputRange__Divider">-</div>
          {this.renderTime('to')}
        </div>

        {error && (
          <div className="TimeInputRange__Error">
            <Icon icon="026-exclamation-mark-circle" />
            {error}
          </div>
        )}
      </div>
    );
  }
}

TimeInputRange.propTypes = {
  values: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }),
  invalid: PropTypes.bool,
  error: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  updateIsValidTimeRange: PropTypes.func
};

export default TimeInputRange;
