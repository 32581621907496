import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  building: PropTypes.shape().isRequired,
  equipment: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ),
  permissionTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  roomType: PropTypes.string.isRequired,
  managedBy: PropTypes.shape(),
  restricted: PropTypes.bool,
  typeDisplayName: PropTypes.string,
  capacity: PropTypes.number
});
