import React from 'react';
import PropTypes from 'prop-types';

import { Translate } from '../common';
import styles from './WelcomeIntro.module.css';

function WelcomeIntro({ mobile }) {
  const classes = mobile ? null : styles.dialog;
  return (
    <div className={classes}>
      <Translate path="welcome.intro" />
    </div>
  );
}

export default WelcomeIntro;

WelcomeIntro.propTypes = {
  mobile: PropTypes.bool
};
