import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, ShowAt, CustomPropTypes } from '../../common';
import { modalOperations } from '../../../state/modal';
import { exceptionsSelectors, exceptionsOperations } from '../../../state/exceptions';
import RecurrenceExceptionDeleteModalMobile from './RecurrenceExceptionDeleteModalMobile';
import RecurrenceExceptionDeleteModalDesktop from './RecurrenceExceptionDeleteModalDesktop';
import keys from '../modalKeys';

function RecurrenceExceptionDeleteModal({ onExit, ...props }) {
  const modalKey = keys.RECURRENCE_EXCEPTION_DELETE_MODAL;
  return (
    <div>
      <ShowAt breakpoint="900AndAbove">
        <Modal modalKey={modalKey} onOverlayClick={onExit}>
          <RecurrenceExceptionDeleteModalDesktop {...props} />
        </Modal>
      </ShowAt>
      <ShowAt breakpoint="900AndBelow">
        <Modal modalKey={modalKey} mobile>
          <RecurrenceExceptionDeleteModalMobile {...props} />
        </Modal>
      </ShowAt>
    </div>
  );
}

const mapStateToProps = state => ({
  exception: exceptionsSelectors.getException(state),
  isInProgress: exceptionsSelectors.isInProgress(state)
});

const mapDispatchToProps = dispatch => {
  const onExit = () => dispatch(modalOperations.clearModals());
  const onClose = () => dispatch(modalOperations.popModal());
  const onReset = (exception, deleteCallback) => {
    dispatch(exceptionsOperations.resetException(exception)).then(() => {
      dispatch(modalOperations.popModal());
      dispatch(modalOperations.removeModal(keys.BOOK_DETAIL_MODAL));
      if (deleteCallback) {
        deleteCallback();
      }
    });
  };
  return { onExit, onClose, onReset };
};

RecurrenceExceptionDeleteModal.propTypes = {
  exception: CustomPropTypes.exception,
  isInProgress: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(RecurrenceExceptionDeleteModal);
