import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { searchSelectors } from '../../../state/search';
import { availabilitySelectors } from '../../../state/availability';
import { locationsSelectors } from '../../../state/locations';

import { EmptyState, withTranslation } from '../../common';

import './_style.css';

function SearchResultsEmptyState(props) {
  const {
    visible,
    showLocationsEmptyState,
    showAvailabilityEmptyState,
    resourceType,
    translate,
    getIsDateInPast
  } = props;

  if (!visible) {
    return null;
  }

  const commonProps = {
    icon: '232-robot-neutral',
    className: cx('SearchResultsEmptyState', {
      'SearchResultsEmptyState--warning': showAvailabilityEmptyState && !showLocationsEmptyState
    }),
    visible: true,
    centered: true
  };

  const renderDateIsInPast = () => {
    const text = translate('search.emptyState.dateInPast');
    return <EmptyState content={text} {...commonProps} />;
  };

  const renderLocationsEmptyState = () => {
    const text = translate('search.emptyState.location');
    return <EmptyState content={text} {...commonProps} />;
  };

  const renderAvailabilityEmptyState = () => {
    const text = translate(`search.emptyState.${resourceType}`);
    return <EmptyState content={text} {...commonProps} />;
  };

  if (getIsDateInPast) {
    return renderDateIsInPast();
  }

  if (showLocationsEmptyState) {
    return renderLocationsEmptyState();
  }

  if (showAvailabilityEmptyState) {
    return renderAvailabilityEmptyState();
  }

  return null;
}

const mapStateToProps = state => ({
  resourceType: searchSelectors.getResourceType(state),
  showAvailabilityEmptyState: availabilitySelectors.showEmptyState(state),
  showLocationsEmptyState: !locationsSelectors.hasLocations(state),
  getIsDateInPast: searchSelectors.getIsDateInPast(state)
});

SearchResultsEmptyState.propTypes = {
  visible: PropTypes.bool.isRequired,
  resourceType: PropTypes.string,
  showAvailabilityEmptyState: PropTypes.bool,
  showLocationsEmptyState: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  getIsDateInPast: PropTypes.bool
};

SearchResultsEmptyState.defaultState = {
  resourceType: 'room',
  getIsDateInPast: false
};

export default withTranslation(connect(mapStateToProps)(SearchResultsEmptyState));
