import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button, withTranslation } from '../../common';

import './_style.css';

function NewResourceButton({ history, translate }) {
  const redirect = () => {
    history.push('/changerequests/new');
  };

  const label = translate('changeRequests.new.title');
  return <Button className="NewResourceButton" label={label} onClick={redirect} />;
}

NewResourceButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(withRouter(NewResourceButton));
