import types from './types';

const updateConfig = config => ({
  type: types.UPDATE_CONFIG,
  config
});

const updateIsLoaded = age => ({
  type: types.UPDATE_LOADED,
  age
});

export default {
  updateConfig,
  updateIsLoaded
};
