import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon } from '../common';

function FeedbackIcon({ happy }) {
  const icon = happy ? '233-robot-happy' : '240-robot-sad';

  const classes = cx('FeedbackIcon__Icon', {
    'FeedbackIcon__Icon--happy': happy,
    'FeedbackIcon__Icon--sad': !happy
  });

  return (
    <div>
      <Icon icon={icon} className={classes} />
    </div>
  );
}

FeedbackIcon.propTypes = {
  happy: PropTypes.bool
};

FeedbackIcon.defaultProps = {
  happy: true
};

export default FeedbackIcon;
