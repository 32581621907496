import React from 'react';
import PropTypes from 'prop-types';

import { DayPicker } from '..';
import { roundToFirstDateOfWeek, roundToLastDateOfWeek } from '../../../util';

function WeekPicker(props) {
  const { selectedDays, onDayClick } = props;
  const weekRange = selectedDays === null
    ? null
    : {
      from: roundToFirstDateOfWeek(selectedDays),
      to: roundToLastDateOfWeek(selectedDays)
    };

  const modifiers = {
    selectedWeek: weekRange
  };
  return (
    <DayPicker
      {...props}
      selectedDays={weekRange}
      onDayClick={onDayClick}
      enableOutsideDays
      modifiers={modifiers}
    />
  );
}

WeekPicker.propTypes = {
  selectedDays: PropTypes.instanceOf(Date),
  onDayClick: PropTypes.func
};

export default WeekPicker;
