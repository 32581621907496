import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Header from '../Header';
import './_style.css';

import { EmptyState, withTranslation } from '../common';

function NotAuthorized({ history, translate }) {
  const title = translate('notAuthorized.title');
  const text = translate('notAuthorized.text');

  const onClose = () => history.push('/book');

  return (
    <div className="NotAuthorized">
      <Header onClose={onClose} title={title} />
      <div className="NotAuthorized__Content">
        <EmptyState visible content={text} icon="239-robot-confused" />
      </div>
    </div>
  );
}

NotAuthorized.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(withRouter(NotAuthorized));
