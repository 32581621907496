import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import first from 'lodash/fp/first';
import last from 'lodash/fp/last';
import moment from 'moment';

import RecurrenceInfo from './RecurrenceInfo';
import RecurrenceExceptions from './RecurrenceExceptions';
import BookFormRecurrenceButton from './BookFormRecurrenceButton';
import { i18nSelectors } from '../../../../state/i18n';
import { recurrenceSelectors } from '../../../../state/recurrence';
import { bookSelectors } from '../../../../state/book';
import { modalOperations } from '../../../../state/modal';
import { exceptionsSelectors, exceptionsOperations } from '../../../../state/exceptions';
import { withTranslation, CustomPropTypes } from '../../../common';
import keys from '../../modalKeys';

class BookFormRecurrence extends Component {
  componentDidMount() {
    const { updateResource, resource } = this.props;
    updateResource(resource);
  }

  getStartDate = () => {
    const {
      resource: { availabilities },
      exceptions
    } = this.props;
    const firstAvailability = first(availabilities);
    const firstException = first(exceptions);
    const firstAvailableDate = moment(firstAvailability.start);
    const firstExceptionDate = firstException && moment(firstException.start);
    if (firstExceptionDate) {
      if (firstAvailableDate.isAfter(firstExceptionDate)) {
        return firstExceptionDate.toISOString();
      }
    }

    return firstAvailableDate.toISOString();
  };

  getEndDate = () => {
    const {
      resource: { availabilities },
      exceptions
    } = this.props;
    const lastAvailability = last(availabilities);
    const lastException = last(exceptions);
    const lastAvailableDate = moment(lastAvailability.end);
    const lastExceptionDate = lastException && moment(lastException.end);
    if (lastExceptionDate) {
      if (lastAvailableDate.isBefore(lastExceptionDate)) {
        // set endTime to normal occurence endTime
        return lastExceptionDate
          .hours(lastAvailableDate.hours())
          .minutes(lastAvailableDate.minutes())
          .toISOString();
      }
    }

    return lastAvailableDate.toISOString();
  };

  renderInfo = () => {
    const {
      recurrenceType,
      recurrencePattern,
      language,
      openRecurrencePatternModal,
      resource
    } = this.props;

    const { availabilities, resourceType } = resource;
    if (!availabilities || !availabilities.length) {
      return null;
    }

    const startDate = this.getStartDate();
    const endDate = this.getEndDate();

    const props = {
      type: recurrenceType,
      pattern: recurrencePattern,
      resourceType,
      startDate,
      endDate,
      showAsAvailable: true,
      language,
      openRecurrencePatternModal
    };
    return <RecurrenceInfo {...props} />;
  };

  renderExceptions = () => {
    const {
      resource,
      exceptions,
      hasUnhandledExceptions,
      hasLoadedAvailabilities,
      patchException,
      openExceptionModal,
      openExceptionDeleteModal
    } = this.props;

    const props = {
      resource,
      exceptions,
      hasUnhandledExceptions,
      hasLoadedAvailabilities,
      patchException,
      openExceptionModal,
      openExceptionDeleteModal,
      showExceptionWarning: true,
      showOutlookInfo: false
    };

    return <RecurrenceExceptions {...props} />;
  };

  render() {
    const { recurrenceType, openRecurrencePatternModal, isTimeRangeValid } = this.props;
    const isRecurrence = recurrenceType !== 'never';
    if (isRecurrence) {
      return (
        <div>
          {this.renderInfo()}
          {this.renderExceptions()}
        </div>
      );
    }
    return (
      <BookFormRecurrenceButton onClick={openRecurrencePatternModal} disabled={!isTimeRangeValid} />
    );
  }
}

const mapStateToProps = state => ({
  recurrenceType: recurrenceSelectors.getRecurrenceType(state),
  recurrencePattern: recurrenceSelectors.getRecurrencePattern(state),
  exceptions: exceptionsSelectors.getExceptions(state),
  hasUnhandledExceptions: exceptionsSelectors.hasUnhandledExceptions(state),
  isTimeRangeValid: bookSelectors.isValidTimeRange(state),
  language: i18nSelectors.getLanguage(state)
});

const mapDispatchToProps = dispatch => ({
  patchException: exception => dispatch(exceptionsOperations.patchException(exception)),
  openExceptionModal: exception => {
    dispatch(exceptionsOperations.updateException(exception));
    dispatch(exceptionsOperations.loadResources());
    dispatch(exceptionsOperations.loadAlternatives());
    dispatch(modalOperations.pushModal(keys.RECURRENCE_EXCEPTION_MODAL));
  },
  openExceptionDeleteModal: exception => {
    dispatch(exceptionsOperations.updateException(exception));
    dispatch(modalOperations.pushModal(keys.RECURRENCE_EXCEPTION_DELETE_MODAL));
  },
  openRecurrencePatternModal: () => dispatch(modalOperations.pushModal(keys.RECURRENCE_PATTERN_MODAL)),
  updateResource: resource => dispatch(exceptionsOperations.updateResource(resource))
});

BookFormRecurrence.propTypes = {
  recurrenceType: PropTypes.string.isRequired,
  recurrencePattern: PropTypes.shape().isRequired,
  resource: CustomPropTypes.resource,
  exceptions: PropTypes.arrayOf(CustomPropTypes.exception),
  hasUnhandledExceptions: PropTypes.bool.isRequired,
  patchException: PropTypes.func.isRequired,
  openExceptionModal: PropTypes.func.isRequired,
  openExceptionDeleteModal: PropTypes.func.isRequired,
  updateResource: PropTypes.func.isRequired,
  openRecurrencePatternModal: PropTypes.func.isRequired,
  hasLoadedAvailabilities: PropTypes.bool,
  language: PropTypes.string.isRequired,
  isTimeRangeValid: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BookFormRecurrence)
);
