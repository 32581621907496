import moment from 'moment';

import actions from './actions';
import selectors from './selectors';
import { myBookingsService } from '../../services';
import { bookOperations, bookSelectors } from '../book';
import { recurrenceMasterOperations } from '../recurrenceMaster';
import { notificationsSelectors, notificationsOperations } from '../notifications';
import { modalOperations } from '../modal';

const {
  clearMyBookings,
  addMyBookings,
  updateMyBooking,
  updateIsLoading,
  updateLastDate,
  removeMyBooking,
  removeRecurrence
} = actions;

const nextMonth = date => moment(date).add(1, 'month');
const startOfNextMonth = date => nextMonth(date).startOf('month');
const endOfNextMonth = date => nextMonth(date).endOf('month');

const createSearchRequest = (startDate, endDate) => ({
  start: startDate.toDate().toISOString(),
  end: endDate.toDate().toISOString()
});

const loadBookings = () => (dispatch, getState) => {
  const state = getState();

  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  dispatch(updateIsLoading(true));
  const lastDate = selectors.getLastDate(state);

  const isFirstMonth = !selectors.hasSearched(state);
  const startDate = isFirstMonth ? moment() : startOfNextMonth(lastDate);
  const endDate = isFirstMonth ? lastDate : endOfNextMonth(lastDate);

  const searchRequest = createSearchRequest(startDate, endDate);

  return myBookingsService
    .post(searchRequest)
    .then(myBookings => {
      dispatch(addMyBookings(myBookings));
      dispatch(updateLastDate(endDate));
    })
    .catch(serverError => {
      const error = getNotification('myBookings.load.error', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => {
      dispatch(updateIsLoading(false));
    });
};

const refreshBookings = () => (dispatch, getState) => {
  const state = getState();

  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  dispatch(clearMyBookings());
  dispatch(updateIsLoading(true));
  const lastDate = selectors.getLastDate(state);

  const searchRequest = createSearchRequest(moment(), lastDate);

  return myBookingsService
    .post(searchRequest)
    .then(myBookings => {
      dispatch(addMyBookings(myBookings));
    })
    .catch(serverError => {
      const error = getNotification('myBookings.load.error', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => {
      dispatch(updateIsLoading(false));
    });
};

const updateBooking = () => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  dispatch(bookOperations.updateIsSubmitting(true));

  dispatch(bookOperations.updateBookingFromState())
    .then(myBooking => {
      const success = getNotification('myBookings.update.success');
      dispatch(notificationsOperations.showSuccess(success));
      dispatch(modalOperations.clearModals());
      dispatch(updateMyBooking(myBooking));
    })
    .catch(serverError => {
      const error = getNotification('myBookings.update.error', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => {
      dispatch(bookOperations.updateIsSubmitting(false));
    });
};

const deleteMyBooking = (globalId, payload, notificationPath) => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);

  dispatch(notificationsOperations.hideError());
  dispatch(bookOperations.updateIsSubmitting(true));

  return myBookingsService
    .remove(globalId, payload)
    .then(() => {
      dispatch(modalOperations.clearModals());
      const success = getNotification(`${notificationPath}.success`);
      dispatch(notificationsOperations.showSuccess(success));
    })
    .catch(serverError => {
      const error = getNotification(`${notificationPath}.error`, serverError);
      dispatch(notificationsOperations.showError(error));
      return Promise.reject(serverError);
    })
    .then(() => {
      dispatch(bookOperations.updateIsSubmitting(false));
      return Promise.resolve();
    });
};

const deleteMaster = (globalId, calendarId) => dispatch => {
  const payload = {
    calendarId,
    isRecurring: true,
    isOccurrence: false
  };

  dispatch(deleteMyBooking(globalId, payload, 'myBookings.remove.master')).then(() => {
    dispatch(removeRecurrence(globalId));
  });
};

const deleteBooking = id => (dispatch, getState) => {
  const state = getState();

  const payload = bookSelectors.getRecurrenceRequestInfo(state);

  dispatch(deleteMyBooking(id, payload, 'myBookings.remove')).then(() => {
    dispatch(removeMyBooking(payload.calendarId));
  });
};

const updateMaster = () => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(recurrenceMasterOperations.updateRecurrenceMaster())
    .then(() => {
      dispatch(clearMyBookings());
      dispatch(modalOperations.clearModals());
      dispatch(loadBookings());
      const success = getNotification('search.recurrence.update.success');
      dispatch(notificationsOperations.showSuccess(success));
    })
    .catch(serverError => {
      const error = getNotification('search.recurrence.update.error', serverError);
      dispatch(notificationsOperations.showError(error));
    });
};

export default {
  resetBookings: clearMyBookings,
  loadBookings,
  refreshBookings,
  deleteBooking,
  deleteMaster,
  updateBooking,
  removeBooking: removeMyBooking,
  removeRecurrence,
  updateMaster
};
