// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsDetails_body__vHWF7 {\n  padding: 80px 20px 20px 20px;\n  display: flex;\n  justify-content: center;\n}\n\n.NewsDetails_bodyForm__ll\\+sV{\n  width: 700px;\n}\n\n.NewsDetails_buttonGroup__DzmKS {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 40px;\n  padding-bottom: 60px;\n}\n\n.NewsDetails_button__9pL04 {\n  margin-left: 20px;\n}", "",{"version":3,"sources":["webpack://./src/views/Admin/NewsDetails/NewsDetails.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".body {\n  padding: 80px 20px 20px 20px;\n  display: flex;\n  justify-content: center;\n}\n\n.bodyForm{\n  width: 700px;\n}\n\n.buttonGroup {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 40px;\n  padding-bottom: 60px;\n}\n\n.button {\n  margin-left: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "NewsDetails_body__vHWF7",
	"bodyForm": "NewsDetails_bodyForm__ll+sV",
	"buttonGroup": "NewsDetails_buttonGroup__DzmKS",
	"button": "NewsDetails_button__9pL04"
};
export default ___CSS_LOADER_EXPORT___;
