import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Icon } from '../common';
import { globalOperations } from '../../state/global';

import './_style.css';

const isUnsuportedBrowser = () => {
  const { navigator } = window;
  const { userAgent } = navigator;
  const msie = userAgent.indexOf('MSIE ');

  if (msie > 0 || !!userAgent.match(/Trident.*rv:11\./)) {
    if (Number.isNaN(parseInt(userAgent.substring(msie + 5, userAgent.indexOf('.', msie)), 10))) {
      if (navigator.appName === 'Netscape') {
        return false;
      }
    } else {
      return true;
    }
  }

  return false;
};

class Browser extends Component {
  constructor(props) {
    super(props);

    const isUnsupported = isUnsuportedBrowser();
    this.isUnsupported = isUnsupported;

    if (isUnsupported) {
      const { disableScrolling } = props;
      disableScrolling();
    }

    this.state = { visible: true };
  }

  close = () => {
    const { enableScrolling } = this.props;
    this.setState({ visible: false });
    enableScrolling();
  };

  render() {
    const { visible } = this.state;

    if (!this.isUnsupported || !visible) {
      return null;
    }

    const german = 'Du benutzt eine ältere Version des Internet Explorers, die wir nicht unterstützen. '
      + 'Bitte installiere die neuste Version.';

    const english = 'You are using an older version of Internet Explorer that we do not support. '
      + 'Please install the latest version.';

    return (
      <div className="Browser">
        <div className="Browser__Content">
          <Icon icon="241-robot-broken" className="BrowserContent__Icon" />

          <div className="BrowserContent__CloseButton">
            <div aria-label="Close" onClick={this.close} role="button" tabIndex={0}>
              <Icon icon="022-close" className="BrowserContentCloseButton__Icon" />
            </div>
          </div>

          <div className="BrowserContent__Text">{german}</div>
          <div className="BrowserContent__Text">{english}</div>
          <div className="BrowserContent__Text BrowserContent__Close">
            <button className="link" onClick={this.close} type="button">
              Schliessen / Close
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Browser.propTypes = {
  enableScrolling: PropTypes.func.isRequired,
  disableScrolling: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  enableScrolling: () => dispatch(globalOperations.enableScrolling()),
  disableScrolling: () => dispatch(globalOperations.disableScrolling())
});

export default connect(
  null,
  mapDispatchToProps
)(Browser);
