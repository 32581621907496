/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, NavLink, Link } from 'react-router-dom';
import { withTranslation, ShowAt } from '../common';
import MenuItem from './MenuItem';
import MenuLink from './MenuLink';
import MenuLogo from './MenuLogo';
import MenuDropdown from './MenuDropdown';
import MyBookingsIcon from '../common/Icon/MyBookingsIcon';
import { authOperations, authSelectors } from '../../state/auth';
// import accountService from '../../services/accountService';
import './_style.css';

function Menu({ isAdmin, translate, userLogout }) {
  const search = translate('menu.search');
  const myBookings = translate('menu.myBookings');
  const feedback = translate('menu.feedback');
  const language = translate('menu.language');
  const more = translate('menu.more');
  const help = translate('menu.help');
  const changeRequests = translate('menu.changeRequests');
  const maintenance = translate('menu.maintenance');
  const photoAdmin = translate('menu.photoAdmin');
  const news = translate('menu.news');
  const settings = translate('menu.settings');
  const logOut = translate('menu.logout');
  const myBookingsIcon = <MyBookingsIcon className="MenuLink__Icon" />;

  return (
    <nav className="Menu" data-testid="top-navigation-menu">
      <ShowAt breakpoint="900AndAbove" className="Menu__LogoWrapper">
        <MenuLogo />
      </ShowAt>
      <MenuItem>
        <MenuLink to="/search" icon="077-search" label={search} />
      </MenuItem>

      <MenuItem>
        <MenuLink to="/mybookings" icon={myBookingsIcon} label={myBookings} />
      </MenuItem>

      <MenuItem hideAt="900AndAbove">
        <MenuLink to="/help" icon="062-question-mark-circle" label={help} />
      </MenuItem>

      <MenuItem hideAt="900AndAbove">
        <MenuLink to="/language" icon="136-globe" label={language} />
      </MenuItem>

      <MenuItem hideAt="900AndBelow">
        <MenuDropdown icon="053-more" label={more}>
          <NavLink to="/help" exact>
            {help}
          </NavLink>
          <NavLink to="/feedback" exact>
            {feedback}
          </NavLink>
          <NavLink to="/language" exact>
            {language}
          </NavLink>
          <NavLink to="/changerequests/my" exact>
            {changeRequests}
          </NavLink>
          {isAdmin && (
            <NavLink to="/admin/maintenance" exact>
              {maintenance}
            </NavLink>
          )}
          {isAdmin && (
            <NavLink to="/admin/photos" exact>
              {photoAdmin}
            </NavLink>
          )}
          {isAdmin && (
            <NavLink to="/admin/news" exact>
              {news}
            </NavLink>
          )}
          {isAdmin && (
            <NavLink to="/admin/settings">
              {settings}
            </NavLink>
          )}
          <Link to="" onClick={userLogout}>
            {logOut}
          </Link>
        </MenuDropdown>
      </MenuItem>
    </nav>
  );
}

Menu.propTypes = {
  isAdmin: PropTypes.bool,
  userLogout: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

Menu.defaultProps = {
  isAdmin: false
};

const mapDispatchToProps = dispatch => ({
  userLogout: () => dispatch(authOperations.logout())
});

const mapStateToProps = state => ({
  isAdmin: authSelectors.isAdministrator(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation(Menu)));
