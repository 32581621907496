import { RRule } from 'rrule';
import map from 'lodash/fp/map';

const mapWeekDays = map(day => {
  switch (day) {
    case 'monday':
      return RRule.MO;
    case 'tuesday':
      return RRule.TU;
    case 'wednesday':
      return RRule.WE;
    case 'thursday':
      return RRule.TH;
    case 'friday':
      return RRule.FR;
    default:
      throw new Error('Not a valid weekday');
  }
});

const mapWeek = map(week => {
  switch (week) {
    case 'first':
      return 1;
    case 'second':
      return 2;
    case 'third':
      return 3;
    case 'fourth':
      return 4;
    case 'last':
      return -1;
    default:
      throw new Error('Not a valid week');
  }
});

const createOptions = (start, end) => {
  const from = start.toDate();
  const until = end.toDate();
  return { dtstart: from, until };
};

const getOccurrences = options => {
  const rule = new RRule(options, true);
  return rule.all();
};

export const getDailyOccurrences = (start, end) => {
  const options = {
    freq: RRule.DAILY,
    ...createOptions(start, end),
    interval: 1,
    byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR]
  };

  return getOccurrences(options);
};

export const getWeeklyOccurrences = (start, end, interval, days) => {
  const options = {
    freq: RRule.WEEKLY,
    ...createOptions(start, end),
    interval,
    byweekday: mapWeekDays(days)
  };

  return getOccurrences(options);
};

export const getMonthlyOccurrences = (start, end, interval, day, week) => {
  const options = {
    freq: RRule.MONTHLY,
    ...createOptions(start, end),
    interval,
    byweekday: mapWeekDays([day]),
    bysetpos: mapWeek([week])
  };

  return getOccurrences(options);
};
