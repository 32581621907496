/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint no-constant-condition: ["error", { "checkLoops": false }] */

export default function utils(reactFlowInstance, onChangedMap) {
  const { getNode, getNodes, setNodes, getIntersectingNodes } = reactFlowInstance;

  function areFloormapBoundsValid(floorBounds, childNodesAbsoluteBounds) {
    return (
      floorBounds.top <= Math.round(childNodesAbsoluteBounds.top)
      && floorBounds.right >= Math.round(childNodesAbsoluteBounds.right)
      && floorBounds.bottom >= Math.round(childNodesAbsoluteBounds.bottom)
      && floorBounds.left <= Math.round(childNodesAbsoluteBounds.left)
    );
  }

  function getFloorMapResizingNodeBounds(data) {
    return {
      top: data.y,
      right: data.x + data.width,
      bottom: data.y + data.height,
      left: data.x
    };
  }

  function getChildNodesAbsoluteBounds() {
    const childNodes = getNodes().filter(n => n.id !== 'floorMap');

    const bounds = { top: 10000, right: 0, bottom: 0, left: 10000 };

    if (childNodes && childNodes.length !== 0) {
      // Get the nodes edge bounds
      childNodes.forEach(n => {
        bounds.top = Math.min(bounds.top, n.positionAbsolute.y);
        bounds.right = Math.max(bounds.right, n.positionAbsolute.x + n.width);
        bounds.bottom = Math.max(
          bounds.bottom,
          n.positionAbsolute.y + n.height
        );
        bounds.left = Math.min(bounds.left, n.positionAbsolute.x);
      });
    }
    return bounds;
  }

  function updateChildNodesMap(childNodes = null) {
    const updatedChildNodes = childNodes ?? getNodes().filter(n => n.id !== 'floorMap');
    onChangedMap({ childNodes: updatedChildNodes });
  }

  function updateFloorMap() {
    const floorNode = getNode('floorMap');
    const childNodes = getNodes().filter(n => n.id !== 'floorMap');
    onChangedMap({
      mapDimensions: {
        width: floorNode.width,
        height: floorNode.height,
        positionX: floorNode.position.x,
        positionY: floorNode.position.y
      },
      childNodes
    });
  }

  function checkCollisionsAndUpdateState(monitoredNode, monitoredNodeStart) {
    let collisions = false;
    const intersectingNodeIds = getIntersectingNodes(monitoredNode).map(n => n.id);

    if (intersectingNodeIds.filter(id => id !== 'floorMap').length !== 0) {
      collisions = true;
      setNodeToPreviousState(monitoredNode, monitoredNodeStart);
    } else {
      // no collisions, so update map:
      updateChildNodesMap();
    }
    return collisions;
  }

  function setNodeToPreviousState(node, previousState, fn = updateChildNodesMap) {
    if (previousState) {
      setNodes(nodes => nodes.map(n => {
        if (n.id === node.id) {
          n = {
            ...previousState,
            position: { ...previousState.position },
            positionAbsolute: { ...previousState.positionAbsolute },
            style: { ...previousState.style },
            data: { ...previousState.data }
          };
        }
        return n;
      }));
      fn();
    }
  }

  function calculateCenterMap() {
    const floorMap = getNode('floorMap');
    return { x: floorMap.width / 2, y: floorMap.height / 2 };
  }

  function calculateNearestPosition(center, existingNodes, nodetype, radius = 0) {
    let currentRadius = radius;

    while (true) {
      let foundPosition = null;

      // Increase the angle range to cover the entire rectangle
      for (let angle = 0; angle < 360; angle += 1) {
        const angleInRadians = (angle * Math.PI) / 180;
        const newX = Math.ceil((center.x + (currentRadius * Math.cos(angleInRadians))) / 10) * 10;
        const newY = Math.ceil((center.y + (currentRadius * Math.sin(angleInRadians))) / 10) * 10;

        const doesIntersect = existingNodes.some(node => {
          const existingNode = {
            left: node.position.x,
            top: node.position.y,
            right: node.position.x + node.width,
            bottom: node.position.y + node.height
          };

          const newNode = {
            left: newX,
            top: newY,
            right: newX + nodetype.width,
            bottom: newY + nodetype.height
          };
          return overlaps(newNode, existingNode);
        });

        // Check if the new position is within the width and height limits
        const withinLimits = newX >= 0 && newX <= center.x * 2 && newY >= 0 && newY <= center.y * 2;

        const withinMargins = (
          newX + nodetype.width + 10 <= center.x * 2
          && newY + nodetype.height + 10 <= center.y * 2
        );

        if (!doesIntersect && withinLimits && withinMargins) {
          foundPosition = { x: newX, y: newY };
          break;
        }
      }

      if (foundPosition) {
        return foundPosition;
      }

      currentRadius += 40;

      if (currentRadius > Math.max(center.x, center.y) || currentRadius > Math.max(center.x * 2, center.y * 2) / 2) {
        throw new Error('No available position found without intersection or outside the limits.');
      }
    }
  }

  // Check if rectangle a overlaps rectangle b
  function overlaps(a, b) {
    // no horizontal overlap
    if (a.left >= b.right || b.left >= a.right) return false;

    // no vertical overlap
    if (a.top >= b.bottom || b.top >= a.bottom) return false;

    return true;
  }

  return {
    areFloormapBoundsValid,
    getFloorMapResizingNodeBounds,
    getChildNodesAbsoluteBounds,
    updateFloorMap,
    setNodeToPreviousState,
    checkCollisionsAndUpdateState,
    calculateCenterMap,
    calculateNearestPosition,
    overlaps
  };
}
