import curry from 'lodash/fp/curry';

export const isFocused = element => element === document.activeElement;

export const getStyles = element => window.getComputedStyle(element);

export const text = element => element.textContent || element.innerText;

export const css = curry((property, element) => getStyles(element).getPropertyValue(property));

export const textWidth = (content, font) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(content);
  return Math.round(metrics.width);
};
