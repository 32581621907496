import React from 'react';
import FormTime from '../FormTime';
import FormSubmit from '../FormSubmit';

import './_style.css';

function FormTimeAndSubmit(props) {
  return (
    <div className="FormTimeAndSubmit">
      <FormTime />
      <FormSubmit small {...props} />
    </div>
  );
}

export default FormTimeAndSubmit;
