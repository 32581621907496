import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './_style.css';

function Icon({ icon, className, onClick, style, ...props }) {
  const classes = classNames(
    {
      icon: true,
      [`icon-${icon}`]: true
    },
    className
  );

  return <i className={classes} onClick={onClick} style={style} aria-hidden="true" {...props} />;
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
  onClick: PropTypes.func
};

Icon.defaultProps = {
  className: undefined,
  style: undefined
};

export default Icon;
