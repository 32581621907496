import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, ShowAt } from '../../common';
import { modalOperations } from '../../../state/modal';
import { bookOperations } from '../../../state/book';

import RecurrencePatternModalDesktop from './RecurrencePatternModalDesktop';
import RecurrencePatternModalMobile from './RecurrencePatternModalMobile';

import keys from '../modalKeys';

import './_style.css';

function RecurrencePatternModal(props) {
  const { clearModals, onSave } = props;

  return (
    <div>
      <ShowAt breakpoint="900AndAbove">
        <Modal modalKey={keys.RECURRENCE_PATTERN_MODAL} onOverlayClick={clearModals}>
          <RecurrencePatternModalDesktop {...props} onSave={onSave} />
        </Modal>
      </ShowAt>
      <ShowAt breakpoint="900AndBelow">
        <Modal modalKey={keys.RECURRENCE_PATTERN_MODAL} mobile>
          <RecurrencePatternModalMobile {...props} onSave={onSave} />
        </Modal>
      </ShowAt>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  clearModals: () => dispatch(modalOperations.clearModals()),
  onSave: () => {
    dispatch(bookOperations.loadSingleAvailability());
    dispatch(modalOperations.popModal());
  }
});

RecurrencePatternModal.propTypes = {
  clearModals: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(RecurrencePatternModal);
