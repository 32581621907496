import React from 'react';
import PropTypes from 'prop-types';

import SidebarFormField from './SidebarFormField';

function BookSidebarDate({ time }) {
  return <SidebarFormField label="common.time" element={time} />;
}

BookSidebarDate.propTypes = {
  time: PropTypes.string
};

export default BookSidebarDate;
