import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, ChangeDetector, Input } from '../../common';
import './_style.css';

function FormFieldFilteredText({ values, update, field, isSubmitted, optional, detectChanges, translate }) {
  const updateValue = event => update(field, event.target.value);

  const labelText = translate(`common.${field}`);
  const getOptionalLabel = () => {
    const optionalText = translate('changeRequests.optional');
    return (
      <span>
        {labelText}
        {' '}
        <span className="ChangeRequestFormField__OptionalLabel">{optionalText}</span>
      </span>
    );
  };
  const label = !optional ? labelText : getOptionalLabel();

  const invalid = isSubmitted && !optional && (!values.new || values.new === '');
  const invalidCharsExp = new RegExp('[^a-zA-Z0-9!#$%&\'* +-/=?^_`{|}~ ]+');
  const invalidValue = invalidCharsExp.test(values.new);

  let error = '';
  if (invalid) {
    error = translate('common.required');
  } else if (invalidValue) {
    error = translate('changeRequests.invalidName');
  }

  return (
    <div className="ChangeRequestFormField">
      <ChangeDetector values={values} invalid={invalid || invalidValue} detectChanges={detectChanges}>
        <Input label={label} onChange={updateValue} value={values.new} error={error} />
      </ChangeDetector>
    </div>
  );
}

FormFieldFilteredText.propTypes = {
  values: PropTypes.shape({
    old: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    new: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  field: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  optional: PropTypes.bool,
  detectChanges: PropTypes.bool,
  isSubmitted: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

FormFieldFilteredText.defaultProps = {
  optional: false,
  detectChanges: true
};

export default withTranslation(FormFieldFilteredText);
