const UPDATE_ROOM = 'photoAdminState/UPDATE_ROOM';
const UPDATE_IS_LOADING = 'photoAdminState/UPDATE_IS_LOADING';
const ADD_PHOTO_ID = 'photoAdminState/ADD_PHOTO_ID';
const REMOVE_PHOTO_ID = 'photoAdminState/REMOVE_PHOTO_ID';
const TOGGLE_LOADING_PHOTO = 'photoAdminState/TOGGLE_LOADING_PHOTO';

export default {
  UPDATE_ROOM,
  UPDATE_IS_LOADING,
  ADD_PHOTO_ID,
  REMOVE_PHOTO_ID,
  TOGGLE_LOADING_PHOTO
};
