import types from '../types';

const addResources = resources => ({
  type: types.ADD_RESOURCES,
  resources
});

const updateResources = resources => ({
  type: types.UPDATE_RESOURCES,
  resources
});

const updateResourcesLoading = loading => ({
  type: types.UPDATE_RESOURCES_LOADING,
  loading
});

const updateTotalCount = totalCount => ({
  type: types.UPDATE_TOTALCOUNT,
  totalCount
});

const updateSkipCount = skipCount => ({
  type: types.UPDATE_SKIPCOUNT,
  skipCount
});

const updateHasMore = hasMore => ({
  type: types.UPDATE_HASMORE,
  hasMore
});

export default {
  addResources,
  updateResources,
  updateResourcesLoading,
  updateTotalCount,
  updateSkipCount,
  updateHasMore
};
