import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation, ChangeDetector } from '../../common';

import './_style.css';

function FormFieldReadOnly({ text, values, field, detectChanges, translate }) {
  const label = translate(`changeRequests.fieldNames.${field}`);

  return (
    <div className="ChangeRequestFormField FormFieldReadOnly">
      <ChangeDetector values={values} detectChanges={detectChanges}>
        <div className="FormFieldReadOnly__Label">{label}</div>
        <div className="FormFieldReadOnly__Value">{text}</div>
      </ChangeDetector>
    </div>
  );
}

FormFieldReadOnly.propTypes = {
  values: PropTypes.shape({
    old: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape(),
      PropTypes.array,
      PropTypes.bool
    ]),
    new: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape(),
      PropTypes.array,
      PropTypes.bool
    ])
  }).isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  detectChanges: PropTypes.bool,
  field: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

FormFieldReadOnly.defaultProps = {
  detectChanges: true
};

export default withTranslation(FormFieldReadOnly);
