import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import { withTranslation, Input, Button, RadioGroup, Radio } from '../../../common';
import { resourcesSubTypesOperations } from '../../../../state/resourcesSubTypes';
import { authSelectors } from '../../../../state/auth';
import Header from '../../../Header';
import formValidation from '../common';
import ResourceSubTypeShape from './ResourceSubTypesShape/ResourceSubTypeShape';

class ResourcesSubTypesCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        color: '',
        resourceType: 'Room',
        nameEN: '',
        nameDE: '',
        nameFR: '',
        nameIT: '',
        height: 30,
        width: 60
      },
      isValid: {
        color: true,
        nameEN: true,
        nameDE: true,
        nameFR: true,
        nameIT: true
      },
      displayColorPicker: false,
      formIsValid: false
    };

    this.validation = {
      nameEN: value => value !== ''
        && /^\S(?:.*\S)?$/.test(value) && value.length >= 1, // not empty and at least 1 character without space
      nameDE: value => value === ''
        || (/^\S(?:.*\S)?$/.test(value) && value.length >= 1), // empty or at least 1 character without space
      nameFR: value => value === ''
        || (/^\S(?:.*\S)?$/.test(value) && value.length >= 1), // empty or at least 1 character without space
      nameIT: value => value === ''
        || (/^\S(?:.*\S)?$/.test(value) && value.length >= 1) // empty or at least 1 character without space
    };
  }

  componentDidMount() {
    const { isAdmin, history } = this.props;
    if (!isAdmin) {
      history.replace('/unauthorized');
    }
  }

  renderType = () => {
    const { form } = this.state;

    const handleResourceTypeChange = event => {
      const { value } = event.target;
      this.setState({ form: { ...form, resourceType: value } });
    };

    return (
      <div className="BookModalRadioGroup">
        <RadioGroup
          label="Type"
          onChange={handleResourceTypeChange}
          value={form.resourceType}
        >
          <Radio
            label="Room"
            value="Room"
          />
          <Radio
            label="Equipment"
            value="Equipment"
          />
        </RadioGroup>
      </div>
    );
  };

  renderColor = () => {
    const { translate } = this.props;
    const { form, displayColorPicker } = this.state;

    return (
      <>
        <label htmlFor="ColorPickerButton">
          {`${ translate('admin.settings.config.colorPickerLabel') }`}
        </label>
        <br />
        <div className="ColorPickerContainer" style={{ marginBottom: '20px' }}>
          <input
            id="ColorPickerButton"
            type="button"
            onClick={this.handleClick}
            className="BlockColorPicker"
            style={{
              backgroundColor: `${ form.color }`
            }}
          />
        </div>
        {displayColorPicker
          && (
            <div
              className="ColorPicker"
              ref={this.referenceFunction}
              onMouseLeave={this.handleClose}
              onBlur={this.handleClose}>
              <ChromePicker
                color={form.color}
                display={displayColorPicker}
                onChange={selectedcolor => {
                  this.setState({ form: { ...form, color: selectedcolor.hex } });
                }}
              />
            </div>
          )}
      </>
    );
  };

  handleClick = () => {
    const { displayColorPickerState } = this.state;
    this.setState({ displayColorPicker: !displayColorPickerState });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  referenceFunction = div => {
    if (div) {
      div.focus();
    }
  };

  renderName = language => {
    const { form, isValid } = this.state;
    const { translate } = this.props;
    const fieldName = `name${ language }`;

    const handleChange = event => {
      const { value } = event.target;
      formValidation.validateField(this, fieldName, value);
      formValidation.validateForm(this, fieldName, value);
    };

    return (
      <>
        <Input
          label={`${translate('common.name')} ${language}
           ${language === 'EN' ? ` ${ translate('common.requiredField')}` : ''}`}
          placeholder={translate(`admin.settings.resourcesSubTypes.add.placeholder.${ fieldName }`)}
          onChange={handleChange}
          error={!isValid[fieldName]}
          value={form[fieldName]}
          className="input"
        />
        {!isValid[fieldName]
          && formValidation.renderValidationError(this, `admin.settings.resourcesSubTypes.add.error.${ fieldName }`)}
      </>
    );
  };

  renderShape = () => {
    const { translate } = this.props;
    const { form } = this.state;

    const innerStyle = {
      width: '500px',
      height: '300px',
      border: '1px solid rgb(223, 223, 223)'
    };

    const resourceSubType = {
      color: form.color,
      width: form.width,
      height: form.height
    };

    const updateDimensions = (width, height) => {
      this.setState({ form: { ...form, width, height } });

      formValidation.validateForm(this, 'width', width);
      formValidation.validateForm(this, 'height', height);
    };

    return (
      <>
        <label htmlFor="ResourceSubTypeShape">
          {`${ translate('admin.settings.resourcesSubTypes.add.shape') }`}
        </label>
        <ResourceSubTypeShape
          style={innerStyle}
          resourceSubType={resourceSubType}
          updateDimensions={updateDimensions} />
      </>
    );
  };

  renderSubmitButton = () => {
    const { submit, isSubmitting, history, translate } = this.props;
    const { form, formIsValid } = this.state;

    const onClick = async event => {
      event.preventDefault();
      event.stopPropagation();

      if (formIsValid) {
        const result = await submit(
          form.color,
          form.resourceType,
          form.nameEN,
          form.nameFR,
          form.nameDE,
          form.nameIT,
          form.width,
          form.height
        );
        if (result === 'Ok') {
          history.replace('/admin/settings/resourcesSubTypes');
        }
      }
    };

    return (
      <div className="button--right">
        <Button
          icon="074-save"
          label={translate('common.create')}
          loading={isSubmitting}
          onClick={onClick}
          disabled={!formIsValid} />
      </div>
    );
  };

  render() {
    const { history, translate } = this.props;
    return (
      <>
        <Header
          onClose={() => { history.push('/admin/settings/resourcesSubTypes'); }}
          title={translate('admin.settings.resourcesSubTypes.add.title')}
          iconRight
          icon="022-close" />
        <div className="Create__Form">
          {this.renderType()}
          {this.renderColor()}
          {this.renderName('EN')}
          {this.renderName('DE')}
          {this.renderName('FR')}
          {this.renderName('IT')}
          {this.renderShape()}
          {this.renderSubmitButton()}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: authSelectors.isAdministrator(state)
});

const mapDispatchToProps = dispatch => ({
  submit: (color, resourceType, nameEN, nameFR, nameDE, nameIT, width, height) => dispatch(
    resourcesSubTypesOperations.addResourcesSubType(color, resourceType, nameEN, nameFR, nameDE, nameIT, width, height)
  )
});

ResourcesSubTypesCreate.propTypes = {
  isAdmin: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  submit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(ResourcesSubTypesCreate));
