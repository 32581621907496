import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { DateInput, withTranslation } from '../../../common';

import styles from './NewsFormCalendar.module.css';

class NewsFormCalendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value
    };
  }

  onChange = async value => {
    const { onChange } = this.props;
    await this.setState({
      value
    });

    if (onChange) {
      onChange(value);
    }
  };

  clearValue = () => {
    this.onChange('');
  };

  render() {
    const { translate, heading, isRequired } = this.props;
    const { value } = this.state;
    const showWarning = isRequired && !value;
    const headingText = isRequired && showWarning
      ? `${heading} ${translate('common.requiredField')}`
      : heading;

    return (
      <div className={styles.dateBody}>
        <div className={styles.dateHeading}>{headingText}</div>
        <div className={styles.input}>
          <DateInput
            className={styles.datePicker}
            showInput={false}
            onChange={this.onChange}
            value={value}
            enablePastDates
            showWeekPicker={false}
          />
        </div>
        {showWarning && <div className={styles.requiredText}>{translate('admin.news.form.dateRequired')}</div>}
        <button className={`${styles.link} link`} type="button" onClick={this.clearValue}>
          {translate('admin.news.form.reset')}
        </button>
      </div>
    );
  }
}

NewsFormCalendar.propTypes = {
  heading: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

NewsFormCalendar.defaultProps = {
  heading: 'heading',
  value: '',
  onChange: () => {},
  isRequired: false
};

export default withTranslation(NewsFormCalendar);
