import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation, SelectBox } from '../common';

class RoomFilterType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false
    };
  }

  showMore = () => {
    this.setState({ showMore: true });
  };

  renderRoomType = roomType => {
    const { onChange } = this.props;
    return (
      <SelectBox
        key={roomType.key}
        className="TypeBox"
        selected={roomType.enabled}
        onClick={() => onChange(roomType)}
      >
        {roomType.name}
      </SelectBox>
    );
  };

  render() {
    const { translate, roomTypes } = this.props;
    const { showMore } = this.state;

    const slicedRoomTypes = showMore ? roomTypes : roomTypes.slice(0, 4);
    return (
      <div className="RoomFilter__Type">
        <div className="RoomFilter__Title">{translate('roomFilter.roomTypesTitle')}</div>
        <div className="RoomFilter__Section">
          <div className="RoomFilter__Content TypeBoxes">
            {slicedRoomTypes.map(roomType => this.renderRoomType(roomType))}
          </div>
        </div>
        <div className="RoomFilter__ShowMore">
          {!showMore && (
            <button className="link" role="link" tabIndex={0} onClick={this.showMore} type="button">
              {translate('roomFilter.showRoomTypes')}
            </button>
          )}
        </div>
      </div>
    );
  }
}

RoomFilterType.propTypes = {
  roomTypes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RoomFilterType);
