import actions from './actions';
import { resourceDetailService } from '../../services';

const { updateResource, updateLoadingResource } = actions;

const loadResource = id => async dispatch => {
  dispatch(updateLoadingResource(true));
  let resource;
  try {
    resource = await resourceDetailService.get(id);
    dispatch(updateResource(resource));
  } catch (serverErr) {
    dispatch(updateLoadingResource(false));
  }

  dispatch(updateLoadingResource(false));
  return resource;
};

export default {
  loadResource
};
