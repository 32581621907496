import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import cx from 'classnames';
import omit from 'lodash/fp/omit';
import isEqual from 'lodash/fp/isEqual';

import { isEscape, isEnter, isUpwards, isDownwards } from '../../../util';
import {
  getNextFocusedItems,
  getPreviousFocusedItems,
  findByValue
} from '../Input/AutoComplete/autocompleteUtil';

import AutoCompleteDropdown from '../Input/AutoComplete/AutoCompleteDropdown';

import './_style.css';

class ChangeRequestFinderPicker extends Component {
  constructor(props) {
    super(props);

    const { changeRequestInfo } = props;

    this.state = {
      focused: false,
      term: (
        changeRequestInfo && changeRequestInfo.id && changeRequestInfo.name && changeRequestInfo.resourceType
        ? `${changeRequestInfo.id} ${changeRequestInfo.name}, ${changeRequestInfo.resourceType}` : ''),
      suggestions: []
    };
  }

  UNSAFE_componentWillReceiveProps({ suggestions: nextSuggestions, changeRequestInfo: nextChangeRequestInfo }) {
    const { suggestions: prevSuggestions, changeRequestInfo: prevChangeRequestInfo } = this.props;
    if (prevSuggestions !== nextSuggestions) {
      this.setState({ suggestions: nextSuggestions });
    }

    if (!isEqual(prevChangeRequestInfo, nextChangeRequestInfo)) {
      this.setState({ term: (
        nextChangeRequestInfo
        && nextChangeRequestInfo.id
        && nextChangeRequestInfo.name
        && nextChangeRequestInfo.resourceType
        ? `${nextChangeRequestInfo.id}, ${nextChangeRequestInfo.name}, ${nextChangeRequestInfo.resourceType}` : '')
      });
    }
  }

  onChange = event => {
    const { loadSuggestions } = this.props;
    const term = event.target.value;
    this.setState({ term });
    loadSuggestions(term);
  };

  onSelect = value => {
    const { loadSuggestions, history } = this.props;
    const changeRequestInfo = omit(['focused'], value);
    this.setState({ term: `${changeRequestInfo.id} ${changeRequestInfo.name}, ${changeRequestInfo.resourceType}` });
    history.push(`/resources/${changeRequestInfo.id}/changeRequest`);
    loadSuggestions('');
  };

  onKeyDown = event => {
    const { keyCode } = event;
    const { suggestions } = this.state;

    if (isEscape(keyCode)) {
      this.exit();
    }

    if (isUpwards(keyCode) || isDownwards(keyCode)) {
      event.preventDefault();
      const newSuggestions = isDownwards(keyCode)
        ? getNextFocusedItems(suggestions)
        : getPreviousFocusedItems(suggestions);
      this.setState({ suggestions: newSuggestions, focused: true });
    }

    if (isEnter(keyCode)) {
      event.preventDefault();
      const selectedChangeRequestInfo = suggestions.find(s => s.focused);
      if (selectedChangeRequestInfo) {
        const changeRequestInfo = findByValue(selectedChangeRequestInfo, suggestions);
        if (changeRequestInfo) {
          this.onSelect(changeRequestInfo);
          this.exit();
        }
      }
    }
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  exit = () => {
    this.setState({
      focused: false
    });
  };

  handleClickOutside = () => {
    this.exit();
  };

  render() {
    const { term, focused, suggestions } = this.state;
    const { className, isLoading, placeholder } = this.props;

    const classes = cx(
      className,
      'ChangeRequestFinderPicker',
      'input-field input-field--autocomplete input-field--fixed',
      {
        'is-open': focused && suggestions.length > 0
      }
    );

    const props = {
      type: 'text',
      value: term,
      placeholder,
      onFocus: this.onFocus,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      ref: node => {
        this.input = node;
      }
    };
    return (
      <div className={classes} onFocus={this.onFocus}>
        <input {...props} />
        <AutoCompleteDropdown
          items={term === '' ? [] : suggestions}
          value={term}
          onChange={this.onSelect}
          onKeyDown={this.onKeyDown}
          showLoader
          loading={isLoading}
        />
      </div>
    );
  }
}

const changeRequestInfoPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired
});

ChangeRequestFinderPicker.propTypes = {
  placeholder: PropTypes.string,
  changeRequestInfo: changeRequestInfoPropType,
  isLoading: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(changeRequestInfoPropType),
  loadSuggestions: PropTypes.func.isRequired,
  className: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(onClickOutside(ChangeRequestFinderPicker));
