import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomPropTypes, RoomType, Favorite, withTranslation } from '../../../../common';
import Availability from '../Availability';
import RoomEquipment from '../RoomEquipment';

import './_style.css';

function Room({ resource, translate, alternativeType, showRelativeTime }) {
  const { id, name, building, capacity, availabilities, resourceSubType, roomEquipment } = resource;

  const showAlternativeTime = alternativeType === 'time';
  const showAlternativeFloor = alternativeType === 'floor';
  const showAlternativeSector = alternativeType === 'sector';
  const showAlternativeCapacity = alternativeType === 'capacity';
  const showAlternativeEquipment = alternativeType === 'roomequipment';

  const nameClassName = cx('Room__Name', {
    'Room__Name--warning': showAlternativeFloor || showAlternativeSector
  });
  const personsClassName = cx('Room__People', {
    'Room__People--warning': showAlternativeCapacity
  });

  const persons = `${capacity || '?'} ${translate('common.people')}`;

  return (
    <div className="Room">
      <div className="Room__Header">
        <div className={nameClassName}>{name}</div>
        <div className="Room__Favorite">
          <Favorite resourceId={id} tooltip={false} medium />
        </div>
      </div>
      <div className="Room__Body">
        <div className="Room__Building">{building.key}</div>
        <div className={personsClassName}>{persons}</div>
      </div>
      <div className="Room__Body">
        <RoomType className="Room__Type" resourceSubType={resourceSubType} />
        <div className="Room__Time">
          <Availability
            availabilities={availabilities}
            showRelativeTime={showRelativeTime}
            showAlternativeTime={showAlternativeTime}
          />
        </div>
      </div>
      <div className="Room__Tray">
        <RoomEquipment
          equipment={roomEquipment}
          showAlternativeEquipment={showAlternativeEquipment}
        />
      </div>
    </div>
  );
}

Room.propTypes = {
  resource: CustomPropTypes.resource,
  translate: PropTypes.func.isRequired,
  showRelativeTime: PropTypes.bool.isRequired,
  alternativeType: PropTypes.string
};

Room.defaultProps = {
  alternativeType: ''
};

export default withTranslation(Room);
