import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bookOperations } from '../../../state/book';
import { searchSelectors } from '../../../state/search';
import { CustomPropTypes } from '../../common';
import { RoomCardRecurrence } from './SearchResultCard/RoomCard';
import { EquipmentCardRecurrence } from './SearchResultCard/EquipmentCard';

function SearchResultRecurrence(props) {
  const { resource, style, openModal, updateDate, updateTime, resetState } = props;

  const onClick = event => {
    const { dataset } = event.target;
    if (dataset.ignore) {
      return;
    }

    if (resource.availabilities && resource.availabilities.length > 0) {
      const { searchedDate, searchedTime } = props;

      resetState();
      updateDate(searchedDate);
      updateTime(searchedTime);
      openModal(resource);
    }
  };

  return (
    <div role="button" tabIndex="0" className="Book__SearchResult" style={style} onClick={onClick}>
      {resource.resourceType === 'Room' && <RoomCardRecurrence resource={resource} />}
      {resource.resourceType === 'Equipment' && <EquipmentCardRecurrence resource={resource} />}
    </div>
  );
}

const mapStateToProps = state => ({
  searchedDate: searchSelectors.getDate(state),
  searchedTime: searchSelectors.getTime(state)
});

const mapDispatchToProps = dispatch => ({
  updateDate: date => dispatch(bookOperations.updateDate(date)),
  updateTime: time => dispatch(bookOperations.updateTime(time)),
  resetState: () => dispatch(bookOperations.resetState())
});

SearchResultRecurrence.propTypes = {
  style: PropTypes.shape(),
  resource: CustomPropTypes.resource,
  openModal: PropTypes.func.isRequired,
  updateDate: PropTypes.func.isRequired,
  updateTime: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  searchedDate: PropTypes.string,
  searchedTime: CustomPropTypes.time
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultRecurrence);
