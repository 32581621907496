import types from './types';

const mapResourceData = resource => ({
  type: types.MAP_RESOURCE_DATA,
  resource
});

const updateFormValue = (field, value) => ({
  type: types.UPDATE_FORM_VALUE,
  field,
  value
});

const updateSubmitted = () => ({
  type: types.UPDATE_SUBMITTED
});

const updateContactIsLoading = isLoading => ({
  type: types.UPDATE_CONTACT_ISLOADING,
  isLoading
});

const updateContactSuggestions = suggestions => ({
  type: types.UPDATE_CONTACT_SUGGESTIONS,
  suggestions
});

const updateIsSubmitting = isSubmitting => ({
  type: types.UPDATE_IS_SUBMITTING,
  isSubmitting
});

const initializeDetail = changeRequest => ({
  type: types.INITIALIZE_DETAIL,
  changeRequest
});

export default {
  mapResourceData,
  updateFormValue,
  updateContactIsLoading,
  updateContactSuggestions,
  updateSubmitted,
  updateIsSubmitting,
  initializeDetail
};
