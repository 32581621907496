/* eslint no-bitwise: ["error", { "allow": ["&=", "<<"] }] */
import omit from 'lodash/fp/omit';
import isObject from 'lodash/fp/isObject';

export const hashCode = str => {
  let hash = 0;
  if (str.length === 0) return hash;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash &= hash; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

export const hashObject = (value, exclude) => {
  if (!isObject) {
    throw new Error(`${value} is not an object`);
  }

  const obj = exclude ? omit(exclude, value) : value;
  return hashCode(JSON.stringify(obj));
};
