import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation, ChangeDetector, Input } from '../../common';

import './_style.css';

function FormField({ values, update, field, isSubmitted, optional, detectChanges, translate }) {
  const updateValue = event => update(field, event.target.value);
  const labelText = translate(`changeRequests.fieldNames.${field}`);

  const getOptionalLabel = () => {
    const optionalText = translate('changeRequests.optional');
    return (
      <span>
        {labelText}
        {' '}
        <span className="ChangeRequestFormField__OptionalLabel">{optionalText}</span>
      </span>
    );
  };

  const invalid = isSubmitted && !optional && (!values.new || values.new === '');

  const label = !optional ? labelText : getOptionalLabel();

  return (
    <div className="ChangeRequestFormField">
      <ChangeDetector values={values} invalid={invalid} detectChanges={detectChanges}>
        <Input label={label} onChange={updateValue} value={values.new} error={invalid} />
      </ChangeDetector>
    </div>
  );
}

FormField.propTypes = {
  values: PropTypes.shape({
    old: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    new: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  field: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  optional: PropTypes.bool,
  detectChanges: PropTypes.bool,
  isSubmitted: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

FormField.defaultProps = {
  optional: false,
  detectChanges: true
};

export default withTranslation(FormField);
