import React from 'react';
import { CustomPropTypes, RoomType } from '../../../common';
import './_style.css';

function RoomNameAndType({ room }) {
  const { displayName, building, resourceSubType } = room;

  return (
    <div>
      <div className="RoomNameAndType__Room">
        <span className="RoomNameAndType__RoomName">{`${displayName},`}</span>
        <span>&nbsp;</span>
        <span className="RoomNameAndType__RoomBuilding">{building.key}</span>
      </div>
      <p>
        <RoomType resourceSubType={resourceSubType} />
      </p>
    </div>
  );
}

RoomNameAndType.propTypes = { room: CustomPropTypes.resource };

export default RoomNameAndType;
