import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from '../Icon/Icon';
import './_style.css';

function EmptyState({ visible, icon, content, className, centered }) {
  if (!visible) {
    return null;
  }

  const classes = cx(className, 'EmptyState', {
    'EmptyState--centered': centered
  });

  return (
    <div className={classes}>
      <div className="EmptyState__Wrapper">
        <Icon icon={icon} className="EmptyState__Icon" />
        <div>{content}</div>
      </div>
    </div>
  );
}

EmptyState.propTypes = {
  visible: PropTypes.bool,
  className: PropTypes.string,
  centered: PropTypes.bool,
  content: PropTypes.node.isRequired,
  icon: PropTypes.string
};

EmptyState.defaultProps = {
  centered: true,
  icon: '237-robot-relaxed'
};

export default EmptyState;
