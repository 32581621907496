// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".News_body__e6wI8 {\n  min-width: 60px;\n  display: flex;\n}\n\n.News_sidebar__d8Gii {\n  background-color: #dadada;\n  width: 60px;\n}\n", "",{"version":3,"sources":["webpack://./src/views/Search/SearchDesktop/Content/Now/News/News.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":[".body {\n  min-width: 60px;\n  display: flex;\n}\n\n.sidebar {\n  background-color: #dadada;\n  width: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "News_body__e6wI8",
	"sidebar": "News_sidebar__d8Gii"
};
export default ___CSS_LOADER_EXPORT___;
