import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Portal from '../Portal';
import DialogContent from './DialogContent';
import { modalSelectors } from '../../../state/modal';

function Dialog(props) {
  const { dialogKey, activeDialogKey, mobile, children } = props;

  if (dialogKey !== activeDialogKey) {
    return null;
  }

  if (mobile) {
    return <Portal id="dialog">{children}</Portal>;
  }

  return (
    <Portal id="dialog" className="Dialog__Overlay" role="presentation">
      <DialogContent {...props} />
    </Portal>
  );
}

Dialog.propTypes = {
  dialogKey: PropTypes.string.isRequired,
  activeDialogKey: PropTypes.string,
  mobile: PropTypes.bool,
  children: PropTypes.element.isRequired
};

Dialog.defaultProps = {
  mobile: false
};

const mapStateToProps = state => ({
  activeDialogKey: modalSelectors.getActiveModalKey(state)
});

export default connect(mapStateToProps)(Dialog);
