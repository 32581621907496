import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import omit from 'lodash/fp/omit';
import find from 'lodash/fp/find';

import identifier from '../identifier';
import Spacing from '../Spacing/Spacing';

const firstChecked = find('checked');

const getCheckedValue = children => {
  const checked = firstChecked(React.Children.map(children, child => child.props));
  return checked ? checked.value : null;
};

class RadioGroup extends Component {
  constructor(props) {
    super(props);

    const { value, defaultValue, children } = props;
    this.state = {
      value: value || defaultValue || getCheckedValue(children) || '',
      id: identifier('RadioGroup')
    };

    this.onChange = this.onChange.bind(this);
  }

  getChildContext() {
    const { disabled } = this.props;
    const { value } = this.state;
    return {
      radioGroup: {
        value,
        disabled,
        onChange: this.onChange
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value } = this.props;
    if (nextProps.value !== value) {
      this.setState({ value: nextProps.value });
    }
  }

  onChange(event) {
    const { value } = event.target;
    const { value: lastValue } = this.state;
    if (value !== lastValue) {
      this.setState({ value });

      const { onChange } = this.props;
      if (onChange) {
        onChange(event);
      }
    }
  }

  renderRadioGroup = () => {
    const { children, horizontal, label, ...props } = this.props;
    const { id } = this.state;
    const otherProps = omit(['onChange', 'disabled', 'value', 'defaultValue'], props);

    const classes = cx('RadioGroup', {
      'RadioGroup--horizontal': horizontal
    });
    if (!horizontal && label) {
      return (
        <Spacing horizontal={0.5}>
          <div id={id} className={classes} {...otherProps}>
            {children}
          </div>
        </Spacing>
      );
    }

    return (
      <div id={id} className={classes} {...otherProps}>
        {children}
      </div>
    );
  };

  render() {
    const { label } = this.props;
    const { id } = this.state;

    return (
      <>
        {label && <label htmlFor={id}>{label}</label>}
        {this.renderRadioGroup()}
      </>
    );
  }
}

RadioGroup.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  horizontal: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
};

RadioGroup.defaultProps = {
  disabled: false,
  horizontal: false
};

RadioGroup.childContextTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  radioGroup: PropTypes.any
};

export default RadioGroup;
