import React from 'react';
import PropTypes from 'prop-types';

import SidebarFormField from './SidebarFormField';

function SidebarDate({ date }) {
  return <SidebarFormField label="common.date" element={date} />;
}

SidebarDate.propTypes = {
  date: PropTypes.string
};

export default SidebarDate;
