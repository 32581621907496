/* eslint-disable no-confusing-arrow */

import { createSelector } from 'reselect';
import moment from 'moment';

import selectors from '.';
import { exceptionsSelectors } from '../../exceptions';
import { recurrenceSelectors } from '../../recurrence';

import {
  isTimeRangeValid,
  isTimeValid,
  isDateValid,
  isDateInPast,
  toDateTime,
  toDate
} from '../../../util/time';
import config from '../../../config';

const getDate = state => state.bookState.book.date;
const getTime = state => state.bookState.book.time;

const isSubmitting = state => state.bookState.book.isSubmitting;

const getStartDateTime = createSelector(
  [getDate, getTime],
  (date, { from }) => toDateTime(toDate(date), from)
);

const getEndDateTime = createSelector(
  [getDate, getTime],
  (date, { to }) => toDateTime(toDate(date), to)
);

const getSubject = state => state.bookState.book.subject;
const getMessage = state => state.bookState.book.message;
const getReminder = state => state.bookState.book.reminder;
const getValidTimeRange = state => state.bookState.book.isValidTimeRange;

const getAdditionalEquipment = state => state.bookState.book.additionalEquipment;

const getSeating = state => state.bookState.book.seating;
const getSeatingId = state => state.bookState.book.seatingId;

const isMessageValid = createSelector(
  getMessage,
  message => message.length < 2047
);

const isSubjectValid = createSelector(
  getSubject,
  subject => subject && subject.length > 0 && subject.length < 255
);

const isValidTimeRange = createSelector(
  getValidTimeRange,
  timeRange => timeRange
);

const getSelectedResource = state => state.bookState.book.resource;

const isValidDate = createSelector(
  getDate,
  date => date === '' || (isDateValid(date) && !isDateInPast(date))
);

const isValidTime = createSelector(
  getTime,
  ({ from, to }) => (from === '' && to === '')
    || (isTimeValid(from) && isTimeValid(to) && isTimeRangeValid(from, to))
);

const isDateAfterMax = createSelector(
  [getDate, isValidDate],
  (date, valid) => {
    if (date === '') {
      return false;
    }

    if (!valid) {
      return false;
    }

    const maxDate = moment().add(config.maxDaysInFuture, 'day');
    return moment(toDate(date)).isAfter(maxDate);
  }
);

// eslint-disable-next-line arrow-body-style
const getAvailabilitiesLoaded = state => {
  return state.bookState.book.resource ? state.bookState.book.resource.availabilitiesLoaded : false;
};

const getStartTime = state => state.bookState.book.start;
const getEndTime = state => state.bookState.book.end;
const isPrivate = state => state.bookState.book.isPrivate;
const isRecurring = state => state.bookState.book.isRecurring;
const isMyBooking = state => state.bookState.book.isMyBooking;
const isBookingCollection = state => state.bookState.book.isBookingCollection;
const isUnhandledRecurrenceException = state => state.bookState.book.isUnhandledRecurrenceException;
const getRoomMeetingResponseType = state => state.bookState.book.roomMeetingResponseType;
const getGlobalId = state => state.bookState.book.globalId;
const getCalendarId = state => state.bookState.book.calendarId;
const getAvailabilities = state => state.bookState.book.resource.availabilities;
const getExceptions = state => state.bookState.book.resource.exceptions;

const isReadyToSubmit = createSelector(
  [
    getAvailabilitiesLoaded,
    recurrenceSelectors.isRecurrenceEnabled,
    exceptionsSelectors.hasUnhandledExceptions,
    isMessageValid,
    isSubjectValid,
    isValidTimeRange,
    isDateAfterMax,
    isValidDate
  ],
  (
    availabilitiesLoaded,
    isRecurrence,
    hasUnhandledExceptions,
    messageValid,
    subjectValid,
    timeRangeValid,
    dateAfterMax,
    dateValid
  ) => {
    if (isRecurrence && !availabilitiesLoaded) {
      return false;
    }

    return (
      (messageValid
        && subjectValid
        && timeRangeValid
        && !hasUnhandledExceptions
        && !dateAfterMax
        && dateValid)
      || false
    );
  }
);

const getRecurrenceRequestInfo = createSelector(
  [getCalendarId, isRecurring],
  (calendarId, recurring) => ({
    calendarId,
    isRecurring: recurring,
    isOccurrence: recurring
  })
);

const getBookRequest = state => {
  const isRecurrence = recurrenceSelectors.getRecurrenceType(state) !== 'never';
  const bookedForPerson = selectors.getBookForPerson(state);
  const attendees = selectors.getSelectedAttendees(state);
  const exceptions = isRecurrence ? exceptionsSelectors.getNewExceptions(state) : [];
  const start = getStartDateTime(state);
  const end = getEndDateTime(state);

  return {
    resourceId: getSelectedResource(state).id,
    globalId: getGlobalId(state),
    subject: getSubject(state) || 'Booked with ReservationHub',
    start,
    end,
    message: getMessage(state),
    reminder: getReminder(state),
    seatingId: getSeatingId(state),
    exceptions,
    attendeeMails: attendees.map(attendee => attendee.email),
    bookedFor: (bookedForPerson && bookedForPerson.email) || null,
    recurrence: isRecurrence ? recurrenceSelectors.getRecurrenceRequest(state) : null,
    isRecurring: isRecurrence
  };
};

export default {
  getDate,
  getTime,
  isValidTimeRange,
  isSubmitting,
  getStartDateTime,
  getEndDateTime,
  getSelectedResource,
  isValidDate,
  isValidTime,
  getMessage,
  getSubject,
  getReminder,
  isMessageValid,
  isSubjectValid,
  isDateAfterMax,
  isReadyToSubmit,
  isBookingCollection,
  getAdditionalEquipment,
  getStartTime,
  getEndTime,
  isRecurring,
  isPrivate,
  isMyBooking,
  isUnhandledRecurrenceException,
  getGlobalId,
  getCalendarId,
  getBookRequest,
  getRecurrenceRequestInfo,
  getRoomMeetingResponseType,
  getAvailabilities,
  getExceptions,
  getSeating,
  getSeatingId
};
