import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../../../common';

import styles from './Regions.module.css';

function RegionsLoader({ visible }) {
  if (!visible) {
    return null;
  }

  return (
    <div className={styles.Regions__Loader}>
      <Loader visible />
    </div>
  );
}

RegionsLoader.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default RegionsLoader;
