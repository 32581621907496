import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation, Input, Button } from '../../../common';
import { regionsOperations } from '../../../../state/regions';
import { authSelectors } from '../../../../state/auth';
import Header from '../../../Header';
import formValidation from '../common';

class RegionsCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        key: '',
        name: ''
      },
      isValid: {
        key: true,
        name: true
      },
      formIsValid: false
    };

    this.validation = {
      key: value => value !== '' && /^[^\s]+$/.test(value), // not empty and no spaces
      name: value => value !== '' // not empty
    };
  }

  componentDidMount() {
    const { isAdmin, history } = this.props;
    if (!isAdmin) {
      history.replace('/unauthorized');
    }
  }

  renderKey = () => {
    const { form, isValid } = this.state;
    const { translate } = this.props;

    const handleChange = event => {
      const { value } = event.target;
      formValidation.validateField(this, 'key', value);
      formValidation.validateForm(this);
    };

    return (
      <>
        <Input
          label={`${translate('common.key')} ${translate('common.requiredField')}`}
          placeholder={translate('admin.settings.regions.add.placeholder.key')}
          onChange={handleChange}
          error={!isValid.key}
          value={form.key}
          className={['input'].join(' ')}
        />
        {!isValid.key && formValidation.renderValidationError(this, 'common.requiredAndNoSpace')}
      </>
    );
  };

  renderName = () => {
    const { form, isValid } = this.state;
    const { translate } = this.props;

    const handleChange = event => {
      const { value } = event.target;
      formValidation.validateField(this, 'name', value);
      formValidation.validateForm(this);
    };

    return (
      <>
        <Input
          label={`${translate('common.name')} ${translate('common.requiredField')}`}
          placeholder={translate('admin.settings.regions.add.placeholder.name')}
          onChange={handleChange}
          error={!isValid.name}
          value={form.name}
          className="input"
        />
        {!isValid.name && formValidation.renderValidationError(this, 'common.required')}
      </>
    );
  };

  renderSubmitButton = () => {
    const { submit, isSubmitting, history, translate } = this.props;
    const { form, formIsValid } = this.state;

    const onClick = async event => {
      event.preventDefault();
      event.stopPropagation();

      if (formIsValid) {
        const result = await submit(form.key, form.name);
        if (result === 'Ok') {
          history.replace('/admin/settings/regions');
        }
      }
    };

    return (
      <div className="button--right">
        <Button
          icon="074-save"
          label={translate('common.create')}
          loading={isSubmitting}
          onClick={onClick}
          disabled={!formIsValid} />
      </div>
    );
  };

  render() {
    const { history, translate } = this.props;
    return (
      <>
        <Header
          onClose={() => { history.push('/admin/settings/regions'); }}
          title={translate('admin.settings.regions.add.title')}
          iconRight
          icon="022-close" />
        <div className="Create__Form">
          {this.renderKey()}
          {this.renderName()}
          {this.renderSubmitButton()}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: authSelectors.isAdministrator(state)
});

const mapDispatchToProps = dispatch => ({
  submit: (regionKey, regionName) => dispatch(regionsOperations.addRegion(regionKey, regionName))
});

RegionsCreate.propTypes = {
  isAdmin: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  submit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(RegionsCreate));
