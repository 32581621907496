import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../../../common';

import styles from '../_style.css';

function FloorsLoader({ visible }) {
  if (!visible) {
    return null;
  }

  return (
    <div className={styles.Floors__Loader}>
      <Loader visible />
    </div>
  );
}

FloorsLoader.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default FloorsLoader;
