import PropTypes from 'prop-types';
import { withTranslation } from '..';

function ValueLabel({ location, translate }) {
  const { type, name, key, buildingKey } = location;
  if (type === 'Building') {
    return key;
  }

  if (type === 'Floor' || type === 'Sector') {
    const fullName = translate(`common.${type.toLowerCase()}`, { name });
    return `${buildingKey}, ${fullName}`;
  }

  return name;
}

export default withTranslation(ValueLabel);

ValueLabel.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }),
  translate: PropTypes.func.isRequired
};
