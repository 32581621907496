import types from './types';

const updateChangeRequests = changeRequests => ({
  type: types.UPDATE_CHANGEREQUESTS,
  changeRequests
});

const updateIsLoading = isLoading => ({
  type: types.UPDATE_ISLOADING,
  isLoading
});

export default {
  updateChangeRequests,
  updateIsLoading
};
