const DEFAULT_PREFIX = 'reservationhub';

let count = 0;
const next = () => {
  count += 1;
  return count;
};

export default function generate(prefix) {
  const counter = next();
  const id = prefix ? `${ prefix }-${ counter }` : `${ DEFAULT_PREFIX }-${ counter }`;
  return id.replace(/\s+/g, '-');
}

const reset = () => {
  count = 0;
};

export { reset };
