import React from 'react';
import PropTypes from 'prop-types';
import { getPropTypes } from './Language';
import LanguageSelectionItem from './LanguageSelectionItem';

function LanguageSelection(props) {
  const { selectedLanguage, availableLanguages, className } = props;
  const LanguageSelectionItems = [];

  availableLanguages.forEach((value, index, array) => {
    LanguageSelectionItems.push(
      <LanguageSelectionItem
        key={value}
        selectedLanguage={value}
        className={value === selectedLanguage ? 'Language__SelectionItem--selected' : ''}
      />
    );
    if (index + 1 !== array.length) {
      LanguageSelectionItems.push(<hr className="Language__SelectionLine" key={`${value}hr`} />);
    }
  });
  return <div className={className}>{LanguageSelectionItems}</div>;
}

LanguageSelection.propTypes = { className: PropTypes.string, ...getPropTypes };

export default LanguageSelection;
