import time from './time/propTypes';
import room from './room/propTypes';
import equipment from './equipment/propTypes';
import resource from './resource/propTypes';
import master from './master/propTypes';
import exception from './exception/propTypes';
import booking from './booking/propTypes';
import location from './location/propTypes';
import adminMessage from './adminMessage/propTypes';
import changeRequest from './changeRequest/propTypes';

export default {
  time,
  room,
  equipment,
  resource,
  master,
  exception,
  booking,
  location,
  adminMessage,
  changeRequest
};
