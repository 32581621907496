import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '../common';

import './_style.css';

function MyBookingsLoader({ visible }) {
  if (!visible) {
    return null;
  }

  return (
    <div className="MyBookings__Loader">
      <Loader visible />
    </div>
  );
}

MyBookingsLoader.propTypes = { visible: PropTypes.bool };

export default MyBookingsLoader;
