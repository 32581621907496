import { combineReducers } from 'redux';

import exceptionsReducers from './alternativesReducers';
import resourcesReducers from './resourcesReducers';
import bookingCollectionReducers from './bookingCollectionReducers';

export default combineReducers({
  alternatives: exceptionsReducers,
  resources: resourcesReducers,
  bookingCollection: bookingCollectionReducers
});
