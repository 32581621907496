import { lookupTranslation } from '../../util';
import { i18nSelectors } from '../i18n';

const getTranslations = state => lookupTranslation(i18nSelectors.getTranslations(state));

const getError = state => state.notificationsState.error;
const getSuccess = state => state.notificationsState.success;

const getNotification = state => (path, error = null) => {
  const translate = getTranslations(state);

  if (error) {
    console.error( error ); // eslint-disable-line
  }

  if (error && error.status === 400) {
    const validationErrors = error.response && error.response.validationErrors;
    if (!validationErrors || validationErrors.length > 1) {
      return getNotification(state)('common.error');
    }

    return {
      title: translate('common.error.title'),
      text: validationErrors[0].errorMessage
    };
  }

  if (error && error.response && error.status === 500) {
    return {
      title: translate('common.error.title'),
      text: error.response
    };
  }

  if (error && error.response && error.status === 503) {
    return {
      title: translate('common.error.title'),
      text: error.response.errorMessage
    };
  }

  return {
    title: translate(`${ path }.title`),
    text: translate(`${ path }.text`)
  };
};

export default {
  getError,
  getSuccess,
  getNotification
};
