import isInteger from 'lodash/fp/isInteger';
import moment from 'moment';

const rxDate = /^(\d{1,2})[.](\d{1,2})[.](\d{4})$/;
const rxTime = /^(\d{2})[:](\d{2})$/;

export const toTwoDigits = n => {
  const value = isInteger(n) ? n : parseInt(n, 10);
  return value < 10 ? `0${value}` : `${value}`;
};

export const toLocaleDateString = date => {
  const dd = toTwoDigits(date.getDate());
  const mm = toTwoDigits(date.getMonth() + 1);
  const yyyy = date.getFullYear();
  return `${dd}.${mm}.${yyyy}`;
};

export const toTimeString = date => {
  const hh = toTwoDigits(date.getHours());
  const MM = toTwoDigits(date.getMinutes());
  return `${hh}:${MM}`;
};

export const getCurrentDate = () => {
  const today = new Date();
  return toLocaleDateString(today);
};

export const getFirstWeekDay = () => {
  const firstWeekDate = moment()
    .startOf('isoweek')
    .toDate();
  return toLocaleDateString(firstWeekDate);
};

export const getCurrentTime = () => {
  const now = new Date();
  return toTimeString(now);
};

export const isDateValid = value => {
  if (value == null) {
    return false;
  }

  const date = value.trim();
  if (!date) {
    return false;
  }

  const regs = date.match(rxDate);
  if (!regs) {
    return false;
  }

  // day value between 1 and 31
  if (regs[1] < 1 || regs[1] > 31) {
    return false;
  }

  // month value between 1 and 12
  if (regs[2] < 1 || regs[2] > 12) {
    return false;
  }

  // year value between 2000 and 2099
  if (regs[3] < 2000 || regs[3] > 2099) {
    return false;
  }

  const validationDate = new Date(regs[3], regs[2] - 1, regs[1]);
  return (
    validationDate
    && (validationDate.getFullYear().toString() === regs[3]
      && toTwoDigits(validationDate.getMonth() + 1) === toTwoDigits(regs[2])
      && toTwoDigits(validationDate.getDate()) === toTwoDigits(regs[1]))
  );
};

export const getHoursFromString = value => {
  const regs = value.match(rxTime);
  return regs[1];
};

export const getMinutesFromString = value => {
  const regs = value.match(rxTime);
  return regs[2];
};

export const isTimeValid = value => {
  const time = value && value.trim();
  if (!time) {
    return false;
  }

  const regs = time.match(rxTime);
  if (!regs) {
    return false;
  }

  // hour value between 0 and 23
  if (getHoursFromString(value) < 0 || getHoursFromString(value) > 23) {
    return false;
  }

  // minute value between 0 and 60
  if (getMinutesFromString(value) < 0 || getMinutesFromString(value) > 60) {
    return false;
  }

  return true;
};

export const toDate = value => {
  if (!isDateValid(value)) {
    throw new Error(`${value} is not a valid date`);
  }

  const regs = value.trim().match(rxDate);
  return new Date(regs[3], regs[2] - 1, regs[1]);
};

export const toDateTime = (date, value) => {
  if (!isTimeValid(value)) {
    throw new Error(`${value} is not a valid time`);
  }

  date.setHours(getHoursFromString(value));
  date.setMinutes(getMinutesFromString(value));

  return date;
};

export const isDateInPast = value => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const date = toDate(value);
  return date < now;
};

export const isTimeRangeValid = (from, to) => {
  if (!from || !to) {
    return false;
  }
  if (from && to) {
    return to > from;
  }
  return false;
};

export const convertMsToMinutes = value => Math.round(value / 1000 / 60);

export const roundToFirstDateOfWeek = date => {
  const startOfWeek = moment(date)
    .startOf('isoweek')
    .toDate();
  return startOfWeek;
};

export const roundToLastDateOfWeek = date => {
  const endOfWeek = moment(date)
    .endOf('isoweek')
    .toDate();
  return endOfWeek;
};

export const oneHour = 1000 * 60 * 60;

export const tenMinutes = 1000 * 60 * 10;

export const fifteenMinutes = 1000 * 60 * 15;

export const isToday = (date = getCurrentDate()) => date === getCurrentDate();

export const toMoment = date => moment(toDate(date));
