import React from 'react';
import PropTypes from 'prop-types';

import Recurrence from '../../Recurrence';
import Header from '../../Header';
import { withTranslation } from '../../common';

import './_style.css';

function RecurrencePatternModalMobile({ onSave, translate }) {
  const title = translate('search.recurrence.title');
  return (
    <div className="RecurrencePatternModalMobile">
      <Header title={title} onClose={onSave} dynamic fixedMobile icon="014-chevron-left" />
      <div className="RecurrencePatternModalMobile__Content">
        <Recurrence />
      </div>
    </div>
  );
}

RecurrencePatternModalMobile.propTypes = {
  onSave: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurrencePatternModalMobile);
