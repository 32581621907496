import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ShowAt, CustomPropTypes } from '../../../../../common';
import { searchSelectors } from '../../../../../../state/search';
import { availabilityOperations } from '../../../../../../state/availability';

import RoomCardRecurrenceDesktop from './RoomCardRecurrenceDesktop';
import RoomCardRecurrenceTablet from './RoomCardRecurrenceTablet';
import RoomCardRecurrenceMobile from './RoomCardRecurrenceMobile';

import { isProfile2, isProfile3 } from '../../../../../../util';

class RoomCardRecurrence extends Component {
  componentDidMount() {
    const { getSingleAvailability, resource } = this.props;
    if (!resource.availabilitiesLoaded) {
      getSingleAvailability(resource);
    }
  }

  render() {
    const { resource } = this.props;
    const profile2 = isProfile2(resource);
    const profile3 = isProfile3(resource);

    const roomCardProps = {
      ...this.props,
      isProfile2: profile2,
      isProfile3: profile3
    };

    return (
      <div className="SearchResultCard">
        <ShowAt breakpoint="600AndBelow">
          <RoomCardRecurrenceMobile {...roomCardProps} />
        </ShowAt>
        <ShowAt breakpoint="600AndAbove 900AndBelow">
          <RoomCardRecurrenceTablet {...roomCardProps} />
        </ShowAt>
        <ShowAt breakpoint="900AndAbove">
          <RoomCardRecurrenceDesktop {...roomCardProps} />
        </ShowAt>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showTimeInput: searchSelectors.isRelativeTime(state)
});

const mapDispatchToProps = dispatch => ({
  getSingleAvailability: resource => dispatch(availabilityOperations.loadSingleAvailability(resource))
});

RoomCardRecurrence.propTypes = {
  resource: CustomPropTypes.resource,
  getSingleAvailability: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomCardRecurrence);
