// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsFormCalendar_dateBody__DxQvy {\n  padding: 10px 5px;\n}\n\n.NewsFormCalendar_dateHeading__1b7R4 {\n  margin-bottom: 4px;\n  max-width: 100%;\n  color: var(--color-black);\n  font-weight: 500;\n  font-size: 1rem;\n}\n\n.NewsFormCalendar_datePicker__zM8K1 {\n  width: 300px;\n}\n\n.NewsFormCalendar_requiredText__2DDLd {\n  font-size: 13px;\n  color: var(--color-theme);\n}\n\n.NewsFormCalendar_input__NLbUA {\n  padding-bottom: 5px;\n}\n\n.NewsFormCalendar_link__jS1y6 {\n  font-size: 13px !important;\n}", "",{"version":3,"sources":["webpack://./src/views/Admin/NewsForm/NewsFormCalendar/NewsFormCalendar.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".dateBody {\n  padding: 10px 5px;\n}\n\n.dateHeading {\n  margin-bottom: 4px;\n  max-width: 100%;\n  color: var(--color-black);\n  font-weight: 500;\n  font-size: 1rem;\n}\n\n.datePicker {\n  width: 300px;\n}\n\n.requiredText {\n  font-size: 13px;\n  color: var(--color-theme);\n}\n\n.input {\n  padding-bottom: 5px;\n}\n\n.link {\n  font-size: 13px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateBody": "NewsFormCalendar_dateBody__DxQvy",
	"dateHeading": "NewsFormCalendar_dateHeading__1b7R4",
	"datePicker": "NewsFormCalendar_datePicker__zM8K1",
	"requiredText": "NewsFormCalendar_requiredText__2DDLd",
	"input": "NewsFormCalendar_input__NLbUA",
	"link": "NewsFormCalendar_link__jS1y6"
};
export default ___CSS_LOADER_EXPORT___;
