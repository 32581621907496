import { createSelector } from 'reselect';
import values from 'lodash/fp/values';
import sortBy from 'lodash/fp/sortBy';
import size from 'lodash/fp/size';
import cx from 'classnames';
import { authSelectors } from '../auth';
import { isProfile2, isProfile3 } from '../../util';
import { buildingsSelectors } from '../buildings';

const getRooms = state => state.timelineBookingsState.rooms;
const getBookings = state => state.timelineBookingsState.bookings;
const getRoomBookings = state => state.timelineBookingsState.roomBookings;
const getTotalCount = state => state.timelineBookingsState.totalCount;
const getSkipCount = state => state.timelineBookingsState.skipCount;
const isLoading = state => state.timelineBookingsState.isLoading;
const isLoadingMore = state => state.timelineBookingsState.isLoadingMore;
const getUser = state => authSelectors.getUser(state);
const getBuildings = state => buildingsSelectors.getBuildings(state);
const isExportLoading = state => state.timelineBookingsState.exportLoading;

const toLower = value => value.toLowerCase();
const isEqual = (value, other) => value && other && value.toLowerCase() === other.toLowerCase();
const hasPermission = (item, permission) => item.permissionTypes.map(toLower).indexOf(permission) > -1;

const getBookingItems = createSelector(
  [getBookings, getUser, getRooms, getBuildings],
  (bookings, user, rooms, buildings) => {
    const email = (user && user.profile.email) || '';
    const isBookedForMe = booking => booking.bookedFor != null && isEqual(booking.bookedFor.email, email);

    const createBookingItem = booking => {
      const room = rooms[booking.resourceId];
      const building = room ? buildings[room.buildingId] : null;
      const roomWithBuilding = room ? { ...room, building } : null;
      const myBooking = hasPermission(booking, 'owner');
      const tentative = booking.roomMeetingResponseType === 'Tentative'
        || booking.roomMeetingResponseType === 'Declined'
        || booking.roomMeetingResponseType === 'Unknown';

      const classes = cx({
        'CalendarBooking--my': myBooking,
        'CalendarBooking--virtual': booking.isVirtual,
        'CalendarBooking--forme': isBookedForMe(booking),
        'CalendarBooking--tentative': tentative
      });

      return {
        ...booking,
        id: booking.calendarId + booking.resourceId,
        title: booking.subject,
        resource: roomWithBuilding,
        group: booking.resourceId,
        type: 'range',
        isMyBooking: myBooking,
        isBookedForMe: isBookedForMe(booking),
        className: classes,
        canResize: myBooking ? 'both' : false,
        canMove: myBooking,
        canChangeGroup: myBooking,
        message: booking.message,
        attendeeMails: booking.attendees.map(attendee => attendee.email)
      };
    };
    return values(bookings).map(createBookingItem);
  }
);

const getRoomGroups = createSelector(
  [getRoomBookings, getRooms, getBuildings],
  (roomBookings, rooms, buildings) => {
    const sortedRooms = sortBy(item => roomBookings.indexOf(item.id), rooms);
    let index = 0;
    return values(sortedRooms).map(resource => {
      index += 1;

      const profile2 = isProfile2(resource);
      const profile3 = isProfile3(resource);
      const disabled = profile2 || profile3;

      return {
        ...resource,
        disabled,
        title: resource.name,
        order: index,
        building: buildings.find(building => building.id === resource.buildingId)
      };
    });
  }
);

const hasRooms = createSelector(
  getRooms,
  rooms => size(rooms) > 0
);

const hasMoreRooms = createSelector(
  getRooms,
  getTotalCount,
  (rooms, totalCount) => {
    const roomsCount = values(rooms).length;
    return roomsCount < totalCount;
  }
);

export default {
  hasRooms,
  getBookingItems,
  getRoomGroups,
  getRoomBookings,
  getTotalCount,
  getSkipCount,
  hasMoreRooms,
  isLoading,
  isLoadingMore,
  isExportLoading
};
