import { combineReducers } from 'redux';
import store from 'store2';

import types from './types';

const loadInitialState = () => {
  const regions = store('regions');
  const buildings = store('buildings');
  const isLoaded = store.has('regions') && store.has('buildings');
  const selectedRegion = null;
  return {
    regions,
    buildings,
    isLoaded,
    selectedRegion
  };
};

const initialState = loadInitialState();

const regionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_REGIONS: {
      return {
        ...state,
        regions: action.regions
      };
    }

    case types.UPDATE_LOADED: {
      return {
        ...state,
        isLoaded: true,
        age: action.age
      };
    }

    case types.SET_SELECTED_REGION: {
      return {
        ...state,
        selectedRegion: action.selectedRegion
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  regions: regionsReducer
});
