import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';
import { Spacing, ShowAt, withTranslation } from '../common';
import LanguageMobile from './LanguageMobile';
import LanguageDesktop from './LanguageDesktop';

function Language({ translate, ...props }) {
  const title = translate('language.title');
  return (
    <div>
      <ShowAt breakpoint="mobileAndBelow">
        <Header backlink title={title} />
        <Spacing horizontal="1" vertical="1">
          <LanguageMobile {...props} />
        </Spacing>
      </ShowAt>

      <ShowAt breakpoint="desktopAndAbove">
        <Header backlink />
        <Spacing horizontal="1" vertical="1">
          <LanguageDesktop {...props} />
        </Spacing>
      </ShowAt>
    </div>
  );
}

const getPropTypes = {
  selectedLanguage: PropTypes.string.isRequired,
  availableLanguages: PropTypes.array.isRequired
};

export { getPropTypes };

Language.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslation(Language);
