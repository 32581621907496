import bookOperations from './bookOperations';
import bookForOperations from './bookForOperations';
import attendeesOperations from './attendeesOperations';
import singleAvailabilityOperations from './singleAvailabilityOperations';

export default {
  ...bookOperations,
  ...bookForOperations,
  ...attendeesOperations,
  ...singleAvailabilityOperations
};
