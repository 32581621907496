import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Profile3, Profile2, CustomPropTypes } from '../../common';

import './_style.css';

function BookDetailProfile({ className, resource, responseType }) {
  const { profile } = resource;
  if (profile !== 2 && profile !== 3) {
    return null;
  }

  const renderProfileInformation = () => {
    if (profile === 2) {
      return <Profile2 responseType={responseType} resource={resource} />;
    }
    if (profile === 3) {
      return <Profile3 resource={resource} responseType={responseType} />;
    }
    return null;
  };

  const classes = profile === 2
    ? cx('BookDetailProfile', `BookDetailProfile--${responseType.toLowerCase()}`)
    : 'BookDetailProfile';

  return (
    <div className={className}>
      <div className={classes}>{renderProfileInformation()}</div>
    </div>
  );
}

BookDetailProfile.propTypes = {
  className: PropTypes.string,
  resource: CustomPropTypes.resource,
  responseType: PropTypes.string
};

export default BookDetailProfile;
