import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon, withTranslation, CustomPropTypes } from '../../../../../common';

import './_style.css';

function TooltipProfileInformation({ resource, responseType, translate }) {
  const { profile } = resource;
  const status = responseType.toLowerCase();
  const resourceType = resource.resourceType.toLowerCase();
  const displayName = resource.managedBy && resource.managedBy.displayName;

  const wrapperClass = 'CalendarBookingTooltip__ProfileInformation';

  const classes = cx(
    'TooltipProfileInformation',
    `TooltipProfileInformation--${responseType.toLowerCase()}`
  );

  function InformationIcon() {
  return <Icon icon="046-information-circle" className="TooltipProfileInformation__Icon" />;
}

  if (status === 'declined') {
    return (
      <div className={wrapperClass}>
        <div className={classes}>
          <InformationIcon />
          {translate(`search.detail.profile2.declined.${resourceType}`, { displayName })}
        </div>
      </div>
    );
  }

  if (status === 'accepted' && profile === 2) {
    return (
      <div className={wrapperClass}>
        <div className={classes}>
          <InformationIcon />
          {translate(`search.detail.profile2.accepted.${resourceType}`, { displayName })}
        </div>
      </div>
    );
  }

  if ((status === 'tentative' || status === 'unknown') && profile === 2) {
    return (
      <div className={wrapperClass}>
        <div className={classes}>
          <InformationIcon />
          {translate(`search.detail.profile2.tentative.${resourceType}`, { displayName })}
        </div>
      </div>
    );
  }

  if (status === 'unknown') {
    return (
      <div className={wrapperClass}>
        <div className={classes}>
          <InformationIcon />
          {translate(`search.detail.unknownResponse.${resourceType}`)}
        </div>
      </div>
    );
  }

  return null;
}

TooltipProfileInformation.propTypes = {
  resource: CustomPropTypes.resource,
  responseType: PropTypes.string,
  translate: PropTypes.func.isRequired
};

export default withTranslation(TooltipProfileInformation);
