import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, withTranslation } from '../../common';
import Header from '../../Header';
import CollectionSearchResults from '../BookingCollectionSearchResults';
import BookingCollectionSidebar from '../BookingCollectionSidebar';

import './_style.css';

function BookingCollectionDeleteDesktop({ resource, date, remove, close, translate }) {
  if (!resource) {
    return null;
  }

  const resources = [{ resource, selected: true }];
  const { resourceType } = resource;
  const title = translate(`bookingCollection.addBookings.${resourceType.toLowerCase()}`);

  const searchResultsProps = {
    resources,
    isLoading: false,
    hasMoreResults: false,
    loadMore: () => {},
    onSelect: () => {},
    visibleAlternatives: {},
    className: 'BookingCollectionDeleteDesktop__Results'
  };

  return (
    <>
      <Header title={title} onClose={close} fixed={false} />
      <div className="BookingCollectionDeleteDesktop__Content">
        <CollectionSearchResults {...searchResultsProps} />
        <div className="BookingCollectionDeleteDesktop__Sidebar">
          <BookingCollectionSidebar
            showSeatings={false}
            resource={resource}
            date={date}
            onRemove={remove}
          />
        </div>
      </div>
    </>
  );
}

BookingCollectionDeleteDesktop.propTypes = {
  resource: CustomPropTypes.resource,
  date: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookingCollectionDeleteDesktop);
