import React, { Component } from 'react';
import PropTypes from 'prop-types';
import identifier from '../identifier';

class Option extends Component {
  constructor(props) {
    super(props);
    this.id = identifier('option');
  }

  componentDidMount() {
    const { id, children, value, selected, disabled, onChanged } = this.props;
    const option = {
      key: id || identifier('option'),
      text: children,
      value,
      selected,
      disabled,
      onChanged,
      select: this.select.bind(this),
      unselect: this.unselect.bind(this)
    };

    const { select } = this.context;
    select.addOption(option);

    if (this.props && selected) {
      this.select();
    }
  }

  componentWillUnmount() {
    const { select } = this.context;
    select.removeOption(this);
  }

  select() {
    if (this.option) {
      this.option.selected = true;
    }
  }

  unselect() {
    if (this.option) {
      this.option.selected = false;
    }
  }

  render() {
    const { id, value, disabled, children } = this.props;
    const reference = ref => {
      this.option = ref;
    };
    return (
      <option key={id} value={value} disabled={disabled} ref={reference}>
        {children}
      </option>
    );
  }
}

Option.propTypes = {
  id: PropTypes.string,
  children: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChanged: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool
};

Option.contextTypes = {
  select: PropTypes.shape({})
};

export default Option;
