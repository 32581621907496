import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomPropTypes } from '../../common';
import RoomCardDesktop from '../../Search/SearchResults/SearchResultCard/RoomCard/RoomCardDesktop';
import EquipmentCardDesktop from '../../Search/SearchResults/SearchResultCard/EquipmentCard/EquipmentCardDesktop';

import './_style.css';

function CollectionSearchResult({ resource, alternativeType, selected, onSelect }) {
  const { resourceType } = resource;
  const isEquipmentCard = resourceType === 'Equipment';

  const props = {
    isProfile2: false,
    isProfile3: false,
    showTimeInput: false,
    showRelativeTime: false,
    selected: false,
    resource,
    alternativeType
  };

  const classes = cx('CollectionSearchResult', {
    CollectionSearchResult__Arrow: selected
  });

  return (
    <div tabIndex="0" role="button" className={classes} onClick={onSelect}>
      {isEquipmentCard ? <EquipmentCardDesktop {...props} /> : <RoomCardDesktop {...props} />}
    </div>
  );
}

CollectionSearchResult.propTypes = {
  resource: CustomPropTypes.resource,
  selected: PropTypes.bool,
  alternativeType: PropTypes.oneOf(['floor', 'sector', 'time', 'roomequipment', 'roomtype', 'capacity']),
  onSelect: PropTypes.func
};

export default CollectionSearchResult;
