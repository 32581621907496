import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon, withTranslation } from '../../common';

function BookDetailBookingCollection({ className, translate }) {
  const text = translate('bookingCollection.detail.text');
  const classes = cx('BookDetailBookingCollection', className);
  return (
    <div className={classes}>
      <Icon icon="006-attachment" className="BookDetailBookingCollection__Icon" />
      {text}
    </div>
  );
}

BookDetailBookingCollection.propTypes = {
  className: PropTypes.string,
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookDetailBookingCollection);
