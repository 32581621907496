import types from './types';
import mapProfile from './profileMapper';

const updateUser = user => {
  const mappedUser = user;
  mappedUser.profile = mapProfile(user.profile);
  return {
    type: types.UPDATE_USER,
    user
  };
};

const updateUserIsLoading = isLoading => ({
  type: types.UPDATE_USER_IS_LOADING,
  isLoading
});

const userExpired = () => ({
  type: types.USER_EXPIRED
});

const silentRenewError = () => ({
  type: types.SILENT_RENEW_ERROR
});

const userLogout = () => ({
  type: types.USER_LOGOUT
});

export default {
  updateUser,
  updateUserIsLoading,
  userExpired,
  silentRenewError,
  userLogout
};
