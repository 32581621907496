import React from 'react';
import PropTypes from 'prop-types';

import HelpPageHeader from './HelpPageHeader';

import { ShowAt, ScrollToTop, withTranslation } from '../common';

import './_style.css';

const HelpPageSectionContent = withTranslation(({ translate, link, path }) => {
  const absoluteUrlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');
  const externalLink = absoluteUrlRegex.test(link);
  const linkProps = {
    href: link,
    target: externalLink ? '_blank' : undefined
  };
  return (
    <div className="HelpPageSection__Content">
      <p>{translate(`help.${path}.text`)}</p>
      {link && <a {...linkProps}>{translate(`help.${path}.link`)}</a>}
    </div>
  );
});

const HelpPageSection = withTranslation(({ translate, path, last, children }) => (
  <>
    <div className="HelpPageSection">
      <p className="HelpPageSection__Title">{translate(`help.${path}.title`)}</p>
      {children}
    </div>
    {!last && <div className="HelpPage__Divider" />}
  </>
));

function HelpPage({ translate }) {
  const title = translate('help.title');
  const howToLink = '#';
  const qAndALink = '#';
  const changeRequestsLink = '/changeRequests/my';
  return (
    <>
      <ScrollToTop />
      <div className="HelpPage">
        <HelpPageHeader title={title} />
        <HelpPageSection path="howTos">
          <HelpPageSectionContent path="howTos" link={howToLink} />
          <br />
          <HelpPageSectionContent path="qAndA" link={qAndALink} />
        </HelpPageSection>
        <div className="HelpPageSection">
          <b>
            {translate('help.serviceDesk.title')}
            :
          </b>
          {' '}
          {translate('help.serviceDesk.text')}
        </div>
        <div className="HelpPage__Divider" />

        <ShowAt breakpoint="900AndBelow">
          <HelpPageSection path="changeRequests" last>
            <HelpPageSectionContent path="changeRequestsMobile" />
          </HelpPageSection>
        </ShowAt>

        <ShowAt breakpoint="900AndAbove">
          <HelpPageSection path="changeRequests" last>
            <HelpPageSectionContent path="changeRequests" link={changeRequestsLink} />
          </HelpPageSection>
        </ShowAt>
      </div>
    </>
  );
}

HelpPage.propTypes = {
  translate: PropTypes.func.isRequired
};

HelpPageSection.propTypes = {
  translate: PropTypes.func,
  path: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};

HelpPageSectionContent.propTypes = {
  translate: PropTypes.func,
  path: PropTypes.string.isRequired,
  link: PropTypes.string
};

export default withTranslation(HelpPage);
