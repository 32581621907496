import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Loader from '../../Loader/Loader';

import AutoCompleteDropdownItem from './AutoCompleteDropdownItem';

function AutoCompleteDropdown({ value, items, onChange, onKeyDown, showLoader, loading }) {
  const renderItems = item => (
    <AutoCompleteDropdownItem
      key={item.value || item.name}
      item={item}
      value={value || ''}
      onChange={onChange}
    />
  );

  const classes = cx('autocomplete js-autocomplete', {
    'is-open': items.length > 0
  });

  return (
    <div role="button" tabIndex="0" className={classes} onKeyDown={onKeyDown}>
      <Loader visible={showLoader && loading} size="small" className="AutoCompleteLoader" />
      {!loading && <ul>{items.map(item => renderItems(item))}</ul>}
    </div>
  );
}

AutoCompleteDropdown.propTypes = {
  value: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string,
      resourceType: PropTypes.string
    })
  ),
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
  loading: PropTypes.bool
};

AutoCompleteDropdown.defaultProps = {
  value: '',
  items: [],
  showLoader: false,
  loading: false
};

export default AutoCompleteDropdown;
