import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/fp/sortBy';
import { Select, Option, withTranslation } from '../common';

function RoomFilterSector({ sectors, onChange, translate }) {
  const selectedSectors = sectors ? sectors.filter(p => p.enabled === true).map(p => p.key) : [];

  const renderOption = () => {
    const options = sortBy(p => p.name, sectors)
      .map(sector => {
        const option = (
          <Option id={sector.key} key={sector.key} value={sector.key} selected={sector.enabled}>{sector.name}</Option>);
        return option;
      });
    return options;
  };

  const onSelectionChanged = event => {
    if (sectors) {
      const sector = sectors.filter(p => p.key === event.selectedOption.key);

      if (sector && sector.length === 1) {
        onChange(sector[0]);
      }
    }
  };

  const noAvailableSectors = sectors.length === 0;

  return (
    <div className="RoomFilter__Sector">
      <div className="RoomFilter__Section Sector__Filter">
        <div className="RoomFilter__Content">
          <Select
            multiple
            value={selectedSectors}
            disabled={noAvailableSectors}
            onChange={onSelectionChanged}
            placeholder={`${translate('roomFilter.sectorTitleSelect')}`}>
            {renderOption()}
          </Select>
        </div>
      </div>
    </div>
  );
}

RoomFilterSector.propTypes = {
  sectors: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RoomFilterSector);
