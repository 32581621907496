import { recurrenceSelectors } from '../recurrence';
import { roomFilterSelectors } from '../roomFilter';
import { locationsSelectors } from '../locations';
import { searchSelectors } from '../search';
import {
  toMoment,
  toDate,
  toDateTime,
  isDateValid,
  isTimeValid,
  getCurrentDate,
  oneHour,
  toTimeString,
  getCurrentTime,
  isToday
} from '../../util';
import { equipmentFilterSelectors } from '../equipmentFilter';

const convertToDate = (date, time) => {
  if (!isDateValid(date)) {
    return null;
  }
  return isTimeValid(time) ? toDateTime(toDate(date), time) : toDate(date);
};

export const getStartDate = state => {
  const date = searchSelectors.getDate(state) || getCurrentDate();
  const time = searchSelectors.getTime(state);
  if (!isToday(date) && (!time.from || time.from === '')) {
    return toMoment(date)
      .set('hours', 8)
      .set('minutes', 0)
      .set('seconds', 0)
      .toDate();
  }
  const from = time.from || getCurrentTime();
  return convertToDate(date, from);
};

export const getEndDate = state => {
  const date = searchSelectors.getDate(state) || getCurrentDate();
  const time = searchSelectors.getTime(state);
  const to = time.to || toTimeString(new Date(Date.now() + oneHour));
  return convertToDate(date, to);
};

export const getEndOfDay = date => {
  const dateReturn = toDate(date);
  const setHours = isToday(date)
    ? () => dateReturn.setHours(23, 59, 59, 0)
    : () => dateReturn.setHours(19, 0, 0, 0);
  setHours();
  return dateReturn;
};

const isExactTime = state => searchSelectors.isExactTime(state);

const createPayload = (state, sortingList) => {
  const recurrence = recurrenceSelectors.getRecurrenceRequest(state);
  const exactTime = isExactTime(state);

  const startDate = getStartDate(state) || new Date();
  const endDate = exactTime
    ? getEndDate(state)
    : getEndOfDay(searchSelectors.getDate(state) || new Date());

  const capacities = roomFilterSelectors.getSelectedCapacitiesNumbers(state);

  const roomTypes = roomFilterSelectors.getSelectedRoomTypesIds(state);
  const equipmentTypes = equipmentFilterSelectors.getSelectedEquipmentTypeIds(state);
  const resourceSubTypes = roomTypes.concat(equipmentTypes);
  const equipment = roomFilterSelectors.getSelectedEquipmentNames(state);

  const floors = roomFilterSelectors.getSelectedFloorIds(state);
  const sectors = roomFilterSelectors.getSelectedSectorIds(state);

  const resourceType = searchSelectors.getResourceType(state);
  const locations = locationsSelectors.getLocationsByType(state);

  return {
    start: startDate.toISOString(),
    end: endDate.toISOString(),
    exactTime,
    locations,
    capacities,
    resourceSubTypes,
    equipment,
    floors,
    sectors,
    recurrence,
    resourceTypes: [resourceType],
    sortingList // optional string to pass a roomlist name which will be used for sorting. not saved to the state
  };
};

export default createPayload;
