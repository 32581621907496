import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from '../../../Header';
import SearchLocation from './SearchLocation';
import { Button, withTranslation } from '../../../common';
import { availabilityOperations } from '../../../../state/availability';

import './_style.css';

class SearchFormLocation extends Component {
  onSave = () => {
    const { loadAvailabilities, history } = this.props;
    loadAvailabilities();
    history.goBack();
  };

  renderHeader = () => {
    const { translate } = this.props;
    const title = translate('search.where.title');
    return <Header onClose={this.onSave} title={title} />;
  };

  renderButton = () => {
    const { translate } = this.props;
    const label = translate('common.search');
    return <Button label={label} className="button--fixed" onClick={this.onSave} />;
  };

  renderSection = child => (
    <div className="SearchFormLocation__Section">
      <div className="SearchFormLocation__Content">{child}</div>
    </div>
  );

  render() {
    return (
      <>
        {this.renderHeader()}

        <div>{this.renderSection(<SearchLocation />)}</div>

        {this.renderButton()}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  loadAvailabilities: () => dispatch(availabilityOperations.loadAvailabilities())
});

SearchFormLocation.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  loadAvailabilities: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const SearchFormLocationComponent = withTranslation(withRouter(SearchFormLocation));
export default connect(
  null,
  mapDispatchToProps
)(SearchFormLocationComponent);
