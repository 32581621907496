const ADD_AVAILABILITY = 'alternatives/ADD_AVAILABILITY';
const UPDATE_AVAILABILITY = 'alternatives/UPDATE_AVAILABILITY';
const UPDATE_SKIPCOUNT = 'alternatives/UPDATE_SKIPCOUNT';
const UPDATE_LOADING = 'alternatives/UPDATE_LOADING';
const HAS_MORE_RESULTS = 'alternatives/HAS_MORE_RESULTS';
const CLEAR_ALL_AVAILABILITIES = 'alternatives/CLEAR_ALL_AVAILABILITIES';
const REMOVE_BY_ID = 'alternatives/REMOVE_BY_ID';

export default {
  ADD_AVAILABILITY,
  UPDATE_AVAILABILITY,
  UPDATE_SKIPCOUNT,
  UPDATE_LOADING,
  HAS_MORE_RESULTS,
  CLEAR_ALL_AVAILABILITIES,
  REMOVE_BY_ID
};
