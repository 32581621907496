import React from 'react';

import { components } from 'react-select';
import Icon from '../Icon/Icon';

const style = {
  fontSize: '18px'
};

function ClearIndicator(props) {
  return components.ClearIndicator && (
  <components.ClearIndicator {...props}>
    <Icon icon="022-close" style={style} />
  </components.ClearIndicator>
);
}

export default ClearIndicator;
