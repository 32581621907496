import config from '../config';
import fetch from './fetch';

const getUrl = path => `${config.remoteUrl}/${path}`;

async function post(feedback) {
  const url = getUrl('feedback');
  return fetch(url, 'POST', feedback);
}

export default { post };
