import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon, Input, ShowAt } from '../../../../common';

import './_style.css';

function SearchFormInput({
  icon,
  value,
  placeholder,
  onClick,
  children,
  disabled,
  readOnly,
  error
}) {
  const renderIcon = () => (
    <div className="SearchFormInput__Icon">
      <Icon icon={icon} onClick={onClick} />
    </div>
  );

  const renderInput = () => {
    const classes = cx({
      'SearchFormInput__Input--readonly': readOnly,
      'SearchFormInput__Input--error': error
    });

    const onChange = readOnly ? () => {} : null;
    return (
      <div className="SearchFormInput__Input">
        <Input
          type="text"
          value={value}
          onClick={onClick}
          disabled={disabled}
          onChange={onChange}
          className={classes}
          placeholder={placeholder}
        />
      </div>
    );
  };

  const render4Mobile = () => (
    <div className="SearchFormInput SearchFormInput--form">
      {renderIcon()}
      {renderInput()}
      {children}
    </div>
  );

  const render4Tablets = () => (
    <div className="SearchFormInput SearchFormInput--form">
      <div className="SearchFormInput--centered">
        {renderIcon()}
        {renderInput()}
        {children}
      </div>
    </div>
  );

  return (
    <div>
      <ShowAt breakpoint="700AndBelow">{render4Mobile()}</ShowAt>
      <ShowAt breakpoint="700AndAbove">{render4Tablets()}</ShowAt>
    </div>
  );
}

SearchFormInput.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};

export default SearchFormInput;
