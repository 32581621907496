import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/fp/isEqual';
import cx from 'classnames';

import { withTranslation } from '..';

import './_style.css';

function ChangeDetector({ values, children, invalid, detectChanges, translate }) {
  if (!detectChanges) {
    return <div>{ children }</div>;
  }

  const valuesEqual = isEqual(values.old, values.new);
  const changedLabel = translate('common.changed');
  const classes = cx('ChangeDetector__Changed', {
    'ChangeDetector__Changed--invalid': invalid
  });

  return (
    <div className="ChangeDetector">
      <div className="ChangeDetector__Children">{children}</div>
      <div className={classes}>{!valuesEqual && <span>{changedLabel}</span>}</div>
    </div>
  );
}

ChangeDetector.propTypes = {
  values: PropTypes.shape({
    old: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.shape(),
      PropTypes.array,
      PropTypes.number
    ]),
    new: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.shape(),
      PropTypes.array,
      PropTypes.number
    ])
  }),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  detectChanges: PropTypes.bool.isRequired,
  invalid: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

ChangeDetector.defaultProps = {
  invalid: false
};

export default withTranslation(ChangeDetector);
