import React from 'react';
import { Icon } from '../../../common';

const renderValidationError = (component, errorPath) => {
  const { translate } = component.props;
  return (
    <div className="validation_error">
      <Icon icon="026-exclamation-mark-circle" />
      <span>
        {' '}
        {translate(errorPath)}
      </span>
    </div>
  );
};

const validateField = (component, fieldName, fieldValue) => {
  const { form, isValid } = component.state;
  component.setState({
    form: { ...form, [fieldName]: fieldValue },
    isValid: { ...isValid, [fieldName]: component.validation[fieldName](fieldValue) }
  });
};

const extraValidateField = (component, fieldName, fieldValue, extraField) => {
  const { form, isValid } = component.state;
  component.setState({
    form: { ...form, [fieldName]: fieldValue, extraField },
    isValid: { ...isValid, [fieldName]: component.validation[fieldName](fieldValue, extraField) }
  });
};

// fieldName and fieldValue are optional
const validateForm = (component, fieldName, fieldValue) => {
  const { form, formIsValid } = component.state;
  let updatedFormIsValid = true;
  let fieldIsValid = true;

  Object.entries(form).forEach(field => {
    const name = field[0];
    const value = name === fieldName ? fieldValue : field[1];

    if (component.validation[name] !== undefined) {
      fieldIsValid = component.validation[name](value);
      if (!fieldIsValid) {
        updatedFormIsValid = false;
      }
    }
  });

  if (updatedFormIsValid !== formIsValid) {
    component.setState({ formIsValid: updatedFormIsValid });
  }
};

// fieldName and fieldValue are optional
const extraValidateForm = (component, fieldName, fieldValue, extraFieldValue) => {
  const { form, formIsValid } = component.state;
  let updatedFormIsValid = true;
  let fieldIsValid = true;

  Object.entries(form).forEach(field => {
    const name = field[0];
    const value = name === fieldName ? fieldValue : field[1];

    if (component.validation[name] !== undefined) {
      fieldIsValid = component.validation[name](value, extraFieldValue);
      if (!fieldIsValid) {
        updatedFormIsValid = false;
      }
    }
  });

  if (updatedFormIsValid !== formIsValid) {
    component.setState({ formIsValid: updatedFormIsValid });
  }
};

export default {
  renderValidationError,
  validateField,
  validateForm,
  extraValidateField,
  extraValidateForm
};
