import config from '../config';

const createUserManager = async () => {
  const Oidc = await import('oidc-client');
  // Uncomment below line when degugging to show log entries
  // Oidc.Log.logger = console;

  const { UserManager } = Oidc;

  const jsonManifest = await (await fetch('/manifest.json')).json();
  const userManagerConfigSettings = jsonManifest.userManagerConfig;
  const userManagerConfig = {
    client_id: userManagerConfigSettings.client_id,
    authority: userManagerConfigSettings.authority,
    response_type: userManagerConfigSettings.response_type,
    scope: userManagerConfigSettings.scope,
    redirect_uri: `${ config.origin }${ userManagerConfigSettings.redirect_uri }`,
    silent_redirect_uri: `${ config.origin }${ userManagerConfigSettings.silent_redirect_uri }`,
    accessTokenExpiringNotificationTime: userManagerConfigSettings.accessTokenExpiringNotificationTime,
    automaticSilentRenew: userManagerConfigSettings.automaticSilentRenew.toLowerCase() === 'false',
    filterProtocolClaims: userManagerConfigSettings.filterProtocolClaims.toLowerCase() === 'true',
    loadUserInfo: userManagerConfigSettings.loadUserInfo.toLowerCase() === 'true',
    post_logout_redirect_uri: config.origin
  };

  return new UserManager(userManagerConfig);
};

export default createUserManager;
