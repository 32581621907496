import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation, ChangeDetector, Radio, RadioGroup } from '../../common';

import './_style.css';

function FormFieldBoolean({
  values,
  update,
  field,
  optional,
  isSubmitted,
  detectChanges,
  translate
}) {
  const updateValue = event => {
    const isTrue = event.target.value === 'true';
    update(field, isTrue);
  };
  const labelText = translate(`changeRequests.fieldNames.${field}`);

  const yes = translate('common.yes');
  const no = translate('common.no');

  const getOptionalLabel = () => {
    const optionalText = translate('changeRequests.optional');
    return (
      <span>
        {labelText}
        {' '}
        <span className="ChangeRequestFormField__OptionalLabel">{optionalText}</span>
      </span>
    );
  };

  const label = !optional ? labelText : getOptionalLabel();
  const value = values.new !== null ? values.new.toString() : null;

  const invalid = isSubmitted && !value;

  return (
    <div className="ChangeRequestFormField">
      <ChangeDetector values={values} invalid={invalid} detectChanges={detectChanges}>
        <>
          <RadioGroup value={value} onChange={updateValue} label={label} horizontal>
            <Radio label={yes} value="true" />
            <Radio label={no} value="false" />
          </RadioGroup>
          {invalid && (
            <p className="ChangeRequestFormField__Required">{translate('common.required')}</p>
          )}
        </>
      </ChangeDetector>
    </div>
  );
}

FormFieldBoolean.propTypes = {
  values: PropTypes.shape({
    old: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    new: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  }).isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  field: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  detectChanges: PropTypes.bool,
  optional: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

FormFieldBoolean.defaultProps = {
  optional: false,
  detectChanges: true
};

export default withTranslation(FormFieldBoolean);
