/* eslint-disable no-undef */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { authOperations } from '../../../state/auth';
import { storeUserManager } from './userManager';

class AuthProvider extends Component {
  constructor(props) {
    super(props);
    this.userManager = props.userManager;
    storeUserManager(this.userManager);

    this.onUserLoaded = this.onUserLoaded.bind(this);
    this.onAccessTokenExpired = this.onAccessTokenExpired.bind(this);
    this.onSilentRenewError = this.onSilentRenewError.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.userManager.events.addUserLoaded(this.onUserLoaded);
    this.userManager.events.addAccessTokenExpired(this.onAccessTokenExpired);
    this.userManager.events.addSilentRenewError(this.onSilentRenewError);
  }

  componentDidMount() {
    const { location } = this.props;
    if (location) {
      authOperations.updateRoute(location.pathname, location.search);
    }
  }

  componentWillUnmount() {
    this.userManager.events.removeUserLoaded(this.onUserLoaded);
    this.userManager.events.addAccessTokenExpired(this.onAccessTokenExpired);
    this.userManager.events.removeSilentRenewError(this.onSilentRenewError);
  }

  onSilentRenewError() {
    const { silentRenewError } = this.props;
    silentRenewError();
  }

  onAccessTokenExpired() {
    const { userExpired } = this.props;
    userExpired();
  }

  onUserLoaded(user) {
    const { updateUser } = this.props;
    updateUser(user);
  }

  render() {
    const { children } = this.props;
    return React.Children.only(children);
  }
}

const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch(authOperations.updateUser(user)),
  userExpired: () => dispatch(authOperations.userExpired()),
  silentRenewError: () => dispatch(authOperations.silentRenewError())
});

AuthProvider.propTypes = {
  userManager: PropTypes.shape().isRequired,
  updateUser: PropTypes.func.isRequired,
  userExpired: PropTypes.func.isRequired,
  silentRenewError: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.any
  }).isRequired,
  children: PropTypes.element.isRequired
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(AuthProvider)
);
