/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { styles, lineStyles } from './styles';

function MobileEquipmentIcon({ disabled, ...otherProps }) {
  const styles1 = {
    fill: 'none',
    stroke: '#000',
    strokeWidth: '1.6px',
    strokeMiterlimit: '10'
  };

  const styles2 = {
    stroke: '#000',
    strokeWidth: '0.3px',
    strokeMiterlimit: '10'
  };

  const styles3 = {
    stroke: '#000',
    strokeWidth: '0.6px',
    strokeMiterlimit: '10'
  };

  const styles4 = {
    fill: '#020202'
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 0 30 30" {...otherProps} style={styles}>
      <path
        style={styles1}
        d="M15,17.08a17.56,17.56,0,0,0-3.8-.5h0A15,15,0,0,0,5,18s-.4-1.9-2.1-1.9A2,2,0,0,0,.8,18h0a2,2,0,0,0,1.7,1.9,8.15,8.15,0,0,0,1.3,2.8,4,4,0,0,0,1.9.3H16.5a4,4,0,0,0,1.9-.3c.4-.2,1-1.8,1.3-2.8A1.93,1.93,0,0,0,21.4,18a1.78,1.78,0,0,0-1.65-1.9H19.5a.75.75,0,0,0-.5.1"
      />
      <circle
        style={styles2}
        cx="6.36"
        cy="25.84"
        r="1"
        transform="translate(-20.15 23.51) rotate(-71.16)"
      />
      <path
        style={styles1}
        d="M17.3,7c-.5-2.3-2.1-4.2-6.1-4.2-6.5,0-6.5,4.8-6.5,8.8,0,.8.5,4.8,1.6,5.7"
      />
      <circle style={styles2} cx="16.4" cy="25.78" r="1" />
      <path
        style={styles3}
        d="M16.3,9.38a.37.37,0,0,1-.08-.52l.08-.08a.38.38,0,0,1,.53-.07l.07.07L20.1,12a.37.37,0,0,1,.08.52l-.08.08-3.2,3.2a.42.42,0,0,1-.6-.6l2.6-2.6H11.8a.4.4,0,1,1,0-.8h7.1Z"
      />
      <path d="M16.6,7.48c.3,0,.8.1,1.8,1.3l-.3-2-1.6.3Z" />
      <path
        style={styles4}
        d="M15.2,16.28h0l-.4,1.6.5.1a10.62,10.62,0,0,1,2.5,1.1c.3-1,.4-2,1.5-2.2.9-.1.2-1.6.2-1.6a1.45,1.45,0,0,0-1.2.3c-.1.1-.8.9-.8.9a1.53,1.53,0,0,1-2.16,0Z"
      />

      {disabled && <line style={lineStyles} x1="2.5" y1="1.5" x2="19.8" y2="29.01" />}
    </svg>
  );
}

MobileEquipmentIcon.propTypes = {
  disabled: PropTypes.bool
};

export default MobileEquipmentIcon;
