import { createSelector } from 'reselect';

const getResourcesSubTypes = state => state.resourcesSubTypesState.resourcesSubTypes.resourcesSubTypes;
const isLoaded = state => state.resourcesSubTypesState.resourcesSubTypes.isLoaded;
const getSelectedResourcesSubType = state => state.resourcesSubTypesState.resourcesSubTypes.selectedResourcesSubType;

const hasResourcesSubTypes = createSelector(
  getResourcesSubTypes,
  resourcesSubTypes => {
    const resourcesSubTypesIDs = [];
    if (resourcesSubTypes !== null) {
      Object.entries(resourcesSubTypes).forEach(resourcesSubType => resourcesSubTypesIDs.push(resourcesSubType));
    }
    return resourcesSubTypes != null && resourcesSubTypesIDs.length > 0;
  }
);

const getAll = createSelector(getResourcesSubTypes, resourcesSubTypes => ({
  ...resourcesSubTypes
}));

export default {
  getAll,
  getResourcesSubTypes,
  isLoaded,
  hasResourcesSubTypes,
  getSelectedResourcesSubType
};
