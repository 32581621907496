import config from '../config';
import fetch from './fetch';

const getUrl = path => `${ config.remoteUrl }/${ path }`;

async function getAll(buildingKey) {
  const url = getUrl(`floors?buildingKey=${ buildingKey }`);
  return fetch(url, 'GET', buildingKey);
}

async function addFloor(payload) {
  const url = getUrl('floors/add');
  return fetch(url, 'POST', payload);
}

async function updateFloor(floorId, payload) {
  const url = getUrl(`floors/update/${ floorId }`);
  return fetch(url, 'PUT', payload);
}

async function remove(name, buildingKey) {
  const url = getUrl(`floors/deleteFloor?name=${ name }&buildingKey=${ buildingKey }`);
  return fetch(url, 'DELETE');
}

async function updateFloorMap(payload) {
  const url = getUrl('floors/map');
  return fetch(url, 'PUT', payload);
}

export default {
  addFloor,
  getAll,
  updateFloor,
  remove,
  updateFloorMap
};
