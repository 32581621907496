import types from './types';

const updateChangeRequest = changeRequest => ({
  type: types.UPDATE_CHANGE_REQUEST,
  changeRequest
});

const updateIsLoading = isLoading => ({
  type: types.UPDATE_IS_LOADING,
  isLoading
});

const updateIsProcessing = (actionType, isProcessing) => ({
  type: types.UPDATE_ACTION_ISPROCESSING,
  actionType,
  isProcessing
});

const updateProcessed = (actionType, processed) => ({
  type: types.UPDATE_ACTION_PROCESSED,
  actionType,
  processed
});

export default {
  updateChangeRequest,
  updateIsLoading,
  updateIsProcessing,
  updateProcessed
};
