/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Markdown from 'react-markdown';

import { Icon } from '../common';

const allowedTypes = ['paragraph', 'emphasis', 'strong', 'link'];

function MaintenanceMessage({ type, text, onClose }) {
  const classes = cx('MaintenanceMessage', {
    'MaintenanceMessage--info': type.toLowerCase() === 'info',
    'MaintenanceMessage--maintenance': type.toLowerCase() === 'maintenance'
  });

  return (
    <div className={classes}>
      <div className="MaintenanceMessage__Content">
        <div className="MaintenanceMessage__Icon">
          <Icon icon="232-robot-neutral" />
        </div>
        <div className="MaintenanceMessage__Text">
          <Markdown skipHtml allowedTypes={allowedTypes} source={text} />
          {text}
        </div>
      </div>
      <div className="MaintenanceMessage__Close">
        <Icon icon="022-close" onClick={onClose} />
      </div>
    </div>
  );
}

MaintenanceMessage.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default MaintenanceMessage;
