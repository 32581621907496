import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Dialog, Button, withTranslation } from '../../common';
import { recurrenceMasterSelectors } from '../../../state/recurrenceMaster';
import { modalOperations } from '../../../state/modal';
import { timelineOperations } from '../../../state/timeline';
import Header from '../../Header';

import keys from '../modalKeys';

class RecurrenceDeleteDialogDesktop extends Component {
  renderDialogHeader = () => {
    const { selectedMaster, popDialog } = this.props;
    const title = selectedMaster.resource.displayName;
    return <Header title={title} onClose={popDialog} icon="022-close" iconRight fixed={false} />;
  };

  renderDialogContent = () => {
    const { translate } = this.props;
    const text = translate('search.detail.recurring.delete');
    return <div className="RecurrenceDeleteDialogDesktop__Content">{text}</div>;
  };

  renderDialogButtons = () => {
    const { selectedMaster, deleteMaster, popDialog, clearModals, translate } = this.props;
    const deleteMasterBooking = () => {
      deleteMaster(selectedMaster.globalId, selectedMaster.calendarId);
      clearModals();
    };

    const yes = translate('common.yes');
    const no = translate('common.no');

    return (
      <div>
        <Button
          className="RecurrenceDeleteDialogDesktop__Button"
          label={no}
          onClick={popDialog}
          secondary
        />
        <Button
          className="RecurrenceDeleteDialogDesktop__Button"
          onClick={deleteMasterBooking}
          label={yes}
        />
      </div>
    );
  };

  renderDialog = () => (
    <div className="RecurrenceDeleteDialogDesktop">
      {this.renderDialogHeader()}
      {this.renderDialogContent()}
      {this.renderDialogButtons()}
    </div>
  );

  render() {
    const { selectedMaster, popDialog } = this.props;
    if (!selectedMaster) {
      return null;
    }

    return (
      <Dialog dialogKey={keys.RECURRENCE_DELETE_DIALOG} onOverlayClick={popDialog}>
        {this.renderDialog()}
      </Dialog>
    );
  }
}

RecurrenceDeleteDialogDesktop.propTypes = {
  selectedMaster: PropTypes.shape({
    resource: PropTypes.shape({
      displayName: PropTypes.any
    }),
    globalId: PropTypes.any,
    calendarId: PropTypes.any
  }),
  deleteMaster: PropTypes.func.isRequired,
  popDialog: PropTypes.func.isRequired,
  clearModals: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  selectedMaster: recurrenceMasterSelectors.getSelectedMaster(state)
});

const mapDispatchToProps = dispatch => ({
  popDialog: () => dispatch(modalOperations.popDialog()),
  clearModals: () => dispatch(modalOperations.clearModals()),
  deleteMaster: (id, calendarId) => dispatch(timelineOperations.deleteMaster(id, calendarId))
});

const RecurrenceDeleteDialogDesktopComponent = withTranslation(RecurrenceDeleteDialogDesktop);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecurrenceDeleteDialogDesktopComponent);
