import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, withTranslation, Input, Button } from '../../../common';
import Header from '../../../Header';
import keys from './modalKeys';
import { modalOperations } from '../../../../state/modal';
import formValidation from '../common';
import { floorsOperations } from '../../../../state/floors';

class FloorCreateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: '',
        buildingKey: ''
      },
      isValid: {
        name: true
      }
    };

    this.validation = {
      name: (value, extraFieldValue) => (
        value !== ''
        && extraFieldValue?.indexOf(extraFieldValue?.find(x => x.name === value)) === -1)
    };
  }

  handleNameChange = (event, floors) => {
    const { value } = event.target;
    formValidation.extraValidateField(this, 'name', value, floors);
    formValidation.extraValidateForm(this, 'name', value, floors);
  };

  renderName = () => {
    const { form, isValid } = this.state;
    const { translate, floors } = this.props;

    return (
      <>
        <Input
          label={translate('common.name')}
          placeholder=""
          onChange={event => this.handleNameChange(event, floors)}
          error={!isValid.name}
          value={form.name}
          className="input"
        />
        {!isValid.name
          && floors.indexOf(floors.find(x => x.name === form.name)) === -1
          && formValidation.renderValidationError(this, 'admin.settings.floors.add.error.name')}
        {!isValid.name
          && floors.indexOf(floors.find(x => x.name === form.name)) !== -1
          && formValidation.renderValidationError(this, 'admin.settings.floors.add.error.duplicate')}
      </>
    );
  };

  addNewFloorToBuilding = () => {
    const { clearModals, sendNewFloor, buildingKey, submit, history, isNewBuilding } = this.props;
    const { form } = this.state;

    if (!isNewBuilding) {
      // call the floor endpoint to create a new floor
      const result = submit(form.name, buildingKey);
      if (result === 'Ok') {
        history.replace('/admin/settings/floors');
      }
    } else {
      sendNewFloor({ name: form.name });
    }
    clearModals();
    this.setState({ form: { name: '' } });
  };

  renderSubmitButton = () => {
    const { translate, floors } = this.props;
    const { form } = this.state;
    const isButtonDisabled = form.name === '' || floors.indexOf(form.name) !== -1;

    return (
      <div className="button--right">
        <Button
          icon="074-save"
          label={translate('common.create')}
          onClick={this.addNewFloorToBuilding}
          disabled={isButtonDisabled}
        />
      </div>
    );
  };

  render() {
    const { translate, clearModals } = this.props;

    return (
      <Modal modalKey={keys.FLOOR_CREATE_DIALOG}>
        <>
          <Header
            onClose={() => { clearModals(); this.setState({ form: { name: '' } }); }}
            title={translate('admin.settings.floors.newFloorTitle')}
            iconRight
            icon="022-close" />
          <div className="BookModalDesktop">
            <div className="BookModal__Main">
              <div className="BookModal__Body">
                <div className="BookModal__Content">
                  <div className="Create__Form">
                    {this.renderName()}
                    {this.renderSubmitButton()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  clearModals: () => {
    dispatch(modalOperations.clearModals());
  },
  submit: (name, buildingKey) => dispatch(floorsOperations.addNewFloorToBuilding(name, buildingKey))
});

FloorCreateModal.propTypes = {
  clearModals: PropTypes.func.isRequired,
  floors: PropTypes.arrayOf(PropTypes.any),
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }),
  translate: PropTypes.func.isRequired,
  sendNewFloor: PropTypes.func,
  submit: PropTypes.func.isRequired,
  buildingKey: PropTypes.string,
  isNewBuilding: PropTypes.bool
};

export default connect(null, mapDispatchToProps)(withTranslation(FloorCreateModal));
