import { combineReducers } from 'redux';
import types from './types';

const initialState = {
  user: null,
  isLoading: true,
  isSigningIn: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_USER: {
      return { ...state, user: action.user };
    }

    case types.UPDATE_USER_IS_LOADING: {
      return { ...state, isLoading: action.isLoading };
    }

    case types.USER_LOGOUT:
    case types.USER_EXPIRED:
    case types.SILENT_RENEW_ERROR: {
      return { ...state, user: null, isLoading: false };
    }

    default:
      return state;
  }
};

export default combineReducers({
  auth: authReducer
});
