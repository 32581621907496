import { createSelector } from 'reselect';
import isEqual from 'lodash/fp/isEqual';
import isEmpty from 'lodash/fp/isEmpty';
import values from 'lodash/fp/values';

import { editResourceSelectors } from '../editResource';

const getResource = state => editResourceSelectors.getResource(state);
const getProfileType = state => state.changeOwnerState.profile;
const getProfiles = state => state.changeOwnerState.profiles;
const getReason = state => state.changeOwnerState.reason;
const isContactLoading = state => state.changeOwnerState.contactIsLoading;
const isOwnerLoading = state => state.changeOwnerState.ownersIsLoading;
const getSuggestionsForContact = state => state.changeOwnerState.contactSuggestions;
const getSuggestionsForOwners = state => state.changeOwnerState.ownersSuggestions;
const isSubmitted = state => state.changeOwnerState.submitted;
const isSubmitting = state => state.changeOwnerState.isSubmitting;

const mapPerson = person => ({
  name: person.displayName,
  value: person.accountName,
  id: person.accountName,
  email: person.email
});

const getContactSuggestions = createSelector(
  getSuggestionsForContact,
  suggestions => suggestions.map(mapPerson)
);

const getOwnerSuggestions = createSelector(
  getSuggestionsForOwners,
  suggestions => suggestions.map(mapPerson)
);

const getActiveProfile = createSelector(
  getProfiles,
  profiles => values(profiles).reduce((prev, current, index) => {
    if (prev) {
      return prev;
    }
    if (current.active) {
      return { ...current, profile: index + 1 };
    }
    return null;
  }, null)
);

const getProfile = createSelector(
  [getProfileType, getActiveProfile],
  (profileType, activeProfile) => {
    if (!activeProfile) {
      return null;
    }
    return {
      profile: { old: profileType, new: activeProfile.profile },
      contact: activeProfile.contact,
      owners: activeProfile.owners
    };
  }
);

const getValues = createSelector(
  [getResource, getActiveProfile, getReason],
  (resource, activeProfile, reason) => {
    if (!activeProfile) {
      return {};
    }
    const { contact, owners, profile } = activeProfile;
    return {
      resourceId: resource.id,
      profile,
      contact: contact.new ? contact.new.email : '',
      owners: owners.new,
      reason
    };
  }
);

const isValid = createSelector(
  getValues,
  allValues => {
    const { reason, contact, owners, profile } = allValues;
    if (!reason) {
      return false;
    }

    if (profile === 2 || profile === 3 || profile === 4) {
      const hasContact = contact != null && contact !== '';
      const hasOwners = !isEmpty(owners);
      return hasContact && hasOwners;
    }

    return true;
  }
);

const hasChanges = createSelector(
  [getProfileType, getActiveProfile],
  (originalProfileType, activeProfile) => {
    if (!activeProfile) {
      return false;
    }

    if (originalProfileType !== activeProfile.profile) {
      return true;
    }

    const { contact, owners } = activeProfile;
    return !isEqual(contact.old, contact.new) || !isEqual(owners.old, owners.new);
  }
);

export default {
  getProfile,
  getProfiles,
  getReason,
  isContactLoading,
  getContactSuggestions,
  isOwnerLoading,
  getOwnerSuggestions,
  isSubmitted,
  isSubmitting,
  isValid,
  hasChanges,
  getValues
};
