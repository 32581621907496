import debounce from 'debounce-promise';
import latest from 'promise-latest';
import config from '../config';
import fetch from './fetch';

const getUrl = path => `${config.remoteUrl}/${path}`;

async function get(id) {
  const url = getUrl(`resources/${id}`);
  return fetch(url, 'GET');
}

async function search(searchRequest) {
  const url = getUrl(`resources/search?name=${searchRequest}`);
  return fetch(url, 'GET', searchRequest);
}

export default {
  get,
  search: debounce(latest(search), 50, { leading: true })
};
