import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Button, withTranslation } from '../../../common';
import {
  deleteResourceSelectors,
  deleteResourceOperations
} from '../../../../state/deleteResource';

import DeleteForm from './DeleteForm';

import './_style.css';

class DeletePage extends Component {
  renderSubmitButton = () => {
    const { submit, isSubmitting, history, translate } = this.props;
    const send = translate('common.send');
    const onClick = event => {
      event.preventDefault();
      event.stopPropagation();
      submit().then(result => {
        if (result) {
          history.replace('/changerequests/my');
        }
      });
    };
    const disabled = isSubmitting;
    return (
      <div className="DeleteForm__Submit">
        <Button label={send} disabled={disabled} onClick={onClick} />
      </div>
    );
  };

  render() {
    return (
      <div className="DeleteForm">
        <DeleteForm {...this.props} />
        {this.renderSubmitButton()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reason: deleteResourceSelectors.getReason(state),
  isValid: deleteResourceSelectors.isValid(state),
  isSubmitted: deleteResourceSelectors.isSubmitted(state),
  isSubmitting: deleteResourceSelectors.isSubmitting(state),
  resourceType: deleteResourceSelectors.getResourceType(state)
});

const mapDispatchToProps = dispatch => ({
  submit: () => dispatch(deleteResourceOperations.submit()),
  updateReason: value => dispatch(deleteResourceOperations.updateReason(value))
});

DeletePage.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired,
  submit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const DeletePageComponent = withRouter(withTranslation(DeletePage));
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeletePageComponent);
