import React from 'react';
import PropTypes from 'prop-types';

function HeaderTitle(props) {
  const { title } = props;
  return (
    <div className="Header__Title">
      {title}
    </div>
  );
}

HeaderTitle.propTypes = {
  title: PropTypes.any.isRequired
};

export default HeaderTitle;
