import types from './types';

const enableScrolling = () => ({
  type: types.ENABLE_SCROLLING
});

const disableScrolling = () => ({
  type: types.DISABLE_SCROLLING
});

export default {
  enableScrolling,
  disableScrolling
};
