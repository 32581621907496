import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, ShowAt, CustomPropTypes } from '../../common';
import { modalOperations } from '../../../state/modal';
import { exceptionsSelectors, exceptionsOperations } from '../../../state/exceptions';
import { recurrenceMasterSelectors } from '../../../state/recurrenceMaster';
import { i18nSelectors } from '../../../state/i18n';

import RecurrenceDetailModalDesktop from './RecurrenceDetailModalDesktop';
import RecurrenceDetailModalMobile from './RecurrenceDetailModalMobile';

import keys from '../modalKeys';

import '../_style.css';
import './_style.css';

const isSameMaster = (resource, other) => resource && other && resource.calendarId === other.calendarId;

class RecurrenceDetailModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      master: null
    };
  }

  UNSAFE_componentWillReceiveProps({ selectedMaster, updateResource }) {
    const { master } = this.state;
    if (selectedMaster && !isSameMaster(selectedMaster, master)) {
      this.setState({ master: selectedMaster });
      const resource = {
        ...selectedMaster.resource,
        exceptions: selectedMaster.exceptions
      };
      updateResource(resource);
    }
  }

  render() {
    // on desktops the delete dialog must be rendered on top of the recurrence detail modal
    const desktopModalKeys = [keys.RECURRENCE_DETAIL_MODAL, keys.RECURRENCE_DELETE_DIALOG];

    return (
      <div>
        <ShowAt breakpoint="900AndAbove">
          <Modal modalKey={desktopModalKeys}>
            <RecurrenceDetailModalDesktop {...this.props} />
          </Modal>
        </ShowAt>
        <ShowAt breakpoint="900AndBelow">
          <Modal modalKey={keys.RECURRENCE_DETAIL_MODAL} mobile>
            <RecurrenceDetailModalMobile {...this.props} />
          </Modal>
        </ShowAt>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  exceptions: exceptionsSelectors.getExceptions(state),
  hasUnhandledExceptions: exceptionsSelectors.hasUnhandledExceptions(state),
  selectedMaster: recurrenceMasterSelectors.getSelectedMaster(state),
  isLoading: recurrenceMasterSelectors.isLoading(state),
  language: i18nSelectors.getLanguage(state)
});

const mapDispatchToProps = dispatch => ({
  clearModals: () => dispatch(modalOperations.clearModals()),
  popModal: () => dispatch(modalOperations.popModal()),
  patchException: exception => dispatch(exceptionsOperations.patchException(exception)),
  openExceptionModal: exception => {
    dispatch(exceptionsOperations.updateException(exception));
    dispatch(exceptionsOperations.loadResources());
    dispatch(exceptionsOperations.loadAlternatives());
    dispatch(modalOperations.pushModal(keys.RECURRENCE_EXCEPTION_MODAL));
  },
  openExceptionDeleteModal: exception => {
    dispatch(exceptionsOperations.updateException(exception));
    dispatch(modalOperations.pushModal(keys.RECURRENCE_EXCEPTION_DELETE_MODAL));
  },
  updateResource: resource => dispatch(exceptionsOperations.updateResource(resource))
});

RecurrenceDetailModal.propTypes = {
  clearModals: PropTypes.func.isRequired,
  confirmDeletion: PropTypes.func.isRequired,
  selectedMaster: PropTypes.shape({
    resource: CustomPropTypes.resource,
    exceptions: PropTypes.any
  }),
  updateResource: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecurrenceDetailModal);
