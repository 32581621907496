import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SearchDate from './SearchDate';

import { availabilityOperations } from '../../../../../state/availability';
import { searchSelectors, searchOperations } from '../../../../../state/search';

function SearchFormDateDay({ setToToday, loadAvailabilities, ...props }) {
  const onTodayClick = () => {
    setToToday();
    loadAvailabilities();
  };

  return <SearchDate {...props} onTodayClick={onTodayClick} />;
}

const mapStateToProps = state => ({
  date: searchSelectors.getDate(state),
  isValid: searchSelectors.isValidDate(state)
});

const mapDispatchToProps = dispatch => ({
  updateDate: date => dispatch(searchOperations.updateDate(date)),
  setToToday: () => dispatch(searchOperations.setToToday()),
  loadAvailabilities: () => dispatch(availabilityOperations.loadAvailabilities())
});

SearchFormDateDay.propTypes = {
  date: PropTypes.string.isRequired,
  isValid: PropTypes.bool.isRequired,
  updateDate: PropTypes.func.isRequired,
  setToToday: PropTypes.func.isRequired,
  loadAvailabilities: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFormDateDay);
