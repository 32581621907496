import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/fp/isNil';
import isEmpty from 'lodash/fp/isEmpty';
import { withTranslation, DateInput } from '../../../../common';
import { getCurrentDate } from '../../../../../util/time';

class SearchDate extends Component {
  constructor(props) {
    super(props);

    const { date } = this.props;

    this.state = {
      selected: !isNil(date) && !isEmpty(date)
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    this.setState({ selected: true });
    const { updateDate } = this.props;
    updateDate(value);
  }

  render() {
    const { date, onTodayClick, isValid, translate, disabled } = this.props;
    const { selected } = this.state;
    const error = !isValid ? translate('search.when.invalid') : '';
    const value = selected ? date : getCurrentDate();

    const placeholder = translate('search.when.chooseDate');
    return (
      <DateInput
        placeholder={placeholder}
        value={value}
        error={error}
        onChange={this.onChange}
        onBlur={this.onBlur}
        icon
        today
        onTodayClick={onTodayClick}
        showInput={!disabled}
      />
    );
  }
}

SearchDate.propTypes = {
  date: PropTypes.string,
  isValid: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  updateDate: PropTypes.func.isRequired,
  onTodayClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default withTranslation(SearchDate);
