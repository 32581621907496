import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTranslation, SelectBox, Translate } from '../../../../common';
import { recurrenceSelectors, recurrenceOperations } from '../../../../../state/recurrence';
import styles from './RecurrenceFilterSwitch.module.css';

function RecurrenceFilterSwitch({ type, updateType, translate }) {
  const types = [{ type: 'never' }, { type: 'daily' }, { type: 'weekly' }, { type: 'monthly' }];

  const options = types.map(x => ({
    ...x,
    label: translate(`search.recurrence.${x.type}`),
    selected: x.type === type
  }));

  return (
    <div className={styles.boxes}>
      <div className={styles.title}>
        <Translate path="search.when.recurrence.title" />
      </div>
      {options.map(option => {
        const onClick = () => updateType(option.type);
        const props = {
          key: option.type,
          onClick,
          selected: option.selected,
          className: styles.box
        };
        return <SelectBox {...props}>{option.label}</SelectBox>;
      })}
    </div>
  );
}

const mapStateToProps = state => ({ type: recurrenceSelectors.getRecurrenceType(state) });

const mapDispatchToProps = dispatch => ({
  updateType: type => dispatch(recurrenceOperations.updateRecurrenceType(type))
});

RecurrenceFilterSwitch.propTypes = {
  type: PropTypes.string.isRequired,
  updateType: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(RecurrenceFilterSwitch));
