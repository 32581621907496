import { createSelector } from 'reselect';

const getHappyComment = state => state.feedbackState.feedback.happyComment;
const getSadComment = state => state.feedbackState.feedback.sadComment;

const submitted = state => state.feedbackState.feedback.submitted;
const isSubmitting = state => state.feedbackState.feedback.isSubmitting;

const hasTriedToSubmit = state => state.feedbackState.feedback.triedToSubmit;

// At least one feedback (positive or negative) is required
const hasFeedback = createSelector(
  [getHappyComment, getSadComment],
  (happyComment, sadComment) => happyComment.length > 3 || sadComment.length > 3
);

const happyCommentNotTooLong = createSelector(
  getHappyComment,
  happyComment => happyComment.length < 600
);

const sadCommentNotTooLong = createSelector(getSadComment, sadComment => sadComment.length < 600);

const isValid = state => hasFeedback(state);

export default {
  getHappyComment,
  getSadComment,
  submitted,
  isSubmitting,
  isValid,
  hasFeedback,
  hasTriedToSubmit,
  happyCommentNotTooLong,
  sadCommentNotTooLong
};
