import moment from 'moment';
import { searchSelectors } from '../search';
import { timelineSelectors } from '../timeline';
import { locationsSelectors } from '../locations';
import { roomFilterSelectors } from '../roomFilter';
import { equipmentFilterSelectors } from '../equipmentFilter';
import { toDate } from '../../util';

const getDefaultPageSize = state => {
  const compactModeEnabled = timelineSelectors.isCompactModeEnabled(state);
  const locations = locationsSelectors.getSelectedLocations(state);
  if (locations.length < 1) {
    return 50;
  }
  return compactModeEnabled ? 20 : 10;
};

const createPayload = state => {
  const date = toDate(searchSelectors.getDate(state));

  const isWeekly = window.location.href.includes('weekly');
  const startDate = isWeekly ? moment(date).startOf('isoweek') : moment(date).startOf('day');
  const endDate = isWeekly ? moment(date).endOf('isoweek') : moment(date).endOf('day');

  const locations = locationsSelectors.getLocationsByType(state);
  const resourceTypes = [searchSelectors.getResourceType(state)];

  const isRoomFiltered = resourceTypes.length && resourceTypes[0] === 'room';
  const isEquipmentFiltered = resourceTypes.length && resourceTypes[0] === 'equipment';

  const floors = roomFilterSelectors.getSelectedFloorIds(state);
  const sectors = roomFilterSelectors.getSelectedSectorIds(state);

  const capacities = isRoomFiltered ? roomFilterSelectors.getSelectedCapacitiesNumbers(state) : [];
  const roomTypes = isRoomFiltered ? roomFilterSelectors.getSelectedRoomTypesIds(state) : [];
  const equipmentTypes = isEquipmentFiltered
    ? equipmentFilterSelectors.getSelectedEquipmentTypeIds(state)
    : [];
  const resourceSubTypes = roomTypes.concat(equipmentTypes);
  const equipment = isRoomFiltered ? roomFilterSelectors.getSelectedEquipmentNames(state) : [];

  const myResources = false;
  const myBookings = false;

  const pageSize = getDefaultPageSize(state);

  return {
    start: startDate.toISOString(),
    end: endDate.toISOString(),
    locations,
    capacities,
    equipment,
    floors,
    sectors,
    resourceTypes,
    resourceSubTypes,
    myResources,
    myBookings,
    pageSize
  };
};

export default createPayload;
