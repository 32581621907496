import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, RoomCard, TrayIcons, Favorite } from '../../../../../common';
import RoomNameAndType from '../../RoomNameAndType';
import RoomCapacityAndTime from '../../RoomCapacityAndTime';
import RecurrenceAvailability from '../../RecurrenceAvailability';
import ProfileInformation from '../../ProfileInformation';

const width = amount => ({ width: amount });

function RoomCardRecurrenceTablet(props) {
  const { resource, isProfile3, isProfile2 } = props;
  const { id, resourceSubType } = resource;

  const renderProfileInformation = () => {
    if (isProfile3 || isProfile2) {
      return <ProfileInformation resource={resource} />;
    }
    return null;
  };

  return (
    <div>
      <RoomCard resourceSubType={resourceSubType}>
        <div style={width('35px')}>
          <Favorite resourceId={id} tooltip={false} medium />
        </div>
        <div style={width('calc(40% - 35px)')}>
          <RoomNameAndType resource={resource} />
        </div>
        <div style={width('30%')}>
          <RoomCapacityAndTime resource={resource} showRelativeTime={false} />
        </div>
        <div style={width('30%')}>
          <TrayIcons equipment={resource.roomEquipment} className="SearchResultCard__Tray" />
        </div>
        <RecurrenceAvailability resource={resource} />
        {renderProfileInformation()}
      </RoomCard>
    </div>
  );
}

RoomCardRecurrenceTablet.propTypes = {
  resource: CustomPropTypes.resource,
  isProfile3: PropTypes.bool.isRequired,
  isProfile2: PropTypes.bool.isRequired
};

export default RoomCardRecurrenceTablet;
