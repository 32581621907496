import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../../config';
import { ShowAt } from '../../common';

import { availabilitySelectors, availabilityOperations } from '../../../state/availability';
import { searchSelectors } from '../../../state/search';
import { recurrenceSelectors } from '../../../state/recurrence';
import { bookOperations, bookSelectors } from '../../../state/book';
import { modalOperations } from '../../../state/modal';

import modalKeys from '../../BookModal/modalKeys';

import {
  BookCreateModal,
  RecurrencePatternModal,
  RecurrenceExceptionModal,
  RecurrenceExceptionDeleteModal
} from '../../BookModal';
import BookingCollectionDialog from '../../BookingCollection/BookingCollectionDialog';
import SearchResultsLoader from './SearchResultsLoader';
import SearchResultsWrapper from './SearchResultsWrapper';
import SearchResult from './SearchResult';
import SearchResultRecurrence from './SearchResultRecurrence';
import calculateRowHeight from './calculateRowHeight';

import './_style.css';

class SearchResults extends Component {
  UNSAFE_componentWillReceiveProps() {
    const { totalCount } = this.props;
    if (totalCount === 0) {
      window.scrollTo(0, 0);
    }
  }

  createBooking = async () => {
    const {
      selectedResource,
      loadAvailabilities,
      createBooking
    } = this.props;

    await createBooking();
    if (selectedResource && (selectedResource.isVirtual || selectedResource.isVirtualMember)) {
      loadAvailabilities();
    }
  };

  renderSearchResult = (resource, height) => {
    const { openCreateModal } = this.props;
    const { id, recurring } = resource;
    const props = {
      resource,
      key: id,
      openModal: openCreateModal,
      style: { height: `${height}px` }
    };

    if (
      resource.numberOfAvailabilities === 0
      && resource.availabilitiesLoaded
    ) {
      return null;
    }

    if (recurring) {
      return <SearchResultRecurrence {...props} />;
    }

    return <SearchResult {...props} />;
  };

  render() {
    const {
      totalCount,
      isLoading,
      isRecurrence,
      recurrenceType,
      resourceType,
      openBookingCollectionDialog,
      getIsDateInPast,
      className
    } = this.props;

    const showLoader = isLoading && totalCount < config.pageSize;

    const setRowHeight = (height, mobile) => resource => calculateRowHeight(isRecurrence, height, mobile, resource);

    return (
      <div className={`Book__SearchResults ${className}`}>
        <BookCreateModal
          createBooking={this.createBooking}
          openBookingCollectionDialog={openBookingCollectionDialog}
        />
        <RecurrencePatternModal />
        <RecurrenceExceptionModal />
        <RecurrenceExceptionDeleteModal />
        <BookingCollectionDialog createBooking={this.createBooking} />
        <SearchResultsLoader
          visible={showLoader}
          isRecurrence={recurrenceType !== 'never'}
          resourceType={resourceType}
        />

        <ShowAt breakpoint="600AndBelow">
          <SearchResultsWrapper
            {...this.props}
            setRowHeight={setRowHeight(144, true)}
            renderSearchResult={this.renderSearchResult}
            getIsDateInPast={getIsDateInPast}
          />
        </ShowAt>

        <ShowAt breakpoint="600AndAbove 900AndBelow">
          <SearchResultsWrapper
            {...this.props}
            setRowHeight={setRowHeight(144, false)}
            renderSearchResult={this.renderSearchResult}
            getIsDateInPast={getIsDateInPast}
          />
        </ShowAt>

        <ShowAt breakpoint="900AndAbove" className="SearchResultsWrapper">
          <SearchResultsWrapper
            {...this.props}
            setRowHeight={setRowHeight(120)}
            renderSearchResult={this.renderSearchResult}
            getIsDateInPast={getIsDateInPast}
          />
        </ShowAt>
      </div>
    );
  }
}

SearchResults.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  createBooking: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  resourceType: PropTypes.string.isRequired,
  availableResources: PropTypes.arrayOf(PropTypes.shape()),
  openCreateModal: PropTypes.func.isRequired,
  openBookingCollectionDialog: PropTypes.func.isRequired,
  selectedResource: PropTypes.shape(),
  isRecurrence: PropTypes.bool,
  recurrenceType: PropTypes.string,
  loadAvailabilities: PropTypes.func.isRequired,
  className: PropTypes.string,
  getIsDateInPast: PropTypes.bool.isRequired
};

const mapState = state => ({
  availableResources: availabilitySelectors.getAvailabilities(state),
  isLoading: availabilitySelectors.isLoading(state),
  totalCount: availabilitySelectors.getTotalCount(state),
  hasMore: availabilitySelectors.hasMoreResults(state),
  resourceType: searchSelectors.getResourceType(state),
  selectedResource: bookSelectors.getSelectedResource(state),
  isRecurrence: availabilitySelectors.isRecurrence(state),
  recurrenceType: recurrenceSelectors.getRecurrenceType(state),
  getIsDateInPast: searchSelectors.getIsDateInPast(state)
});

const mapDispatch = dispatch => ({
  createBooking: () => dispatch(bookOperations.createBooking()),
  openCreateModal: resource => dispatch(bookOperations.loadEmptyBooking(resource, modalKeys.BOOK_CREATE_MODAL)),

  // eslint-disable-next-line arrow-body-style
  openBookingCollectionDialog: () => {
    return dispatch(modalOperations.pushModal(modalKeys.BOOKING_COLLECTION_INFORMATION_DIALOG));
  },

  loadAvailabilities: () => dispatch(availabilityOperations.loadAvailabilities(true)),
  loadMoreAvailabilities: () => dispatch(availabilityOperations.loadMoreAvailabilitites())
});

export default connect(
  mapState,
  mapDispatch
)(withRouter(SearchResults));
