import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';

import WeekIndex from './WeekIndex';
import { Translate, Icon } from '../../common';
import { DaysSelectBoxes } from '../RecurrenceSelectBoxes';
import { recurrenceSelectors, recurrenceOperations } from '../../../state/recurrence';

import './_style.css';

function RecurrenceWeekly({ days, inverted, index, toggleDay, updateIndex, isValid }) {
  const classes = cx('RecurrenceWeekly', { 'RecurrenceWeekly--inverted': inverted });
  return (
    <div className={classes}>
      <WeekIndex index={index} updateIndex={updateIndex} />
      <div className="RecurrenceWeekly__Days">
        <DaysSelectBoxes days={days} toggleDay={toggleDay} />
      </div>
      {!isValid && (
        <div className="TimeInputRange__Error">
          <Icon icon="026-exclamation-mark-circle" />
          <Translate path="search.recurrence.validationError" />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = state => ({
  days: recurrenceSelectors.getDaysOfWeek(state),
  index: recurrenceSelectors.getIndexOfWeek(state),
  isValid: recurrenceSelectors.isValidRecurrence(state)
});

const mapDispatchToProps = dispatch => ({
  toggleDay: day => dispatch(recurrenceOperations.toggleDayOfWeek(day)),
  updateIndex: index => dispatch(recurrenceOperations.updateIndexOfWeek(index))
});

RecurrenceWeekly.propTypes = {
  inverted: PropTypes.bool,
  days: PropTypes.arrayOf(PropTypes.string).isRequired,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isValid: PropTypes.bool.isRequired,
  toggleDay: PropTypes.func.isRequired,
  updateIndex: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecurrenceWeekly);
