const RESET = 'changePermissions/RESET';
const INITIALIZE_DATA = 'changePermissions/INITIALIZE_DATA';
const UPDATE_FORM_VALUE = 'changePermissions/UPDATE_FORM_VALUE';

const UPDATE_OWNER_ISLOADING = 'changePermissions/UPDATE_OWNER_ISLOADING';
const UPDATE_OWNER_SUGGESTIONS = 'changePermissions/UPDATE_OWNER_SUGGESTIONS';

const UPDATE_SUBMITTED = 'changePermissions/UPDATE_SUBMITTED';
const UPDATE_IS_SUBMITTING = 'changePermissions/UPDATE_IS_SUBMITTING';

export default {
  RESET,
  INITIALIZE_DATA,
  UPDATE_FORM_VALUE,
  UPDATE_OWNER_ISLOADING,
  UPDATE_OWNER_SUGGESTIONS,
  UPDATE_SUBMITTED,
  UPDATE_IS_SUBMITTING
};
