import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { editResourceSelectors, editResourceOperations } from '../../../state/editResource';
import { changeMasterDataOperations } from '../../../state/changeMasterData';
import { changeOwnerOperations } from '../../../state/changeOwner';
import { changePermissionsOperations } from '../../../state/changePermissions';
import { deleteResourceOperations } from '../../../state/deleteResource';

import Header from '../../Header';
import { withTranslation, Loader } from '../../common';
import ResourceDetail from './ResourceDetail';
import FormSwitch from './FormSwitch';

import MasterDataForm from './MasterDataForm';
import OwnerForm from './OwnerForm';
import PhotoForm from './PhotoForm';
import PermissionsForm from './PermissionsForm';
import DeleteForm from './DeleteForm';

import FormTypes from './formTypes';

import './_style.css';

class EditResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedForm: ''
    };
  }

  componentDidMount() {
    const {
      loadResource,
      resetMasterDataForm,
      resetOwnerForm,
      resetPermissionsForm,
      resetDeleteForm,
      match
    } = this.props;
    const id = match.params.resourceId;
    loadResource(id).then(resource => {
      resetMasterDataForm(resource);
      resetOwnerForm(resource);
      resetPermissionsForm();
      resetDeleteForm();
    });
  }

  changeSelectedForm = event => {
    this.setState({
      selectedForm: event.target.value
    });
  };

  renderMasterDataForm = () => {
    const { selectedForm } = this.state;

    if (selectedForm !== FormTypes.MASTERDATA) {
      return null;
    }

    return <MasterDataForm key={FormTypes.MASTERDATA} />;
  };

  renderOwnerForm = () => {
    const { selectedForm } = this.state;

    if (selectedForm !== FormTypes.OWNER) {
      return null;
    }

    return <OwnerForm key={FormTypes.OWNER} />;
  };

  renderPermissionsForm = () => {
    const { selectedForm } = this.state;

    if (selectedForm !== FormTypes.PERMISSIONS) {
      return null;
    }

    return <PermissionsForm key={FormTypes.PERMISSIONS} />;
  };

  renderPhotoForm = () => {
    const { resource } = this.props;
    const { selectedForm } = this.state;

    if (selectedForm !== FormTypes.PHOTO) {
      return null;
    }

    return <PhotoForm key={FormTypes.PHOTO} resource={resource} />;
  };

  renderDeleteForm = () => {
    const { selectedForm } = this.state;

    if (selectedForm !== FormTypes.DELETE) {
      return null;
    }

    return <DeleteForm key={FormTypes.DELETE} />;
  };

  renderForms = () => {
    const { resource, isResourceLoading } = this.props;

    if (!resource || isResourceLoading) {
      return null;
    }

    return (
      <div className="EditResource__Form">
        {this.renderOwnerForm()}
        {this.renderPermissionsForm()}
        {this.renderPhotoForm()}
        {this.renderDeleteForm()}
        {this.renderMasterDataForm()}
      </div>
    );
  };

  renderInformation = () => {
    const { resource, isResourceLoading } = this.props;
    const { selectedForm } = this.state;

    if (isResourceLoading || !resource) {
      return (
        <div className="EditResource__Loader">
          <Loader visible />
        </div>
      );
    }

    return (
      <>
        <ResourceDetail resource={resource} />
        <hr className="EditResource__Divider" />
        <FormSwitch
          resource={resource}
          onChange={this.changeSelectedForm}
          selectedForm={selectedForm}
        />
        <hr className="EditResource__Divider" />
      </>
    );
  };

  render() {
    const { translate, history } = this.props;
    const title = translate('changeRequests.title');

    return (
      <div className="EditResource">
        <Header onClose={history.goBack} title={title} iconRight icon="022-close" />
        {this.renderInformation()}
        {this.renderForms()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resource: editResourceSelectors.getResource(state),
  isResourceLoading: editResourceSelectors.isResourceLoading(state)
});

const mapDispatchToProps = dispatch => ({
  loadResource: id => dispatch(editResourceOperations.loadResource(id)),
  resetMasterDataForm: resource => dispatch(changeMasterDataOperations.reset(resource)),
  resetOwnerForm: resource => dispatch(changeOwnerOperations.reset(resource)),
  resetPermissionsForm: () => dispatch(changePermissionsOperations.reset()),
  resetDeleteForm: () => dispatch(deleteResourceOperations.reset())
});

EditResource.propTypes = {
  resource: PropTypes.shape(),
  loadResource: PropTypes.func.isRequired,
  resetMasterDataForm: PropTypes.func.isRequired,
  resetOwnerForm: PropTypes.func.isRequired,
  resetPermissionsForm: PropTypes.func.isRequired,
  resetDeleteForm: PropTypes.func.isRequired,
  isResourceLoading: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      resourceId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

const EditResourceComponent = connect(mapStateToProps, mapDispatchToProps)(EditResource);
export default withTranslation(withRouter(EditResourceComponent));
