import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  myChangeRequestsSelectors,
  myChangeRequestsOperations
} from '../../../state/myChangeRequests';
import { Spacing } from '../../common';
import MyChangeRequestsLoader from './MyChangeRequestsLoader';
import MyChangeRequestsHeader from './MyChangeRequestsHeader';
import MyChangeRequestsEmptyState from './MyChangeRequestsEmptyState';
import MyChangeRequestsTable from './MyChangeRequestsTable';

class MyChangeRequests extends Component {
  componentDidMount() {
    const { loadChangeRequests } = this.props;
    loadChangeRequests();
  }

  renderHeader = () => {
    const { hasChangeRequests } = this.props;
    return <MyChangeRequestsHeader showHelp={hasChangeRequests} />;
  };

  renderLoader = () => {
    const { isLoading } = this.props;
    return <MyChangeRequestsLoader visible={isLoading} />;
  };

  renderStartPage = () => {
    const { isLoading, hasChangeRequests } = this.props;
    const showStartPage = !isLoading && !hasChangeRequests;
    if (!showStartPage) {
      return null;
    }

    return <MyChangeRequestsEmptyState />;
  };

  renderChangeRequests = () => {
    const { isLoading, hasChangeRequests, changeRequests } = this.props;
    const showChangeRequests = !isLoading && hasChangeRequests;
    if (!showChangeRequests) {
      return null;
    }

    return (
      <div>
        <Spacing horizontal="1" vertical="1">
          <MyChangeRequestsTable changeRequests={changeRequests} />
        </Spacing>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderLoader()}
        {this.renderStartPage()}
        {this.renderChangeRequests()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: myChangeRequestsSelectors.isLoading(state),
  changeRequests: myChangeRequestsSelectors.getChangeRequests(state),
  hasChangeRequests: myChangeRequestsSelectors.hasChangeRequests(state)
});

const mapDispatchToProps = dispatch => ({
  loadChangeRequests: () => dispatch(myChangeRequestsOperations.loadChangeRequests())
});

MyChangeRequests.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  changeRequests: PropTypes.arrayOf(PropTypes.shape({})),
  hasChangeRequests: PropTypes.bool.isRequired,
  loadChangeRequests: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(MyChangeRequests);
