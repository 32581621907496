import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { FormField, withTranslation } from '../../common';

import './_style.css';

function BookDetailDate({ start, translate }) {
  const dateTitle = translate('search.detail.date.name');
  const formatDate = date => moment(date).format('DD.MM.YYYY');
  const label = <strong>{dateTitle}</strong>;
  const element = formatDate(start);

  return <FormField className="BookDetail__FormField" label={label} element={element} />;
}

BookDetailDate.propTypes = {
  start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookDetailDate);
