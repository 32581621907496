import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { modalOperations } from '../../../../state/modal';
import { Icon, withTranslation, ShowAt, CustomPropTypes } from '../../../common';

import './_style.css';

function SidebarChangeRequest({ resource, history, clearModals, translate }) {
  const { id, resourceType, roomType, isVirtual } = resource;
  const route = `/resources/${id}/changeRequest`;
  const label = translate('changeRequests.createChangeRequest');

  const isRoom = resourceType.toLowerCase() === 'room';
  const isTelepresence = roomType && roomType.toLowerCase() === 'telepresence';
  const isSurfaceHub = roomType && roomType.toLowerCase() === 'surfacehub';

  if (isRoom && (isTelepresence || isSurfaceHub || isVirtual)) {
    return null;
  }

  const redirectToChangeRequest = () => {
    clearModals();
    history.push(route);
  };

  return (
    <ShowAt breakpoint="900AndAbove" className="BookDetailSidebar__ChangeRequest">
      <button className="link" onClick={redirectToChangeRequest} role="link" type="button">
        <Icon className="BookDetailSidebar__ChangeRequestIcon" icon="238-robot-surprized" />
        {' '}
        {label}
      </button>
    </ShowAt>
  );
}

SidebarChangeRequest.propTypes = {
  resource: CustomPropTypes.resource.isRequired,
  clearModals: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

const mapDispatchToProps = dispatch => ({
  clearModals: () => dispatch(modalOperations.clearModals())
});

const ChangeRequestLink = connect(
  null,
  mapDispatchToProps
)(SidebarChangeRequest);

export default withRouter(withTranslation(ChangeRequestLink));
