import config from '../config';
import fetch from './fetch';

const getUrl = (path, id) => {
  const url = `${config.remoteUrl}/${path}`;
  return id ? `${url}/${id}` : url;
};

async function post(searchRequest) {
  const url = getUrl('bookings/my');
  return fetch(url, 'POST', searchRequest);
}

async function remove(id, payload) {
  const url = getUrl('bookings/delete', id);
  return fetch(url, 'POST', { ...payload });
}

async function hasOwnership() {
  const url = getUrl('permissions/ownership');
  try {
    return await fetch(url, 'GET');
  } catch (err) {
    return err;
  }
}

export default { post, remove, hasOwnership };
