import React from 'react';
import PropTypes from 'prop-types';

import { SelectBox, withTranslation } from '../common';

import './_style.css';

function EquipmentFilter(props) {
  const { filter, onChange, translate } = props;

  const renderEquipment = item => (
    <SelectBox
      key={item.key}
      className="EquipmentBox"
      selected={item.enabled}
      onClick={() => onChange(item)}
    >
      {item.name}
    </SelectBox>
  );

  return (
    <div className="EquipmentFilter">
      <div className="EquipmentFilter__Title">{translate('equipmentFilter.resourceTitle')}</div>
      <div className="EquipmentFilter__Section">
        <div className="EquipmentFilter__Content EquipmentBoxes">
          {filter.map(item => renderEquipment(item))}
        </div>
      </div>
    </div>
  );
}

EquipmentFilter.propTypes = {
  filter: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(EquipmentFilter);
