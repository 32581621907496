import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, ShowAt } from '../../common';
import { bookSelectors } from '../../../state/book';
import { modalOperations } from '../../../state/modal';
import BookDetailModalDesktop from './BookDetailModalDesktop';
import BookDetailModalMobile from './BookDetailModalMobile';

import keys from '../modalKeys';

import '../_style.css';

function BookDetailModal(props) {
  const { clearModals, deleteBooking, deleteMaster, selectedResource } = props;

  const deleteBookingWrapper = (id, calendarId, isRecurring, isOccurrence) => {
    if (isRecurring && !isOccurrence) {
      deleteMaster(id, calendarId);
    } else {
      deleteBooking(id, calendarId, selectedResource.id);
    }
  };
  return (
    <>
      <ShowAt breakpoint="900AndAbove">
        <Modal modalKey={keys.BOOK_DETAIL_MODAL} onOverlayClick={clearModals}>
          <BookDetailModalDesktop {...props} deleteBooking={deleteBookingWrapper} />
        </Modal>
      </ShowAt>
      <ShowAt breakpoint="900AndBelow">
        <Modal modalKey={keys.BOOK_DETAIL_MODAL} mobile>
          <BookDetailModalMobile {...props} deleteBooking={deleteBookingWrapper} />
        </Modal>
      </ShowAt>
    </>
  );
}

const mapStateToProps = state => ({
  selectedResource: bookSelectors.getSelectedResource(state),
  subject: bookSelectors.getSubject(state),
  attendees: bookSelectors.getSelectedAttendees(state),
  bookedFor: bookSelectors.getBookForPerson(state),
  message: bookSelectors.getMessage(state),
  start: bookSelectors.getStartTime(state),
  end: bookSelectors.getEndTime(state),
  isRecurring: bookSelectors.isRecurring(state),
  isPrivate: bookSelectors.isPrivate(state),
  isBookingCollection: bookSelectors.isBookingCollection(state),
  isUnhandledRecurrenceException: bookSelectors.isUnhandledRecurrenceException(state),
  roomMeetingResponseType: bookSelectors.getRoomMeetingResponseType(state),
  globalId: bookSelectors.getGlobalId(state),
  calendarId: bookSelectors.getCalendarId(state),
  seating: bookSelectors.getSeating(state)
});

const mapDispatchToProps = dispatch => ({
  clearModals: () => dispatch(modalOperations.clearModals())
});

BookDetailModal.propTypes = {
  clearModals: PropTypes.func.isRequired,
  deleteBooking: PropTypes.func.isRequired,
  deleteMaster: PropTypes.func.isRequired,
  selectedResource: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookDetailModal);
