import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Translate, Favorite } from '../../../common';
import { favoriteSelectors, favoriteOperations } from '../../../../state/favorite';

import './_style.css';

class SidebarFavorite extends Component {
  toggle = () => {
    const { addFavorite, removeFavorite, resourceId, active } = this.props;
    const action = active ? removeFavorite : addFavorite;
    action(resourceId);
  };

  render() {
    const { active, resourceId } = this.props;

    return (
      <div className="BookDetailSidebar__Favorite">
        <div className="BookDetailSidebar__FavoriteIcon">
          <Favorite small tooltip={false} resourceId={resourceId} />
        </div>
        <button
          aria-label="Favorite"
          role="link"
          onClick={this.toggle}
          className="BookDetailSidebar__FavoriteText"
          type="button"
        >
          <Translate path={`favorite.${active ? 'removeFavorite' : 'addFavorite'}`} />
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state, { resourceId }) => ({
  active: favoriteSelectors.isFavorite(state, resourceId)
});

const mapDispatchToProps = dispatch => ({
  addFavorite: id => dispatch(favoriteOperations.addFavorite(id)),
  removeFavorite: id => dispatch(favoriteOperations.removeFavorite(id))
});

SidebarFavorite.propTypes = {
  active: PropTypes.bool,
  resourceId: PropTypes.string.isRequired,
  addFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarFavorite);
