import moment from 'moment';

const translationPath = path => `search.results.${path}`;

const getEvening = date => moment(date)
  .set('hours', 19)
  .set('minutes', 0)
  .set('seconds', 0);

const getMorning = date => moment(date)
  .set('hours', 8)
  .set('minutes', 0)
  .set('seconds', 0);

const freeFromUntilEvening = ({ start }) => {
  const startTime = moment(start).format('HH:mm');
  return [translationPath('freeFromUntilEvening'), { start: startTime }];
};

const freeFromUntil = ({ start, end }) => {
  const startTime = moment(start).format('HH:mm');
  const endTime = moment(end).format('HH:mm');
  return [translationPath('freeFromUntil'), { start: startTime, end: endTime }];
};

const freeFor = ({ end }) => {
  const now = moment();
  const endTime = moment(end);
  const hours = endTime.diff(now, 'hours');
  const minutes = endTime.subtract(hours, 'hours').diff(now, 'minutes');
  if (hours === 0 && minutes > 0) {
    return [translationPath('freeFor.minutes'), { minutes }];
  }
  if (minutes === 0 && hours > 0) {
    if (hours === 1) {
      return [translationPath('freeFor.hours.single'), { hours }];
    }
    return [translationPath('freeFor.hours.normal'), { hours }];
  }

  if (hours === 1 && minutes > 1) {
    return [translationPath('freeFor.both.singleHour'), { hours, minutes }];
  }

  if (hours > 1 && minutes === 1) {
    return [translationPath('freeFor.both.singleMinute'), { hours, minutes }];
  }

  if (hours === 1 && minutes === 1) {
    return [translationPath('freeFor.both.singleBoth'), { hours, minutes }];
  }
  return [translationPath('freeFor.both.normal'), { hours, minutes }];
};

const freeFrom = ({ start }) => {
  const startTime = moment(start).format('HH:mm');
  return [translationPath('freeFrom'), { start: startTime }];
};

const getRelativeAvailabilityTranslation = availabilities => {
  const availability = availabilities[0];
  const { start, end } = availability;
  const evening = getEvening(end);
  const morning = getMorning(start);

  const isAvailableNow = moment(start).isBefore(moment());
  const isToday = moment().isSame(moment(start), 'day');
  const isAvailableUntilEvening = moment(end).isSameOrAfter(evening);
  const isAvailableAllDay = moment(start).isSameOrBefore(morning) && isAvailableUntilEvening;

  if (!isAvailableNow && !isAvailableUntilEvening) {
    return freeFromUntil(availability);
  }

  if (!isToday && !isAvailableAllDay) {
    if (isAvailableUntilEvening) {
      return freeFromUntilEvening(availability);
    }
  }

  if (!isToday) {
    return [translationPath('freeUntilEvening')];
  }

  if (!isAvailableNow && isAvailableUntilEvening) {
    return freeFrom(availability);
  }

  if (!isAvailableUntilEvening) {
    return freeFor(availability);
  }

  return [translationPath('freeUntilTonight')];
};

const calculateRelativeAvailability = (availabilities, translate) => {
  if (!availabilities || availabilities.length <= 0) {
    return '';
  }

  return translate(...getRelativeAvailabilityTranslation(availabilities));
};

export default calculateRelativeAvailability;
