import types from '../types';

const updateResources = resources => ({
  type: types.UPDATE_RESOURCES,
  resources
});

const updateResourcesLoading = loading => ({
  type: types.UPDATE_RESOURCES_LOADING,
  loading
});

export default {
  updateResources,
  updateResourcesLoading
};
