import React from 'react';
import PropTypes from 'prop-types';

import { FormField, withTranslation, CollapsibleText } from '../../common';

function BookDetailMessage({ message, translate }) {
  const messageTitle = translate('search.detail.message.name');
  const label = <strong>{messageTitle}</strong>;
  const element = (
    <CollapsibleText className="BookDetailMessage" text={message || '-'} height={72} />
  );

  return <FormField className="BookDetail__FormField" label={label} element={element} />;
}

BookDetailMessage.propTypes = {
  message: PropTypes.string,
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookDetailMessage);
