const UPDATE_USER = 'auth/UPDATE_USER';
const UPDATE_USER_IS_LOADING = 'auth/UPDATE_USER_IS_LOADING';
const USER_EXPIRED = 'auth/USER_EXPIRED';
const SILENT_RENEW_ERROR = 'auth/SILENT_RENEW_ERROR';
const USER_LOGOUT = 'account/LOGOUT';

export default {
  UPDATE_USER,
  UPDATE_USER_IS_LOADING,
  USER_EXPIRED,
  SILENT_RENEW_ERROR,
  USER_LOGOUT
};
