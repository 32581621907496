import { combineReducers } from 'redux';
import uniqBy from 'lodash/fp/uniqBy';
import types from './types';

const initialState = {
  availability: [],
  isLoading: false,
  totalCount: 0,
  skipCount: 0,
  hasMore: false,
  isRecurrence: false
};

const uniqAvailabilities = uniqBy('resource.id');

const availabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_AVAILABILITY: {
      return {
        ...state,
        availability: action.availability
      };
    }

    case types.ADD_AVAILABILITY: {
      return {
        ...state,
        availability: uniqAvailabilities([...state.availability, ...action.availability])
      };
    }

    case types.UPDATE_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      };
    }

    case types.UPDATE_SKIPCOUNT: {
      return {
        ...state,
        skipCount: action.skipCount
      };
    }

    case types.UPDATE_TOTALCOUNT: {
      return {
        ...state,
        totalCount: action.totalCount
      };
    }

    case types.UPDATE_HASMORE: {
      return {
        ...state,
        hasMore: action.hasMore
      };
    }

    case types.UPDATE_ISRECURRENCE: {
      return {
        ...state,
        isRecurrence: action.isRecurrence
      };
    }

    case types.REMOVE_RESOURCE_BY_ID: {
      return {
        ...state,
        availability: state.availability.filter(o => o.resource.id !== action.resourceId)
      };
    }

    case types.UPDATE_RESOURCE_AVAILABILITIES: {
      const availability = state.availability.map(item => {
        if (action.resource.id === item.resource.id) {
          return {
            ...item,
            availableOccurrences: action.availabilities.availableOccurrences,
            nonAvailableOccurrences: action.availabilities.nonAvailableOccurrences,
            numberOfAvailabilities: action.availabilities.numberOfAvailabilities,
            numberOfNonAvailabilities: action.availabilities.numberOfNonAvailabilities,
            resource: {
              ...item.resource,
              availabilitiesLoaded: true
            }
          };
        }
        return item;
      });

      return {
        ...state,
        availability
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  availability: availabilityReducer
});
