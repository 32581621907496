import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from '../../../Header';
import Recurrence from '../../../Recurrence';
import { Button, withTranslation } from '../../../common';
import { availabilityOperations } from '../../../../state/availability';
import { recurrenceSelectors } from '../../../../state/recurrence';

import './_style.css';

function SearchFormRecurrence({ isValid, loadAvailabilities, history, translate }) {
  const onSave = () => {
    if (isValid) {
      loadAvailabilities();
      history.goBack();
    }
  };

  const renderHeader = () => {
    const title = translate('search.when.recurrence.title');
    return <Header onClose={onSave} title={title} />;
  };

  const renderButton = () => {
    const label = translate('common.save');
    return <Button label={label} className="button--fixed" onClick={onSave} disabled={!isValid} />;
  };

  return (
    <>
      {renderHeader()}
      <div className="SearchFormRecurrence">
        <Recurrence />
      </div>
      {renderButton()}
    </>
  );
}

const mapStateToProps = state => ({
  isValid: recurrenceSelectors.isValid(state)
});

const mapDispatchToProps = dispatch => ({
  loadAvailabilities: () => dispatch(availabilityOperations.loadAvailabilities())
});

SearchFormRecurrence.propTypes = {
  isValid: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  loadAvailabilities: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const SearchFormRecurrenceComponent = withTranslation(withRouter(SearchFormRecurrence));
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFormRecurrenceComponent);
