import { combineReducers } from 'redux';
import store from 'store2';

import types from './types';
import { modalTypes } from '../modal';

const initialState = {
  resource: null,
  date: '',
  time: { from: '', to: '' },
  isSubmitting: false,
  isValidTimeRange: true,
  message: '',
  subject: 'Booked with ReservationHub',
  reminder: store.get('reminder', true),
  start: '',
  end: '',
  isRecurring: false,
  isPrivate: false,
  globalId: '',
  calendarId: '',
  seating: null,
  seatingId: null,
  bookForPerson: null,
  bookForSuggestions: [],
  bookForIsLoading: false,
  suggestedAttendees: [],
  selectedAttendees: [],
  attendeesSearchTerm: '',
  attendeesIsLoading: false
};

const bookReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_SELECTED_RESOURCE: {
      return {
        ...state,
        resource: action.resource
      };
    }

    case types.UPDATE_IS_SUBMITTING: {
      return {
        ...state,
        isSubmitting: action.isSubmitting
      };
    }

    case types.UPDATE_IS_VALIDTIMERANGE: {
      return {
        ...state,
        isValidTimeRange: action.isValidTimeRange
      };
    }

    case types.UPDATE_DATE: {
      return {
        ...state,
        date: action.date
      };
    }

    case types.UPDATE_TIME: {
      return {
        ...state,
        time: action.time
      };
    }

    case types.UPDATE_MESSAGE: {
      return {
        ...state,
        message: action.message
      };
    }

    case types.UPDATE_SUBJECT: {
      return {
        ...state,
        subject: action.subject
      };
    }

    case types.UPDATE_REMINDER: {
      return {
        ...state,
        reminder: action.reminder
      };
    }

    case types.LOAD_BOOKING: {
      return {
        ...initialState,
        ...action.booking
      };
    }

    case types.UPDATE_SELECTED_BOOKING: {
      const { booking, buildings } = action;
      const newBooking = {
        ...state,
        ...booking
      };
      newBooking.resource.building = buildings[newBooking.resource.buildingId];

      return newBooking;
    }

    case types.RESET_STATE: {
      const { resource } = state;
      return {
        ...initialState,
        resource
      };
    }

    case types.CACHE_STATE: {
      return {
        ...state,
        cachedState: state
      };
    }

    case types.RESET_STATE_FROM_CACHE: {
      return {
        ...state.cachedState
      };
    }

    case types.UPDATE_BOOKFOR_PERSON: {
      return { ...state, bookForPerson: action.person };
    }

    case types.UPDATE_BOOKFOR_ISLOADING: {
      return { ...state, bookForIsLoading: action.isLoading };
    }

    case types.UPDATE_BOOKFOR_SUGGESTIONS: {
      return { ...state, bookForSuggestions: action.suggestions };
    }

    case types.UPDATE_SUGGESTED_ATTENDEES: {
      return { ...state, suggestedAttendees: action.suggestedAttendees };
    }

    case types.UPDATE_SELECTED_ATTENDEES: {
      return { ...state, selectedAttendees: action.selectedAttendees };
    }

    case types.CLEAR_ATTENDEES: {
      return { ...state, selectedAttendees: [], attendees: [] };
    }

    case types.SET_ATTENDEES_SEARCH_TERM: {
      return { ...state, attendeesSearchTerm: action.term };
    }

    case types.TOGGLE_ATTENDEES_LOADER: {
      return { ...state, attendeesIsLoading: action.isLoading };
    }

    case types.UPDATE_RESOURCE_AVAILABILITIES_LOADING: {
      return { ...state, resource: { ...state.resource, availabilitiesLoaded: action.loaded } };
    }

    case types.UPDATE_SEATING_ID: {
      return { ...state, seatingId: action.seatingId };
    }

    case types.UPDATE_RESOURCE_AVAILABILITIES: {
      return {
        ...state,
        resource: {
          ...state.resource,
          availabilities: action.availabilities.availableOccurrences,
          exceptions: action.availabilities.nonAvailableOccurrences,
          numberOfAvailabilities: action.availabilities.numberOfAvailabilities,
          numberOfNonAvailabilities: action.availabilities.numberOfNonAvailabilities,
          availabilitiesLoaded: true
        }
      };
    }

    case modalTypes.CLEAR_MODALS: {
      return {
        ...state,
        reminder: store.get('reminder', true)
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  book: bookReducer
});
