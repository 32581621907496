import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../../../common';

function BuildingsLoader({ visible }) {
  if (!visible) {
    return null;
  }

  return (
    <div className="Buildings__Loader">
      <Loader visible />
    </div>
  );
}

BuildingsLoader.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default BuildingsLoader;
