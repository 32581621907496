import { createSelector } from 'reselect';
import keys from 'lodash/fp/keys';
import { bookSelectors } from '../../book';
import { isProfile3 } from '../../../util';
import { buildingsSelectors } from '../../buildings';

const { getBuildings } = buildingsSelectors;
const getAlternatives = state => state.bookingCollectionState.alternatives.sections;

const getSectionNames = createSelector(
  getAlternatives,
  keys
);

const getResourceType = createSelector(
  bookSelectors.getSelectedResource,
  resource => resource && resource.resourceType
);

const getAlternativeResources = createSelector(
  [getAlternatives, getSectionNames, getBuildings, getResourceType],
  (alternatives, sections, buildings, resourceType) => {
    if (resourceType === 'Equipment') {
      return [];
    }

    const mapAvailability = (availability, section) => {
      const { resource, availabilities } = availability;
      return {
        availabilities,
        ...resource,
        building: buildings.find(building => building.id === resource.buildingId),
        alternativeType: section
      };
    };

    return sections.reduce((allRooms, section) => {
      const rooms = alternatives[section].availability
        .filter(({ resource }) => !isProfile3(resource))
        .map(room => mapAvailability(room, section));
      return [...allRooms, ...rooms];
    }, []);
  }
);

const areAlternativesLoading = createSelector(
  [getAlternatives, getSectionNames],
  (sections, sectionNames) => sectionNames.reduce((result, section) => result || sections[section].isLoading, false)
);

const getHasMore = section => createSelector(
  getAlternatives,
  sections => sections[section].hasMore
);

const getIsLoading = section => createSelector(
  getAlternatives,
  sections => sections[section].isLoading
);

const getSkipCount = section => createSelector(
  getAlternatives,
  sections => sections[section].skipCount
);

export default {
  areAlternativesLoading,
  getSectionNames,
  getAlternativeResources,
  getHasMore,
  getIsLoading,
  getSkipCount
};
