import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { HideAt } from '../common';

function MenuItem({ hideAt, children, className, style }) {
  const classes = cx('Menu__Item', className);
  if (hideAt) {
    return (
      <HideAt breakpoint={hideAt} className={classes} style={style}>
        {children}
      </HideAt>
    );
  }

  return <div className="Menu__Item">{children}</div>;
}

MenuItem.propTypes = {
  hideAt: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape()
};

export default MenuItem;
