import types from './types';

const updateFloors = floors => ({
  type: types.UPDATE_FLOORS,
  floors
});

const updateIsLoaded = age => ({
  type: types.UPDATE_LOADED,
  age
});

const setSelectedFloor = selectedFloor => ({
  type: types.SET_SELECTED_FLOOR,
  selectedFloor
});

const resetFloors = () => ({
  type: types.RESET_FLOORS
});

export default {
  updateFloors,
  updateIsLoaded,
  setSelectedFloor,
  resetFloors
};
