import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './_style.css';

function FormField({ label, element, wrap, truncate, className }) {
  const classes = cx('FormField', { 'FormField--wrap': wrap }, className);
  const elementClasses = cx('FormField__Element', {
    'FormField__Element--truncate': truncate
  });

  return (
    <div className={classes}>
      <div className="FormField__Label">{label}</div>
      <div className={elementClasses}>{element}</div>
    </div>
  );
}

FormField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  element: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  truncate: PropTypes.bool,
  wrap: PropTypes.bool,
  className: PropTypes.string
};

FormField.defaultProps = {
  truncate: true,
  wrap: false
};

export default FormField;
