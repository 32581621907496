import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import store from 'store2';

import key from './key';
import { ShowAt } from '../common';
import { modalOperations } from '../../state/modal';
import WelcomeModal from './WelcomeModal';
import WelcomeDialog from './WelcomeDialog';

const flag = 'welcomemessage.ignore.info';

const shouldIgnoreInfo = () => {
  const userHasIgnoredInfo = store.has(flag) || store.session.has(flag);
  const obsolete = moment().isAfter('2019-09-30');
  return userHasIgnoredInfo || obsolete;
};

const ignore4ever = () => store(flag, true);
const ignore4session = () => store.session(flag, true);

class WelcomeMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doNotShow: false,
      isInfoIgnored: shouldIgnoreInfo()
    };
  }

  componentDidMount() {
    const { openDialog } = this.props;
    const { isInfoIgnored } = this.state;
    if (!isInfoIgnored) {
      openDialog();
    }
  }

  onClose = () => {
    const { closeDialog } = this.props;
    this.ignore();
    closeDialog();
  };

  ignore = () => {
    const { doNotShow } = this.state;
    const ignore = doNotShow ? ignore4ever : ignore4session;
    ignore();
  };

  toggleDoNotShow = () => {
    const { doNotShow } = this.state;
    this.setState({ doNotShow: !doNotShow });
  };

  render() {
    const { isInfoIgnored, doNotShow } = this.state;
    if (isInfoIgnored) {
      return null;
    }

    const props = {
      doNotShow,
      toggleDoNotShow: this.toggleDoNotShow,
      close: this.onClose,
      ignore: this.ignore
    };

    return (
      <div>
        <ShowAt breakpoint="900AndBelow">
          <WelcomeModal {...props} />
        </ShowAt>

        <ShowAt breakpoint="900AndAbove">
          <WelcomeDialog {...props} />
        </ShowAt>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openDialog: () => dispatch(modalOperations.pushDialog(key)),
  closeDialog: () => dispatch(modalOperations.popDialog())
});

WelcomeMessage.propTypes = {
  openDialog: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(WelcomeMessage);
