import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TabBar, Tab, Translate } from '../../../common';
import styles from './ContentSwitch.module.css';
import { recurrenceSelectors } from '../../../../state/recurrence';

const views = ['now', 'daily', 'weekly'];
const getCurrentView = ({ pathname }) => (pathname.split('/')[2] || 'now').toLowerCase();

function ContentSwitch({ history, location, isRecurrenceActive }) {
  const redirect = view => {
    history.push(`/search/${view}`);
  };
  const currentView = getCurrentView(location);

  return (
    <TabBar value={currentView} onChange={redirect} className={styles.bar}>
      {views.map(view => (
        <Tab
          key={view}
          value={view}
          className={styles.tab}
          activeClassName={styles.active}
          disabledClassName={styles.disabled}
          isDisabled={(view === 'daily' || view === 'weekly') && isRecurrenceActive}
        >
          <Translate path={`search.switch.${view}`} />
        </Tab>
      ))}
    </TabBar>
  );
}

const mapStateToProps = state => ({
  isRecurrenceActive: recurrenceSelectors.isActive(state)
});

export default connect(
  mapStateToProps,
  null
)(withRouter(ContentSwitch));

ContentSwitch.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  isRecurrenceActive: PropTypes.bool
};
