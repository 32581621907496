import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Icon } from '../common';

function BackLink({ history }) {
  const onClick = () => history.goBack();
  const classes = 'link Header__Item Header__Item--left Header__Link';
  return (
    <button aria-label="Back" className={classes} onClick={onClick} role="link" tabIndex={0} type="button">
      <Icon icon="014-chevron-left" className="Back-Link" />
    </button>
  );
}

BackLink.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(BackLink);
