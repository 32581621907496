import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './_style.css';

function ModalContent({ className, children }) {
  const classes = cx('Modal', className);

  return <div className={classes}>{children}</div>;
}

ModalContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired
};

export default ModalContent;
