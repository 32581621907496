// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsNew_body__h46yK {\n  padding: 80px 20px 20px 20px;\n  display: flex;\n  justify-content: center;\n}\n\n.NewsNew_bodyForm__QTnFY{\n  width: 700px;\n}\n\n.NewsNew_buttonGroup__0XCV7 {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 40px;\n  padding-bottom: 60px;\n}\n\n.NewsNew_button__NIOV\\+ {\n  margin-left: 20px;\n}", "",{"version":3,"sources":["webpack://./src/views/Admin/NewsNew/NewsNew.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,gBAAgB;EAChB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".body {\n  padding: 80px 20px 20px 20px;\n  display: flex;\n  justify-content: center;\n}\n\n.bodyForm{\n  width: 700px;\n}\n\n.buttonGroup {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 40px;\n  padding-bottom: 60px;\n}\n\n.button {\n  margin-left: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "NewsNew_body__h46yK",
	"bodyForm": "NewsNew_bodyForm__QTnFY",
	"buttonGroup": "NewsNew_buttonGroup__0XCV7",
	"button": "NewsNew_button__NIOV+"
};
export default ___CSS_LOADER_EXPORT___;
