import React from 'react';
import PropTypes from 'prop-types';

import SidebarFormField from './SidebarFormField';

function SidebarRoomEquipment({ equipmentIcons }) {
  return <SidebarFormField label="common.equipment" element={equipmentIcons} />;
}

SidebarRoomEquipment.propTypes = {
  equipmentIcons: PropTypes.shape({})
};

export default SidebarRoomEquipment;
