import React from 'react';
import PropTypes from 'prop-types';

import { ShowAt, CustomPropTypes, withTranslation } from '../../../common';

import BookingRoomCardMobile from './BookingRoomCardMobile';
import BookingRoomCardTabletAndDesktop from './BookingRoomCardTabletAndDesktop';

import { isProfile3 } from '../../../../util';

import '../_style.css';

function BookingRoomCard({ booking, selected, onOpenOccurrence, onOpenMaster, style }) {
  const { resource } = booking;

  const profile3 = isProfile3(resource);
  const profile2 = resource.profile === 2;

  const props = {
    booking,
    onOpenOccurrence,
    onOpenMaster,
    selected,
    isProfile3: profile3,
    isProfile2: profile2,
    style
  };

  return (
    <div className="BookingRoomCard">
      <ShowAt breakpoint="600AndBelow">
        <BookingRoomCardMobile {...props} />
      </ShowAt>
      <ShowAt breakpoint="600AndAbove">
        <BookingRoomCardTabletAndDesktop {...props} />
      </ShowAt>
    </div>
  );
}

BookingRoomCard.propTypes = {
  booking: CustomPropTypes.booking,
  style: PropTypes.shape(),
  selected: PropTypes.bool.isRequired,
  onOpenOccurrence: PropTypes.func.isRequired,
  onOpenMaster: PropTypes.func.isRequired
};

export default withTranslation(BookingRoomCard);
