import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SidebarLocation from './SidebarLocation';
import SidebarResourceType from './SidebarResourceType';
import SidebarRoomSize from './SidebarRoomSize';
import SidebarRoomEquipment from './SidebarRoomEquipment';
import SidebarFavorite from './SidebarFavorite';
import {
  TrayIcons,
  DataQualityInformation,
  ShowAt,
  Spacing,
  CustomPropTypes
} from '../../../common';

import './_style.css';
import SidebarSeatingArrangement from './SidebarSeatingArrangement';

class BookDetailSidebar extends Component {
  renderSidebarLocation = () => {
    const { resource } = this.props;
    const { building } = resource;
    if (!building) {
      return null;
    }
    return <SidebarLocation location={building.key} />;
  };

  renderSidebarEquipmentType = () => {
    const { resource } = this.props;
    const { equipmentTypeDisplayName, resourceSubType } = resource;
    if (!equipmentTypeDisplayName) {
      return null;
    }
    return <SidebarResourceType resourceType={resourceSubType} type="equipment" />;
  };

  renderSidebarRoomType = () => {
    const { resource } = this.props;
    const { roomTypeDisplayName, roomType } = resource;
    if (!roomTypeDisplayName) {
      return null;
    }
    return <SidebarResourceType resourceType={roomType} type="roomTypes" />;
  };

  renderSidebarRoomSize = () => {
    const { resource } = this.props;
    const { capacity } = resource;
    if (!capacity || capacity < 1) {
      return null;
    }
    return <SidebarRoomSize roomSize={capacity} />;
  };

  renderSidebarRoomEquipment = () => {
    const { resource } = this.props;
    const { roomEquipment } = resource;
    if (!roomEquipment || roomEquipment.length < 1) {
      return null;
    }
    return <SidebarRoomEquipment equipmentIcons={<TrayIcons equipment={roomEquipment} />} />;
  };

  renderSidebarSeating = () => {
    const { seating } = this.props;
    return <SidebarSeatingArrangement seating={seating} />;
  };

  renderSidebarFavorite = () => {
    const { resource } = this.props;
    const { id, isFavorite } = resource;
    return <SidebarFavorite resourceId={id} active={isFavorite} />;
  };

  renderDataQualityInformation = () => {
    const { resource } = this.props;
    const { resourceType } = resource;
    if (resourceType !== 'Equipment') {
      return null;
    }
    return (
      <>
        <ShowAt breakpoint="600AndAbove">
          <Spacing top="1">
            <DataQualityInformation width="100%" path="resourceDataQuality" small />
          </Spacing>
        </ShowAt>
        <ShowAt breakpoint="600AndBelow">
          <Spacing top="1">
            <DataQualityInformation width="100%" collapsible path="resourceDataQuality" small />
          </Spacing>
        </ShowAt>
      </>
    );
  };

  render() {
    return (
      <div className="SidebarInformation">
        {this.renderSidebarLocation()}
        {this.renderSidebarEquipmentType()}
        {this.renderSidebarRoomType()}
        {this.renderSidebarRoomSize()}
        {this.renderSidebarRoomEquipment()}
        {this.renderSidebarSeating()}
        {this.renderSidebarFavorite()}
        {this.renderDataQualityInformation()}
      </div>
    );
  }
}

BookDetailSidebar.propTypes = {
  resource: CustomPropTypes.resource,
  seating: PropTypes.shape()
};

export default BookDetailSidebar;
