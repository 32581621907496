import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

class TabBar extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  getChildContext() {
    const { value } = this.props;

    return {
      tabBar: {
        value,
        onChange: this.onChange
      }
    };
  }

  onChange(value) {
    const { value: lastValue, onChange } = this.props;
    if (value !== lastValue) {
      if (onChange) {
        onChange(value);
      }
    }
  }

  render() {
    const { className, children } = this.props;
    const classes = cx(className);

    return <div className={classes}>{children}</div>;
  }
}

TabBar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

TabBar.childContextTypes = {
  tabBar: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  })
};

export default TabBar;
