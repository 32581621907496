import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import cx from 'classnames';
import omit from 'lodash/fp/omit';
import isEqual from 'lodash/fp/isEqual';

import { isEscape, isEnter, isUpwards, isDownwards } from '../../../util';
import {
  getNextFocusedItems,
  getPreviousFocusedItems,
  findByValue
} from '../Input/AutoComplete/autocompleteUtil';

import Icon from '../Icon/Icon';
import Translate from '../I18n/Translate';
import AutoCompleteDropdown from '../Input/AutoComplete/AutoCompleteDropdown';

import './_style.css';

class PersonPicker extends Component {
  constructor(props) {
    super(props);

    const { person } = props;

    this.state = {
      focused: false,
      term: (person && person.name && person.value ? `${person.name}, ${person.value}` : ''),
      suggestions: [],
      dirty: false
    };
  }

  UNSAFE_componentWillReceiveProps({ suggestions: nextSuggestions, person: nextPerson }) {
    const { suggestions: prevSuggestions, person: prevPerson } = this.props;
    if (prevSuggestions !== nextSuggestions) {
      this.setState({ suggestions: nextSuggestions });
    }

    if (!isEqual(prevPerson, nextPerson)) {
      this.setState({ term: (nextPerson && nextPerson.name && nextPerson.value
        ? `${nextPerson.name}, ${nextPerson.value}` : '')
      });
    }
  }

  onChange = event => {
    const { loadSuggestions, updatePerson } = this.props;
    const term = event.target.value;
    this.setState({ term, dirty: true });
    loadSuggestions(term);
    updatePerson(null);
  };

  onSelect = value => {
    const { loadSuggestions, updatePerson } = this.props;
    const person = omit(['focused'], value);
    this.setState({ term: `${person.name}, ${person.value}` });
    updatePerson(person);
    loadSuggestions('');
  };

  onKeyDown = event => {
    const { keyCode } = event;
    const { suggestions } = this.state;

    if (isEscape(keyCode)) {
      this.exit();
    }

    if (isUpwards(keyCode) || isDownwards(keyCode)) {
      event.preventDefault();
      const newSuggestions = isDownwards(keyCode)
        ? getNextFocusedItems(suggestions)
        : getPreviousFocusedItems(suggestions);
      this.setState({ suggestions: newSuggestions, focused: true });
    }

    if (isEnter(keyCode)) {
      event.preventDefault();
      const selectedPerson = suggestions.find(s => s.focused);
      if (selectedPerson) {
        const person = findByValue(selectedPerson, suggestions);
        if (person) {
          this.onSelect(person);
          this.exit();
        }
      }
    }
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  exit = () => {
    this.setState({
      focused: false
    });
  };

  handleClickOutside = () => {
    this.exit();
  };

  isValid = () => {
    const { focused, dirty, term } = this.state;
    const { person, required } = this.props;
    if (focused) {
      return true;
    }

    if (dirty && !required && term === '') {
      return true;
    }
    return person !== null;
  };

  render() {
    const { term, focused, suggestions, dirty } = this.state;
    const { className, isLoading, placeholder, invalid } = this.props;

    const isInvalid = invalid || (dirty && !this.isValid());

    const classes = cx(
      className,
      'PersonPicker',
      'input-field input-field--autocomplete input-field--fixed',
      {
        'PersonPicker--invalid': isInvalid,
        'is-open': focused && suggestions.length > 0
      }
    );

    const props = {
      type: 'text',
      value: term,
      placeholder,
      onFocus: this.onFocus,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      className: cx({ invalid: isInvalid }),
      ref: node => {
        this.input = node;
      }
    };
    return (
      <div className={classes} onFocus={this.onFocus}>
        <input {...props} />
        <AutoCompleteDropdown
          items={term === '' ? [] : suggestions}
          value={term}
          onChange={this.onSelect}
          onKeyDown={this.onKeyDown}
          showLoader
          loading={isLoading}
        />
        <div className="PersonPicker__Error">
          <Icon icon="026-exclamation-mark-circle" />
          <Translate path="personpicker.validationError" />
        </div>
      </div>
    );
  }
}

const personPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
});

PersonPicker.propTypes = {
  placeholder: PropTypes.string,
  person: personPropType,
  isLoading: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(personPropType),
  loadSuggestions: PropTypes.func.isRequired,
  updatePerson: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool
};

PersonPicker.defaultProps = {
  invalid: false,
  required: false
};

export default onClickOutside(PersonPicker);
