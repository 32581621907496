const UPDATE_SUGGESTED_ATTENDEES = 'book/UPDATE_SUGGESTED_ATTENDEES';
const UPDATE_SELECTED_ATTENDEES = 'book/UPDATE_SELECTED_ATTENDEES';
const CLEAR_ATTENDEES = 'book/CLEAR_ATTENDEES';
const SET_ATTENDEES_SEARCH_TERM = 'book/SET_ATTENDEES_SEARCH_TERM';
const TOGGLE_ATTENDEES_LOADER = 'book/TOGGLE_ATTENDEES_LOADER';

export default {
  UPDATE_SUGGESTED_ATTENDEES,
  UPDATE_SELECTED_ATTENDEES,
  CLEAR_ATTENDEES,
  SET_ATTENDEES_SEARCH_TERM,
  TOGGLE_ATTENDEES_LOADER
};
