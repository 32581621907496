/* eslint-disable import/first, react/jsx-filename-extension */

import 'react-app-polyfill/ie11';
import './styles/_index.css';

import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { loadUser } from './auth';
import { AuthProvider } from './views/common';
import configureStore from './state/store';
import App from './views/App';

export default (translations, userManager) => {
  const store = configureStore(translations);
  loadUser(store, userManager);

  const container = document.getElementById('root');
  const root = createRoot(container);
  root.render(
    <ReduxProvider store={store}>
      <Router>
        <AuthProvider userManager={userManager}>
          <App />
        </AuthProvider>
      </Router>
    </ReduxProvider>
    );
};
