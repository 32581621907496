import store from 'store2';
import includes from 'lodash/fp/includes';
import actions from './actions';
import accountService from '../../services/accountService';

const { updateUser, updateUserIsLoading, userExpired, silentRenewError } = actions;

const ignoreRoutes = ['/callback', '/silentrenew', '/login'];

const updateRoute = (path, queryParams) => {
  if (path && !includes(path, ignoreRoutes)) {
    store.session('location', { path, queryParams });
  }
};

const logout = () => async dispatch => {
  dispatch(actions.userLogout());
  await accountService.logout();
};

export default {
  updateUser,
  updateUserIsLoading,
  userExpired,
  silentRenewError,
  updateRoute,
  logout
};
