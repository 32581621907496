const UPDATE_TIME = 'bookingCollection/UPDATE_TIME';
const ADD_RESOURCE = 'bookingCollection/ADD_RESOURCE';
const REMOVE_RESOURCE = 'bookingCollection/REMOVE_RESOURCE';
const RESET_COLLECTION_STATE = 'bookingCollection/RESET_COLLECTION_STATE';
const UPDATE_SEATING_ID = 'bookingCollection/UPDATE_SEATING_ID';

const ADD_RESOURCES = 'bookingCollection/ADD_RESOURCES';
const UPDATE_RESOURCES = 'bookingCollection/UPDATE_RESOURCES';
const UPDATE_RESOURCES_LOADING = 'bookingCollection/UPDATE_RESOURCES_LOADING';
const UPDATE_TOTALCOUNT = 'bookingCollection/UPDATE_TOTALCOUNT';
const UPDATE_SKIPCOUNT = 'bookingCollection/UPDATE_SKIPCOUNT';
const UPDATE_HASMORE = 'bookingCollection/UPDATE_HASMORE';

const CLEAR_ALTERNATIVE_AVAILABILITIES = 'bookingCollection/CLEAR_ALTERNATIVE_AVAILABILITIES';
const ADD_ALTERNATIVE_AVAILABILITY = 'bookingCollection/ADD_ALTERNATIVE_AVAILABILITY';
const UPDATE_ALTERNATIVE_AVAILABILITY = 'bookingCollection/UPDATE_ALTERNATIVE_AVAILABILITY';
const UPDATE_ALTERNATIVE_SKIPCOUNT = 'bookingCollection/UPDATE_ALTERNATIVE_SKIPCOUNT';
const UPDATE_ALTERNATIVE_LOADING = 'bookingCollection/UPDATE_ALTERNATIVE_LOADING';
const HAS_MORE_ALTERNATIVE_RESULTS = 'bookingCollection/HAS_MORE_ALTERNATIVE_RESULTS';

export default {
  UPDATE_TIME,
  UPDATE_SEATING_ID,
  ADD_RESOURCE,
  REMOVE_RESOURCE,
  ADD_RESOURCES,
  UPDATE_RESOURCES,
  UPDATE_RESOURCES_LOADING,
  UPDATE_TOTALCOUNT,
  UPDATE_SKIPCOUNT,
  UPDATE_HASMORE,
  CLEAR_ALTERNATIVE_AVAILABILITIES,
  ADD_ALTERNATIVE_AVAILABILITY,
  UPDATE_ALTERNATIVE_AVAILABILITY,
  UPDATE_ALTERNATIVE_SKIPCOUNT,
  UPDATE_ALTERNATIVE_LOADING,
  HAS_MORE_ALTERNATIVE_RESULTS,
  RESET_COLLECTION_STATE
};
