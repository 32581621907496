import debounce from 'debounce-promise';
import actions from './actions';
import selectors from './selectors';
import { changeMasterDataService, peopleService } from '../../services';

import { notificationsSelectors, notificationsOperations } from '../notifications';
import { encodeSearchTerm } from '../../util';

const {
  mapResourceData: reset,
  initializeDetail,
  updateFormValue,
  updateSubmitted,
  updateIsSubmitting,
  updateContactIsLoading,
  updateContactSuggestions
} = actions;

const submit = () => async (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(updateSubmitted());

  if (!selectors.isValid(state)) {
    return false;
  }
  dispatch(updateIsSubmitting(true));

  try {
    const values = selectors.getValues(state);
    const result = await changeMasterDataService.post(values);
    dispatch(updateIsSubmitting(false));
    return result;
  } catch (serverError) {
    const error = getNotification('changeRequests.error', serverError);
    dispatch(notificationsOperations.showError(error));
    dispatch(updateIsSubmitting(false));
    return false;
  }
};

const loadContactSuggestions = term => (dispatch, getState) => {
  const state = getState();

  const debounceFetch = debounce(searchTerm => peopleService.get(searchTerm), 200);
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const searchTerm = encodeSearchTerm(encodeURIComponent(term));

  if (searchTerm && searchTerm.length > 0) {
    dispatch(updateContactIsLoading(true));
    return debounceFetch(searchTerm)
      .then(response => {
        dispatch(updateContactSuggestions(response));
      })
      .catch(serverError => {
        const error = getNotification('peoplepicker.error', serverError);
        dispatch(notificationsOperations.showError(error));
      })
      .then(() => {
        dispatch(updateContactIsLoading(false));
      });
  }
  dispatch(updateContactSuggestions([]));
  return null;
};

export default {
  reset,
  initializeDetail,
  updateFormValue,
  loadContactSuggestions,
  submit
};
