import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  building: PropTypes.shape({
    key: PropTypes.string
  }),
  isFavorite: PropTypes.bool,
  name: PropTypes.string.isRequired,
  roomType: PropTypes.string,
  roomTypeDisplayName: PropTypes.string,
  roomEquipment: PropTypes.arrayOf(PropTypes.shape({})),
  equipmentType: PropTypes.string,
  capacity: PropTypes.number,
  equipment: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string
    })
  ),
  equipmentTypeDisplayName: PropTypes.string,
  availabilities: PropTypes.arrayOf(
    PropTypes.shape({
      end: PropTypes.string.isRequired,
      start: PropTypes.string.isRequired
    })
  ),
  resourceType: PropTypes.oneOf(['Equipment', 'Room']),
  managedBy: PropTypes.shape()
});
