import React from 'react';
import PropTypes from 'prop-types';

import { Input } from '../../common';

function RecurrenceNumberInput({ value, onChange, onFocus, max, className }) {
  const isNumber = input => /^[0-9]+$/.test(input);
  const parse = input => parseInt(input, 10);
  const isLowerThanMax = input => {
    if (max == null || !isNumber(input)) {
      return false;
    }
    const number = parse(input);
    return number < max;
  };

  const onChangeHandler = event => {
    const input = event.target.value;

    if (input === '') {
      onChange(input);
      return;
    }

    if (isNumber(input)) {
      const number = parse(input);
      if (number > 0) {
        onChange(input);
      }
    }
  };

  const onBlurHandler = event => {
    const input = event.target.value;
    const isEmpty = input == null || input === '';

    if (isEmpty || !isNumber(input)) {
      onChange(1);
      return;
    }

    const number = parse(input);
    if (number < 1) {
      onChange(1);
      return;
    }

    if (max != null && !isLowerThanMax(number)) {
      onChange(max);
    }
  };

  return (
    <Input
      type="number"
      value={value}
      onChange={onChangeHandler}
      onFocus={onFocus}
      onBlur={onBlurHandler}
      className={className}
    />
  );
}

RecurrenceNumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  max: PropTypes.number,
  className: PropTypes.string
};

export default RecurrenceNumberInput;
