import { createSelector } from 'reselect';

import { editResourceSelectors } from '../editResource';

const getResource = state => editResourceSelectors.getResource(state);
const getReason = state => state.deleteResourceState.reason;
const isSubmitted = state => state.deleteResourceState.submitted;
const isSubmitting = state => state.deleteResourceState.isSubmitting;

const getValues = createSelector(
  [getResource, getReason],
  (resource, reason) => ({
    resourceId: resource.id,
    reason
  })
);

const isValid = createSelector(
  getReason,
  reason => reason !== ''
);
const hasChanges = createSelector(
  getReason,
  reason => reason !== ''
);

const getResourceType = createSelector(
  getResource,
  resource => resource.resourceType.toLowerCase()
);

export default {
  getReason,
  getValues,
  getResourceType,
  hasChanges,
  isValid,
  isSubmitted,
  isSubmitting
};
