/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SearchForm from './SearchForm';
import SearchFormDate from './SearchFormDate';
import SearchFormLocation from './SearchFormLocation';
import SearchFormFilter from './SearchFormFilter';
import SearchFormRecurrence from './SearchFormRecurrence';
import SearchResults from '../SearchResults';
import AlternativeSearchResults from '../AlternativeSearchResults';
import { locationsOperations, locationsSelectors } from '../../../state/locations';
import { availabilityOperations } from '../../../state/availability';

import styles from './SearchMobile.module.css';

function BookResultsMobile() {
  return (
    <div className={styles.bookResultsMobile}>
      {/*
    <div className="BookResultsMobile__Reset">
      <button className="link" tabIndex={0} onClick={resetFilters} type="button">
        {translate('common.resetFilters')}
      </button>
    </div>
    */}
      <SearchResults className={styles.searchResults} />
      <AlternativeSearchResults />
    </div>
  );
}

function SearchMobile({
  match,
  searchLocations,
  updateLocations,
  loadAvailabilities
}) {
  const when = `${match.url}/when`;
  const where = `${match.url}/where`;
  const what = `${match.url}/what`;
  const recurrence = `${match.url}/recurrence`;

  const urlParams = new URLSearchParams(window.location.search);
  const query = urlParams.get('query');

  const [locations, setLocations] = useState(null);

  useEffect(() => {
    if (query) {
      searchLocations(query, result => {
        if (result) {
          updateLocations([result[0]], () => loadAvailabilities());
          setLocations(result);
        }
      });
    }
  }, [locations]);

  return (
    <>
      <Route exact path={match.url} component={SearchForm} />
      <Route exact path={match.url} component={BookResultsMobile} />
      <Route path={when} component={SearchFormDate} />
      <Route path={what} component={SearchFormFilter} />
      <Route path={where} component={SearchFormLocation} />
      <Route path={recurrence} component={SearchFormRecurrence} />
    </>
  );
}

const mapStateToProps = state => ({
  selectedLocations: locationsSelectors.getSelectedLocations(state)
});

const mapDispatchToProps = dispatch => ({
  searchLocations: (input, callback) => dispatch(locationsOperations.searchLocations(input, callback)),
  updateLocations: (locations, callback) => dispatch(locationsOperations.updateLocations(locations, callback)),
  loadAvailabilities: () => dispatch(availabilityOperations.loadAvailabilities())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchMobile));

SearchMobile.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    isExact: PropTypes.bool.isRequired
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }),
  searchLocations: PropTypes.func,
  updateLocations: PropTypes.func,
  loadAvailabilities: PropTypes.func
};
