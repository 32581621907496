import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon } from '../../common';

import './_style.css';

function BookDetailNotes({ className, notes }) {
  return (
    <div className={cx('BookDetailNotes', className)}>
      <Icon icon="046-information-circle" className="BookDetailNotes__Icon" />
      {notes}
    </div>
  );
}

BookDetailNotes.propTypes = {
  className: PropTypes.string,
  notes: PropTypes.string.isRequired
};

export default BookDetailNotes;
