import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '../../Icon/Icon';

function AutoCompleteDropdownItem({ item, value, onChange }) {
  const replaceParenthesis = string => string.replace('(', '\\(').replace(')', '\\)');
  const regex = new RegExp(`(${replaceParenthesis(value)})`, 'gi');
  const userInput = value.toLowerCase();

  const renderPart = (part, index) => {
    const key = `${index}-${part}`;
    return part.toLowerCase() === userInput ? (
      <strong key={key}>{part}</strong>
    ) : (
      <span key={key}>{part}</span>
    );
  };

  const renderHighlighted = label => {
    if (value.length < 1) return label;
    const parts = label.split(regex);
    return <span>{parts.map((part, index) => renderPart(part, index))}</span>;
  };

  const renderItem = currentItem => {
    if (currentItem.selected) {
      return currentItem.name;
    }
    if (currentItem.value) {
      return renderHighlighted(`${currentItem.name}, ${currentItem.value}`);
    }
    if (currentItem.resourceType) {
      let iconSelected;
      if (currentItem.resourceType === 'Room') {
        iconSelected = '223-map-pointer';
      }
      if (currentItem.resourceType === 'Equipment') {
        iconSelected = '222-blueprint';
      }

      const parts = currentItem.name.split(regex);
      const formattedParts = parts.map((part, index) => renderPart(part, index));

      return (
        <span>
          <Icon icon={iconSelected} className="Room__Icon" />
          {formattedParts}
        </span>
      );
    }
    return '';
  };

  const classes = cx({ 'js-hover': item.focused });
  const autocompleteClasses = cx('autocomplete__result', {
    'autocomplete__result--selected': item.selected
  });

  return (
    <li className={classes}>
      <div
        className={autocompleteClasses}
        tabIndex="0"
        role="button"
        onClick={() => onChange(item)}
      >
        {renderItem(item)}
      </div>
    </li>
  );
}

AutoCompleteDropdownItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    resourceType: PropTypes.string,
    focused: PropTypes.bool,
    selected: PropTypes.bool
  }),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default AutoCompleteDropdownItem;
