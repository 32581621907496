import types from '../types';

const updateResourceAvailabilitiesLoading = loaded => ({
  type: types.UPDATE_RESOURCE_AVAILABILITIES_LOADING,
  loaded
});

const updateResourceAvailabilities = availabilities => ({
  type: types.UPDATE_RESOURCE_AVAILABILITIES,
  availabilities
});

export default {
  updateResourceAvailabilitiesLoading,
  updateResourceAvailabilities
};
