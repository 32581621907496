import { createSelector } from 'reselect';

import resourcesSelectors from './resourcesSelectors';
import alternativesSelectors from './alternativesSelectors';

const { getResources, areResourcesLoading } = resourcesSelectors;
const { getAlternativeResources, areAlternativesLoading } = alternativesSelectors;

const showEmptyState = createSelector(
  [getResources, getAlternativeResources, areResourcesLoading, areAlternativesLoading],
  (resources, alternativeResources, resourcesAreLoading, alternativesAreLoading) => {
    const isLoading = resourcesAreLoading || alternativesAreLoading;
    const resultCount = resources.length + alternativeResources.length;
    return resultCount < 1 && !isLoading;
  }
);

export default {
  showEmptyState
};
