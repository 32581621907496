import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { bookingCollectionOperations } from '../../../state/bookingCollection';
import { Button, CustomPropTypes, withTranslation, ResourceImages } from '../../common';
import { BookDetailSidebar } from '../../BookModal/BookDetail';
import SidebarSeatingPicker from '../../BookModal/BookDetail/BookDetailSidebar/SidebarSeatingPicker';

import './_style.css';

function BookingCollectionSidebar(props) {
  const { resource, onSelect, onRemove, date, showSeatings, translate } = props;

  const classes = cx(
    'BookingCollectionSidebar',
    'BookingCollectionSidebar__Shadow',
    'BookingCollectionSidebar__Shadow--left'
  );

  const select = translate('search.recurrence.exceptions.select');
  const remove = translate('common.delete');
  const buttonProps = {
    full: true,
    label: onSelect ? select : remove,
    onClick: onSelect || onRemove,
    loading: false
  };

  return (
    <div className={classes}>
      <Scrollbars style={{ height: '100%' }} autoHide>
        <ResourceImages resource={resource} />
        <div className="BookingCollectionSidebar__Date">
          <div className="BookingCollectionSidebar__DateLabel">{translate('common.date')}</div>
          <div className="BookingCollectionSidebar__DateValue">{date}</div>
        </div>
        <div className="BookingCollectionSidebar__Detail">
          <BookDetailSidebar resource={resource} />
          {showSeatings && (
            <SidebarSeatingPicker
              resource={resource}
              updateSeatingId={bookingCollectionOperations.updateSeatingId}
            />
          )}
          <div className="BookingCollectionSidebar__Book">
            {onSelect ? <Button {...buttonProps} /> : <Button secondary {...buttonProps} />}
          </div>
        </div>
      </Scrollbars>
    </div>
  );
}

BookingCollectionSidebar.propTypes = {
  date: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func,
  resource: CustomPropTypes.resource,
  showSeatings: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

BookingCollectionSidebar.defaultProps = {
  showSeatings: true
};

export default withTranslation(BookingCollectionSidebar);
