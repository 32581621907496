/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { styles, lineStyles } from './styles';

function WhiteboardIcon({ disabled, ...otherProps }) {
  return (
    <svg viewBox="-2 0 30 30" xmlns="http://www.w3.org/2000/svg" {...otherProps} style={styles}>
      <path d="M21.6,6.17H3a3,3,0,0,0-3,3v14a1.08,1.08,0,0,0,1,1H11.1a1.48,1.48,0,0,0,1.35,1.6H22a1.55,1.55,0,0,0,1.4-1.6h.2a1.08,1.08,0,0,0,1-1v-14A3,3,0,0,0,21.6,6.17ZM2,22.17v-13a.94.94,0,0,1,.88-1H21.6a1,1,0,0,1,1,1v13Zm14.2,2.5H13.1a.5.5,0,1,1,0-1h3.1a.5.5,0,0,1,0,1Zm5.1,0H18.2a.5.5,0,0,1,0-1h3.1a.5.5,0,0,1,0,1Z" />
      {disabled && <line style={lineStyles} x1="4.45" y1="1.5" x2="21.75" y2="29.01" />}
    </svg>
  );
}

WhiteboardIcon.propTypes = {
  disabled: PropTypes.bool
};

export default WhiteboardIcon;
