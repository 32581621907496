import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { LocationPicker, withTranslation, Icon } from '../../../../common';
import { locationsSelectors, locationsOperations } from '../../../../../state/locations';

import './_style.css';

function SearchLocation({ translate, ...props }) {
  const { selectedLocations, updateLocations, searchLocations } = props;
  const locationPickerProps = {
    autoFocus: true,
    locations: selectedLocations,
    updateLocations,
    loadLocations: searchLocations,
    placeholder: translate('search.where.placeholder')
  };

  return (
    <div className="SearchLocation">
      <Icon icon="223-map-pointer" className="SearchLocation__Icon" />
      <div style={{ width: 452 }}>
        <LocationPicker {...locationPickerProps} />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  selectedLocations: locationsSelectors.getSelectedLocations(state)
});

const mapDispatchToProps = dispatch => ({
  searchLocations: input => dispatch(locationsOperations.searchLocations(input)),
  updateLocations: locations => dispatch(locationsOperations.updateLocations(locations))
});

SearchLocation.propTypes = {
  selectedLocations: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string
    })
  ),
  searchLocations: PropTypes.func.isRequired,
  updateLocations: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const SearchLocationComponent = withTranslation(SearchLocation);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchLocationComponent);
