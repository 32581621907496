import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation, TextArea } from '../../common';

import './_style.css';

function FormFieldReason({ value, update, isSubmitted, translate }) {
  const updateField = event => update('reason', event.target.value);

  const label = translate('changeRequests.fieldNames.reason');
  const invalid = isSubmitted && (!value || value === '');

  return (
    <div className="ChangeRequestFormField__Reason">
      <TextArea
        label={label}
        onChange={updateField}
        value={value}
        maxRows={10}
        fixed
        error={invalid}
      />
    </div>
  );
}

FormFieldReason.propTypes = {
  value: PropTypes.string,
  update: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(FormFieldReason);
