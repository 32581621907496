import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from '../../../Icon/Icon';

class AutoCompleteTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  render() {
    const {
      id,
      placeholder,
      items,
      onKeyDown,
      onChange,
      onFocus,
      input,
      ...otherProps
    } = this.props;

    const { selected } = this.state;

    const focus = () => {
      this.setState({
        selected: true
      });
      onFocus();
    };

    const blur = () => {
      this.setState({
        selected: false
      });
    };

    const inputClasses = cx('InputTags', {
      'InputTags--focused': selected
    });

    const tags = items.map(item => {
      const removeTag = () => {
        onChange(item);
      };

      return (
        <span className="InputTag" key={item.value}>
          <span>
            {item.name}
            ,
            &nbsp;
            {item.value}
          </span>
          <button aria-label="Remove" className="InputTag__Close" onClick={removeTag} type="button">
            <Icon icon="022-close" />
          </button>
        </span>
      );
    });

    return (
      <div className={inputClasses}>
        {tags}
        <input
          type="text"
          id={id}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onChange={() => onChange()}
          onFocus={focus}
          onBlur={blur}
          autoComplete="off"
          ref={input}
          {...otherProps}
        />
      </div>
    );
  }
}
AutoCompleteTags.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape()),
  onFocus: PropTypes.func,
  classes: PropTypes.string,
  input: PropTypes.func
};

export default AutoCompleteTags;
