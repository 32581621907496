import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

class Portal extends Component {
  constructor(props) {
    super(props);
    const { id } = props;

    this.rootSelector = document.getElementById(id);
    this.container = document.createElement('div');
  }

  componentDidMount() {
    this.rootSelector.appendChild(this.container);
  }

  componentWillUnmount() {
    this.rootSelector.removeChild(this.container);
  }

  render() {
    const { children, ...props } = this.props;
    return ReactDOM.createPortal(<div {...props}>{children}</div>, this.container);
  }
}

Portal.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Portal;
