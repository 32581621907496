import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withTranslation, Icon } from '../../common';

import { recurrenceMasterOperations } from '../../../state/recurrenceMaster';
import { bookSelectors } from '../../../state/book';
import { authSelectors } from '../../../state/auth';

function BookDetailRecurring({
  loadAndOpenRecurrence,
  calendarId,
  user,
  bookFor,
  isMyBooking,
  className,
  translate
}) {
  const recurringInformation = translate('search.detail.recurring.text');
  const openRecurrenceText = translate('search.detail.recurring.openRecurrence');

  const hasEmail = user.profile.email != null;
  let email = '';
  if (hasEmail) {
      if (typeof user.profile.email === 'string') {
          email = user.profile.email;
      }
  }

  if (Array.isArray(user.profile.email)) {
    const uniqueEmails = new Set(user.profile.email.map(e => e.toLowerCase()));

    if (uniqueEmails.size === 1) {
      email = [...uniqueEmails];
    } else {
      email = user.profile.email[0].toLowerCase();
    }
  }

  const isBookedForMe = bookFor && bookFor.email.toLowerCase() === email && !isMyBooking;

  const openRecurrence = () => {
    loadAndOpenRecurrence(calendarId);
  };

  const classes = cx('BookDetailRecurring', className);

  return (
    <div className={classes}>
      <Icon icon="046-information-circle" className="BookDetailRecurring__Icon" />
      <div>
        {recurringInformation}
        <br />
        {!isBookedForMe && (
          <button className="link" onClick={openRecurrence} role="link" type="button">
            {openRecurrenceText}
          </button>
        )}
      </div>
    </div>
  );
}

BookDetailRecurring.propTypes = {
  loadAndOpenRecurrence: PropTypes.func.isRequired,
  className: PropTypes.string,
  calendarId: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  bookFor: PropTypes.shape({
    email: PropTypes.string
  }),
  user: PropTypes.shape({
    profile: PropTypes.shape({
      email: PropTypes.string.isRequired
    })
  }),
  isMyBooking: PropTypes.bool
};

const mapStateToProps = state => ({
  user: authSelectors.getUser(state),
  isMyBooking: bookSelectors.isMyBooking(state),
  bookFor: bookSelectors.getBookForPerson(state)
});

const mapDispatchToProps = dispatch => ({
  loadAndOpenRecurrence: calendarId => dispatch(recurrenceMasterOperations.loadAndOpenRecurrenceMaster(calendarId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(BookDetailRecurring));
