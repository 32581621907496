import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon, Radio, ChangeDetector, withTranslation } from '../../../common';
import {
  FormFieldPerson,
  FormFieldPeople,
  FormFieldReason,
  FormFieldReadOnly
} from '../../FormElements';

import './_style.css';

class OwnerForm extends Component {
  renderLabel = profile => {
    const { translate } = this.props;
    return translate(`changeRequests.profile.title.${profile}`);
  };

  renderDescription = profile => {
    const { translate } = this.props;
    const text = translate(`changeRequests.profile.description.${profile}`);
    return (
      <div className="OwnerFormProfile__Description">
        <Icon icon="046-information-circle" className="OwnerFormProfileDescription__Icon" />
        {text}
      </div>
    );
  };

  renderContact = (profileType, profile) => {
    const { contact, active } = profile;
    const {
      contactSuggestions,
      loadContactSuggestions,
      isContactLoading,
      updateContact,
      isSubmitted
    } = this.props;

    const updateValue = (field, person) => {
      updateContact(profileType, person);
    };

    return (
      <FormFieldPerson
        field="contact"
        values={contact}
        suggestions={contactSuggestions}
        loadSuggestions={loadContactSuggestions}
        update={updateValue}
        isSubmitted={isSubmitted && active}
        isLoading={isContactLoading}
      />
    );
  };

  renderOwners = (profileType, profile) => {
    const { owners, active } = profile;
    const {
      isOwnerLoading,
      ownerSuggestions,
      loadOwnerSuggestions,
      updateOwners,
      isSubmitted
    } = this.props;

    const updateValue = (field, persons) => {
      updateOwners(profileType, persons);
    };

    const props = {
      field: 'owners',
      values: owners,
      loading: isOwnerLoading,
      suggestions: ownerSuggestions,
      loadSuggestions: loadOwnerSuggestions,
      update: updateValue,
      isSubmitted: isSubmitted && active
    };

    return <FormFieldPeople {...props} />;
  };

  renderProfile1 = ({ active }) => {
    const { profile: activeProfile, selectProfile } = this.props;
    const label = this.renderLabel('profile1');

    const { profile } = activeProfile;
    const detectChanges = profile.new === 1;
    return (
      <div>
        <div className="OwnerFormProfile__ProfileType">
          <ChangeDetector values={profile} detectChanges={detectChanges}>
            <Radio label={label} checked={active} value="1" onChange={() => selectProfile(1)} />
          </ChangeDetector>
          <div className="OwnerFormProfile__Content">{this.renderDescription('profile1')}</div>
        </div>
        {' '}
      </div>
    );
  };

  renderProfile2 = profile => {
    const { profile: activeProfile, selectProfile } = this.props;
    const { active } = profile;
    const label = this.renderLabel('profile2');

    const detectChanges = activeProfile.profile.new === 2;
    return (
      <div className="OwnerFormProfile">
        <div className="OwnerFormProfile__ProfileType">
          <ChangeDetector values={activeProfile.profile} detectChanges={detectChanges}>
            <Radio label={label} checked={active} value="2" onChange={() => selectProfile(2)} />
          </ChangeDetector>
        </div>

        <div className="OwnerFormProfile__Content">
          {this.renderDescription('profile2')}
          {active && this.renderContact(2, profile)}
          {active && this.renderOwners(2, profile)}
        </div>
      </div>
    );
  };

  renderProfile3 = profile => {
    const { profile: activeProfile, selectProfile } = this.props;
    const { active } = profile;
    const label = this.renderLabel('profile3');

    const detectChanges = activeProfile.profile.new === 3;
    return (
      <div className="OwnerFormProfile">
        <div className="OwnerFormProfile__ProfileType">
          <ChangeDetector values={activeProfile.profile} detectChanges={detectChanges}>
            <Radio label={label} checked={active} value="3" onChange={() => selectProfile(3)} />
          </ChangeDetector>
        </div>

        <div className="OwnerFormProfile__Content">
          {this.renderDescription('profile3')}
          {active && this.renderContact(3, profile)}
          {active && this.renderOwners(3, profile)}
        </div>
      </div>
    );
  };

  renderProfile4 = profile => {
    const { profile: activeProfile, selectProfile } = this.props;
    const { active } = profile;
    const label = this.renderLabel('profile4');

    const detectChanges = activeProfile.profile.new === 4;
    return (
      <div className="OwnerFormProfile">
        <div className="OwnerFormProfile__ProfileType">
          <ChangeDetector values={activeProfile.profile} detectChanges={detectChanges}>
            <Radio label={label} checked={active} value="4" onChange={() => selectProfile(4)} />
          </ChangeDetector>
        </div>

        <div className="OwnerFormProfile__Content">
          {this.renderDescription('profile4')}
          {active && this.renderOwners(4, profile)}
        </div>
      </div>
    );
  };

  renderReason = () => {
    const { reason, isSubmitted, updateReason } = this.props;
    const update = (field, value) => {
      updateReason(value);
    };

    return (
      <div className="OwnerFormReason">
        <FormFieldReason value={reason} update={update} isSubmitted={isSubmitted} />
      </div>
    );
  };

  renderValidation = () => {
    const { isSubmitted, isValid, translate } = this.props;
    if (isSubmitted) {
      if (!isValid) {
        const validationError = translate('common.validationError');
        return <div className="OwnerForm__ValidationError">{validationError}</div>;
      }
    }

    return null;
  };

  renderReadOnly = () => {
    const { profile, reason } = this.props;
    if (profile === null) {
      return null;
    }

    const { contact, owners, profile: type } = profile;
    if (type.new === 1) {
      return (
        <>
          <FormFieldReadOnly values={type} field="profile" text={type.new} />
          <FormFieldReadOnly values={{ old: reason, new: reason }} field="reason" text={reason} />
        </>
      );
    }

    const ownersList = (
      <ul>
        {owners.new.map(owner => (
          <li key={owner.id}>{owner.name}</li>
        ))}
      </ul>
    );
    return (
      <>
        <FormFieldReadOnly values={type} field="profile" text={type.new} />
        <FormFieldReadOnly
          values={contact}
          field="contact"
          text={contact.new && contact.new.name}
        />
        <div className="OwnerForm__Owners--readonly">
          <FormFieldReadOnly values={owners} field="owners" text={ownersList} />
        </div>
        <FormFieldReadOnly values={{ old: reason, new: reason }} field="reason" text={reason} />
      </>
    );
  };

  render() {
    const { profile, profiles, readOnly } = this.props;

    if (!profile) {
      return null;
    }

    if (readOnly) {
      return this.renderReadOnly();
    }
    return (
      <>
        {this.renderProfile1(profiles[1])}
        {this.renderProfile2(profiles[2])}
        {this.renderProfile3(profiles[3])}
        {this.renderProfile4(profiles[4])}
        {this.renderReason()}
        {this.renderValidation()}
      </>
    );
  }
}

OwnerForm.propTypes = {
  readOnly: PropTypes.bool,
  profile: PropTypes.shape({
    new: PropTypes.any,
    active: PropTypes.any,
    contact: PropTypes.shape({
      new: PropTypes.shape({
        map: PropTypes.any,
        name: PropTypes.any
      })
    }),
    owners: PropTypes.any,
    profile: PropTypes.any
  }),
  profiles: PropTypes.shape({}),
  reason: PropTypes.string,
  isSubmitted: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
  hasChanges: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  selectProfile: PropTypes.func.isRequired,
  contactSuggestions: PropTypes.arrayOf(PropTypes.shape()),
  isContactLoading: PropTypes.bool,
  updateContact: PropTypes.func.isRequired,
  loadContactSuggestions: PropTypes.func.isRequired,
  isOwnerLoading: PropTypes.bool.isRequired,
  updateOwners: PropTypes.func.isRequired,
  ownerSuggestions: PropTypes.arrayOf(PropTypes.shape()),
  loadOwnerSuggestions: PropTypes.func.isRequired,
  updateReason: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

OwnerForm.defaultProps = {
  readOnly: false
};

export default withTranslation(OwnerForm);
