import actions from './actions';
import selectors from './selectors';
import { recurrenceOperations } from '../recurrence';
import { isTimeRangeValid } from '../../util/time';
import { isDateTimeInPast } from '../../util/dateTime';
import { getCurrentDate } from '../../util';
import { roomFilterOperations } from '../roomFilter';
import { equipmentFilterOperations } from '../equipmentFilter';
import { locationsOperations } from '../locations';
import { availabilityOperations } from '../availability';

const switchResourceType = resourceType => dispatch => {
  dispatch(actions.switchResourceType(resourceType));
  // ToDo: Reload results
};

const { resetChanged, updateSearchedExactTime } = actions;

const isValidDateTimeInFuture = (date, time) => {
  const { from, to } = time;
  const isRangeValid = isTimeRangeValid(from, to);
  const isInPast = isDateTimeInPast(date, time);
  return isRangeValid && !isInPast;
};

const resetRecurrence = (date, time, dispatch) => {
  const isValid = isValidDateTimeInFuture(date, time);
  if (!isValid) {
    dispatch(recurrenceOperations.disableRecurrence());
  }
};

const updateDate = date => (dispatch, getState) => {
  const state = getState();
  const time = selectors.getTime(state);
  dispatch(actions.updateDate(date));
  resetRecurrence(date, time, dispatch);
};

const setToToday = () => (dispatch, getState) => {
  const date = getCurrentDate();
  updateDate(date)(dispatch, getState);
};

const updateTime = time => (dispatch, getState) => {
  const state = getState();
  const date = selectors.getDate(state);
  dispatch(actions.updateTime(time));
  resetRecurrence(date, time, dispatch);
};

const removeTag = tag => dispatch => {
  if (tag.resourceType === 'room') {
    dispatch(roomFilterOperations.toggleFilter(tag));
  } else {
    dispatch(equipmentFilterOperations.toggleFilter(tag));
  }
};

const resetFilters = () => dispatch => {
  dispatch(locationsOperations.clearLocations());
  dispatch(locationsOperations.setToDefault());
  dispatch(recurrenceOperations.clear());
  dispatch(roomFilterOperations.resetFilter('book'));
  dispatch(equipmentFilterOperations.resetFilter('book'));
  dispatch(actions.resetFilters());
  dispatch(availabilityOperations.loadAvailabilities(true));
};

export default {
  resetChanged,
  switchResourceType,
  setToToday,
  updateDate,
  updateTime,
  updateSearchedExactTime,
  removeTag,
  resetFilters
};
