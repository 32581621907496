import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import identifier from '../identifier';
import Icon from '../Icon/Icon';
import Tooltip from '../Tooltip/Tooltip';
import Translate from '../I18n/Translate';

import './_style.css';

class Star extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipId: identifier('star')
    };
  }

  render() {
    const { active, onClick, medium, small, tooltip, className } = this.props;
    const { tooltipId } = this.state;

    const icon = active ? '228-star-filled' : '082-star';

    const containerProps = {
      'data-tip': true,
      'data-for': tooltipId
    };

    const iconProps = {
      icon,
      onClick,
      className: cx(
        'Star',
        {
          'Star--medium': medium,
          'Star--small': small,
          'Star--active': active
        },
        className
      )
    };

    return (
      <div {...containerProps}>
        {tooltip && (
          <Tooltip id={tooltipId} small place="bottom">
            <p>
              <Translate path={`favorite.${active ? 'removeFavorite' : 'addFavorite'}`} />
            </p>
          </Tooltip>
        )}
        <Icon {...iconProps} data-ignore="true" />
      </div>
    );
  }
}

Star.propTypes = {
  small: PropTypes.bool,
  medium: PropTypes.bool,
  active: PropTypes.bool,
  tooltip: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default Star;
