import { createSelector } from 'reselect';

const getBookForPerson = state => state.bookState.book.bookForPerson;
const isBookForLoading = state => state.bookState.book.bookForIsLoading;
const getSuggestions = state => state.bookState.book.bookForSuggestions;

const getBookForSuggestions = createSelector(
  getSuggestions,
  suggestions => suggestions.map(person => ({
    name: person.displayName,
    value: person.accountName,
    id: person.accountName,
    email: person.email
  }))
);

export default {
  getBookForPerson,
  getBookForSuggestions,
  isBookForLoading
};
