import React from 'react';
import PropTypes from 'prop-types';
import './_style.css';
import { Icon } from '..';

function WarningBox(props) {
  const { text } = props;
  return (
    <div className="WarningBox">
      <div className="Icon">
        <Icon icon="240-robot-sad" />
      </div>
      <div className="Message">{ text }</div>
    </div>
  );
}

WarningBox.propTypes = {
  text: PropTypes.string.isRequired
};

export default WarningBox;
