import types from './types';

const updateMessage = message => ({
  type: types.UPDATE_MAINTENANCE_MESSAGE,
  message
});

const hideMessages = () => ({
  type: types.HIDE_MESSAGES
});

const reset = () => ({
  type: types.RESET
});

const updatePayload = payload => ({
  type: types.UPDATE_PAYLOAD,
  payload
});

const updateLoading = isLoading => ({
  type: types.UPDATE_IS_LOADING,
  isLoading
});

const updatePersisted = isPersisted => ({
  type: types.UPDATE_IS_PERSISTED,
  isPersisted
});

const updateSubmitting = isSubmitting => ({
  type: types.UPDATE_IS_SUBMITTING,
  isSubmitting
});

const updateSubmitted = () => ({
  type: types.UPDATE_IS_SUBMITTED
});

export default {
  updateMessage,
  hideMessages,
  reset,
  updatePayload,
  updateLoading,
  updatePersisted,
  updateSubmitted,
  updateSubmitting
};
