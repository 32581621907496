import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Dialog, Button, withTranslation, Spacing } from '../../../common';
import { modalOperations } from '../../../../state/modal';

import Header from '../../../Header';

import keys from './modalKeys';
import { resourcesSubTypesOperations, resourcesSubTypesSelectors } from '../../../../state/resourcesSubTypes';
import { getResourceSubTypeNameTranslation } from '../../../../util';

class ResourcesSubTypesDeleteDialog extends Component {
  confirmedDeleteResourcesSubType = () => {
    const { clearModals, deleteResourcesSubType } = this.props;
    deleteResourcesSubType();
    clearModals();
  };

  renderDialogContent = () => {
    const { translate, popDialog, selectedResourcesSubType } = this.props;

    const title = `${ translate('admin.settings.resourcesSubTypes.delete.title') }`;
    const text = `${ translate('admin.settings.resourcesSubTypes.delete.confirm') }:
          ${ getResourceSubTypeNameTranslation(selectedResourcesSubType) } ?`;
    const yes = translate('common.yes');
    const no = translate('common.no');

    return (
      <div>
        <Header title={title} onClose={popDialog} icon="022-close" iconRight fixed={false} />
        <div className="ResourcesSubTypeFormField">
          <Spacing horizontal={1} vertical={1}>
            <div>
              {text}
            </div>
          </Spacing>
          <div className="Delete_Buttons_Container">
            <div>
              <Button
                className="ResourcesSubTypesDelete__Button"
                label={no}
                onClick={popDialog}
                secondary
              />
              <Button
                className="ResourcesSubTypesDelete__Button"
                label={yes}
                onClick={this.confirmedDeleteResourcesSubType}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { popDialog } = this.props;

    return (
      <Dialog dialogKey={keys.RESOURCESSUBTYPE_DELETE_DIALOG} onOverlayClick={popDialog}>
        {this.renderDialogContent()}
      </Dialog>
    );
  }
}

ResourcesSubTypesDeleteDialog.propTypes = {
  popDialog: PropTypes.func.isRequired,
  clearModals: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  deleteResourcesSubType: PropTypes.func.isRequired,
  selectedResourcesSubType: PropTypes.shape({
    key: PropTypes.any,
    name: PropTypes.any
  })
};

const mapStateToProps = state => ({
  selectedResourcesSubType: resourcesSubTypesSelectors.getSelectedResourcesSubType(state)
});

const mapDispatchToProps = dispatch => ({
  popDialog: () => dispatch(modalOperations.popDialog()),
  clearModals: () => dispatch(modalOperations.clearModals()),
  deleteResourcesSubType: () => dispatch(resourcesSubTypesOperations.deleteResourcesSubType())
});

const ResourcesSubTypesDeleteDialogComponent = withTranslation(ResourcesSubTypesDeleteDialog);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesSubTypesDeleteDialogComponent);
