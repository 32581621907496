import React from 'react';
import PropTypes from 'prop-types';

import RecurrenceDetailContent from './RecurrenceDetailContent';
import { Spacing, ShowAt } from '../../common';

function RecurrenceDetail({ confirmDeletion, ...props }) {
  return (
    <div>
      <ShowAt breakpoint="900AndAbove">
        <Spacing bottom="1">
          <RecurrenceDetailContent {...props} />
        </Spacing>
      </ShowAt>
      <ShowAt breakpoint="900AndBelow">
        <RecurrenceDetailContent {...props} />
      </ShowAt>
    </div>
  );
}

RecurrenceDetail.propTypes = {
  confirmDeletion: PropTypes.func.isRequired
};

export default RecurrenceDetail;
