import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomPropTypes, withTranslation } from '../../../../common';
import Availability from '../Availability';
import './_style.css';

function RoomCapacityAndTime({ resource, alternativeType, showRelativeTime, translate }) {
  const { capacity, availabilities } = resource;

  const showAlternativeTime = alternativeType === 'time';
  const showAlternativeCapacity = alternativeType === 'capacity';

  const persons = `${capacity || '?'} ${translate('common.people')}`;
  const personsClasses = cx({
    'RoomCapacity--warning': showAlternativeCapacity
  });

  return (
    <div>
      <div className={personsClasses}>{persons}</div>
      <div>
        <Availability
          availabilities={availabilities}
          showRelativeTime={showRelativeTime}
          showAlternativeTime={showAlternativeTime}
        />
      </div>
    </div>
  );
}

RoomCapacityAndTime.propTypes = {
  resource: CustomPropTypes.resource,
  alternativeType: PropTypes.string,
  showRelativeTime: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

RoomCapacityAndTime.defaultProps = {
  alternativeType: ''
};

export default withTranslation(RoomCapacityAndTime);
