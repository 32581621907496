import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../common';

import './_style.css';

function BookFormRecurrence({ translate }) {
  return (
    <div className="BookFormRecurrenceUpdate">
      <p className="BookFormRecurrenceUpdate__Title">
        {translate('search.detail.recurring.update.title')}
      </p>
      <p>{translate('search.detail.recurring.update.text')}</p>
    </div>
  );
}

BookFormRecurrence.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookFormRecurrence);
