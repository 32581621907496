/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

function AlarmClockIcon({ className }) {
  const styles = {
    fill: 'currentcolor'
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      className={className}
      styles={styles} // eslint-disable-line react/no-unknown-property
    >
      <path
        d="M14 2C6.83 2 1 7.83 1 15s5.83 13 13 13 13-5.83 13-13S21.17 2 14 2zm0 24C7.93 26 3 21.07 3 15S7.93 4 14 4s11 4.93 11 11-4.93 11-11 11zm1-18v8c0 .27-.11.52-.29.71l-4 4A.996.996 0 1 1 9.3 19.3l3.7-3.71V8c0-.55.45-1 1-1s1 .45 1 1zM5.814 1.964l-3.85 3.85s-.55.55-1.257-.157C0 4.95.55 4.4.55 4.4L4.4.55c.302-.303.868-.232 1.257.157.389.389.454.95.152 1.252l.005.005zM23.464.55l3.85 3.85s.55.55-.157 1.257c-.707.707-1.257.157-1.257.157l-3.85-3.85c-.303-.302-.232-.868.157-1.257.389-.389.95-.454 1.252-.152l.005-.005z"
        fill="#333"
        fillRule="evenodd"
      />
    </svg>
  );
}

AlarmClockIcon.propTypes = {
  className: PropTypes.string
};

export default AlarmClockIcon;
