import { useRef, useCallback } from 'react';
import useEventListener from './useEventListener';

const getWidth = () => Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

const breakpointCrossed = breakpoint => (width, currentWidth) => {
  if (width >= breakpoint && currentWidth < breakpoint) {
    return true;
  }

  return width < breakpoint && currentWidth >= breakpoint;
};

function useBreakpointCrossing(breakpoint, handler) {
  const width = useRef(getWidth());

  const onResize = useCallback(() => {
    const currentWidth = getWidth();
    const hasBreakpointCrossed = breakpointCrossed(breakpoint);
    if (hasBreakpointCrossed(width.current, currentWidth)) {
      handler();
    }

    width.current = currentWidth;
  }, [breakpoint, handler]);

  useEventListener('resize', onResize);
}

export default useBreakpointCrossing;
