import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import moment from 'moment';
// import 'moment/locale/de-ch';

import { TimeInputRange } from '../../../../common';
import { searchSelectors, searchOperations } from '../../../../../state/search';
import { emitter } from '../../../../../util';

import styles from './SearchBarTimePicker.module.css';

class SearchBarTimePicker extends Component {
  onChange = time => {
    const { updateTime } = this.props;
    updateTime(time);
    emitter.emit('onSearchFilterChanged', null);
  };

  render() {
    const { time } = this.props;
    /*
    const startHourPlaceholder = moment().add(1, 'hour').minutes(0).format('HH:mm');
    const endHourPlaceholder = moment().add(2, 'hour').minutes(0).format('HH:mm');
    */
    return (

      <div className={styles.body}>
        <TimeInputRange values={time} onChange={this.onChange} ref={this.timeInputRange} />
      </div>
    );
  }
}

SearchBarTimePicker.propTypes = {
  time: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string
  }),
  updateTime: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  time: searchSelectors.getTime(state)
});

const mapDispatchToProps = dispatch => ({
  updateTime: time => dispatch(searchOperations.updateTime(time))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarTimePicker);
