const SHOW_ERROR = 'notifications/SHOW_ERROR';
const HIDE_ERROR = 'notifications/HIDE_ERROR';

const SHOW_SUCCESS = 'notifications/SHOW_SUCCESS';
const HIDE_SUCCESS = 'notifications/HIDE_SUCCESS';

export default {
  SHOW_ERROR,
  HIDE_ERROR,
  SHOW_SUCCESS,
  HIDE_SUCCESS
};
