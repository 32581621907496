import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import config from '../../../config';

function ResourceImage({ photoId, className, style }) {
  if (!photoId) {
    return null;
  }
  const source = `${config.remoteUrl}/assets/${photoId}`;
  const classes = cx('ResourceImage', className);

  return <img src={source} className={classes} alt="" draggable={false} style={style} />;
}

ResourceImage.propTypes = {
  photoId: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape()
};

export default ResourceImage;
