import includes from 'lodash/fp/includes';

export const isProfile3 = ({ permissionTypes, roomType }) => {
  const hasProfile3Permissions = !includes('Create', permissionTypes) && !includes('Request', permissionTypes);

  if (!roomType) {
    return hasProfile3Permissions;
  }

  const isSpecialRoomType = roomType.toLowerCase() === 'surfacehub' || roomType.toLowerCase() === 'telepresence';

  return hasProfile3Permissions || (roomType && isSpecialRoomType);
};

export const isProfile2 = ({ permissionTypes }) => includes('Request', permissionTypes);

export const getProfile = resource => {
  if (isProfile3(resource)) {
    return 3;
  }
  if (isProfile2(resource)) {
    return 2;
  }

  return 1;
};
