import React from 'react';
import PropTypes from 'prop-types';

function AutoCompleteText({
  id,
  placeholder,
  value,
  onKeyDown,
  onChange,
  onFocus,
  classes,
  input,
  ...otherProps
}) {
  return (
    <input
      type="text"
      id={id}
      placeholder={placeholder}
      value={value}
      onKeyDown={onKeyDown}
      onChange={() => onChange()}
      onFocus={onFocus}
      autoComplete="off"
      className={classes}
      ref={input}
      {...otherProps}
  />
  );
}
AutoCompleteText.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onFocus: PropTypes.func,
  classes: PropTypes.string,
  input: PropTypes.func
};

export default AutoCompleteText;
