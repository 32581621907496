import React from 'react';
import PropTypes from 'prop-types';

import { InlineLoader, withTranslation } from '../../../common';

function AlternativeSearchResultsInlineLoader({ visible, translate }) {
  if (!visible) {
    return null;
  }

  const text = translate('common.loadmore');
  return (
    <InlineLoader
      wrapperClassName="AlternativeSearchResultsInlineLoader"
      className="AlternativeSearchResultsInlineLoader__Loader"
      text={text}
    />
  );
}

AlternativeSearchResultsInlineLoader.propTypes = {
  visible: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

export default withTranslation(AlternativeSearchResultsInlineLoader);
