import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, ShowAt } from '../../common';
import {
  recurrenceMasterSelectors,
  recurrenceMasterOperations
} from '../../../state/recurrenceMaster';
import { modalOperations } from '../../../state/modal';

import RecurrenceUpdateModalDesktop from './RecurrenceUpdateModalDesktop';
import RecurrenceUpdateModalMobile from './RecurrenceUpdateModalMobile';

import keys from '../modalKeys';

import '../_style.css';

function RecurrenceUpdateModal(props) {
  const { clearModals } = props;

  return (
    <div>
      <ShowAt breakpoint="900AndAbove">
        <Modal modalKey={keys.RECURRENCE_UPDATE_MODAL} onOverlayClick={clearModals}>
          <RecurrenceUpdateModalDesktop {...props} />
        </Modal>
      </ShowAt>
      <ShowAt breakpoint="900AndBelow">
        <Modal modalKey={keys.RECURRENCE_UPDATE_MODAL} mobile>
          <RecurrenceUpdateModalMobile {...props} />
        </Modal>
      </ShowAt>
    </div>
  );
}

const mapStateToProps = state => ({
  selectedMaster: recurrenceMasterSelectors.getSelectedMaster(state),
  bookForPerson: recurrenceMasterSelectors.getBookForPerson(state),
  bookForLoading: recurrenceMasterSelectors.isBookForLoading(state),
  bookForSuggestions: recurrenceMasterSelectors.getBookForSuggestions(state),
  attendeesSearchTerm: recurrenceMasterSelectors.getAttendeesSearchTerm(state),
  attendeesSuggestions: recurrenceMasterSelectors.getAttendeesSuggestions(state),
  selectedAttendees: recurrenceMasterSelectors.getSelectedAttendees(state),
  attendeesLoading: recurrenceMasterSelectors.areAttendeesLoading(state),
  isSubjectValid: recurrenceMasterSelectors.isSubjectValid(state),
  isMessageValid: recurrenceMasterSelectors.isMessageValid(state),
  isLoading: recurrenceMasterSelectors.isLoading(state)
});

const mapDispatchToProps = dispatch => ({
  updateSubject: subject => dispatch(recurrenceMasterOperations.updateSubject(subject)),
  updateMessage: message => dispatch(recurrenceMasterOperations.updateMessage(message)),
  updatePerson: person => dispatch(recurrenceMasterOperations.updateBookForPerson(person)),
  updateReminder: reminder => dispatch(recurrenceMasterOperations.updateReminder(reminder)),
  loadSuggestions: term => dispatch(recurrenceMasterOperations.loadBookForSuggestions(term)),
  updateTerm: term => dispatch(recurrenceMasterOperations.setAttendeesSearchTerm(term)),
  updateSelectedPeople: attendees => dispatch(recurrenceMasterOperations.updateSelectedAttendees(attendees)),
  fetchPeople: () => dispatch(recurrenceMasterOperations.fetchAttendees()),
  clearModals: () => dispatch(modalOperations.clearModals()),
  goBackToDetail: () => {
    dispatch(recurrenceMasterOperations.restoreSelectedMaster());
    dispatch(modalOperations.popModal());
  }
});

RecurrenceUpdateModal.propTypes = {
  selectedMaster: PropTypes.shape(),
  updateMaster: PropTypes.func.isRequired,
  clearModals: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecurrenceUpdateModal);
