import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SearchFormSwitch from './SearchFormSwitch';
import SearchFormDataQuality from './SearchFormDataQuality';
import SearchFormInputRedirect from './SearchFormInputRedirect';
import { withTranslation } from '../../../common';
import { searchSelectors } from '../../../../state/search';
import { locationsSelectors } from '../../../../state/locations';
import { roomFilterSelectors } from '../../../../state/roomFilter';
import { equipmentFilterSelectors } from '../../../../state/equipmentFilter';

import getRoomFilterSummary from '../../../RoomFilter/roomFilterSummary';
import getEquipmentFilterSummary from '../../../EquipmentFilter/equipmentFilterSummary';

class SearchForm extends Component {
  renderDataQuality = () => {
    const { resourceType } = this.props;
    return resourceType === 'room' ? null : <SearchFormDataQuality />;
  };

  renderDate = () => {
    const { dateTime, translate, match } = this.props;

    const props = {
      icon: '009-calendar',
      value: dateTime,
      placeholder: translate('search.when.placeholder'),
      url: `${match.url}/when`
    };

    return <SearchFormInputRedirect {...props} />;
  };

  renderLocation = () => {
    const { locations, locationError, translate, match } = this.props;

    const props = {
      icon: '223-map-pointer',
      value: locations,
      placeholder: translate('search.where.placeholder'),
      url: `${match.url}/where`,
      error: locationError
    };

    return <SearchFormInputRedirect {...props} />;
  };

  renderFilter = () => {
    const { resourceType, roomFilter, equipmentFilter, translate, match } = this.props;
    const createSummary = type => (type === 'room'
      ? getRoomFilterSummary(roomFilter, translate)
      : getEquipmentFilterSummary(equipmentFilter, translate));

    const props = {
      icon: '053-more',
      value: resourceType ? createSummary(resourceType) : '',
      placeholder: translate(`roomFilter.placeholder.${resourceType}`),
      url: `${match.url}/what`
    };

    return <SearchFormInputRedirect {...props} />;
  };

  render() {
    return (
      <>
        <SearchFormSwitch />
        {this.renderDataQuality()}
        {this.renderDate()}
        {this.renderLocation()}
        {this.renderFilter()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  dateTime: searchSelectors.getDateTime(state),
  locations: locationsSelectors.getLocationNames(state),
  resourceType: searchSelectors.getResourceType(state),
  roomFilter: roomFilterSelectors.getAllSelectedFilters(state),
  equipmentFilter: equipmentFilterSelectors.getSelectedEquipmentTypeIds(state),
  locationError: !locationsSelectors.hasLocations(state)
});

SearchForm.propTypes = {
  dateTime: PropTypes.string.isRequired,
  locations: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  locationError: PropTypes.bool.isRequired,
  roomFilter: PropTypes.arrayOf(PropTypes.shape()),
  equipmentFilter: PropTypes.arrayOf(PropTypes.string),
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    isExact: PropTypes.bool.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

const SearchFormComponent = withTranslation(withRouter(SearchForm));
export default connect(mapStateToProps)(SearchFormComponent);
