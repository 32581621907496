import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { modalOperations } from '../../../state/modal';
import {
  bookingCollectionSelectors,
  bookingCollectionOperations
} from '../../../state/bookingCollection';
import { Dialog } from '../../common';
import keys from '../../BookModal/modalKeys';

import BookingCollectionDialogContent from './BookingCollectionDialogContent';

function BookingCollectionDialog({ close, createBooking, ignore, ignored }) {
  if (ignored) {
    return null;
  }

  const onClose = () => {
    createBooking();
    close();
  };

  return (
    <div>
      <Dialog dialogKey={keys.BOOKING_COLLECTION_INFORMATION_DIALOG} onOverlayClick={close}>
        <BookingCollectionDialogContent close={onClose} ignore={ignore} />
      </Dialog>
    </div>
  );
}

const mapStateToProps = () => {
  const ignored = !bookingCollectionSelectors.showWarning();
  return {
    ignored
  };
};

const mapDispatchToProps = dispatch => ({
  close: () => dispatch(modalOperations.popDialog()),
  ignore: () => bookingCollectionOperations.ignoreWarning()
});

BookingCollectionDialog.propTypes = {
  close: PropTypes.func.isRequired,
  ignore: PropTypes.func.isRequired,
  ignored: PropTypes.bool.isRequired,
  createBooking: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingCollectionDialog);
