/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { styles, lineStyles } from './styles';

function TabletIcon({ disabled, ...otherProps }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.000000 30.000000" {...otherProps} style={styles}>
      <g transform="translate(-0.500000,25.500000) scale(0.030,-0.030)">
        <path d="M853.3333333333334 693.3333333333334H170.6666666666667C114.3466666666667 693.3333333333334 68.2666666666667 647.2533333333333 68.2666666666667 590.9333333333334V113.0666666666667C68.2666666666667 56.7466666666667 114.3466666666667 10.6666666666666 170.6666666666667 10.6666666666666H853.3333333333334C909.6533333333334 10.6666666666666 955.7333333333333 56.7466666666667 955.7333333333333 113.0666666666667V590.9333333333334C955.7333333333333 647.2533333333333 909.6533333333334 693.3333333333334 853.3333333333334 693.3333333333334zM887.4666666666667 113.0666666666667C887.4666666666667 94.2933333333333 872.1066666666667 78.9333333333334 853.3333333333334 78.9333333333334H170.6666666666667C151.8933333333334 78.9333333333334 136.5333333333333 94.2933333333333 136.5333333333333 113.0666666666667V590.9333333333334C136.5333333333333 609.7066666666667 151.8933333333334 625.0666666666666 170.6666666666667 625.0666666666666H853.3333333333334C872.1066666666667 625.0666666666666 887.4666666666667 609.7066666666667 887.4666666666667 590.9333333333334V113.0666666666667zM204.8 556.8H819.2V147.2000000000001H204.8V556.8z" fill="#333" />
      </g>
      {disabled && <line style={lineStyles} x1="3.45" y1="2.5" x2="24.75" y2="29.01" />}
    </svg>
  );
}

TabletIcon.propTypes = {
    disabled: PropTypes.bool
};

export default TabletIcon;
