import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { authSelectors } from '../../../state/auth';
import withUserManager from './withUserManager';

class LoginCallback extends React.Component {
  UNSAFE_componentWillMount() {
    const { history, userManager } = this.props;
    userManager.signinRedirectCallback()
      .then(() => {
        const { path, queryParams } = authSelectors.getRoute();
        history.replace({
          pathname: path,
          search: queryParams
        });
      })
      .catch(error => {
        if (error.toString().indexOf('No matching state found in storage') !== -1) {
          history.replace('/');
        }
      });
  }

  render() {
    const nope = null;
    return nope;
  }
}

LoginCallback.propTypes = {
  userManager: PropTypes.shape().isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired
};

export default withUserManager(withRouter(LoginCallback));
