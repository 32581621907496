import React from 'react';
import { connect } from 'react-redux';
import Language from './Language';
import { i18nSelectors } from '../../state/i18n';

function LanguagePage(props) {
  return <Language {...props} />;
}

const mapStateToProps = state => ({
  selectedLanguage: i18nSelectors.getLanguage(state),
  availableLanguages: i18nSelectors.getSupportedLanguages(state)
});

export default connect(mapStateToProps)(LanguagePage);
