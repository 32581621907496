import React from 'react';
import { components } from 'react-select';

import Icon from '../Icon/Icon';

function MultiValueRemove(props) {
  return components.DropdownIndicator && (
  <components.MultiValueRemove {...props}>
    <Icon icon="022-close" />
  </components.MultiValueRemove>
);
}

export default MultiValueRemove;
