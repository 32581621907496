import React from 'react';
import PropTypes from 'prop-types';

import { ShowAt, CustomPropTypes, withTranslation } from '../../../common';

import { isProfile3 } from '../../../../util';

import BookingResourceCardMobile from './BookingResourceCardMobile';
import BookingResourceCardTabletAndDesktop from './BookingResourceCardTabletAndDesktop';

import '../_style.css';

function BookingResourceCard({ booking, selected, style, onOpenOccurrence, onOpenMaster }) {
  const { resource } = booking;

  const profile3 = isProfile3(resource);
  const profile2 = resource.profile === 2;

  const props = {
    booking,
    onOpenOccurrence,
    onOpenMaster,
    selected,
    isProfile3: profile3,
    isProfile2: profile2,
    style
  };

  return (
    <div className="BookingEquipmentCard">
      <ShowAt breakpoint="600AndBelow">
        <BookingResourceCardMobile {...props} />
      </ShowAt>
      <ShowAt breakpoint="600AndAbove">
        <BookingResourceCardTabletAndDesktop {...props} />
      </ShowAt>
    </div>
  );
}

BookingResourceCard.propTypes = {
  booking: CustomPropTypes.booking,
  selected: PropTypes.bool.isRequired,
  style: PropTypes.shape(),
  onOpenOccurrence: PropTypes.func.isRequired,
  onOpenMaster: PropTypes.func.isRequired
};

export default withTranslation(BookingResourceCard);
