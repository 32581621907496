import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button, withTranslation, CustomPropTypes } from '../../common';
import RecurrenceExceptionDeleteModalHeader from './RecurrenceExceptionModalHeader/RecurrenceExceptionDeleteModalHeader';
import RecurrenceExceptionDeleteSidebar from './RecurrenceExceptionSidebar/RecurrenceExceptionDeleteSidebar';
import AlternativeSearchResults from './AlternativeSearchResults';

import './_style.css';

function RecurrenceExceptionDeleteModalDesktop(props) {
  const { exception, onClose, onReset, isInProgress, deleteCallback, translate } = props;
  const { resource } = exception;

  const handleReset = () => onReset(exception, deleteCallback);

  const classes = cx(
    'RecurrenceExceptionSidebar',
    'RecurrenceExceptionSidebar__Shadow',
    'RecurrenceExceptionSidebar__Shadow--left'
  );

  const resources = [
    {
      resource: { ...resource, alternativeType: null },
      selected: true
    }
  ];

  const { start } = exception;
  const buttonLabel = translate('common.delete');
  return (
    <div className="RecurrenceExceptionModalDesktop">
      <RecurrenceExceptionDeleteModalHeader date={start} onClose={onClose} />

      <div className="RecurrenceExceptionModalDesktop__Results">
        <div className="RecurrenceExceptionModalDesktop__Panel">
          <AlternativeSearchResults
            className="RecurrenceExceptionsDeletePanel"
            resources={resources}
            onSelect={() => {}}
          />
        </div>
        <div className="RecurrenceExceptionModalDesktop__Panel">
          <div className={classes}>
            <RecurrenceExceptionDeleteSidebar exception={exception} />
            <div className="RecurrenceExceptionResetButton">
              <Button secondary label={buttonLabel} onClick={handleReset} loading={isInProgress} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

RecurrenceExceptionDeleteModalDesktop.propTypes = {
  exception: CustomPropTypes.exception,
  isInProgress: PropTypes.bool.isRequired,
  deleteCallback: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurrenceExceptionDeleteModalDesktop);
