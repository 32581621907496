/* eslint-disable react/forbid-prop-types */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';

import identifier from '../identifier';
import InputField from './InputField';
import TextAreaDimensions from './TextAreaDimensions';

class TextArea extends Component {
  constructor(props) {
    super(props);

    const { id, label, defaultValue, minRows, maxRows } = props;
    this.state = {
      id: id || React.isValidElement(label) ? identifier() : identifier(label),
      hasValue: !isEmpty(defaultValue),
      minRows: Math.min(minRows, maxRows),
      maxRows: Math.max(minRows, maxRows)
    };

    this.textarea = null;
    this.onAttach = this.onAttach.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resizeComponent = this.resizeComponent.bind(this);
  }

  componentDidMount() {
    this.resizeComponent();
    window.addEventListener('resize', this.resizeComponent);
    window.addEventListener('orientationchange', this.resizeComponent);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeComponent);
    window.removeEventListener('orientationchange', this.resizeComponent);
  }

  onAttach(element) {
    if (element) {
      this.textarea = new TextAreaDimensions(element);
    }
  }

  onBlur(event) {
    this.resizeComponent();
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(event);
    }
  }

  onFocus(event) {
    this.resizeComponent();
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(event);
    }
  }

  onChange(event) {
    this.resizeComponent();
    this.setState({
      hasValue: !isEmpty(event.target.value)
    });

    const { onChange } = this.props;
    if (onChange) {
      onChange(event);
    }
  }

  resizeComponent() {
    if (this.textarea) {
      const { minRows, maxRows } = this.state;
      this.textarea.updateDimensions(minRows, maxRows);
    }
  }

  render() {
    const { id, hasValue } = this.state;
    const { label, value, fixed, valid, error, className, ...props } = this.props;

    const classes = classNames(
      {
        'is-fixed': value || hasValue,
        invalid: error,
        valid: valid && !error
      },
      className
    );

    const otherProps = omit(
      ['id', 'minRows', 'maxRows', 'onFocus', 'onBlur', 'onChange', 'style'],
      props
    );

    const style = omit(['height', 'overflow'], props.style);

    const textArea = (
      <textarea
        {...otherProps}
        id={id}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onChange={this.onChange}
        className={classes}
        value={value}
        style={style}
        ref={this.onAttach}
      />
    );

    return (
      <InputField
        id={id}
        label={label}
        input={textArea}
        fixed={fixed}
        valid={valid}
        error={error}
        multiline
      />
    );
  }
}

TextArea.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  fixed: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  valid: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

TextArea.defaultProps = {
  id: '',
  label: '',
  value: undefined,
  defaultValue: undefined,
  fixed: false,
  minRows: 3,
  maxRows: Number.MAX_SAFE_INTEGER,
  style: {},
  className: undefined,
  valid: false,
  error: undefined,
  onFocus: undefined,
  onBlur: undefined,
  onChange: undefined
};

export default TextArea;
