import React from 'react';
import Checkbox from './Checkbox';

function Empty() {
  const nullValue = () => null;
  return nullValue;
}

function EmptyCheckbox(props) {
  return <Checkbox label={<Empty />} {...props} />;
}

export default EmptyCheckbox;
