import actions from './actions';
import selectors from './selectors';
import { notificationsSelectors, notificationsOperations } from '../notifications';

import createPayload from '../availability/payload';
import { alternativesService } from '../../services';

const pageSize = 4;

const loadSectionItems = (dispatch, state) => (section, payload) => {
  dispatch(actions.updateIsLoading(section, true));
  return alternativesService
    .post(payload, section)
    .then(response => {
      const { totalCount, nextSkipCount } = response;
      const hasMore = totalCount > nextSkipCount;
      dispatch(actions.updateHasMoreResults(section, hasMore));
      dispatch(actions.updateSkipCount(section, nextSkipCount));
      dispatch(
        payload.skipCount === 0
          ? actions.updateAvailability(section, response.results)
          : actions.addAvailability(section, response.results)
      );
    })
    .catch(serverError => {
      const getNotification = notificationsSelectors.getNotification(state);
      const error = getNotification('search.load.error', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => {
      dispatch(actions.updateIsLoading(section, false));
    });
};

const loadSection = (dispatch, getState) => section => {
  const state = getState();
  const load = loadSectionItems(dispatch, state);

  dispatch(actions.updateAvailability(section, []));
  dispatch(actions.updateSkipCount(section, 0));

  const payload = createPayload(state);
  payload.skipCount = 0;
  payload.pageSize = pageSize;
  return load(section, payload);
};

const loadAlternatives = (dispatch, getState) => {
  const state = getState();

  if (!selectors.isEnabled(state)) {
    dispatch(actions.clearAllAvailabilities());
    return Promise.resolve(false);
  }

  const load = loadSection(dispatch, getState);
  const abort = () => Promise.resolve(false);
  const sections = ['time', 'floor', 'sector', 'roomequipment', 'roomtype', 'capacity'];
  const promises = sections.map(section => (selectors.showSection(section)(state) ? load(section) : abort));

  return Promise.all(promises);
};

const loadMoreAlternatives = section => (dispatch, getState) => {
  const state = getState();
  const load = loadSectionItems(dispatch, state);

  const hasMore = selectors.getHasMore(section)(state);
  if (!hasMore) {
    return Promise.reject(new Error('no more results available'));
  }

  const nextSkipCount = selectors.getSkipCount(section)(state);

  const payload = createPayload(state);
  payload.skipCount = nextSkipCount;
  payload.pageSize = pageSize;
  return load(section, payload);
};

const removeResourceById = actions.removeResource;

export default {
  loadAlternatives,
  loadMoreAlternatives,
  removeResourceById
};
