import cloneDeep from 'lodash/fp/cloneDeep';
import types from './types';

const initialState = {
  values: {},
  contactSuggestions: [],
  contactIsLoading: false,
  submitted: false,
  isSubmitting: false
};

const miniscule = value => value.charAt(0).toLowerCase() + value.slice(1);

const formatPerson = person => person && {
  name: person.displayName,
  id: person.accountName,
  value: person.accountName,
  email: person.email
};

const initializeValue = (value, defaultValue) => {
  if (value === false) {
    return {
      old: false,
      new: false
    };
  }

  return {
    old: value || defaultValue,
    new: value || defaultValue
  };
};

const initializeField = (name, value, defaultValue = '') => ({
  [name]: initializeValue(value, defaultValue)
});

const decapitalizeFirstLetter = string => string && string.charAt(0).toLowerCase() + string.slice(1);

const changeMasterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MAP_RESOURCE_DATA: {
      const { resource } = action;

      const values = {
        ...initializeField('floor', resource.floor && resource.floor.name),
        ...initializeField('sector', resource.sector && resource.sector.name),
        ...initializeField('capacity', resource.capacity),
        ...initializeField('resourceSubType', resource.resourceSubType.id),
        ...initializeField(
          'roomEquipment',
          (
            (resource.roomEquipment
              && resource.roomEquipment.map(equipment => miniscule(equipment.roomEquipmentType)))
            || []
          ).sort()
        ),
        ...initializeField('notes', resource.notes),
        ...initializeField('daylight', resource.daylight, null),
        ...initializeField('confidentialMeetings', resource.confidentialMeetings),
        ...initializeField('contact', formatPerson(resource.managedBy)),
        ...initializeField('reason', '')
      };

      return {
        ...state,
        values,
        submitted: false
      };
    }

    case types.INITIALIZE_DETAIL: {
      const initializeNew = (name, value) => ({
        [name]: { ...state.values[name], new: value }
      });
      const { changeRequest } = action;
      const {
        floor,
        sector,
        capacity,
        resourceSubType,
        roomEquipment,
        daylight,
        notes,
        confidentialMeetings,
        contact,
        reason
      } = changeRequest;

      const values = {
        ...initializeNew('floor', floor),
        ...initializeNew('sector', sector),
        ...initializeNew('capacity', capacity),
        ...initializeNew('resourceSubType', resourceSubType.id),
        ...initializeNew(
          'roomEquipment',
          roomEquipment.map(equipment => decapitalizeFirstLetter(equipment.roomEquipmentType))
        ),
        ...initializeNew('notes', notes),
        ...initializeNew('daylight', daylight),
        ...initializeNew('confidentialMeetings', confidentialMeetings),
        ...initializeNew('contact', formatPerson(contact)),
        ...initializeNew('reason', reason)
      };

      return { ...state, values: { ...state.values, ...values } };
    }

    case types.UPDATE_FORM_VALUE: {
      const newState = cloneDeep(state);
      newState.values[action.field] = {
        ...newState.values[action.field],
        new: action.value
      };
      return newState;
    }

    case types.UPDATE_SUBMITTED: {
      return { ...state, submitted: true };
    }

    case types.UPDATE_CONTACT_ISLOADING: {
      return { ...state, contactIsLoading: action.isLoading };
    }

    case types.UPDATE_CONTACT_SUGGESTIONS: {
      return { ...state, contactSuggestions: action.suggestions };
    }

    case types.UPDATE_IS_SUBMITTING: {
      return { ...state, isSubmitting: action.isSubmitting };
    }

    default:
      return state;
  }
};

export default changeMasterDataReducer;
