/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import RoomFilter from '../../../RoomFilter';
import EquipmentFilter from '../../../EquipmentFilter';

import './_style.css';
import Header from '../../../Header';

import { withTranslation } from '../../../common';
import { locationsSelectors } from '../../../../state/locations';
import { roomFilterOperations } from '../../../../state/roomFilter';
import { buildingsService } from '../../../../services';

function BookingCollectionResourceFilter({
    resourceType,
    toggleRoomFilter,
    toggleEquipmentFilter,
    loadResources,
    isFilterPanelActive,
    toggleFilterPanel,
    translate,
    roomFilter,
    equipmentFilter,
    selectedLocations,
    updateFloors,
    updateSectors
  }) {
  const onFilterChange = key => {
    if (resourceType.toLowerCase() === 'room') {
      toggleRoomFilter(key);
    } else {
      toggleEquipmentFilter(key);
    }
    loadResources(resourceType);
  };

  const renderFilter = () => {
    if (resourceType.toLowerCase() === 'room') {
      return <RoomFilter filter={roomFilter} onChange={onFilterChange} variant="vertical" />;
    }
    return <EquipmentFilter filter={equipmentFilter} onChange={onFilterChange} />;
  };

  const updateFloorsAndSectors = () => {
    if (selectedLocations.length === 1) {
      const isBuilding = selectedLocations.some(p => p.type === 'Building');

      if (isBuilding) {
        const buildingId = selectedLocations[0].id;

        buildingsService.get(buildingId).then(data => {
          const newFloors = data?.floors?.map(floor => ({
            key: floor.id,
            name: floor.name,
            enabled: false,
            type: 'floor'
          }));

          const newSectors = data?.sectors?.map(sector => ({
            key: sector.id,
            name: sector.name,
            enabled: false,
            type: 'sector'
          }));
          if (newFloors) {
            updateFloors(newFloors);
          }
          if (newSectors) {
            updateSectors(newSectors);
          }
        });
      } else {
        updateFloors([]);
        updateSectors([]);
      }
    } else {
      updateFloors([]);
      updateSectors([]);
    }
  };

  useEffect(() => {
    updateFloorsAndSectors();
  }, [selectedLocations]);

  return (
    <>
      <TransitionGroup
        transitionName="overlay"
        transitionLeaveTimeout={200}
        transitionEnterTimeout={200}
      >
        {isFilterPanelActive && (
          <div
            className="ResourceFilter__Overlay"
            onClick={toggleFilterPanel}
            onKeyPress={toggleFilterPanel}
            role="presentation"
          />
        )}
      </TransitionGroup>

      <TransitionGroup
        transitionName="filter-panel"
        transitionEnterTimeout={200}
        transitionAppearTimeout={200}
        transitionLeaveTimeout={200}
      >
        {isFilterPanelActive && (
          <div className="BookingCollectionResourceFilter">
            <Header
              title={translate('search.filter.filterBy')}
              onClose={toggleFilterPanel}
              fixed={false}
              iconRight
              icon="022-close"
            />
            {renderFilter()}
          </div>
        )}
      </TransitionGroup>
    </>
  );
}

const mapStateToProps = state => ({
  selectedLocations: locationsSelectors.getExistingSelectedLocations(state)
});

const mapDispatchToProps = dispatch => ({
  updateFloors: floors => dispatch(roomFilterOperations.changeFloors('bookingCollection', floors)),
  updateSectors: sectors => dispatch(roomFilterOperations.changeSectors('bookingCollection', sectors))
});

BookingCollectionResourceFilter.propTypes = {
  isFilterPanelActive: PropTypes.bool.isRequired,
  resourceType: PropTypes.string.isRequired,
  toggleRoomFilter: PropTypes.func.isRequired,
  toggleEquipmentFilter: PropTypes.func.isRequired,
  roomFilter: PropTypes.arrayOf(PropTypes.shape()),
  toggleFilterPanel: PropTypes.func.isRequired,
  loadResources: PropTypes.func.isRequired,
  equipmentFilter: PropTypes.arrayOf(PropTypes.shape()),
  translate: PropTypes.func.isRequired,
  selectedLocations: PropTypes.arrayOf(PropTypes.shape()),
  updateFloors: PropTypes.func,
  updateSectors: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(BookingCollectionResourceFilter));
