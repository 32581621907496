import values from 'lodash/fp/values';
import { createSelector } from 'reselect';

import { getFilterTranslation } from '../../util';

const getNamespace = state => state.roomFilterState.namespace;
const enabledFilter = filter => filter.enabled === true;
const isCapacity = filter => filter.type === 'capacity';
const isRoomType = filter => filter.type === 'roomType';
const isEquipment = filter => filter.type === 'equipment';
const isFloor = filter => filter.type === 'floor';
const isSector = filter => filter.type === 'sector';

const isDirty = state => {
  const namespace = getNamespace(state);
  return state.roomFilterState.dirty[namespace];
};

const getRoomFilters = state => {
  const namespace = getNamespace(state);
  return values(state.roomFilterState.rooms[namespace]);
};

const hasSelectedFilters = createSelector(
  getRoomFilters,
  allFilters => allFilters.some(enabledFilter)
);

const getAllSelectedFilters = createSelector(
  getRoomFilters,
  allFilters => allFilters.filter(enabledFilter)
);

/* floor filters */
const getFloorFilters = createSelector(
  getRoomFilters,
  allFilters => allFilters.filter(isFloor)
);

const getSelectedFloorFilters = createSelector(
  getFloorFilters,
  floorFilters => floorFilters.filter(enabledFilter)
);

const getSelectedFloorIds = createSelector(
  getSelectedFloorFilters,
  selectedFloorFilters => selectedFloorFilters.map(floor => floor.key)
);

/* floor sectors */
const getSectorFilters = createSelector(
  getRoomFilters,
  allFilters => allFilters.filter(isSector)
);

const getSelectedSectorFilters = createSelector(
  getSectorFilters,
  sectorFilters => sectorFilters.filter(enabledFilter)
);

const getSelectedSectorIds = createSelector(
  getSelectedSectorFilters,
  selectedSectorFilters => selectedSectorFilters.map(sector => sector.key)
);

/* capacity filters */
const getCapacityFilters = createSelector(
  getRoomFilters,
  allFilters => allFilters.filter(isCapacity)
);

const getSelectedCapacityFilters = createSelector(
  getCapacityFilters,
  capacityFilters => capacityFilters.filter(enabledFilter)
);

const getSelectedCapacitiesNumbers = createSelector(
  getSelectedCapacityFilters,
  selectedCapacityFilters => selectedCapacityFilters.map(capacity => ({
    min: capacity.min,
    max: capacity.max
  }))
);

/* roomType filters */
const getRoomTypeFilters = createSelector(
  getRoomFilters,
  allFilters => allFilters.filter(isRoomType)
);

const getSelectedRoomTypeFilters = createSelector(
  getRoomTypeFilters,
  roomTypeFilters => roomTypeFilters.filter(enabledFilter)
);

const getSelectedRoomTypesIds = createSelector(
  getSelectedRoomTypeFilters,
  selectedRoomTypeFilters => selectedRoomTypeFilters.map(filter => filter.key)
);

/* equipment filters */
const getEquipmentFilters = createSelector(
  getRoomFilters,
  allFilters => allFilters.filter(isEquipment)
);

const getSelectedEquipmentFilters = createSelector(
  getEquipmentFilters,
  equipmentFilters => equipmentFilters.filter(enabledFilter)
);

const getSelectedEquipmentNames = createSelector(
  getSelectedEquipmentFilters,
  selectedRoomTypeFilters => selectedRoomTypeFilters.map(filter => filter.name)
);

const getRoomFilterTags = createSelector(
  getAllSelectedFilters,
  selectedFilters => selectedFilters.map(filter => {
    const translation = getFilterTranslation(filter);
    return {
      key: filter.key,
      type: filter.type,
      resourceType: 'room',
      name: 'filter.name',
      translation
    };
  })
);

export default {
  getNamespace,
  isDirty,
  getRoomFilters,
  hasSelectedFilters,
  getAllSelectedFilters,

  getFloorFilters,
  getSelectedFloorFilters,
  getSelectedFloorIds,

  getSectorFilters,
  getSelectedSectorFilters,
  getSelectedSectorIds,

  getCapacityFilters,
  getSelectedCapacityFilters,
  getSelectedCapacitiesNumbers,

  getRoomTypeFilters,
  getSelectedRoomTypeFilters,
  getSelectedRoomTypesIds,

  getEquipmentFilters,
  getSelectedEquipmentFilters,
  getSelectedEquipmentNames,

  getRoomFilterTags
};
