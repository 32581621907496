const BOOK_DETAIL_MODAL = 'BOOK_DETAIL_MODAL';
const BOOK_CREATE_MODAL = 'BOOK_CREATE_MODAL';
const BOOK_UPDATE_MODAL = 'BOOK_UPDATE_MODAL';
const RECURRENCE_DETAIL_MODAL = 'RECURRENCE_DETAIL_MODAL';
const RECURRENCE_UPDATE_MODAL = 'RECURRENCE_UPDATE_MODAL';
const RECURRENCE_DELETE_DIALOG = 'RECURRENCE_DELETE_DIALOG';
const RECURRENCE_EXCEPTION_MODAL = 'RECURRENCE_EXCEPTION_MODAL';
const RECURRENCE_EXCEPTION_DELETE_MODAL = 'RECURRENCE_EXCEPTION_DELETE_MODAL';
const RECURRENCE_PATTERN_MODAL = 'RECURRENCE_PATTERN_MODAL';
const BOOKING_COLLECTION_INFORMATION_DIALOG = 'BOOKING_COLLECTION_INFORMATION_DIALOG';

export default {
  BOOK_DETAIL_MODAL,
  BOOK_CREATE_MODAL,
  BOOK_UPDATE_MODAL,
  RECURRENCE_DETAIL_MODAL,
  RECURRENCE_UPDATE_MODAL,
  RECURRENCE_DELETE_DIALOG,
  RECURRENCE_EXCEPTION_MODAL,
  RECURRENCE_EXCEPTION_DELETE_MODAL,
  RECURRENCE_PATTERN_MODAL,
  BOOKING_COLLECTION_INFORMATION_DIALOG
};
