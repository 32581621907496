import types from './types';

const initialState = {
  reason: '',
  submitted: false,
  isSubmitting: false
};

const deleteResourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET: {
      return { ...initialState };
    }

    case types.UPDATE_REASON: {
      return { ...state, reason: action.reason };
    }

    case types.UPDATE_SUBMITTED: {
      return { ...state, submitted: true };
    }

    case types.UPDATE_IS_SUBMITTING: {
      return { ...state, isSubmitting: action.isSubmitting };
    }

    default:
      return state;
  }
};

export default deleteResourceReducer;
