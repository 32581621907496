import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { TabBar, Tab, Translate } from '../../../common';
import styles from './SettingsSwitch.module.css';

const views = ['config', 'regions', 'buildings', 'resourcesSubTypes'];
const getCurrentView = ({ pathname }) => (pathname.split('/')[3] || 'config');

function SettingsSwitch({ history, location }) {
  const redirect = view => {
    history.push(`/admin/settings/${ view }`);
  };
  const currentView = getCurrentView(location);

  return (
    <TabBar value={currentView} onChange={redirect} className={styles.bar}>
      {views.map(view => (
        <Tab
          key={view}
          value={view}
          className={styles.tab}
          activeClassName={styles.active}
          disabledClassName={styles.disabled}
          isDisabled={false}
        >
          <Translate path={`admin.settings.${ view }.title`} />
        </Tab>
      ))}
    </TabBar>
  );
}

export default connect(
  null,
  null
)(withRouter(SettingsSwitch));

SettingsSwitch.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};
