import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../common';

function BookFormRecurrenceBookingCollectionInfo({ translate }) {
  const text = translate('search.detail.recurring.bookingCollectionInfo');
  return (
    <div className="BookFormRecurrenceButton BookFormRecurrenceBookingCollectionInfo">{text}</div>
  );
}

BookFormRecurrenceBookingCollectionInfo.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookFormRecurrenceBookingCollectionInfo);
