import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TimeLineBackgrounds extends Component {
  shouldComponentUpdate(nextProps) {
    const { canvasTimeStart, canvasTimeEnd, canvasWidth, groups } = this.props;
    return (
      nextProps.canvasTimeStart !== canvasTimeStart
      || nextProps.canvasTimeEnd !== canvasTimeEnd
      || nextProps.canvasWidth !== canvasWidth
      || nextProps.groups.length !== groups.length
    );
  }

  render() {
    const { groupTops, canvasWidth, groups, compactMode } = this.props;

    const height = compactMode ? 34 : 69;

    const backgrounds = [];
    for (let i = 0; i < groups.length; i++) {
      const { disabled } = groups[i];
      if (disabled) {
        backgrounds.push(
          <div
            key={i}
            style={{
              position: 'absolute',
              top: groupTops[i],
              height,
              left: 0,
              width: canvasWidth,
              background: 'rgba(0, 0, 0, 0.04)',
              zIndex: 41
            }}
          />
        );
      }
    }

    return <div style={{ display: 'absolute' }}>{backgrounds}</div>;
  }
}

TimeLineBackgrounds.propTypes = {
  canvasTimeStart: PropTypes.number,
  canvasTimeEnd: PropTypes.number,
  canvasWidth: PropTypes.number,
  groupTops: PropTypes.arrayOf(PropTypes.number.isRequired),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool.isRequired
    })
  ),
  compactMode: PropTypes.bool.isRequired
};

export default TimeLineBackgrounds;
