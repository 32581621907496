// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecurrenceFilterSwitch_title__OtvhV {\n  width: 180px;\n  height: 43px;\n  font-weight: 600;\n}\n\n.RecurrenceFilterSwitch_boxes__ZR436 {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  padding: 20px 0;\n  width: 306px;\n  border-right: 1px solid var(--color-gray-20);\n}\n\n.RecurrenceFilterSwitch_box__66ilF {\n  width: 180px;\n  padding: 12px 10px;\n  text-align: center;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05);\n}\n\n.RecurrenceFilterSwitch_box__66ilF:not(:last-child) {\n  margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["webpack://./src/views/Search/SearchDesktop/RecurrenceFilter/RecurrenceFilterSwitch/RecurrenceFilterSwitch.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,eAAe;EACf,YAAY;EACZ,4CAA4C;AAC9C;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,+CAA+C;AACjD;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".title {\n  width: 180px;\n  height: 43px;\n  font-weight: 600;\n}\n\n.boxes {\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  padding: 20px 0;\n  width: 306px;\n  border-right: 1px solid var(--color-gray-20);\n}\n\n.box {\n  width: 180px;\n  padding: 12px 10px;\n  text-align: center;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.05);\n}\n\n.box:not(:last-child) {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "RecurrenceFilterSwitch_title__OtvhV",
	"boxes": "RecurrenceFilterSwitch_boxes__ZR436",
	"box": "RecurrenceFilterSwitch_box__66ilF"
};
export default ___CSS_LOADER_EXPORT___;
