import types from '../types';

const updateShown = shown => ({
  type: types.UPDATE_SHOWN,
  shown
});

const updateSelectedResource = resource => ({
  type: types.UPDATE_SELECTED_RESOURCE,
  resource
});

const updateIsSubmitting = isSubmitting => ({
  type: types.UPDATE_IS_SUBMITTING,
  isSubmitting
});

const updateIsValidTimeRange = isValidTimeRange => ({
  type: types.UPDATE_IS_VALIDTIMERANGE,
  isValidTimeRange
});

const updateDate = date => ({
  type: types.UPDATE_DATE,
  date
});

const updateTime = time => ({
  type: types.UPDATE_TIME,
  time
});

const updateMessage = message => ({
  type: types.UPDATE_MESSAGE,
  message
});

const updateSubject = subject => ({
  type: types.UPDATE_SUBJECT,
  subject
});

const updateReminder = reminder => ({
  type: types.UPDATE_REMINDER,
  reminder
});

const resetState = () => ({
  type: types.RESET_STATE
});

const loadBooking = booking => ({
  type: types.LOAD_BOOKING,
  booking
});

const updateSelectedBooking = (booking, buildings) => ({
  type: types.UPDATE_SELECTED_BOOKING,
  booking,
  buildings
});

const cacheState = () => ({
  type: types.CACHE_STATE
});

const resetStateFromCache = () => ({
  type: types.RESET_STATE_FROM_CACHE
});

const updateSeatingId = seatingId => ({
  type: types.UPDATE_SEATING_ID,
  seatingId
});

export default {
  updateShown,
  updateSelectedResource,
  updateIsSubmitting,
  updateIsValidTimeRange,
  updateDate,
  updateTime,
  updateMessage,
  updateSubject,
  updateReminder,
  resetState,
  loadBooking,
  cacheState,
  resetStateFromCache,
  updateSelectedBooking,
  updateSeatingId
};
