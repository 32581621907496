import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import AnimateHeight from 'react-animate-height';

import { Icon } from '..';

import './_style.css';

class Accordion extends Component {
  constructor(props) {
    super(props);
    const { openByDefault } = this.props;
    this.state = {
      open: openByDefault
    };
  }

  onClick = () => {
    const { open } = this.state;
    this.setState({
      open: !open
    });
  };

  render() {
    const {
      className,
      headComponent,
      bodyComponent,
      headClasses,
      bodyClasses,
      duration,
      height
    } = this.props;
    const { open } = this.state;

    const classes = cx('Accordion', className);

    const iconClasses = cx({
      '020-chevron-flat-up': open,
      '017-chevron-flat-down': !open
    });

    return (
      <div className={classes}>
        <div className="Accordion__Head" role="presentation" onClick={this.onClick}>
          <div className={headClasses}>{headComponent}</div>
          <div className="Accordion__Icon">
            <Icon icon={iconClasses} />
          </div>
        </div>
        <AnimateHeight
          duration={duration}
          height={open ? height : 0}
          contentClassName="Accordion__Body"
        >
          <div className={bodyClasses}>{bodyComponent}</div>
        </AnimateHeight>
      </div>
    );
  }
}
Accordion.propTypes = {
  openByDefault: PropTypes.bool,
  headComponent: PropTypes.node.isRequired,
  bodyComponent: PropTypes.node.isRequired,
  duration: PropTypes.number,
  className: PropTypes.string,
  headClasses: PropTypes.string,
  bodyClasses: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Accordion.defaultProps = {
  openByDefault: false,
  duration: 200,
  height: 'auto'
};

export default Accordion;
