import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { withTranslation, Icon } from '../../../common';
import { regionsOperations } from '../../../../state/regions';
import RegionsDeleteDialog from './RegionsDeleteDialog';

class RegionsTable extends Component {
  constructor() {
    super();
    this.tableWrapper = React.createRef();
    this.setHasScroll = this.setHasScroll.bind(this);
  }

  componentDidMount() {
    this.setHasScroll();
    window.addEventListener('resize', this.setHasScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHasScroll);
  }

  setHasScroll() {
    const { onTableScrollBar } = this.props;
    const maxHeight = window.innerHeight - 300; // same maxHeight as style .scrollable_table__wrapper
    const box = this.tableWrapper.current?.getBoundingClientRect();
    const hasScroll = box.height >= maxHeight;
    onTableScrollBar(hasScroll);
  }

  renderHeaderRow = () => {
    const { translate } = this.props;

    const columns = [
      translate('common.key'),
      translate('common.name'),
      ''
    ];

    const renderCell = value => <th key={value}>{value}</th>;

    return <tr>{columns.map(renderCell)}</tr>;
  };

  renderRow = region => {
    const cells = [
      { key: region.key },
      { name: region.name }
    ];

    const setUpModal = () => {
      const { actionOnClick, setSelectedRegion } = this.props;
      setSelectedRegion(region);
      actionOnClick();
    };

    const renderCell = (item, index) => {
      const value = Object.values(item)[0];
      return (
        <td key={index}>
          {value}
        </td>
      );
    };

    const redirect = () => {
      const { history } = this.props;
      const url = `/admin/settings/regions/${ region.id }`;
      history.push(url);
    };

    return (
      <tr key={region.key}>
        {cells.map((value, index) => renderCell(value, index))}
        <td aria-label="Delete">
          <Icon icon="008-bin" className="Delete__Icon" onClick={setUpModal} />
          <Icon
            icon="025-edit"
            className="Edit__Icon"
            onClick={redirect} />
        </td>
      </tr>
    );
  };

  render() {
    const { regions } = this.props;
    const regionsArray = Object.entries(regions)
      .map(item => ({ id: item[1].id, key: item[1].key, name: item[1].name }));

    // sorted by name asc
    regionsArray.sort((a, b) => {
      if (a.key > b.key) {
        return 1;
      }
      return a.key < b.key ? -1 : 0;
    });

    return (
      <>
        <div className="table table--responsive">
          <div className="scrollable_table__wrapper" ref={this.tableWrapper}>
            <table>
              <thead>{this.renderHeaderRow()}</thead>
              <tbody>{regionsArray.map(this.renderRow)}</tbody>
            </table>
          </div>
        </div>
        <RegionsDeleteDialog />
      </>
    );
  }
}

RegionsTable.propTypes = {
  regions: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired,
  actionOnClick: PropTypes.func.isRequired,
  setSelectedRegion: PropTypes.func.isRequired,
  onTableScrollBar: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  setSelectedRegion: region => dispatch(regionsOperations.setSelectedRegion(region))
});

const RegionsTableComponent = withTranslation(RegionsTable);

export default connect(null, mapDispatchToProps)(withRouter(RegionsTableComponent));
