import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { authSelectors } from '../../../state/auth';
import {
  maintenanceMessageSelectors,
  maintenanceMessageOperations
} from '../../../state/maintenanceMessage';
import { Loader, CustomPropTypes, withTranslation } from '../../common';
import Header from '../../Header';
import MaintenanceForm from './MaintenanceForm';

class Maintenance extends Component {
  componentDidMount() {
    const { isAdmin, history, getLatest } = this.props;
    if (!isAdmin) {
      history.replace('/unauthorized');
      return;
    }
    getLatest();
  }

  renderHeader = () => {
    const { history, translate } = this.props;
    const title = translate('admin.title');
    return <Header onClose={history.goBack} title={title} iconRight icon="022-close" />;
  };

  render() {
    const { isLoading, ...props } = this.props;

    return (
      <div>
        {this.renderHeader()}
        {isLoading && (
          <div className="MaintenanceForm__Loader">
            <Loader />
          </div>
        )}
        {!isLoading && <MaintenanceForm {...props} />}
      </div>
    );
  }
}

Maintenance.propTypes = {
  isAdmin: PropTypes.bool,
  payload: CustomPropTypes.adminMessage.isRequired,
  isValid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPersisted: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  languagesValid: PropTypes.bool.isRequired,
  rangeValid: PropTypes.bool.isRequired,
  dateValid: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired,
  getLatest: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

Maintenance.defaultProps = {
  isAdmin: false
};

const mapStateToProps = state => ({
  isAdmin: authSelectors.isAdministrator(state),
  payload: maintenanceMessageSelectors.getPayload(state),
  isValid: maintenanceMessageSelectors.isValid(state),
  isDirty: maintenanceMessageSelectors.isDirty(state),
  isLoading: maintenanceMessageSelectors.isLoading(state),
  isPersisted: maintenanceMessageSelectors.isPersisted(state),
  isSubmitting: maintenanceMessageSelectors.isSubmitting(state),
  isSubmitted: maintenanceMessageSelectors.isSubmitted(state),
  languagesValid: maintenanceMessageSelectors.languagesValid(state),
  rangeValid: maintenanceMessageSelectors.rangeValid(state),
  dateValid: maintenanceMessageSelectors.dateValid(state)
});

const mapDispatchToProps = dispatch => ({
  getLatest: () => dispatch(maintenanceMessageOperations.getLatest()),
  clear: () => dispatch(maintenanceMessageOperations.clear()),
  update: payload => dispatch(maintenanceMessageOperations.updatePayload(payload)),
  submit: () => dispatch(maintenanceMessageOperations.submit())
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation(withRouter(Maintenance))
);
