import React from 'react';
import { CustomPropTypes } from '../../../../common';

function ResourceLocation({ resource }) {
  const { building } = resource;

  return (
    <div>
      {building.key}
    </div>
  );
}

ResourceLocation.propTypes = {
  resource: CustomPropTypes.resource
};

export default ResourceLocation;
