import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../common';

function CloseLink({ onClose, icon, iconRight }) {
  const classes = classNames({
    link: true,
    Header__Item: true,
    'Header__Item--left': !iconRight,
    'Header__Item--right': iconRight,
    Header__Link: true
  });
  const onClick = () => onClose();

  return (
    <button aria-label="Back" className={classes} onClick={onClick} role="link" tabIndex={0} type="button">
      <Icon icon={icon || '014-chevron-left'} className="Back-Link" />
    </button>
  );
}

CloseLink.propTypes = {
  onClose: PropTypes.func,
  icon: PropTypes.string,
  iconRight: PropTypes.bool.isRequired
};

export default CloseLink;
