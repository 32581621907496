import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Header from '../../Header';
import {
  BookDetailDate,
  BookDetailTime,
  BookDetailContent,
  BookDetailSidebar,
  BookDetailActions,
  BookDetailPrivate,
  BookDetailRecurring,
  BookDetailProfile,
  BookDetailNotes,
  BookDetailBookingCollection,
  RecurrenceUnhandledException
} from '../BookDetail';

import { ResourceImages, Spacing } from '../../common';
import { getProfile } from '../../../util';

class BookDetailModalMobile extends Component {
  renderSidebar = () => {
    const {
      selectedResource,
      seating,
      calendarId,
      isRecurring,
      isBookingCollection,
      roomMeetingResponseType,
      isUnhandledRecurrenceException,
      isPrivate
    } = this.props;

    const { notes, resourceType } = selectedResource;

    return (
      <div className="BookDetailSidebar">
        <div className="BookDetailInformation">
          <BookDetailSidebar resource={selectedResource} seating={seating} />
        </div>

        {isRecurring && (
          <Spacing top={1}>
            <BookDetailRecurring calendarId={calendarId} />
          </Spacing>
        )}

        <Spacing top={1}>
          <BookDetailProfile resource={selectedResource} responseType={roomMeetingResponseType} />
        </Spacing>

        {isUnhandledRecurrenceException && (
          <Spacing top={1}>
            <RecurrenceUnhandledException resourceType={resourceType} />
          </Spacing>
        )}

        {isPrivate && (
          <Spacing top={1}>
            <BookDetailPrivate />
          </Spacing>
        )}

        {isBookingCollection && (
          <Spacing top={1}>
            <BookDetailBookingCollection />
          </Spacing>
        )}

        {notes && (
          <Spacing top={1}>
            <BookDetailNotes notes={notes} />
          </Spacing>
        )}
      </div>
    );
  };

  renderContent = () => <BookDetailContent {...this.props} />;

  renderDateTime = () => {
    const { start, end } = this.props;

    return (
      <div>
        <BookDetailDate start={start} />
        <BookDetailTime start={start} end={end} />
      </div>
    );
  };

  renderSubmit = () => {
    const { selectedResource, isRecurring, deleteBooking, roomMeetingResponseType } = this.props;

    const profile = getProfile(selectedResource);
    const canEdit = !(profile === 3 && roomMeetingResponseType.toLowerCase() === 'declined');

    return (
      <div className="BookModalSubmit">
        <BookDetailActions
          deleteBooking={deleteBooking}
          isRecurring={isRecurring}
          resource={selectedResource}
          canEdit={canEdit}
        />
      </div>
    );
  };

  render() {
    const { selectedResource, clearModals } = this.props;
    return (
      <>
        <Header
          title={selectedResource ? selectedResource.displayName : null}
          dynamic
          onClose={clearModals}
          fixedMobile
        />

        <div className="BookModalMobile">
          <ResourceImages resource={selectedResource} />

          <div className="BookModal__Main">
            <div className="BookModal__Content">{this.renderContent()}</div>
            <hr className="BookModal__Divider" style={{ marginBottom: 20, marginTop: 20 }} />
            <div className="BookModal__Content">{this.renderDateTime()}</div>
            <hr className="BookModal__Divider" style={{ marginBottom: 20, marginTop: 20 }} />
            <div className="BookModal__Content">{this.renderSidebar()}</div>
          </div>
        </div>

        {this.renderSubmit()}
      </>
    );
  }
}

BookDetailModalMobile.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  selectedResource: PropTypes.shape({
    displayName: PropTypes.string,
    notes: PropTypes.any,
    resourceType: PropTypes.any
  }),
  isRecurring: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isBookingCollection: PropTypes.bool,
  isUnhandledRecurrenceException: PropTypes.bool,
  calendarId: PropTypes.string.isRequired,
  roomMeetingResponseType: PropTypes.string,
  deleteBooking: PropTypes.func.isRequired,
  clearModals: PropTypes.func.isRequired,
  seating: PropTypes.shape()
};

export default BookDetailModalMobile;
