import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation, SelectBox } from '../common';

class RoomFilterEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false
    };
  }

  showMore = () => {
    this.setState({ showMore: true });
  };

  renderEquipment = item => {
    const { onChange, translate } = this.props;
    return (
      <SelectBox
        key={item.key}
        className="EquipmentBox"
        selected={item.enabled}
        onClick={() => onChange(item)}
      >
        {translate(`equipment.${item.name}`)}
      </SelectBox>
    );
  };

  render() {
    const { equipment, translate } = this.props;
    const { showMore } = this.state;
    const slicedEquipments = showMore ? equipment : equipment.slice(0, 4);

    return (
      <div className="RoomFilter__Equipment">
        <div className="RoomFilter__Title">{translate('roomFilter.roomEquipmentTitle')}</div>
        <div className="RoomFilter__Section">
          <div className="RoomFilter__Content EquipmentBoxes">
            {slicedEquipments.map(item => this.renderEquipment(item))}
          </div>
        </div>
        <div className="RoomFilter__ShowMore">
          {!showMore && (
            <button className="link" role="link" tabIndex={0} onClick={this.showMore} type="button">
              {translate('roomFilter.showRoomEquipments')}
            </button>
          )}
        </div>
      </div>
    );
  }
}

RoomFilterEquipment.propTypes = {
  equipment: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RoomFilterEquipment);
