import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../../common';

import './_style.css';

function BookFormNotes({ notes }) {
  return (
    <div className="BookFormNotes">
      <Icon icon="046-information-circle" className="BookFormNotes__Icon" />
      <span>{notes}</span>
    </div>
  );
}

BookFormNotes.propTypes = {
  notes: PropTypes.string
};

export default BookFormNotes;
