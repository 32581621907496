import React from 'react';
import PropTypes from 'prop-types';

import { Button, EmptyState, withTranslation } from '../../../common';
import './_style.css';

function RecurrenceExceptionModalEmptyState({ visible, onClick, isLastException, translate }) {
  if (!visible) {
    return null;
  }

  const text = translate('search.emptyState.room');
  const buttonLabel = isLastException
    ? translate('search.recurrence.exceptions.back')
    : translate('search.recurrence.exceptions.next');

  const content = (
    <div>
      <div className="RecurrenceExceptionModalEmptyState__Text">{text}</div>
      <div>
        <Button className="MyBookings__EmptyStateButton" onClick={onClick} label={buttonLabel} />
      </div>
    </div>
  );

  return (
    <div className="RecurrenceExceptionModalEmptyState">
      <EmptyState icon="232-robot-neutral" visible centered content={content} />
    </div>
  );
}

RecurrenceExceptionModalEmptyState.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isLastException: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurrenceExceptionModalEmptyState);
