import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withTranslation, Icon } from '../../../common';

import './_style.css';

function BookFormRecurrenceButton({ translate, onClick, disabled }) {
  const classes = cx('BookFormRecurrenceButton', {
    'BookFormRecurrenceButton--disabled': disabled
  });

  const clickHandler = () => {
    if (disabled) {
      return;
    }
    onClick();
  };

  return (
    <div className={classes} onClick={clickHandler} role="button" tabIndex="0">
      <p className="BookFormRecurrenceButton__Label">{translate('search.recurrence.title')}</p>
      <Icon className="BookFormRecurrenceButton__Icon" icon="015-chevron-right" />
    </div>
  );
}

BookFormRecurrenceButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookFormRecurrenceButton);
