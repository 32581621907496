import React from 'react';
import PropTypes from 'prop-types';

import AsyncDropDown from '../DropDown/AsyncDropDown';
import MenuLabel from './MenuLabel';
import ValueLabel from './ValueLabel';

const formatLabel = (location, { context, inputValue }) => {
  if (context === 'menu') {
    return <MenuLabel location={location} inputValue={inputValue} />;
  }
  return <ValueLabel location={location} />;
};

const getValue = ({ id }) => id;

const LocationPicker = React.forwardRef(
  ({ locations, updateLocations, loadLocations, ...props }, ref) => (
    <AsyncDropDown
      isMulti
      ref={ref}
      value={locations}
      loadOptions={loadLocations}
      formatOptionLabel={formatLabel}
      getOptionValue={getValue}
      onChange={value => updateLocations(value)}
      {...props}
    />
  )
);

export default LocationPicker;

LocationPicker.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  ),
  updateLocations: PropTypes.func.isRequired,
  loadLocations: PropTypes.func.isRequired
};
