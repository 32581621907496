import moment from 'moment';

import types from './types';
import searchTypes from '../search/types';
import bookTypes from '../book/types/bookTypes';

const daySorter = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5
};

const sortByDay = (a, b) => daySorter[a] - daySorter[b];

let todayName = moment()
  .format('dddd')
  .toLowerCase();
todayName = todayName === 'saturday' || todayName === 'sunday' ? 'monday' : todayName;

const initialState = {
  active: false,
  type: 'never',
  week: {
    index: 1,
    daysOfWeek: [todayName],
    daysOfWeekChanged: false
  },
  month: {
    index: 1,
    weekOfMonth: 'first',
    dayOfMonth: todayName,
    dayOfMonthChanged: false
  },
  numberOfBookings: 10,
  endType: 'after',
  endDate: moment()
    .add(6, 'months')
    .format('DD.MM.YYYY'),
  hasChanged: false,
  cachedState: null
};

const toggleValueInList = (list, value) => {
  const item = list.find(val => val === value);
  return item ? list.filter(val => val !== item) : [...list, value];
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ACTIVE: {
      const { active } = action;
      return { ...state, active };
    }

    case types.UPDATE_RECURRENCE_TYPE: {
      return { ...state, type: action.recurrenceType, hasChanged: true };
    }

    case types.UPDATE_INDEX_OF_WEEK: {
      return {
        ...state,
        week: { ...state.week, index: action.index },
        hasChanged: true
      };
    }

    case types.TOGGLE_DAY_OF_WEEK: {
      return {
        ...state,
        week: {
          ...state.week,
          daysOfWeek: toggleValueInList(state.week.daysOfWeek, action.day).sort(sortByDay),
          daysOfWeekChanged: true
        },
        hasChanged: true
      };
    }

    case types.UPDATE_INDEX_OF_MONTH: {
      return {
        ...state,
        month: { ...state.month, index: action.index },
        hasChanged: true
      };
    }

    case types.TOGGLE_WEEK_OF_MONTH: {
      return {
        ...state,
        month: {
          ...state.month,
          weekOfMonth: action.week
        },
        hasChanged: true
      };
    }

    case types.TOGGLE_DAY_OF_MONTH: {
      return {
        ...state,
        month: {
          ...state.month,
          dayOfMonth: action.day,
          dayOfMonthChanged: true
        },
        hasChanged: true
      };
    }

    case types.UPDATE_NUMBER_OF_BOOKINGS: {
      return {
        ...state,
        numberOfBookings: action.numberOfBookings,
        hasChanged: true
      };
    }

    case types.UPDATE_RECURRENCE_END_TYPE: {
      return {
        ...state,
        endType: action.endType,
        hasChanged: true
      };
    }

    case types.UPDATE_RECURRENCE_END_DATE: {
      return {
        ...state,
        endDate: action.endDate,
        hasChanged: true
      };
    }

    case types.CLEAR: {
      return {
        ...initialState
      };
    }

    case types.RESET_CHANGED: {
      return {
        ...state,
        hasChanged: false
      };
    }

    case types.CACHE_STATE: {
      return {
        ...state,
        cachedState: state
      };
    }

    case types.RESET_STATE: {
      if (state.cachedState) {
        return {
          ...state.cachedState
        };
      }
      return state;
    }

    case searchTypes.UPDATE_DATE:
    case bookTypes.UPDATE_DATE: {
      const getDay = () => {
        const { date: selectedDate } = action;
        const date = moment(selectedDate, 'DD.MM.YYYY');
        date.locale('en');
        return date.format('dddd').toLowerCase();
      };

      const day = getDay();
      if (day === 'saturday' || day === 'sunday') {
        return state;
      }

      const { daysOfWeekChanged } = state.week;
      const week = daysOfWeekChanged ? state.week : { ...state.week, daysOfWeek: [day] };

      const { dayOfMonthChanged } = state.month;
      const month = dayOfMonthChanged ? state.month : { ...state.month, dayOfMonth: day };

      return {
        ...state,
        week,
        month
      };
    }

    default:
      return state;
  }
};

export default reducer;
