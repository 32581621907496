import { createSelector } from 'reselect';

const getRegions = state => state.regionsState.regions.regions;
const isLoaded = state => state.regionsState.regions.isLoaded;
const getAge = state => state.regionsState.regions.age;
const getSelectedRegion = state => state.regionsState.regions.selectedRegion;

const hasRegions = createSelector(
  getRegions,
  regions => {
    const regionIDs = [];
    if (regions) {
      Object.entries(regions).forEach(region => regionIDs.push(region));
    }
    return regions != null && regionIDs.length > 0;
  }
);

const getAll = createSelector(getRegions, regions => ({
  ...regions
}));

export default {
  getAll,
  getRegions,
  hasRegions,
  isLoaded,
  getAge,
  getSelectedRegion
};
