import { changePermissionsSelectors } from '../changePermissions';
import { changeMasterDataSelectors } from '../changeMasterData';
import { changeOwnerSelectors } from '../changeOwner';
import { deleteResourceSelectors } from '../deleteResource';
import { createResourceSelectors } from '../createResource';

const isLoading = state => state.changeRequestDetailState.isLoading;
const getChangeRequest = state => state.changeRequestDetailState.changeRequest;
const approveProcessed = state => state.changeRequestDetailState.isProcessed.approve;
const isSubmitted = state => approveProcessed(state);
const isSubmitting = state => {
  const actions = ['approve', 'reject', 'discard', 'complete'];
  return actions.some(action => state.changeRequestDetailState.isProcessing[action]);
};

const getSelector = type => {
  switch (type) {
    case 'Masterdata':
      return changeMasterDataSelectors;
    case 'Permissions':
      return changePermissionsSelectors;
    case 'Profile':
      return changeOwnerSelectors;
    case 'Delete':
      return deleteResourceSelectors;
    case 'NewResource':
      return createResourceSelectors;
    default:
      throw new Error('not a valid changeRequestType');
  }
};

const getValues = state => {
  const changeRequest = getChangeRequest(state);
  const { changeRequestType } = changeRequest;
  const selector = getSelector(changeRequestType);
  return selector.getValues(state);
};

const isValid = state => {
  const changeRequest = getChangeRequest(state);
  const { changeRequestType } = changeRequest;
  const selector = getSelector(changeRequestType);
  return selector.isValid(state);
};

const getRejectModalKey = () => 'CHANGEREQUEST_REJECT_MODAL';

export default {
  isLoading,
  isSubmitted,
  isSubmitting,
  getChangeRequest,
  getValues,
  getRejectModalKey,
  isValid
};
