import types from './types';

const setActive = active => ({
  type: types.SET_ACTIVE,
  active
});

const updateRecurrenceType = recurrenceType => ({
  type: types.UPDATE_RECURRENCE_TYPE,
  recurrenceType
});

const updateIndexOfWeek = index => ({
  type: types.UPDATE_INDEX_OF_WEEK,
  index
});

const toggleDayOfWeek = day => ({
  type: types.TOGGLE_DAY_OF_WEEK,
  day
});

const updateIndexOfMonth = index => ({
  type: types.UPDATE_INDEX_OF_MONTH,
  index
});

const toggleWeekOfMonth = week => ({
  type: types.TOGGLE_WEEK_OF_MONTH,
  week
});

const toggleDayOfMonth = day => ({
  type: types.TOGGLE_DAY_OF_MONTH,
  day
});

const updateRecurrenceEndType = endType => ({
  type: types.UPDATE_RECURRENCE_END_TYPE,
  endType
});

const updateNumberOfBookings = numberOfBookings => ({
  type: types.UPDATE_NUMBER_OF_BOOKINGS,
  numberOfBookings
});

const updateRecurrenceEndDate = endDate => ({
  type: types.UPDATE_RECURRENCE_END_DATE,
  endDate
});

const clear = () => ({
  type: types.CLEAR
});

const resetChanged = () => ({
  type: types.RESET_CHANGED
});

const cacheState = () => ({
  type: types.CACHE_STATE
});

const resetState = () => ({
  type: types.RESET_STATE
});

export default {
  setActive,
  updateRecurrenceType,
  updateIndexOfWeek,
  toggleDayOfWeek,
  updateIndexOfMonth,
  toggleWeekOfMonth,
  toggleDayOfMonth,
  updateNumberOfBookings,
  updateRecurrenceEndType,
  updateRecurrenceEndDate,
  clear,
  resetChanged,
  cacheState,
  resetState
};
