import React from 'react';
import PropTypes from 'prop-types';

import {
  Icon,
  TimeInputRange,
  TimeInputRangeValidation,
  withTranslation,
  CustomPropTypes
} from '../../../../common';

import './_style.css';

function SearchTime({ translate, date, time, updateTime }) {
  const error = translate('search.when.wrongTime');

  const props = {
    values: time,
    onChange: updateTime,
    error
  };

  return (
    <div className="SearchTime">
      <div className="SearchTime__Icon">
        <Icon icon="021-clock" />
      </div>
      <div className="SearchTime__Input">
        <TimeInputRangeValidation date={date} time={time}>
          <TimeInputRange {...props} />
        </TimeInputRangeValidation>
      </div>
    </div>
  );
}

SearchTime.propTypes = {
  date: PropTypes.string,
  time: CustomPropTypes.time.isRequired,
  updateTime: PropTypes.func.isRequired,
  translate: PropTypes.func
};

export default withTranslation(SearchTime);
