import React from 'react';
import PropTypes from 'prop-types';

import BookDetailSubject from './BookDetailSubject';
import BookDetailAttendees from './BookDetailAttendees';
import BookDetailBookedFor from './BookDetailBookedFor';
import BookDetailMessage from './BookDetailMessage';

function BookDetailContent(props) {
  const { subject, attendees, bookedFor, message } = props;

  return (
    <>
      <BookDetailSubject subject={subject} />
      <BookDetailBookedFor person={bookedFor} />
      <BookDetailAttendees attendees={attendees} />
      <BookDetailMessage message={message} />
    </>
  );
}

BookDetailContent.propTypes = {
  subject: PropTypes.string,
  message: PropTypes.string,
  bookedFor: PropTypes.shape({}),
  attendees: PropTypes.arrayOf(PropTypes.shape({}))
};

export default BookDetailContent;
