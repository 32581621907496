import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation, ChangeDetector, TextArea } from '../../common';

import './_style.css';

function FormFieldTextArea({
  values,
  update,
  field,
  optional,
  isSubmitted,
  detectChanges,
  translate
}) {
  const updateValue = event => update(field, event.target.value);
  const labelText = translate(`changeRequests.fieldNames.${field}`);

  const getOptionalLabel = () => {
    const optionalText = translate('changeRequests.optional');
    return (
      <span>
        {labelText}
        {' '}
        <span className="ChangeRequestFormField__OptionalLabel">{optionalText}</span>
      </span>
    );
  };

  const label = !optional ? labelText : getOptionalLabel();
  const invalid = isSubmitted && (!values.new || values.new === '');

  return (
    <div className="ChangeRequestFormField">
      <ChangeDetector values={values} invalid={invalid} detectChanges={detectChanges}>
        <TextArea
          label={label}
          onChange={updateValue}
          value={values.new}
          maxRows={10}
          error={invalid}
          fixed
        />
      </ChangeDetector>
    </div>
  );
}

FormFieldTextArea.propTypes = {
  values: PropTypes.shape({
    old: PropTypes.string,
    new: PropTypes.string
  }).isRequired,
  field: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  optional: PropTypes.bool,
  detectChanges: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

FormFieldTextArea.defaultProps = {
  optional: false,
  isSubmitted: false,
  detectChanges: true
};

export default withTranslation(FormFieldTextArea);
