import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';

import { Icon, CustomPropTypes, withTranslation, ResourceImages } from '../../../common';
import { BookDetailSidebar } from '../../BookDetail';
import { SidebarTime, SidebarDate } from '../../BookDetail/BookDetailSidebar';

import './_style.css';

const getDate = date => moment(date).format('DD.MM.YYYY');
const getTime = date => moment(date).format('HH:mm');
const formatTime = (start, end) => `${getTime(start)} - ${getTime(end)}`;

const didNotAccept = reason => reason === 'ResourceDidNotAccept';

function RecurrenceExceptionDeleteSidebar({ exception, mobile, translate }) {
  const { start, end, resource, reasons } = exception;
  const sidebarClasses = cx('RecurrenceExceptionDeleteSidebar__Resource', {
    'RecurrenceExceptionDeleteSidebar__Resource--desktop': !mobile
  });

  const showNotAcceptedInfo = reasons && reasons.some(didNotAccept);
  const text = translate('search.detail.profile2.unknown');

  return (
    <div>
      <ResourceImages resource={resource} />

      {mobile && (
        <div>
          <div className="RecurrenceExceptionDeleteSidebar__Date">
            <SidebarDate date={getDate(start)} />
            <SidebarTime time={formatTime(start, end)} />
          </div>

          <div className="RecurrenceExceptionDivider" />
        </div>
      )}

      <div className={sidebarClasses}>
        <BookDetailSidebar resource={resource} />
      </div>

      {showNotAcceptedInfo && (
        <div className="RecurrenceExceptionNotAcceptedInfo">
          <Icon
            icon="046-information-circle"
            className="RecurrenceExceptionNotAcceptedInfo__Icon"
          />
          {text}
        </div>
      )}
    </div>
  );
}

RecurrenceExceptionDeleteSidebar.propTypes = {
  exception: CustomPropTypes.exception,
  mobile: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

RecurrenceExceptionDeleteSidebar.defaultProps = {
  mobile: false
};

export default withTranslation(RecurrenceExceptionDeleteSidebar);
