import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '..';
import { getResourceSubTypeNameTranslation } from '../../../util';

function EquipmentType({ resourceSubType }) {
  const name = getResourceSubTypeNameTranslation(resourceSubType);
  return <span style={{ color: resourceSubType.color }}>{name}</span>;
}

EquipmentType.propTypes = {
  resourceSubType: PropTypes.shape()
};

export default withTranslation(EquipmentType);
