import React from 'react';
import PropTypes from 'prop-types';

import SidebarFormField from './SidebarFormField';

function SidebarLocation({ location }) {
  return <SidebarFormField label="common.location" element={location} />;
}

SidebarLocation.propTypes = {
  location: PropTypes.string
};

export default SidebarLocation;
