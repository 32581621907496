import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withTranslation, SelectBox } from '../../common';
import { recurrenceSelectors, recurrenceOperations } from '../../../state/recurrence';

import './_style.css';

function RecurrenceSwitch({ type, updateType, translate }) {
  const types = [{ type: 'never' }, { type: 'daily' }, { type: 'weekly' }, { type: 'monthly' }];

  const options = types.map(x => ({
    ...x,
    label: translate(`search.recurrence.${x.type}`),
    selected: x.type === type
  }));

  return (
    <div className="RecurrenceSwitch__Boxes">
      {options.map(option => {
        const onClick = () => updateType(option.type);
        const props = {
          key: option.type,
          onClick,
          selected: option.selected,
          className: 'RecurrenceSwitchBox SelectBox--halved'
        };
        return <SelectBox {...props}>{option.label}</SelectBox>;
      })}
    </div>
  );
}

const mapStateToProps = state => ({ type: recurrenceSelectors.getRecurrenceType(state) });

const mapDispatchToProps = dispatch => ({
  updateType: type => dispatch(recurrenceOperations.updateRecurrenceType(type))
});

RecurrenceSwitch.propTypes = {
  type: PropTypes.string.isRequired,
  updateType: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(RecurrenceSwitch));
