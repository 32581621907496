import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../../../../common';
import styles from './CalendarLoader.module.css';

function CalendarLoader({ visible }) {
  if (!visible) {
    return null;
  }

  return (
    <div className={styles.loader}>
      <Loader visible />
    </div>
  );
}

CalendarLoader.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default CalendarLoader;
