import types from '../types';

const clearAttendees = () => ({ type: types.CLEAR_ATTENDEES });

const updateSuggestedAttendees = suggestedAttendees => ({
  type: types.UPDATE_SUGGESTED_ATTENDEES,
  suggestedAttendees
});

const updateSelectedAttendees = selectedAttendees => ({
  type: types.UPDATE_SELECTED_ATTENDEES,
  selectedAttendees
});

const setAttendeesSearchTerm = term => ({
  type: types.SET_ATTENDEES_SEARCH_TERM,
  term
});

const toggleAttendeesLoader = isLoading => ({
  type: types.TOGGLE_ATTENDEES_LOADER,
  isLoading
});

export default {
  updateSuggestedAttendees,
  updateSelectedAttendees,
  clearAttendees,
  setAttendeesSearchTerm,
  toggleAttendeesLoader
};
