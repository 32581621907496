import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Header from '../../Header';
import { withTranslation, Button } from '../../common';

import { createResourceSelectors, createResourceOperations } from '../../../state/createResource';
import { changeMasterDataSelectors } from '../../../state/changeMasterData';

import CreateResource from './CreateResource';

import './_style.css';
import { resourcesSubTypesOperations, resourcesSubTypesSelectors } from '../../../state/resourcesSubTypes';

class CreateResourcePage extends Component {
  componentDidMount() {
    const { resetState, loadResourceSubTypes } = this.props;
    loadResourceSubTypes();
    resetState();
  }

  onClose = () => {
    const { resetState, history } = this.props;
    resetState();
    history.goBack();
  };

  renderSubmitButton = () => {
    const { submit, resourceType, isSubmitting, history, translate } = this.props;
    if (!resourceType.new) {
      return null;
    }
    const send = translate('common.send');
    const onClick = event => {
      event.preventDefault();
      event.stopPropagation();
      submit().then(result => {
        if (result) {
          history.replace('/changerequests/my');
        }
      });
    };
    return (
      <div className="CreateResource__Submit">
        <Button label={send} loading={isSubmitting} onClick={onClick} />
      </div>
    );
  };

  render() {
    const { translate } = this.props;
    const title = translate('changeRequests.new.title');
    return (
      <div className="CreateResource">
        <Header onClose={this.onClose} title={title} iconRight icon="022-close" />
        <CreateResource {...this.props} />
        <div className="CreateResource__Form CreateResource__Form--submit">
          {this.renderSubmitButton()}
        </div>
      </div>
    );
  }
}

CreateResourcePage.propTypes = {
  resetState: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  resourceType: PropTypes.shape().isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired,
  loadResourceSubTypes: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  name: createResourceSelectors.getName(state),
  resourceType: createResourceSelectors.getResourceType(state),
  building: createResourceSelectors.getBuilding(state),
  isBuildingLoading: createResourceSelectors.isBuildingLoading(state),
  buildingSuggestions: createResourceSelectors.getBuildingSuggestions(state),
  region: createResourceSelectors.getRegion(state),
  street: createResourceSelectors.getStreet(state),
  zipCode: createResourceSelectors.getZipCode(state),
  floor: createResourceSelectors.getFloor(state),
  sector: createResourceSelectors.getSector(state),
  resourceSubType: createResourceSelectors.getResourceSubType(state),
  resourcesSubTypes: resourcesSubTypesSelectors.getAll(state),
  owners: createResourceSelectors.getOwners(state),
  capacity: createResourceSelectors.getCapacity(state),
  daylight: createResourceSelectors.getDaylight(state),
  roomEquipment: createResourceSelectors.getRoomEquipment(state),
  roomEquipmentOptions: changeMasterDataSelectors.getRoomEquipmentOptions(state),
  confidentialMeetings: createResourceSelectors.getConfidentialMeetings(state),
  contact: createResourceSelectors.getContact(state),
  notes: createResourceSelectors.getNotes(state),
  reason: createResourceSelectors.getReason(state),
  profile: createResourceSelectors.getProfile(state),
  isSubmitted: createResourceSelectors.isSubmitted(state),
  isSubmitting: createResourceSelectors.isSubmitting(state),
  isPeopleLoading: createResourceSelectors.isPeopleLoading(state),
  peopleSuggestions: createResourceSelectors.getPeopleSuggestions(state),
  ownersProfile2: createResourceSelectors.getOwnersProfile2(state),
  ownersProfile3: createResourceSelectors.getOwnersProfile3(state),
  ownersProfile4: createResourceSelectors.getOwnersProfile4(state),
  isValid: createResourceSelectors.isValid(state),
  changeRequestInfo: createResourceSelectors.getChangeRequestInfo(state),
  changeRequestInfoSuggestions: createResourceSelectors.getChangeRequestInfoSuggestions(state),
  isChangeRequestInfoLoading: createResourceSelectors.isChangeRequestInfoLoading(state)
});

const mapDispatchToProps = dispatch => ({
  updateFormValue: (field, value) => dispatch(createResourceOperations.updateFormValue(field, value)),
  loadChangeRequestInfoSuggestions: term => dispatch(createResourceOperations.loadChangeRequestInfoSuggestions(term)),
  loadBuildingSuggestions: term => dispatch(createResourceOperations.loadBuildingSuggestions(term)),
  loadResourceSubTypes: () => dispatch(resourcesSubTypesOperations.loadResourcesSubTypes()),
  loadPeopleSuggestions: term => dispatch(createResourceOperations.loadPeopleSuggestions(term)),
  submit: () => dispatch(createResourceOperations.submit()),
  resetState: () => dispatch(createResourceOperations.resetState())
});

const CreateResourceComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateResourcePage);

export default withTranslation(withRouter(CreateResourceComponent));
