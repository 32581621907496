import debounce from 'debounce-promise';
import latest from 'promise-latest';
import config from '../config';
import fetch, { fetchBlob } from './fetch';

const getUrl = path => `${config.remoteUrl}/${path}`;

async function post(searchRequest) {
  const url = getUrl('bookings/search');
  return fetch(url, 'POST', searchRequest);
}

async function exportData(searchRequest) {
  const url = getUrl('bookings/search');
  return fetchBlob(url, 'POST', searchRequest);
}

export default {
  post: debounce(latest(post), 200, { leading: true }),
  exportData
};
