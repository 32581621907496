import React from 'react';
import PropTypes from 'prop-types';
import ValidationSuccess from './ValidationSuccess';
import ValidationError from './ValidationError';

import './_style.css';

function Validation({ message, type, visible, className }) {
  if (type === 'success') {
    return <ValidationSuccess message={message} visible={visible} className={className} />;
  }
  if (type === 'error') {
    return <ValidationError message={message} visible={visible} className={className} />;
  }
  return null;
}

Validation.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error']).isRequired,
  visible: PropTypes.bool,
  className: PropTypes.string
};

export default Validation;
