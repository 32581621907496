import config from '../config';
import fetch from './fetch';

const getUrl = path => `${ config.remoteUrl }/${ path }`;

async function get() {
  const url = getUrl('resourcesubtypes');
  return fetch(url, 'GET');
}

async function addResourcesSubType(payload) {
  const url = getUrl('resourcesubtypes/createResourceSubType');
  return fetch(url, 'POST', payload);
}

async function updateResourcesSubType(resourcesSubTypeId, payload) {
  const url = getUrl(`resourcesubtypes/updateResourceSubType/${resourcesSubTypeId}`);
  return fetch(url, 'PUT', payload);
}

async function remove(resourceSubTypeId) {
  const url = getUrl(`resourcesubtypes/deleteResourceSubType/${resourceSubTypeId}`);
  return fetch(url, 'DELETE');
}

export default {
  get,
  remove,
  addResourcesSubType,
  updateResourcesSubType
};
