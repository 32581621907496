import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/fp/isEqual';
import { Scrollbars } from 'react-custom-scrollbars-2';

import BookForm, {
  BookFormSubmit,
  BookFormProfile3,
  BookFormDate,
  BookFormTime,
  BookFormNotes,
  BookFormRecurrence,
  BookFormRecurrenceBookingCollectionInfo,
  BookFormProfile2,
  BookFormTimeWarning
} from '../BookForm';

import { BookDetailSidebar } from '../BookDetail';
import { CustomPropTypes, ResourceImages, Spacing } from '../../common';

import { isProfile2, isProfile3 } from '../../../util/profile';

import Header from '../../Header';
import SidebarChangeRequest from '../BookDetail/BookDetailSidebar/SidebarChangeRequest';
import SidebarSeatingPicker from '../BookDetail/BookDetailSidebar/SidebarSeatingPicker';
import BookFormCollection, {
  BookFormCollectionResources,
  BookFormCollectionPlaceholder
} from '../BookForm/BookFormCollection';

class BookCreateModalDesktop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showBookingCollectionTimeWarning: false,
      date: null,
      time: null
    };
  }

  UNSAFE_componentWillMount() {
    const { date, time } = this.props;
    this.setState({
      date,
      time
    });
  }

  renderBookForm = () => {
    const { isRecurrence } = this.props;

    return <BookForm {...this.props} recurrence={isRecurrence} />;
  };

  renderBookFormTime = () => {
    const { selectedResource, isBookingCollection } = this.props;
    const profile3 = isProfile3(selectedResource);

    if (profile3) {
      return null;
    }

    const onBlur = field => event => {
      // eslint-disable-next-line react/destructuring-assignment
      const didChange = !isEqual(this.state[field], event.target.value);
      if (isBookingCollection && didChange) {
        this.setState({
          showBookingCollectionTimeWarning: true
        });
      }
    };

    return (
      <>
        <BookFormDate onBlur={onBlur('date')} {...this.props} />
        <BookFormTime onBlur={onBlur('time')} {...this.props} />
        {this.renderBookFormTimeWarning()}
      </>
    );
  };

  renderBookFormTimeWarning = () => {
    const { showBookingCollectionTimeWarning } = this.state;
    const { isBookingCollection } = this.props;
    if (!isBookingCollection || !showBookingCollectionTimeWarning) {
      return null;
    }
    return <BookFormTimeWarning />;
  };

  renderBookFormRecurrence = () => {
    const { selectedResource, isRecurrence, isBookingCollection } = this.props;
    const profile3 = isProfile3(selectedResource);

    if (profile3) {
      return null;
    }

    if (isBookingCollection) {
      return <BookFormRecurrenceBookingCollectionInfo />;
    }

    return (
      <BookFormRecurrence
        recurrence={isRecurrence}
        resource={selectedResource}
        hasLoadedAvailabilities={selectedResource.availabilitiesLoaded}
      />
    );
  };

  renderBookFormCollectionResources = type => {
    const { collectionResources } = this.props;
    if (collectionResources[type].length <= 0) {
      return null;
    }
    return (
      <BookFormCollectionResources resources={collectionResources[type]} resourceType={type} />
    );
  };

  renderBookFormCollectionPlaceholder = () => {
    const { isRecurrence } = this.props;
    if (!isRecurrence) {
      return null;
    }
    return (
      <>
        <hr className="BookModal__Divider" />
        <div className="BookModal__Content">
          <BookFormCollectionPlaceholder />
        </div>
      </>
    );
  };

  renderBookFormCollection = type => {
    const { selectedResource, isRecurrence } = this.props;

    if (isRecurrence) {
      return null;
    }

    if (selectedResource.resourceType.toLowerCase() === 'equipment' && type === 'room') {
      return null;
    }
    return (
      <>
        <hr className="BookModal__Divider" style={{ marginBottom: 20 }} />
        <div className="BookModal__Content">
          <div className="BookModal__RoomCollection">
            <BookFormCollection resource={selectedResource} resourceType={type} />
          </div>
        </div>
        {this.renderBookFormCollectionResources(type)}
      </>
    );
  };

  renderBookFormProfile2 = () => {
    const { selectedResource } = this.props;
    const profile2 = isProfile2(selectedResource);

    if (!profile2) {
      return null;
    }

    return <BookFormProfile2 resource={selectedResource} />;
  };

  renderNotes = () => {
    const { selectedResource } = this.props;
    const { notes } = selectedResource;

    if (!notes) {
      return null;
    }

    return (
      <Spacing bottom="1">
        <BookFormNotes notes={notes} />
      </Spacing>
    );
  };

  renderBookFormSubmit = () => {
    const {
      selectedResource,
      openBookingCollectionDialog,
      showBookingCollectionWarning,
      createBooking,
      isReadyToSubmit,
      isSubmitting,
      update
    } = this.props;

    const profile2 = isProfile2(selectedResource);

    const props = {
      isReadyToSubmit,
      isSubmitting,
      profile2,
      update,
      onClick: showBookingCollectionWarning ? openBookingCollectionDialog : createBooking
    };

    return <BookFormSubmit {...props} />;
  };

  renderBody = () => {
    const { selectedResource } = this.props;
    const profile3 = isProfile3(selectedResource);

    if (profile3) {
      return <BookFormProfile3 resource={selectedResource} />;
    }

    return (
      <div className="BookModal__Body">
        <div className="BookModal__Content">{this.renderBookForm()}</div>
        <hr className="BookModal__Divider" style={{ marginTop: 20, marginBottom: 26 }} />
        <div className="BookModal__Content">{this.renderBookFormTime()}</div>
        <hr className="BookModal__Divider" style={{ marginTop: 20, marginBottom: 20 }} />
        <div className="BookModal__Content">
          <div className="BookModal__Recurrence">{this.renderBookFormRecurrence()}</div>
        </div>
        {this.renderBookFormCollection('equipment')}
        {this.renderBookFormCollection('room')}
        {this.renderBookFormCollectionPlaceholder()}
        <hr className="BookModal__Divider" style={{ marginBottom: 20 }} />
        <div className="BookModal__Content">{this.renderBookFormProfile2()}</div>
        <div className="BookModal__Content">{this.renderNotes()}</div>
        <div className="BookModal__Content">{this.renderBookFormSubmit()}</div>
      </div>
    );
  };

  render() {
    const { selectedResource, clearModals } = this.props;

    return (
      <>
        <Header
          title={selectedResource.displayName}
          onClose={clearModals}
          fixed={false}
          iconRight
          icon="022-close"
        />
        <div className="BookModalDesktop">
          <div className="BookModal__Main">
            <Scrollbars autoHide>{this.renderBody()}</Scrollbars>
          </div>
          <div className="BookModal__Sidebar">
            <Scrollbars autoHide>
              <ResourceImages resource={selectedResource} />
              <div className="BookModal__SidebarInfo">
                <BookDetailSidebar resource={selectedResource} />
                <SidebarSeatingPicker resource={selectedResource} />
                <SidebarChangeRequest resource={selectedResource} />
              </div>
            </Scrollbars>
          </div>
        </div>
      </>
    );
  }
}

BookCreateModalDesktop.propTypes = {
  selectedResource: PropTypes.shape({
    displayName: PropTypes.string,
    availabilitiesLoaded: PropTypes.any,
    resourceType: PropTypes.string,
    notes: PropTypes.any
  }),
  collectionResources: PropTypes.shape({
    room: PropTypes.arrayOf(CustomPropTypes.resource),
    equipment: PropTypes.arrayOf(CustomPropTypes.resource)
  }),
  date: PropTypes.string,
  time: PropTypes.shape(),
  clearModals: PropTypes.func.isRequired,
  isRecurrence: PropTypes.bool.isRequired,
  createBooking: PropTypes.func.isRequired,
  isReadyToSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isBookingCollection: PropTypes.bool.isRequired,
  showBookingCollectionWarning: PropTypes.bool.isRequired,
  openBookingCollectionDialog: PropTypes.func.isRequired,
  update: PropTypes.bool
};

export default BookCreateModalDesktop;
