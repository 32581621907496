/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog, withTranslation } from '../../../../common';
import { modalOperations } from '../../../../../state/modal';
import keys from './modalKeys';
import { buildingsSelectors } from '../../../../../state/buildings';
import Header from '../../../../Header';
import AddResourcesToMapButton from './Buttons/AddResourcesToMapButton';
import CancelResourcesToMapButton from './Buttons/CancelResourcesToMapButton';

function AddRoomResourceDialog({
  popDialog,
  selectedBuildingKey,
  selectedFloor,
  buildings,
  translate,
  addSelectedResourcesToMap }) {
  const building = buildings.find(b => b.key === selectedBuildingKey);

  // get all allocated Resources in all floors for the current building
  const allocatedResources = [];
  if (building && building.floors) {
    building.floors.forEach(floor => {
      if (floor.map.nodesJson) {
        const childNodes = JSON.parse(floor.map.nodesJson);
        childNodes.forEach(node => {
          if (node.type === 'ResourceNode' && allocatedResources.indexOf(node.id) === -1) {
            allocatedResources.push(node.id);
          }
        });
      }
    });
  }

  let asignableResources = building.resources
    .filter(resource => allocatedResources.indexOf(resource.id) === -1)
    // only rooms associated to current floor can be selected
    .filter(resource => resource.resourceType !== 'Room'
      || (resource.resourceType === 'Room' && resource.floor.id === selectedFloor.id));

  const selectedResources = useRef([]);
  const [disabledAddResource, setDisabledAddResource] = useState(true);
  const [availableResources, setAvailableResources] = useState(asignableResources);
  const [noResourcesMessage, setNoResourcesMessage] = useState(
    translate('admin.settings.floors.map.addDialog.noResourcesMessage')
);

  useEffect(() => {
    setAvailableResources(asignableResources);
  }, [selectedFloor.id]);

  const renderDialogContent = () => {
    const headerTitle = (
      <div style={{ display: 'flex' }}>
        <div style={{ fontSize: 40, fontWeight: 'normal' }}>+</div>
        <div>
          {translate('admin.settings.floors.map.addResourceNodeButtonLabel')}
        </div>
      </div>
    );

    const renderTableHeaderRow = () => {
      const columns = [
        '',
        translate('admin.settings.floors.map.addDialog.table.resourceType'),
        translate('admin.settings.floors.map.addDialog.table.name')
      ];

      return (
        <tr>
          {columns.map((value, index) => {
            if (index === 1) {
              return <th key={value} className="resource-type">{value}</th>;
            }
            return <th key={value}>{value}</th>;
          })}
        </tr>
      );
    };

    const changeSelectedResources = (resourceId, event) => {
      if (event.target.checked) {
        selectedResources.current.push(resourceId);
      } else {
        selectedResources.current = selectedResources.current.filter(i => i !== resourceId);
      }
      setDisabledAddResource(!(selectedResources.current.length > 0));
    };

    const renderRow = resource => (
      <tr key={resource.id}>
        <td className="check">
          <input type="checkbox" onClick={event => changeSelectedResources(resource.id, event)} />
        </td>
        <td className="resource-type" style={{ borderLeft: `${ resource.resourceSubType.color } 6px solid` }}>
          {resource.resourceType === 'Room' ? 'Room' : resource.resourceSubType.nameEN}
        </td>
        <td>{resource.name}</td>
      </tr>
    );

    const onAddResourcesToMap = () => {
      const resourcesToAdd = [];
      selectedResources.current.forEach(id => {
        const resource = availableResources.find(res => res.id === id);
        resourcesToAdd.push({
          id,
          color: resource.resourceSubType.color,
          width: resource.resourceSubType.width,
          height: resource.resourceSubType.height,
          resourceType: resource.resourceType === 'Room' ? 'Room' : resource.resourceSubType.nameEN,
          name: resource.name
        });
      });

      addSelectedResourcesToMap(resourcesToAdd);

      // remove the previously added resources from the availableResources array
      asignableResources = availableResources.filter(r => selectedResources.current.indexOf(r.id) === -1);

      popDialog();
      selectedResources.current = [];
      setAvailableResources(asignableResources);
      setDisabledAddResource(true);
      setNoResourcesMessage(translate('admin.settings.floors.map.addDialog.noResourcesMessage2'));
    };

    return (
      <div className="add-resource">
        <Header title={headerTitle} onClose={popDialog} icon="022-close" iconRight fixed={false} />

        {availableResources.length === 0
          ? <div>{noResourcesMessage}</div>
          : (
            <>
              <div>{translate('admin.settings.floors.map.addDialog.subTitle')}</div>
              <div className="table table--responsive">
                <div className="scrollable_table__wrapper">
                  <table>
                    <thead>{renderTableHeaderRow()}</thead>
                    <tbody>{availableResources.map(renderRow)}</tbody>
                  </table>
                </div>
              </div>

              <div className="Footer__buttons">
                <CancelResourcesToMapButton actionOnClick={popDialog} />
                <AddResourcesToMapButton actionOnClick={onAddResourcesToMap} disabled={disabledAddResource} />
              </div>
            </>
          )}

      </div>
    );
  };

  return (
    <Dialog dialogKey={keys.FLOORMAP_ADD_ROOM_RESOURCE_DIALOG} onOverlayClick={popDialog}>
      {renderDialogContent()}
    </Dialog>
  );
}

AddRoomResourceDialog.propTypes = {
  popDialog: PropTypes.func.isRequired,
  selectedBuildingKey: PropTypes.string.isRequired,
  selectedFloor: PropTypes.any,
  buildings: PropTypes.any,
  translate: PropTypes.func.isRequired,
  addSelectedResourcesToMap: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  buildings: buildingsSelectors.getBuildings(state)
});

const mapDispatchToProps = dispatch => ({
  popDialog: () => dispatch(modalOperations.popDialog()),
  clearModals: () => dispatch(modalOperations.clearModals())
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(AddRoomResourceDialog));
