import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  changePermissionsSelectors,
  changePermissionsOperations
} from '../../../../state/changePermissions';
import { Button, withTranslation } from '../../../common';

import PermissionsForm from './PermissionsForm';

import './_style.css';

class PermissionsPage extends Component {
  renderSubmitButton = () => {
    const { submit, isSubmitting, hasChanges, history, translate } = this.props;
    const send = translate('common.send');
    const onClick = event => {
      event.preventDefault();
      event.stopPropagation();
      submit().then(result => {
        if (result) {
          history.replace('/changerequests/my');
        }
      });
    };
    const disabled = isSubmitting || !hasChanges;
    return (
      <div className="PermissionsForm__Submit">
        <Button label={send} disabled={disabled} onClick={onClick} />
      </div>
    );
  };

  render() {
    return (
      <div>
        <PermissionsForm {...this.props} />
        {this.renderSubmitButton()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  owners: changePermissionsSelectors.getOwners(state),
  reason: changePermissionsSelectors.getReason(state),
  isOwnerLoading: changePermissionsSelectors.isOwnerLoading(state),
  ownerSuggestions: changePermissionsSelectors.getOwnerSuggestions(state),
  isSubmitted: changePermissionsSelectors.isSubmitted(state),
  isSubmitting: changePermissionsSelectors.isSubmitting(state),
  isValid: changePermissionsSelectors.isValid(state),
  hasChanges: changePermissionsSelectors.hasChanges(state)
});

const mapDispatchToProps = dispatch => ({
  loadOwnerSuggestions: term => dispatch(changePermissionsOperations.loadOwnerSuggestions(term)),
  updateFormValue: (field, value) => dispatch(changePermissionsOperations.updateFormValue(field, value)),
  submit: () => dispatch(changePermissionsOperations.submit())
});

PermissionsPage.propTypes = {
  owners: PropTypes.shape(),
  reason: PropTypes.shape(),
  isOwnerLoading: PropTypes.bool.isRequired,
  ownerSuggestions: PropTypes.arrayOf(PropTypes.shape()),
  loadOwnerSuggestions: PropTypes.func.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

const PermissionsPageComponent = withRouter(withTranslation(PermissionsPage));
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionsPageComponent);
