import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon, withTranslation } from '../../../common';
import { FormFieldReason, FormFieldReadOnly } from '../../FormElements';

import './_style.css';

class DeleteForm extends Component {
  renderInfo = () => {
    const { translate, resourceType } = this.props;
    if (!resourceType) {
      return null;
    }

    const text = translate(`changeRequests.delete.${resourceType}`);
    return (
      <div className="DeleteForm__Information">
        <Icon icon="046-information-circle" className="DeleteFormInformation__Icon" />
        {text}
      </div>
    );
  };

  renderReason = () => {
    const { reason, isSubmitted, readOnly, updateReason } = this.props;

    if (readOnly) {
      return <FormFieldReadOnly field="reason" values={{ new: reason, old: '' }} text={reason} />;
    }

    const update = (field, value) => updateReason(value);
    return <FormFieldReason value={reason} update={update} isSubmitted={isSubmitted} />;
  };

  renderValidation = () => {
    const { isSubmitted, isValid, translate } = this.props;
    if (isSubmitted) {
      if (!isValid) {
        const validationError = translate('common.validationError');
        return <div className="DeleteForm__ValidationError">{validationError}</div>;
      }
    }

    return null;
  };

  render() {
    return (
      <>
        {this.renderInfo()}
        {this.renderReason()}
        {this.renderValidation()}
      </>
    );
  }
}

DeleteForm.propTypes = {
  reason: PropTypes.string,
  resourceType: PropTypes.oneOf(['room', 'equipment']),
  isValid: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  updateReason: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

DeleteForm.defaultProps = {
  readOnly: false
};

export default withTranslation(DeleteForm);
