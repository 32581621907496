// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".News_body__jpLnW {\n  padding: 80px 20px 20px 20px;\n}\n\n.News_tableHead__iJAMO {\n  display: flex;\n}\n\n.News_tableHeadTitle__GZgaa {\n  width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  padding: 16px;\n  margin: 0;\n}\n\n.News_buttonNewNews__\\+eXjD {\n  height: 48px;\n}", "",{"version":3,"sources":["webpack://./src/views/Admin/News/News.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,YAAY;AACd","sourcesContent":[".body {\n  padding: 80px 20px 20px 20px;\n}\n\n.tableHead {\n  display: flex;\n}\n\n.tableHeadTitle {\n  width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  padding: 16px;\n  margin: 0;\n}\n\n.buttonNewNews {\n  height: 48px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "News_body__jpLnW",
	"tableHead": "News_tableHead__iJAMO",
	"tableHeadTitle": "News_tableHeadTitle__GZgaa",
	"buttonNewNews": "News_buttonNewNews__+eXjD"
};
export default ___CSS_LOADER_EXPORT___;
