import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import find from 'lodash/fp/find';

import { isProfile3 } from '../../../../util/profile';
import { bookOperations, bookSelectors } from '../../../../state/book';
import { withTranslation, CustomPropTypes, Radio, RadioGroup } from '../../../common';

import './_style.css';

class SidebarSeatingPicker extends Component {
  UNSAFE_componentWillMount() {
    const { updateSeatingId, resource, seating } = this.props;
    if (seating) {
      updateSeatingId(seating.id);
    } else if (resource && resource.profile !== 3) {
      const defaultSeating = find(['isDefault', true], resource.seatings);
      updateSeatingId(defaultSeating && defaultSeating.id);
    }
  }

  update = event => {
    const { updateSeatingId, resource } = this.props;
    const selectedSeating = find(['id', event.target.value], resource.seatings);
    updateSeatingId(selectedSeating && selectedSeating.id);
  };

  renderSeatingsRadioButtons = () => {
    const { resource } = this.props;
    return resource.seatings.map(seating => (
      <Radio label={seating.displayName} key={seating.id} value={seating.id} />
    ));
  };

  renderSeatingsList = () => {
    const { resource } = this.props;
    return resource.seatings.map(seating => <li key={seating.id}>{seating.displayName}</li>);
  };

  renderSeatings = () => {
    const { resource, selectedSeatingId } = this.props;

    const profile3 = isProfile3(resource);
    if (profile3) {
      return <ul>{this.renderSeatingsList()}</ul>;
    }

    return (
      <RadioGroup value={selectedSeatingId} onChange={this.update}>
        {this.renderSeatingsRadioButtons()}
      </RadioGroup>
    );
  };

  render() {
    const { translate, resource } = this.props;
    if (!resource.seatings || resource.seatings.length === 0) {
      return null;
    }

    return (
      <div className="SidebarSeatingPicker">
        <p className="SidebarSeatingPicker__Title">{translate('search.detail.seating')}</p>
        {this.renderSeatings()}
      </div>
    );
  }
}

SidebarSeatingPicker.propTypes = {
  resource: CustomPropTypes.resource.isRequired,
  seating: PropTypes.shape(),
  selectedSeatingId: PropTypes.string,
  updateSeatingId: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const mapStateToProps = (state, { seating }) => ({
  selectedSeatingId: bookSelectors.getSeatingId(state),
  seating: seating || bookSelectors.getSeating(state)
});

const mapDispatchToProps = (dispatch, { updateSeatingId }) => {
  const action = updateSeatingId || bookOperations.updateSeatingId;
  return {
    updateSeatingId: seatingId => dispatch(action(seatingId))
  };
};

const SidebarSeatingsComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarSeatingPicker);

export default withRouter(withTranslation(SidebarSeatingsComponent));
