import { createSelector } from 'reselect';
import { buildingsSelectors } from '../../buildings';

const areResourcesLoading = state => state.exceptionsState.resources.isLoading;
const getResources = state => state.exceptionsState.resources.resources;
const { getBuildings } = buildingsSelectors;

const getResourcesWithBuilding = createSelector(
  [getResources, getBuildings],
  (resources, buildings) => resources.map(resource => ({
    ...resource,
    building: buildings.find(building => building.id === resource.buildingId)
  }))
);

export default {
  getResources: getResourcesWithBuilding,
  areResourcesLoading
};
