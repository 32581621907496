import React from 'react';
import PropTypes from 'prop-types';

import { Favorite, CustomPropTypes, EquipmentType } from '../../../../common';
import Availability from '../Availability';

import './_style.css';

function Equipment({ resource, showRelativeTime, alternativeType }) {
  const { id, name, building, availabilities, resourceSubType } = resource;
  const showAlternativeTime = alternativeType === 'time';

  return (
    <div className="Equipment">
      <div className="Equipment__Header">
        <div className="Equipment__Name">{name}</div>
        <div className="Equipment__Favorite">
          <Favorite resourceId={id} tooltip={false} medium />
        </div>
      </div>
      <div className="Equipment__Body">
        <div className="Equipment__Building">{building.key}</div>
        <div className="Equipment__Time">
          <Availability
            availabilities={availabilities}
            showRelativeTime={showRelativeTime}
            showAlternativeTime={showAlternativeTime}
          />
        </div>
      </div>
      <div>
        <EquipmentType resourceSubType={resourceSubType} />
      </div>
    </div>
  );
}

Equipment.propTypes = {
  resource: CustomPropTypes.resource,
  showRelativeTime: PropTypes.bool.isRequired,
  alternativeType: PropTypes.string
};

Equipment.defaultProps = {
  alternativeType: ''
};

export default Equipment;
