// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WelcomeImage_modal__o6tuy {\n  margin-top: 10px;\n  margin-bottom: 14px;\n  width: 130px;\n  height: 130px;\n}\n\n.WelcomeImage_dialog__BNJaT {\n  margin-top: -14px;\n  margin-left: -14px;\n}\n", "",{"version":3,"sources":["webpack://./src/views/Welcome/WelcomeImage.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".modal {\n  margin-top: 10px;\n  margin-bottom: 14px;\n  width: 130px;\n  height: 130px;\n}\n\n.dialog {\n  margin-top: -14px;\n  margin-left: -14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "WelcomeImage_modal__o6tuy",
	"dialog": "WelcomeImage_dialog__BNJaT"
};
export default ___CSS_LOADER_EXPORT___;
