import React from 'react';
import PropTypes from 'prop-types';

import { Translate } from '../../../common';
import SidebarFormField from './SidebarFormField';

function SidebarRoomSize({ roomSize }) {
  const element = <Translate path="common.persons" count={roomSize} />;
  return <SidebarFormField label="common.size" element={element} />;
}

SidebarRoomSize.propTypes = {
  roomSize: PropTypes.number
};

export default SidebarRoomSize;
