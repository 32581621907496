import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../common';
import NewResourceButton from './NewResourceButton';

function MyChangeRequestsEmptyState({ translate }) {
  const title = translate('changeRequests.my.empty.title');
  const newResource = translate('changeRequests.my.empty.new');
  const detail = translate('changeRequests.my.empty.detail');
  const detailLink = translate('changeRequests.my.empty.detailLink');
  return (
    <div className="MyChangeRequests__EmptyState">
      <div className="MyChangeRequestsEmptyState__Title">
        <h4>{title}</h4>
      </div>

      <div className="MyChangeRequestsEmptyState__Body">
        <div className="MyChangeRequestsEmptyState__Panel">
          <div className="MyChangeRequestsEmptyState__PanelContent">
            <div className="MyChangeRequestsEmptyState__Section">{newResource}</div>
            <div className="MyChangeRequestsEmptyState__Section">
              <NewResourceButton />
            </div>
          </div>
        </div>

        <div className="MyChangeRequestsEmptyState__Panel">
          <div className="MyChangeRequestsEmptyState__PanelContent">
            <div className="MyChangeRequestsEmptyState__Section">{detail}</div>
            <div className="MyChangeRequestsEmptyState__Section">{detailLink}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

MyChangeRequestsEmptyState.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslation(MyChangeRequestsEmptyState);
