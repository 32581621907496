import React from 'react';
import PropTypes from 'prop-types';

import { SelectBox, withTranslation } from '../../common';

function WeekSelectBoxes({ week, toggleWeek, translate }) {
  const weekNumbers = [
    { week: 'first' },
    { week: 'second' },
    { week: 'third' },
    { week: 'fourth' },
    { week: 'last' }
  ];

  const options = weekNumbers.map(x => ({
    ...x,
    label: translate(`common.ordinals.number.${x.week}`),
    selected: week === x.week
  }));

  return (
    <div className="WeekSelectBoxes">
      {options.map(option => {
        const onClick = () => toggleWeek(option.week);
        const props = {
          key: option.week,
          onClick,
          selected: option.selected,
          className: 'WeekSelectBox'
        };
        return <SelectBox {...props}>{option.label}</SelectBox>;
      })}
    </div>
  );
}

WeekSelectBoxes.propTypes = {
  week: PropTypes.string.isRequired,
  toggleWeek: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(WeekSelectBoxes);
