import React from 'react';
import PropTypes from 'prop-types';

import Loader from './Loader';

function InlineLoader({ visible, text, wrapperClassName, className }) {
  if (!visible) {
    return null;
  }

  return (
    <div className={wrapperClassName}>
      <Loader visible size="small" className={className} />
      <div>{text}</div>
    </div>
  );
}

InlineLoader.propTypes = {
  visible: PropTypes.bool,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string
};

InlineLoader.defaultProps = {
  visible: true
};

export default InlineLoader;
