import types from '../types';

const initialState = {
  resources: [],
  isLoading: false
};

const resourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_RESOURCES: {
      return { ...state, resources: action.resources };
    }

    case types.UPDATE_RESOURCES_LOADING: {
      return { ...state, isLoading: action.loading };
    }

    default:
      return state;
  }
};

export default resourcesReducer;
