import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ShowAt, CustomPropTypes } from '../../../common';
import RoomCardDesktop from '../../../Search/SearchResults/SearchResultCard/RoomCard/RoomCardDesktop';
import RoomCardMobile from '../../../Search/SearchResults/SearchResultCard/RoomCard/RoomCardMobile';
import EquipmentCardDesktop from '../../../Search/SearchResults/SearchResultCard/EquipmentCard/EquipmentCardDesktop';
import EquipmentCardMobile from '../../../Search/SearchResults/SearchResultCard/EquipmentCard/EquipmentCardMobile';

import './_style.css';

function AlternativeSearchResult({ resource, alternativeType, selected, onSelect }) {
  const { resourceType } = resource;
  const isEquipmentCard = resourceType === 'Equipment';

  const props = {
    isProfile2: false,
    isProfile3: false,
    showTimeInput: false,
    showRelativeTime: false,
    selected: false,
    resource,
    alternativeType
  };

  const classes = cx('AlternativeSearchResult', {
    AlternativeSearchResult__Arrow: selected
  });

  const renderEquipmentCard = () => (
    <>
      <ShowAt breakpoint="1000AndAbove">
        <EquipmentCardDesktop {...props} />
      </ShowAt>
      <ShowAt breakpoint="1000AndBelow">
        <EquipmentCardMobile {...props} />
      </ShowAt>
    </>
  );

  const renderRoomCard = () => (
    <>
      <ShowAt breakpoint="1000AndAbove">
        <RoomCardDesktop {...props} />
      </ShowAt>
      <ShowAt breakpoint="1000AndBelow">
        <RoomCardMobile {...props} />
      </ShowAt>
    </>
  );

  return (
    <div tabIndex="0" role="button" className={classes} onClick={onSelect}>
      {isEquipmentCard ? renderEquipmentCard() : renderRoomCard()}
    </div>
  );
}

AlternativeSearchResult.propTypes = {
  resource: CustomPropTypes.resource,
  selected: PropTypes.bool,
  alternativeType: PropTypes.oneOf(['floor', 'sector', 'time', 'roomequipment', 'roomtype', 'capacity']),
  onSelect: PropTypes.func
};

export default AlternativeSearchResult;
