import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, withTranslation } from '../../common';
import AlarmClockIcon from '../../common/Icon/AlarmClockIcon';

function BookFormReminder({ reminder, updateReminder, translate }) {
  const onChange = event => {
    updateReminder(event.target.checked);
  };

  const label = translate('search.detail.reminder');

  return (
    <div className="BookFormReminder">
      <div className="BookFormReminder__Icon">
        <AlarmClockIcon className="BookFormReminderAlarmClockIcon" />
      </div>
      <Checkbox label={label} checked={reminder} onChange={onChange} />
    </div>
  );
}

BookFormReminder.propTypes = {
  reminder: PropTypes.bool.isRequired,
  updateReminder: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookFormReminder);
