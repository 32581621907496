import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Input, TextArea, withTranslation } from '../../../common';

import styles from './NewsFormInputGroup.module.css';

class NewsFormInputGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      titleValue: props.value.title,
      textValue: props.value.text
    };
  }

  onChangeTitle = async event => {
    await this.setState({
      titleValue: event.target.value
    });
    this.onChange();
  };

  onChangeText = async event => {
    await this.setState({
      textValue: event.target.value
    });
    this.onChange();
  };

  onChange() {
    const { onChange } = this.props;
    const { titleValue, textValue } = this.state;
    if (onChange) {
      onChange({
        title: titleValue,
        text: textValue
      });
    }
  }

  render() {
    const { heading, translate, isRequired } = this.props;
    const { titleValue, textValue } = this.state;

    const showWarning = isRequired && (!titleValue || !textValue);
    const showTextOrTitleToLongWarning = (titleValue.length > 500 || textValue.length > 1500);

    const headingText = isRequired && showWarning
      ? `${heading} ${translate('common.requiredField')}`
      : heading;

    return (
      <div className={styles.body}>
        <div className={styles.heading}>{headingText}</div>
        <div className={styles.input}>
          <Input
            placeholder={translate('admin.news.form.placeholder.title')}
            onChange={this.onChangeTitle}
            value={titleValue}
          />
        </div>
        <div>
          <TextArea
            minRows={3}
            maxRows={5}
            fixed
            type="text"
            placeholder={translate('admin.news.form.placeholder.text')}
            value={textValue}
            className="MaintenanceFormInput__Input"
            onChange={this.onChangeText}
          />
        </div>
        {showWarning && <div className={styles.requiredText}>{translate('admin.news.form.required')}</div>}
        {showTextOrTitleToLongWarning
          && <div className={styles.requiredText}>{translate('admin.news.form.overflow')}</div>}
      </div>
    );
  }
}

NewsFormInputGroup.propTypes = {
  heading: PropTypes.string,
  value: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string
  }),
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
  translate: PropTypes.func.isRequired
};

NewsFormInputGroup.defaultProps = {
  heading: 'heading',
  value: {
    title: '',
    text: ''
  },
  isRequired: false
};

export default withTranslation(NewsFormInputGroup);
