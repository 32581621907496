import React, { Component } from 'react';
import cx from 'classnames';

import { Favorite, CustomPropTypes } from '../../../../../common';
import CalendarEquipmentTooltip from './CalendarEquipmentTooltip';

import './_style.css';

class CalendarEquipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTooltip: false
    };
  }

  activateTooltip = () => {
    const { activeTooltip } = this.state;
    if (!activeTooltip) {
      this.setState({
        activeTooltip: true
      });
    }
  };

  deactivateTooltip = () => {
    const { activeTooltip } = this.state;
    if (activeTooltip) {
      this.setState({
        activeTooltip: false
      });
    }
  };

  render() {
    const { equipment } = this.props;
    const { activeTooltip } = this.state;
    const { id, displayName, building, disabled } = equipment;

    const tooltipId = 'CalendarEquipmentTooltip';

    const props = {
      className: cx(
'CalendarEquipment',
        {
          'CalendarEquipment--disabled': disabled
        }
),
      onMouseEnter: this.activateTooltip,
      onMouseLeave: this.deactivateTooltip
    };

    const tooltipProps = {
      id: tooltipId,
      equipment,
      active: activeTooltip
    };

    return (
      <div>
        <CalendarEquipmentTooltip {...tooltipProps} />
        <div data-tip data-for={tooltipId} {...props}>
          <div className="CalendarEquipment__Info">
            <span className="CalendarEquipment__Name">{displayName}</span>
            <span className="CalendarEquipment__Building">{building.key}</span>
          </div>
          <div className="CalendarEquipment__Favorite">
            <Favorite small resourceId={id} />
          </div>
        </div>
      </div>
    );
  }
}

CalendarEquipment.propTypes = {
  equipment: CustomPropTypes.equipment
};

export default CalendarEquipment;
