import types from './types';

const toggleFilter = item => ({
  type: types.TOGGLE_FILTER,
  key: item.key
});

const changeFilter = name => ({
  type: types.CHANGE_FILTER,
  name
});

const changeFloors = (namespace, floors) => ({
  type: types.UPDATE_FLOORS,
  namespace,
  floors
});

const changeSectors = (namespace, sectors) => ({
  type: types.UPDATE_SECTORS,
  namespace,
  sectors
});

const resetDirty = () => ({
  type: types.RESET_DIRTY
});

const resetFilter = namespace => ({
  type: types.RESET_FILTER,
  namespace
});

const updateRoomTypes = (namespace, resourcesSubTypes) => ({
  type: types.UPDATE_ROOMTYPES,
  namespace,
  resourcesSubTypes
});

export default {
  toggleFilter,
  changeFilter,
  changeFloors,
  changeSectors,
  resetDirty,
  resetFilter,
  updateRoomTypes
};
