import actions from './actions';
import selectors from './selectors';
import { globalOperations } from '../global';

const { setOffset, removeModal, setFakeModalState } = actions;

const pushModal = modalKey => (dispatch, getState) => {
  const state = getState();

  const isModalActive = selectors.getModalHistory(state).length > 0;
  if (!isModalActive) {
    const scrollOffset = window.pageYOffset || document.documentElement.scrollTop;
    dispatch(setOffset(scrollOffset));
    dispatch(globalOperations.disableScrolling());
  }

  dispatch(actions.pushModal(modalKey));
};

const popModal = () => (dispatch, getState) => {
  dispatch(actions.popModal());
  const state = getState();

  const isModalActive = selectors.getModalHistory(state).length > 0;
  if (!isModalActive) {
    const lastOffset = selectors.getOffset(state);
    window.scrollTo(0, lastOffset);
    dispatch(globalOperations.enableScrolling());
  }
};

const clearModals = () => (dispatch, getState) => {
  const state = getState();

  dispatch(globalOperations.enableScrolling());
  dispatch(actions.clearModals());
  const lastOffset = selectors.getOffset(state);
  window.scrollTo(0, lastOffset);
};

export default {
  pushModal,
  pushDialog: pushModal,
  popModal,
  popDialog: popModal,
  clearModals,
  clearDialogs: clearModals,
  removeModal,
  removeDialog: removeModal,
  setOffset,
  setFakeModalState
};
