import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../../../common';

import styles from './ResourcesSubTypes.module.css';

function ResourcesSubTypesLoader({ visible }) {
  if (!visible) {
    return null;
  }

  return (
    <div className={styles.ResourcesSubType__Loader}>
      <Loader visible />
    </div>
  );
}

ResourcesSubTypesLoader.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default ResourcesSubTypesLoader;
