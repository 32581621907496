import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes } from '../../common';
import RecurrenceExceptionModalHeader from './RecurrenceExceptionModalHeader';
import RecurrenceExceptionModalLoader from './RecurrenceExceptionModalLoader';
import RecurrenceExceptionModalEmptyState from './RecurrenceExceptionModalEmptyState';
import AlternativeSearchResults from './AlternativeSearchResults';

import '../_style.css';

function RecurrenceExceptionModalMobile(props) {
  const {
    exception,
    resources,
    isLastException,
    isLoading,
    isInProgress,
    showEmptyState,
    onClose,
    onNext,
    onSelect,
    onBook
  } = props;

  const { start } = exception;
  return (
    <div className="RecurrenceExceptionModalMobile">
      <RecurrenceExceptionModalHeader isMobile date={start} onClose={onClose} />

      <div className="BookModalMobile RecurrenceExceptionModalMobile__Content">
        <RecurrenceExceptionModalLoader visible={isLoading} />
        <RecurrenceExceptionModalEmptyState
          visible={showEmptyState}
          onClick={onNext}
          isLastException={isLastException}
        />

        {resources.length > 0 && (
          <AlternativeSearchResults
            isMobile
            isInProgress={isInProgress}
            isLastException={isLastException}
            resources={resources}
            onSelect={onSelect}
            onBook={onBook}
          />
        )}
      </div>
    </div>
  );
}

RecurrenceExceptionModalMobile.propTypes = {
  exception: CustomPropTypes.exception,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      resource: CustomPropTypes.resource,
      selected: PropTypes.bool
    })
  ),
  isLastException: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  showEmptyState: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onBook: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default RecurrenceExceptionModalMobile;
