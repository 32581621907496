const UPDATE_FORM_VALUE = 'createResource/UPDATE_FORM_VALUE';
const UPDATE_SUBMITTED = 'createResource/UPDATE_SUBMITTED';
const UPDATE_IS_SUBMITTING = 'createResource/UPDATE_IS_SUBMITTING';

const UPDATE_PEOPLE_ISLOADING = 'createResource/UPDATE_PEOPLE_ISLOADING';
const UPDATE_PEOPLE_SUGGESTIONS = 'createResource/UPDATE_PEOPLE_SUGGESTIONS';

const UPDATE_BUILDING_ISLOADING = 'createResource/UPDATE_BUILDING_ISLOADING';
const UPDATE_BUILDING_SUGGESTIONS = 'createResource/UPDATE_BUILDING_SUGGESTIONS';

const UPDATE_CHANGEREQUESTINFO_ISLOADING = 'createResource/UPDATE_CHANGEREQUESTINFO_ISLOADING';
const UPDATE_CHANGEREQUESTINFO_SUGGESTIONS = 'createResource/UPDATE_CHANGEREQUESTINFO_SUGGESTIONS';

const RESET_STATE = 'createResource/RESET_STATE';

const INITIALIZE_DETAIL = 'createResource/INITIALIZE_DETAIL';

export default {
  UPDATE_FORM_VALUE,
  UPDATE_BUILDING_ISLOADING,
  UPDATE_BUILDING_SUGGESTIONS,
  UPDATE_CHANGEREQUESTINFO_ISLOADING,
  UPDATE_CHANGEREQUESTINFO_SUGGESTIONS,
  UPDATE_PEOPLE_ISLOADING,
  UPDATE_PEOPLE_SUGGESTIONS,
  UPDATE_SUBMITTED,
  UPDATE_IS_SUBMITTING,
  RESET_STATE,
  INITIALIZE_DETAIL
};
