import { combineReducers } from 'redux';
import store from 'store2';

import types from './types';

const loadInitialState = () => {
  const buildings = store('buildingsData');
  const isLoaded = store.has('buildingsData');
  return {
    buildings,
    isLoaded
  };
};

const initialState = loadInitialState();

const buildingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_BUILDINGS: {
      const buildings = action.buildings.filter(building => building.key !== null && building.key !== ''
        && building.name !== null
        && building.name !== '');

      return { ...state, buildings };
    }

    case types.UPDATE_LOADED: {
      return {
        ...state,
        isLoaded: true,
        age: action.age
      };
    }

    case types.ADD_FLOORS_TO_BUILDING: {
      const newState = { ...state };
      const currentBuilding = newState.buildings.filter(x => x.key === action.buildingKey)[0];
      currentBuilding.floors = action.floors;

      return newState;
    }

    case types.SET_SELECTED_BUILDING: {
      return {
        ...state,
        selectedBuilding: action.selectedBuilding
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  buildingsData: buildingsReducer
});
