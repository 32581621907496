const UPDATE_SELECTED_RESOURCE = 'book/UPDATE_SELECTED_RESOURCE';
const UPDATE_HAS_SUCCEEDED = 'book/UPDATE_HAS_SUCCEEDED';
const UPDATE_HAS_SYSTEM_ERROR = 'book/UPDATE_HAS_SYSTEM_ERROR';
const UPDATE_HAS_VALIDATION_ERROR = 'book/UPDATE_HAS_VALIDATION_ERROR';
const UPDATE_IS_SUBMITTING = 'book/UPDATE_IS_SUBMITTING';
const UPDATE_IS_VALIDTIMERANGE = 'book/UPDATE_IS_VALIDTIMERANGE';
const UPDATE_DATE = 'book/UPDATE_DATE';
const UPDATE_TIME = 'book/UPDATE_TIME';
const UPDATE_MESSAGE = 'book/UPDATE_MESSAGE';
const UPDATE_SUBJECT = 'book/UPDATE_SUBJECT';
const UPDATE_REMINDER = 'book/UPDATE_REMINDER';
const RESET_STATE = 'book/RESET_STATE';
const LOAD_BOOKING = 'book/LOAD_DETAILBOOKING';
const CACHE_STATE = 'book/CACHE_STATE';
const RESET_STATE_FROM_CACHE = 'book/RESET_STATE_FROM_CACHE';
const UPDATE_SELECTED_BOOKING = 'book/UPDATE_SELECTED_BOOKING';
const UPDATE_SEATING_ID = 'book/UPDATE_SEATING_ID';

export default {
  UPDATE_SELECTED_RESOURCE,
  UPDATE_HAS_SUCCEEDED,
  UPDATE_HAS_SYSTEM_ERROR,
  UPDATE_HAS_VALIDATION_ERROR,
  UPDATE_IS_SUBMITTING,
  UPDATE_IS_VALIDTIMERANGE,
  UPDATE_DATE,
  UPDATE_TIME,
  UPDATE_MESSAGE,
  UPDATE_SUBJECT,
  UPDATE_REMINDER,
  RESET_STATE,
  LOAD_BOOKING,
  CACHE_STATE,
  RESET_STATE_FROM_CACHE,
  UPDATE_SELECTED_BOOKING,
  UPDATE_SEATING_ID
};
