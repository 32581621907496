import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import BookForm, {
  BookFormSubmit,
  BookFormProfile3,
  BookFormProfile2,
  BookFormNotes,
  BookFormTime,
  BookFormDate
} from '../BookForm';
import { BookDetailSidebar } from '../BookDetail';

import Header from '../../Header';

import { ResourceImages, Spacing } from '../../common';
import { isProfile2, isProfile3 } from '../../../util';
import SidebarChangeRequest from '../BookDetail/BookDetailSidebar/SidebarChangeRequest';
import SidebarSeatingPicker from '../BookDetail/BookDetailSidebar/SidebarSeatingPicker';

class BookUpdateModalDesktop extends Component {
  renderBookForm = () => (
    <div>
      <BookForm update {...this.props} recurrence={false} />
    </div>
  );

  renderBookFormTime = () => (
    <>
      <BookFormDate {...this.props} />
      <BookFormTime {...this.props} />
    </>
  );

  renderBookFormProfile2 = () => {
    const { selectedResource } = this.props;
    const profile2 = isProfile2(selectedResource);

    if (!profile2) {
      return null;
    }

    return <BookFormProfile2 resource={selectedResource} />;
  };

  renderNotes = () => {
    const { selectedResource } = this.props;
    const { notes } = selectedResource;

    if (!notes) {
      return null;
    }

    return (
      <Spacing bottom="1">
        <BookFormNotes notes={notes} />
      </Spacing>
    );
  };

  renderBookFormSubmit = () => {
    const { selectedResource, updateBooking, isReadyToSubmit, isSubmitting } = this.props;
    const profile2 = isProfile2(selectedResource);

    return (
      <BookFormSubmit
        onClick={updateBooking}
        isReadyToSubmit={isReadyToSubmit}
        isSubmitting={isSubmitting}
        profile2={profile2}
        update
      />
    );
  };

  renderBody = () => {
    const { selectedResource } = this.props;
    const profile3 = isProfile3(selectedResource);

    if (profile3) {
      return <BookFormProfile3 resource={selectedResource} />;
    }

    return (
      <div className="BookModal__Body">
        <div className="BookModal__Content">{this.renderBookForm()}</div>
        <hr className="BookModal__Divider" style={{ marginTop: 20, marginBottom: 26 }} />
        <div className="BookModal__Content">{this.renderBookFormTime()}</div>
        <hr className="BookModal__Divider" style={{ marginTop: 20, marginBottom: 20 }} />
        <div className="BookModal__Content">
          {this.renderBookFormProfile2()}
          {this.renderNotes()}
          {this.renderBookFormSubmit()}
        </div>
      </div>
    );
  };

  render() {
    const { selectedResource, goBackToDetail, seating } = this.props;

    return (
      <>
        <Header
          title={selectedResource.displayName}
          onClose={goBackToDetail}
          fixed={false}
          icon="014-chevron-left"
        />
        <div className="BookModalDesktop">
          <div className="BookModal__Main">
            <Scrollbars autoHide>{this.renderBody()}</Scrollbars>
          </div>
          <div className="BookModal__Sidebar">
            <Scrollbars autoHide>
              <ResourceImages resource={selectedResource} />
              <div className="BookModal__SidebarInfo">
                <BookDetailSidebar resource={selectedResource} seating={seating} />
                <SidebarSeatingPicker resource={selectedResource} />
                <SidebarChangeRequest resource={selectedResource} />
              </div>
            </Scrollbars>
          </div>
        </div>
      </>
    );
  }
}

BookUpdateModalDesktop.propTypes = {
  selectedResource: PropTypes.shape({
    displayName: PropTypes.string,
    notes: PropTypes.any
  }),
  goBackToDetail: PropTypes.func.isRequired,
  updateBooking: PropTypes.func.isRequired,
  isReadyToSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  seating: PropTypes.shape()
};

export default BookUpdateModalDesktop;
