/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */

import './_styles.css';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactFlow, {
  ReactFlowProvider,
  Background,
  Controls,
  useReactFlow
} from 'reactflow';
import { withTranslation } from '../../../../common';
import 'reactflow/dist/style.css';
import FloorNode, { getFloorNode } from './Nodes/FloorNode';
import BlockNode, { getBlockNode } from './Nodes/BlockNode';
import ResourceNode, { getResourceNode } from './Nodes/ResourceNode';
import { floorsOperations } from '../../../../../state/floors';
import SaveFloorMapButton from './SaveFloorMapButton';
import AddResourceNodeButton from './AddResourceNodeButton';
import AddBlockNodeButton from './AddBlockNodeButton';
import { notificationsOperations } from '../../../../../state/notifications';
import utils from './utils';
import AddRoomResourceDialog from './AddRoomResourceDialog';
import { modalOperations } from '../../../../../state/modal';
import modalKeys from './modalKeys';

function FloorsMaps({
  style, selectedFloor, onChangedMap, childNodes,
  openAddRoomResourceDialog, selectedBuildingKey }) {
  const proOptions = { hideAttribution: true };
  const reactFlowInstance = useReactFlow();
  const { getIntersectingNodes, getNodes, addNodes, setNodes, getNode, deleteElements } = reactFlowInstance;
  const {
    areFloormapBoundsValid,
    getFloorMapResizingNodeBounds,
    getChildNodesAbsoluteBounds,
    updateFloorMap,
    setNodeToPreviousState,
    checkCollisionsAndUpdateState,
    calculateCenterMap,
    calculateNearestPosition,
    overlaps
  } = utils(reactFlowInstance, onChangedMap);

  const nodeTypes = {
    FloorNode,
    BlockNode,
    ResourceNode
  };
  let nodeId = 0;
  let draggedNodeStart = null;
  let resizedNodeStart = null;
  let childNodesAbsoluteBounds = null;
  let nodePreviousState = null;
  const debug = false;

  // Floormap Events
  // ------------------------------------------------------------
  const onFloorMapResizeStart = (evt, data) => {
    childNodesAbsoluteBounds = getChildNodesAbsoluteBounds();
  };

  const shouldFloorMapResize = (evt, data) => {
    const resizingFloorMapBounds = getFloorMapResizingNodeBounds(data);
    const validMapBounds = areFloormapBoundsValid(resizingFloorMapBounds, childNodesAbsoluteBounds);
    return validMapBounds;
  };

  const onFloorMapResize = (evt, data) => {
    setNodes(nodes => nodes.map(n => {
      if (n.id !== 'floorMap') {
        n.position = {
          x: Math.round(n.positionAbsolute.x - data.x),
          y: Math.round(n.positionAbsolute.y - data.y)
        };
        if (debug) {
          n.data.position = `p:(${ n.position.x },${ n.position.y })`;
          n.position.x += 0.001; // to force re-rendering;
        }
      }
      if (n.id === 'floorMap' && debug) {
        n.position.x = data.x + 0.001; // to force re-rendering (ONLY x,y changes force a re-rendering)
        n.data.position = `(${ data.x },${ data.y })`;
        n.data.width = `${ data.width }`;
        n.data.height = `${ data.height }`;
      }
      return n;
    }));
  };

  const onFloorMapResizeEnd = (evt, data) => {
    if (debug) {
      setNodes(nodes => nodes.map(n => {
        if (n.id === 'floorMap') {
          n.position.x = Math.round(n.position.x); // restore the true x
        } else {
          n.position.x = Math.round(n.position.x); // restore the true x
        }
        return n;
      }));
    }
    updateFloorMap();
  };

  // Nodes Events
  // -----------------------------------------------------------------------
  // Dragging
  const onNodeDragStart = (evt, draggedNode) => {
    draggedNodeStart = draggedNode;
  };

  const onNodeDrag = (evt, node) => {
    const collisions = getIntersectingNodes(node).filter(n => n.id !== 'floorMap').length > 0;
    if (collisions) {
      setNodeToPreviousState(node, nodePreviousState);
    } else {
      if (debug) {
        setNodes(nodes => nodes.map(n => {
          if (n.id === node.id) {
            n.data.position = `p:(${ n.position.x },${ n.position.y })`;
            n.data.positionAbsolute = `a:(${ n.positionAbsolute.x },${ n.positionAbsolute.y })`;
          }
          return n;
        }));
      }
      nodePreviousState = node;
    }
  };

  const onNodeDragStop = (evt, draggedNode) => {
    checkCollisionsAndUpdateState(draggedNode, draggedNodeStart);
    nodePreviousState = null;
  };

  // Resizing
  const onBlockNodeResizeStart = (evt, resizedNodeData) => {
    resizedNodeStart = getNodes() // eslint-disable-line
      .filter(n => n.position.x === resizedNodeData.x && n.position.y === resizedNodeData.y)[0];
  };

  const shouldBlockNodeResize = (evt, data) => {
    let shouldResize = true;
    let intersected = false;

    const ns = resizedNodeStart;
    const resizingRectangle = {
      left: ns.positionAbsolute.x + (data.x - ns.position.x),
      top: ns.positionAbsolute.y + (data.y - ns.position.y),
      right: (ns.positionAbsolute.x + (data.x - ns.position.x)) + data.width,
      bottom: (ns.positionAbsolute.y + (data.y - ns.position.y)) + data.height
    };

    // Check intersection with other nodes
    getNodes().forEach(n => {
      if (n.id !== 'floorMap' && n.id !== resizedNodeStart.id) {
        if (!intersected) {
          const nRectangle = {
            left: n.positionAbsolute.x,
            top: n.positionAbsolute.y,
            right: n.positionAbsolute.x + n.width,
            bottom: n.positionAbsolute.y + n.height
          };

          intersected = overlaps(resizingRectangle, nRectangle);
        }
      }
    });

    // Check intersection with floorMap Boundaries
    if (!intersected) {
      const floorMap = getNode('floorMap');
      intersected = resizingRectangle.left < floorMap.positionAbsolute.x
        || resizingRectangle.top < floorMap.positionAbsolute.y
        || resizingRectangle.right > floorMap.positionAbsolute.x + floorMap.width
        || resizingRectangle.bottom > floorMap.positionAbsolute.y + floorMap.height;
    }

    shouldResize = !intersected;
    return shouldResize;
  };

  const onBlockNodeResize = (evt, data) => {
    if (debug) {
      setNodes(nodes => nodes.map(n => {
        if (n.id === resizedNodeStart.id) {
          n.data.position = `p:(${ n.position.x },${ n.position.y })`;
          n.data.positionAbsolute = `a:(${ n.positionAbsolute.x },${ n.positionAbsolute.y })`;
        }
        return n;
      }));
    }
  };

  const onBlockNodeResizeEnd = (evt, data) => {
    updateFloorMap();
  };

  // Add nodes
  // ------------------------------
  const addBlock = () => {
    try {
      const blockNode = getBlockNode();
      blockNode.id = `${ nodeId += 1 }`;
      blockNode.parentNode = 'floorMap';
      blockNode.data = {
        onResizeStart: onBlockNodeResizeStart,
        shouldResize: shouldBlockNodeResize,
        onResize: onBlockNodeResize,
        onResizeEnd: onBlockNodeResizeEnd
      };

      const currentJsonNodes = getNodes().filter(n => n.id !== 'floorMap');
      blockNode.position = calculateNearestPosition(calculateCenterMap(), currentJsonNodes, getBlockNode());

      if (debug) {
        const { x, y } = getNode('floorMap').position;
        blockNode.data = {
          ...blockNode.data,
          debug,
          position: `p:(${ blockNode.position.x },${ blockNode.position.y })`,
          positionAbsolute: `a:(${ blockNode.position.x + x },${ blockNode.position.y + y })`
        };
      }

      addNodes(blockNode);
      jsonNodes.push({ ...blockNode });
      onChangedMap({ childNodes: jsonNodes });
    } catch (error) {
      onChangedMap({ message: error.message });
    }
  };

  const addResource = resource => {
    try {
      const resourceNode = getResourceNode();
      resourceNode.id = resource.id;
      resourceNode.width = resource.width;
      resourceNode.height = resource.height;
      resourceNode.style.width = resource.width;
      resourceNode.style.height = resource.height;
      resourceNode.data = {
        ...resourceNode.data,
        name: resource.name,
        resourceType: resource.resourceType
      };

      resourceNode.parentNode = 'floorMap';
      const currentJsonNodes = getNodes().filter(n => n.id !== 'floorMap');
      resourceNode.position = calculateNearestPosition(calculateCenterMap(), currentJsonNodes, resourceNode);

      if (debug) {
        const { x, y } = getNode('floorMap').position;
        resourceNode.data = {
          ...resourceNode.data,
          debug,
          position: `p:(${ resourceNode.position.x },${ resourceNode.position.y })`,
          positionAbsolute: `a:(${ resourceNode.position.x + x },${ resourceNode.position.y + y })`
        };
      }

      addNodes(resourceNode);
      jsonNodes.push({ ...resourceNode });
      onChangedMap({ childNodes: jsonNodes });
    } catch (error) {
      onChangedMap({ message: error.message });
    }
  };

  const openAddRoomResourceModalDialog = () => {
    openAddRoomResourceDialog();
  };
  // Nodes initialization
  // ------------------------------
  const jsonNodes = [];
  const nodes = [getFloorNode({
    onFloorMapResizeStart, onFloorMapResize, onFloorMapResizeEnd, shouldFloorMapResize, selectedFloor, debug
  })];

  if (childNodes) {
    childNodes.forEach(n => {
      if (n.type === 'BlockNode') {
        nodeId = Math.max(nodeId, Number(n.id));
        n.data = {
          onResizeStart: onBlockNodeResizeStart,
          shouldResize: shouldBlockNodeResize,
          onResize: onBlockNodeResize,
          onResizeEnd: onBlockNodeResizeEnd
        };
      }
      if (debug) {
        const { x, y } = nodes[0].position;
        n.data = {
          ...n.data,
          debug,
          position: `p:(${ n.position.x },${ n.position.y })`,
          positionAbsolute: `a:(${ n.position.x + x },${ n.position.y + y })`
        };
      }
      nodes.push(n);
      jsonNodes.push(n);
    });
  }

  const addSelectedResourcesToMap = selectedResources => {
    selectedResources.forEach(resource => {
      addResource(resource);
    });
  };

  return (
    <>
      <div style={style}>
        <ReactFlow
          proOptions={proOptions}
          defaultNodes={nodes}
          nodes={nodes}
          fitView
          nodeTypes={nodeTypes}
          snapToGrid
          snapGrid={[10, 10]}
          onNodeDragStart={onNodeDragStart}
          onNodeDragStop={onNodeDragStop}
          onNodeDrag={onNodeDrag}
        >
          <Controls />
          <Background variant="dots" gap={10} size={1} />
        </ReactFlow>
        <div style={{ marginTop: '30px' }} className="floormap-action-buttons">
          <AddResourceNodeButton actionOnClick={openAddRoomResourceModalDialog} />
          <AddBlockNodeButton actionOnClick={addBlock} />
        </div>
      </div>
      <AddRoomResourceDialog
        selectedBuildingKey={selectedBuildingKey}
        selectedFloor={selectedFloor}
        addSelectedResourcesToMap={addSelectedResourcesToMap} />
    </>
  );
}

function FloorsMapsProvider(props) {
  const { style, selectedFloor, updateFloorMap, showError, openAddRoomResourceDialog, selectedBuildingKey } = props;
  let map = selectedFloor?.map;
  const childNodes = map.nodesJson ? JSON.parse(map.nodesJson) : null;

  const onSave = (sendNotifications = true) => {
    map.NodesJson = map.nodesJson.replace('"selected":true', '"selected":false');
    updateFloorMap(map, selectedFloor, sendNotifications);
  };

  const onChangedMap = ( { mapDimensions, childNodes, message, forceSave } ) => { // eslint-disable-line
    if (mapDimensions) map = { ...map, ...mapDimensions };
    if (childNodes) map.nodesJson = JSON.stringify(childNodes);
    if (forceSave) onSave(map, false);

    if (message) {
      showError({
        title: 'Map',
        text: message
      });
    }
  };

  return (
    <>
      <ReactFlowProvider>
        <FloorsMaps
          style={style}
          selectedFloor={selectedFloor}
          onChangedMap={onChangedMap}
          childNodes={childNodes}
          openAddRoomResourceDialog={openAddRoomResourceDialog}
          selectedBuildingKey={selectedBuildingKey}
        />
      </ReactFlowProvider>
      <div className="button--right" style={{ position: 'fixed', bottom: '30px', right: '50px' }}>
        <SaveFloorMapButton actionOnClick={onSave} />
      </div>
    </>
  );
}

const mapDispatchToProps = dispatch => ({
  updateFloorMap: (map, selectedFloor, sendNotifications = true) => {
    dispatch(floorsOperations.updateFloorMap(map, selectedFloor, sendNotifications));
  },
  showError: error => dispatch(notificationsOperations.showError(error)),
  openAddRoomResourceDialog: () => dispatch(modalOperations.pushModal(modalKeys.FLOORMAP_ADD_ROOM_RESOURCE_DIALOG))
});

FloorsMaps.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  selectedFloor: PropTypes.objectOf(PropTypes.any),
  onChangedMap: PropTypes.func.isRequired,
  childNodes: PropTypes.arrayOf(PropTypes.any),
  openAddRoomResourceDialog: PropTypes.func.isRequired,
  selectedBuildingKey: PropTypes.string.isRequired
};

FloorsMapsProvider.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  selectedFloor: PropTypes.objectOf(PropTypes.any),
  updateFloorMap: PropTypes.func.isRequired,
  showError: PropTypes.func,
  openAddRoomResourceDialog: PropTypes.func.isRequired,
  selectedBuildingKey: PropTypes.string.isRequired
};

export default connect(null, mapDispatchToProps)(withTranslation(FloorsMapsProvider));
