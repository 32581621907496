export const styles = {
  fill: 'currentcolor'
};

export const lineStyles = {
  fill: 'none',
  stroke: '#FF8B2E',
  strokeWidth: '3',
  strokeLinecap: 'round',
  strokeMiterlimit: '10'
};
