import store from 'store2';

import actions from './actions';
import selectors from './selectors';

import { locationsService } from '../../services';
import { notificationsSelectors, notificationsOperations } from '../notifications';
import { buildingsSelectors } from '../buildings';

const { cacheLocationState, resetToCache } = actions;

const clearLocations = () => dispatch => {
  store.remove('locations');
  dispatch(actions.clearLocations());
};

const setToDefault = () => (dispatch, getState) => {
  const state = getState();

  const storedLocations = store('locations');

  const hasStoredLocations = storedLocations != null && storedLocations.length > 0;

  const defaultLocation = selectors.getDefaultLocation(state);

  const hasDefaultLocation = defaultLocation != null;

  if (!hasStoredLocations && !hasDefaultLocation) {
    return;
  }

  const locations = hasStoredLocations
    ? storedLocations
    : [
      {
        ...defaultLocation,
        type: 'Building'
      }
    ];

  dispatch(actions.updateLocations(locations));
};

const setToBuildingById = buildingId => (dispatch, getState) => {
  const state = getState();
  const buildings = buildingsSelectors.getBuildings(state);

  if (!buildings) {
    return;
  }

  const searchedBuilding = buildings[buildingId.toLowerCase()];
  if (!searchedBuilding) {
    return;
  }

  const locations = [
    {
      ...searchedBuilding,
      type: 'Building'
    }
  ];

  dispatch(actions.updateLocations(locations));
};

const searchLocations = (input, callback) => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return locationsService
    .search(input)
    .then(result => {
      if (callback) {
        callback(result);
      }
      return result;
    })
    .catch(() => {
      const err = getNotification('locationpicker.error');
      dispatch(notificationsOperations.showError(err));
    });
};

const updateLocations = (locations, callback, save = true) => (dispatch, getState) => {
  const state = getState();
  const selectedLocations = selectors.getSelectedLocations(state);

  if (locations && locations !== selectedLocations) {
    if (save) {
      store('locations', locations);
    }
    dispatch(actions.updateLocations(locations));
    dispatch(actions.updateChanged());

    if (callback) {
      callback(locations);
    }
  }
};

const removeBuildingFromLocation = buildingKey => dispatch => {
  dispatch(actions.removeBuildingFromLocation(buildingKey));
};

const { resetChanged } = actions;

export default {
  searchLocations,
  updateLocations,
  cacheLocationState,
  resetToCache,
  clearLocations,
  setToDefault,
  setToBuildingById,
  resetChanged,
  removeBuildingFromLocation
};
