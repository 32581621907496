import actions from './actions';
import selectors from './selectors';

import { notificationsSelectors, notificationsOperations } from '../notifications';
import { assetsService } from '../../services';

const { updateRoom, updateIsLoading, addPhotoId, removePhotoId, toggleLoadingPhoto } = actions;

const uploadPhoto = formData => async (dispatch, getState) => {
  const state = getState();
  const selectedRoom = selectors.getSelectedRoom(state);
  const getNotification = notificationsSelectors.getNotification(state);

  if (!selectedRoom) {
    return false;
  }

  dispatch(updateIsLoading(true));
  try {
    const photoId = await assetsService.uploadRoomPhoto(selectedRoom.id, formData);
    dispatch(updateIsLoading(false));
    dispatch(addPhotoId(photoId));

    const success = getNotification('admin.photos.success.upload');
    dispatch(notificationsOperations.showSuccess(success));

    return photoId;
  } catch (serverErr) {
    const error = getNotification('admin.photos.error.upload', serverErr);
    dispatch(notificationsOperations.showError(error));
    dispatch(updateIsLoading(false));
    return false;
  }
};

const removePhoto = photoId => async (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);

  dispatch(toggleLoadingPhoto(photoId));
  try {
    await assetsService.deleteAsset(photoId);
    dispatch(toggleLoadingPhoto(photoId));
    dispatch(removePhotoId(photoId));

    const success = getNotification('admin.photos.success.delete');
    dispatch(notificationsOperations.showSuccess(success));

    return Promise.resolve();
  } catch (serverErr) {
    const error = getNotification('admin.photos.error.delete', serverErr);
    dispatch(notificationsOperations.showError(error));
    dispatch(toggleLoadingPhoto(photoId));
    return false;
  }
};

export default {
  updateRoom,
  uploadPhoto,
  removePhoto
};
