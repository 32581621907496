import { createSelector } from 'reselect';
import store from 'store2';

import get from 'lodash/fp/get';

const getUser = state => state.authState.auth.user;

const getProfile = createSelector(getUser, user => user && user.profile);
const isSigningIn = state => state.authState.auth.isSigningIn;
const isLoading = state => state.authState.auth.isLoading;

const isSignedIn = createSelector(getUser, user => user !== null && !user.expired);

const getOffice = createSelector(getUser, get('profile.office'));

const isAdministrator = createSelector(getUser, user => {
  let roles = get('profile.role', user);

  if (!roles) {
    roles = get('profile.roles', user);
  }
  if (Array.isArray(roles)) {
    return roles.some(role => role.toLowerCase() === 'administrator');
  }
  if (typeof roles === 'string') {
    return roles.toLowerCase() === 'administrator';
  }
  return false;
});

const getRoute = () => (store.session.has('location') ? store.session('location') : { path: '/', queryParams: '' });

export default {
  getUser,
  isLoading,
  isSignedIn,
  isSigningIn,
  isAdministrator,
  getOffice,
  getProfile,
  getRoute
};
