import React from 'react';
import PropTypes from 'prop-types';

import { Icon, withTranslation, CustomPropTypes } from '../../../../../common';

import './_style.css';

function UnhandledRecurrenceException({ resource, translate }) {
  const resourceType = resource.resourceType.toLowerCase();

  const text = translate(`search.detail.recurring.unhandled.${resourceType}`);
  const resolve = translate('search.detail.recurring.unhandled.resolve');

  function InformationIcon() {
  return <Icon icon="046-information-circle" className="UnhandledRecurrenceException__Icon" />;
}

  return (
    <div className="CalendarBookingTooltip__UnhandledRecurrenceException">
      <InformationIcon />
      {text}
      {' '}
      {resolve}
    </div>
  );
}

UnhandledRecurrenceException.propTypes = {
  resource: CustomPropTypes.resource,
  translate: PropTypes.func.isRequired
};

export default withTranslation(UnhandledRecurrenceException);
