import config from '../config';
import fetch from './fetch';

const getUrl = path => `${config.remoteUrl}/${path}`;

async function post(payload) {
  const url = getUrl('changeRequests/masterdata');
  return fetch(url, 'POST', payload);
}

export default { post };
