import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation, Icon } from '../../common';

function BookFormTimeWarning({ translate }) {
  const warningText = translate('bookingCollection.timeWarning');
  return (
    <div className="BookFormTimeWarning">
      <Icon icon="094-warning" />
      <p>{warningText}</p>
    </div>
  );
}

BookFormTimeWarning.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookFormTimeWarning);
