import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import cx from 'classnames';
import omit from 'lodash/fp/omit';
import isEqual from 'lodash/fp/isEqual';

import { isEscape, isEnter, isUpwards, isDownwards } from '../../../util';
import {
  getNextFocusedItems,
  getPreviousFocusedItems,
  findByValue
} from '../Input/AutoComplete/autocompleteUtil';

import Icon from '../Icon/Icon';
import Translate from '../I18n/Translate';
import AutoCompleteDropdown from '../Input/AutoComplete/AutoCompleteDropdown';

import './_style.css';

class BuildingPicker extends Component {
  constructor(props) {
    super(props);

    const { building } = props;

    this.state = {
      focused: false,
      term: (building && building.name && building.value ? `${building.name}, ${building.value}` : ''),
      suggestions: [],
      dirty: false
    };
  }

  UNSAFE_componentWillReceiveProps({ suggestions: nextSuggestions, building: nextBuilding }) {
    const { suggestions: prevSuggestions, building: prevBuilding } = this.props;
    if (prevSuggestions !== nextSuggestions) {
      this.setState({ suggestions: nextSuggestions });
    }

    if (!isEqual(prevBuilding, nextBuilding)) {
      this.setState({ term: (nextBuilding && nextBuilding.name && nextBuilding.value
        ? `${nextBuilding.name}, ${nextBuilding.value}` : '')
      });
    }
  }

  onChange = event => {
    const { loadSuggestions, updateBuilding } = this.props;
    const term = event.target.value;
    this.setState({ term, dirty: true });
    loadSuggestions(term);
    updateBuilding(null);
  };

  onSelect = value => {
    const { loadSuggestions, updateBuilding } = this.props;
    const building = omit(['focused'], value);
    this.setState({ term: `${building.name}, ${building.value}` });
    updateBuilding(building);
    loadSuggestions('');
  };

  onKeyDown = event => {
    const { keyCode } = event;
    const { suggestions } = this.state;

    if (isEscape(keyCode)) {
      this.exit();
    }

    if (isUpwards(keyCode) || isDownwards(keyCode)) {
      event.preventDefault();
      const newSuggestions = isDownwards(keyCode)
        ? getNextFocusedItems(suggestions)
        : getPreviousFocusedItems(suggestions);
      this.setState({ suggestions: newSuggestions, focused: true });
    }

    if (isEnter(keyCode)) {
      event.preventDefault();
      const selectedBuilding = suggestions.find(s => s.focused);
      if (selectedBuilding) {
        const building = findByValue(selectedBuilding, suggestions);
        if (building) {
          this.onSelect(building);
          this.exit();
        }
      }
    }
  };

  onFocus = () => {
    this.setState({ focused: true });
  };

  exit = () => {
    this.setState({
      focused: false
    });
  };

  handleClickOutside = () => {
    this.exit();
  };

  isValid = () => {
    const { focused, dirty, term } = this.state;
    const { building, required } = this.props;
    if (focused) {
      return true;
    }

    if (dirty && !required && term === '') {
      return true;
    }
    return building !== null;
  };

  render() {
    const { term, focused, suggestions, dirty } = this.state;
    const { className, isLoading, placeholder, invalid } = this.props;

    const isInvalid = invalid || (dirty && !this.isValid());

    const classes = cx(
      className,
      'BuildingPicker',
      'input-field input-field--autocomplete input-field--fixed',
      {
        'BuildingPicker--invalid': isInvalid,
        'is-open': focused && suggestions.length > 0
      }
    );

    const props = {
      type: 'text',
      value: term,
      placeholder,
      onFocus: this.onFocus,
      onChange: this.onChange,
      onKeyDown: this.onKeyDown,
      className: cx({ invalid: isInvalid }),
      ref: node => {
        this.input = node;
      }
    };
    return (
      <div className={classes} onFocus={this.onFocus}>
        <input {...props} />
        <AutoCompleteDropdown
          items={term === '' ? [] : suggestions}
          value={term}
          onChange={this.onSelect}
          onKeyDown={this.onKeyDown}
          showLoader
          loading={isLoading}
        />
        <div className="BuildingPicker__Error">
          <Icon icon="026-exclamation-mark-circle" />
          <Translate path="buildingpicker.validationError" />
        </div>
      </div>
    );
  }
}

const buildingPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
});

BuildingPicker.propTypes = {
  placeholder: PropTypes.string,
  building: buildingPropType,
  isLoading: PropTypes.bool.isRequired,
  suggestions: PropTypes.arrayOf(buildingPropType),
  loadSuggestions: PropTypes.func.isRequired,
  updateBuilding: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool
};

BuildingPicker.defaultProps = {
  invalid: false,
  required: false
};

export default onClickOutside(BuildingPicker);
