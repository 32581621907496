import { createSelector } from 'reselect';
import map from 'lodash/fp/map';
import find from 'lodash/fp/find';
import groupBy from 'lodash/fp/groupBy';
import mapValues from 'lodash/fp/mapValues';

import { regionsSelectors } from '../regions';
import { authSelectors } from '../auth';
import { getLocationName } from '../../util';
import { buildingsSelectors } from '../buildings';

const getAllLocations = state => regionsSelectors.getAll(state);
const getSelectedLocations = state => state.locationsState.locations;
const getOffice = state => authSelectors.getOffice(state);
const isChanged = state => state.locationsState.wasChanged;
const getBuildings = state => buildingsSelectors.getBuildings(state);

const convertType = type => (type === 'Room' ? 'resourceIds' : `${ type?.toLowerCase() }Ids`);
const groupByLocationType = groupBy(({ type }) => convertType(type));
const mapLocationId = map(({ id }) => id);
const mapLocationIds = mapValues(mapLocationId);

const getDefaultLocation = createSelector(
  [getAllLocations, getOffice],
  (locations, office) => find(location => location.key === office, locations)
);

const getLocationsByType = createSelector(
  [getSelectedLocations],
  selectedLocations => {
    const locations = { regionIds: [], buildingIds: [], floorIds: [], sectorIds: [], resourceIds: [] };
    const locationsByType = { ...locations, ...groupByLocationType(selectedLocations) };
    return mapLocationIds(locationsByType);
  }
);

const getLocationNames = createSelector(
  getSelectedLocations,
  (locations = []) => locations.map(getLocationName).join(', ')
);

const hasLocations = createSelector(
  getSelectedLocations,
  (locations = []) => locations.length > 0
);

const getExistingSelectedLocations = createSelector(
  [getAllLocations, getBuildings, getSelectedLocations],
  (locations, buildings, selectedLocations) => {
    if (selectedLocations && selectedLocations.length > 0) {
      const regionNames = Object.values(locations).map(location => location.name);
      const selectedRegions = selectedLocations.filter(l => l.type === 'Region')
        .filter(l => regionNames.indexOf(l.name) !== -1);

      const buildingKeys = buildings.map(building => building.key);
      const selectedBuildings = selectedLocations.filter(l => l.type === 'Building')
        .filter(l => buildingKeys.indexOf(l.key) !== -1);

      const selectedRooms = selectedLocations.filter(l => l.type === 'Room');

      return [...selectedRegions, ...selectedBuildings, ...selectedRooms];
    }

    return selectedLocations;
  }
);

export default {
  getAllLocations,
  getDefaultLocation,
  getLocationNames,
  getLocationsByType,
  getSelectedLocations,
  hasLocations,
  isChanged,
  getExistingSelectedLocations
};
