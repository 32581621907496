// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchMobile_bookResultsMobile__grXB7 {\n  padding-bottom: 59px;\n  overflow-x: hidden;\n}\n\n.SearchMobile_bookResultsMobile__grXB7 .SearchMobile_searchResults__HZkvx {\n  padding-left: 0;\n  height: calc(100% - 60px);\n}", "",{"version":3,"sources":["webpack://./src/views/Search/SearchMobile/SearchMobile.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B","sourcesContent":[".bookResultsMobile {\n  padding-bottom: 59px;\n  overflow-x: hidden;\n}\n\n.bookResultsMobile .searchResults {\n  padding-left: 0;\n  height: calc(100% - 60px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bookResultsMobile": "SearchMobile_bookResultsMobile__grXB7",
	"searchResults": "SearchMobile_searchResults__HZkvx"
};
export default ___CSS_LOADER_EXPORT___;
