import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Header from '../../Header';
import { BookDetailSidebar, BookDetailNotes, RecurrenceDetailActions } from '../BookDetail';
import RecurrenceDetail from '../BookDetail/RecurrenceDetail';
import RecurrenceDetailInfo from '../BookDetail/RecurrenceDetailInfo';
import RecurrenceExceptions from '../BookForm/BookFormRecurrence/RecurrenceExceptions';

import { Loader, withTranslation, ResourceImages, CustomPropTypes } from '../../common';

import './_style.css';
import SidebarChangeRequest from '../BookDetail/BookDetailSidebar/SidebarChangeRequest';

class RecurrenceDetailModalDesktop extends Component {
  renderRecurrenceDetail = () => {
    const { isLoading } = this.props;
    if (isLoading) {
      return <Loader visible />;
    }

    return (
      <>
        <RecurrenceDetail {...this.props} />
        <RecurrenceDetailInfo {...this.props} />
      </>
    );
  };

  renderLoader = () => (
    <div className="BookModal__Main">
      <div className="RecurrenceDetailLoading">
        <Loader visible />
      </div>
    </div>
  );

  renderBody = () => {
    const {
      isLoading,
      selectedMaster,
      exceptions,
      hasUnhandledExceptions,
      patchException,
      openExceptionDeleteModal,
      openExceptionModal,
      confirmDeletion
    } = this.props;

    if (isLoading) {
      return this.renderLoader();
    }

    const {
      resource: { notes }
    } = selectedMaster;

    return (
      <>
        <div className="BookModal__Main">
          <Scrollbars autoHide>
            <div className="BookModal__Body">
              <div className="BookModal__Content">{this.renderRecurrenceDetail()}</div>
              <div className="BookModal__Content">
                <RecurrenceExceptions
                  exceptions={exceptions}
                  resource={selectedMaster}
                  openExceptionModal={openExceptionModal}
                  openExceptionDeleteModal={openExceptionDeleteModal}
                  hasUnhandledExceptions={hasUnhandledExceptions}
                  patchException={patchException}
                  showExceptionWarning={false}
                  showOutlookInfo
                  hasLoadedAvailabilities
                />
              </div>
              <div className="BookModal__Content">
                {notes && <BookDetailNotes notes={notes} className="BookModal__Info" />}
              </div>
              <div className="BookModal__Content">
                <RecurrenceDetailActions confirmDeletion={confirmDeletion} />
              </div>
            </div>
          </Scrollbars>
        </div>
        <div className="BookModal__Sidebar">
          <Scrollbars autoHide>
            <ResourceImages resource={selectedMaster.resource} />
            <div className="BookModal__SidebarInfo">
              <BookDetailSidebar
                resource={selectedMaster.resource}
                seating={selectedMaster.seating}
              />
              <SidebarChangeRequest resource={selectedMaster.resource} />
            </div>
          </Scrollbars>
        </div>
      </>
    );
  };

  render() {
    const { selectedMaster, isLoading, popModal, translate } = this.props;

    const title = isLoading
      ? translate('search.detail.recurring.loadingRecurrence')
      : selectedMaster.resource.displayName;

    return (
      <>
        <Header title={title} onClose={popModal} fixed={false} icon="014-chevron-left" />
        <div className="BookModalDesktop">{this.renderBody()}</div>
      </>
    );
  }
}

RecurrenceDetailModalDesktop.propTypes = {
  selectedMaster: PropTypes.shape({
    displayName: PropTypes.string,
    resource: PropTypes.shape({
      displayName: PropTypes.any,
      notes: PropTypes.any
    }),
    seating: PropTypes.any
  }),
  isLoading: PropTypes.bool,
  popModal: PropTypes.func.isRequired,
  confirmDeletion: PropTypes.func.isRequired,
  exceptions: PropTypes.arrayOf(CustomPropTypes.exception),
  hasUnhandledExceptions: PropTypes.bool,
  openExceptionModal: PropTypes.func.isRequired,
  openExceptionDeleteModal: PropTypes.func.isRequired,
  patchException: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurrenceDetailModalDesktop);
