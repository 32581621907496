import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Profile3, Profile2, CustomPropTypes } from '../../../common';

import './_style.css';

function ProfileInformation({ resource, responseType }) {
  const { profile } = resource;

  const renderProfileInformation = () => {
    if (profile === 2) {
      return <Profile2 responseType={responseType} resource={resource} />;
    }
    if (profile === 3) {
      return <Profile3 resource={resource} />;
    }
    return null;
  };

  const classes = cx(
    'ProfileInformation',
    responseType != null ? `ProfileInformation--${responseType.toLowerCase()}` : null
  );

  return (
    <div>
      <div className={classes}>
        {renderProfileInformation()}
      </div>
    </div>
  );
}

ProfileInformation.propTypes = {
  resource: CustomPropTypes.resource,
  responseType: PropTypes.string
};

export default ProfileInformation;
