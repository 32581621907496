import { createSelector } from 'reselect';
import moment from 'moment';
import first from 'lodash/fp/first';

import { searchSelectors } from '../search';
import { isDateValid, toDate, isDateInPast } from '../../util';
import { getDailyOccurrences, getWeeklyOccurrences, getMonthlyOccurrences } from './occurrences';

const getDate = state => searchSelectors.getDate(state);
const getTime = state => searchSelectors.getTime(state);

const isActive = state => state.recurrenceState.active;

const getRecurrenceType = state => state.recurrenceState.type;
const recurrenceHasChanged = state => state.recurrenceState.hasChanged;

const getWeek = state => state.recurrenceState.week;
const getIndexOfWeek = state => state.recurrenceState.week.index;
const getDaysOfWeek = state => state.recurrenceState.week.daysOfWeek;
const getDaysOfWeekChanged = state => state.recurrenceState.week.daysOfWeekChanged;

const getMonth = state => state.recurrenceState.month;
const getIndexOfMonth = state => state.recurrenceState.month.index;
const getWeekOfMonth = state => state.recurrenceState.month.weekOfMonth;
const getDayOfMonth = state => state.recurrenceState.month.dayOfMonth;

const getRecurrenceEndType = state => state.recurrenceState.endType;
const getRecurrenceEndDate = state => state.recurrenceState.endDate;
const getNumberOfBookings = state => state.recurrenceState.numberOfBookings;

const isRecurrenceEnabled = createSelector(
  getRecurrenceType,
  type => type !== 'never'
);

const isPending = createSelector(
  [isActive, isRecurrenceEnabled],
  (active, enabled) => active || enabled
);

const getRecurrenceEnd = createSelector(
  [getRecurrenceEndType, getNumberOfBookings, getRecurrenceEndDate],
  (type, max, date) => {
    const hasMaxNumber = type === 'after';
    const endType = hasMaxNumber ? 'endAfter' : 'endDate';
    const numberOfOccurrences = hasMaxNumber ? max : null;
    const endDate = hasMaxNumber ? null : date;
    return { endType, numberOfOccurrences, endDate };
  }
);

const getWeeklyRecurrence = createSelector(
  [getIndexOfWeek, getDaysOfWeek],
  (indexOfWeek, daysOfWeek) => ({
    interval: indexOfWeek,
    daysOfWeek
  })
);

const getMonthlyRecurrence = createSelector(
  [getIndexOfMonth, getDayOfMonth, getWeekOfMonth],
  (indexOfMonth, dayOfMonth, weekOfMonth) => ({
    interval: indexOfMonth,
    dayOfWeek: dayOfMonth,
    weekOfMonthIndex: weekOfMonth
  })
);

const getRecurrenceRequest = createSelector(
  [getRecurrenceType, getRecurrenceEnd, getWeeklyRecurrence, getMonthlyRecurrence],
  (recurrenceType, recurrenceEnd, weeklyRecurrence, monthlyRecurrence) => {
    const dailyRecurrence = {
      interval: 1
    };

    return {
      recurrencePattern: recurrenceType,
      recurrenceEnd,
      dailyPattern: recurrenceType === 'daily' ? dailyRecurrence : null,
      weeklyPattern: recurrenceType === 'weekly' ? weeklyRecurrence : null,
      monthlyPattern: recurrenceType === 'monthly' ? monthlyRecurrence : null
    };
  }
);

const getRecurrencePattern = createSelector(
  [getRecurrenceType, getWeek, getMonth],
  (type, week, month) => {
    switch (type) {
      case 'weekly':
        return week;
      case 'monthly':
        return month;
      default:
        return {};
    }
  }
);

const getOccurrences = (state, startDate, endDate) => {
  const recurrencePattern = getRecurrenceType(state);

  if (recurrencePattern === 'daily') {
    return getDailyOccurrences(startDate, endDate);
  }

  if (recurrencePattern === 'weekly') {
    const indexOfWeek = getIndexOfWeek(state);
    const daysOfWeek = getDaysOfWeek(state);
    return getWeeklyOccurrences(startDate, endDate, indexOfWeek, daysOfWeek);
  }

  if (recurrencePattern === 'monthly') {
    const indexOfMonth = getIndexOfMonth(state);
    const dayOfMonth = getDayOfMonth(state);
    const weekOfMonth = getWeekOfMonth(state);
    return getMonthlyOccurrences(startDate, endDate, indexOfMonth, dayOfMonth, weekOfMonth);
  }

  return [];
};

const isValidEndDate = createSelector(
  [getDate, getTime, getRecurrenceEndDate, getRecurrenceEndType, state => state],
  (date, time, recurrenceEndDate, recurrenceEndType, state) => {
    const userHasSelectedEndDate = recurrenceEndType !== 'after';
    if (!userHasSelectedEndDate) {
      return true;
    }

    const isValidDate = isDateValid(date) && !isDateInPast(date);
    const isValidRecurrenceEndDate = isDateValid(recurrenceEndDate);
    if (!isValidDate || !isValidRecurrenceEndDate) {
      return false;
    }

    const { from } = time;
    const startDate = moment(date, 'DD.MM.YYYY');
    const startTime = moment(from, ['HHmm', 'HH:mm'], true);
    if (startTime.isValid()) {
      startDate.set({
        hour: startTime.get('hour'),
        minute: startTime.get('minute'),
        second: 0
      });
    }

    const endDate = moment(toDate(recurrenceEndDate)).endOf('day');
    if (!startDate.isBefore(endDate)) {
      return false;
    }

    const occurrences = getOccurrences(state, startDate, endDate);
    if (occurrences.length <= 0) {
      return false;
    }

    const firstOccurrence = moment(first(occurrences));
    return firstOccurrence.isSameOrAfter(startDate, 'day');
  }
);

const isValidRecurrenceEnd = createSelector(
  [getRecurrenceEndType, getRecurrenceEndDate, isValidEndDate],
  (recurrenceEndType, recurrenceEndDate, validEndDate) => {
    const isDate = recurrenceEndType === 'date';
    const isValidDate = isDateValid(recurrenceEndDate);

    if ((isDate && !isValidDate) || !validEndDate) {
      return false;
    }

    return true;
  }
);

const isValidRecurrence = state => !(
  getDaysOfWeek(state).length === 0
    && getDaysOfWeekChanged(state)
    && getRecurrenceType(state) === 'weekly'
);

const isValid = createSelector(
  [isValidRecurrenceEnd, isValidRecurrence],
  (validRecurrenceEnd, validRecurrence) => validRecurrenceEnd && validRecurrence
);

export default {
  isActive,
  isPending,
  isRecurrenceEnabled,
  getRecurrenceType,
  recurrenceHasChanged,
  getIndexOfWeek,
  getDaysOfWeek,
  getIndexOfMonth,
  getWeekOfMonth,
  getDayOfMonth,
  getNumberOfBookings,
  getRecurrenceEndType,
  getRecurrenceEndDate,
  getRecurrencePattern,
  getRecurrenceRequest,
  isValidRecurrence,
  isValidRecurrenceEnd,
  isValid
};
