import { createSelector } from 'reselect';

import isBoolean from 'lodash/fp/isBoolean';
import isEmpty from 'lodash/fp/isEmpty';
import isNumber from 'lodash/fp/isNumber';
import omit from 'lodash/fp/omit';
import values from 'lodash/fp/values';
import pick from 'lodash/fp/pick';

const isSubmitted = state => state.createResourceState.submitted;
const isSubmitting = state => state.createResourceState.isSubmitting;

const getFormValue = (state, field) => state.createResourceState.values[field];

const getResourceType = state => getFormValue(state, 'resourceType');
const getName = state => getFormValue(state, 'name');
const getRegion = state => getFormValue(state, 'region');
const getStreet = state => getFormValue(state, 'street');
const getZipCode = state => getFormValue(state, 'zipCode');
const getFloor = state => getFormValue(state, 'floor');
const getSector = state => getFormValue(state, 'sector');
const getResourceSubType = state => getFormValue(state, 'resourceSubType');
const getCapacity = state => getFormValue(state, 'capacity');
const getDaylight = state => getFormValue(state, 'daylight');
const getRoomEquipment = state => getFormValue(state, 'roomEquipment');
const getConfidentialMeetings = state => getFormValue(state, 'confidentialMeetings');
const getNotes = state => getFormValue(state, 'notes');
const getReason = state => getFormValue(state, 'reason');
const getProfile = state => getFormValue(state, 'profile');
const getOwnersProfile2 = state => getFormValue(state, 'ownersProfile2');
const getOwnersProfile3 = state => getFormValue(state, 'ownersProfile3');
const getOwnersProfile4 = state => getFormValue(state, 'ownersProfile4');

const mapPerson = person => ({
  ...person,
  accountName: person.value,
  displayName: person.name,
  memberType: 'User'
});

const pickPersonValues = person => pick(['accountName', 'displayName', 'memberType'], person);

const getOwners = createSelector(
  [getProfile, getOwnersProfile2, getOwnersProfile3, getOwnersProfile4],
  (profile, ownersProfile2, ownersProfile3, ownersProfile4) => {
    if (profile.new === '1') {
      return { new: [], old: [] };
    }

    if (profile.new === '2') {
      return { new: ownersProfile2.new.map(mapPerson), old: [] };
    }

    if (profile.new === '3') {
      return { new: ownersProfile3.new.map(mapPerson), old: [] };
    }

    if (profile.new === '4') {
      return { new: ownersProfile4.new.map(mapPerson), old: [] };
    }

    if (profile.new === null) {
      return { new: [], old: [] };
    }

    return {};
  }
);

const getContact = state => getFormValue(state, 'contact');

const isPeopleLoading = state => state.createResourceState.peopleIsLoading;
const getSuggestions = state => state.createResourceState.peopleSuggestions;

const getPeopleSuggestions = createSelector(
  getSuggestions,
  suggestions => suggestions.map(person => ({
    name: person.displayName,
    value: person.accountName,
    id: person.accountName,
    email: person.email,
    accountName: person.accountName
  }))
);

const getBuilding = state => getFormValue(state, 'building');

const isBuildingLoading = state => state.createResourceState.isBuildingLoading;
const getSuggestionsBuilding = state => state.createResourceState.buildingSuggestions;

const getBuildingSuggestions = createSelector(
  getSuggestionsBuilding,
  suggestions => suggestions.map(building => ({
    name: building.name,
    value: building.key
  }))
);

const getChangeRequestInfo = state => getFormValue(state, 'changeRequestInfo');

const isChangeRequestInfoLoading = state => state.createResourceState.isChangeRequestInfoLoading;
const getSuggestionsChangeRequestInfo = state => state.createResourceState.changeRequestInfoSuggestions;

const getChangeRequestInfoSuggestions = createSelector(
  getSuggestionsChangeRequestInfo,
  suggestions => suggestions.map(changeRequestInfo => ({
    id: changeRequestInfo.id,
    name: changeRequestInfo.name,
    resourceType: changeRequestInfo.resourceType
  }))
);

const isValueEmpty = value => {
  if (isBoolean(value)) {
    return false;
  }
  if (!value) {
    return true;
  }
  if (isNumber(value) && value > 0) {
    return false;
  }
  return isEmpty(value);
};

const getValues = state => {
  const contactMail = getContact(state).new && getContact(state).new.email;
  const resourceType = getResourceType(state).new;

  const commonValues = {
    resourceType,
    name: getName(state).new,
    contact: contactMail,
    notes: getNotes(state).new,
    reason: getReason(state).new,
    building: getBuilding(state).new
  };

  const specificValues = resourceType && resourceType.toLowerCase() === 'room'
    ? {
      resourceSubType: getResourceSubType(state).new,
      floor: getFloor(state).new,
      sector: getSector(state).new,
      capacity: getCapacity(state).new,
      daylight: getDaylight(state).new,
      confidentialMeetings: getConfidentialMeetings(state).new,
      roomEquipment: getRoomEquipment(state).new
    }
    : {
      resourceSubType: getResourceSubType(state).new
    };

  const profile = getProfile(state).new;

  const owners = getOwners(state).new.map(pickPersonValues);

  const profileValues = profile !== '1'
    ? {
      profile: parseInt(profile, 10) || null,
      owners
    }
    : { profile: parseInt(profile, 10) || null };

  return { ...commonValues, ...specificValues, ...profileValues };
};

const isValid = createSelector(
  [getValues],
  allValues => {
    const requiredValues = values(
      omit(['resourceId', 'resourceType', 'roomEquipment', 'sector', 'notes'], allValues)
    );
    const allValid = requiredValues.reduce((prev, value) => {
      if (prev === true) {
        return !isValueEmpty(value);
      }
      return false;
    }, true);
    const { capacity } = allValues;
    const isRoom = allValues.resourceType && allValues.resourceType.toLowerCase() === 'room';
    const isCapacityValid = isRoom ? isNumber(capacity) && capacity > 0 && capacity <= 1000 : true;
    const hasInvalidName = /[^a-zA-Z0-9!#$%&'* +-/=?^_`{|}~ ]+/.test(allValues.name);
    return allValid && isCapacityValid && !hasInvalidName;
  }
);

export default {
  getChangeRequestInfo,
  isChangeRequestInfoLoading,
  getChangeRequestInfoSuggestions,
  getBuilding,
  isBuildingLoading,
  getBuildingSuggestions,
  isPeopleLoading,
  getPeopleSuggestions,
  getResourceType,
  getName,
  getRegion,
  getStreet,
  getZipCode,
  getFloor,
  getSector,
  getResourceSubType,
  getCapacity,
  getDaylight,
  getRoomEquipment,
  getConfidentialMeetings,
  getContact,
  getNotes,
  getReason,
  getProfile,
  isSubmitted,
  isSubmitting,
  getOwners,
  getOwnersProfile2,
  getOwnersProfile3,
  getOwnersProfile4,
  isValid,
  getValues
};
