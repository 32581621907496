import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

function ButtonGroup({ right, fill, children }) {
  const classes = classNames({
    'button-group': true,
    'button-group--right': right,
    'button-group--fill': fill
  });

  return (
    <div className={classes}>
      {children}
    </div>
  );
}

ButtonGroup.propTypes = {
  right: PropTypes.bool,
  fill: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

ButtonGroup.defaultProps = {
  right: false,
  fill: false
};

export default ButtonGroup;
