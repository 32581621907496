import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Route } from 'react-router-dom';

import { LoginCallback, LoginRenewal, LoginRoute, LoginPage } from './common';
import { loggingService } from '../services';
import { withOfflineState } from './NoConnectionState';
import Menu from './Menu';
import Browser from './Browser';
import Content from './Content';
import Refresh from './Refresh';
import Notifications from './Notifications';
import { authSelectors } from '../state/auth';
import { regionsOperations } from '../state/regions';
import { maintenanceMessageOperations } from '../state/maintenanceMessage';
import StartupLoader from './StartupLoader';
import ErrorPage from './ErrorPage';
import WelcomeMessage from './Welcome';
import MaintenanceMessages from './MaintenanceMessages';
import { resourcesSubTypesOperations } from '../state/resourcesSubTypes';
import { buildingsOperations } from '../state/buildings';
import { configOperations } from '../state/config';

class App extends Component {
  constructor(props) {
    super(props);
    this.hasLoaded = false;
    this.state = {
      hasError: false
    };
  }

  componentDidUpdate() {
    const { user } = this.props;
    const signedIn = user !== null && !user.expired;

    if (signedIn && !this.hasLoaded) {
      const { updateRegions, updateBuildings, updateResourceSubTypes, loadCurrentMessage, getConfig } = this.props;
      updateRegions();
      updateBuildings();
      updateResourceSubTypes();
      loadCurrentMessage();
      getConfig();
      this.hasLoaded = true;
    }
  }

  componentDidCatch(error, info) {
    const { componentStack } = info;
    loggingService.error('Uncaught Exception', error, componentStack);
    this.setState({
      hasError: true
    });
  }

  render() {
    const { user, isLoading } = this.props;
    const { hasError } = this.state;
    const signedIn = user !== null && !user.expired;

    const onClose = () => {
      window.location.reload();
    };

    if (hasError) {
      return <ErrorPage onClose={onClose} />;
    }

    return (
      <>
        <Refresh />
        <Browser />
        <Notifications />
        <MaintenanceMessages />
        {signedIn && (
          <>
            <WelcomeMessage />
            <Route exact path="/" component={Menu} />
            <Route path="/search" component={Menu} />
            <Route exact path="/mybookings" component={Menu} />
            <Route exact path="/help" component={Menu} />
            <Route exact path="/language" component={Menu} />
          </>
        )}
        { signedIn && <StartupLoader /> }
        <Route path="/login" component={LoginPage} />
        <Route path="/callback" component={LoginCallback} />
        <Route path="/silentrenew" component={LoginRenewal} />
        <LoginRoute component={Content} signedIn={signedIn} isLoading={isLoading} />
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: authSelectors.getUser(state),
  isLoading: authSelectors.isLoading(state)
});

const mapDispatchToProps = dispatch => ({
  updateRegions: () => dispatch(regionsOperations.updateRegions()),
  updateBuildings: () => dispatch(buildingsOperations.updateBuildings()),
  updateResourceSubTypes: () => dispatch(resourcesSubTypesOperations.loadResourcesSubTypes()),
  loadCurrentMessage: () => dispatch(maintenanceMessageOperations.loadCurrentMessage()),
  getConfig: () => dispatch(configOperations.getConfig())
});

App.propTypes = {
  user: PropTypes.shape(),
  isLoading: PropTypes.bool.isRequired,
  loadCurrentMessage: PropTypes.func.isRequired,
  updateRegions: PropTypes.func.isRequired,
  updateBuildings: PropTypes.func.isRequired,
  updateResourceSubTypes: PropTypes.func.isRequired,
  getConfig: PropTypes.func.isRequired
};

export default withOfflineState(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(App)
  )
);
