import types from './types';

const mapOwnerData = resource => ({
  type: types.MAP_OWNER_DATA,
  resource
});

const initializeData = changeRequest => ({
  type: types.INITIALIZE_DATA,
  changeRequest
});

const initializeOwners = (owners, mode) => ({
  type: types.INITIALIZE_OWNERS,
  owners,
  mode
});

const selectProfile = profile => ({
  type: types.SELECT_PROFILE,
  profile
});

const updateReason = reason => ({
  type: types.UPDATE_REASON,
  reason
});

const updateContact = (profile, contact) => ({
  type: types.UPDATE_CONTACT,
  profile,
  contact
});

const updateContactIsLoading = isLoading => ({
  type: types.UPDATE_CONTACT_ISLOADING,
  isLoading
});

const updateContactSuggestions = suggestions => ({
  type: types.UPDATE_CONTACT_SUGGESTIONS,
  suggestions
});

const updateOwners = (profile, owners) => ({
  type: types.UPDATE_OWNERS,
  profile,
  owners
});

const updateOwnerIsLoading = isLoading => ({
  type: types.UPDATE_OWNER_ISLOADING,
  isLoading
});

const updateOwnerSuggestions = suggestions => ({
  type: types.UPDATE_OWNER_SUGGESTIONS,
  suggestions
});

const updateIsSubmitting = isSubmitting => ({
  type: types.UPDATE_IS_SUBMITTING,
  isSubmitting
});

const updateSubmitted = () => ({
  type: types.UPDATE_SUBMITTED
});

export default {
  mapOwnerData,
  initializeData,
  initializeOwners,
  selectProfile,
  updateContact,
  updateOwners,
  updateReason,
  updateContactIsLoading,
  updateOwnerIsLoading,
  updateContactSuggestions,
  updateOwnerSuggestions,
  updateIsSubmitting,
  updateSubmitted
};
