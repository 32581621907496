// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WelcomeTitle_title__ia3z9 {\n  font-size: 18px;\n  line-height: 32px;\n  letter-spacing: -0.35px;\n  font-weight: 600;\n}\n\n.WelcomeTitle_modal__jhkUN {\n}\n\n.WelcomeTitle_dialog__GxBw3 {\n  margin-top: 5px;\n  margin-left: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/views/Welcome/WelcomeTitle.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;AAEA;;AAEA;EAEE,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".title {\n  font-size: 18px;\n  line-height: 32px;\n  letter-spacing: -0.35px;\n  font-weight: 600;\n}\n\n.modal {\n  composes: title;\n}\n\n.dialog {\n  composes: title;\n  margin-top: 5px;\n  margin-left: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "WelcomeTitle_title__ia3z9",
	"modal": "WelcomeTitle_modal__jhkUN WelcomeTitle_title__ia3z9",
	"dialog": "WelcomeTitle_dialog__GxBw3 WelcomeTitle_title__ia3z9"
};
export default ___CSS_LOADER_EXPORT___;
