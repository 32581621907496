/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { styles, lineStyles } from './styles';

function DiscIcon({ disabled, ...otherProps }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.000000 30.000000" {...otherProps} style={styles}>
      <g transform="translate(-0.500000,25.500000) scale(0.030,-0.030)">
        <path d="M512 727.4666666666667C720.2133333333334 727.4666666666667 887.4666666666667 560.2133333333334 887.4666666666667 352S720.2133333333334 -23.4666666666667 512 -23.4666666666667S136.5333333333333 143.7866666666666 136.5333333333333 352S303.7866666666667 727.4666666666667 512 727.4666666666667M512 795.7333333333333C266.24 795.7333333333333 68.2666666666667 597.76 68.2666666666667 352S266.24 -91.7333333333333 512 -91.7333333333333S955.7333333333333 106.24 955.7333333333333 352S757.76 795.7333333333333 512 795.7333333333333zM512 198.4C426.6666666666667 198.4 358.4 266.6666666666667 358.4 352S426.6666666666667 505.6 512 505.6S665.6 437.3333333333333 665.6 352S597.3333333333334 198.4 512 198.4zM512 437.3333333333333C464.2133333333333 437.3333333333333 426.6666666666667 399.7866666666667 426.6666666666667 352S464.2133333333333 266.6666666666667 512 266.6666666666667S597.3333333333334 304.2133333333334 597.3333333333334 352S559.7866666666666 437.3333333333333 512 437.3333333333333z" fill="#333" />
      </g>
      {disabled && <line style={lineStyles} x1="3.45" y1="2.5" x2="24.75" y2="29.01" />}
    </svg>
  );
}

DiscIcon.propTypes = {
    disabled: PropTypes.bool
};

export default DiscIcon;
