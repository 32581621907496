import PropTypes from 'prop-types';

export default PropTypes.shape({
  type: PropTypes.string,
  german: PropTypes.string,
  french: PropTypes.string,
  italian: PropTypes.string,
  english: PropTypes.string,
  from: PropTypes.shape(
    {
      date: PropTypes.string,
      time: PropTypes.string
    }.isRequired
  ),
  to: PropTypes.shape(
    {
      date: PropTypes.string,
      time: PropTypes.string
    }.isRequired
  )
});
