import React from 'react';

import { CustomPropTypes } from '../../../common';
import { toTwoDigits } from '../../../../util';

const toTimeString = value => {
  if (!value) {
    return '';
  }

  const date = new Date(value);
  return `${toTwoDigits(date.getHours())}:${toTwoDigits(date.getMinutes())}`;
};

function BookingTime({ booking }) {
  const { start, end } = booking;
  const from = toTimeString(start);
  const to = toTimeString(end);
  const time = `${from} - ${to}`;

  return <div>{time}</div>;
}

BookingTime.propTypes = { booking: CustomPropTypes.booking };

export default BookingTime;
