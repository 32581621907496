import React from 'react';
import PropTypes from 'prop-types';

import { FormField, withTranslation } from '../../common';

function BookDetailBookedFor({ person, translate }) {
  const title = translate('search.detail.bookedFor.name');
  const name = person ? `${person.displayName}` : '-';
  const label = <strong>{title}</strong>;

  return <FormField className="BookDetail__FormField" label={label} element={name} />;
}

BookDetailBookedFor.propTypes = {
  person: PropTypes.shape({
    displayName: PropTypes.string.isRequired
  }),
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookDetailBookedFor);
