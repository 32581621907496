import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withTranslation, LinkButton } from '../../common';

import './_style.css';

function CollectionLoadMore({ visible, onClick, className, translate }) {
  if (!visible) {
    return null;
  }

  const classes = cx('CollectionSearchResults__LoadMore', className);

  const linkText = translate('search.alternativeResults.loadmore');
  return <LinkButton label={linkText} className={classes} onClick={onClick} />;
}

CollectionLoadMore.propTypes = {
  visible: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

CollectionLoadMore.defaultProps = {
  visible: false
};

export default withTranslation(CollectionLoadMore);
