import actions from '../actions';
import selectors from '../selectors';
import config from '../../../config';
import { roomFilterSelectors } from '../../roomFilter';
import { notificationsSelectors, notificationsOperations } from '../../notifications';
import { availabilityService } from '../../../services';
import mapCapacity from './mapCapacity';

const { updateResources, updateResourcesLoading } = actions;
const { hideError } = notificationsOperations;

const createPayload = state => {
  const capacityFilters = roomFilterSelectors.getCapacityFilters(state);

  const { start, end } = selectors.getException(state);
  const resource = selectors.getResource(state);
  const { buildingId, capacity, resourceType, roomEquipment, equipmentType, roomType } = resource;
  const locations = { buildingIds: [buildingId] };

  const mapEquipment = item => item.roomEquipmentType;

  return {
    start,
    end,
    exactTime: true,
    locations,
    roomTypes: [roomType],
    capacities: mapCapacity(capacityFilters, capacity),
    equipment: roomEquipment ? roomEquipment.map(mapEquipment) : [],
    equipmentTypes: equipmentType ? [equipmentType] : [],
    resourceProfiles: ['allUsersAllowed'],
    recurrence: false,
    resourceTypes: [resourceType],
    pageSize: config.pageSize,
    skipCount: 0
  };
};

const loadResources = () => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);

  dispatch(updateResources([]));
  dispatch(updateResourcesLoading(true));
  dispatch(hideError());

  const payload = createPayload(state);
  return availabilityService
    .post(payload, false)
    .then(response => {
      const resources = response.results.map(result => ({
        ...result.resource,
        availabilities: result.availabilities
      }));
      dispatch(updateResources(resources));
    })
    .catch(serverError => {
      const error = getNotification('search.load.error', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => dispatch(updateResourcesLoading(false)));
};

export default {
  loadResources
};
