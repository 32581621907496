import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, withTranslation, CustomPropTypes } from '../../../../common';
import { bookSelectors, bookOperations } from '../../../../../state/book';
import { availabilityOperations } from '../../../../../state/availability';

import { isProfile2 } from '../../../../../util';

import './_style.css';

function FormSubmit(props) {
  const {
    small,
    isSubmitting,
    isValid,
    createBooking,
    openModal,
    resource,
    updateSelectedResource,
    loadAvailabilities,
    translate
  } = props;
  const disabled = isSubmitting || !isValid;

  const profile2 = isProfile2(resource);
  const bookNowText = translate('search.now.small');
  const requestNowText = translate(small ? 'search.now.profile2.small' : 'search.now.profile2.long');
  const advancedText = translate(small ? 'search.advanced.small' : 'search.advanced.long');

  const classes = 'FormSubmit__Button';

  const bookNow = async () => {
    updateSelectedResource(resource);

    await createBooking();
    if (resource && (resource.isVirtual || resource.isVirtualMember)) {
      loadAvailabilities();
    }
  };

  return (
    <div className="FormSubmit__ButtonGroup">
      <Button
        className={classes}
        label={advancedText}
        onClick={e => {
          updateSelectedResource(resource);
          openModal(e);
          // This fixed the weird IE bug where the
          // placeholder of the next clicked input field was editable
          document.body.focus();
        }}
        secondary
      />
      <Button
        data-ignore="true"
        className={classes}
        disabled={disabled}
        loading={isSubmitting}
        onClick={bookNow}
        label={profile2 ? requestNowText : bookNowText}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  isValid: bookSelectors.isValidTimeRange(state),
  isSubmitting: bookSelectors.isSubmitting(state)
});

const mapDispatchToProps = dispatch => ({
  createBooking: () => dispatch(bookOperations.createBooking()),
  loadAvailabilities: () => dispatch(availabilityOperations.loadAvailabilities(true)),
  updateSelectedResource: room => dispatch(bookOperations.updateSelectedResource(room))
});

FormSubmit.propTypes = {
  resource: CustomPropTypes.resource,
  small: PropTypes.bool,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  createBooking: PropTypes.func.isRequired,
  updateSelectedResource: PropTypes.func.isRequired,
  loadAvailabilities: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(FormSubmit));
