import types from '../types';

const updateSelectedMaster = master => ({
  type: types.UPDATE_SELECTED_MASTER,
  master
});

const updateSubject = subject => ({
  type: types.UPDATE_SUBJECT,
  subject
});

const updateMessage = message => ({
  type: types.UPDATE_MESSAGE,
  message
});

const updateReminder = reminder => ({
  type: types.UPDATE_REMINDER,
  reminder
});

const updateIsLoading = isLoading => ({
  type: types.UPDATE_IS_LOADING,
  isLoading
});

const cacheSelectedMaster = () => ({
  type: types.CACHE_SELECTED_MASTER
});

const restoreSelectedMaster = () => ({
  type: types.RESTORE_SELECTED_MASTER
});

export default {
  updateSelectedMaster,
  updateSubject,
  updateMessage,
  updateReminder,
  updateIsLoading,
  cacheSelectedMaster,
  restoreSelectedMaster
};
