import omit from 'lodash/fp/omit';

import actions from '../actions';
import selectors from '../selectors';
import { bookSelectors } from '..';
import { recurrenceSelectors, recurrenceOperations } from '../../recurrence';
import { notificationsSelectors, notificationsOperations } from '../../notifications';
import { exceptionsOperations } from '../../exceptions';
import { availabilityService } from '../../../services';

const { updateResourceAvailabilitiesLoading, updateResourceAvailabilities } = actions;

const updateResource = () => (dispatch, getState) => {
  const state = getState();
  const resource = selectors.getSelectedResource(state);
  dispatch(exceptionsOperations.updateResource(resource));
};

const loadSingleAvailability = () => async (dispatch, getState) => {
  const state = getState();
  const resource = selectors.getSelectedResource(state);
  const isRecurrence = recurrenceSelectors.getRecurrenceType(state) !== 'never';

  if (!resource || !isRecurrence) {
    return null;
  }
  const recurrence = recurrenceSelectors.getRecurrenceRequest(state);

  const start = bookSelectors.getStartDateTime(state);
  const end = bookSelectors.getEndDateTime(state);
  const isValidTimeRange = bookSelectors.isValidTimeRange(state);
  if (!isValidTimeRange) {
    dispatch(recurrenceOperations.updateRecurrenceType('never'));
    return Promise.reject(new Error('Timerange not valid'));
  }

  dispatch(updateResourceAvailabilitiesLoading(false));

  const requestBody = {
    recurrence,
    start,
    end,
    resourceTypes: ['room']
  };

  try {
    const resourceWithAvailabilities = await availabilityService.single(resource.id, requestBody);
    const { nonAvailableOccurrences } = resourceWithAvailabilities;
    resourceWithAvailabilities.nonAvailableOccurrences = nonAvailableOccurrences.map(exception => {
      const newException = omit('suggestedAlternativeResource', exception);
      newException.resource = exception.suggestedAlternativeResource;
      return newException;
    });
    dispatch(updateResourceAvailabilities(resourceWithAvailabilities));
    dispatch(updateResource());
    return resourceWithAvailabilities;
  } catch (serverError) {
    const getNotification = notificationsSelectors.getNotification(state);
    const error = getNotification(
      'search.recurrence.exceptions.notifications.singleAvailabilityError',
      serverError
    );
    dispatch(notificationsOperations.showError(error));
    return serverError;
  }
};

export default { loadSingleAvailability };
