import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  bookingCollectionSelectors,
  bookingCollectionOperations
} from '../../../state/bookingCollection';
import CollectionLoadMore from './CollectionLoadMore';
import CollectionResultsInlineLoader from './CollectionSearchResultsInlineLoader';

class CollectionSearchResultSection extends Component {
  onShowMore = () => {
    const { isLoading, hasMore, loadMore } = this.props;
    if (!isLoading && hasMore) {
      loadMore();
    }
  };

  render() {
    const { isLoading, hasMore, children } = this.props;
    const showLoadMore = hasMore && !isLoading;

    return (
      <div className="CollectionSearchResults__Section">
        {children}
        <CollectionLoadMore visible={showLoadMore} onClick={this.onShowMore} />
        <CollectionResultsInlineLoader visible={isLoading} />
      </div>
    );
  }
}

const mapStateToProps = (state, { type }) => ({
  hasMore: bookingCollectionSelectors.getHasMore(type)(state),
  isLoading: bookingCollectionSelectors.getIsLoading(type)(state)
});

const mapDispatchToProps = (dispatch, { type }) => ({
  loadMore: () => dispatch(bookingCollectionOperations.loadMoreAlternatives(type))
});

CollectionSearchResultSection.propTypes = {
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  loadMore: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionSearchResultSection);
