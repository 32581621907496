import types from './types';

const updateSearchTerm = searchTerm => ({
  type: types.UPDATE_SEARCH_TERM,
  searchTerm
});

const updateIsLoading = isLoading => ({
  type: types.UPDATE_IS_LOADING,
  isLoading
});

const updateSuggestedRooms = suggestedRooms => ({
  type: types.UPDATE_SUGGESTED_ROOMS,
  suggestedRooms
});

const updateSelectedRooms = selectedRooms => ({
  type: types.UPDATE_SELECTED_ROOMS,
  selectedRooms
});

export default {
  updateSearchTerm,
  updateIsLoading,
  updateSuggestedRooms,
  updateSelectedRooms
};
