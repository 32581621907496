import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import isEqual from 'lodash/fp/isEqual';

import { bookSelectors } from '../../../../state/book';
import { bookingCollectionOperations } from '../../../../state/bookingCollection';
import { Icon, CustomPropTypes, withTranslation } from '../../../common';

import './_style.css';

const getTimeFromAvailability = resource => {
  if (!resource.availabilities || resource.availabilities.length === 0) {
    return null;
  }
  const availability = resource.availabilities[0];
  const from = moment(availability.start).format('HH:mm');
  const to = moment(availability.end).format('HH:mm');
  return { from, to };
};

function BookFormCollectionResources({ resources, resourceType, time, remove, translate }) {
  const title = translate(`bookingCollection.addedBookings.${resourceType}`);

  const mapResources = resource => {
    const { id, name, building } = resource;
    const collectionResourceTime = getTimeFromAvailability(resource);
    const { from, to } = collectionResourceTime;
    const key = id + from + to;
    const isSameTime = isEqual(collectionResourceTime, time);
    const timeDisplay = isSameTime ? '' : `(${from} - ${to})`;
    const link = `/bookingcollection/${id}/remove`;
    const linkText = `${name}, ${building.key} ${timeDisplay}`.trimRight();
    const removeItem = () => remove(id);
    return (
      <li key={key}>
        <div className="BookFormCollectionResource">
          <Link to={link}>{linkText}</Link>
          <div
            aria-label="Remove"
            role="button"
            tabIndex="0"
            onClick={removeItem}
            className="BookFormCollectionResource__RemoveButton"
          >
            <Icon icon="022-close" className="BookFormCollectionResource__RemoveIcon" />
          </div>
        </div>
      </li>
    );
  };

  return (
    <div className="BookFormCollectionResources">
      <div className="BookFormCollectionResources__Content">
        <p className="BookFormCollectionResources__Title">{title}</p>
        <ul>{resources.map(mapResources)}</ul>
      </div>
    </div>
  );
}

BookFormCollectionResources.propTypes = {
  resources: PropTypes.arrayOf(CustomPropTypes.resource),
  time: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }),
  resourceType: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  time: bookSelectors.getTime(state)
});

const mapDispatchToProps = dispatch => ({
  remove: resourceId => dispatch(bookingCollectionOperations.removeResource(resourceId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(BookFormCollectionResources));
