import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

import './_style.css';

function Tooltip({ id, float, place, children, className, small }) {
  const classes = cx('Tooltip', className, {
    'Tooltip--small': small
  });

  return (
    <ReactTooltip place={place} float={float} id={id} className={classes} type="light">
      {children}
    </ReactTooltip>
  );
}

Tooltip.propTypes = {
  id: PropTypes.string.isRequired,
  place: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
  float: PropTypes.bool,
  className: PropTypes.string
};

Tooltip.defaultProps = {
  float: true,
  place: 'right',
  small: false
};

export default Tooltip;
