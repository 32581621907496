const ADD_ROOMS = 'timelineBookings/ADD_ROOMS';
const ADD_BOOKINGS = 'timelineBookings/ADD_BOOKINGS';
const ADD_ROOM_BOOKINGS = 'timelineBookings/ADD_ROOM_BOOKINGS';
const UPDATE_ROOMS = 'timelineBookings/UPDATE_ROOMS';
const UPDATE_BOOKINGS = 'timelineBookings/UPDATE_BOOKINGS';
const UPDATE_ROOM_BOOKINGS = 'timelineBookings/UPDATE_ROOM_BOOKINGS';
const UPDATE_BOOKING = 'timelineBookings/UPDATE_BOOKING';
const REMOVE_BOOKING = 'timelineBookings/REMOVE_BOOKING';
const REMOVE_RECURRING = 'timelineBookings/REMOVE_RECURRING';
const UPDATE_SKIPCOUNT = 'timelineBookings/UPDATE_SKIPCOUNT';
const UPDATE_TOTALCOUNT = 'timelineBookings/UPDATE_TOTALCOUNT';
const UPDATE_LOADING = 'timelineBookings/UPDATE_LOADING';
const UPDATE_LOADING_MORE = 'roomBookings/UPDATE_LOADING_MORE';
const CACHE_BOOKINGS = 'timelineBookings/CACHE_BOOKINGS';
const RESET_BOOKINGS_FROM_CACHE = 'timelineBookings/RESET_BOOKINGS_FROM_CACHE';
const EXPORT_LOADING = 'roomBookings/EXPORT_LOADING';

export default {
  ADD_ROOMS,
  ADD_BOOKINGS,
  ADD_ROOM_BOOKINGS,
  UPDATE_ROOMS,
  UPDATE_BOOKINGS,
  UPDATE_ROOM_BOOKINGS,
  UPDATE_BOOKING,
  REMOVE_BOOKING,
  REMOVE_RECURRING,
  UPDATE_SKIPCOUNT,
  UPDATE_TOTALCOUNT,
  UPDATE_LOADING,
  UPDATE_LOADING_MORE,
  CACHE_BOOKINGS,
  RESET_BOOKINGS_FROM_CACHE,
  EXPORT_LOADING
};
