import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Header from '../../../Header';
import { withTranslation } from '../../../common';

const getShortDate = date => moment(date).format('DD.MM.YYYY');

function RecurrenceExceptionModalHeader({ isMobile, date, onClose, translate }) {
  const shortDate = getShortDate(date);
  const title = translate('search.recurrence.exceptions.title', {
    date: shortDate
  });
  const commonProps = {
    title,
    onClose,
    icon: '014-chevron-left'
  };

  function MobileHeader() {
  return <Header {...commonProps} dynamic fixedMobile />;
}
  function DesktopHeader() {
  return <Header {...commonProps} fixed={false} />;
}
  return isMobile ? <MobileHeader /> : <DesktopHeader />;
}

RecurrenceExceptionModalHeader.propTypes = {
  isMobile: PropTypes.bool,
  date: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurrenceExceptionModalHeader);
