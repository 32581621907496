import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import RollyIcon from './RollyIcon';
import AudioIcon from './AudioIcon';
import ScreenIcon from './ScreenIcon';
import BeamerIcon from './BeamerIcon';
import FlipchartIcon from './FlipchartIcon';
import WhiteboardIcon from './WhiteboardIcon';
import WebexboardIcon from './WebexboardIcon';
import SurfaceHubIcon from './SurfaceHubIcon';
import TelepresenceIcon from './TelepresenceIcon';
import ModeratorCaseIcon from './ModeratorCaseIcon';
import MobileEquipmentIcon from './MobileEquipmentIcon';
import ClickShareIcon from './ClickShareIcon';

import DiscIcon from './DiscIcon';
import PhotoCamIcon from './PhotoCamIcon';
import PrinterIcon from './PrinterIcon';
import RouterIcon from './RouterIcon';
import SmartphoneIcon from './SmartphoneIcon';
import TabletIcon from './TabletIcon';
import VideoCamIcon from './VideoCamIcon';
import WorkstationIcon from './WorkstationIcon';
import DiverseIcon from './DiverseIcon';

import Tooltip from '../Tooltip/Tooltip';
import identifier from '../identifier';
import withTranslation from '../I18n/withTranslation';

import './_style.css';

class TrayIcons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipId: identifier('IconTooltip'),
      activeModalText: null
    };
    this.renderIcon = this.renderIcon.bind(this);
  }

  renderIcon(typeOfEquipment = '', disabled = false) {
    const { translate } = this.props;
    const { tooltipId } = this.state;
    const lowerCaseFirstLetter = text => text.charAt(0).toLowerCase() + text.slice(1);

    const type = typeOfEquipment.toLocaleLowerCase();
    const trayClass = cx('Tray-Item', {
      'Tray-Item--small': type === 'rolly' || type === 'flipchart'
    });
    const props = {
      className: trayClass,
      disabled,
      'data-tooltip-content': translate(`equipment.${lowerCaseFirstLetter(typeOfEquipment)}`),
      'data-tooltip-id': tooltipId
    };

    const icons = {
      audio: <AudioIcon {...props} />,
      rolly: <RollyIcon {...props} />,
      beamer: <BeamerIcon {...props} />,
      screen: <ScreenIcon {...props} />,
      flipchart: <FlipchartIcon {...props} />,
      surfacehub: <SurfaceHubIcon {...props} />,
      whiteboard: <WhiteboardIcon {...props} />,
      webexboard: <WebexboardIcon {...props} />,
      telepresence: <TelepresenceIcon {...props} />,
      moderatorcase: <ModeratorCaseIcon {...props} />,
      mobileequipment: <MobileEquipmentIcon {...props} />,
      clickshare: <ClickShareIcon {...props} />,
      brenner: <DiscIcon {...props} />,
      digicam: <PhotoCamIcon {...props} />,
      printer: <PrinterIcon {...props} />,
      router: <RouterIcon {...props} />,
      mobile: <SmartphoneIcon {...props} />,
      tablet: <TabletIcon {...props} />,
      videocam: <VideoCamIcon {...props} />,
      desktop: <WorkstationIcon {...props} />,
      diverse: <DiverseIcon {...props} />
    };
    return icons[type] || null;
  }

  render() {
    const { equipment = [], className } = this.props;
    const { activeModalText, tooltipId } = this.state;

    if (equipment.length < 1) {
      return null;
    }
    const classes = cx('TrayIcons', className);
    const sortedEquipment = equipment.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div className={classes}>
        <Tooltip id={tooltipId} float place="top" small>
          <p>{activeModalText}</p>
        </Tooltip>
        {sortedEquipment.map(item => (
          <div key={item.id}>{this.renderIcon(item.roomEquipmentType, item.disabled)}</div>
        ))}
      </div>
    );
  }
}

TrayIcons.propTypes = {
  equipment: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      roomEquipmentType: PropTypes.string
    })
  ),
  className: PropTypes.string,
  translate: PropTypes.func.isRequired
};

export default withTranslation(TrayIcons);
