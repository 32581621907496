import types from './types';

const showError = error => ({
  type: types.SHOW_ERROR,
  error
});

const hideError = () => ({
  type: types.HIDE_ERROR
});

const showSuccess = success => ({
  type: types.SHOW_SUCCESS,
  success
});

const hideSuccess = () => ({
  type: types.HIDE_SUCCESS
});

export default {
  showError,
  hideError,
  showSuccess,
  hideSuccess
};
