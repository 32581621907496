import { combineReducers } from 'redux';
import store from 'store2';
import types from './types';

const loadInitialState = () => {
  const config = store('config');
  const isLoaded = store.has('config');
  return {
    config,
    isLoaded
  };
};

const initialState = loadInitialState();

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_CONFIG: {
      return {
        ...state,
        config: action.config
      };
    }

    case types.LOAD_CONFIG: {
      return {
        ...state,
        config: action.config
      };
    }

    case types.UPDATE_LOADED: {
      return {
        ...state,
        isLoaded: true,
        age: action.age
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  config: configReducer
});
