import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ResourcesSubTypesDeleteDialog from './ResourcesSubTypesDeleteDialog';

import { withTranslation, Icon } from '../../../common';
import { resourcesSubTypesOperations } from '../../../../state/resourcesSubTypes';

class ResourcesSubTypesTable extends Component {
  constructor() {
    super();
    this.tableWrapper = React.createRef();
    this.setHasScroll = this.setHasScroll.bind(this);
  }

  componentDidMount() {
    this.setHasScroll();
    window.addEventListener('resize', this.setHasScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHasScroll);
  }

  setHasScroll() {
    const { onTableScrollBar } = this.props;
    const maxHeight = window.innerHeight - 300; // same maxHeight as style .scrollable_table__wrapper
    const box = this.tableWrapper.current?.getBoundingClientRect();
    const hasScroll = box.height >= maxHeight;
    onTableScrollBar(hasScroll);
  }

  renderHeaderRow = () => {
    const { translate } = this.props;

    const columns = [
      translate('admin.settings.resourcesSubTypes.table.header.resourceType'),
      translate('admin.settings.resourcesSubTypes.table.header.nameEN'),
      translate('admin.settings.resourcesSubTypes.table.header.nameFR'),
      translate('admin.settings.resourcesSubTypes.table.header.nameDE'),
      translate('admin.settings.resourcesSubTypes.table.header.nameIT'),
      ''
    ];

    const renderCell = value => <th key={value}>{value}</th>;

    return <tr>{columns.map(renderCell)}</tr>;
  };

  renderRow = resourcesSubType => {
    const cells = [
      { resourceType: resourcesSubType.resourceType },
      { nameEN: resourcesSubType.nameEN },
      { nameFR: resourcesSubType.nameFR },
      { nameDE: resourcesSubType.nameDE },
      { nameIT: resourcesSubType.nameIT }
    ];

    const setUpModal = () => {
      const { actionOnClick, setSelectedResourcesSubType } = this.props;
      setSelectedResourcesSubType(resourcesSubType);
      actionOnClick();
    };

    const renderCell = (item, index) => {
      const value = Object.values(item)[0];

      return (
        <td key={index}>
          {value}
        </td>
      );
    };

    const redirect = () => {
      const { history } = this.props;
      const url = `/admin/settings/resourcessubtypes/${ resourcesSubType.id }`;
      history.push(url);
    };

    return (
      <tr
        key={resourcesSubType.id}
        style={{ borderLeft: `solid 8px ${ resourcesSubType.color ? resourcesSubType.color : '#fff' }` }}>
        {cells.map((value, index) => renderCell(value, index))}
        <td aria-label="Delete">
          <Icon icon="008-bin" className="Delete__Icon" onClick={setUpModal} />
          <Icon
            icon="025-edit"
            className="Edit__Icon"
            onClick={redirect} />
        </td>
      </tr>
    );
  };

  render() {
    const { resourcesSubTypes } = this.props;
    const resourcesSubTypesArray = Object.entries(resourcesSubTypes).map(item => (
      {
        id: item[1].id,
        color: item[1].color,
        resourceType: item[1].resourceType,
        nameEN: item[1].nameEN,
        nameFR: item[1].nameFR,
        nameDE: item[1].nameDE,
        nameIT: item[1].nameIT

      }));
    // sorted by resourceType and NameEN asc
    resourcesSubTypesArray.sort((a, b) => {
      if (a.resourceType + a.nameEN > b.resourceType + b.nameEN) return 1;
      if (a.resourceType + a.nameEN < b.resourceType + b.nameEN) return -1;
      return 0;
    });

    return (
      <>
        <div className="table table--responsive">
          <div className="scrollable_table__wrapper" ref={this.tableWrapper}>
            <table>
              <thead>{this.renderHeaderRow()}</thead>
              <tbody>
                {resourcesSubTypesArray.map(this.renderRow)}
              </tbody>
            </table>
          </div>
        </div>
        <ResourcesSubTypesDeleteDialog />
      </>
    );
  }
}

ResourcesSubTypesTable.propTypes = {
  resourcesSubTypes: PropTypes.objectOf(PropTypes.any),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired,
  actionOnClick: PropTypes.func.isRequired,
  setSelectedResourcesSubType: PropTypes.func.isRequired,
  onTableScrollBar: PropTypes.func.isRequired
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  setSelectedResourcesSubType: resourcesSubType => dispatch(
    resourcesSubTypesOperations.setSelectedResourcesSubType(resourcesSubType)
  )
});

const ResourcesSubTypesTableComponent = withTranslation(ResourcesSubTypesTable);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResourcesSubTypesTableComponent));
