import config from '../config';
import fetch from './fetch';

const getUrl = path => `${config.remoteUrl}/${path}`;

async function getNews(pageSize = 5, skipCounter = 0) {
  const url = getUrl(`news?pageSize=${pageSize}&skipCount=${skipCounter}`);
  return fetch(url, 'GET');
}

async function getNewsAdmin() {
  const url = getUrl('news/admin');
  return fetch(url, 'GET');
}

async function getNewsById(id) {
  const url = getUrl(`news/${id}`);
  return fetch(url, 'GET');
}

async function createNews(payload) {
  const url = getUrl('news');
  return fetch(url, 'POST', payload);
}

async function updateNews(id, payload) {
  const url = getUrl(`news/${id}`);
  return fetch(url, 'PUT', payload);
}

async function removeNews(id) {
  const url = getUrl(`news/${id}`);
  return fetch(url, 'DELETE');
}

const service = {
  getNews,
  getNewsAdmin,
  getNewsById,
  createNews,
  updateNews,
  removeNews
};

export default service;
