import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import omit from 'lodash/fp/omit';
import cx from 'classnames';

import { Button } from '../../../common';

import './_style.css';

class FlyoutButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFlyout: false
    };

    this.openFlyout = this.openFlyout.bind(this);
    this.closeFlyout = this.closeFlyout.bind(this);
  }

  handleClickOutside() {
    this.closeFlyout();
  }

  openFlyout() {
    this.setState({ showFlyout: true });
  }

  closeFlyout() {
    this.setState({ showFlyout: false });
  }

  render() {
    const { showFlyout } = this.state;
    const { options, outerClassName, isMobile } = this.props;

    const optionElements = options.map((option, index, arr) => (
      <div key={option.label}>
        <button
          type="button"
          className={cx('FlyoutButton__Option', {
            'FlyoutButton__Option--mobile': isMobile,
            'FlyoutButton__Option--desktop': !isMobile
          })}
          onClick={() => {
            this.closeFlyout();
            option.onClick();
          }}
        >
          {option.label}
        </button>
        {index !== arr.length - 1 && <div className="FlyoutButton__Seperator" />}
      </div>
    ));

    const wrapperMargin = options.length * -60;

    const passProps = omit(
      [
        'options',
        'outerClassName',
        'isMobile',
        'eventTypes',
        'outsideClickIgnoreClass',
        'preventDefault',
        'stopPropagation',
        'disableOnClickOutside',
        'enableOnClickOutside'
      ],
      this.props
    );

    return (
      <div className={outerClassName}>
        {showFlyout && (
          <div
            className={cx('FlyoutButton__Wrapper', {
              'FlyoutButton__Wrapper--desktop ArrowBox ArrowDown': !isMobile,
              'FlyoutButton__Wrapper--mobile': isMobile
            })}
            style={!isMobile ? { marginTop: wrapperMargin - 20 } : { marginTop: wrapperMargin }}
          >
            {optionElements}
          </div>
        )}
        <Button {...passProps} onClick={this.openFlyout} />
      </div>
    );
  }
}

FlyoutButton.propTypes = {
  outerClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      label: PropTypes.string
    })
  ),
  isMobile: PropTypes.bool
};

export default onClickOutside(FlyoutButton);
