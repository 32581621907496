import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Modal, ShowAt } from '../../common';
import { bookSelectors, bookOperations } from '../../../state/book';
import { recurrenceSelectors, recurrenceOperations } from '../../../state/recurrence';
import { bookingCollectionSelectors } from '../../../state/bookingCollection';
import { modalOperations } from '../../../state/modal';

import BookCreateModalDesktop from './BookCreateModalDesktop';
import BookCreateModalMobile from './BookCreateModalMobile';

import keys from '../modalKeys';

import '../_style.css';

function BookCreateModal(props) {
  const { clearModals } = props;

  return (
    <div>
      <ShowAt breakpoint="900AndAbove">
        <Modal
          modalKey={[keys.BOOK_CREATE_MODAL, keys.BOOKING_COLLECTION_INFORMATION_DIALOG]}
          onOverlayClick={clearModals}
        >
          <BookCreateModalDesktop {...props} />
        </Modal>
      </ShowAt>
      <ShowAt breakpoint="900AndBelow">
        <Modal modalKey={keys.BOOK_CREATE_MODAL} mobile>
          <BookCreateModalMobile {...props} />
        </Modal>
      </ShowAt>
    </div>
  );
}

const mapStateToProps = state => ({
  selectedResource: bookSelectors.getSelectedResource(state),
  subject: bookSelectors.getSubject(state),
  date: bookSelectors.getDate(state),
  time: bookSelectors.getTime(state),
  isValidDate: bookSelectors.isValidDate(state),
  isDateAfterMax: bookSelectors.isDateAfterMax(state),
  isValidTimeRange: bookSelectors.isValidTimeRange(state),
  isMessageValid: bookSelectors.isMessageValid(state),
  isSubjectValid: bookSelectors.isSubjectValid(state),
  isReadyToSubmit: bookSelectors.isReadyToSubmit(state),
  isSubmitting: bookSelectors.isSubmitting(state),
  message: bookSelectors.getMessage(state),
  reminder: bookSelectors.getReminder(state),
  additionalEquipment: bookSelectors.getAdditionalEquipment(state),
  recurrenceType: recurrenceSelectors.getRecurrenceType(state),
  isRecurrence: recurrenceSelectors.isRecurrenceEnabled(state),
  isBookingCollection: bookingCollectionSelectors.isBookingCollection(state),
  showBookingCollectionWarning: bookingCollectionSelectors.showBookingCollectionWarning(state),
  bookForPerson: bookSelectors.getBookForPerson(state),
  bookForLoading: bookSelectors.isBookForLoading(state),
  bookForSuggestions: bookSelectors.getBookForSuggestions(state),
  attendeesSearchTerm: bookSelectors.getAttendeesSearchTerm(state),
  attendeesSuggestions: bookSelectors.getAttendeesSuggestions(state),
  selectedAttendees: bookSelectors.getSelectedAttendees(state),
  attendeesLoading: bookSelectors.areAttendeesLoading(state),
  collectionResources: {
    room: bookingCollectionSelectors.getSelectedRooms(state),
    equipment: bookingCollectionSelectors.getSelectedEquipments(state)
  }
});

const mapDispatchToProps = dispatch => ({
  updateDate: date => dispatch(bookOperations.updateDate(date)),
  updateTime: time => dispatch(bookOperations.updateTime(time)),
  updateIsValidTimeRange: isTimeValid => dispatch(bookOperations.updateIsValidTimeRange(isTimeValid)),
  updateSubject: subject => dispatch(bookOperations.updateSubject(subject)),
  updateMessage: message => dispatch(bookOperations.updateMessage(message)),
  updatePerson: person => dispatch(bookOperations.updateBookForPerson(person)),
  updateReminder: reminder => dispatch(bookOperations.updateReminder(reminder)),
  loadSuggestions: term => dispatch(bookOperations.loadBookForSuggestions(term)),
  updateTerm: term => dispatch(bookOperations.setAttendeesSearchTerm(term)),
  updateSelectedPeople: attendees => dispatch(bookOperations.updateSelectedAttendees(attendees)),
  fetchPeople: () => dispatch(bookOperations.fetchAttendees()),
  clearModals: () => {
    dispatch(recurrenceOperations.resetState());
    dispatch(modalOperations.clearModals());
  }
});

BookCreateModal.propTypes = {
  clearModals: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(BookCreateModal);
