import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/fp/omit';
import identifier from '../identifier';

import './_style.css';

class Checkbox extends Component {
  constructor(props) {
    super(props);

    const { id } = props;
    this.state = {
      id: id || identifier()
    };
  }

  render() {
    const { id } = this.state;
    const { label, description, inverted, ...props } = this.props;
    const otherProps = omit(['id'], props);
    const classes = classNames('checkbox', {
      'checkbox--inverted': inverted
    });

    return (
      <div className={classes}>
        <input id={id} type="checkbox" {...otherProps} />
        <label htmlFor={id}>{label}</label>
        {description && <p>{description}</p>}
      </div>
    );
  }
}

Checkbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.string,
  inverted: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func
};

export default Checkbox;
