import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/fp/isEqual';

import { Modal, ShowAt, CustomPropTypes } from '../../common';
import { modalOperations } from '../../../state/modal';
import { exceptionsSelectors, exceptionsOperations } from '../../../state/exceptions';
import RecurrenceExceptionModalDesktop from './RecurrenceExceptionModalDesktop';
import RecurrenceExceptionModalMobile from './RecurrenceExceptionModalMobile';
import { makeSelectable, select, getSelected } from './resourceSelectionUtil';
import keys from '../modalKeys';

class RecurrenceExceptionModal extends Component {
  constructor(props) {
    super(props);

    const { resources } = this.props;
    this.state = {
      selectedResources: makeSelectable(resources)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedResources } = this.state;
    const { exception, resources } = this.props;
    const { exception: nextException, resources: nextResources } = nextProps;

    const willExceptionChange = exception !== nextException;
    const willResourcesChange = !isEqual(resources, nextResources);
    if (willExceptionChange || willResourcesChange) {
      const selectedResource = getSelected(selectedResources);
      const updatedResources = willResourcesChange ? makeSelectable(nextResources) : [];
      this.setState({
        selectedResources: select(updatedResources, selectedResource)
      });
    }
  }

  goToNextException = () => {
    const { nextException, onNext, onClose } = this.props;
    if (nextException) {
      onNext(nextException);
    } else {
      onClose();
    }
  };

  selectResource = resource => {
    const { selectedResources } = this.state;
    this.setState({
      selectedResources: select(selectedResources, resource)
    });
  };

  patchException = resource => {
    const { start: exceptionStart, end: exceptionEnd } = resource.availabilities[0];
    const { onPatch, exception: previousException } = this.props;
    const { start, end, calendarId } = previousException;
    const exception = {
      calendarId,
      start,
      end,
      resource,
      exceptionStart,
      exceptionEnd
    };
    onPatch(exception).then(() => this.goToNextException());
  };

  render() {
    const {
      onExit,
      onClose,
      showEmptyState,
      isLoading,
      isInProgress,
      exception,
      nextException
    } = this.props;
    const { selectedResources } = this.state;

    const props = {
      onClose,
      exception,
      isLoading,
      isInProgress,
      showEmptyState,
      isLastException: nextException === null,
      resources: selectedResources,
      onNext: this.goToNextException,
      onSelect: this.selectResource,
      onBook: this.patchException
    };

    const modalKey = keys.RECURRENCE_EXCEPTION_MODAL;

    return (
      <div>
        <ShowAt breakpoint="900AndAbove">
          <Modal modalKey={modalKey} onOverlayClick={onExit}>
            <RecurrenceExceptionModalDesktop {...props} />
          </Modal>
        </ShowAt>
        <ShowAt breakpoint="900AndBelow">
          <Modal modalKey={modalKey} mobile>
            <RecurrenceExceptionModalMobile {...props} />
          </Modal>
        </ShowAt>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  exception: exceptionsSelectors.getException(state),
  nextException: exceptionsSelectors.getNextException(state),
  resource: exceptionsSelectors.getResource(state),
  resources: [
    ...exceptionsSelectors.getResources(state),
    ...exceptionsSelectors.getAlternativeResources(state)
  ],
  isLoading: exceptionsSelectors.areResourcesLoading(state),
  isInProgress: exceptionsSelectors.isInProgress(state),
  showEmptyState: exceptionsSelectors.showEmptyState(state)
});

const mapDispatchToProps = dispatch => {
  const onExit = () => dispatch(modalOperations.clearModals());
  const onClose = () => dispatch(modalOperations.popModal());
  const onPatch = exception => dispatch(exceptionsOperations.patchException(exception));
  const onNext = nextException => {
    dispatch(exceptionsOperations.updateException(nextException));
    dispatch(exceptionsOperations.loadResources());
    dispatch(exceptionsOperations.loadAlternatives());
  };

  return {
    onExit,
    onClose,
    onPatch,
    onNext
  };
};

RecurrenceExceptionModal.propTypes = {
  resource: CustomPropTypes.resource,
  resources: PropTypes.arrayOf(CustomPropTypes.resource),
  exception: CustomPropTypes.exception,
  nextException: CustomPropTypes.exception,
  isLoading: PropTypes.bool.isRequired,
  isInProgress: PropTypes.bool.isRequired,
  showEmptyState: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onPatch: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecurrenceExceptionModal);
