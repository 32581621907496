import React from 'react';
import PropTypes from 'prop-types';

import { Icon, withTranslation } from '../../../common';

import './_style.css';

function PrivateInformation({ translate }) {
  return (
    <div className="PrivateInformation">
      <Icon icon="049-lock" />
      {translate('common.private')}
    </div>
  );
}

PrivateInformation.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslation(PrivateInformation);
