import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { changeOwnerSelectors, changeOwnerOperations } from '../../../../state/changeOwner';
import OwnerForm from './OwnerForm';

import './_style.css';

class OwnerDetail extends Component {
  componentDidMount() {
    const { initializeData } = this.props;
    initializeData();
  }

  render() {
    const { changeRequest, ...props } = this.props;
    const { readOnly, status } = changeRequest;

    const isReadonly = readOnly || status === 'Approved' || status === 'Rejected';

    return (
      <div className="OwnerForm">
        <OwnerForm readOnly={isReadonly} {...props} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: changeOwnerSelectors.getProfile(state),
  profiles: changeOwnerSelectors.getProfiles(state),
  reason: changeOwnerSelectors.getReason(state),
  isValid: changeOwnerSelectors.isValid(state),
  hasChanges: changeOwnerSelectors.hasChanges(state),
  isContactLoading: changeOwnerSelectors.isContactLoading(state),
  contactSuggestions: changeOwnerSelectors.getContactSuggestions(state),
  isOwnerLoading: changeOwnerSelectors.isOwnerLoading(state),
  ownerSuggestions: changeOwnerSelectors.getOwnerSuggestions(state)
});

const mapDispatchToProps = (dispatch, { changeRequest }) => ({
  initializeData: () => dispatch(changeOwnerOperations.initializeData(changeRequest)),
  selectProfile: type => dispatch(changeOwnerOperations.selectProfile(type)),
  updateReason: reason => dispatch(changeOwnerOperations.updateReason(reason)),
  updateContact: (profile, contact) => dispatch(changeOwnerOperations.updateContact(profile, contact)),
  updateOwners: (profile, owners) => dispatch(changeOwnerOperations.updateOwners(profile, owners)),
  loadContactSuggestions: term => dispatch(changeOwnerOperations.loadContactSuggestions(term)),
  loadOwnerSuggestions: term => dispatch(changeOwnerOperations.loadOwnerSuggestions(term)),
  submit: () => dispatch(changeOwnerOperations.submit())
});

OwnerDetail.propTypes = {
  changeRequest: PropTypes.shape({
    readOnly: PropTypes.any,
    status: PropTypes.any
  }),
  initializeData: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnerDetail);
