import PropTypes from 'prop-types';

export default PropTypes.shape({
  createdOn: PropTypes.string.isRequired,
  modifiedOn: PropTypes.string,
  buildingKey: PropTypes.string.isRequired,
  resourceName: PropTypes.string.isRequired,
  resourceType: PropTypes.oneOf(['Room', 'Equipment']),
  changeRequestType: PropTypes.oneOf([
    'Masterdata',
    'Delete',
    'Profile',
    'Permissions',
    'NewResource'
  ]),
  changeRequestTypeDisplayName: PropTypes.string.isRequired,
  assignee: PropTypes.shape({}),
  status: PropTypes.oneOf([
    'New',
    'Approved',
    'Rejected',
    'Done',
    'InProgress',
    'Discarded',
    'Failed'
  ]).isRequired,
  statusDisplayName: PropTypes.string.isRequired
});
