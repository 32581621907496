import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { FormField, withTranslation } from '../../common';

import './_style.css';

function BookDetailTime({ start, end, translate }) {
  const timeTitle = translate('search.detail.time.name');
  const formatTime = time => moment(time).format('HH:mm');
  const label = <strong className="BookDetailKey">{timeTitle}</strong>;
  const element = `${formatTime(start)} - ${formatTime(end)}`;

  return <FormField className="BookDetail__FormField--last" label={label} element={element} />;
}

BookDetailTime.propTypes = {
  start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookDetailTime);
