import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isArray from 'lodash/fp/isArray';
import cx from 'classnames';

import Portal from '../Portal';
import ModalContent from './ModalContent';
import { modalSelectors } from '../../../state/modal';
import { iOS } from '../../../util';

const hasKey = (key, activeKey) => {
  if (!isArray(key)) {
    return key === activeKey;
  }

  return key.reduce((prev, current) => (prev === true ? true : current === activeKey), false);
};

function Modal(props) {
  const { modalKey, activeModalKey, mobile, children } = props;

  const hasModalKey = hasKey(modalKey, activeModalKey);
  if (!hasModalKey) {
    return null;
  }

  if (mobile) {
    const classes = cx('Modal', 'Modal--mobile', { 'Modal--ios': iOS() });
    return (
      <Portal id="modal" className={classes}>
        {children}
      </Portal>
    );
  }

  return (
    <Portal id="modal">
      <ModalContent {...props} />
    </Portal>
  );
}

Modal.propTypes = {
  modalKey: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  activeModalKey: PropTypes.string,
  mobile: PropTypes.bool,
  children: PropTypes.element.isRequired
};

Modal.defaultProps = {
  mobile: false
};

const mapStateToProps = state => ({
  activeModalKey: modalSelectors.getActiveModalKey(state)
});

export default connect(mapStateToProps)(Modal);
