import { createSelector } from 'reselect';

const getAttendeesSearchTerm = state => state.bookState.book.attendeesSearchTerm;
const getSuggestedAttendees = state => state.bookState.book.suggestedAttendees;
const areAttendeesLoading = state => state.bookState.book.attendeesIsLoading;
const getSelectedAttendees = state => state.bookState.book.selectedAttendees;

const getAttendeesSuggestions = createSelector(
  getSuggestedAttendees,
  suggestedAttendees => suggestedAttendees.map(attendee => ({
    name: `${attendee.givenname} ${attendee.surname}, ${attendee.department}`,
    value: attendee.accountName,
    id: attendee.accountName,
    email: attendee.email
  }))
);

export default {
  getAttendeesSearchTerm,
  getAttendeesSuggestions,
  getSelectedAttendees,
  areAttendeesLoading
};
