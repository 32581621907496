const UPDATE_RATING = 'feedback/SET_RATING';
const UPDATE_HAPPY_COMMENT = 'feedback/SET_HAPPY_COMMENT';
const UPDATE_SAD_COMMENT = 'feedback/SET_SAD_COMMENT';
const HAS_TRIED_TO_SUBMIT = 'feedback/HAS_TRIED_TO_SUBMIT';
const HAS_SUCCEEDED = 'feedback/HAS_SUCCEEDED';
const HAS_SYSTEM_ERROR = 'feedback/HAS_SYSTEM_ERROR';
const SUBMITTING = 'feedback/SUBMITTING';
const CLEAR = 'feedback/CLEAR';

export default {
  UPDATE_RATING,
  UPDATE_HAPPY_COMMENT,
  UPDATE_SAD_COMMENT,
  HAS_TRIED_TO_SUBMIT,
  HAS_SUCCEEDED,
  HAS_SYSTEM_ERROR,
  SUBMITTING,
  CLEAR
};
