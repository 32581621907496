import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import omit from 'lodash/fp/omit';
import identifier from '../identifier';
import InputField from './InputField';

class Input extends Component {
  constructor(props) {
    super(props);

    const { id, label } = props;
    this.state = {
      id: id || React.isValidElement(label) ? identifier() : identifier(label)
    };

    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
    this.setSelectionRange = this.setSelectionRange.bind(this);
  }

  setSelectionRange(...args) {
    this.input.setSelectionRange(...args);
  }

  get selectionStart() {
    this.input.focus();
    return this.input.selectionStart;
  }

  get selectionEnd() {
    return this.input.selectionEnd;
  }

  get hasFocus() {
    return this.input === document.activeElement;
  }

  focus() {
    this.input.focus();
  }

  blur() {
    this.input.blur();
  }

  render() {
    const { id } = this.state;
    const { label, value, valid, error, className, onChange } = this.props;

    const inputRef = input => {
      this.input = input;
    };

    const classes = cx(
      {
        invalid: error,
        valid: valid && !error
      },
      className
    );

    const otherProps = omit(
      ['id', 'label', 'value', 'valid', 'error', 'className', 'onChange'],
      this.props
    );

    const input = (
      <input
        {...otherProps}
        id={id}
        className={classes}
        value={value}
        onChange={onChange}
        ref={inputRef}
      />
    );

    return <InputField id={id} label={label} input={input} valid={valid} error={error} />;
  }
}

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valid: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  onChange: PropTypes.func
};

Input.defaultProps = {
  id: '',
  label: '',
  type: 'text',
  value: undefined,
  valid: false,
  error: undefined,
  className: undefined,
  onChange: undefined
};

export default Input;
