import debounce from 'debounce-promise';

import { peopleService } from '../../../services';
import actions from '../actions';

import { notificationsSelectors, notificationsOperations } from '../../notifications';
import { encodeSearchTerm } from '../../../util';

const { updateBookForPerson, updateBookForIsLoading, updateBookForSuggestions } = actions;

const loadBookForSuggestions = term => (dispatch, getState) => {
  const state = getState();

  const debounceFetch = debounce(searchTerm => peopleService.get(searchTerm), 200);
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const searchTerm = encodeSearchTerm(encodeURIComponent(term));

  if (searchTerm && searchTerm.length > 0) {
    dispatch(updateBookForIsLoading(true));
    return debounceFetch(searchTerm)
      .then(response => {
        dispatch(updateBookForSuggestions(response));
      })
      .catch(serverError => {
        const error = getNotification('peoplepicker.error', serverError);
        dispatch(notificationsOperations.showError(error));
      })
      .then(() => {
        dispatch(updateBookForIsLoading(false));
      });
  }
  dispatch(updateBookForSuggestions([]));
  return null;
};

export default {
  updateBookForPerson,
  loadBookForSuggestions
};
