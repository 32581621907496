import React from 'react';
import { getPropTypes } from './Language';
import LanguageSelection from './LanguageSelection';

import './_styles.css';

function LanguageMobile(props) {
  return (
    <div>
      <LanguageSelection className="Language__Selection" {...props} />
    </div>
  );
}

LanguageMobile.propTypes = getPropTypes;

export default LanguageMobile;
