import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, ResourceImage, withTranslation } from '../../common';

class PhotoAdminItem extends Component {
  componentDidMount() {}

  handleClick = () => {
    const { translate, photoId, removePhoto } = this.props;
    const confirmText = translate('admin.photos.confirmation');
    const confirmed = confirm(confirmText); // eslint-disable-line
    if (!confirmed) {
      return;
    }
    removePhoto(photoId);
  };

  render() {
    const { photoId, isLoading, translate } = this.props;
    return (
      <div className="PhotoAdminItem">
        <div className="PhotoAdminItem__Image">
          <ResourceImage photoId={photoId} />
        </div>
        <div className="PhotoAdminItem__Action">
          <Button
            label={translate('common.delete')}
            onClick={this.handleClick}
            loading={isLoading}
          />
        </div>
      </div>
    );
  }
}

PhotoAdminItem.propTypes = {
  photoId: PropTypes.string.isRequired,
  removePhoto: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(PhotoAdminItem);
