const SWITCH_RESOURCE_TYPE = 'search/SWITCH_RESOURCE_TYPE';
const UPDATE_DATE = 'search/UPDATE_DATE';
const UPDATE_TIME = 'search/UPDATE_TIME';
const RESET_CHANGED = 'search/RESET_CHANGED';
const UPDATE_SEARCHED_EXACT_TIME = 'search/UPDATE_SEARCHED_EXACT_TIME';
const RESET_FILTERS = 'search/RESET_FILTERS';

export default {
  SWITCH_RESOURCE_TYPE,
  UPDATE_DATE,
  UPDATE_TIME,
  RESET_CHANGED,
  UPDATE_SEARCHED_EXACT_TIME,
  RESET_FILTERS
};
