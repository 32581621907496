import { combineReducers } from 'redux';
import store from 'store2';
import types from './types';

const loadInitialState = () => {
  const floors = store('floors');
  const isLoaded = store.has('floors');
  return {
    floors,
    isLoaded
  };
};

const initialState = loadInitialState();

const floorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_FLOORS: {
      return {
        ...state,
        floors: action.floors
      };
    }

    case types.UPDATE_LOADED: {
      return {
        ...state,
        isLoaded: true,
        age: action.age
      };
    }

    case types.SET_SELECTED_FLOOR: {
      return {
        ...state,
        selectedFloor: action.selectedFloor
      };
    }

    case types.RESET_FLOORS: {
      return {
        ...state,
        floors: [],
        selectedFloor: null
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  floors: floorsReducer
});
