import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { ShowAt } from '../../common';
import SearchResultsInlineLoader from './SearchResultsInlineLoader';
import SearchResultsEmptyState from './SearchResultsEmptyState';
import AlternativeSearchResults from '../AlternativeSearchResults';

function SearchResults(props) {
  const {
    isLoading,
    availableResources,
    setRowHeight,
    hasMore,
    loadMoreAvailabilities,
    renderSearchResult,
    getIsDateInPast
  } = props;
  const showInlineLoader = isLoading && availableResources && availableResources.length > 0;
  const showEmptyState = (!isLoading && availableResources.length === 0) || getIsDateInPast;

  const handleLoadMore = () => {
    if (!isLoading) {
      loadMoreAvailabilities();
    }
  };

  const renderMobile = () => {
    if (showEmptyState) {
      return <SearchResultsEmptyState visible />;
    }

    return (
      <InfiniteScroll
        loadMore={handleLoadMore}
        hasMore={hasMore}
        loader={
          <SearchResultsInlineLoader visible={showInlineLoader} key="SearchResultsInlineLoader" />
        }
        threshold={1400}>
        {availableResources.map(resource => {
          const bookingCount = resource.bookings ? resource.bookings.length : 0;
          let rowHeight = setRowHeight(resource);

          if (resource.showJournal && !!bookingCount && resource.resourceType === 'Room') {
            const extraHeight = (bookingCount + resource.availabilities.length) * 80;
            rowHeight += extraHeight;
          }
          return renderSearchResult(resource, rowHeight);
        })}
      </InfiniteScroll>
    );
  };

  const renderDesktop = () => (
    <Scrollbars style={{ height: '100%' }} autoHide>
      <SearchResultsEmptyState visible={showEmptyState} />
      {!showEmptyState && (
        <>
          <InfiniteScroll
            element="div"
            loadMore={handleLoadMore}
            hasMore={hasMore}
            useWindow={false}
            loader={
              <SearchResultsInlineLoader visible={showInlineLoader} key="SearchResultsInlineLoader" />
            }
            threshold={1400}>
            {availableResources.map(resource => {
              const rowHeight = setRowHeight(resource);
              return renderSearchResult(resource, rowHeight);
            })}
          </InfiniteScroll>
          <AlternativeSearchResults />
        </>
      )}
    </Scrollbars>
  );

  return (
    <>
      <ShowAt breakpoint="900AndBelow">{renderMobile()}</ShowAt>
      <ShowAt breakpoint="900AndAbove" className="SearchResultsWrapper">
        {renderDesktop()}
      </ShowAt>
    </>
  );
}

SearchResults.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  setRowHeight: PropTypes.func.isRequired,
  availableResources: PropTypes.arrayOf(PropTypes.shape()),
  loadMoreAvailabilities: PropTypes.func.isRequired,
  renderSearchResult: PropTypes.func.isRequired,
  getIsDateInPast: PropTypes.bool.isRequired
};

export default SearchResults;
