/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

function MyBookingsIcon({ className }) {
  const styles = {
    fill: 'currentcolor',
    width: '30px',
    height: '30px'
  };

  const styles1 = {
    stroke: 'currentcolor',
    strokeWidth: '0.5',
    strokeMiterlimit: '10'
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      style={styles}
      className={className}
    >
      <title>my_bookings</title>
      <path d="M23.8,15V6.3c0-0.5-0.4-0.9-1-1H20V2.5c0-0.5-0.4-0.9-0.9-0.9S18.2,2,18.2,2.5v2.8H8.8V2.5c0-0.5-0.6-0.9-1.1-0.9S6.8,2,6.8,2.5v2.9H4c-0.5,0-0.9,0.4-0.9,0.9v17c0,1.5,1.2,2.8,2.8,2.8h8.9c1.2,1,2.8,1.5,4.4,1.5c3.9,0,7.1-3.2,7.1-7.1C26.3,18.3,25.4,16.3,23.8,15z M5,23.2V9.1h16.9v4.8c-0.8-0.3-1.7-0.5-2.7-0.5c-3.9,0-7.2,3.2-7.2,7.1c0,1.3,0.4,2.5,1,3.6H5.9C5.4,24.1,5,23.7,5,23.2z M19.2,26c-3,0-5.5-2.4-5.5-5.5s2.4-5.4,5.5-5.4s5.5,2.4,5.5,5.4S22.2,26,19.2,26z" />
      <path
        style={styles1}
        d="M18.3,23l-2.1-2.1c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l1.6,1.6l2.3-4.1c0.1-0.3,0.5-0.3,0.7-0.2c0.3,0.1,0.3,0.5,0.2,0.7l-2.6,4.7c-0.1,0.2-0.3,0.3-0.5,0.3C18.5,23.1,18.4,23.1,18.3,23z"
      />
    </svg>
  );
}

MyBookingsIcon.propTypes = {
  className: PropTypes.string
};

export default MyBookingsIcon;
