import store from 'store2';
import moment from 'moment';

import actions from './actions';
import selectors from './selectors';

import { maintenanceService, loggingService } from '../../services';
import { notificationsSelectors, notificationsOperations } from '../notifications';

const {
  updateMessage,
  reset,
  updatePayload,
  updateLoading,
  updatePersisted,
  updateSubmitting,
  updateSubmitted
} = actions;

const parseDate = date => moment(date, 'DD.MM.YYYY');
const parseTime = time => moment(time, ['HHmm', 'HH:mm'], true);

const toDate = (date, time) => {
  const selectedDate = parseDate(date);
  const selectedTime = parseTime(time);
  selectedDate.set({
    hour: selectedTime.get('hour'),
    minute: selectedTime.get('minute')
  });
  return selectedDate.toDate();
};

const toDateTime = date => {
  const momentDate = moment(date);
  return {
    date: momentDate.format('DD.MM.YYYY'),
    time: momentDate.format('HH:mm')
  };
};

const convertFromPayload = payload => {
  const { from, to, ...props } = payload;
  return {
    ...props,
    from: toDate(from.date, from.time),
    to: toDate(to.date, to.time)
  };
};

const convertToPayload = message => {
  const { from, to, type, ...props } = message;
  return {
    ...props,
    type: type.toLowerCase(),
    from: toDateTime(from),
    to: toDateTime(to)
  };
};

const getLatest = () => (dispatch, getState) => {
  const state = getState();

  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(updateLoading(true));

  return maintenanceService
    .latest()
    .then(response => {
      if (Object.keys(response).length > 0) {
        dispatch(updatePayload(convertToPayload(response)));
        dispatch(updatePersisted(true));
      }
    })
    .catch(serverError => {
      const error = getNotification('common.error', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => dispatch(updateLoading(false)));
};

const submit = () => (dispatch, getState) => {
  const state = getState();
  dispatch(updateSubmitted());

  const getNotification = notificationsSelectors.getNotification(state);
  const message = convertFromPayload(selectors.getPayload(state));

  dispatch(updateSubmitting(true));

  return maintenanceService
    .put(message)
    .then(response => {
      dispatch(updatePayload(convertToPayload(response)));
      const success = getNotification('admin.success.created');
      dispatch(notificationsOperations.showSuccess(success));
    })
    .catch(serverError => {
      const error = getNotification('common.error', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => dispatch(updateSubmitting(false)));
};

const clear = () => (dispatch, getState) => {
  const state = getState();

  const isPersisted = selectors.isPersisted(state);
  if (!isPersisted) {
    return Promise.resolve(dispatch(reset()));
  }

  const getNotification = notificationsSelectors.getNotification(state);
  const payload = selectors.getPayload(state);
  const { id } = payload;

  dispatch(updateSubmitting(true));
  return maintenanceService
    .remove(id)
    .then(() => {
      dispatch(reset());
      const success = getNotification('admin.success.removed');
      dispatch(notificationsOperations.showSuccess(success));
    })
    .catch(serverError => {
      const error = getNotification('common.error', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => dispatch(updateSubmitting(false)));
};

const hideMessages = () => dispatch => {
  store.session('hideMaintenanceMessages', true);
  dispatch(actions.hideMessages());
};

const loadCurrentMessage = () => async dispatch => {
  try {
    const message = await maintenanceService.getCurrent();
    dispatch(updateMessage(message));
  } catch (err) {
    loggingService.error('Failed loading current maintenance message', err);
  }
};

export default {
  updateMessage,
  loadCurrentMessage,
  hideMessages,
  updatePayload,
  getLatest,
  submit,
  clear
};
