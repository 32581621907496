import React from 'react';
import PropTypes from 'prop-types';

import { TextArea } from '../common';

function FeedbackTextArea({ value, onChange, placeholder }) {
  return (
    <TextArea
      minRows={5}
      maxRows={5}
      fixed
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      maxLength="600"
  />
  );
}

FeedbackTextArea.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

export default FeedbackTextArea;
