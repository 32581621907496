import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, Button, withTranslation, Spacing } from '../../../common';
import { modalOperations } from '../../../../state/modal';
import Header from '../../../Header';
import keys from './modalKeys';
import { floorsOperations, floorsSelectors } from '../../../../state/floors';

class FloorsDeleteDialog extends Component {
  confirmedDeleteFloor = () => {
    const { clearModals, removeFloor, selectedFloor, isNewBuilding, deleteFloor } = this.props;

    if (!isNewBuilding) {
      deleteFloor(selectedFloor.name, selectedFloor.buildingKey);
    } else {
      removeFloor(selectedFloor);
    }
    clearModals();
  };

  renderDialogContent = () => {
    const { translate, popDialog, selectedFloor } = this.props;

    const title = `${ translate('admin.settings.floors.delete.title') }${ selectedFloor?.name }`;
    const text = `${ translate('admin.settings.floors.delete.confirm') }
      ${ translate('common.name') } ${ selectedFloor?.name } ?`;
    const yes = translate('common.yes');
    const no = translate('common.no');

    return (
      <div>
        <Header title={title} onClose={popDialog} icon="022-close" iconRight fixed={false} />
        <div className="FloorFormField">
          <Spacing horizontal={1} vertical={1}>
            <div>
              {text}
            </div>
          </Spacing>
          <div>
            <Button
              className="FloorsDelete__Button"
              label={no}
              onClick={popDialog}
              secondary
            />
            <Button
              className="FloorsDelete__Button"
              label={yes}
              onClick={this.confirmedDeleteFloor}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { popDialog } = this.props;

    return (
      <Dialog dialogKey={keys.FLOOR_DELETE_DIALOG} onOverlayClick={popDialog}>
        {this.renderDialogContent()}
      </Dialog>
    );
  }
}

FloorsDeleteDialog.propTypes = {
  popDialog: PropTypes.func.isRequired,
  clearModals: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  deleteFloor: PropTypes.func.isRequired, // used for the next story
  selectedFloor: PropTypes.any,
  removeFloor: PropTypes.func,
  isNewBuilding: PropTypes.bool
};

const mapStateToProps = state => ({
  selectedFloor: floorsSelectors.getSelectedFloor(state)
});

const mapDispatchToProps = dispatch => ({
  popDialog: () => dispatch(modalOperations.popDialog()),
  clearModals: () => dispatch(modalOperations.clearModals()),
  deleteFloor: (name, buildingKey) => dispatch(floorsOperations.deleteFloor(name, buildingKey))
});

const FloorsDeleteDialogComponent = withTranslation(FloorsDeleteDialog);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FloorsDeleteDialogComponent);
