const isSelected = item => item.selected;

const markFirstSelected = resources => {
  const result = [...resources];
  if (result.length > 0) {
    result[0].selected = true;
  }
  return result;
};

// eslint-disable-next-line arrow-body-style
const isSameAvailability = (availability, other) => {
  return (
    availability && other && availability.start === other.start && availability.end === other.end
  );
};

export const isSameResource = (resource, other) => {
  const { availabilities } = resource;
  const { availabilities: otherAvailabilities } = other;
  return (
    resource.id === other.id
    && availabilities.length
    && otherAvailabilities.length
    && isSameAvailability(availabilities[0], otherAvailabilities[0])
  );
};

export const makeSelectable = resources => resources.map(resource => ({ resource, selected: false }));

export const select = (resources, selectedResource) => (selectedResource
  ? resources.map(item => ({
    ...item,
    selected: isSameResource(item.resource, selectedResource)
  }))
  : resources);

export const getSelected = resources => {
  const selected = resources.find(isSelected);
  return selected && selected.resource;
};

export const getFirstSelected = resources => {
  const first = resources.find(isSelected);
  const item = first || (resources.length && resources[0]) || null;
  return (item && item.resource) || null;
};

export const markOneSelected = resources => {
  const first = resources.find(isSelected);
  return first ? resources : markFirstSelected(resources);
};
