import React from 'react';
import PropTypes from 'prop-types';

import { SelectBox, withTranslation } from '../../common';

import './_style.css';

function DaysSelectBoxes({ days, toggleDay, translate }) {
  const weekDays = [
    { day: 'monday' },
    { day: 'tuesday' },
    { day: 'wednesday' },
    { day: 'thursday' },
    { day: 'friday' }
  ];

  const options = weekDays.map(x => ({
    ...x,
    label: translate(`common.weekDaysShort.${x.day}`),
    selected: days.some(day => day === x.day)
  }));

  return (
    <div className="DaysSelectBoxes">
      {options.map(option => {
        const onClick = () => toggleDay(option.day);
        const props = {
          key: option.day,
          onClick,
          selected: option.selected,
          className: 'DaysSelectBox'
        };
        return <SelectBox {...props}>{option.label}</SelectBox>;
      })}
    </div>
  );
}

DaysSelectBoxes.propTypes = {
  days: PropTypes.arrayOf(PropTypes.string).isRequired,
  toggleDay: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(DaysSelectBoxes);
