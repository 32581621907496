import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { locationsOperations, locationsSelectors } from '../../../../../state/locations';
import { LocationPicker, withTranslation } from '../../../../common';
import { emitter } from '../../../../../util';

class SearchBarLocationPicker extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const { setToDefault } = this.props;
    setToDefault();
  }

  updateLocations = locations => {
    const { updateLocations } = this.props;
    updateLocations(locations);
    emitter.emit('onSearchFilterChanged', null);
  };

  render() {
    const { selectedLocations, searchLocations, translate } = this.props;
    const locationPickerProps = {
      updateLocations: this.updateLocations,
      locations: selectedLocations,
      loadLocations: searchLocations,
      placeholder: translate('search.where.placeholder')
    };

    return (
      <div>
        <LocationPicker ref={this.inputRef} {...locationPickerProps} />
      </div>
    );
  }
}

SearchBarLocationPicker.propTypes = {
  selectedLocations: PropTypes.arrayOf(PropTypes.shape()),
  searchLocations: PropTypes.func.isRequired,
  updateLocations: PropTypes.func.isRequired,
  setToDefault: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  selectedLocations: locationsSelectors.getExistingSelectedLocations(state)
});

const mapDispatchToProps = dispatch => ({
  searchLocations: input => dispatch(locationsOperations.searchLocations(input)),
  updateLocations: (locations, callback) => dispatch(locationsOperations.updateLocations(locations, callback, true)),
  setToDefault: () => dispatch(locationsOperations.setToDefault())
});

export default withTranslation(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SearchBarLocationPicker)
);
