import React from 'react';

import Header from '../Header';
import MyBookings from './MyBookings';

function MyBookingsPageDesktop(props) {
  return (
    <div>
      <Header />
      <div className="MyBookingsDesktopWrapper">
        <Header />
        <MyBookings {...props} />
      </div>
    </div>
  );
}

export default MyBookingsPageDesktop;
