/* eslint-disable class-methods-use-this */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Timeline, {
  TodayMarker, TimelineMarkers, TimelineHeaders, DateHeader
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import moment from 'moment';
import find from 'lodash/fp/find';
import pick from 'lodash/fp/pick';
import forEach from 'lodash/fp/forEach';
import cx from 'classnames';

import CalendarRoom from '../CalendarRoom';
import CalendarEquipment from '../CalendarEquipment';
import { CalendarBooking, CalendarBufferedBooking } from '../CalendarBooking';
import TimeLineBackgrounds from './TimeLineBackgrounds';
import { getMinDate, getMaxDate, getStartDate, getEndDate, getZoomMin, getZoomMax } from './helper';

import { emitter } from '../../../../../../util';

import './_style.css';

const createEquipment = group => {
  const props = {
    equipment: group
  };
  return <CalendarEquipment {...props} />;
};

const createRoom = group => {
  const props = {
    room: group
  };
  return <CalendarRoom {...props} />;
};

class TimeLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: []
    };

    const { type, date } = props;
    this.lastValidStart = getStartDate(type, date);
    this.lastValidEnd = getEndDate(type, date);

    this.onAdd = this.onAdd.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.onResize = this.onResize.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.renderBooking = this.renderBooking.bind(this);
    this.renderGroup = this.renderGroup.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.onItemDoubleClick = this.onItemDoubleClick.bind(this);
  }

  onAdd(groupId, time) {
    const { updateDate, updateTime, items, groups, openCreateModal } = this.props;

    const resource = groups.filter(g => g.id === groupId)[0];

    const otherBookings = items.filter(it => it.resource.id === resource.id);
    let isOverlapping = false;

    const bookingStart = moment(time);

    forEach(otherBooking => {
      const otherBookingStart = moment(otherBooking.start);
      const otherBookingEnd = moment(otherBooking.end);
      if (bookingStart.isBetween(otherBookingStart, otherBookingEnd)) {
        isOverlapping = true;
        return false;
      }
      return true;
    }, otherBookings);
    if (isOverlapping) {
      return false;
    }

    const start = moment(bookingStart);
    updateDate(start.format('DD.MM.YYYY'));
    updateTime({
      from: start.format('HH:mm'),
      to: start.add(1, 'hours').format('HH:mm')
    });
    openCreateModal(resource);

    return true;
  }

  onMove(itemId, dragTime, newGroupOrder) {
    const { items, groups } = this.props;
    const group = groups[newGroupOrder];
    const item = find(['id', itemId], items);

    const duration = moment.duration(moment(item.end).diff(item.start));
    const newStart = moment(dragTime).toISOString();
    const newEnd = moment(dragTime)
      .add(duration)
      .toISOString();

    this.onDrag(item, group, newStart, newEnd);
  }

  onResize(itemId, time, edge) {
    const { items, groups } = this.props;
    const item = find(['id', itemId], items);
    const group = find(['id', item.group], groups);

    const newStart = edge === 'left' ? moment(time).toISOString() : moment(item.start).toISOString();
    const newEnd = edge === 'right' ? moment(time).toISOString() : moment(item.end).toISOString();

    this.onDrag(item, group, newStart, newEnd);
  }

  onDrag(item, group, start, end) {
    const {
      items,
      updateBooking,
      addStateBooking,
      removeStateBooking,
      cacheBookingsState,
      resetBookingsStateFromCache,
      loadBookings,
      setDirty
    } = this.props;

    const didGroupChange = item.group !== group.id;
    const isEquipment = item.resource && item.resource.resourceType === 'Equipment';

    const newBooking = {
      ...item,
      isOccurrence: item.isRecurring,
      resourceId: group.id,
      start,
      end,
      seatingId: !didGroupChange && item.seating && item.seating.id
    };

    const bookings = items.filter(booking => {
      if (item.id !== booking.id) {
        return booking.group === group.id;
      }
      return false;
    });

    const isOverlapping = bookings.filter(
      booking => moment(newBooking.start) < moment(booking.end)
        && moment(newBooking.end) > moment(booking.start)
    ).length > 0;

    if (isOverlapping || (isEquipment && didGroupChange)) {
      return false;
    }

    const payload = {
      ...pick(
        [
          'subject',
          'message',
          'reminder',
          'attendeeMails',
          'globalId',
          'calendarId',
          'start',
          'end',
          'calendarId',
          'isRecurring',
          'isOccurrence',
          'resourceId',
          'seatingId'
        ],
        newBooking
      ),
      bookedFor: newBooking.bookedFor && newBooking.bookedFor.email
    };

    cacheBookingsState();
    if (didGroupChange) {
      setTimeout(() => {
        removeStateBooking(item.calendarId, item.group);
      }, 0);
    }
    this.setState({ selected: [] });
    addStateBooking({
      [newBooking.calendarId + newBooking.resourceId]: {
        ...newBooking,
        seating: null
      }
    });

    const isVirtualMember = resource => resource && (resource.isVirtual || resource.isVirtualMember);

    const shouldReload = isVirtualMember(newBooking.resource) || isVirtualMember(group);

    updateBooking(payload)
      .then(booking => {
        if (shouldReload) {
          setDirty();
          loadBookings();
        } else {
          removeStateBooking(newBooking.calendarId, newBooking.resourceId);
          addStateBooking({
            [newBooking.calendarId + newBooking.resourceId]: {
              ...newBooking,
              seating: booking.seating
            }
          });
        }
      })
      .catch(resetBookingsStateFromCache);
    return true;
  }

  onSelect(itemId) {
    const { items } = this.props;
    const { selected } = this.state;
    const item = find(['id', itemId], items);
    const selectedIndex = selected.indexOf(itemId);

    if (item.isMyBooking) {
      if (selectedIndex > -1) {
        this.setState({ selected: [] });
      } else {
        this.setState({ selected: [itemId] });
      }
    }
    emitter.emit('onCalendarBookingSelect', item);
  }

  onItemDoubleClick(itemId) {
    const { items } = this.props;
    const item = find(['id', itemId], items);
    emitter.emit('onCalendarBookingEdit', item);
  }

  onTimeChange(start, end, updateCanvas) {
    const formatToUnixInMs = date => parseInt(moment(date).format('x'), 10);

    const { date, type } = this.props;
    const min = formatToUnixInMs(getMinDate(type, date));
    const max = formatToUnixInMs(getMaxDate(type, date));

    const isAtMax = end >= max;
    const isAtMin = start <= min;

    if (!isAtMax && !isAtMin) {
      this.lastValidStart = start;
      this.lastValidEnd = end;
      return updateCanvas(start, end);
    }

    if (isAtMax) {
      return updateCanvas(this.lastValidStart, max);
    }
    if (isAtMin) {
      return updateCanvas(min, this.lastValidEnd);
    }
    this.lastValidStart = min;
    this.lastValidEnd = max;
    return updateCanvas(min, max);
  }

  renderGroup({ group }) {
    const renderElement = resourceType => {
      if (resourceType === 'Equipment') {
        return createEquipment;
      }
      if (resourceType === 'Room') {
        return createRoom;
      }
      return () => { };
    };

    return renderElement(group.resourceType)(group);
  }

  renderBooking({ item, getItemProps }) {
    const { seatingIdentifier } = item;
    const showWithBuffer = seatingIdentifier != null;
    const props = {
      booking: item,
      start_time: item.start,
      end_time: item.end,
      className: showWithBuffer ? 'buffer' : ''
    };

    return (
      <div {...getItemProps(item.itemProps)}>
        <div className="rct-item-content">
          {showWithBuffer ? <CalendarBufferedBooking {...props} /> : <CalendarBooking {...props} />}
        </div>
      </div>
    );
  }

  render() {
    const { items, groups, type, date, compactModeEnabled } = this.props;
    const { selected } = this.state;
    const start = getStartDate(type, date);
    const end = getEndDate(type, date);
    const zoomMin = getZoomMin(type);
    const zoomMax = getZoomMax(type);

    const classes = cx('Timeline', {
      'Timeline--compact': compactModeEnabled
    });

    const mapItems = item => ({
      ...item,
      start: moment(item.start),
      end: moment(item.end)
    });

    const moveResizeValidator = time => {
      if (moment(time) <= moment()) {
        return moment();
      }
      return time;
    };

    const keys = {
      groupIdKey: 'id',
      groupTitleKey: 'title',
      groupRightTitleKey: 'rightTitle',
      itemIdKey: 'id',
      itemTitleKey: 'title',
      itemDivTitleKey: 'title',
      itemGroupKey: 'group',
      itemTimeStartKey: 'start',
      itemTimeEndKey: 'end',
      groupLabelKey: 'title'
    };

    const timelineProps = {
      items: items.map(mapItems),
      groups,
      keys,
      defaultTimeStart: start,
      defaultTimeEnd: end,
      itemRenderer: this.renderBooking,
      groupRenderer: this.renderGroup,
      fixedHeader: 'sticky',
      sidebarWidth: 300,
      lineHeight: compactModeEnabled ? 35 : 70,
      itemHeightRatio: 0.9,
      stickyOffset: 60,
      fullUpdate: false,
      maxZoom: zoomMax,
      minZoom: zoomMin,
      onTimeChange: this.onTimeChange,
      onItemMove: this.onMove,
      onItemResize: this.onResize,
      onItemSelect: this.onSelect,
      onCanvasDoubleClick: this.onAdd,
      onItemDoubleClick: this.onItemDoubleClick,
      selected,
      moveResizeValidator,
      stackItems: false
    };

    const primaryDateHeaderUnit = type === 'day' ? 'primaryHeader' : 'primaryHeader';
    const secondaryDateHeaderUnit = type === 'day' ? 'hour' : 'day';
    const secondaryDateHeaderLabelFormat = type === 'day' ? 'HH:00' : 'dddd, D';

    if (groups.length > 0) {
      return (
        <div>
          <div className={classes}>
            <Timeline {...timelineProps}>
              <TimelineMarkers>
                <TodayMarker />
              </TimelineMarkers>
              <TimeLineBackgrounds compactMode={compactModeEnabled} />
              <TimelineHeaders>
                <DateHeader unit={primaryDateHeaderUnit} />
                <DateHeader unit={secondaryDateHeaderUnit} labelFormat={secondaryDateHeaderLabelFormat} />
              </TimelineHeaders>
            </Timeline>
          </div>
        </div>
      );
    }

    return null;
  }
}

TimeLine.propTypes = {
  type: PropTypes.oneOf(['day', 'week']),
  items: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  groups: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  updateDate: PropTypes.func.isRequired,
  updateTime: PropTypes.func.isRequired,
  createBooking: PropTypes.func.isRequired,
  updateBooking: PropTypes.func.isRequired,
  deleteBooking: PropTypes.func.isRequired,
  deleteMaster: PropTypes.func.isRequired,
  updateMaster: PropTypes.func.isRequired,
  removeStateBooking: PropTypes.func.isRequired,
  addStateBooking: PropTypes.func.isRequired,
  cacheBookingsState: PropTypes.func.isRequired,
  resetBookingsStateFromCache: PropTypes.func.isRequired,
  updateCalendarBooking: PropTypes.func.isRequired,
  openCreateModal: PropTypes.func.isRequired,
  openRecurrenceDeleteDialog: PropTypes.func.isRequired,
  compactModeEnabled: PropTypes.bool.isRequired,
  setDirty: PropTypes.func.isRequired,
  loadBookings: PropTypes.func.isRequired
};

TimeLine.defaultProps = {
  type: 'day'
};

export default TimeLine;
