import { detectLanguage } from '../i18n';

const getResourceSubTypeNameTranslation = resourceSubType => {
  if (resourceSubType == null) return '';

  switch (detectLanguage()) {
    case 'en': return resourceSubType.nameEN;
    case 'fr': return resourceSubType.nameFR;
    case 'de': return resourceSubType.nameDE;
    case 'it': return resourceSubType.nameIT;
    default: return resourceSubType.nameEN;
  }
};

const getResourceSubTypeOptions = (resourcesSubTypes, resourceType) => {
  const options = Object.entries(resourcesSubTypes)
  .filter(option => option[1].resourceType === resourceType)
  .map(option => ({
    key: option[1].id,
    name: getResourceSubTypeNameTranslation(option[1])
  }))
  .sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return options;
};

const getResourceSubTypesObject = (resourceSubTypes, resourceType) => {
  let resourceSubTypesString = '{';

  // Todo: fix this
  if (resourceSubTypes) {
    resourceSubTypes
    .filter(resourceSubType => resourceSubType.resourceType === resourceType)
    .forEach(resourceSubType => {
      resourceSubTypesString += `"${resourceSubType.id}": 
      {
        "key": "${resourceSubType.id}", 
        "name": "${getResourceSubTypeNameTranslation(resourceSubType)}", 
        "type": "${resourceType.toLowerCase()}Type", 
        "enabled": false
      },`;
    });
    resourceSubTypesString = resourceSubTypesString.length > 1
      ? resourceSubTypesString.slice(0, resourceSubTypesString.length - 1)
      : resourceSubTypesString;
  }
  resourceSubTypesString += '}';

  return JSON.parse(resourceSubTypesString);
};

export {
  getResourceSubTypeNameTranslation,
  getResourceSubTypeOptions,
  getResourceSubTypesObject
};
