import React from 'react';
import PropTypes from 'prop-types';

function DropDownWrapper({ label, id, children }) {
  return (
    <div className="DropDown">
      {label && (
      <label className="DropDown__Label" htmlFor={id}>
        {label}
      </label>
    )}
      {children}
    </div>
  );
}

export default DropDownWrapper;

DropDownWrapper.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.node.isRequired
};
