import React from 'react';
import PropTypes from 'prop-types';

import { Loader, Translate, withTranslation } from '../../common';

import './_style.css';

function SearchResultsLoader({ visible, isRecurrence, resourceType, translate }) {
  if (!visible) {
    return null;
  }

  const renderRecurrenceText = () => {
    const resources = translate(
      `search.recurrence.loading.${resourceType === 'room' ? 'rooms' : 'resources'}`
    );
    const resource = translate(
      `search.recurrence.loading.${resourceType === 'room' ? 'room' : 'resource'}`
    );

    return (
      <Translate
        path="search.recurrence.loading.text"
        resource={resource}
        resources={resources}
        className="SearchResultsLoader__RecurrenceText"
      />
    );
  };

  return (
    <div className="SearchResultsLoader">
      <Loader className="SearchResultsLoader__Recurrence" visible>
        {isRecurrence && renderRecurrenceText()}
      </Loader>
    </div>
  );
}

SearchResultsLoader.propTypes = {
  visible: PropTypes.bool.isRequired,
  isRecurrence: PropTypes.bool,
  resourceType: PropTypes.string,
  translate: PropTypes.func.isRequired
};

export default withTranslation(SearchResultsLoader);
