import React from 'react';
import PropTypes from 'prop-types';

import { Radio, RadioGroup, withTranslation } from '../../common';

function CreateResourceSwitch({ value, switchForm, translate }) {
  const label = translate('changeRequests.new.formSwitch.label');
  const room = translate('changeRequests.new.formSwitch.newRoom');
  const equipment = translate('changeRequests.new.formSwitch.newEquipment');

  return (
    <div className="CreateResourceSwitch">
      <RadioGroup label={label} value={value} onChange={switchForm}>
        <Radio label={room} value="room" />
        <Radio label={equipment} value="equipment" />
      </RadioGroup>
    </div>
  );
}

CreateResourceSwitch.propTypes = {
  value: PropTypes.string,
  switchForm: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(CreateResourceSwitch);
