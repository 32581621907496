import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, TabBar, Tab, Tag, withTranslation } from '../../../common';
import RoomFilter from '../../../RoomFilter';
import EquipmentFilter from '../../../EquipmentFilter';
import { roomFilterSelectors, roomFilterOperations } from '../../../../state/roomFilter';
import {
  equipmentFilterSelectors,
  equipmentFilterOperations
} from '../../../../state/equipmentFilter';
import { emitter } from '../../../../util';
import { searchSelectors, searchOperations } from '../../../../state/search';

import styles from './FilterBar.module.css';
import { resourcesSubTypesSelectors } from '../../../../state/resourcesSubTypes';

function FilterBar({
    toggleRoomFilter,
    toggleEquipmentFilter,
    switchResourceType,
    removeTag,
    translate,
    roomFilter,
    equipmentFilter,
    resourceType,
    tags,
    resetFilters
  }) {
  const [isFilterHidden, setIsFilterHidden] = useState(true);

  const toggleFilterVisibility = () => {
    setIsFilterHidden(!isFilterHidden);
  };

  const onRoomFilterChange = key => {
    toggleRoomFilter(key);
    emitter.emit('onSearchFilterChanged', null);
  };

  const onEquipmentFilterChange = key => {
    toggleEquipmentFilter(key);
    emitter.emit('onSearchFilterChanged', null);
  };

  const onTabChange = newResourceType => {
    switchResourceType(newResourceType);
    emitter.emit('onSearchFilterChanged', null);
  };

  const onTagRemove = tag => {
    removeTag(tag);
    emitter.emit('onSearchFilterChanged', null);
  };

  const onResetFilters = () => {
    resetFilters();
    emitter.emit('onSearchFilterChanged', null);
  };

  const buttonText = isFilterHidden
    ? translate('search.filter.open')
    : translate('search.filter.close');
  const tabs = ['room', 'equipment'];

  return (
    <div className={styles.body}>
      <div className={`${styles.bodyPart} ${styles.bodyPartFirst}`}>
        <Button
          icon="253-filter"
          outlined
          label={buttonText}
          onClick={toggleFilterVisibility}
        />
        <div className={styles.tags}>
          {tags.map(tag => (
            <Tag
              item={tag}
              key={tag.key}
              onClick={() => {
                onTagRemove(tag);
              }}
            />
          ))}
        </div>
        <div className={styles.resetFilter}>
          <button className="link" onClick={onResetFilters} type="button">
            {translate('common.resetFilters')}
          </button>
        </div>
      </div>
      {!isFilterHidden && (
        <div>
          <TabBar className={styles.bar} value={resourceType} onChange={onTabChange}>
            {tabs.map(tab => (
              <Tab className={styles.tab} activeClassName={styles.active} key={tab} value={tab}>
                {translate(`resourceFilter.${tab}`)}
              </Tab>
            ))}
          </TabBar>
          <div className={`${styles.bodyPart} ${styles.bodyPartSecond}`}>
            {resourceType === 'room' && (
              <RoomFilter
                variant="horizontal"
                filter={roomFilter}
                onChange={onRoomFilterChange}
              />
            )}
            {resourceType === 'equipment' && (
              <EquipmentFilter filter={equipmentFilter} onChange={onEquipmentFilterChange} />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

FilterBar.propTypes = {
  translate: PropTypes.func.isRequired,
  roomFilter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleRoomFilter: PropTypes.func.isRequired,
  equipmentFilter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleEquipmentFilter: PropTypes.func.isRequired,
  resourceType: PropTypes.string.isRequired,
  switchResourceType: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      resourceType: PropTypes.string.isRequired,
      translation: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]).isRequired,
      type: PropTypes.string.isRequired
    })
  ).isRequired,
  removeTag: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  roomFilter: roomFilterSelectors.getRoomFilters(state),
  equipmentFilter: equipmentFilterSelectors.getEquipmentFilters(state),
  resourceType: searchSelectors.getResourceType(state),
  tags: searchSelectors.getTags(state),
  resourcesSubTypes: resourcesSubTypesSelectors.getAll(state)
});

const mapDispatchToProps = dispatch => ({
  toggleRoomFilter: item => dispatch(roomFilterOperations.toggleFilter(item)),
  toggleEquipmentFilter: item => dispatch(equipmentFilterOperations.toggleFilter(item)),
  switchResourceType: () => dispatch(searchOperations.switchResourceType()),
  resetFilters: () => dispatch(searchOperations.resetFilters()),
  removeTag: tag => dispatch(searchOperations.removeTag(tag))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(FilterBar));
