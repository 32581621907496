import React from 'react';
import PropTypes from 'prop-types';

import { Button, withTranslation } from '../../../../common';
import styles from './LoadMoreButton.module.css';

function LoadMoreButton({
  onClick,
  translate,
  currentCount,
  totalCount,
  isSubmitting,
  compactMode
}) {
  const pageSize = compactMode ? 20 : 10;

  const rest = totalCount - currentCount;
  const count = rest < pageSize ? rest : pageSize;
  const label = translate('search.timeline.loadMore', { count });
  return (
    <div className={styles.button}>
      <Button label={label} icon="071-retry" onClick={onClick} loading={isSubmitting} secondary />
    </div>
  );
}

LoadMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  currentCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  compactMode: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(LoadMoreButton);
