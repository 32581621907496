import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';

import MonthIndex from './MonthIndex';
import { WeekSelectBoxes, DaysSelectBoxes } from '../RecurrenceSelectBoxes';
import { recurrenceSelectors, recurrenceOperations } from '../../../state/recurrence';

import './_style.css';

function RecurrenceMonthly(props) {
  const { inverted } = props;
  const { index, updateIndex } = props;
  const { day, toggleDay } = props;
  const { week, toggleWeek } = props;

  const classes = cx('RecurrenceMonthly', { 'RecurrenceMonthly--inverted': inverted });

  return (
    <div className={classes}>
      <MonthIndex index={index} updateIndex={updateIndex} />

      <div className="RecurrenceMonthly__Weeks">
        <WeekSelectBoxes week={week} toggleWeek={toggleWeek} />
      </div>

      <div className="RecurrenceMonthly__Days">
        <DaysSelectBoxes days={[day]} toggleDay={toggleDay} />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  index: recurrenceSelectors.getIndexOfMonth(state),
  day: recurrenceSelectors.getDayOfMonth(state),
  week: recurrenceSelectors.getWeekOfMonth(state)
});

const mapDispatchToProps = dispatch => ({
  updateIndex: index => dispatch(recurrenceOperations.updateIndexOfMonth(index)),
  toggleDay: day => dispatch(recurrenceOperations.toggleDayOfMonth(day)),
  toggleWeek: week => dispatch(recurrenceOperations.toggleWeekOfMonth(week))
});

RecurrenceMonthly.propTypes = {
  inverted: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  day: PropTypes.string,
  week: PropTypes.string,
  updateIndex: PropTypes.func.isRequired,
  toggleDay: PropTypes.func.isRequired,
  toggleWeek: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecurrenceMonthly);
