/* eslint-disable arrow-body-style */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEqual from 'lodash/fp/isEqual';

import CustomPropTypes from '../PropTypes';
import { withTranslation, Validation } from '..';

const today = moment();
const isToday = date => moment(date, 'DD.MM.YYYY').isSame(today, 'day');
const isInPast = time => moment(time, ['HHmm', 'HH:mm'], true).isBefore(moment().subtract(15, 'minutes'));

function TimeInputRangeValidation({ date, time, children }) {
  const hasDate = date != null;
  const hasTime = time && time.from !== '';

  const isDateToday = hasDate && isToday(date);
  const isTimeInPast = isDateToday && hasTime && isInPast(time.from);
  const isInvalidRange = hasTime && isEqual(time.from, time.to);

  const invalid = isTimeInPast || isInvalidRange;

  const renderChildren = () => {
    return React.Children.map(children, child => React.cloneElement(child, { invalid }));
  };

  return (
    <div>
      {renderChildren()}
      {isTimeInPast && (
        <div className="TimeInputRange__InputValidation">
          <Validation type="error" visible message="search.when.wrongTime" />
        </div>
      )}
    </div>
  );
}

TimeInputRangeValidation.propTypes = {
  date: PropTypes.string,
  time: CustomPropTypes.time,
  children: PropTypes.element
};

export default withTranslation(TimeInputRangeValidation);
