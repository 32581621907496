import config from '../config';
import fetch from './fetch';

const getUrl = path => `${ config.remoteUrl }/${ path }`;

async function get(id) {
  const url = getUrl(`buildings/${ id }`);
  return fetch(url, 'GET');
}

async function getAll() {
  const url = getUrl('buildings');
  return fetch(url, 'GET');
}

// payload documentation: https://wiki.swisscom.com/display/itsdev/Create+Building
async function addBuilding(payload) {
  const url = getUrl('buildings/add');
  return fetch(url, 'POST', payload);
}

async function updateBuilding(buildingId, payload) {
  const url = getUrl(`buildings/${buildingId}`);
  return fetch(url, 'PUT', payload);
}

async function remove(buildingKey) {
  const url = getUrl(`buildings/deleteBuilding?buildingKey=${ buildingKey }`);
  return fetch(url, 'DELETE');
}

export default {
  get,
  getAll,
  addBuilding,
  updateBuilding,
  remove
};
