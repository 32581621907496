import React from 'react';
import PropTypes from 'prop-types';

import { Button, RoomCard, CustomPropTypes, withTranslation } from '../../common';

function BookCollectionResult({ resource, onClick, isInProgress }) {
  const label = 'Book';

  return (
    <div className="CollectionAlternativeResult">
      <RoomCard resourceSubType={resource.resourceSubType} showRelativeTime={false}>
        <Button label={label} onClick={onClick} loading={isInProgress} />
      </RoomCard>
    </div>
  );
}

BookCollectionResult.propTypes = {
  onClick: PropTypes.func.isRequired,
  resource: CustomPropTypes.resource,
  isInProgress: PropTypes.bool.isRequired
};

export default withTranslation(BookCollectionResult);
