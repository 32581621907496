import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { ShowAt, CustomPropTypes } from '../../../../../common';
import { searchSelectors } from '../../../../../../state/search';
import { availabilityOperations } from '../../../../../../state/availability';

import EquipmentCardRecurrenceMobile from './EquipmentCardRecurrenceMobile';
import EquipmentCardRecurrenceTablet from './EquipmentCardRecurrenceTablet';
import EquipmentCardRecurrenceDesktop from './EquipmentCardRecurrenceDesktop';

import { isProfile2, isProfile3 } from '../../../../../../util';

class EquipmentCardRecurrence extends Component {
  componentDidMount() {
    const { getSingleAvailability, resource } = this.props;
    if (!resource.availabilitiesLoaded) {
      getSingleAvailability(resource);
    }
  }

  render() {
    const { resource } = this.props;
    const profile2 = isProfile2(resource);
    const profile3 = isProfile3(resource);

    const equipmentCardProps = {
      ...this.props,
      isProfile2: profile2,
      isProfile3: profile3
    };

    return (
      <div>
        <ShowAt breakpoint="600AndBelow">
          <EquipmentCardRecurrenceMobile {...equipmentCardProps} />
        </ShowAt>
        <ShowAt breakpoint="600AndAbove 900AndBelow">
          <EquipmentCardRecurrenceTablet {...equipmentCardProps} />
        </ShowAt>
        <ShowAt breakpoint="900AndAbove">
          <EquipmentCardRecurrenceDesktop {...equipmentCardProps} />
        </ShowAt>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  showTimeInput: searchSelectors.isRelativeTime(state)
});

const mapDispatchToProps = dispatch => ({
  getSingleAvailability: room => dispatch(availabilityOperations.loadSingleAvailability(room))
});

EquipmentCardRecurrence.propTypes = {
  resource: CustomPropTypes.resource,
  getSingleAvailability: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentCardRecurrence);
