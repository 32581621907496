/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Radio,
  RadioGroup,
  TextArea,
  Icon,
  HelpButton,
  Button,
  CustomPropTypes,
  withTranslation
} from '../../common';
import MaintenanceDate from './MaintenanceDate';
import MaintenanceTime from './MaintenanceTime';

import './_style.css';

class MaintenanceForm extends Component {
  renderType = () => {
    const { payload, update, translate } = this.props;
    const label = translate('admin.type.label');
    const info = translate('admin.type.info');
    const maintenance = translate('admin.type.maintenance');
    const { type } = payload;
    const switchType = ({ target: { value } }) => {
      update({ ...payload, type: value });
    };
    return (
      <div className="MaintenanceForm__Type">
        <RadioGroup label={label} value={type} onChange={switchType}>
          <Radio label={info} value="info" />
          <Radio label={maintenance} value="maintenance" />
        </RadioGroup>
      </div>
    );
  };

  renderMessageInput = language => {
    const { payload, update, isSubmitted, isDirty, translate } = this.props;
    const max = 500;
    const id = `admin.message.${language}`;
    const label = translate(`admin.language.${language}`);
    const placeholder = translate('admin.language.placeholder');
    const message = payload[language];
    const onChange = ({ target: { value } }) => {
      const exceeded = value.length > max;
      if (!exceeded) {
        update({ ...payload, [language]: value });
      }
    };
    const length = (message != null && message.length) || 0;
    const empty = length < 1;

    return (
      <>
        <label htmlFor={id}>{label}</label>
        <div className="MaintenanceFormInput MaintenanceFormInput--message">
          <Icon icon="080-speech-bubble" className="MaintenanceFormInput__Icon" />
          <TextArea
            minRows={2}
            maxRows={3}
            fixed
            id={id}
            type="text"
            placeholder={placeholder}
            value={message}
            error={isSubmitted && isDirty && empty}
            className="MaintenanceFormInput__Input"
            onChange={onChange}
          />
        </div>
        <div className="MaintenanceFormInput__InputCounter">
          {length}
          {' / '}
          {max}
        </div>
      </>
    );
  };

  renderMessageValidationSummary = () => {
    const { isSubmitted, isDirty, languagesValid, translate } = this.props;
    const showValidationSummary = isSubmitted && isDirty && !languagesValid;
    if (!showValidationSummary) {
      return null;
    }

    const validationSummary = translate('admin.warning.languages');
    return <div className="MaintenanceFormInput__ValidationSummary">{validationSummary}</div>;
  };

  renderDateTime = type => {
    const { payload, isDirty, isSubmitted, update, translate } = this.props;
    const id = `admin.date.${type}`;
    const label = translate(`admin.date.${type}`);

    const date = payload[type].date;
    const time = payload[type].time;
    const onDateChange = value => update({ ...payload, [type]: { date: value, time } });
    const onTimeChange = value => update({ ...payload, [type]: { date, time: value } });
    const showError = isDirty && isSubmitted;

    return (
      <>
        <label htmlFor={id}>{label}</label>
        <MaintenanceDate value={date} onChange={onDateChange} showError={showError} />
        <MaintenanceTime value={time} onChange={onTimeChange} showError={showError} />
      </>
    );
  };

  renderSampleText = () => {
    const text = 'This is an **important** information. '
      + 'You can find more infos [here](https://intranet.swisscom.com).';
    return <div>{text}</div>;
  };

  renderSyntaxHelp = () => {
    const { translate } = this.props;
    const syntax = translate('admin.help.syntax');
    const example = translate('admin.help.example');
    return (
      <div className="MaintenanceFormInput__Info">
        <div className="MaintenanceFormInfo__Intro">{syntax}</div>
        <table width="100%">
          <thead>
            <tr>
              <th aria-label="Link" />
              <th>Syntax:</th>
              <th>Result:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Links:</td>
              <td>[link](/uri)</td>
              <td>
                <code>{'<a href="/uri">link</a>'}</code>
              </td>
            </tr>
            <tr>
              <td>Bold:</td>
              <td>**text**</td>
              <td>
                <code>{'<strong>text</strong>'}</code>
              </td>
            </tr>
            <tr>
              <td>Italic:</td>
              <td>*text*</td>
              <td>
                <code>{'<em>text</em>'}</code>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="MaintenanceFormInfo__Example">{example}</div>
        {this.renderSampleText()}
      </div>
    );
  };

  renderInfo = () => (
    <div className="MaintenanceFormInput__Help">
      <div className="MaintenanceFormInput__HelpContent">
        <HelpButton
          content={this.renderSyntaxHelp()}
          className="MaintenanceFormInput__HelpButton"
        />
      </div>
    </div>
  );

  renderDateValidationSummary = () => {
    const { isDirty, isSubmitted, dateValid, rangeValid, translate } = this.props;
    const showDateValidationSummary = isSubmitted && isDirty && !dateValid;
    const showRangeValidationSummary = isSubmitted && isDirty && !rangeValid;
    if (!showDateValidationSummary && !showRangeValidationSummary) {
      return null;
    }

    const validationSummary = showDateValidationSummary
      ? translate('admin.warning.date')
      : translate('admin.warning.range');

    return <div className="MaintenanceFormInput__ValidationSummary">{validationSummary}</div>;
  };

  renderButtons = () => {
    const {
      isSubmitting,
      isSubmitted,
      isDirty,
      isValid,
      isPersisted,
      clear,
      submit,
      translate
    } = this.props;
    const remove = translate('common.delete');
    const save = translate('common.save');
    return (
      <div className="MaintenanceFormInput__Actions">
        {isPersisted && <Button secondary disabled={isSubmitting} label={remove} onClick={clear} />}
        <Button
          label={save}
          disabled={isSubmitting || (isDirty && isSubmitted && !isValid)}
          onClick={submit}
          className="MaintenanceFormInputSubmit"
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        <div className="MaintenanceForm">{this.renderType()}</div>
        <hr className="MaintenanceForm__Divider" />
        <div className="MaintenanceForm__Messages">
          {this.renderInfo()}
          {this.renderMessageInput('german')}
          {this.renderMessageInput('french')}
          {this.renderMessageInput('italian')}
          {this.renderMessageInput('english')}
          {this.renderMessageValidationSummary()}
        </div>
        <hr className="MaintenanceForm__Divider" />
        <div className="MaintenanceForm__DateTime">
          {this.renderDateTime('from')}
          {this.renderDateTime('to')}

          {this.renderDateValidationSummary()}
        </div>
        <div className="MaintenanceForm__Submit">{this.renderButtons()}</div>
      </div>
    );
  }
}

MaintenanceForm.propTypes = {
  payload: CustomPropTypes.adminMessage.isRequired,
  isDirty: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isPersisted: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  languagesValid: PropTypes.bool.isRequired,
  rangeValid: PropTypes.bool.isRequired,
  dateValid: PropTypes.bool.isRequired,
  clear: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(MaintenanceForm);
