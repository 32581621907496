import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BookForm, {
  BookFormSubmit,
  BookFormNotes,
  BookFormProfile3,
  BookFormProfile2,
  BookFormRecurrence,
  BookFormDate,
  BookFormTime
} from '../BookForm';
import { BookDetailSidebar } from '../BookDetail';

import { Spacing, ResourceImages } from '../../common';
import { isProfile2, isProfile3 } from '../../../util';
import Header from '../../Header';

class BookCreateModalMobile extends Component {
  renderBookForm = () => {
    const { selectedResource, isRecurrence } = this.props;
    const profile3 = isProfile3(selectedResource);

    if (profile3) {
      return null;
    }

    return <BookForm mobile {...this.props} recurrence={isRecurrence} />;
  };

  renderBookFormTime = () => {
    const { selectedResource } = this.props;
    const profile3 = isProfile3(selectedResource);

    if (profile3) {
      return null;
    }
    return (
      <>
        <BookFormDate {...this.props} mobile />
        <BookFormTime {...this.props} />
      </>
    );
  };

  renderBookFormRecurrence = () => {
    const { selectedResource, isRecurrence } = this.props;
    const profile3 = isProfile3(selectedResource);

    if (profile3) {
      return null;
    }
    return (
      <BookFormRecurrence
        recurrence={isRecurrence}
        resource={selectedResource}
        hasLoadedAvailabilities={selectedResource.availabilitiesLoaded}
      />
    );
  };

  renderBookFormProfile2 = () => {
    const { selectedResource } = this.props;
    const profile2 = isProfile2(selectedResource);

    if (!profile2) {
      return null;
    }

    return (
      <Spacing top={1}>
        <BookFormProfile2 resource={selectedResource} />
      </Spacing>
    );
  };

  renderNotes = () => {
    const { selectedResource } = this.props;
    const { notes } = selectedResource;

    if (!notes) {
      return null;
    }

    return (
      <Spacing top={1}>
        <BookFormNotes notes={notes} />
      </Spacing>
    );
  };

  renderSidebar = () => {
    const { selectedResource } = this.props;
    return (
      <>
        <BookDetailSidebar resource={selectedResource} />
        {this.renderBookFormProfile2()}
        {this.renderNotes()}
      </>
    );
  };

  renderBody = () => {
    const { selectedResource } = this.props;
    const profile3 = isProfile3(selectedResource);
    if (profile3) {
      return <BookFormProfile3 resource={selectedResource} />;
    }

    return (
      <>
        <div className="BookModal__Content">{this.renderBookForm()}</div>
        <hr className="BookModal__Divider" style={{ marginBottom: 20, marginTop: 15 }} />
        <div className="BookModal__Content">{this.renderBookFormTime()}</div>
        <hr className="BookModal__Divider" style={{ marginBottom: 20 }} />
        <div className="BookModal__Content">{this.renderBookFormRecurrence()}</div>
      </>
    );
  };

  renderSubmit = () => {
    const { selectedResource, createBooking, isReadyToSubmit, isSubmitting } = this.props;
    const profile3 = isProfile3(selectedResource);
    const profile2 = isProfile2(selectedResource);

    if (profile3) {
      return null;
    }
    return (
      <BookFormSubmit
        onClick={createBooking}
        isReadyToSubmit={isReadyToSubmit}
        isSubmitting={isSubmitting}
        profile2={profile2}
        mobile
      />
    );
  };

  render() {
    const { selectedResource, clearModals } = this.props;

    return (
      <>
        <Header
          title={selectedResource.displayName}
          onClose={clearModals}
          dynamic
          fixedMobile
          iconRight
          icon="022-close"
        />

        <div className="BookModalMobile">
          <ResourceImages resource={selectedResource} />
          <div className="BookModal__Main">
            {this.renderBody()}
            <hr className="BookModal__Divider" style={{ marginBottom: 20 }} />
            <div className="BookModal__Content">{this.renderSidebar()}</div>
          </div>
        </div>

        {this.renderSubmit()}
      </>
    );
  }
}
BookCreateModalMobile.propTypes = {
  selectedResource: PropTypes.shape({
    displayName: PropTypes.string,
    availabilitiesLoaded: PropTypes.any,
    notes: PropTypes.any
  }),
  clearModals: PropTypes.func.isRequired,
  isRecurrence: PropTypes.bool.isRequired,
  createBooking: PropTypes.func.isRequired,
  isReadyToSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired
};

export default BookCreateModalMobile;
