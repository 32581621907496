/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../common';

import './_style.css';

function BookFormCollectionPlaceholder({ translate }) {
  const text = translate('bookingCollection.placeholder.text');
  const createMarkup = () => ({ __html: text });
  return (
    <div className="BookFormCollectionPlaceholder">
      <span dangerouslySetInnerHTML={createMarkup()} />
    </div>
  );
}

BookFormCollectionPlaceholder.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookFormCollectionPlaceholder);
