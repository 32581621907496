import types from './types';

const initialState = {
  master: null,
  cachedMaster: null,
  isLoading: false,
  attendees: {
    searchTerm: '',
    suggestions: [],
    selected: [],
    isLoading: false
  },
  bookFor: {
    person: null,
    suggestions: [],
    isLoading: false
  }
};

const recurrenceMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_SELECTED_MASTER: {
      return {
        ...state,
        master: action.master
      };
    }

    case types.UPDATE_SUBJECT: {
      return {
        ...state,
        master: {
          ...state.master,
          subject: action.subject
        }
      };
    }

    case types.UPDATE_MESSAGE: {
      return {
        ...state,
        master: {
          ...state.master,
          message: action.message
        }
      };
    }

    case types.UPDATE_REMINDER: {
      return {
        ...state,
        master: {
          ...state.master,
          reminder: action.reminder
        }
      };
    }

    case types.UPDATE_IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading
      };
    }

    case types.CACHE_SELECTED_MASTER: {
      return {
        ...state,
        cachedMaster: state.master
      };
    }

    case types.RESTORE_SELECTED_MASTER: {
      return {
        ...state,
        master: state.cachedMaster,
        cachedMaster: null
      };
    }

    case types.UPDATE_BOOKFOR_PERSON: {
      return {
        ...state,
        bookFor: {
          ...state.bookFor,
          person: action.person
        }
      };
    }

    case types.UPDATE_BOOKFOR_ISLOADING: {
      return {
        ...state,
        bookFor: {
          ...state.bookFor,
          isLoading: action.isLoading
        }
      };
    }

    case types.UPDATE_BOOKFOR_SUGGESTIONS: {
      return {
        ...state,
        bookFor: {
          ...state.bookFor,
          suggestions: action.suggestions
        }
      };
    }

    case types.UPDATE_SUGGESTED_ATTENDEES: {
      return {
        ...state,
        attendees: {
          ...state.attendees,
          suggestions: action.suggestedAttendees
        }
      };
    }

    case types.UPDATE_SELECTED_ATTENDEES: {
      return {
        ...state,
        attendees: {
          ...state.attendees,
          selected: action.selectedAttendees
        }
      };
    }

    case types.CLEAR_ATTENDEES: {
      return {
        ...state,
        attendees: {
          ...state.attendees,
          selected: []
        },
        master: {
          ...state.master,
          attendees: []
        }
      };
    }

    case types.SET_ATTENDEES_SEARCH_TERM: {
      return {
        ...state,
        attendees: {
          ...state.attendees,
          searchTerm: action.term
        }
      };
    }

    case types.TOGGLE_ATTENDEES_LOADER: {
      return {
        ...state,
        attendees: {
          ...state.attendees,
          isLoading: action.isLoading
        }
      };
    }

    default:
      return state;
  }
};

export default recurrenceMasterReducer;
