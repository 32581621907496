import types from '../types';

const updateTime = time => ({
  type: types.UPDATE_TIME,
  time
});

const addResource = resource => ({
  type: types.ADD_RESOURCE,
  resource
});

const removeResource = resourceId => ({
  type: types.REMOVE_RESOURCE,
  resourceId
});

const updateSeatingId = seatingId => ({
  type: types.UPDATE_SEATING_ID,
  seatingId
});

const resetCollectionState = () => ({
  type: types.RESET_COLLECTION_STATE
});

export default {
  updateTime,
  addResource,
  removeResource,
  resetCollectionState,
  updateSeatingId
};
