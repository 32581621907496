import types from './types';

const addAvailability = (section, availability) => ({
  type: types.ADD_AVAILABILITY,
  section,
  availability
});

const updateAvailability = (section, availability) => ({
  type: types.UPDATE_AVAILABILITY,
  section,
  availability
});

const updateSkipCount = (section, skipCount) => ({
  type: types.UPDATE_SKIPCOUNT,
  section,
  skipCount
});

const updateIsLoading = (section, isLoading) => ({
  type: types.UPDATE_LOADING,
  section,
  isLoading
});

const updateHasMoreResults = (section, hasMore) => ({
  type: types.HAS_MORE_RESULTS,
  section,
  hasMore
});

const clearAllAvailabilities = () => ({
  type: types.CLEAR_ALL_AVAILABILITIES
});

const removeResource = resourceId => ({
  type: types.REMOVE_BY_ID,
  resourceId
});

export default {
  addAvailability,
  updateAvailability,
  updateSkipCount,
  updateIsLoading,
  updateHasMoreResults,
  clearAllAvailabilities,
  removeResource
};
