import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../common';

import './_style.css';
import WarningBox from '../../../common/WarningBox/WarningBox';

function RecurrenceExceptionsWarning({ translate }) {
  const text = translate('search.recurrence.warning');
  return <WarningBox text={text} />;
}

RecurrenceExceptionsWarning.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurrenceExceptionsWarning);
