const UPDATE_MAINTENANCE_MESSAGE = 'maintenanceMessageState/UPDATE_MAINTENANCE_MESSAGE';
const HIDE_MESSAGES = 'maintenanceMessageState/HIDE_MESSAGES';

const RESET = 'maintenanceMessageState/RESET';
const UPDATE_PAYLOAD = 'maintenanceMessageState/UPDATE_PAYLOAD';
const UPDATE_IS_LOADING = 'maintenanceMessageState/UPDATE_IS_LOADING';
const UPDATE_IS_PERSISTED = 'maintenanceMessageState/UPDATE_IS_PERSISTED';
const UPDATE_IS_SUBMITTING = 'maintenanceMessageState/UPDATE_IS_SUBMITTING';
const UPDATE_IS_SUBMITTED = 'maintenanceMessageState/UPDATE_IS_SUBMITTED';

export default {
  UPDATE_MAINTENANCE_MESSAGE,
  HIDE_MESSAGES,
  RESET,
  UPDATE_PAYLOAD,
  UPDATE_IS_LOADING,
  UPDATE_IS_PERSISTED,
  UPDATE_IS_SUBMITTING,
  UPDATE_IS_SUBMITTED
};
