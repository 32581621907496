import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  changePermissionsSelectors,
  changePermissionsOperations
} from '../../../../state/changePermissions';

import PermissionsForm from './PermissionsForm';

import './_style.css';

class PermissionsDetails extends Component {
  componentDidMount() {
    const { initializeData, changeRequest } = this.props;
    initializeData(changeRequest);
  }

  render() {
    const { changeRequest } = this.props;
    const { readOnly, status } = changeRequest;

    const isReadonly = readOnly || status === 'Approved' || status === 'Rejected';

    return (
      <div className="OwnerForm">
        <PermissionsForm readOnly={isReadonly} {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  owners: changePermissionsSelectors.getOwners(state),
  reason: changePermissionsSelectors.getReason(state),
  isOwnerLoading: changePermissionsSelectors.isOwnerLoading(state),
  ownerSuggestions: changePermissionsSelectors.getOwnerSuggestions(state),
  isValid: changePermissionsSelectors.isValid(state),
  hasChanges: changePermissionsSelectors.hasChanges(state)
});

const mapDispatchToProps = dispatch => ({
  loadOwnerSuggestions: term => dispatch(changePermissionsOperations.loadOwnerSuggestions(term)),
  initializeData: changeRequest => dispatch(changePermissionsOperations.initializeData(changeRequest)),
  updateFormValue: (field, value) => dispatch(changePermissionsOperations.updateFormValue(field, value))
});

PermissionsDetails.propTypes = {
  changeRequest: PropTypes.shape({
    readOnly: PropTypes.any,
    status: PropTypes.any
  }),
  initializeData: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionsDetails);
