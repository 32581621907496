import mapValues from 'lodash/fp/mapValues';

import types from './types';

const defaultProfile = {
  active: false,
  contact: {
    old: null,
    new: null
  },
  owners: { old: [], new: [] }
};

const initialState = {
  profile: 1,
  profiles: {
    1: { ...defaultProfile },
    2: { ...defaultProfile },
    3: { ...defaultProfile },
    4: { ...defaultProfile }
  },
  reason: '',
  contactSuggestions: [],
  contactIsLoading: false,
  ownersSuggestions: [],
  ownersIsLoading: false,
  submitted: false,
  isSubmitting: false
};

const formatPerson = person => person && {
  name: person.displayName,
  id: person.accountName,
  value: person.accountName,
  email: person.email || person.accountName
};

const { UPDATE_OWNER_MODE } = types;

const changeOwnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MAP_OWNER_DATA: {
      const { profile: type, managedBy } = action.resource;
      let index = 0;
      const resourceProfile = type === 0 ? 1 : type;

      const profiles = mapValues(profile => {
        index += 1;
        const active = resourceProfile === index;
        const contact = formatPerson(managedBy);
        return {
          ...profile,
          active,
          contact: {
            old: contact,
            new: contact
          }
        };
      }, state.profiles);
      return {
        ...state,
        profile: resourceProfile,
        profiles,
        reason: ''
      };
    }

    case types.INITIALIZE_DATA: {
      const { resourceProfile, contact, owners, reason, resource } = action.changeRequest;
      let index = 0;
      const profiles = mapValues(() => {
        index += 1;
        return {
          active: resourceProfile === index,
          contact: {
            old: formatPerson(resource.managedBy),
            new: formatPerson(contact)
          },
          owners: {
            old: [],
            new: owners.map(person => ({ ...person, ...formatPerson(person) }))
          }
        };
      }, state.profiles);
      return {
        ...state,
        profile: resource.profile,
        profiles,
        reason
      };
    }

    case types.INITIALIZE_OWNERS: {
      const { owners, mode } = action;
      const profiles = mapValues(profile => {
        const mappedOwners = owners.map(person => ({
          ...person,
          ...formatPerson(person)
        }));
        switch (mode) {
          case UPDATE_OWNER_MODE.new: {
            return {
              ...profile,
              owners: { old: profile.owners.old, new: mappedOwners }
            };
          }
          case UPDATE_OWNER_MODE.old: {
            return {
              ...profile,
              owners: { old: mappedOwners, new: profile.owners.new }
            };
          }
          default: {
            return {
              ...profile,
              owners: { old: mappedOwners, new: mappedOwners }
            };
          }
        }
      }, state.profiles);
      return { ...state, profiles };
    }

    case types.SELECT_PROFILE: {
      const { profile } = action;
      let index = 0;
      const profiles = mapValues(currentProfile => {
        index += 1;
        return { ...currentProfile, active: profile === index };
      }, state.profiles);
      return { ...state, profiles };
    }

    case types.UPDATE_CONTACT: {
      const { profile, contact: newContact } = action;
      let index = 0;
      const profiles = mapValues(currentProfile => {
        index += 1;
        const currentContact = currentProfile.contact;
        const contact = profile === index ? { ...currentContact, new: newContact } : currentContact;
        return { ...currentProfile, contact };
      }, state.profiles);
      return { ...state, profiles };
    }

    case types.UPDATE_OWNERS: {
      const newOwners = action.owners.map(owner => {
        if (owner.memberType) {
          return owner;
        }
        return {
          ...owner,
          accountName: owner.value,
          displayName: owner.name,
          memberType: 'User'
        };
      });

      let index = 0;
      const profiles = mapValues(currentProfile => {
        index += 1;
        const currentOwners = currentProfile.owners;
        const owners = action.profile === index ? { ...currentOwners, new: newOwners } : currentOwners;
        return { ...currentProfile, owners };
      }, state.profiles);
      return { ...state, profiles };
    }

    case types.UPDATE_REASON: {
      const { reason } = action;
      return { ...state, reason };
    }

    case types.UPDATE_CONTACT_ISLOADING: {
      return { ...state, contactIsLoading: action.isLoading };
    }

    case types.UPDATE_CONTACT_SUGGESTIONS: {
      return { ...state, contactSuggestions: action.suggestions };
    }

    case types.UPDATE_OWNER_ISLOADING: {
      return { ...state, ownersIsLoading: action.isLoading };
    }

    case types.UPDATE_OWNER_SUGGESTIONS: {
      return { ...state, ownersSuggestions: action.suggestions };
    }

    case types.UPDATE_SUBMITTED: {
      return { ...state, submitted: true };
    }

    case types.UPDATE_IS_SUBMITTING: {
      return { ...state, isSubmitting: action.isSubmitting };
    }

    default:
      return state;
  }
};

export default changeOwnerReducer;
