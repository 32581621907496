import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import Notification from './Notification';

import './_style.css';

const icon = <Icon icon="235-robot-laughing" className="Notification__Confirmation-Icon" />;

function Confirmation({ text, title, autoClose, onClose }) {
  return (
    <Notification
      icon={icon}
      type="confirmation"
      title={title}
      text={text}
      autoClose={autoClose}
      onClose={onClose}
  />
  );
}

Confirmation.propTypes = {
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  autoClose: PropTypes.bool,
  onClose: PropTypes.func
};

Confirmation.defaultProps = {
  autoClose: false
};

export default Confirmation;
