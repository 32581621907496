import omit from 'lodash/fp/omit';

import { toggleCompactMode, setDirty } from './actions';

import { myBookingsService } from '../../services';
import { bookSelectors, bookOperations } from '../book';
import { timelineBookingsOperations } from '../timelineBookings';
import { modalOperations } from '../modal';
import { recurrenceOperations } from '../recurrence';
import { recurrenceMasterOperations } from '../recurrenceMaster';
import { notificationsSelectors, notificationsOperations } from '../notifications';

const createBooking = () => async (dispatch, getState) => {
  const state = getState();
  const bookRequest = bookSelectors.getBookRequest(state);
  const resource = bookSelectors.getSelectedResource(state);
  const isVirtual = resource && (resource.isVirtual || resource.isVirtualMember);

  if (!bookSelectors.isReadyToSubmit(state)) {
    return Promise.reject(new Error('Form is not valid'));
  }

  const bookings = await dispatch(bookOperations.submitBooking());

  if (bookRequest.isRecurring || isVirtual) {
    dispatch(setDirty());
    dispatch(timelineBookingsOperations.loadRoomBookings());
    dispatch(recurrenceOperations.resetState());
    return bookings;
  }

  const newCalendarBookings = bookings.map(booking => ({
    [booking.calendarId + booking.resource.id]: {
      ...omit('resource', booking),
      resourceId: booking.resource.id
    }
  }));

  newCalendarBookings.forEach(booking => dispatch(timelineBookingsOperations.addBookings(booking)));
  return bookings;
};

const updateBooking = () => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  dispatch(bookOperations.updateIsSubmitting(true));
  const resource = bookSelectors.getSelectedResource(state);

  dispatch(bookOperations.updateBookingFromState())
    .then(booking => {
      if (resource && (resource.isVirtual || resource.isVirtualMember)) {
        dispatch(setDirty());
        dispatch(timelineBookingsOperations.loadRoomBookings());
      } else {
        dispatch(bookOperations.updateSelectedBooking(booking));
        dispatch(timelineBookingsOperations.updateBooking(booking));
      }

      dispatch(modalOperations.clearModals());

      const success = getNotification('search.success.update');
      dispatch(notificationsOperations.showSuccess(success));
    })
    .catch(serverError => {
      const error = getNotification('search.error.update', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => {
      dispatch(bookOperations.updateIsSubmitting(false));
    });
};

const deleteCalendarBooking = (globalId, payload, notificationPath) => (dispatch, getState) => {
  const state = getState();

  const getNotification = notificationsSelectors.getNotification(state);
  const resource = bookSelectors.getSelectedResource(state);

  dispatch(notificationsOperations.hideError());
  dispatch(bookOperations.updateIsSubmitting(true));

  return myBookingsService
    .remove(globalId, payload)
    .then(() => {
      const success = getNotification(`${notificationPath}.success`);
      dispatch(notificationsOperations.showSuccess(success));
      dispatch(modalOperations.clearModals());
      if (resource && (resource.isVirtual || resource.isVirtualMember)) {
        dispatch(setDirty());
        dispatch(timelineBookingsOperations.loadRoomBookings());
      }
    })
    .catch(serverError => {
      const error = getNotification(`${notificationPath}.error`, serverError);
      dispatch(notificationsOperations.showError(error));
      return Promise.reject(serverError);
    })
    .then(() => {
      dispatch(bookOperations.updateIsSubmitting(false));
      return Promise.resolve();
    });
};

const deleteBooking = (globalId, calendarId, resourceId) => (dispatch, getState) => {
  const state = getState();

  const payload = bookSelectors.getRecurrenceRequestInfo(state);

  dispatch(deleteCalendarBooking(globalId, payload, 'search.remove')).then(() => {
    dispatch(timelineBookingsOperations.removeBooking(payload.calendarId, resourceId));
  });
};

const updateMaster = () => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(recurrenceMasterOperations.updateRecurrenceMaster())
    .then(() => {
      dispatch(setDirty());
      dispatch(timelineBookingsOperations.loadRoomBookings());
      dispatch(modalOperations.clearModals());
      const success = getNotification('search.recurrence.update.success');
      dispatch(notificationsOperations.showSuccess(success));
    })
    .catch(serverError => {
      const error = getNotification('search.recurrence.update.error', serverError);
      dispatch(notificationsOperations.showError(error));
    });
};

const deleteMaster = (globalId, calendarId) => dispatch => {
  const payload = {
    calendarId,
    isRecurring: true,
    isOccurrence: false
  };

  dispatch(deleteCalendarBooking(globalId, payload, 'search.remove.master')).then(() => {
    dispatch(timelineBookingsOperations.removeRecurring(globalId));
  });
};

export default {
  toggleCompactMode,
  setDirty,
  createBooking,
  updateBooking,
  deleteBooking,
  updateMaster,
  deleteMaster
};
