import store from 'store2';
import actions from './actions';
import { floorsService } from '../../services';
import { notificationsOperations, notificationsSelectors } from '../notifications';
import buildingsActions from '../buildings/actions';

const persistData = dispatch => (floors, buildingKey) => {
  store('floors', floors);
  dispatch(buildingsActions.addFloorsToBuilding(floors, buildingKey));
  dispatch(actions.updateFloors(floors));
  dispatch(actions.updateIsLoaded(Date.now()));
};

const resetFloors = () => dispatch => {
  dispatch(actions.resetFloors());
};

const updateFloors = buildingKey => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const persist = persistData(dispatch, state);
  const searchBuildingKey = buildingKey == null ? '' : buildingKey;

  return floorsService
    .getAll(searchBuildingKey)
    .then(response => {
      const data = response;
      persist(data, searchBuildingKey);
    })
    .catch(serverError => {
      const error = getNotification('floors.error', serverError);
      dispatch(notificationsOperations.showError(error));
    });
};

const updateFloor = floor => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  return floorsService
    .updateFloor(floor.id, { Name: floor.name, BuildingKey: floor.buildingKey })
    .then(() => {
      const persist = persistData(dispatch, state);
      return floorsService
        .getAll(floor.buildingKey)
        .then(response => {
          const success = getNotification('admin.settings.floors.update.success');
          dispatch(notificationsOperations.showSuccess(success));
          const data = response;
          persist(data, floor.buildingKey);
          return 'Ok';
        })
        .catch(serverError => {
          const error = getNotification('admin.settings.floors.update.error', serverError);
          dispatch(notificationsOperations.showError(error));
          return 'Error';
        });
    })
    .catch(serverError => {
      const error = getNotification('admin.settings.floors.update.error', serverError);
      dispatch(notificationsOperations.showError(error));
      return 'Error';
    });
};

const setSelectedFloor = floor => dispatch => {
  dispatch(actions.setSelectedFloor(floor));
};

const deleteFloor = (name, buildingKey) => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return floorsService
    .remove(name, buildingKey)
    .then(() => {
      const persist = persistData(dispatch, state);
      return floorsService
        .getAll(buildingKey)
        .then(response => {
          const success = getNotification('admin.settings.floors.delete.success');
          dispatch(notificationsOperations.showSuccess(success));
          const data = response;

          // reset the selectedFloor
          dispatch(actions.setSelectedFloor(null));

          persist(data, buildingKey);
          return 'Ok';
        })
        .catch(serverError => {
          const error = getNotification('admin.settings.floors.delete.error', serverError);
          dispatch(notificationsOperations.showError(error));
          return 'Error';
        });
    })
    .catch(serverError => {
      const error = getNotification('admin.settings.floors.delete.error', serverError);
      dispatch(notificationsOperations.showError(error));
      return 'Error';
    });
};

const addNewFloorToBuilding = (name, buildingKey) => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return floorsService
    .addFloor({ Name: name, BuildingKey: buildingKey })
    .then(() => {
      const persist = persistData(dispatch, state);
      return floorsService
        .getAll(buildingKey)
        .then(response => {
          const success = getNotification('admin.settings.floors.add.success');
          dispatch(notificationsOperations.showSuccess(success));
          const data = response;

          // set the new created floor as the selectedFloor
          const newSelectedFloor = data.find(floor => floor.name === name && floor.buildingKey === buildingKey);
          dispatch(actions.setSelectedFloor(newSelectedFloor));

          persist(data, buildingKey);
          return 'Ok';
        })
        .catch(serverError => {
          const error = getNotification('admin.settings.floors.add.error', serverError);
          dispatch(notificationsOperations.showError(error));
          return 'Error';
        });
    })
    .catch(serverError => {
      const error = getNotification('admin.settings.floors.add.error', serverError);
      dispatch(notificationsOperations.showError(error));
      return 'Error';
    });
};

const updateFloorMap = (map, selectedFloor) => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return floorsService
    .updateFloorMap(map)
    .then(updatedFloorMap => {
      const success = getNotification('admin.settings.floors.update.success');
      dispatch(notificationsOperations.showSuccess(success));

      const persist = persistData(dispatch, state);
      const newSelectedFloor = { ...selectedFloor };
      newSelectedFloor.map = updatedFloorMap;

      dispatch(actions.setSelectedFloor(newSelectedFloor));

      const previousFloors = state.floorsState.floors.floors;
      let newFloors = { ...previousFloors };
      newFloors = previousFloors.map(floor => (floor.id === newSelectedFloor.id ? newSelectedFloor : floor));

      persist(newFloors, newSelectedFloor.buildingKey);
      return 'Ok';
    })
    .catch(serverError => {
      const error = getNotification('admin.settings.floors.update.error', serverError);
      dispatch(notificationsOperations.showError(error));
      return 'Error';
    });
};

export default {
  updateFloors,
  updateFloor,
  setSelectedFloor,
  deleteFloor,
  addNewFloorToBuilding,
  updateFloorMap,
  resetFloors
};
