/* eslint-disable import/prefer-default-export */

import moment from 'moment';

export const isDateTimeInPast = (date, time) => {
  const now = moment();
  const selectedDate = moment(date, 'DD.MM.YYYY');
  const selectedTime = time ? moment(time.from, ['HHmm', 'HH:mm'], true) : now;
  selectedDate.set({
    hour: selectedTime.get('hour'),
    minute: selectedTime.get('minute')
  });

  return selectedDate.isBefore(now);
};
