import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Input } from '../../common';

function DateInput({ value, onChange, onFocus, className }) {
  const isValidDateInFuture = input => {
    if (input == null) {
      return false;
    }

    const date = moment(input, 'DD.MM.YYYY');
    return date.isValid() && date.isAfter(moment());
  };

  const onChangeHandler = event => onChange(event.target.value);

  const onBlurHandler = event => {
    const input = event.target.value;
    if (!isValidDateInFuture(input)) {
      onChange(
        moment()
          .add(1, 'd')
          .format('DD.MM.YYYY')
      );
    }
  };

  return (
    <Input
      type="text"
      pattern="\d{1,2}.\d{1,2}.\d{4}"
      value={value}
      onChange={onChangeHandler}
      onFocus={onFocus}
      onBlur={onBlurHandler}
      className={className}
    />
  );
}

DateInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  className: PropTypes.string
};

export default DateInput;
