import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button, CustomPropTypes, withTranslation, ResourceImages } from '../../../common';
import { BookDetailSidebar } from '../../BookDetail';

import './_style.css';

function RecurrenceExceptionSidebar(props) {
  const { resource, isLastException, isInProgress, onBook, translate } = props;

  const classes = cx(
    'RecurrenceExceptionSidebar',
    'RecurrenceExceptionSidebar__Shadow',
    'RecurrenceExceptionSidebar__Shadow--left'
  );

  const onClick = onBook ? () => onBook(resource) : undefined;
  const label = isLastException
    ? translate('search.recurrence.exceptions.select')
    : translate('search.recurrence.exceptions.selectNext');

  return (
    <div className={classes}>
      <ResourceImages resource={resource} />
      <div className="RecurrenceExceptionSidebar__Detail">
        <div className="BookDetailInformation">
          <BookDetailSidebar resource={resource} />
        </div>
        <div className="RecurrenceExceptionSidebar__Book">
          <Button label={label} onClick={onClick} loading={isInProgress} full />
        </div>
      </div>
    </div>
  );
}

RecurrenceExceptionSidebar.propTypes = {
  resource: CustomPropTypes.resource,
  isLastException: PropTypes.bool,
  isInProgress: PropTypes.bool.isRequired,
  onBook: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurrenceExceptionSidebar);
