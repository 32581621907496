import types from './types';

const updateResource = resource => ({
  type: types.UPDATE_RESOURCE,
  resource
});

const updateLoadingResource = loading => ({
  type: types.UPDATE_LOADING_RESOURCE,
  loading
});

export default {
  updateResource,
  updateLoadingResource
};
