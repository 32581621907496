import omit from 'lodash/fp/omit';

import actions from '../actions';
import { searchSelectors } from '../../search';
import { recurrenceSelectors } from '../../recurrence';
import { availabilityService } from '../../../services';

const { updateResourceAvailabilities } = actions;

const loadSingleAvailability = resource => async (dispatch, getState) => {
  const state = getState();
  const recurrence = recurrenceSelectors.getRecurrenceRequest(state);
  if (!resource.availabilities || (resource.availabilities.length <= 0 && !resource.isVirtual)) {
    return Promise.reject();
  }
  const start = searchSelectors.getStartDateTime(state);
  const end = searchSelectors.getEndDateTime(state);

  const requestBody = {
    recurrence,
    start,
    end,
    resourceTypes: ['room']
  };

  try {
    const resourceWithAvailabilities = await availabilityService.single(resource.id, requestBody);
    const { nonAvailableOccurrences } = resourceWithAvailabilities;
    resourceWithAvailabilities.nonAvailableOccurrences = nonAvailableOccurrences.map(exception => {
      const newException = omit('suggestedAlternativeResource', exception);
      newException.resource = exception.suggestedAlternativeResource;
      return newException;
    });
    dispatch(updateResourceAvailabilities(resource, resourceWithAvailabilities));
    return resourceWithAvailabilities;
  } catch (err) {
    return err;
  }
};

export default loadSingleAvailability;
