const getSearchTerm = state => state.roomPickerState.searchTerm;
const isLoading = state => state.roomPickerState.isLoading;
const getSelectedRooms = state => state.roomPickerState.selectedRooms;

const getSuggestedRooms = state => state.roomPickerState.suggestedRooms.map(room => ({
  ...room,
  originalName: room.name,
  name: `${room.name}, ${room.buildingKey}`,
  value: room.id,
  photoIds: room.photoIds || []
}));

export default {
  getSearchTerm,
  getSelectedRooms,
  getSuggestedRooms,
  isLoading
};
