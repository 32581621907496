import store from 'store2';
import types from './types';

const initialState = {
  payload: {
    type: 'info',
    german: '',
    french: '',
    italian: '',
    english: '',
    from: {
      date: '',
      time: ''
    },
    to: {
      date: '',
      time: ''
    }
  },
  loading: false,
  submitted: false,
  submitting: false,
  persisted: false,
  dirty: false,
  message: null,
  hideMessages: store.session('hideMaintenanceMessages') || false
};

const maintenanceMessageReducers = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_PAYLOAD: {
      return {
        ...state,
        payload: { ...action.payload },
        dirty: true
      };
    }

    case types.UPDATE_IS_LOADING: {
      return { ...state, loading: action.isLoading };
    }

    case types.UPDATE_IS_PERSISTED: {
      return { ...state, persisted: action.isPersisted };
    }

    case types.UPDATE_IS_SUBMITTING: {
      return { ...state, submitting: action.isSubmitting };
    }

    case types.UPDATE_IS_SUBMITTED: {
      return { ...state, submitted: true };
    }

    case types.RESET: {
      return {
        ...initialState,
        payload: { ...initialState.payload, type: state.payload.type }
      };
    }

    case types.UPDATE_MAINTENANCE_MESSAGE: {
      return {
        ...initialState,
        message: action.message
      };
    }

    case types.HIDE_MESSAGES: {
      return {
        ...initialState,
        hideMessages: true
      };
    }

    default:
      return state;
  }
};

export default maintenanceMessageReducers;
