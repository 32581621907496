import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from '../Icon/Icon';

import './_style.css';

class Notification extends Component {
  constructor(props) {
    super(props);

    const { autoClose } = this.props;

    this.state = {
      visible: true,
      overflown: false,
      expanded: false,
      autoClose
    };

    if (autoClose) {
      this.timeout = setTimeout(() => this.close(), 3000);
    }

    this.text = null;
    this.message = null;
    this.widthText = null;
    this.heightText = null;
    this.close = this.close.bind(this);
    this.expand = this.expand.bind(this);
    this.onResize = this.onResize.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
    this.renderClose = this.renderClose.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('orientationchange', this.onResize);
    this.onResize();
  }

  componentDidUpdate({ text: prevText }) {
    const { text } = this.props;
    if (text === prevText) {
      return;
    }
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('orientationchange', this.onResize);

    if (this.timeout) {
      clearTimeout(this.thimeout);
      this.timeout = null;
    }
  }

  onResize() {
    const { expanded } = this.state;
    if (!expanded) {
      const messageHeight = this.message.clientHeight;
      const textHeight = this.text.clientHeight;
      const maxTextHeight = this.heightText.scrollHeight;
      const expandedMessageHeight = messageHeight + (maxTextHeight - textHeight);

      const textWidth = this.text.clientWidth;
      const textMaxWidth = this.widthText.scrollWidth;
      this.setState({
        overflown: textMaxWidth > textWidth,
        maxHeight: expandedMessageHeight - 8
      });
    }
  }

  close() {
    const { autoClose } = this.state;
    const { onClose } = this.props;

    /* Do not autoclose if the component was already unmounted */
    if (autoClose && !this.timeout) {
      return;
    }

    this.setState({ visible: false });

    if (onClose) {
      onClose();
    }
  }

  expand() {
    const { overflown } = this.state;
    if (overflown) {
      this.setState({ expanded: true });
    }
  }

  renderIcon() {
    const { icon } = this.props;
    if (React.isValidElement(icon)) {
      return <div className="Notification__Icon">{icon}</div>;
    }
    return (
      <div className="Notification__Icon">
        <Icon icon={icon} />
      </div>
    );
  }

  renderMessage() {
    const { expanded, overflown } = this.state;
    const { title, text, autoClose } = this.props;

    const messageClasses = cx('Notification__Message', {
      'Notification__Message--autoclose': autoClose,
      'Notification__Message--expandable': overflown && !expanded
    });

    const textClasses = cx('Notification__Text', {
      'Notification__Text--cropped': !expanded
    });

    const withStyle = {
      whiteSpace: 'nowrap',
      visibility: 'hidden'
    };

    const heightStyle = {
      whiteSpace: 'wrap',
      overflow: 'scroll',
      overflowX: 'hidden',
      visibility: 'hidden'
    };

    const textRef = div => {
      this.text = div;
    };

    const messageRef = div => {
      this.message = div;
    };

    const textWidthRef = div => {
      this.widthText = div;
    };

    const textHeightRef = div => {
      this.heightText = div;
    };

    const messageProps = overflown && !expanded ? { role: 'button', tabIndex: '0', onClick: this.expand } : {};

    return (
      <div className={messageClasses} {...messageProps} ref={messageRef} data-testid="notification-message">
        <div className="Notification__Title">{title}</div>
        <div className={textClasses} ref={textRef}>
          {text}
        </div>
        {!expanded && (
          <div>
            <div className="Notification__Text" style={withStyle} ref={textWidthRef}>
              {text}
            </div>
            <div className="Notification__Text" style={heightStyle} ref={textHeightRef}>
              {text}
            </div>
          </div>
        )}
      </div>
    );
  }

  renderClose() {
    const { autoClose } = this.props;

    if (autoClose) {
      return null;
    }

    return (
      <div className="Notification__Close">
        <button
          aria-label="Close"
          onClick={this.close}
          className="Notification__Close-Link"
          type="button">
          <Icon icon="022-close" className="Notification__Close-Icon" />
        </button>
      </div>
    );
  }

  render() {
    const { visible, expanded, maxHeight } = this.state;
    const { type } = this.props;
    const classes = cx('Notification', {
      'Notification--error': type === 'error',
      'Notification--info': type === 'info',
      'Notification--confirmation': type === 'confirmation',
      'Notification--warning': type === 'warning' || type === 'maintenance',
      'Notification--hidden': !visible,
      'Notification--expanded': expanded
    });

    const style = expanded ? { height: `${ maxHeight }px` } : {};

    return (
      <div className={classes} style={style}>
        {this.renderIcon()}
        {this.renderMessage()}
        {this.renderClose()}
      </div>
    );
  }
}

Notification.propTypes = {
  type: PropTypes.oneOf(['confirmation', 'error', 'warning', 'info', 'maintenance']).isRequired,
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  autoClose: PropTypes.bool,
  onClose: PropTypes.func
};

Notification.defaultProps = {
  autoClose: false
};

export default Notification;
