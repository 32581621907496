import { createSelector } from 'reselect';
import findIndex from 'lodash/fp/findIndex';
import { buildingsSelectors } from '../../buildings';

const getResource = state => state.exceptionsState.exceptions.resource;
const getException = state => state.exceptionsState.exceptions.exception;
const getExceptions = state => state.exceptionsState.exceptions.exceptions;
const isInProgress = state => state.exceptionsState.exceptions.isInProgress;
const { getBuildings } = buildingsSelectors;

const getExceptionWithDetails = createSelector(
  [getException, getBuildings],
  (exception, buildings) => {
    if (!exception) {
      return null;
    }

    const { resource } = exception;
    if (!resource) {
      return exception;
    }

    const availabilities = resource.availabilities
      ? resource.availabilities
      : [{ start: exception.start, end: exception.end }];

    return {
      ...exception,
      resource: {
        ...resource,
        building: buildings.find(building => building.id === resource.buildingId),
        availabilities
      }
    };
  }
);

const getNextException = createSelector(
  [getExceptions, getException],
  (allExceptions, currentException) => {
    if (currentException == null) {
      return null;
    }

    const index = findIndex({ start: currentException.start }, allExceptions);
    let nextIndex = index + 1;
    while (nextIndex < allExceptions.length) {
      const exception = allExceptions[nextIndex];
      if (exception.exceptionStart == null) {
        return exception;
      }
      nextIndex += 1;
    }
    return null;
  }
);

const getNewExceptions = createSelector(
  getExceptions,
  exceptions => exceptions.map(({ start, end, skip, resource, exceptionStart, exceptionEnd }) => (skip
    ? { start, end, skip }
    : { start, end, resourceId: resource.id, exceptionStart, exceptionEnd }))
);

const hasUnhandledExceptions = createSelector(
  getExceptions,
  exceptions => {
    const hasUnhandled = exceptions.reduce((unhandled, exception) => {
      const { resource, calendarId, skip = false } = exception;
      const hasResource = resource != null || calendarId != null;
      return unhandled || (!skip && !hasResource);
    }, false);

    return hasUnhandled;
  }
);

export default {
  getResource,
  getException: getExceptionWithDetails,
  getExceptions,
  getNextException,
  getNewExceptions,
  hasUnhandledExceptions,
  isInProgress
};
