import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { lookupTranslation } from '../../../util';
import { i18nSelectors } from '../../../state/i18n';

const withTranslation = Component => {
  function enhancedComponent(props) {
    const { translations } = props;

    if (!translations) {
      return <Component {...props} />;
    }

    const translate = lookupTranslation(translations);
    const getTranslation = (path, values) => translate(path, values);

    return <Component {...props} translate={getTranslation} />;
  }

  enhancedComponent.displayName = `withTranslation(${ Component.displayName || Component.name })`;
  enhancedComponent.WrappedComponent = Component;

  enhancedComponent.propTypes = {
    translations: PropTypes.shape()
  };

  const mapStateToProps = state => ({
    translations: i18nSelectors.getTranslations(state)
  });

  return connect(mapStateToProps)(enhancedComponent);
};

export default withTranslation;
