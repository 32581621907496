import React from 'react';
import PropTypes from 'prop-types';

import { Icon, PersonPicker, withTranslation, HelpButton } from '../../common';

function BookFor({ translate, ...props }) {
  const placeholder = translate('search.detail.bookedFor.title');
  const info = translate('search.detail.bookedFor.info');
  return (
    <div className="BookModalInput BookDetailPeople">
      <Icon icon="058-person" className="BookModalInput__Icon" />
      <PersonPicker placeholder={placeholder} {...props} />
      <div className="BookedForHelpButton">
        <HelpButton content={<div className="BookedForHelpButton__Info">{info}</div>} />
      </div>
    </div>
  );
}

const personPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
});

BookFor.propTypes = {
  person: personPropType,
  suggestions: PropTypes.arrayOf(personPropType),
  loadSuggestions: PropTypes.func.isRequired,
  updatePerson: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookFor);
