import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Button } from '..';

import './_style.css';

class Popup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutside, true);
  }

  onClickOutside(event) {
    const { domNode } = this;
    if (!domNode || !domNode.contains(event.target)) {
      this.onSave();
    }
  }

  onSave() {
    const { visible } = this.state;
    const { onExit } = this.props;
    this.setState({ visible: false });
    if (visible && onExit) {
      onExit();
    }
  }

  getMaxHeight = element => {
    if (!this.element) {
      return 0;
    }
    const { top } = element.getBoundingClientRect();
    const viewPortHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return viewPortHeight - (top + 80);
  };

  show() {
    this.setState({ visible: true });
  }

  hide() {
    this.setState({ visible: false });
  }

  render() {
    const { visible } = this.state;
    const {
      component,
      children,
      place,
      className,
      fixedHeight,
      showSaveButton,
      buttonLabel
    } = this.props;

    const classes = cx('Popup', { 'Popup--visible': visible });

    const contentClasses = cx('Popup__Content', className, 'ArrowBox', {
      ArrowUp: place === 'bottom',
      ArrowDown: place === 'top',
      ArrowLeft: place === 'left',
      ArrowRight: place === 'right'
    });

    const domRef = node => {
      this.domNode = node;
    };

    const maxHeight = this.getMaxHeight(this.domNode);
    const style = fixedHeight
      ? { maxHeight: 282, overflowY: 'auto' }
      : { maxHeight, overflowY: 'auto' };

    return (
      <div>
        {component}
        <div className={classes} ref={domRef}>
          <div className={contentClasses} role="menu" tabIndex="0">
            <div style={style}>
              {children}
              {showSaveButton && (
                <Button label={buttonLabel} className="PopupSaveButton" onClick={this.onSave} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Popup.propTypes = {
  component: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
  place: PropTypes.oneOf(['bottom', 'top', 'left', 'right']),
  className: PropTypes.string,
  onExit: PropTypes.func,
  fixedHeight: PropTypes.bool,
  showSaveButton: PropTypes.bool,
  buttonLabel: PropTypes.string
};

Popup.defaultProps = {
  place: 'bottom',
  fixedHeight: false,
  showSaveButton: false,
  buttonLabel: 'Save'
};

export default Popup;
