import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Header';
import { ShowAt } from '../common';

function HelpPageHeader({ title }) {
  return (
    <>
      <ShowAt breakpoint="900AndBelow">
        <Header
          title={title}
          flexibleSpacesLink
          dynamic
          fixedMobile />
      </ShowAt>
      <ShowAt breakpoint="900AndAbove">
        <Header flexibleSpacesLink />
      </ShowAt>
    </>
  );
}

HelpPageHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default HelpPageHeader;
