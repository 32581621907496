import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button, withTranslation } from '../../../../../common';

import '../../../_style.css';

function CancelResourcesToMapButton({ translate, actionOnClick }) {
  const label = translate('admin.settings.floors.map.addDialog.cancelResourcesToMapButtonLabel');
  return (
    <Button
      secondary
      label={label}
      onClick={actionOnClick} />
  );
}

CancelResourcesToMapButton.propTypes = {
  translate: PropTypes.func.isRequired,
  actionOnClick: PropTypes.func.isRequired
};

export default withTranslation(withRouter(CancelResourcesToMapButton));
