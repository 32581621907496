import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SearchRecurrence from './SearchRecurrence';
import { withTranslation } from '../../../../common';
import { recurrenceSelectors } from '../../../../../state/recurrence';
import { searchSelectors } from '../../../../../state/search';
import { formatRecurrence } from '../../../../../util';

function SearchFormDateRecurrence({ time, isValid, history, type, pattern, translate }) {
  const recurrence = formatRecurrence(translate, type, pattern);
  const enabled = time && time.from && isValid;

  const redirect = () => {
    if (enabled) {
      history.push('/search/recurrence');
    }
  };

  return (
    <div onClick={redirect} role="presentation">
      <SearchRecurrence recurrence={recurrence} disabled={!enabled} />
    </div>
  );
}

const mapStateToProps = state => ({
  time: searchSelectors.getTime(state),
  isValid: searchSelectors.isValidDateWithTime(state),
  type: recurrenceSelectors.getRecurrenceType(state),
  pattern: recurrenceSelectors.getRecurrencePattern(state)
});

SearchFormDateRecurrence.propTypes = {
  time: PropTypes.shape({
    from: PropTypes.any
  }).isRequired,
  isValid: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  pattern: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

const SearchFormDateRecurrenceComponent = withTranslation(withRouter(SearchFormDateRecurrence));
export default connect(mapStateToProps)(SearchFormDateRecurrenceComponent);
