import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../../config';
import { Button, withTranslation } from '../../../common';

import './_style.css';

function PhotoForm({ resource, translate }) {
  const {
    name,
    building: { key: buildingKey }
  } = resource;
  const send = translate('common.send');
  const info = translate('changeRequests.photo.info');
  const hint = translate('changeRequests.photo.hint');
  const size = translate('changeRequests.photo.size');
  const subject = `ReservationHub photos ${buildingKey} - ${name}`;

  // eslint-disable-next-line max-len
  const content = 'Hi everyone,%0D%0A%0D%0Ahere are new photos for this room.%0D%0A%0D%0A[] Add the photos%0D%0A[] Replace the photos%0D%0A%0D%0Athanks%0D%0Afriendly regards';

  const onClick = () => {
    window.location.href = `mailto:${config.support}?subject=${subject}&body=${content}`;
  };

  return (
    <div className="Photo__Form">
      <div className="PhotoForm__Info">{info}</div>
      <div>
        {hint}
        <br />
        {size}
      </div>
      <div className="PhotoForm__Submit">
        <Button label={send} onClick={onClick} />
      </div>
    </div>
  );
}

PhotoForm.propTypes = {
  resource: PropTypes.shape({
    name: PropTypes.string.isRequired,
    building: PropTypes.shape({
      key: PropTypes.string.isRequired
    })
  }).isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(PhotoForm);
