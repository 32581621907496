import debounce from 'debounce-promise';
import actions from './actions';
import selectors from './selectors';
import { changePermissionsService, peopleService } from '../../services';

import { notificationsSelectors, notificationsOperations } from '../notifications';
import { encodeSearchTerm } from '../../util';

const {
  reset,
  initializeData,
  updateFormValue,
  updateOwnerIsLoading,
  updateOwnerSuggestions,
  updateSubmitted,
  updateIsSubmitting
} = actions;

const submit = () => async (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(updateSubmitted());

  if (!selectors.isValid(state)) {
    return false;
  }
  dispatch(updateIsSubmitting(true));

  try {
    const values = selectors.getValues(state);
    const result = await changePermissionsService.post(values);
    dispatch(updateIsSubmitting(false));
    return result;
  } catch (serverError) {
    const error = getNotification('changeRequests.error', serverError);
    dispatch(notificationsOperations.showError(error));
    dispatch(updateIsSubmitting(false));
    return false;
  }
};

const loadOwnerSuggestions = term => (dispatch, getState) => {
  const state = getState();

  const debounceFetch = debounce(searchTerm => peopleService.get(searchTerm), 200);
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const searchTerm = encodeSearchTerm(encodeURIComponent(term));

  if (searchTerm && searchTerm.length > 0) {
    dispatch(updateOwnerIsLoading(true));
    return debounceFetch(searchTerm)
      .then(response => {
        dispatch(updateOwnerSuggestions(response));
      })
      .catch(serverError => {
        const error = getNotification('peoplepicker.error', serverError);
        dispatch(notificationsOperations.showError(error));
      })
      .then(() => {
        dispatch(updateOwnerIsLoading(false));
      });
  }
  dispatch(updateOwnerSuggestions([]));
  return null;
};

export default {
  reset,
  initializeData,
  updateFormValue,
  loadOwnerSuggestions,
  submit
};
