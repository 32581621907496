import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withTranslation } from '../common';

const formatCalendar = (today, tomorrow) => ({
  sameDay: `[${today}]`,
  nextDay: `[${tomorrow}]`,
  nextWeek: 'dddd, DD.MM.YYYY',
  sameElse: 'dddd, DD.MM.YYYY'
});

const renderDate = format => date => moment(date).calendar(null, format);

function MyBookingsDate({ date, style, translate }) {
  const today = translate('common.today');
  const tomorrow = translate('common.tomorrow');
  const render = renderDate(formatCalendar(today, tomorrow));
  return (
    <div className="MyBookingsDate" style={style}>
      {render(date)}
    </div>
  );
}

MyBookingsDate.propTypes = {
  date: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  translate: PropTypes.func.isRequired
};

export default withTranslation(MyBookingsDate);
