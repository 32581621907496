import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import cx from 'classnames';

import { Icon } from '../../common';
import './_style.css';

class AlternativeSearchResultsAccordion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true
    };
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle(event) {
    const { open } = this.state;
    this.setState({ open: !open });
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    const { title, children } = this.props;
    const { open } = this.state;

    const titleProps = {
      tabIndex: 0,
      role: 'button',
      className: 'AlternativeSearchResultsAccordion__Head ignore-react-onclickoutside',
      onClick: this.onToggle
    };

    const iconClasses = cx({
      '013-chevron-down': !open,
      '016-chevron-up': open
    });

    return (
      <div className="AlternativeSearchResultsAccordion">
        <div className="AlternativeSearchResults__Indentation">
          <div {...titleProps}>
            <span className="AlternativeSearchResultsAccordion__Title">{title}</span>
            <div className="AlternativeSearchResultsAccordion__Icon">
              <Icon icon={iconClasses} />
            </div>
          </div>
        </div>
        <AnimateHeight duration={200} height={open ? 'auto' : 0}>
          <div>{children}</div>
        </AnimateHeight>
      </div>
    );
  }
}

AlternativeSearchResultsAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};

export default AlternativeSearchResultsAccordion;
