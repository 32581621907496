import config from '../config';
import fetch from './fetch';

const getUrl = id => `${config.remoteUrl}/bookings/recurrence/exception?calendarid=${encodeURIComponent(id)}`;

async function put(id, payload) {
  const url = getUrl(id);
  return fetch(url, 'PUT', payload);
}

async function reset(id) {
  const url = getUrl(id);
  return fetch(url, 'DELETE');
}

export default { put, reset };
