import debounce from 'debounce-promise';
import actions from './actions';
import selectors from './selectors';
import { peopleService, buildingService, createResourceService, resourceDetailService } from '../../services';

import { notificationsSelectors, notificationsOperations } from '../notifications';
import { encodeSearchTerm } from '../../util';

const {
  updateFormValue,
  updateSubmitted,
  updateIsSubmitting,
  resetState,
  initializeDetail,
  updateChangeRequestInfoIsLoading,
  updateChangeRequestInfoSuggestions,
  updateBuildingIsLoading,
  updateBuildingSuggestions,
  updatePeopleIsLoading,
  updatePeopleSuggestions
} = actions;

const submit = () => async (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);

  dispatch(updateSubmitted(true));

  if (!selectors.isValid(state)) {
    return false;
  }

  dispatch(updateIsSubmitting(true));
  try {
    const values = selectors.getValues(state);
    values.building = values.building.value;
    const result = await createResourceService.post(values);
    dispatch(updateIsSubmitting(false));
    return result;
  } catch (serverError) {
    const error = getNotification('changeRequests.error', serverError);
    dispatch(notificationsOperations.showError(error));
    dispatch(updateIsSubmitting(false));
    return false;
  }
};

const loadChangeRequestInfoSuggestions = term => (dispatch, getState) => {
  const state = getState();

  const debounceFetch = debounce(searchTerm => resourceDetailService.search(searchTerm), 200);
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const searchTerm = encodeSearchTerm(encodeURIComponent(term));

  if (searchTerm && searchTerm.length > 2) {
    dispatch(updateChangeRequestInfoIsLoading(true));
    return debounceFetch(searchTerm)
      .then(response => {
        dispatch(updateChangeRequestInfoSuggestions(response));
      })
      .catch(serverError => {
        const error = getNotification('changeRequestInfopicker.error', serverError);
        dispatch(notificationsOperations.showError(error));
      })
      .then(() => {
        dispatch(updateChangeRequestInfoIsLoading(false));
      });
  }
  dispatch(updateChangeRequestInfoSuggestions([]));
  return null;
};

const loadBuildingSuggestions = term => (dispatch, getState) => {
  const state = getState();

  const debounceFetch = debounce(searchTerm => buildingService.get(searchTerm), 200);
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const searchTerm = encodeSearchTerm(encodeURIComponent(term));

  if (searchTerm && searchTerm.length > 2) {
    dispatch(updateBuildingIsLoading(true));
    return debounceFetch(searchTerm)
      .then(response => {
        dispatch(updateBuildingSuggestions(response));
      })
      .catch(serverError => {
        const error = getNotification('buildingpicker.error', serverError);
        dispatch(notificationsOperations.showError(error));
      })
      .then(() => {
        dispatch(updateBuildingIsLoading(false));
      });
  }
  dispatch(updateBuildingSuggestions([]));
  return null;
};

const loadPeopleSuggestions = term => (dispatch, getState) => {
  const state = getState();

  const debounceFetch = debounce(searchTerm => peopleService.get(searchTerm), 200);
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const searchTerm = encodeSearchTerm(encodeURIComponent(term));

  if (searchTerm && searchTerm.length > 0) {
    dispatch(updatePeopleIsLoading(true));
    return debounceFetch(searchTerm)
      .then(response => {
        dispatch(updatePeopleSuggestions(response));
      })
      .catch(serverError => {
        const error = getNotification('peoplepicker.error', serverError);
        dispatch(notificationsOperations.showError(error));
      })
      .then(() => {
        dispatch(updatePeopleIsLoading(false));
      });
  }
  dispatch(updatePeopleSuggestions([]));
  return null;
};

export default {
  updateFormValue,
  loadChangeRequestInfoSuggestions,
  loadBuildingSuggestions,
  loadPeopleSuggestions,
  resetState,
  submit,
  initializeDetail
};
