import actions from './actions';

const {
  toggleFilter,
  changeFilter,
  resetDirty,
  resetFilter,
  changeFloors,
  changeSectors,
  updateRoomTypes
} = actions;

export default {
  toggleFilter,
  changeFilter,
  changeFloors,
  changeSectors,
  resetDirty,
  resetFilter,
  updateRoomTypes
};
