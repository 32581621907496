import store from 'store2';

import actions from '../actions';

const { updateTime, addResource, removeResource, resetCollectionState, updateSeatingId } = actions;

const ignoreWarning = () => {
  store('bookingCollection.ignore.warning', true);
};

export default {
  addResource,
  removeResource,
  ignoreWarning,
  updateTime,
  resetCollectionState,
  updateSeatingId
};
