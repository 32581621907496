// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WelcomeDialog_dialog__2tK9W {\n  width: 520px;\n  padding: 34px;\n}\n\n.WelcomeDialog_intro__5ceKD {\n  display: flex;\n}\n\n.WelcomeDialog_sections__980QA {\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n\n.WelcomeDialog_section__0-FYR {\n  margin-bottom: 20px;\n}\n\n.WelcomeDialog_subheader__7oYPw {\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://./src/views/Welcome/WelcomeDialog.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".dialog {\n  width: 520px;\n  padding: 34px;\n}\n\n.intro {\n  display: flex;\n}\n\n.sections {\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n\n.section {\n  margin-bottom: 20px;\n}\n\n.subheader {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "WelcomeDialog_dialog__2tK9W",
	"intro": "WelcomeDialog_intro__5ceKD",
	"sections": "WelcomeDialog_sections__980QA",
	"section": "WelcomeDialog_section__0-FYR",
	"subheader": "WelcomeDialog_subheader__7oYPw"
};
export default ___CSS_LOADER_EXPORT___;
