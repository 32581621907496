import types from './types';

const updateFormValue = (field, value) => ({
  type: types.UPDATE_FORM_VALUE,
  field,
  value
});

const updateSubmitted = () => ({
  type: types.UPDATE_SUBMITTED
});

const updatePeopleIsLoading = isLoading => ({
  type: types.UPDATE_PEOPLE_ISLOADING,
  isLoading
});

const updatePeopleSuggestions = suggestions => ({
  type: types.UPDATE_PEOPLE_SUGGESTIONS,
  suggestions
});

const updateChangeRequestInfoIsLoading = isChangeRequestInfoLoading => ({
  type: types.UPDATE_CHANGEREQUESTINFO_ISLOADING,
  isChangeRequestInfoLoading
});

const updateChangeRequestInfoSuggestions = changeRequestInfoSuggestions => ({
  type: types.UPDATE_CHANGEREQUESTINFO_SUGGESTIONS,
  changeRequestInfoSuggestions
});

const updateBuildingIsLoading = isBuildingLoading => ({
  type: types.UPDATE_BUILDING_ISLOADING,
  isBuildingLoading
});

const updateBuildingSuggestions = buildingSuggestions => ({
  type: types.UPDATE_BUILDING_SUGGESTIONS,
  buildingSuggestions
});

const updateIsSubmitting = isSubmitting => ({
  type: types.UPDATE_IS_SUBMITTING,
  isSubmitting
});

const resetState = () => ({
  type: types.RESET_STATE
});

const initializeDetail = changeRequest => ({
  type: types.INITIALIZE_DETAIL,
  changeRequest
});

export default {
  updateFormValue,
  updateChangeRequestInfoIsLoading,
  updateChangeRequestInfoSuggestions,
  updateBuildingIsLoading,
  updateBuildingSuggestions,
  updatePeopleIsLoading,
  updatePeopleSuggestions,
  updateSubmitted,
  updateIsSubmitting,
  resetState,
  initializeDetail
};
