import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { i18nOperations } from '../../../state/i18n';

const loadLanguage = language => import(`../../../i18n/translations.${language}`);

const withLanguageSwitch = Component => {
  function enhancedComponent({ updateLanguage, ...props }) {
    // eslint-disable-next-line arrow-body-style
    const process = language => new Promise(resolve => loadLanguage(language).then(({ translations }) => {
      localStorage.setItem('language', language);
      resolve(updateLanguage(language, translations));
      moment.locale(language);
    }));
    return <Component {...props} switchLanguage={process} />;
  }

  const mapDispatchToProps = dispatch => ({
    updateLanguage: (language, translations) => {
      dispatch(i18nOperations.updateLanguage(language, translations));
    }
  });

  enhancedComponent.propTypes = {
    updateLanguage: PropTypes.func.isRequired
  };

  return connect(
    null,
    mapDispatchToProps
  )(enhancedComponent);
};

export default withLanguageSwitch;
