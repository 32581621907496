import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { bookOperations } from '../../../state/book';
import {
  Icon,
  TimeInputRange,
  TimeInputRangeValidation,
  CustomPropTypes,
  withTranslation
} from '../../common';

function BookFormTime(props) {
  const { date, time, onBlur, updateTime, loadSingleAvailability, translate, updateIsValidTimeRange } = props;

  const onChange = values => {
    updateTime(values);
    loadSingleAvailability();
  };

  const timeInputRangeProps = {
    values: time,
    onChange,
    onBlur,
    error: translate('search.when.wrongTime'),
    updateIsValidTimeRange
  };

  return (
    <div className="BookModalInput BookModalInputDate">
      <Icon icon="021-clock" className="BookModalInput__Icon" />
      <TimeInputRangeValidation date={date} time={time}>
        <TimeInputRange {...timeInputRangeProps} />
      </TimeInputRangeValidation>
    </div>
  );
}
BookFormTime.propTypes = {
  isValid: PropTypes.bool,
  date: PropTypes.string,
  time: CustomPropTypes.time,
  updateTime: PropTypes.func.isRequired,
  loadSingleAvailability: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  translate: PropTypes.func,
  updateIsValidTimeRange: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
  loadSingleAvailability: () => dispatch(bookOperations.loadSingleAvailability())
});

export default connect(
  null,
  mapDispatchToProps
)(withTranslation(BookFormTime));
