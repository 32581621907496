import debounce from 'debounce-promise';

import actions from './actions';
import selectors from './selectors';
import { roomsService } from '../../services';

import { notificationsSelectors, notificationsOperations } from '../notifications';
import { encodeSearchTerm } from '../../util';

const { updateSearchTerm, updateSelectedRooms, updateIsLoading, updateSuggestedRooms } = actions;

const debounceFetch = debounce(
  (searchTerm, includePhotoIds) => roomsService.get(searchTerm, includePhotoIds),
  200
);

const fetchRooms = (includePhotoIds = false) => (dispatch, getState) => {
  const state = getState();

  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const searchTerm = encodeSearchTerm(encodeURIComponent(selectors.getSearchTerm(state)));

  if (searchTerm && searchTerm.length > 0) {
    dispatch(updateIsLoading(true));
    return debounceFetch(searchTerm, includePhotoIds)
      .then(response => {
        dispatch(updateSuggestedRooms(response));
      })
      .catch(serverError => {
        const error = getNotification('peoplepicker.error', serverError);
        dispatch(notificationsOperations.showError(error));
      })
      .then(() => {
        dispatch(updateIsLoading(false));
      });
  }
  return null;
};

export default {
  updateSearchTerm,
  updateIsLoading,
  updateSuggestedRooms,
  updateSelectedRooms,
  fetchRooms
};
