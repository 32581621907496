import React from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/fp/includes';

import { CustomPropTypes, EquipmentCard } from '../../../common';
import EquipmentBooking from '../Booking/EquipmentBooking';
import FormSubmit from '../FormSubmit';
import PrivateInformation from '../PrivateInformation';
import ProfileInformation from '../ProfileInformation';
import RecurringInformation from '../RecurringInformation';

function BookingResourceCardMobile({
  booking,
  selected,
  onOpenOccurrence,
  onOpenMaster,
  style,
  isProfile3,
  isProfile2
}) {
  const { resource, isPrivate, isRecurring, roomMeetingResponseType } = booking;
  const { resourceSubType } = resource;

  const renderBookingInformation = () => {
    if (selected) {
      return null;
    }
    return <EquipmentBooking booking={booking} />;
  };

  const renderPrivateInformation = () => {
    if (!isPrivate || selected) {
      return null;
    }
    return <PrivateInformation key="Private" />;
  };

  const renderProfileInformation = () => {
    if (selected || !(isProfile2 || isProfile3)) {
      return null;
    }

    return (
      <ProfileInformation
        resource={resource}
        responseType={roomMeetingResponseType}
        key="Profile"
      />
    );
  };

  const renderRecurringInformation = () => {
    if (selected || !isRecurring) {
      return null;
    }
    return <RecurringInformation key="Recurring" />;
  };

  const renderFormSubmit = () => {
    if (!selected || !isRecurring) {
      return null;
    }
    return (
      <FormSubmit
        openDetailView={onOpenOccurrence}
        openMasterView={onOpenMaster}
        key="BookingSubmit"
      />
    );
  };

  const onCardClick = () => {
    const isBookedForMe = !includes('Owner', booking.permissionTypes);

    if (!isRecurring || isBookedForMe) {
      onOpenOccurrence();
    }
  };

  return (
    <EquipmentCard resourceSubType={resourceSubType} onClick={onCardClick} style={style}>
      {renderBookingInformation()}
      {renderPrivateInformation()}
      {renderRecurringInformation()}
      {renderFormSubmit()}
      {renderProfileInformation()}
    </EquipmentCard>
  );
}

BookingResourceCardMobile.propTypes = {
  booking: CustomPropTypes.booking,
  selected: PropTypes.bool.isRequired,
  style: PropTypes.shape(),
  onOpenOccurrence: PropTypes.func.isRequired,
  onOpenMaster: PropTypes.func.isRequired,
  isProfile3: PropTypes.bool.isRequired,
  isProfile2: PropTypes.bool.isRequired
};

export default BookingResourceCardMobile;
