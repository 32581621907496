import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RecurrenceNumberInput from './RecurrenceNumberInput';
import RecurrenceDateInput from './RecurrenceDateInput';
import { searchSelectors } from '../../../state/search';
import { recurrenceSelectors, recurrenceOperations } from '../../../state/recurrence';
import { Radio, RadioGroup, withTranslation, DateInput, ValidationError } from '../../common';
import { getCurrentDate } from '../../../util';

import './_style.css';

function RecurrenceEnd({ translate, startDate, ...props }) {
  const { endType, updateEndType } = props;
  const { endDate, updateEndDate } = props;
  const { numberOfBookings, updateNumberOfBookings } = props;
  const { isValidRecurrenceEnd: isValid } = props;

  const title = translate('search.recurrence.end.title');
  const placeholder = translate('search.recurrence.end.chooseDate');
  const endNumberLabelBefore = translate('search.recurrence.end.numberOfBookings.before');
  const endNumberLabelAfter = translate('search.recurrence.end.numberOfBookings.after');
  const endDateLabel = translate('search.recurrence.end.date');

  const setToToday = () => updateEndDate(getCurrentDate());

  return (
    <div className="RecurrenceEnd">
      <div className="Recurrence__Title">{title}</div>

      <RadioGroup
        value={endType}
        onChange={e => updateEndType(e.target.value)}
        className="RecurrenceEndRadioGroup"
      >
        <table className="RecurrenceEnd__Table">
          <tbody>
            <tr>
              <td>
                <Radio
                  value="after"
                  id="radio-recurrenceafter"
                  label={endNumberLabelBefore}
                  className="RecurrenceEnd__Radio"
                />
              </td>
              <td aria-label="Recurrence">
                <RecurrenceNumberInput
                  max={550}
                  value={numberOfBookings}
                  onChange={updateNumberOfBookings}
                  onFocus={() => updateEndType('after')}
                />
              </td>
              <td>{endNumberLabelAfter}</td>
            </tr>

            <tr>
              <td>
                <Radio
                  value="date"
                  id="radio-recurrencedate"
                  label={endDateLabel}
                  className="RecurrenceEnd__Radio RecurrenceEnd__Radio--last"
                />
              </td>
              <td aria-label="Recurrence button" colSpan={2}>
                <RecurrenceDateInput
                  value={endDate}
                  onFocus={() => updateEndType('date')}
                  onChange={value => updateEndDate(value)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </RadioGroup>

      <ValidationError
        message="search.recurrence.unknownEnd"
        visible={!isValid}
        className="RecurrenceEnd__ValidationError"
      />

      <div>
        {endType === 'date' && (
          <DateInput
            placeholder={placeholder}
            showInput={false}
            className="RecurrenceEnd__DateInput"
            startDate={startDate}
            value={endDate}
            onChange={updateEndDate}
            today
            onTodayClick={setToToday}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  startDate: searchSelectors.getDate(state),
  endDate: recurrenceSelectors.getRecurrenceEndDate(state),
  endType: recurrenceSelectors.getRecurrenceEndType(state),
  numberOfBookings: recurrenceSelectors.getNumberOfBookings(state),
  isValidRecurrenceEnd: recurrenceSelectors.isValidRecurrenceEnd(state)
});

const mapDispatchToProps = dispatch => ({
  updateEndType: endType => dispatch(recurrenceOperations.updateRecurrenceEndType(endType)),
  updateEndDate: endDate => dispatch(recurrenceOperations.updateRecurrenceEndDate(endDate)),
  updateNumberOfBookings: count => dispatch(recurrenceOperations.updateNumberOfBookings(count))
});

RecurrenceEnd.propTypes = {
  endType: PropTypes.string.isRequired,
  updateEndType: PropTypes.func.isRequired,
  numberOfBookings: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  updateNumberOfBookings: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  updateEndDate: PropTypes.func.isRequired,
  isValidRecurrenceEnd: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

const RecurrenceEndComponent = withTranslation(RecurrenceEnd);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecurrenceEndComponent);
