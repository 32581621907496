import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { bookSelectors } from '../../state/book';
import { modalOperations } from '../../state/modal';
import {
  bookingCollectionSelectors,
  bookingCollectionOperations
} from '../../state/bookingCollection';
import BookingCollectionDeleteDesktop from './BookingCollectionDeleteDesktop';

const getResourceId = props => {
  const { match } = props;
  return match.params.resourceId;
};

class BookingCollectionDelete extends Component {
  UNSAFE_componentWillMount() {
    const { setFakeModalActive } = this.props;
    setFakeModalActive();
  }

  render() {
    const { resource, date, remove, history } = this.props;
    return (
      <BookingCollectionDeleteDesktop
        resource={resource}
        date={date}
        remove={remove}
        close={history.goBack}
      />
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const resourceId = getResourceId(ownProps);
  const resource = bookingCollectionSelectors.getResource(resourceId, state);
  const date = bookSelectors.getDate(state);
  return { resource, date };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const resourceId = getResourceId(ownProps);
  const remove = () => {
    dispatch(bookingCollectionOperations.removeResource(resourceId));
    ownProps.history.goBack();
  };
  const setFakeModalActive = () => {
    dispatch(modalOperations.setFakeModalState(true));
  };
  return { remove, setFakeModalActive };
};

BookingCollectionDelete.propTypes = {
  resource: PropTypes.shape({}),
  remove: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  setFakeModalActive: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired
};

const BookingCollectionDeleteComponent = withRouter(BookingCollectionDelete);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingCollectionDeleteComponent);
