import types from './types';

const setFavorite = (resourceId, isActive) => ({
  type: types.SET_FAVORITE,
  resourceId,
  isActive
});

const resetFavorites = resources => ({
  type: types.RESET_FAVORITES,
  resources
});

export default {
  setFavorite,
  resetFavorites
};
