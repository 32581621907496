import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CustomPropTypes, withTranslation, ShowAt } from '../../../../common';
import { searchSelectors } from '../../../../../state/search';

import EquipmentCardMobile from './EquipmentCardMobile';
import EquipmentCardTablet from './EquipmentCardTablet';
import EquipmentCardDesktop from './EquipmentCardDesktop';

import { isProfile2, isProfile3 } from '../../../../../util';

import '../_style.css';

function EquipmentCard(props) {
  const { selected, resource, openModal } = props;

  const profile2 = isProfile2(resource);
  const profile3 = isProfile3(resource);

  const onSelect = event => {
    if (!selected && !profile3) {
      return;
    }

    if (event) {
      const { dataset } = event.target;
      if (dataset && dataset.ignore) {
        return;
      }
    }

    openModal(resource);
  };

  const equipmentCardProps = {
    ...props,
    onSelect,
    isProfile2: profile2,
    isProfile3: profile3
  };

  return (
    <div className="SearchResultCard">
      <ShowAt breakpoint="600AndBelow">
        <EquipmentCardMobile {...equipmentCardProps} />
      </ShowAt>
      <ShowAt breakpoint="600AndAbove 900AndBelow">
        <EquipmentCardTablet {...equipmentCardProps} />
      </ShowAt>
      <ShowAt breakpoint="900AndAbove">
        <EquipmentCardDesktop {...equipmentCardProps} />
      </ShowAt>
    </div>
  );
}

const mapStateToProps = state => ({
  showTimeInput: searchSelectors.isRelativeTime(state),
  showRelativeTime: !searchSelectors.getSearchedExactTime(state)
});

EquipmentCard.propTypes = {
  resource: CustomPropTypes.resource,
  selected: PropTypes.bool.isRequired,
  showTimeInput: PropTypes.bool.isRequired,
  showRelativeTime: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(withTranslation(EquipmentCard));
