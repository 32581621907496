import React from 'react';
import PropTypes from 'prop-types';

import { Icon, PeoplePicker, withTranslation } from '../../common';

function BookFormPeople({ translate, selectedPeople, ...props }) {
  const placeholder = translate('peoplepicker.placeholder');

  const maxLength = 17;

  const mappedSelectedPeople = selectedPeople.map(p => ({
    ...p,
    name: p.name.length > maxLength ? `${p.name.substring(0, maxLength)}...` : p.name
  }));

  return (
    <div className="BookModalInput BookDetailPeople">
      <Icon icon="042-group" className="BookModalInput__Icon" />
      <PeoplePicker placeholder={placeholder} {...props} selectedPeople={mappedSelectedPeople} />
    </div>
  );
}

BookFormPeople.propTypes = {
  translate: PropTypes.func.isRequired,
  selectedPeople: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default withTranslation(BookFormPeople);
