/* eslint-disable no-param-reassign */
import '../../_style.css';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactFlow, {
  ReactFlowProvider,
  Background,
  Controls,
  useReactFlow
} from 'reactflow';
import { withTranslation } from '../../../../common';
import 'reactflow/dist/style.css';
import ResourceSubTypeNode, { getResourceSubTypeNode } from './ResourceSubTypeNode';

function ResourceSubTypeShape({ style, resourceSubType, onChangedResource }) {
  const proOptions = { hideAttribution: true };
  const reactFlowInstance = useReactFlow();
  const { setNodes, getNode } = reactFlowInstance;
  const nodeTypes = {
    ResourceSubTypeNode
  };
  const debug = false;

 function updateResourceSubType() {
    const resourceSubTypeNode = getNode('resourceSubTypeNode');
    onChangedResource({
      resourceSubTypeDimensions: {
        width: resourceSubTypeNode.width,
        height: resourceSubTypeNode.height
      }
    });
  }

  const onResourceSubTypeResizeEnd = () => {
    if (debug) {
      setNodes(nodes => nodes.map(n => {
        if (n.id === 'resourceSubTypeNode') {
          n.position.x = Math.round(n.position.x); // restore the true x
        } else {
          n.position.x = Math.round(n.position.x); // restore the true x
        }
        return n;
      }));
    }
    updateResourceSubType();
  };

  // ResourceSubType node initialization
  // ------------------------------
  const resourceSubTypeNode = [getResourceSubTypeNode({
    resourceSubType,
    onResourceSubTypeResizeEnd,
    debug
  })];

  return (
    <div style={style}>
      <ReactFlow
        proOptions={proOptions}
        fitView
        defaultNodes={resourceSubTypeNode}
        nodes={resourceSubTypeNode}
        nodeTypes={nodeTypes}
        snapToGrid
        snapGrid={[10, 10]}
      >
        <Controls />
        <Background variant="dots" gap={10} size={1} />
      </ReactFlow>
    </div>
  );
}

function ResourceSubTypeShapeProvider(props) {
  const { style, updateDimensions } = props;
  let { resourceSubType } = props;

  const onChangedResource = ( { resourceSubTypeDimensions } ) => { // eslint-disable-line
    if (resourceSubTypeDimensions) resourceSubType = { ...resourceSubType, ...resourceSubTypeDimensions };
    updateDimensions(resourceSubTypeDimensions.width, resourceSubTypeDimensions.height);
  };

  return (
    <ReactFlowProvider>
      <ResourceSubTypeShape style={style} resourceSubType={resourceSubType} onChangedResource={onChangedResource} />
    </ReactFlowProvider>
  );
}

ResourceSubTypeShape.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  resourceSubType: PropTypes.objectOf(PropTypes.any),
  onChangedResource: PropTypes.func.isRequired
};

ResourceSubTypeShapeProvider.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  resourceSubType: PropTypes.objectOf(PropTypes.any),
  updateDimensions: PropTypes.func
};

export default connect(null, null)(withTranslation(ResourceSubTypeShapeProvider));
