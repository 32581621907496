import debounce from 'debounce-promise';
import latest from 'promise-latest';
import config from '../config';
import fetch from './fetch';

const getUrl = path => `${config.remoteUrl}/${path}`;

async function get(searchRequest) {
  const url = getUrl(`buildings/search?query=${searchRequest}`);
  return fetch(url, 'GET', searchRequest);
}

export default {
  get: debounce(latest(get), 50, { leading: true })
};
