import { normalize, schema } from 'normalizr';

// Define config
const config = new schema.Entity(
  'config'
);

const normalizeData = data => normalize(data, config);

export default normalizeData;
