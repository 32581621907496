import actions from './actions';
import selectors from './selectors';
import { feedbackService } from '../../services';

import { notificationsSelectors, notificationsOperations } from '../notifications';

const { updateHappyComment, updateSadComment } = actions;

const submitFeedback = () => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  const happyComment = selectors.getHappyComment(state);
  const sadComment = selectors.getSadComment(state);
  const isValid = selectors.isValid(state);

  if (!isValid) {
    dispatch(actions.hasTriedToSubmit);
    return Promise.resolve(false);
  }

  const feedback = {
    happyComment: happyComment || null,
    sadComment: sadComment || null
  };

  dispatch(notificationsOperations.hideError());
  dispatch(actions.submitting(true));

  return feedbackService
    .post(feedback)
    .then(() => {
      const success = getNotification('feedback.success');
      dispatch(notificationsOperations.showSuccess(success));
      dispatch(actions.clear);
      return true;
    })
    .catch(serverError => {
      const error = getNotification('feedback.error', serverError);
      dispatch(notificationsOperations.showError(error));
      return false;
    })
    .then(success => {
      dispatch(actions.submitting(false));
      return success;
    });
};

export default {
  updateHappyComment,
  updateSadComment,
  submitFeedback
};
