import types from './types';

const initialState = {
  scrollingEnabled: true
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ENABLE_SCROLLING: {
      return { ...state, scrollingEnabled: true };
    }

    case types.DISABLE_SCROLLING: {
      return { ...state, scrollingEnabled: false };
    }

    default:
      return state;
  }
};

export default globalReducer;
