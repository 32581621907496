const REGISTER_MODAL = 'modal/REGISTER_MODAL';
const UNREGISTER_MODAL = 'modal/UNREGISTER_MODAL';

const PUSH_MODAL = 'modal/PUSH_MODAL';
const POP_MODAL = 'modal/POP_MODAL';
const CLEAR_MODALS = 'modal/CLEAR_MODALS';
const REMOVE_MODAL = 'modal/REMOVE_MODAL';

const SET_OFFSET = 'modal/SET_OFFSET';

const SET_FAKE_MODAL_STATE = 'modal/SET_FAKE_MODAL_STATE';

export default {
  REGISTER_MODAL,
  UNREGISTER_MODAL,
  PUSH_MODAL,
  POP_MODAL,
  CLEAR_MODALS,
  REMOVE_MODAL,
  SET_OFFSET,
  SET_FAKE_MODAL_STATE
};
