import React, { Component } from 'react';
import { DayPicker } from 'react-day-picker';
import PropTypes from 'prop-types';
import isDate from 'lodash/fp/isDate';
import isNull from 'lodash/fp/isNull';
import { toLocaleDateString } from '../../../util/time';
import './_style.css';
import 'react-day-picker/dist/style.css';

class Picker extends Component {
  constructor(props) {
    super(props);
    this.lastSelected = null;
    this.month = props.month;
    this.onDayClick = this.onDayClick.bind(this);
    this.state = {
      month: new Date()
    };
  }

  UNSAFE_componentWillMount() {
    let { selectedDays } = this.props;
    if (selectedDays !== null) {
      selectedDays = !isDate(selectedDays) ? selectedDays.from : selectedDays;
      this.month = selectedDays;
    }
  }

  onDayClick(day, { disabled, selected }) {
    const { onDayClick: onDaySelected } = this.props;
    if (disabled || selected) {
      return;
    }

    if (onDaySelected) {
      onDaySelected(toLocaleDateString(day));
    }
  }

  onMonthChange = newDate => {
    this.setState({ month: newDate });
  };

  render() {
    const { month } = this.state;
    let { selectedDays } = this.props;
    selectedDays = isDate(selectedDays) || isNull(selectedDays) ? selectedDays : selectedDays.from;
    this.lastSelected = selectedDays;

    return (
      <DayPicker
        {...this.props}
        selected={selectedDays}
        month={month}
        onMonthChange={this.onMonthChange}
        onDayClick={this.onDayClick}
      />
    );
  }
}

Picker.propTypes = {
  month: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date)
    })
  ]),
  onMonthChange: PropTypes.func,
  onDayClick: PropTypes.func,
  selectedDays: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.shape({
      from: PropTypes.instanceOf(Date),
      to: PropTypes.instanceOf(Date)
    })
  ]),
  todayButton: PropTypes.string,
  onTodayButtonClick: PropTypes.func
};

export default Picker;
