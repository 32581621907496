import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { feedbackOperations, feedbackSelectors } from '../../state/feedback';
import Feedback from './Feedback';

function FeedbackPage({ submit, history, ...otherProps }) {
  const submitFeedback = () => {
    submit().then(successful => {
      if (successful) {
        history.goBack();
      }
    });
  };
  return (
    <div className="Feedback__Content">
      <Feedback {...otherProps} submit={submitFeedback} />
    </div>
  );
}

FeedbackPage.propTypes = {
  submit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({
  happyComment: feedbackSelectors.getHappyComment(state),
  sadComment: feedbackSelectors.getSadComment(state),
  submitted: feedbackSelectors.submitted(state),
  isSubmitting: feedbackSelectors.isSubmitting(state),
  isValid: feedbackSelectors.isValid(state),
  hasFeedback: feedbackSelectors.hasFeedback(state),
  hasTriedToSubmit: feedbackSelectors.hasTriedToSubmit(state),
  happyCommentNotTooLong: feedbackSelectors.happyCommentNotTooLong(state),
  sadCommentNotTooLong: feedbackSelectors.sadCommentNotTooLong(state)
});

const mapDispatchToProps = dispatch => ({
  updateHappyComment: comment => dispatch(feedbackOperations.updateHappyComment(comment)),
  updateSadComment: comment => dispatch(feedbackOperations.updateSadComment(comment)),
  submit: () => dispatch(feedbackOperations.submitFeedback())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FeedbackPage));
