import React from 'react';

import Header from '../../Header';
import SearchBar from './SearchBar';
import FilterBar from './FilterBar';
import RecurrenceFilter from './RecurrenceFilter';
import ContentSwitch from './ContentSwitch';
import Content from './Content';

import styles from './SearchDesktop.module.css';

function SearchDesktop() {
  return (
    <>
      <Header flexibleSpacesLink fixed />
      <div className={styles.content}>
        <SearchBar />
        <RecurrenceFilter />
        <FilterBar />
        <ContentSwitch />
        <Content />
      </div>
    </>
  );
}

export default SearchDesktop;
