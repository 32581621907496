import React from 'react';
import PropTypes from 'prop-types';

import withTranslation from '../I18n/withTranslation';
import Icon from '../Icon/Icon';
import CustomPropTypes from '../PropTypes';

import './_style.css';

function Profile2({ resource, path, translate, responseType }) {
  const displayName = resource.managedBy && resource.managedBy.displayName;
  const resourceType = resource.resourceType.toLowerCase();
  const status = responseType && responseType.toLowerCase();

  const getText = () => {
    if (responseType && displayName) {
      return translate(`search.detail.profile2.${status}.${resourceType}`, {
        displayName
      });
    }

    if (displayName) {
      return translate(`${path}.book`, { displayName });
    }

    return translate(`${path}.managedNull`);
  };

  const text = getText();
  return (
    <div className="Profile2">
      <Icon icon="046-information-circle" className="Profile2__Icon" />
      <span>{text}</span>
    </div>
  );
}

Profile2.propTypes = {
  resource: CustomPropTypes.resource,
  translate: PropTypes.func.isRequired,
  path: PropTypes.string,
  responseType: PropTypes.string
};

Profile2.defaultProps = {
  path: 'search.detail.profile2.room'
};

export default withTranslation(Profile2);
