import { createSelector } from 'reselect';
import groupBy from 'lodash/fp/groupBy';
import times from 'lodash/fp/times';
import moment from 'moment';
import { buildingsSelectors } from '../buildings';

const isLoading = state => state.myBookingsState.myBookings.isLoading;
const hasSearched = state => state.myBookingsState.myBookings.searched;
const getLastDate = state => state.myBookingsState.myBookings.lastDate;
const getMyBookings = state => state.myBookingsState.myBookings.myBookings;
const getBuildings = state => buildingsSelectors.getBuildings(state);

const maxDate = moment()
  .endOf('month')
  .add(18, 'months');

const nextMonth = (date, index = 1) => moment(date).add(index, 'month');
const getNextMonthByIndex = date => index => nextMonth(date, index);

const getMonthName = date => date
  .clone()
  .locale(moment.locale())
  .format('MMMM');

const getMonthIndex = date => date.clone().month();

const groupBookingsByDay = groupBy(booking => moment(booking.start)
  .clone()
  .startOf('date')
  .format());

const getMyBookingsWithBuilding = createSelector(
  [getMyBookings, getBuildings],
  (myBookings, buildings) => {
    const mapResource = resource => ({
      ...resource,
      building: buildings.find(building => building.id === resource.buildingId)
    });

    return myBookings.map(booking => ({
      ...booking,
      resource: mapResource(booking.resource)
    }));
  }
);

const getMyBookingsByMonth = createSelector(
  [getMyBookingsWithBuilding, getLastDate],
  (bookings, lastDate) => {
    const firstDate = moment().endOf('month');
    const differenceInMonths = Math.round(lastDate.diff(firstDate, 'days') / 30);

    const getMonthByIndex = getNextMonthByIndex(firstDate);

    const months = times(index => {
      const date = getMonthByIndex(index);
      return {
        key: date.format('YYYY-M'),
        month: getMonthName(date),
        year: date.format('YYYY')
      };
    }, differenceInMonths + 1);

    const groupedBookings = groupBy(
      booking => moment(booking.start)
        .startOf('date')
        .format('YYYY-M'),
      bookings
    );

    return months.reduce((prev, current) => {
      const result = { ...prev };
      const { key } = current;
      result[key] = {
        month: current.month,
        year: current.year,
        bookings: groupBookingsByDay(groupedBookings[key]) || []
      };
      return result;
    }, {});
  }
);

const hasMore = createSelector(
  getLastDate,
  lastDate => lastDate < maxDate
);
const hasBookings = createSelector(
  getMyBookings,
  bookings => bookings.length > 0
);
const getCurrentMonth = createSelector(
  getLastDate,
  lastDate => getMonthName(lastDate)
);
const getCurrentMonthIndex = createSelector(
  getLastDate,
  lastDate => getMonthIndex(lastDate)
);
const getNextMonth = createSelector(
  getLastDate,
  lastDate => getMonthName(nextMonth(lastDate))
);

export default {
  isLoading,
  hasMore,
  hasBookings,
  hasSearched,
  getLastDate,
  getCurrentMonth,
  getCurrentMonthIndex,
  getNextMonth,
  getMyBookingsByMonth
};
