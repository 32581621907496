import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { withTranslation } from '../../../../common';
import SearchFormInput from '../SearchFormInput';

function SearchFormInputRedirect(props) {
  const { icon, value, error, placeholder, url, disabled, history } = props;

  const redirect = () => {
    if (disabled) {
      return;
    }
    history.push(url);
  };

  return (
    <SearchFormInput
      icon={icon}
      value={value}
      error={error}
      disabled={disabled}
      placeholder={placeholder}
      onClick={redirect}
      readOnly
    />
  );
}

export default withTranslation(withRouter(SearchFormInputRedirect));

SearchFormInputRedirect.propTypes = {
  icon: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  url: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

SearchFormInputRedirect.defaultProps = {
  disabled: false
};
