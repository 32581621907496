import types from './types';

export const addRooms = rooms => ({
  type: types.ADD_ROOMS,
  rooms
});

export const addBookings = bookings => ({
  type: types.ADD_BOOKINGS,
  bookings
});

export const addRoomBookings = items => ({
  type: types.ADD_ROOM_BOOKINGS,
  items
});

export const updateRooms = rooms => ({
  type: types.UPDATE_ROOMS,
  rooms
});

export const updateBookings = bookings => ({
  type: types.UPDATE_BOOKINGS,
  bookings
});

export const updateRoomBookings = items => ({
  type: types.UPDATE_ROOM_BOOKINGS,
  items
});

export const updateBooking = booking => ({
  type: types.UPDATE_BOOKING,
  booking
});

export const removeBooking = (calendarId, resourceId) => ({
  type: types.REMOVE_BOOKING,
  calendarId,
  resourceId
});

export const removeRecurring = globalId => ({
  type: types.REMOVE_RECURRING,
  globalId
});

export const updateSkipCount = skipCount => ({
  type: types.UPDATE_SKIPCOUNT,
  skipCount
});

export const updateTotalCount = totalCount => ({
  type: types.UPDATE_TOTALCOUNT,
  totalCount
});

export const updateIsLoading = isLoading => ({
  type: types.UPDATE_LOADING,
  isLoading
});

export const updateIsLoadingMore = isLoadingMore => ({
  type: types.UPDATE_LOADING_MORE,
  isLoadingMore
});

export const cacheBookings = () => ({
  type: types.CACHE_BOOKINGS
});

export const resetBookingsFromCache = () => ({
  type: types.RESET_BOOKINGS_FROM_CACHE
});

export const exportLoading = loading => ({
  type: types.EXPORT_LOADING,
  loading
});
