/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { styles, lineStyles } from './styles';

function WebexboardIcon({ disabled, ...otherProps }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.000000 30.000000" {...otherProps} style={styles}>
      <g transform="translate(0.000000,27.000000) scale(0.023500,-0.023500)">
        <path d="M529 957 c-19 -12 -38 -33 -44 -45 -9 -21 -14 -22 -175 -22 -182 0 -201 -5 -237 -59 -23 -34 -23 -36 -23 -395 0 -268 3 -365 12 -374 9 -9 143 -12 540 -12 l528 0 11 27 c6 18 9 153 7 378 -4 458 10 435 -260 435 l-163 0 -17 30 c-33 58 -121 76 -179 37z m-20 -198 c40 -41 42 -41 98 -37 50 4 61 9 89 41 l31 37 150 0 c102 0 153 -4 161 -12 9 -9 12 -96 12 -330 l0 -318 -450 0 -450 0 0 318 c0 234 3 321 12 330 8 8 58 12 159 12 l147 0 41 -41z" />
        <path d="M564 640 c-57 -23 -71 -100 -28 -144 57 -56 149 -17 149 63 0 65 -62 106 -121 81z" />
        <path d="M524 421 c-62 -28 -88 -72 -92 -154 -3 -51 -1 -72 9 -78 22 -14 303 -11 317 3 7 7 12 35 12 63 0 138 -124 221 -246 166z" />
      </g>
      {disabled && <line style={lineStyles} x1="3.45" y1="2.5" x2="24.75" y2="29.01" />}
    </svg>
  );
}

WebexboardIcon.propTypes = {
    disabled: PropTypes.bool
};

export default WebexboardIcon;
