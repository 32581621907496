import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, RoomCard } from '../../../../../common';
import Room from '../../Room';
import RecurrenceAvailability from '../../RecurrenceAvailability';
import ProfileInformation from '../../ProfileInformation';

function RoomCardRecurrenceMobile(props) {
  const { resource, isProfile3, isProfile2 } = props;

  const renderProfileInformation = () => {
    if (isProfile3 || isProfile2) {
      return <ProfileInformation resource={resource} />;
    }
    return null;
  };

  return (
    <div>
      <RoomCard resourceSubType={resource.resourceSubType}>
        <Room resource={resource} showRelativeTime={false} />
        <RecurrenceAvailability resource={resource} />
        {renderProfileInformation()}
      </RoomCard>
    </div>
  );
}

RoomCardRecurrenceMobile.propTypes = {
  resource: CustomPropTypes.resource,
  isProfile3: PropTypes.bool.isRequired,
  isProfile2: PropTypes.bool.isRequired
};

export default RoomCardRecurrenceMobile;
