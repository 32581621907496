import React from 'react';
import PropTypes from 'prop-types';

import { Translate } from '../common';

import styles from './WelcomeTitle.module.css';

function WelcomeTitle({ mobile }) {
  const classes = mobile ? styles.modal : styles.dialog;
  return (
    <div className={classes}>
      <Translate path="welcome.title" />
    </div>
  );
}

export default WelcomeTitle;

WelcomeTitle.propTypes = {
  mobile: PropTypes.bool
};
