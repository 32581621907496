import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation, Button } from '../../common';

function BookFormSubmit({
  isSubmitting,
  onClick,
  isReadyToSubmit,
  mobile,
  translate,
  update,
  profile2
}) {
  const text = () => {
    if (profile2) {
      return translate('search.detail.profile2.send');
    }
    if (update) {
      return translate('common.update');
    }
    return translate('common.book');
  };

  return (
    <div className="BookModalSubmit">
      <Button
        label={text()}
        onClick={onClick}
        disabled={!isReadyToSubmit}
        primary
        loading={isSubmitting}
        fixed={mobile}
        className={!mobile ? 'BookDetailDesktop__Save' : null}
      />
    </div>
  );
}

BookFormSubmit.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isReadyToSubmit: PropTypes.bool.isRequired,
  mobile: PropTypes.bool,
  translate: PropTypes.func,
  update: PropTypes.bool,
  profile2: PropTypes.bool.isRequired
};

BookFormSubmit.defaultProps = {
  mobile: false
};

export default withTranslation(BookFormSubmit);
