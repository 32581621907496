import types from './types';

const initialState = {
  locations: [],
  floors: {},
  sectors: {},
  wasChanged: false,
  cache: null
};

const locationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_LOCATIONS: {
      const { locations } = action;
      return { ...state, locations };
    }

    case types.CLEAR_LOCATIONS: {
      return { ...state, locations: [] };
    }

    case types.SET_FLOORS: {
      return {
        ...state,
        floors: { ...state.floors, [action.building.buildingId]: action.building }
      };
    }

    case types.SET_SECTORS: {
      return {
        ...state,
        sectors: { ...state.sectors, [action.building.buildingId]: action.building }
      };
    }

    case types.UPDATE_CHANGED: {
      return {
        ...state,
        wasChanged: true
      };
    }

    case types.RESET_CHANGED: {
      return {
        ...state,
        wasChanged: false
      };
    }

    case types.BUILDING_REMOVED: {
      const removedBuildingLocations = state.locations.filter(loc => loc.key !== action.buildingKey);
      return {
        ...state,
        locations: removedBuildingLocations
      };
    }

    case types.CACHE_LOCATIONS: {
      return {
        ...state,
        cache: { ...state }
      };
    }

    case types.RESET_TO_CACHE: {
      return {
        ...state.cache
      };
    }

    default:
      return state;
  }
};

export default locationsReducer;
