import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import RecurrenceInfo from '../BookForm/BookFormRecurrence/RecurrenceInfo';
import { CustomPropTypes, withTranslation } from '../../common';

function RecurrenceDetailContent(props) {
  const { language, selectedMaster } = props;

  const type = selectedMaster.recurrencePattern.toLowerCase();
  let pattern = {};
  if (type === 'monthly') {
    pattern = {
      index: selectedMaster.pattern.interval,
      weekOfMonth: selectedMaster.pattern.weekOfMonthIndex.toLowerCase(),
      dayOfMonth: selectedMaster.pattern.dayOfWeek.toLowerCase()
    };
  }

  if (type === 'weekly') {
    pattern = {
      index: selectedMaster.pattern.interval,
      daysOfWeek: selectedMaster.pattern.daysOfWeek.map(day => day.toLowerCase())
    };
  }

  const masterEnd = moment(selectedMaster.end);
  const lastOccurrence = moment(selectedMaster.recurrenceEnd.endDate)
    .hours(masterEnd.hours())
    .minutes(masterEnd.minutes())
    .toISOString();

  const recurrenceInfoProps = {
    type,
    pattern,
    resourceType: selectedMaster.resource.resourceType,
    startDate: selectedMaster.start,
    endDate: lastOccurrence,
    showAsAvailable: false,
    language
  };

  return <RecurrenceInfo {...recurrenceInfoProps} />;
}

RecurrenceDetailContent.propTypes = {
  language: PropTypes.string.isRequired,
  selectedMaster: CustomPropTypes.master.isRequired,
  exceptions: PropTypes.arrayOf(CustomPropTypes.exception),
  openExceptionModal: PropTypes.func.isRequired,
  openExceptionDeleteModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurrenceDetailContent);
