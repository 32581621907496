import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import withUserManager from './withUserManager';

class LoginRenewal extends React.Component {
  UNSAFE_componentWillMount() {
    const { userManager } = this.props;
    userManager.signinSilentCallback();
  }

  render() {
    const nope = null;
    return nope;
  }
}

LoginRenewal.propTypes = {
  userManager: PropTypes.shape({
    signinSilentCallback: PropTypes.any
  }).isRequired
};

export default withUserManager(withRouter(LoginRenewal));
