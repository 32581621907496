import React from 'react';
import PropTypes from 'prop-types';

import Recurrence from '../../Recurrence';
import Header from '../../Header';

import { withTranslation, Button } from '../../common';

import './_style.css';

function RecurrencePatternModalDesktop({ onSave, translate }) {
  const title = translate('search.recurrence.title');
  return (
    <div>
      <Header title={title} onClose={onSave} fixed={false} icon="014-chevron-left" />
      <div className="RecurrencePatternModalDesktop">
        <Recurrence />
        <Button
          className="RecurrencePatternModal__Button"
          onClick={onSave}
          label={translate('common.save')}
        />
      </div>
    </div>
  );
}

RecurrencePatternModalDesktop.propTypes = {
  onSave: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurrencePatternModalDesktop);
