import curry from 'lodash/fp/curry';
import isEmpty from 'lodash/fp/isEmpty';

import { isProfile2, isProfile3 } from '../../../util';

const isRoomType = type => roomType => roomType === type;
const isSurfaceHub = isRoomType('SurfaceHub');
const isTelepresence = isRoomType('Telepresence');

const calculateRowHeight = (isRecurrence, defaultHeight, mobile, resource) => {
  const { roomType } = resource;
  const profile3 = isProfile3(resource);
  const profile2 = isProfile2(resource);

  if (isEmpty(resource.roomEquipment) && mobile && isRecurrence && profile3) {
    return defaultHeight + 140;
  }

  if (isEmpty(resource.roomEquipment) && mobile && isRecurrence && profile2) {
    return defaultHeight + 90;
  }

  if (isEmpty(resource.roomEquipment) && mobile && isRecurrence) {
    return defaultHeight + 20;
  }

  if (isRecurrence && (profile2 || profile3)) {
    return defaultHeight + (mobile ? 120 : 50);
  }

  if (isRecurrence) {
    return defaultHeight + (mobile ? 60 : 30);
  }

  if (isEmpty(resource.roomEquipment) && (isSurfaceHub(roomType) || isTelepresence(roomType))) {
    return defaultHeight + (mobile ? 40 : 20);
  }

  if (isEmpty(resource.roomEquipment) && (profile2 || profile3)) {
    return defaultHeight + (mobile ? 50 : 20);
  }

  if (profile2 || profile3) {
    return defaultHeight + (mobile ? 80 : 20);
  }

  return defaultHeight;
};

export default curry(calculateRowHeight);
