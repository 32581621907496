// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ResourcesSubTypes_ResourcesSubType__Loader__-vOKP {\n    height: calc(100vh - 60px);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }", "",{"version":3,"sources":["webpack://./src/views/Admin/Settings/ResourcesSubTypes/ResourcesSubTypes.module.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB","sourcesContent":[".ResourcesSubType__Loader {\n    height: calc(100vh - 60px);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ResourcesSubType__Loader": "ResourcesSubTypes_ResourcesSubType__Loader__-vOKP"
};
export default ___CSS_LOADER_EXPORT___;
