/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import config from '../../../config';
import { withTranslation, Icon } from '..';

import './_style.css';

class DataQualityInformation extends Component {
  constructor(props) {
    super(props);

    const { collapsible } = props;

    this.state = {
      collapsed: collapsible,
      animated: collapsible
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    const { collapsed } = this.state;

    if (!collapsed) {
      this.setState({ collapsed: true });
    } else {
      this.setState({ collapsed: false });
    }

    this.setState({ animated: !collapsed });
  }

  render() {
    const { translate, collapsible, small, width, path, className } = this.props;
    const { collapsed, animated } = this.state;

    if (!config[path]) {
      return null;
    }

    const text = collapsed
      ? `${translate(`common.${path}`).substring(0, 55)}...`
      : translate(`common.${path}`);

    const interactionProps = collapsible
      ? {
        role: 'button',
        tabIndex: 0,
        onClick: this.toggleCollapse,
        onKeyPress: this.toggleCollapse
      }
      : {};

    const classes = cx('DataQualityInformation', {
      'DataQualityInformation--collapsed': animated,
      'DataQualityInformation--shown': !animated,
      '.DataQualityInformation--centerVertical': !collapsible
    });

    const textClasses = 'DataQualityInformation__Text';
    const iconClasses = cx('DataQualityInformation__Icon', {
      'DataQualityInformation__Icon--small': small
    });

    return (
      <div className={className}>
        <div className={classes} {...interactionProps}>
          <Icon icon="239-robot-confused" className={iconClasses} />
          <div className={textClasses} style={{ maxWidth: width }}>
            <span
              dangerouslySetInnerHTML={{
                __html: text
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

DataQualityInformation.propTypes = {
  translate: PropTypes.func.isRequired,
  collapsible: PropTypes.bool,
  small: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  path: PropTypes.string,
  className: PropTypes.string
};

DataQualityInformation.defaultProps = {
  collapsible: false,
  small: false
};

export default withTranslation(DataQualityInformation);
