import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, TrayIcons, RoomType, withTranslation } from '../../../common';
import BookingTime from '../BookingTime';

import './_style.css';

function Booking({ booking, translate }) {
  const { resource } = booking;
  const { name, building, capacity, resourceSubType, roomEquipment } = resource;

  const persons = `${capacity || '?'} ${translate('common.people')}`;

  return (
    <div className="Booking" tabIndex="0" role="button">
      <div className="Booking__Header">
        <div className="Booking__Name">{name}</div>
        {booking.resource.resourceType === 'Room' && (
          <div className="Booking__People">{persons}</div>
        )}
      </div>
      <div className="Booking__Body">
        <div className="Booking__Building">{building.key}</div>
        <div className="Booking__Time">
          <BookingTime booking={booking} />
        </div>
      </div>
      <div className="Booking__Information">
        {resourceSubType && (
          <div className="Booking__Type">
            <RoomType resourceSubType={resourceSubType} />
          </div>
        )}
        <div className="Booking__Tray">
          <TrayIcons equipment={roomEquipment} />
        </div>
      </div>
    </div>
  );
}

Booking.propTypes = {
  booking: CustomPropTypes.booking,
  translate: PropTypes.func.isRequired
};

export default withTranslation(Booking);
