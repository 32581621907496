import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, RoomCard, Favorite } from '../../../../common';
import FormTime from '../FormTime';
import FormSubmit from '../FormSubmit';
import ProfileInformation from '../ProfileInformation';
import RoomNameAndType from '../RoomNameAndType';
import RoomCapacityAndTime from '../RoomCapacityAndTime';
import RoomEquipment from '../RoomEquipment';

const width = amount => ({ width: amount });

function RoomCardTablet(props) {
  const { resource, selected, showTimeInput, onSelect, isProfile3, isProfile2 } = props;
  const { alternativeType, showRelativeTime } = props;

  const showAlternativeEquipment = alternativeType === 'roomequipment';

  const renderRoomInformation = () => {
    const { id } = resource;
    if (!selected || isProfile3) {
      return [
        <div style={width('35px')} key="Favorite">
          <Favorite resourceId={id} tooltip={false} medium />
        </div>,
        <div style={width('calc(43% - 35px')} key="RoomNameAndType">
          <RoomNameAndType resource={resource} alternativeType={alternativeType} />
        </div>,
        <div style={width('37%')} key="RoomCapacityAndTime">
          <RoomCapacityAndTime
            resource={resource}
            alternativeType={alternativeType}
            showRelativeTime={showRelativeTime}
          />
        </div>,
        <div style={width('20%')} key="TrayIcons">
          <div className="SearchResultCard__Tray">
            <RoomEquipment
              equipment={resource.roomEquipment}
              showAlternativeEquipment={showAlternativeEquipment}
            />
          </div>
        </div>
      ];
    }
    return null;
  };

  const renderSelectedNoTimeInput = () => {
    if (selected && !showTimeInput && !isProfile3) {
      return [
        <div style={width('38%')} key="RoomNameAndType">
          <RoomNameAndType resource={resource} />
        </div>,
        <div style={width('58%')} key="FormSubmit">
          <FormSubmit small openModal={onSelect} resource={resource} />
        </div>
      ];
    }
    return null;
  };

  const renderSelectedTimeInput = () => {
    if (selected && showTimeInput && !isProfile3) {
      return [
        <div style={width('38%')} key="FormTime">
          <FormTime />
        </div>,
        <div style={width('58%')} key="FormSubmit">
          <FormSubmit small openModal={onSelect} resource={resource} />
        </div>
      ];
    }
    return null;
  };

  const renderProfileInformation = () => {
    if (isProfile3 || isProfile2) {
      return <ProfileInformation resource={resource} />;
    }
    return null;
  };

  const onClick = () => {
    if (isProfile3) {
      onSelect();
    }
  };

  return (
    <div>
      <RoomCard resourceSubType={resource.resourceSubType} onClick={onClick}>
        {renderRoomInformation()}
        {renderSelectedNoTimeInput()}
        {renderSelectedTimeInput()}
        {renderProfileInformation()}
      </RoomCard>
    </div>
  );
}

RoomCardTablet.propTypes = {
  resource: CustomPropTypes.resource,
  selected: PropTypes.bool.isRequired,
  showTimeInput: PropTypes.bool.isRequired,
  showRelativeTime: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  isProfile3: PropTypes.bool.isRequired,
  isProfile2: PropTypes.bool.isRequired,
  alternativeType: PropTypes.string
};

export default RoomCardTablet;
