import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ShowAt } from '../common';
import {
  BookDetailModal,
  BookUpdateModal,
  RecurrenceDetailModal,
  RecurrenceDeleteDialog,
  RecurrenceUpdateModal,
  RecurrenceExceptionDeleteModal,
  RecurrenceExceptionModal
} from '../BookModal';
import MyBookingsPageMobile from './MyBookingsPageMobile';
import MyBookingsPageDesktop from './MyBookingsPageDesktop';
import { myBookingsSelectors, myBookingsOperations } from '../../state/myBookings';
import { modalOperations } from '../../state/modal';
import { bookOperations } from '../../state/book';
import { recurrenceMasterOperations } from '../../state/recurrenceMaster';

import modalKeys from '../BookModal/modalKeys';

class MyBookingsPage extends Component {
  componentDidMount() {
    const { resetBookings, loadBookings } = this.props;
    resetBookings();
    loadBookings();
  }

  render() {
    const {
      match,
      updateBooking,
      refreshBookings,
      deleteBooking,
      deleteMaster,
      updateMaster,
      openBookingModal,
      openRecurrenceDeleteDialog
    } = this.props;
    const mybookings = match.url;

    const goToDetail = booking => {
      openBookingModal(booking, modalKeys.BOOK_DETAIL_MODAL);
    };

    return (
      <div>
        <BookDetailModal deleteBooking={deleteBooking} deleteMaster={deleteMaster} />
        <RecurrenceDetailModal confirmDeletion={openRecurrenceDeleteDialog} />
        <RecurrenceExceptionModal />
        <RecurrenceExceptionDeleteModal deleteCallback={refreshBookings} />
        <RecurrenceUpdateModal updateMaster={updateMaster} />
        <BookUpdateModal updateBooking={updateBooking} />
        <RecurrenceDeleteDialog deleteMaster={deleteMaster} />
        <ShowAt breakpoint="mobileAndBelow">
          <Route
            path={mybookings}
            exact
            render={() => <MyBookingsPageMobile {...this.props} openBookingModal={goToDetail} />}
          />
        </ShowAt>

        <ShowAt breakpoint="desktopAndAbove">
          <MyBookingsPageDesktop {...this.props} openBookingModal={goToDetail} />
        </ShowAt>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: myBookingsSelectors.isLoading(state),
  hasMore: myBookingsSelectors.hasMore(state),
  hasBookings: myBookingsSelectors.hasBookings(state),
  myBookingsByMonth: myBookingsSelectors.getMyBookingsByMonth(state),
  currentMonth: myBookingsSelectors.getCurrentMonth(state),
  currentMonthIndex: myBookingsSelectors.getCurrentMonthIndex(state),
  nextMonth: myBookingsSelectors.getNextMonth(state)
});

const mapDispatchToProps = dispatch => ({
  resetBookings: () => dispatch(myBookingsOperations.resetBookings()),
  loadBookings: () => dispatch(myBookingsOperations.loadBookings()),
  refreshBookings: () => dispatch(myBookingsOperations.refreshBookings()),
  deleteBooking: id => dispatch(myBookingsOperations.deleteBooking(id)),
  deleteMaster: (globalId, calendarId) => dispatch(myBookingsOperations.deleteMaster(globalId, calendarId)),
  openBookingModal: (booking, modalKey) => dispatch(bookOperations.loadBooking(booking, modalKey)),
  openMasterModal: calendarId => dispatch(recurrenceMasterOperations.loadAndOpenRecurrenceMaster(calendarId)),
  updateBooking: () => dispatch(myBookingsOperations.updateBooking()),
  openRecurrenceDeleteDialog: () => dispatch(modalOperations.pushModal(modalKeys.RECURRENCE_DELETE_DIALOG)),
  updateMaster: () => dispatch(myBookingsOperations.updateMaster())
});

MyBookingsPage.propTypes = {
  resetBookings: PropTypes.func.isRequired,
  loadBookings: PropTypes.func.isRequired,
  refreshBookings: PropTypes.func.isRequired,
  updateBooking: PropTypes.func.isRequired,
  deleteBooking: PropTypes.func.isRequired,
  deleteMaster: PropTypes.func.isRequired,
  openRecurrenceDeleteDialog: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  openBookingModal: PropTypes.func,
  openMasterModal: PropTypes.func,
  updateMaster: PropTypes.func.isRequired
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyBookingsPage)
);
