import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import { bookOperations } from '../../../state/book';
import { alternativesSelectors, alternativesOperations } from '../../../state/alternatives';
import modalKeys from '../../BookModal/modalKeys';
import SearchResult from '../SearchResults/SearchResult';
import Loader from './AlternativeLoader';
import LoadMore from './AlternativeLoadMore';
import { ShowAt } from '../../common';
import calculateRowHeight from '../SearchResults/calculateRowHeight';

const calculateHeight = calculateRowHeight(false);

class AlternativeSearchResultsSection extends Component {
  constructor(props) {
    super(props);

    this.onShowMore = this.onShowMore.bind(this);
  }

  onShowMore() {
    const { isLoading, hasMore, loadMore } = this.props;
    if (!isLoading && hasMore) {
      loadMore();
    }
  }

  renderSearchResult = (defaultHeight, mobile, resource) => {
    const { openCreateModal: openModal, type } = this.props;
    const height = calculateHeight(defaultHeight, mobile, resource);
    const props = {
      key: resource.id,
      style: {
        height: `${height}px`,
        position: 'relative'
      },
      resource,
      openModal,
      alternativeType: type
    };
    return <SearchResult {...props} />;
  };

  render() {
    const { availabilities, isLoading, hasMore } = this.props;

    const classes = cx({ AlternativeSearchResultsSection: hasMore });

    return (
      <div className={classes}>
        <ShowAt breakpoint="600AndBelow">
          {availabilities.map(availability => this.renderSearchResult(144, true, availability))}
        </ShowAt>

        <ShowAt breakpoint="600AndAbove 900AndBelow">
          {availabilities.map(availability => this.renderSearchResult(144, false, availability))}
        </ShowAt>

        <ShowAt breakpoint="900AndAbove">
          {availabilities.map(availability => this.renderSearchResult(120, false, availability))}
        </ShowAt>

        <Loader visible={isLoading} />
        <LoadMore visible={hasMore && !isLoading} onClick={this.onShowMore} />
      </div>
    );
  }
}

const mapStateToProps = (state, { type }) => ({
  hasMore: alternativesSelectors.getHasMore(type)(state),
  isLoading: alternativesSelectors.getIsLoading(type)(state),
  availabilities: alternativesSelectors.getAvailabilities(type)(state)
});

const mapDispatchToProps = (dispatch, { type }) => ({
  loadMore: () => dispatch(alternativesOperations.loadMoreAlternatives(type)),
  openCreateModal: resource => dispatch(bookOperations.loadEmptyBooking(resource, modalKeys.BOOK_CREATE_MODAL))
});

AlternativeSearchResultsSection.propTypes = {
  type: PropTypes.oneOf(['floor', 'sector', 'time', 'roomequipment', 'roomtype', 'capacity']),
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  availabilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loadMore: PropTypes.func.isRequired,
  openCreateModal: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlternativeSearchResultsSection);
