import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, RoomCard, TrayIcons, Favorite } from '../../../../../common';
import RoomNameAndType from '../../RoomNameAndType';
import RoomCapacity from '../../RoomCapacity';
import ResourceAvailability from '../../ResourceAvailability';
import RecurrenceAvailability from '../../RecurrenceAvailability';
import ProfileInformation from '../../ProfileInformation';

const width = amount => ({ width: amount });

function RoomCardRecurrenceDesktop(props) {
  const { resource, isProfile3, isProfile2 } = props;
  const { id, resourceSubType } = resource;

  const renderProfileInformation = () => {
    if (isProfile3 || isProfile2) {
      return <ProfileInformation resource={resource} />;
    }
    return null;
  };

  return (
    <div>
      <RoomCard resourceSubType={resourceSubType}>
        <div style={width('34px')}>
          <Favorite resourceId={id} medium />
        </div>
        <div style={width('calc(35% - 34px)')}>
          <RoomNameAndType resource={resource} />
        </div>
        <div style={width('20%')}>
          <RoomCapacity resource={resource} />
        </div>
        <div style={width('25%')}>
          <ResourceAvailability resource={resource} showRelativeTime={false} />
        </div>
        <div style={width('20%')}>
          <TrayIcons equipment={resource.roomEquipment} className="SearchResultCard__Tray" />
        </div>
        <RecurrenceAvailability resource={resource} />
        {renderProfileInformation()}
      </RoomCard>
    </div>
  );
}

RoomCardRecurrenceDesktop.propTypes = {
  resource: CustomPropTypes.resource,
  isProfile3: PropTypes.bool.isRequired,
  isProfile2: PropTypes.bool.isRequired
};

export default RoomCardRecurrenceDesktop;
