import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, Spacing } from '../../../common';
import { authSelectors } from '../../../../state/auth';
import {
  floorsSelectors,
  floorsOperations
} from '../../../../state/floors';
import { modalOperations } from '../../../../state/modal';
import modalKeys from './modalKeys';
import FloorsTable from './FloorsTable';
import FloorsLoader from './FloorsLoader';
import FloorsDeleteDialog from './FloorsDeleteDialog';
import NewFloorButton from './NewFloorButton';
import FloorsCreateModal from './FloorsCreateModal';
import Header from '../../../Header';
import FloorsMapsProvider from './Maps/FloorsMaps';
import FloorUpdateModal from './FloorUpdateModal';

function Floors(props) {
  const {
    history,
    location,
    isLoaded,
    floors,
    selectedFloor,
    translate } = props;

  useEffect(() => {
    const { loadFloors, isAdmin, setSelectedFloor } = props;

    if (!isAdmin) {
      history.replace('/unauthorized');
    }

    if (location.buildingKey) {
      loadFloors(location.buildingKey);
    }

    if (floors.length > 0 && !selectedFloor) {
      setSelectedFloor(floors[0]);
    }
  }, [isLoaded, JSON.stringify(floors), JSON.stringify(selectedFloor), location.buildingKey]);

  const renderLoader = () => <FloorsLoader visible={!isLoaded} />;

  const renderFloors = () => {
    const {
      openFloorDeleteDialog,
      openFloorCreateModal
    } = props;

    const showFloors = isLoaded;
    if (!showFloors) {
      return null;
    }

    return (
      <div className="Floors__Container">
        <Spacing horizontal="1" vertical="1">
          <FloorsTable
            floors={floors}
            isNewBuilding={false}
            actionOnClick={openFloorDeleteDialog} />
          <div className="floors-button--right">
            <NewFloorButton isNewBuilding={false} actionOnClick={openFloorCreateModal} />
          </div>
          <FloorsDeleteDialog />
          <FloorsCreateModal floors={floors} buildingKey={location.buildingKey} />
          <FloorUpdateModal floors={floors} isNewBuilding={false} />
        </Spacing>
      </div>
    );
  };

  const renderFloorsMaps = () => {
    const showMaps = !!selectedFloor && floors.length > 0;
    if (!showMaps) {
      return null;
    }

    const innerStyle = {
      width: '(70vw)',
      height: 'calc(100vh - 260px)',
      maxHeight: '600px',
      border: '1px solid rgb(223, 223, 223)',
      margin: '20px'
    };

    return (
      <div className="Canvas__Container">
        <FloorsMapsProvider
          style={innerStyle}
          selectedFloor={selectedFloor}
          selectedBuildingKey={location.buildingKey} />
      </div>
    );
  };

  return (
    <>
      <Header
        onClose={() => { history.push('/admin/settings/buildings'); }}
        title={translate('admin.settings.floors.title') + location.buildingKey ?? ''}
        iconRight
        icon="022-close" />
      <div className="Floors_Canvas_Container">
        {renderFloors()}
        {renderFloorsMaps()}
      </div>
      {renderLoader()}
    </>
  );
}

const mapStateToProps = state => ({
  isLoaded: floorsSelectors.isLoaded(state),
  floors: floorsSelectors.getFloors(state),
  isAdmin: authSelectors.isAdministrator(state),
  selectedFloor: floorsSelectors.getSelectedFloor(state)
});

const mapDispatchToProps = dispatch => ({
  loadFloors: buildingKey => dispatch(floorsOperations.updateFloors(buildingKey)),
  openFloorCreateModal: () => dispatch(modalOperations.pushModal(modalKeys.FLOOR_CREATE_DIALOG)),
  openFloorDeleteDialog: () => dispatch(modalOperations.pushModal(modalKeys.FLOOR_DELETE_DIALOG)),
  setSelectedFloor: floor => dispatch(floorsOperations.setSelectedFloor(floor))
});

Floors.propTypes = {
  isAdmin: PropTypes.bool,
  isLoaded: PropTypes.bool,
  buildingKey: PropTypes.string,
  floors: PropTypes.arrayOf(PropTypes.any),
  loadFloors: PropTypes.func.isRequired,
  openFloorCreateModal: PropTypes.func.isRequired,
  openFloorDeleteDialog: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    buildingKey: PropTypes.string
  }).isRequired,
  translate: PropTypes.func.isRequired,
  selectedFloor: PropTypes.any,
  setSelectedFloor: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(withRouter(Floors)));
