import types from '../types';

const initialState = {
  resources: [],
  isLoading: false,
  totalCount: 0,
  skipCount: 0,
  hasMore: false
};

const resourcesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_RESOURCES: {
      return { ...state, resources: [...state.resources, ...action.resources] };
    }

    case types.UPDATE_RESOURCES: {
      return { ...state, resources: action.resources };
    }

    case types.UPDATE_RESOURCES_LOADING: {
      return { ...state, isLoading: action.loading };
    }

    case types.UPDATE_SKIPCOUNT: {
      return { ...state, skipCount: action.skipCount };
    }

    case types.UPDATE_TOTALCOUNT: {
      return { ...state, totalCount: action.totalCount };
    }

    case types.UPDATE_HASMORE: {
      return { ...state, hasMore: action.hasMore };
    }

    default:
      return state;
  }
};

export default resourcesReducer;
