import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { changeOwnerSelectors, changeOwnerOperations } from '../../../../state/changeOwner';
import { Button, withTranslation } from '../../../common';

import OwnerForm from './OwnerForm';

import './_style.css';

class OwnerPage extends Component {
  renderSubmitButton = () => {
    const { submit, isSubmitting, hasChanges, history, translate } = this.props;
    const send = translate('common.send');
    const onClick = event => {
      event.preventDefault();
      event.stopPropagation();
      submit().then(result => {
        if (result) {
          history.replace('/changerequests/my');
        }
      });
    };

    const disabled = isSubmitting || !hasChanges;
    return (
      <div className="OwnerForm__Submit">
        <Button label={send} disabled={disabled} onClick={onClick} />
      </div>
    );
  };

  render() {
    return (
      <div className="OwnerForm">
        <OwnerForm {...this.props} />
        {this.renderSubmitButton()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: changeOwnerSelectors.getProfile(state),
  profiles: changeOwnerSelectors.getProfiles(state),
  reason: changeOwnerSelectors.getReason(state),
  isValid: changeOwnerSelectors.isValid(state),
  isSubmitted: changeOwnerSelectors.isSubmitted(state),
  isSubmitting: changeOwnerSelectors.isSubmitting(state),
  hasChanges: changeOwnerSelectors.hasChanges(state),
  isContactLoading: changeOwnerSelectors.isContactLoading(state),
  contactSuggestions: changeOwnerSelectors.getContactSuggestions(state),
  isOwnerLoading: changeOwnerSelectors.isOwnerLoading(state),
  ownerSuggestions: changeOwnerSelectors.getOwnerSuggestions(state)
});

const mapDispatchToProps = dispatch => ({
  selectProfile: type => dispatch(changeOwnerOperations.selectProfile(type)),
  updateReason: reason => dispatch(changeOwnerOperations.updateReason(reason)),
  updateContact: (profile, contact) => dispatch(changeOwnerOperations.updateContact(profile, contact)),
  updateOwners: (profile, owners) => dispatch(changeOwnerOperations.updateOwners(profile, owners)),
  loadContactSuggestions: term => dispatch(changeOwnerOperations.loadContactSuggestions(term)),
  loadOwnerSuggestions: term => dispatch(changeOwnerOperations.loadOwnerSuggestions(term)),
  submit: () => dispatch(changeOwnerOperations.submit())
});

OwnerPage.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

const OwnerPageComponent = withTranslation(withRouter(OwnerPage));
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnerPageComponent);
