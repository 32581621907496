import React from 'react';
import PropTypes from 'prop-types';

import { Button, withTranslation, CustomPropTypes } from '../../common';
import RecurrenceExceptionDeleteModalHeader from './RecurrenceExceptionModalHeader/RecurrenceExceptionDeleteModalHeader';
import RecurrenceExceptionDeleteSidebar from './RecurrenceExceptionSidebar/RecurrenceExceptionDeleteSidebar';

import './_style.css';

function RecurrenceExceptionDeleteModalMobile(props) {
  const { exception, isInProgress, onClose, onReset, deleteCallback, translate } = props;

  const handleReset = () => onReset(exception, deleteCallback);

  const { start } = exception;
  const buttonLabel = translate('common.delete');
  return (
    <div className="RecurrenceExceptionModalMobile">
      <RecurrenceExceptionDeleteModalHeader isMobile date={start} onClose={onClose} />

      <div className="BookModalMobile">
        <RecurrenceExceptionDeleteSidebar exception={exception} mobile />
      </div>

      <Button
        secondary
        label={buttonLabel}
        className="button--fixed"
        onClick={handleReset}
        loading={isInProgress}
      />
    </div>
  );
}

RecurrenceExceptionDeleteModalMobile.propTypes = {
  exception: CustomPropTypes.exception,
  isInProgress: PropTypes.bool.isRequired,
  deleteCallback: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurrenceExceptionDeleteModalMobile);
