import set from 'lodash/fp/set';

import types from '../types';

const defaultSectionState = {
  availability: [],
  isLoading: false,
  skipCount: 0,
  hasMore: false
};

const initialState = {
  sections: {
    time: { ...defaultSectionState },
    roomequipment: { ...defaultSectionState },
    roomtype: { ...defaultSectionState },
    capacity: { ...defaultSectionState }
  }
};

const updateState = state => (section, name, value) => {
  const path = `${section}.${name}`;
  return set(path, value, state.sections);
};

const alternativesReducer = (state = initialState, action) => {
  const updateSections = updateState(state);

  switch (action.type) {
    case types.ADD_ALTERNATIVE_AVAILABILITY: {
      const { section } = action;
      const availabilities = [...state.sections[section].availability, ...action.availability];
      const sections = updateSections(section, 'availability', availabilities);
      return {
        ...state,
        sections: { ...sections }
      };
    }

    case types.UPDATE_ALTERNATIVE_AVAILABILITY: {
      const sections = updateSections(action.section, 'availability', action.availability);
      return {
        ...state,
        sections: { ...sections }
      };
    }

    case types.UPDATE_ALTERNATIVE_LOADING: {
      const sections = updateSections(action.section, 'isLoading', action.isLoading);
      return {
        ...state,
        sections: { ...sections }
      };
    }

    case types.UPDATE_ALTERNATIVE_SKIPCOUNT: {
      const sections = updateSections(action.section, 'skipCount', action.skipCount);
      return {
        ...state,
        sections: { ...sections }
      };
    }

    case types.HAS_MORE_ALTERNATIVE_RESULTS: {
      const sections = updateSections(action.section, 'hasMore', action.hasMore);
      return {
        ...state,
        sections: { ...sections }
      };
    }

    case types.PATCH_EXCEPTION: {
      return initialState;
    }

    default:
      return state;
  }
};

export default alternativesReducer;
