import types from './types';

const updateBuildings = buildings => ({
  type: types.UPDATE_BUILDINGS,
  buildings
});

const addFloorsToBuilding = (floors, buildingKey) => ({
  type: types.ADD_FLOORS_TO_BUILDING,
  floors,
  buildingKey
});

const updateIsLoaded = age => ({
  type: types.UPDATE_LOADED,
  age
});

const setSelectedBuilding = selectedBuilding => ({
  type: types.SET_SELECTED_BUILDING,
  selectedBuilding
});

export default {
  addFloorsToBuilding,
  updateBuildings,
  updateIsLoaded,
  setSelectedBuilding
};
