import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { RadioGroup, Radio, withTranslation } from '../../../common';
import FormTypes from '../formTypes';

import './_style.css';

const showPermissionsForm = ({ profile }) => profile > 2;

class FormSwitch extends Component {
  renderRadioButtons = () => {
    const { resource, translate } = this.props;

    const getTranslation = path => translate(`changeRequests.formSwitch.formTypes.${path}`);
    const resourceType = resource.resourceType.toLowerCase();

    const createFormType = (formType, label) => ({
      value: formType,
      label: label || getTranslation(formType)
    });

    const formTypes = [
      createFormType(FormTypes.MASTERDATA),
      createFormType(FormTypes.OWNER),
      createFormType(FormTypes.DELETE, getTranslation(`${FormTypes.DELETE}.${resourceType}`))
    ];

    if (resourceType === 'room') {
      formTypes.splice(2, 0, createFormType(FormTypes.PHOTO));
    }

    if (showPermissionsForm(resource)) {
      formTypes.splice(2, 0, createFormType(FormTypes.PERMISSIONS));
    }

    return formTypes.map(type => <Radio key={type.value} label={type.label} value={type.value} />);
  };

  render() {
    const { selectedForm, onChange, translate } = this.props;
    const title = translate('changeRequests.formSwitch.title');
    const titleInformation = translate('changeRequests.formSwitch.titleInformation');

    return (
      <div className="FormSwitch">
        <p className="FormSwitch__Title">
          {title}
          {' '}
          <small>{titleInformation}</small>
        </p>
        <RadioGroup value={selectedForm} onChange={onChange}>
          {this.renderRadioButtons()}
        </RadioGroup>
      </div>
    );
  }
}

FormSwitch.propTypes = {
  resource: PropTypes.shape({
    resourceType: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  selectedForm: PropTypes.string,
  translate: PropTypes.func.isRequired
};

export default withTranslation(FormSwitch);
