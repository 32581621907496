import React from 'react';
import PropTypes from 'prop-types';

function WelcomeSection({ children, className }) {
  return <div className={className}>{children}</div>;
}

export default WelcomeSection;

WelcomeSection.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string
};
