import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Icon } from '../../../common';

import './_style.css';

function RecurringInformation({ translate }) {
  return (
    <div>
      <div className="RecurringInformation">
        <Icon icon="007-backup" />
        {' '}
        {translate('myBookings.recurring')}
      </div>
    </div>
  );
}

RecurringInformation.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurringInformation);
