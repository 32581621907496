import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './_style.css';

function EquipmentCard(props) {
  const { resourceSubType, children, onClick } = props;

  return (
    <div
      role="button"
      tabIndex="0"
      className="EquipmentCard"
      onClick={onClick}
      style={{ borderLeft: `solid 8px ${ resourceSubType.color }` }}>
      {React.Children.map(children, child => {
        if (!child) {
          return null;
        }
        return React.cloneElement(child, {
          className: cx('EquipmentCard__Section', child.props.className)
        });
      })}
    </div>
  );
}

EquipmentCard.propTypes = {
  resourceSubType: PropTypes.shape(),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onClick: PropTypes.func
};

export default EquipmentCard;
