import React from 'react';

import BookDetailSubject from './BookDetailSubject';
import BookDetailAttendees from './BookDetailAttendees';
import BookDetailMessage from './BookDetailMessage';
import { CustomPropTypes, withTranslation } from '../../common';
import BookDetailBookedFor from './BookDetailBookedFor';

function RecurrenceDetailContent(props) {
  const { selectedMaster } = props;

  return (
    <>
      <BookDetailSubject subject={selectedMaster.subject} />
      <BookDetailBookedFor person={selectedMaster.bookedFor} />
      <BookDetailAttendees attendees={selectedMaster.attendees} />
      <BookDetailMessage message={selectedMaster.message} />
    </>
  );
}

RecurrenceDetailContent.propTypes = {
  selectedMaster: CustomPropTypes.master.isRequired
};

export default withTranslation(RecurrenceDetailContent);
