import React from 'react';
import PropTypes from 'prop-types';

import { Button, RoomCard, CustomPropTypes, withTranslation } from '../../../common';

function BookAlternativeResult({ resource, onClick, isLastException, translate, isInProgress }) {
  const label = isLastException
    ? translate('search.recurrence.exceptions.select')
    : translate('search.recurrence.exceptions.selectNext');

  return (
    <div className="BookAlternativeResult">
      <RoomCard resourceSubType={resource.resourceSubType} showRelativeTime={false}>
        <Button label={label} onClick={onClick} loading={isInProgress} />
      </RoomCard>
    </div>
  );
}

BookAlternativeResult.propTypes = {
  onClick: PropTypes.func.isRequired,
  resource: CustomPropTypes.resource,
  isLastException: PropTypes.bool,
  isInProgress: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookAlternativeResult);
