import isNil from 'lodash/fp/isNil';
import cx from 'classnames';

const getEquipmentClassName = (resourceSubType, prefix, ...classNames) => {
  if (!prefix) {
    return ' ';
  }

  const type = (resourceSubType.nameEN.substring(0, resourceSubType.nameEN.length - 2) || '').toLowerCase();
  return cx(classNames.join(' '), {
    [`${prefix}--unknown`]: isNil(resourceSubType.nameEN),
    [`${prefix}--${type}`]: type
  });
};

export default getEquipmentClassName;
