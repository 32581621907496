import React from 'react';
import PropTypes from 'prop-types';

import { Button, withTranslation } from '../../../common';

import './_style.css';

function FormSubmit(props) {
  const { translate, openDetailView, openMasterView } = props;

  const occurrence = translate('myBookings.openOccurrence');
  const recurrence = translate('myBookings.openRecurrence');

  const classes = 'MyBookingsFormSubmit__Button';

  return (
    <div className="MyBookingsFormSubmit__ButtonGroup">
      <Button className={classes} label={occurrence} secondary onClick={openDetailView} />
      <Button className={classes} label={recurrence} onClick={openMasterView} />
    </div>
  );
}

FormSubmit.propTypes = {
  translate: PropTypes.func.isRequired,
  openDetailView: PropTypes.func.isRequired,
  openMasterView: PropTypes.func.isRequired
};

export default withTranslation(FormSubmit);
