import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BookForm, {
  BookFormSubmit,
  BookFormProfile3,
  BookFormProfile2,
  BookFormDate,
  BookFormTime,
  BookFormNotes
} from '../BookForm';
import { BookDetailSidebar } from '../BookDetail';

import { Spacing, ResourceImages } from '../../common';
import { isProfile2, isProfile3 } from '../../../util';

import Header from '../../Header';

class BookUpdateModalMobile extends Component {
  renderBookForm = () => {
    const { selectedResource } = this.props;
    const profile3 = isProfile3(selectedResource);

    if (profile3) {
      return null;
    }

    return <BookForm mobile {...this.props} update recurrence={false} />;
  };

  renderBookFormTime = () => {
    const { selectedResource } = this.props;
    const profile3 = isProfile3(selectedResource);

    if (profile3) {
      return null;
    }
    return (
      <>
        <BookFormDate {...this.props} mobile />
        <BookFormTime {...this.props} />
      </>
    );
  };

  renderBookFormProfile2 = () => {
    const { selectedResource } = this.props;
    const profile2 = isProfile2(selectedResource);

    if (!profile2) {
      return null;
    }

    return (
      <Spacing top={1}>
        <BookFormProfile2 resource={selectedResource} />
      </Spacing>
    );
  };

  renderSidebar = () => {
    const { selectedResource } = this.props;
    return (
      <>
        <BookDetailSidebar resource={selectedResource} />
        {this.renderBookFormProfile2()}
      </>
    );
  };

  renderNotes = () => {
    const { selectedResource } = this.props;
    const { notes } = selectedResource;

    if (!notes) {
      return null;
    }

    return (
      <Spacing top={1}>
        <BookFormNotes notes={notes} />
      </Spacing>
    );
  };

  renderBody = () => {
    const { selectedResource } = this.props;
    const profile3 = isProfile3(selectedResource);
    if (profile3) {
      return <BookFormProfile3 resource={selectedResource} />;
    }

    return (
      <>
        <div className="BookModal__Content">{this.renderBookForm()}</div>
        <hr className="BookModal__Divider" style={{ marginBottom: 20, marginTop: 15 }} />
        <div className="BookModal__Content">{this.renderBookFormTime()}</div>
      </>
    );
  };

  renderSubmit = () => {
    const { selectedResource, updateBooking, isReadyToSubmit, isSubmitting } = this.props;
    const profile3 = isProfile3(selectedResource);
    const profile2 = isProfile2(selectedResource);

    if (profile3) {
      return null;
    }
    return (
      <BookFormSubmit
        onClick={updateBooking}
        isReadyToSubmit={isReadyToSubmit}
        isSubmitting={isSubmitting}
        profile2={profile2}
        mobile
        update
      />
    );
  };

  render() {
    const { selectedResource, goBackToDetail } = this.props;
    return (
      <>
        <Header
          title={selectedResource.displayName}
          onClose={goBackToDetail}
          dynamic
          fixedMobile
          icon="014-chevron-left"
        />

        <div className="BookModalMobile">
          <ResourceImages resource={selectedResource} />
          <div className="BookModal__Main">
            {this.renderBody()}
            <hr className="BookModal__Divider" style={{ marginBottom: 20 }} />
            <div className="BookModal__Content">{this.renderSidebar()}</div>
            <div className="BookModal__Content">{this.renderNotes()}</div>
          </div>
        </div>

        {this.renderSubmit()}
      </>
    );
  }
}

BookUpdateModalMobile.propTypes = {
  selectedResource: PropTypes.shape({
    displayName: PropTypes.string,
    notes: PropTypes.any
  }),
  goBackToDetail: PropTypes.func.isRequired,
  updateBooking: PropTypes.func.isRequired,
  isReadyToSubmit: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  seating: PropTypes.shape()
};

export default BookUpdateModalMobile;
