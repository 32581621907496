import actions from './actions';
import selectors from './selectors';
import { deleteResourceService } from '../../services';
import { notificationsSelectors, notificationsOperations } from '../notifications';

const { reset, updateReason, updateSubmitted, updateIsSubmitting } = actions;

const submit = () => async (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(updateSubmitted());

  if (!selectors.isValid(state)) {
    return false;
  }

  dispatch(updateIsSubmitting(true));

  try {
    const values = selectors.getValues(state);
    const result = await deleteResourceService.post(values);
    dispatch(updateIsSubmitting(false));
    return result;
  } catch (serverError) {
    const error = getNotification('changeRequests.error', serverError);
    dispatch(notificationsOperations.showError(error));
    dispatch(updateIsSubmitting(false));
    return false;
  }
};

export default {
  reset,
  submit,
  updateReason
};
