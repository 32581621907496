import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  FormField,
  FormFieldSelect,
  FormFieldNumber,
  FormFieldBoolean,
  FormFieldPerson,
  FormFieldTextArea,
  FormFieldReadOnly
} from '../FormElements';
import {
  getResourceSubTypeNameTranslation,
  getResourceSubTypeOptions
} from '../../../util';

class CreateRoom extends Component {
  renderFloor = () => {
    const { floor, isSubmitted, readOnly, updateFormValue } = this.props;
    if (readOnly) {
      return <FormFieldReadOnly values={floor} field="floor" text={floor.new} />;
    }
    return (
      <FormField
        field="floor"
        values={floor}
        update={updateFormValue}
        isSubmitted={isSubmitted}
        detectChanges={false}
      />
    );
  };

  renderSector = () => {
    const { sector, isSubmitted, readOnly, updateFormValue } = this.props;
    if (readOnly) {
      return <FormFieldReadOnly values={sector} field="sector" text={sector.new} />;
    }
    return (
      <FormField
        field="sector"
        values={sector}
        update={updateFormValue}
        isSubmitted={isSubmitted}
        detectChanges={false}
        optional
      />
    );
  };

  renderRoomType = () => {
    const {
      resourceSubType,
      resourcesSubTypes,
      changeRequest,
      updateFormValue,
      readOnly,
      isSubmitted
    } = this.props;

    const placeholder = ' - ';

    if (readOnly) {
      return (
        <FormFieldReadOnly
          values={resourceSubType}
          field="resourceSubType"
          text={getResourceSubTypeNameTranslation(changeRequest.resourceSubType)}
        />
      );
    }

    return (
      <FormFieldSelect
        field="resourceSubType"
        placeholder={placeholder}
        options={getResourceSubTypeOptions(resourcesSubTypes, 'Room')}
        values={resourceSubType}
        update={updateFormValue}
        isSubmitted={isSubmitted}
        detectChanges={false}
      />
    );
  };

  renderCapacity = () => {
    const { capacity, isSubmitted, readOnly, updateFormValue } = this.props;
    if (readOnly) {
      return <FormFieldReadOnly values={capacity} field="capacity" text={capacity.new} />;
    }
    return (
      <FormFieldNumber
        field="capacity"
        max={1000}
        values={capacity}
        update={updateFormValue}
        isSubmitted={isSubmitted}
        detectChanges={false}
      />
    );
  };

  renderRoomEquipment = () => {
    const {
      roomEquipment,
      roomEquipmentOptions,
      isSubmitted,
      updateFormValue,
      readOnly,
      translate
    } = this.props;
    const options = roomEquipmentOptions.map(option => ({
      key: option,
      name: translate(`equipment.${option}`)
    }));
    const placeholder = ' - ';

    if (readOnly) {
      const equipments = (roomEquipment && roomEquipment.new)
        .map(equipment => translate(`equipment.${equipment}`))
        .join('; ');
      return <FormFieldReadOnly values={roomEquipment} field="roomEquipment" text={equipments} />;
    }
    return (
      <FormFieldSelect
        field="roomEquipment"
        placeholder={placeholder}
        options={options}
        values={roomEquipment}
        update={updateFormValue}
        isSubmitted={isSubmitted}
        detectChanges={false}
        optional
        multiple
      />
    );
  };

  renderDaylight = () => {
    const { daylight, isSubmitted, readOnly, translate, updateFormValue } = this.props;
    if (readOnly) {
      const text = daylight.new ? translate('common.yes') : translate('common.no');
      return <FormFieldReadOnly values={daylight} field="daylight" text={text} />;
    }
    return (
      <FormFieldBoolean
        values={daylight}
        field="daylight"
        update={updateFormValue}
        isSubmitted={isSubmitted}
        detectChanges={false}
      />
    );
  };

  renderContact = () => {
    const {
      contact,
      peopleSuggestions,
      loadPeopleSuggestions,
      isPeopleLoading,
      readOnly,
      updateFormValue,
      isSubmitted
    } = this.props;

    if (readOnly) {
      return (
        <FormFieldReadOnly
          values={contact}
          field="contact"
          text={contact.new && contact.new.name}
        />
      );
    }

    return (
      <FormFieldPerson
        field="contact"
        values={contact}
        suggestions={peopleSuggestions}
        loadSuggestions={loadPeopleSuggestions}
        update={updateFormValue}
        isSubmitted={isSubmitted}
        isLoading={isPeopleLoading}
        detectChanges={false}
      />
    );
  };

  renderNotes = () => {
    const { notes, updateFormValue, readOnly } = this.props;

    if (readOnly) {
      return <FormFieldReadOnly values={notes} field="notes" text={notes.new} />;
    }

    return (
      <FormFieldTextArea
        field="notes"
        values={notes}
        update={updateFormValue}
        optional
        detectChanges={false}
      />
    );
  };

  render() {
 return (
   <>
     {this.renderFloor()}
     {this.renderSector()}
     {this.renderRoomType()}
     {this.renderCapacity()}
     {this.renderRoomEquipment()}
     {this.renderDaylight()}
     {this.renderContact()}
     {this.renderNotes()}
   </>
 );
}
}

CreateRoom.propTypes = {
  floor: PropTypes.shape().isRequired,
  capacity: PropTypes.shape().isRequired,
  resourceSubType: PropTypes.shape().isRequired,
  resourcesSubTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  daylight: PropTypes.shape().isRequired,
  notes: PropTypes.shape().isRequired,
  roomEquipment: PropTypes.shape().isRequired,
  // confidentialMeetings: PropTypes.shape().isRequired,
  contact: PropTypes.shape().isRequired,
  roomEquipmentOptions: PropTypes.arrayOf(PropTypes.string),
  peopleSuggestions: PropTypes.arrayOf(PropTypes.shape()),
  isPeopleLoading: PropTypes.bool,
  loadPeopleSuggestions: PropTypes.func.isRequired,
  sector: PropTypes.shape().isRequired,
  readOnly: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool,
  updateFormValue: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  changeRequest: PropTypes.shape()
};

export default CreateRoom;
