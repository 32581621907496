import types from '../types';

const updateBookForPerson = person => ({
  type: types.UPDATE_BOOKFOR_PERSON,
  person
});

const updateBookForIsLoading = isLoading => ({
  type: types.UPDATE_BOOKFOR_ISLOADING,
  isLoading
});

const updateBookForSuggestions = suggestions => ({
  type: types.UPDATE_BOOKFOR_SUGGESTIONS,
  suggestions
});

export default {
  updateBookForPerson,
  updateBookForIsLoading,
  updateBookForSuggestions
};
