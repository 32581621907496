import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button, withTranslation } from '../../../../common';

import '../../_style.css';

function SaveFloorMapButton({ translate, actionOnClick }) {
  return (
    <Button
      icon="074-save"
      label={translate('admin.settings.floors.map.saveFloorMapButtonLabel')}
      onClick={actionOnClick} />
  );
}

SaveFloorMapButton.propTypes = {
  translate: PropTypes.func.isRequired,
  actionOnClick: PropTypes.func.isRequired
};

export default withTranslation(withRouter(SaveFloorMapButton));
