import { createSelector } from 'reselect';
import moment from 'moment';

import { i18nSelectors } from '../i18n';

const getPayload = state => state.maintenanceMessageState.payload;
const isLoading = state => state.maintenanceMessageState.loading;
const isPersisted = state => state.maintenanceMessageState.persisted;
const isSubmitting = state => state.maintenanceMessageState.submitting;
const isSubmitted = state => state.maintenanceMessageState.submitted;
const isDirty = state => state.maintenanceMessageState.dirty;

const parseDate = date => moment(date, 'DD.MM.YYYY');
const parseTime = time => moment(time, ['HHmm', 'HH:mm'], true);

const isValidDateTime = (date, time) => {
  if (date && time) {
    const selectedDate = parseDate(date);
    const selectedTime = parseTime(time);
    return selectedDate.isValid() && selectedTime.isValid();
  }
  return false;
};

const toMoment = (date, time) => {
  const selectedDate = parseDate(date);
  const selectedTime = parseTime(time);
  selectedDate.set({
    hour: selectedTime.get('hour'),
    minute: selectedTime.get('minute')
  });
  return selectedDate;
};

const languagesValid = createSelector(
  getPayload,
  payload => {
    const languages = ['german', 'french', 'italian', 'english'];
    const hasLanguage = language => payload[language].length > 0;
    return languages.every(hasLanguage);
  }
);

const fromValid = createSelector(
  getPayload,
  payload => {
    const { date, time } = payload.from;
    return isValidDateTime(date, time);
  }
);

const toValid = createSelector(
  getPayload,
  payload => {
    const { date, time } = payload.to;
    return isValidDateTime(date, time);
  }
);

const dateValid = createSelector(
  [fromValid, toValid],
  (validFrom, validTo) => validFrom && validTo
);

const rangeValid = createSelector(
  [getPayload, dateValid],
  (payload, validDate) => {
    if (!validDate) {
      return false;
    }

    const { from, to } = payload;
    const convert = ({ date, time }) => toMoment(date, time);
    const start = convert(from);
    const end = convert(to);
    return start.isBefore(end);
  }
);

const isValid = createSelector(
  [languagesValid, dateValid, rangeValid],
  (validLanguages, validDate, validRange) => validLanguages && validDate && validRange
);

const getCurrentMessage = state => {
  const { message } = state.maintenanceMessageState;
  if (message && Object.keys(message).length === 0) {
    return null;
  }
  return message;
};

const areMessagesHidden = state => state.maintenanceMessageState.hideMessages;

const getText = (message, language) => {
  switch (language) {
    case 'de': {
      return message.german;
    }

    case 'fr': {
      return message.french;
    }

    case 'it': {
      return message.italian;
    }

    case 'en': {
      return message.english;
    }

    default: {
      return '';
    }
  }
};

const getCurrentTranslatedMessage = createSelector(
  [getCurrentMessage, i18nSelectors.getLanguage],
  (message, language) => {
    if (!message) {
      return null;
    }
    return {
      ...message,
      message: getText(message, language)
    };
  }
);

export default {
  getPayload,
  isDirty,
  isLoading,
  isPersisted,
  isSubmitting,
  isSubmitted,
  isValid,
  languagesValid,
  rangeValid,
  dateValid,
  getCurrentMessage,
  getCurrentTranslatedMessage,
  areMessagesHidden
};
