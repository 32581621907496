import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { recurrenceMasterSelectors } from '../../../state/recurrenceMaster';
import { modalOperations } from '../../../state/modal';
import { myBookingsOperations } from '../../../state/myBookings';
import { Modal, Button, withTranslation } from '../../common';
import Header from '../../Header';
import keys from '../modalKeys';

import './_style.css';

class RecurrenceDeleteDialogMobile extends Component {
  renderHeader = () => {
    const { selectedMaster, popModal } = this.props;
    const title = selectedMaster.resource.displayName;
    const props = {
      title,
      dynamic: true,
      onClose: popModal,
      fixedMobile: true
    };
    return <Header {...props} />;
  };

  renderContent = () => {
    const { translate } = this.props;
    const text = translate('search.detail.recurring.delete');
    return <div className="RecurrenceDeleteDialogMobile__Content">{text}</div>;
  };

  renderButtons = () => {
    const { selectedMaster, deleteMaster, popModal, clearModals, translate } = this.props;

    const deleteMasterBooking = () => {
      deleteMaster(selectedMaster.globalId, selectedMaster.calendarId);
      clearModals();
    };

    const yes = translate('common.yes');
    const no = translate('common.no');

    return (
      <div className="RecurrenceDeleteDialogMobile__ButtonGroup button--fixed">
        <Button
          className="RecurrenceDeleteDialogMobile__Button"
          label={no}
          onClick={popModal}
          secondary
        />
        <Button
          className="RecurrenceDeleteDialogMobile__Button"
          onClick={deleteMasterBooking}
          label={yes}
        />
      </div>
    );
  };

  render() {
    const { selectedMaster } = this.props;
    if (!selectedMaster) {
      return null;
    }

    return (
      <Modal modalKey={keys.RECURRENCE_DELETE_DIALOG} mobile>
        <div>
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderButtons()}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  selectedMaster: recurrenceMasterSelectors.getSelectedMaster(state)
});

const mapDispatchToProps = dispatch => ({
  popModal: () => dispatch(modalOperations.popModal()),
  clearModals: () => dispatch(modalOperations.clearModals()),
  deleteMaster: (id, calendarId) => dispatch(myBookingsOperations.deleteMaster(id, calendarId))
});

RecurrenceDeleteDialogMobile.propTypes = {
  selectedMaster: PropTypes.shape({
    resource: PropTypes.shape({
      displayName: PropTypes.any
    }),
    globalId: PropTypes.any,
    calendarId: PropTypes.any
  }),
  deleteMaster: PropTypes.func.isRequired,
  popModal: PropTypes.func.isRequired,
  clearModals: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

const RecurrenceDeleteDialogMobileComponent = withTranslation(RecurrenceDeleteDialogMobile);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecurrenceDeleteDialogMobileComponent);
