const TOGGLE_FILTER = 'equipmentFilter/TOGGLE_FILTER';
const CHANGE_FILTER = 'equipmentFilter/CHANGE_FILTER';
const RESET_DIRTY = 'equipmentFilter/RESET_DIRTY';
const RESET_FILTER = 'equipmentFilter/RESET_FILTER';
const UPDATE_EQUIPMENTTYPES = 'equipmentFilter/UPDATE_EQUIPMENTTYPES';

export default {
  TOGGLE_FILTER,
  CHANGE_FILTER,
  RESET_DIRTY,
  RESET_FILTER,
  UPDATE_EQUIPMENTTYPES
};
