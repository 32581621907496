import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon, withTranslation, TrayIcons } from '../../../common';

import ResourceDetailField from './ResourceDetailField';

import './_style.css';
import { getResourceSubTypeNameTranslation } from '../../../../util';

class ResourceDetail extends Component {
  renderName = () => {
    const { obsolete, resource } = this.props;
    const { name, displayName } = resource;
    const value = obsolete ? displayName : name;
    return (
      <ResourceDetailField label="common.name">
        <span>{value}</span>
      </ResourceDetailField>
    );
  };

  renderAccountName = () => {
    const { obsolete, resource } = this.props;
    const { accountName } = resource;
    if (obsolete || !accountName) {
      return null;
    }

    return (
      <ResourceDetailField label="Account">
        <span>{accountName}</span>
      </ResourceDetailField>
    );
  };

  renderResourceSubType = () => {
    const { obsolete, resource } = this.props;
    const { resourceSubType } = resource;
    if (!resourceSubType || obsolete) {
      return null;
    }
    return (
      <ResourceDetailField label="common.type">
        <span>{getResourceSubTypeNameTranslation(resourceSubType)}</span>
      </ResourceDetailField>
    );
  };

  renderLocation = () => {
    const { obsolete, resource } = this.props;
    const { building } = resource;
    if (obsolete) {
      return null;
    }
    return (
      <ResourceDetailField label="common.location">
        <span>{building.name}</span>
      </ResourceDetailField>
    );
  };

  renderSize = () => {
    const { translate, obsolete, resource } = this.props;
    const { resourceType, capacity } = resource;
    if (obsolete || resourceType !== 'Room') {
      return null;
    }

    return (
      <ResourceDetailField label="common.size">
        <span>{translate('common.persons', { count: capacity })}</span>
      </ResourceDetailField>
    );
  };

  renderRoomEquipment = () => {
    const { obsolete, resource } = this.props;
    const { resourceType, roomEquipment } = resource;
    if (obsolete || resourceType !== 'Room') {
      return null;
    }

    return (
      <ResourceDetailField label="common.equipment">
        <TrayIcons equipment={roomEquipment} />
      </ResourceDetailField>
    );
  };

  renderContact = () => {
    const { obsolete, resource } = this.props;
    const { managedBy = {}, managedByDisplayName } = resource;
    const value = obsolete ? managedByDisplayName : managedBy.displayName;
    if (!value) {
      return null;
    }

    return (
      <ResourceDetailField label="common.contact">
        <span>{value}</span>
      </ResourceDetailField>
    );
  };

  renderNotes = () => {
    const { obsolete, resource } = this.props;
    const { notes } = resource;
    if (obsolete || !notes) {
      return null;
    }

    return (
      <ResourceDetailField label="common.notes">
        <span>{notes}</span>
      </ResourceDetailField>
    );
  };

  renderResourceDeleted = () => {
    const { obsolete, translate } = this.props;
    if (!obsolete) {
      return null;
    }
    const text = translate('changeRequests.resourceDeleted');
    return (
      <div className="ResourceDetail__ResourceDeleted">
        <Icon icon="026-exclamation-mark-circle" className="ResourceDetailResourceDeleted__Icon" />
        {text}
      </div>
    );
  };

  render() {
    const { resource } = this.props;
    if (!resource) {
      return null;
    }

    return (
      <div className="ResourceDetail">
        {this.renderName()}
        {this.renderAccountName()}
        {this.renderLocation()}
        {this.renderResourceSubType()}
        {this.renderSize()}
        {this.renderRoomEquipment()}
        {this.renderContact()}
        {this.renderNotes()}
        {this.renderResourceDeleted()}
      </div>
    );
  }
}

ResourceDetail.propTypes = {
  resource: PropTypes.shape(),
  obsolete: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

ResourceDetail.defaultProps = {
  obsolete: false
};

export default withTranslation(ResourceDetail);
