import debounce from 'debounce-promise';

import actions from './actions';
import selectors from './selectors';
import types from './types';
import { notificationsSelectors, notificationsOperations } from '../notifications';
import { ownersService, peopleService, changeOwnersService } from '../../services';
import { encodeSearchTerm } from '../../util';

const {
  mapOwnerData,
  initializeData,
  initializeOwners,
  selectProfile,
  updateReason,
  updateContact,
  updateContactIsLoading,
  updateContactSuggestions,
  updateOwners,
  updateOwnerIsLoading,
  updateOwnerSuggestions,
  updateSubmitted,
  updateIsSubmitting
} = actions;

const reset = resource => async (dispatch, getState) => {
  const state = getState();

  dispatch(mapOwnerData(resource));

  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  try {
    const owners = await ownersService.get(resource.id);
    dispatch(initializeOwners(owners, types.UPDATE_OWNER_MODE.both));
  } catch (serverError) {
    const error = getNotification('changeRequests.error', serverError);
    dispatch(notificationsOperations.showError(error));
  }
};

const initializeChangeRequest = changeRequest => async (dispatch, getState) => {
  const state = getState();
  dispatch(initializeData(changeRequest));
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  try {
    const { resource } = changeRequest;
    const owners = await ownersService.get(resource.id);
    dispatch(initializeOwners(owners, types.UPDATE_OWNER_MODE.old));
  } catch (serverError) {
    const error = getNotification('changeRequests.error', serverError);
    dispatch(notificationsOperations.showError(error));
  }
};

const submit = () => async (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(updateSubmitted());

  if (!selectors.isValid(state)) {
    return false;
  }
  dispatch(updateIsSubmitting(true));

  try {
    const values = selectors.getValues(state);
    const result = await changeOwnersService.post(values);
    dispatch(updateIsSubmitting(false));
    return result;
  } catch (serverError) {
    const error = getNotification('changeRequests.error', serverError);
    dispatch(notificationsOperations.showError(error));
    dispatch(updateIsSubmitting(false));
    return false;
  }
};

const loadContactSuggestions = term => (dispatch, getState) => {
  const state = getState();

  const debounceFetch = debounce(searchTerm => peopleService.get(searchTerm), 200);
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const searchTerm = encodeSearchTerm(encodeURIComponent(term));

  if (searchTerm && searchTerm.length > 0) {
    dispatch(updateContactIsLoading(true));
    return debounceFetch(searchTerm)
      .then(response => {
        dispatch(updateContactSuggestions(response));
      })
      .catch(serverError => {
        const error = getNotification('peoplepicker.error', serverError);
        dispatch(notificationsOperations.showError(error));
      })
      .then(() => {
        dispatch(updateContactIsLoading(false));
      });
  }
  dispatch(updateContactSuggestions([]));
  return null;
};

const loadOwnerSuggestions = term => (dispatch, getState) => {
  const state = getState();

  const debounceFetch = debounce(searchTerm => peopleService.get(searchTerm), 200);
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const searchTerm = encodeSearchTerm(encodeURIComponent(term));

  if (searchTerm && searchTerm.length > 0) {
    dispatch(updateOwnerIsLoading(true));
    return debounceFetch(searchTerm)
      .then(response => {
        dispatch(updateOwnerSuggestions(response));
      })
      .catch(serverError => {
        const error = getNotification('peoplepicker.error', serverError);
        dispatch(notificationsOperations.showError(error));
      })
      .then(() => {
        dispatch(updateOwnerIsLoading(false));
      });
  }
  dispatch(updateOwnerSuggestions([]));
  return null;
};

export default {
  reset,
  initializeData: initializeChangeRequest,
  submit,
  selectProfile,
  updateReason,
  loadContactSuggestions,
  loadOwnerSuggestions,
  updateContact,
  updateOwners
};
