/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { styles, lineStyles } from './styles';

function ScreenIcon({ disabled, ...otherProps }) {
  return (
    <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" {...otherProps} style={styles}>
      <path d="M25,6.6h-7.6l2.3-2.2c0.4-0.4,0.4-1,0-1.4s-1-0.4-1.4,0L15,6.1l-3.3-3.3c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l2.3,2.3H5c-1.7,0-3,1.3-3,3v16c0,0.6,0.4,1,1,1h24c0.6,0,1-0.4,1-1v-16C28,7.9,26.7,6.6,25,6.6C25,6.6,25,6.6,25,6.6z M26,24.6H4v-15c0-0.6,0.4-1,1-1h20c0.6,0,1,0.4,1,1V24.6z M6,10.6h18v12H6V10.6z" />
      {disabled && <line style={lineStyles} x1="6.1" y1="1.7" x2="22.9" y2="28.3" />}
    </svg>
  );
}

ScreenIcon.propTypes = {
  disabled: PropTypes.bool
};

export default ScreenIcon;
