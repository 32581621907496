import { createSelector } from 'reselect';

const getBookForPerson = state => {
  const { person } = state.recurrenceMasterState.bookFor;
  if (!person) {
    return null;
  }

  return {
    ...person,
    value: person.email,
    name: person.name || person.displayName,
    id: person.accountName
  };
};
const isBookForLoading = state => state.recurrenceMasterState.bookFor.isLoading;
const getSuggestions = state => state.recurrenceMasterState.bookFor.suggestions;

const getBookForSuggestions = createSelector(
  getSuggestions,
  suggestions => suggestions.map(person => ({
    name: `${person.givenname} ${person.surname}, ${person.department}`,
    value: person.accountName,
    id: person.accountName,
    email: person.email
  }))
);

export default {
  getBookForPerson,
  getBookForSuggestions,
  isBookForLoading
};
