import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/fp/find';

import AutoComplete from '../Input/AutoComplete/AutoComplete';
import identifier from '../identifier';

// import './_style.css';

function PeoplePicker({
  placeholder,
  people,
  selectedPeople,
  term,
  fetchPeople,
  updateTerm,
  updateSelectedPeople,
  loading,
  classes
}) {
  const onChange = event => {
    updateTerm(event.target.value);
    if (event.values !== selectedPeople) {
      updateSelectedPeople(event.values);
    }
    fetchPeople();
  };
  const addEmail = event => {
    const alreadyAdded = find({ email: event.target.value }, selectedPeople);
    if (!alreadyAdded) {
      const newRecipient = {
        email: event.target.value,
        id: identifier(),
        name: event.target.value,
        value: event.target.value
      };

      const onChangeEvent = {
        ...event,
        values: [...selectedPeople, newRecipient]
      };

      onChange(onChangeEvent);
    }
  };

  return (
    <AutoComplete
      placeholder={placeholder}
      value={term}
      onChange={onChange}
      onEnter={addEmail}
      suggestions={people}
      selectedValues={selectedPeople}
      withTags
      loading={loading}
      className={classes}
    />
  );
}

PeoplePicker.propTypes = {
  placeholder: PropTypes.string,
  people: PropTypes.arrayOf(PropTypes.shape()),
  selectedPeople: PropTypes.arrayOf(PropTypes.shape()),
  term: PropTypes.string,
  fetchPeople: PropTypes.func,
  updateTerm: PropTypes.func,
  updateSelectedPeople: PropTypes.func,
  loading: PropTypes.bool,
  classes: PropTypes.string
};

export default PeoplePicker;
