import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';

import { ShowAt, Button, withTranslation } from '../../common';
import { bookSelectors, bookOperations } from '../../../state/book';
import { authSelectors } from '../../../state/auth';
import { modalOperations } from '../../../state/modal';
import FlyoutButton from './FlyoutButton';

import { isProfile3 } from '../../../util';

import modalKeys from '../modalKeys';

function BookDetailActions(props) {
  const {
    isSubmitting,
    globalId,
    calendarId,
    deleteBooking,
    openUpdateModal,
    start,
    end,
    translate,
    isRecurring,
    canEdit,
    user,
    bookFor,
    isMyBooking,
    resource
  } = props;
  const disabled = isSubmitting;

  const deleteBookingText = translate('common.delete');
  const updateBookingText = translate('common.update');

  const hasEmail = user.profile.email != null;
  let email = '';
  if (hasEmail) {
      if (typeof user.profile.email === 'string') {
          email = user.profile.email;
      }
  }

  if (Array.isArray(user.profile.email)) {
    const uniqueEmails = new Set(user.profile.email.map(e => e.toLowerCase()));
    if (uniqueEmails.size === 1) {
      email = [...uniqueEmails];
    } else {
      email = user.profile.email[0].toLowerCase();
    }
  }

  if (bookFor && bookFor.email.toLowerCase() === email && !isMyBooking) {
    return null;
  }

  if (isProfile3(resource)) {
    return null;
  }

  const deleteBookingItem = (isBookingRecurring, isOccurrence) => {
    deleteBooking(globalId, calendarId, isBookingRecurring, isOccurrence);
  };

  const openModal = () => {
    const date = moment(start).format('DD.MM.YYYY');
    const time = {
      from: moment(start).format('HH:mm'),
      to: moment(end).format('HH:mm')
    };
    openUpdateModal(date, time);
  };

  const renderButtons = (isMobile, className) => {
    const classes = cx('BookDetailActions__ButtonGroup', className);
    const buttonClasses = cx('BookDetailActions__Button', {
      BookDetailActions__ButtonFixed: isMobile
    });

    const flyoutButtons = [
      {
        label: translate('search.detail.recurring.deleteWholeRecurring'),
        onClick: () => deleteBookingItem(true, false)
      },
      {
        label: translate('search.detail.recurring.deleteOnlyOccurrence'),
        onClick: () => deleteBookingItem(true, true)
      }
    ];

    return (
      <div className={classes}>
        {!isMobile && isRecurring && (
          <FlyoutButton
            className={buttonClasses}
            outerClassName="BookDetailActions__ButtonWrapper"
            loading={isSubmitting}
            label={deleteBookingText}
            secondary
            options={flyoutButtons}
          />
        )}
        {isMobile && isRecurring && (
          <div className="BookDetailActions__ButtonHalf">
            <FlyoutButton
              className={buttonClasses}
              outerClassName="BookDetailActions__ButtonFixed"
              loading={isSubmitting}
              label={deleteBookingText}
              secondary
              options={flyoutButtons}
              isMobile
            />
          </div>
        )}
        {!isRecurring && (
          <div
            className={
              !isMobile ? 'BookDetailActions__ButtonWrapper' : 'BookDetailActions__ButtonHalf'
            }
          >
            <Button
              className={buttonClasses}
              loading={isSubmitting}
              label={deleteBookingText}
              onClick={() => deleteBookingItem(false, false)}
              secondary
            />
          </div>
        )}
        {canEdit && (
          <div
            className={
              !isMobile ? 'BookDetailActions__ButtonWrapper' : 'BookDetailActions__ButtonHalf'
            }
          >
            <Button
              className={buttonClasses}
              disabled={disabled}
              onClick={openModal}
              label={updateBookingText}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <ShowAt breakpoint="900AndBelow">{renderButtons(true, 'button--fixed')}</ShowAt>
      <ShowAt breakpoint="900AndAbove">{renderButtons(false)}</ShowAt>
    </div>
  );
}

const mapStateToProps = state => ({
  isSubmitting: bookSelectors.isSubmitting(state),
  globalId: bookSelectors.getGlobalId(state),
  calendarId: bookSelectors.getCalendarId(state),
  start: bookSelectors.getStartTime(state),
  end: bookSelectors.getEndTime(state),
  user: authSelectors.getUser(state),
  bookFor: bookSelectors.getBookForPerson(state),
  isMyBooking: bookSelectors.isMyBooking(state)
});

const mapDispatchToProps = dispatch => ({
  openUpdateModal: (date, time) => {
    dispatch(bookOperations.cacheState());
    dispatch(bookOperations.updateDate(date));
    dispatch(bookOperations.updateTime(time));
    dispatch(modalOperations.pushModal(modalKeys.BOOK_UPDATE_MODAL));
  }
});

BookDetailActions.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  globalId: PropTypes.string.isRequired,
  calendarId: PropTypes.string.isRequired,
  deleteBooking: PropTypes.func.isRequired,
  openUpdateModal: PropTypes.func.isRequired,
  start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  isRecurring: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool,
  user: PropTypes.shape({
    profile: PropTypes.shape({
      email: PropTypes.string.isRequired
    })
  }),
  bookFor: PropTypes.shape({
    email: PropTypes.string
  }),
  isMyBooking: PropTypes.bool,
  resource: PropTypes.shape()
};

BookDetailActions.defaultProps = {
  canEdit: true
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(BookDetailActions));
