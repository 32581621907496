/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { styles, lineStyles } from './styles';

function ModeratorCaseIcon({ disabled, ...otherProps }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 0 30 30" {...otherProps} style={styles}>
      <path d="M23,7.32H18v-2a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2v2H3a3,3,0,0,0-3,3v14a1.08,1.08,0,0,0,1,1H25a1.08,1.08,0,0,0,1-1v-14A3,3,0,0,0,23,7.32Zm-13-2h6v2H10Zm14,18H2v-13a.94.94,0,0,1,.88-1H23a1,1,0,0,1,1,1Z" />
      <path d="M17.6,12H8.7a.43.43,0,0,0-.4.4v4.8a1.54,1.54,0,0,0,1.5,1.5h.7v1.8a.43.43,0,0,0,.4.4c.1,0,.2,0,.2-.1l2.9-2.1h2.5a1.54,1.54,0,0,0,1.5-1.5v-4.8A.43.43,0,0,0,17.6,12Z" />
      {disabled && <line style={lineStyles} x1="4.52" y1="1.5" x2="21.82" y2="29.01" />}
    </svg>
  );
}

ModeratorCaseIcon.propTypes = {
  disabled: PropTypes.bool
};

export default ModeratorCaseIcon;
