import React from 'react';
import Async from 'react-select/async';
import PropTypes from 'prop-types';

import DropdownIndicator from './DropdownIndicator';
import ClearIndicator from './ClearIndicator';
import MultiValueRemove from './MultiValueRemove';
import DropDownWrapper from './DropDownWrapper';

import identifier from '../identifier';

import './DropDown.css';

const autoGeneratedId = identifier();

const AsyncDropDown = React.forwardRef(({ label, id, ...props }, ref) => (
  <DropDownWrapper id={id} label={label}>
    <Async
      {...props}
      ref={ref}
      inputId={autoGeneratedId || id}
      className="DropDown__Container"
      classNamePrefix="DropDown"
      components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
    />
  </DropDownWrapper>
));

export default AsyncDropDown;

AsyncDropDown.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};
