import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Header';
import { Spacing, withTranslation } from '../common';
import FeedbackForm from './FeedbackForm';
import { getPropTypes } from './Feedback';

import './_styles.css';

function FeedbackDesktop({ translate, ...props }) {
  const title = translate('feedback.title');

  return (
    <div>
      <Header backlink title={title} />
      <div className="Feedback__Content Feedback__Content--desktop">
        <Spacing horizontal="1" vertical="1">
          <FeedbackForm {...props} />
        </Spacing>
      </div>
    </div>
  );
}

FeedbackDesktop.propTypes = {
  ...getPropTypes,
  translate: PropTypes.func.isRequired
};

export default withTranslation(FeedbackDesktop);
