// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchBarRecurrence_icon__5Cre7 {\n  font-size: 22px !important;\n  margin-bottom: 2px;\n}\n\n.SearchBarRecurrence_active__Ivr8i,\n.SearchBarRecurrence_active__Ivr8i:hover,\n.SearchBarRecurrence_active__Ivr8i:focus {\n  border: 1px solid var(--color-theme);\n}\n", "",{"version":3,"sources":["webpack://./src/views/Search/SearchDesktop/SearchBar/SearchBarRecurrence/SearchBarRecurrence.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,kBAAkB;AACpB;;AAEA;;;EAGE,oCAAoC;AACtC","sourcesContent":[".icon {\n  font-size: 22px !important;\n  margin-bottom: 2px;\n}\n\n.active,\n.active:hover,\n.active:focus {\n  border: 1px solid var(--color-theme);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "SearchBarRecurrence_icon__5Cre7",
	"active": "SearchBarRecurrence_active__Ivr8i"
};
export default ___CSS_LOADER_EXPORT___;
