import React from 'react';

import { Profile2, CustomPropTypes } from '../../common';

function BookFormProfile2({ resource }) {
  const resourceType = resource.resourceType || 'room';
  const path = resourceType.toLowerCase() === 'equipment'
    ? 'search.detail.profile2.equipment'
    : 'search.detail.profile2.room';

  return (
    <div className="BookFormProfile2">
      <Profile2 path={path} resource={resource} />
    </div>
  );
}

BookFormProfile2.propTypes = {
  resource: CustomPropTypes.resource
};

export default BookFormProfile2;
