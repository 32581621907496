import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Header from '../../Header';
import { BookDetailSidebar, BookDetailNotes, RecurrenceDetailActions } from '../BookDetail';
import RecurrenceDetail from '../BookDetail/RecurrenceDetail';
import RecurrenceDetailInfo from '../BookDetail/RecurrenceDetailInfo';
import RecurrenceExceptions from '../BookForm/BookFormRecurrence/RecurrenceExceptions';

import { Loader, withTranslation, ResourceImages, Spacing, CustomPropTypes } from '../../common';

import './_style.css';

class RecurrenceDetailModalMobile extends Component {
  renderHeader = () => {
    const { isLoading, popModal, selectedMaster, translate } = this.props;

    const title = isLoading
      ? translate('search.detail.recurring.loadingRecurrence')
      : selectedMaster.resource.displayName;

    const props = {
      title,
      dynamic: true,
      onClose: popModal,
      fixedMobile: true
    };

    if (isLoading) {
      return (
        <>
          <Header {...props} />
          <Loader visible />
        </>
      );
    }

    return <Header {...props} />;
  };

  renderRecurrenceDetail = () => {
    const { isLoading } = this.props;
    if (isLoading) {
      return <Loader visible />;
    }

    return (
      <RecurrenceDetail {...this.props} />
    );
  };

  renderLoader = () => (
    <div className="BookModal__Main">
      <div className="RecurrenceDetailLoading">
        <Loader visible />
      </div>
    </div>
  );

  renderBody = () => {
    const {
      isLoading,
      selectedMaster,
      exceptions,
      hasUnhandledExceptions,
      patchException,
      openExceptionDeleteModal,
      openExceptionModal,
      confirmDeletion
    } = this.props;

    if (isLoading) {
      return this.renderLoader();
    }

    return (
      <>
        <div className="BookModal__Content">{this.renderRecurrenceDetail()}</div>
        <hr className="BookModal__Divider" style={{ marginBottom: 20, marginTop: 20 }} />
        <div className="BookModal__Content">
          <RecurrenceDetailInfo {...this.props} />
          <RecurrenceExceptions
            exceptions={exceptions}
            resource={selectedMaster}
            patchException={patchException}
            openExceptionModal={openExceptionModal}
            openExceptionDeleteModal={openExceptionDeleteModal}
            hasUnhandledExceptions={hasUnhandledExceptions}
            showExceptionWarning={false}
            showOutlookInfo
            hasLoadedAvailabilities
          />
        </div>
        <div className="BookModal__Content">
          <RecurrenceDetailActions confirmDeletion={confirmDeletion} />
        </div>
      </>
    );
  };

  renderSidebar = () => {
    const { selectedMaster } = this.props;
    return (
      <div className="RecurrenceDetailInformation">
        <BookDetailSidebar resource={selectedMaster.resource} seating={selectedMaster.seating} />
      </div>
    );
  };

  renderNotes = () => {
    const { selectedMaster } = this.props;
    const {
      resource: { notes }
    } = selectedMaster;

    if (!notes) {
      return null;
    }

    return (
      <Spacing top={1}>
        <BookDetailNotes notes={notes} />
      </Spacing>
    );
  };

  renderSubmit = () => {
    const { confirmDeletion } = this.props;
    return <RecurrenceDetailActions confirmDeletion={confirmDeletion} />;
  };

  render() {
    const { isLoading, selectedMaster } = this.props;

    if (isLoading) {
      return this.renderHeader();
    }

    return (
      <>
        {this.renderHeader()}

        <div className="BookModalMobile">
          <ResourceImages resource={selectedMaster.resource} />

          <div className="BookModal__Main">
            {this.renderBody()}
            <hr className="BookModal__Divider" style={{ marginBottom: 20, marginTop: 20 }} />
            <div className="BookModal__Content">{this.renderSidebar()}</div>
            <div className="BookModal__Content">{this.renderNotes()}</div>
          </div>
        </div>

        {this.renderSubmit()}
      </>
    );
  }
}

RecurrenceDetailModalMobile.propTypes = {
  selectedMaster: PropTypes.shape({
    resource: PropTypes.shape({
      displayName: PropTypes.any,
      notes: PropTypes.any
    }),
    seating: PropTypes.any
  }),
  isLoading: PropTypes.bool,
  roomMeetingResponseType: PropTypes.string,
  popModal: PropTypes.func.isRequired,
  confirmDeletion: PropTypes.func.isRequired,
  exceptions: PropTypes.arrayOf(CustomPropTypes.exception),
  hasUnhandledExceptions: PropTypes.bool,
  patchException: PropTypes.func.isRequired,
  openExceptionModal: PropTypes.func.isRequired,
  openExceptionDeleteModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurrenceDetailModalMobile);
