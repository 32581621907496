const UPDATE_BUILDINGS = 'buildings/UPDATE_BUILDINGS';
const UPDATE_LOADED = 'buildings/UPDATE_LOADED';
const SET_SELECTED_BUILDING = 'buildings/SET_SELECTED_BUILDING';
const ADD_FLOORS_TO_BUILDING = 'buildings/ADD_FLOORS_TO_BUILDING';

export default {
    UPDATE_BUILDINGS,
    UPDATE_LOADED,
    SET_SELECTED_BUILDING,
    ADD_FLOORS_TO_BUILDING
};
