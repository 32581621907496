import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { favoriteSelectors, favoriteOperations } from '../../../state/favorite';
import Star from './Star';

class Favorite extends PureComponent {
  toggle = () => {
    const { addFavorite, removeFavorite, resourceId, active } = this.props;
    const action = active ? removeFavorite : addFavorite;
    action(resourceId);
  };

  render() {
    const { active, medium, small, tooltip } = this.props;
    const props = {
      active,
      medium,
      small,
      tooltip,
      onClick: this.toggle
    };

    return <Star {...props} />;
  }
}

const mapStateToProps = (state, { resourceId }) => ({
  active: favoriteSelectors.isFavorite(state, resourceId)
});

const mapDispatchToProps = dispatch => ({
  addFavorite: id => dispatch(favoriteOperations.addFavorite(id)),
  removeFavorite: id => dispatch(favoriteOperations.removeFavorite(id))
});

Favorite.propTypes = {
  active: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  tooltip: PropTypes.bool,
  resourceId: PropTypes.string.isRequired,
  addFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired
};

Favorite.defaultProps = {
  tooltip: true
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Favorite);
