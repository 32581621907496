import { createSelector } from 'reselect';
import isEqual from 'lodash/fp/isEqual';

import { editResourceSelectors } from '../editResource';

const getFormValue = (state, field) => state.changePermissionsState.values[field];

const getResource = state => editResourceSelectors.getResource(state);
const getOwners = state => getFormValue(state, 'owners');
const getReason = state => getFormValue(state, 'reason');
const isOwnerLoading = state => state.changePermissionsState.ownerIsLoading;
const getSuggestions = state => state.changePermissionsState.ownerSuggestions;
const isSubmitted = state => state.changePermissionsState.submitted;
const isSubmitting = state => state.changePermissionsState.isSubmitting;

const getOwnerSuggestions = createSelector(
  getSuggestions,
  suggestions => suggestions.map(person => ({
    name: person.displayName,
    value: person.accountName,
    id: person.accountName,
    email: person.email
  }))
);

const getValues = createSelector(
  [getResource, getOwners, getReason],
  (resource, owners, reason) => {
    const resourceId = resource.id;
    const emails = owners.new.map(owner => owner.email);
    return { id: resourceId, resourceId, owners: emails, reason: reason.new };
  }
);

const isValid = createSelector(
  [getOwners, getReason],
  (owners, reason) => {
    const hasReason = reason.new !== '';
    const hasOwners = owners.new.length > 0;
    return hasReason && hasOwners;
  }
);

const hasChanges = createSelector(
  getOwners,
  owners => !isEqual(owners.old, owners.new)
);

export default {
  getOwners,
  getReason,
  isOwnerLoading,
  getOwnerSuggestions,
  isSubmitted,
  isSubmitting,
  getValues,
  hasChanges,
  isValid
};
