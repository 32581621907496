import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../common';
import { FormFieldPeople, FormFieldReason, FormFieldReadOnly } from '../../FormElements';

class PermissionsForm extends Component {
  renderOwners = () => {
    const {
      owners,
      isOwnerLoading,
      ownerSuggestions,
      loadOwnerSuggestions,
      updateFormValue,
      readOnly,
      isSubmitted
    } = this.props;

    const props = {
      label: 'permissions',
      field: 'owners',
      values: owners,
      loading: isOwnerLoading,
      suggestions: ownerSuggestions,
      loadSuggestions: loadOwnerSuggestions,
      update: updateFormValue,
      isSubmitted
    };

    if (readOnly) {
      const ownersList = (
        <ul>
          {owners.new.map(owner => (
            <li key={owner.id}>{owner.name}</li>
          ))}
        </ul>
      );
      return <FormFieldReadOnly values={owners} field="owners" text={ownersList} />;
    }

    return <FormFieldPeople {...props} />;
  };

  renderReason = () => {
    const { reason, isSubmitted, readOnly, updateFormValue } = this.props;

    if (readOnly) {
      return <FormFieldReadOnly values={reason} text={reason.new} field="reason" />;
    }
    return (
      <FormFieldReason value={reason.new} update={updateFormValue} isSubmitted={isSubmitted} />
    );
  };

  renderValidation = () => {
    const { isSubmitted, isValid, translate } = this.props;
    if (isSubmitted) {
      if (!isValid) {
        const validationError = translate('common.validationError');
        return <div className="PermissionsForm__ValidationError">{validationError}</div>;
      }
    }

    return null;
  };

  render() {
    return (
      <>
        {this.renderOwners()}
        {this.renderReason()}
        {this.renderValidation()}
      </>
    );
  }
}

PermissionsForm.propTypes = {
  owners: PropTypes.shape(),
  reason: PropTypes.shape(),
  isOwnerLoading: PropTypes.bool.isRequired,
  ownerSuggestions: PropTypes.arrayOf(PropTypes.shape()),
  loadOwnerSuggestions: PropTypes.func.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  isValid: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

PermissionsForm.defaultProps = {
  readOnly: false
};

export default withTranslation(PermissionsForm);
