import React from 'react';
import PropTypes from 'prop-types';

import SidebarFormField from './SidebarFormField';

function SidebarSeatingArrangement({ seating }) {
  if (!seating) {
    return null;
  }
  return (
    <SidebarFormField label="common.seating" element={seating.displayName} />
  );
}

SidebarSeatingArrangement.propTypes = {
  seating: PropTypes.shape()
};

export default SidebarSeatingArrangement;
