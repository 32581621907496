import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Header from '../../Header';

import {
  BookDetailContent,
  BookDetailTime,
  BookDetailDate,
  BookDetailSidebar,
  BookDetailActions,
  BookDetailPrivate,
  BookDetailRecurring,
  BookDetailProfile,
  BookDetailNotes,
  BookDetailBookingCollection,
  RecurrenceUnhandledException
} from '../BookDetail';

import { ResourceImages } from '../../common';
import { getProfile } from '../../../util';
import SidebarChangeRequest from '../BookDetail/BookDetailSidebar/SidebarChangeRequest';

class BookDetailModalDesktop extends Component {
  renderBookDetailContent = () => <BookDetailContent {...this.props} />;

  renderBookDetailTime = () => {
    const { start, end } = this.props;

    return (
      <>
        <BookDetailDate start={start} />
        <BookDetailTime start={start} end={end} />
      </>
    );
  };

  renderUnhandledRecurrenceException = () => {
    const { isUnhandledRecurrenceException, selectedResource } = this.props;
    if (!isUnhandledRecurrenceException) {
      return null;
    }

    return (
      <div className="BookModal__Info">
        <RecurrenceUnhandledException resourceType={selectedResource.resourceType} />
      </div>
    );
  };

  renderNotes = () => {
    const { selectedResource } = this.props;
    const { notes } = selectedResource;
    if (notes) {
      return <BookDetailNotes notes={notes} className="BookModal__Info" />;
    }

    return null;
  };

  renderBody = () => {
    const {
      isRecurring,
      isPrivate,
      isBookingCollection,
      calendarId,
      selectedResource,
      roomMeetingResponseType,
      deleteBooking
    } = this.props;

    const profile = getProfile(selectedResource);
    const canEdit = () => {
      if (profile === 3 && roomMeetingResponseType.toLowerCase() === 'declined') {
        return false;
      }
      return true;
    };

    return (
      <div className="BookModal__Body">
        <div className="BookModal__Content">{this.renderBookDetailContent()}</div>
        <hr className="BookModal__Divider" style={{ marginBottom: 20 }} />
        <div className="BookModal__Content">{this.renderBookDetailTime()}</div>
        <hr className="BookModal__Divider" style={{ marginTop: 20, marginBottom: 20 }} />
        <div className="BookModal__Content">
          {isRecurring && (
            <BookDetailRecurring calendarId={calendarId} className="BookModal__Info" />
          )}
          {isPrivate && <BookDetailPrivate className="BookModal__Info" />}
          <BookDetailProfile
            className="BookModal__Info"
            resource={selectedResource}
            responseType={roomMeetingResponseType}
          />
          {this.renderUnhandledRecurrenceException()}
          {this.renderNotes()}
          {isBookingCollection && <BookDetailBookingCollection className="BookModal__Info" />}
        </div>
        <div className="BookModal__Content">
          <BookDetailActions
            resource={selectedResource}
            deleteBooking={deleteBooking}
            isRecurring={isRecurring}
            canEdit={canEdit()}
          />
        </div>
      </div>
    );
  };

  renderSidebar = () => {
    const { selectedResource, seating } = this.props;

    return (
      <>
        <ResourceImages resource={selectedResource} />
        <div className="BookModal__SidebarInfo">
          <BookDetailSidebar resource={selectedResource} seating={seating} />
          <SidebarChangeRequest resource={selectedResource} />
        </div>
      </>
    );
  };

  render() {
    const { selectedResource, clearModals } = this.props;

    return (
      <>
        <Header
          title={selectedResource ? selectedResource.displayName : null}
          onClose={clearModals}
          fixed={false}
          iconRight
          icon="022-close"
        />
        <div className="BookModalDesktop">
          <div className="BookModal__Main">
            <Scrollbars autoHide>{this.renderBody()}</Scrollbars>
          </div>

          <div className="BookModal__Sidebar">
            <Scrollbars autoHide>{this.renderSidebar()}</Scrollbars>
          </div>
        </div>
      </>
    );
  }
}

BookDetailModalDesktop.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  selectedResource: PropTypes.shape({
    displayName: PropTypes.string,
    resourceType: PropTypes.any,
    notes: PropTypes.any
  }),
  seating: PropTypes.shape(),
  isRecurring: PropTypes.bool,
  isPrivate: PropTypes.bool,
  isBookingCollection: PropTypes.bool,
  isUnhandledRecurrenceException: PropTypes.bool,
  calendarId: PropTypes.string.isRequired,
  roomMeetingResponseType: PropTypes.string,
  clearModals: PropTypes.func.isRequired,
  deleteBooking: PropTypes.func.isRequired
};

export default BookDetailModalDesktop;
