import bookTypes from './bookTypes';
import bookForTypes from './bookForTypes';
import attendeesTypes from './attendeesTypes';
import singleAvailabilityTypes from './singleAvailabilityTypes';

export default {
  ...bookTypes,
  ...bookForTypes,
  ...attendeesTypes,
  ...singleAvailabilityTypes
};
