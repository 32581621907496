import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'classnames';

import { Icon, withTranslation, Loader, CustomPropTypes, Tooltip } from '../../../common';

import './_style.css';
import RecurrenceExceptionsWarning from './RecurrenceExceptionsWarning';

const isDifferentResource = reason => reason === 'DifferentResource';
const isDifferentTime = reason => reason === 'DifferentTime';
const isDeclined = reason => reason === 'ResourceDeclined';
const isNoResource = reason => reason === 'NoResourceSelected';
const didNotAccept = reason => reason === 'ResourceDidNotAccept';
const isUnknown = reason => reason === 'Unknown';
const isValidReason = reason => isDifferentResource(reason) || isDifferentTime(reason);
const isInvalidReason = reason => isDeclined(reason) || isNoResource(reason);
const hasValidReason = reasons => !reasons.some(isInvalidReason) && reasons.some(isValidReason);
const format = date => moment(date).format('dd DD.MM.YYYY, HH:mm');

class RecurrenceExceptions extends Component {
  getExceptions = () => {
    const { resource, exceptions } = this.props;
    const { exceptions: unhandledExceptions } = resource;
    const isNotUnhandledException = ({ reasons, resourceMeetingResponseType }) => reasons
      && reasons.some(isUnknown)
      && resourceMeetingResponseType
      && resourceMeetingResponseType === 'Accepted';

    return exceptions && exceptions.length
      ? exceptions
      : (unhandledExceptions || []).filter(isNotUnhandledException);
  };

  renderHead = () => {
    const { hasLoadedAvailabilities, translate } = this.props;
    const exceptions = this.getExceptions();
    const count = exceptions.length;
    return (
      <div>
        {hasLoadedAvailabilities && count > 0 && (
          <p className="BookDetailRecurrence__Exceptions">
            {count > 1
              && translate('search.recurrence.exceptions.summary.titleMore', {
                count
              })}
            {count === 1 && translate('search.recurrence.exceptions.summary.titleOne')}
          </p>
        )}
        {!hasLoadedAvailabilities && (
          <div className="BookDetailRecurrence__Exceptions--loading">
            <Loader size="small" visible />
            <p>{translate('search.recurrence.exceptions.summary.loadingExceptions')}</p>
          </div>
        )}
      </div>
    );
  };

  renderBody = () => {
    const { translate, showOutlookInfo } = this.props;
    const exceptions = this.getExceptions();
    const mappedExceptions = exceptions.map(this.renderException);
    return (
      <div>
        <ul>{mappedExceptions}</ul>
        {showOutlookInfo && (
          <p className="BookDetailRecurrence__Info">
            <Icon icon="046-information-circle" className="BookDetailRecurrence__Icon" />
            {translate('search.recurrence.exceptions.summary.text')}
          </p>
        )}
      </div>
    );
  };

  renderLink = exception => {
    const { openExceptionModal, openExceptionDeleteModal, translate } = this.props;
    const { reasons } = exception;
    const showAlternativeResource = reasons ? hasValidReason(reasons) : exception.resource != null;
    const selectException = () => openExceptionModal(exception);
    const deleteException = () => openExceptionDeleteModal(exception);

    if (showAlternativeResource) {
      return (
        <button className="link" onClick={deleteException} type="button">
          {exception.resource.name}
        </button>
      );
    }

    const selectAlternative = translate('search.recurrence.exceptions.selectAlternative');

    return (
      <button className="link" onClick={selectException} type="button">
        <Icon
          icon="085-synchronise"
          className="RecurrenceException__LinkIcon Icon--flip-horizontal"
        />
        {selectAlternative}
      </button>
    );
  };

  renderSkip = exception => {
    const { translate, patchException } = this.props;
    const discard = translate('search.recurrence.exceptions.discard');
    const onClick = () => patchException({ ...exception, skip: true });
    return (
      <button className="link" onClick={onClick} type="button">
        <Icon icon="008-bin" className="RecurrenceException__LinkIcon" />
        {discard}
      </button>
    );
  };

  renderUnskip = exception => {
    const { translate, patchException } = this.props;
    const unskip = translate('search.recurrence.exceptions.unskip');
    const onClick = () => patchException({ ...exception, skip: false });
    return (
      <button className="link" onClick={onClick} type="button">
        <Icon icon="071-retry" className="RecurrenceException__LinkIcon Icon--flip-horizontal" />
        {unskip}
      </button>
    );
  };

  renderDidNotAccept = () => {
    const { translate } = this.props;
    const text = translate('search.detail.profile2.unknown');

    return (
      <span className="RecurrenceInfo__NotAccepted" data-tip data-for="RecurrenceException">
        <Tooltip id="RecurrenceException" small place="top">
          {text}
        </Tooltip>
        <Icon icon="046-information-circle" />
      </span>
    );
  };

  renderException = exception => {
    const { exceptionStart, exceptionEnd, reasons, start, end } = exception;

    const hasResource = exception.resource != null;
    const showAlternativeResource = reasons ? hasValidReason(reasons) : hasResource;
    const showDidNotAccept = reasons && showAlternativeResource && reasons.some(didNotAccept);
    const { skip = false } = exception;

    const startDate = format(exceptionStart || start);
    const endDate = format(exceptionEnd || end);

    const classes = cx('RecurrenceException__Date', { 'RecurrenceException__Date--skipped': skip });

    return (
      <li key={exception.start} className="RecurrenceException">
        {'— '}
        <span className={classes}>
          <span className="RecurrenceException__DateTime">{startDate}</span>
          {' - '}
          <span className="RecurrenceException__DateTime">{endDate}</span>
        </span>
        {skip ? (
          <span className="RecurrenceException__Link">{this.renderUnskip(exception)}</span>
        ) : (
          <>
            <span className="RecurrenceException__Link">
              {this.renderLink(exception)}
              {showDidNotAccept && this.renderDidNotAccept()}
            </span>
            {!hasResource && (
              <span className="RecurrenceException__Link">{this.renderSkip(exception)}</span>
            )}
          </>
        )}
      </li>
    );
  };

  renderWarning = () => {
    const { showExceptionWarning } = this.props;
    return showExceptionWarning ? <RecurrenceExceptionsWarning /> : null;
  };

  render() {
    const { hasLoadedAvailabilities } = this.props;
    if (!hasLoadedAvailabilities) {
      return (
        <div className="RecurrenceExceptions">
          <div className="RecurrenceExceptions__Head">{this.renderHead()}</div>
        </div>
      );
    }

    const exceptions = this.getExceptions();
    const count = exceptions.length;
    if (count === 0) {
      return null;
    }

    return (
      <div className="RecurrenceExceptions">
        {this.renderWarning()}
        {this.renderHead()}
        {this.renderBody()}
      </div>
    );
  }
}

RecurrenceExceptions.propTypes = {
  translate: PropTypes.func.isRequired,
  resource: PropTypes.shape({
    exceptions: PropTypes.arrayOf(CustomPropTypes.exception)
  }),
  exceptions: PropTypes.arrayOf(CustomPropTypes.exception),
  hasLoadedAvailabilities: PropTypes.bool,
  patchException: PropTypes.func.isRequired,
  openExceptionModal: PropTypes.func.isRequired,
  showOutlookInfo: PropTypes.bool.isRequired,
  showExceptionWarning: PropTypes.bool.isRequired,
  openExceptionDeleteModal: PropTypes.func.isRequired
};

export default withTranslation(RecurrenceExceptions);
