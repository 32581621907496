import types from './types';

const initialState = {
  resources: {}
};

const updateResourceState = state => (resourceId, isActive) => ({
  ...state,
  resources: {
    ...state.resources,
    [resourceId]: isActive
  }
});

const favoriteReducer = (state = initialState, action) => {
  const updateResource = updateResourceState(state);

  switch (action.type) {
    case types.SET_FAVORITE: {
      const { resourceId, isActive } = action;
      return updateResource(resourceId, isActive);
    }

    case types.RESET_FAVORITES: {
      const { resources } = action;
      return {
        ...state,
        resources: { ...state.resources, ...resources }
      };
    }

    default:
      return state;
  }
};

export default favoriteReducer;
