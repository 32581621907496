import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { authSelectors } from '../../../state/auth';
import withUserManager from './withUserManager';

class LoginPage extends React.Component {
  componentDidMount() {
    const { isSignedIn, userManager } = this.props;
    if (!isSignedIn) {
      userManager.signinRedirect();
    }
  }

  render() {
    // ToDo. Eventuell Logon Button rendern,
    // falls User mittels Backbutton erneut auf Login Seite wechselt
    const { userManager } = this.props;
    userManager.signinRedirect();
    return null;
  }
}

const mapStateToProps = state => ({
  isSignedIn: authSelectors.isSignedIn(state)
});

LoginPage.propTypes = {
  userManager: PropTypes.shape().isRequired,
  isSignedIn: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(withUserManager(LoginPage));
