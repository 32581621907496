import React from 'react';
import PropTypes from 'prop-types';

import { Button, withTranslation } from '../common';
import styles from './WelcomeButton.module.css';

function WelcomeButton({ mobile, onClick, translate }) {
  const label = translate('welcome.button');
  const classes = mobile ? styles.modal : styles.dialog;
  return <Button label={label} onClick={onClick} className={classes} />;
}

WelcomeButton.propTypes = {
  mobile: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(WelcomeButton);
