import types from './types';

const initialState = {
  searchTerm: '',
  suggestedRooms: [],
  selectedRooms: [],
  isLoading: false
};

const roomPickerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_SUGGESTED_ROOMS: {
      return { ...state, suggestedRooms: action.suggestedRooms };
    }

    case types.UPDATE_SELECTED_ROOMS: {
      return { ...state, selectedRooms: action.selectedRooms };
    }

    case types.UPDATE_SEARCH_TERM: {
      return { ...state, searchTerm: action.searchTerm };
    }

    case types.UPDATE_IS_LOADING: {
      return { ...state, isLoading: action.isLoading };
    }

    default:
      return state;
  }
};

export default roomPickerReducer;
