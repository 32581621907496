import actions from './actions';
import selectors from './selectors';

const isValidMonth = value => value >= 0 && value <= 12;
const isValidWeek = value => value >= 0 && value <= 26;

const {
  setActive,
  updateRecurrenceType,
  toggleDayOfWeek,
  toggleWeekOfMonth,
  toggleDayOfMonth,
  updateRecurrenceEndType,
  updateRecurrenceEndDate,
  clear,
  resetChanged,
  cacheState,
  resetState
} = actions;

const toggleActive = () => (dispatch, getState) => {
  const state = getState();
  const active = selectors.isActive(state);
  dispatch(setActive(!active));
};

const setInactive = () => dispatch => {
  dispatch(setActive(false));
};

const valueOrEmptyString = value => value || '';

const updateNumberOfBookings = value => dispatch => {
  if (value !== 0) {
    dispatch(actions.updateNumberOfBookings(valueOrEmptyString(value)));
  }
};

const updateIndexOfWeek = index => dispatch => {
  const value = parseInt(index, 10);
  if (isValidWeek(value) || index === '') {
    dispatch(actions.updateIndexOfWeek(valueOrEmptyString(value)));
  }
};

const updateIndexOfMonth = index => dispatch => {
  const value = parseInt(index, 10);
  if (isValidMonth(value) || index === '') {
    dispatch(actions.updateIndexOfMonth(valueOrEmptyString(value)));
  }
};

const disableRecurrence = () => dispatch => {
  dispatch(updateRecurrenceType('never'));
};

export default {
  toggleActive,
  setInactive,
  updateRecurrenceType,
  updateIndexOfWeek,
  toggleDayOfWeek,
  updateIndexOfMonth,
  toggleWeekOfMonth,
  toggleDayOfMonth,
  updateNumberOfBookings,
  updateRecurrenceEndType,
  updateRecurrenceEndDate,
  disableRecurrence,
  clear,
  resetChanged,
  cacheState,
  resetState
};
