import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { authSelectors } from '../../../../state/auth';
import { modalOperations } from '../../../../state/modal';
import modalKeys from './modalKeys';
import { withTranslation, Icon } from '../../../common';
import { floorsOperations, floorsSelectors } from '../../../../state/floors';
import UpdateFloorButton from './UpdateFloorButton';

class FloorsTable extends Component {
  componentDidMount() {
    const { isAdmin, history } = this.props;
    if (!isAdmin) {
      history.replace('/unauthorized');
    }
  }

  renderHeaderRow = () => {
    const { translate } = this.props;

    const columns = [
      translate('common.name'),
      ''
    ];

    const renderCell = value => <th key={value}>{value}</th>;

    return <tr>{columns.map(renderCell)}</tr>;
  };

  renderRow = floor => {
    const { openFloorUpdateModal, selectedFloor, setSelectedFloor } = this.props;

    const cells = [
      { name: floor.name }
    ];

    const setUpModal = () => {
      const { actionOnClick } = this.props;
      setSelectedFloor(floor);
      actionOnClick();
    };

    const updateModal = () => {
      setSelectedFloor(floor);
      openFloorUpdateModal();
    };

    const renderCell = (item, index) => {
      const value = Object.values(item)[0];
      return (
        <td key={index}>
          {value}
        </td>
      );
    };

    const rowClassName = floor.name === selectedFloor?.name ? 'selected' : '';

    return (
      <tr key={floor.name} className={rowClassName} onClick={() => setSelectedFloor(floor)}>
        {cells.map((value, index) => renderCell(value, index))}
        <td aria-label="Delete">
          <Icon icon="008-bin" className="Delete__Icon" onClick={setUpModal} />
          <UpdateFloorButton actionOnClick={updateModal} />
        </td>
      </tr>
    );
  };

  render() {
    const { translate, floors } = this.props;

    const title = translate('admin.settings.floors.table.title');
    const noFloorsMessage = translate('admin.settings.floors.table.noFloors');

    return (
      <div className="table table--responsive">
        <div className="Settings__TableHead">
          <h4 className="Settings__Title">{title}</h4>
        </div>
        <div className="Settings__Table">
          <div className="scrollable_table__wrapper">
            <table className="inline__table">
              <thead>{this.renderHeaderRow()}</thead>
              <tbody>{floors.length > 0 ? floors.map(this.renderRow) : <tr><td>{noFloorsMessage}</td></tr>}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

FloorsTable.propTypes = {
  floors: PropTypes.arrayOf(PropTypes.any),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired,
  actionOnClick: PropTypes.func.isRequired,
  setSelectedFloor: PropTypes.func.isRequired,
  selectedFloor: PropTypes.any
};

const mapDispatchToProps = dispatch => ({
  setSelectedFloor: floor => dispatch(floorsOperations.setSelectedFloor(floor)),
  openFloorUpdateModal: () => dispatch(modalOperations.pushModal(modalKeys.FLOOR_UPDATE_DIALOG))
});

const mapStateToProps = state => ({
  isAdmin: authSelectors.isAdministrator(state),
  selectedFloor: floorsSelectors.getSelectedFloor(state)
});

const FloorsTableComponent = withTranslation(FloorsTable);

FloorsTable.propTypes = {
  isAdmin: PropTypes.bool,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  setSelectedFloor: PropTypes.func.isRequired,
  openFloorUpdateModal: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FloorsTableComponent));
