import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Translate from '../I18n/Translate';
import { Icon } from '..';

import './_style.css';

const getVisible = (message, className) => (
  <div className={cx('ValidationError', className)}>
    <Icon icon="026-exclamation-mark-circle" />
    {' '}
    <Translate path={message} />
  </div>
);

function ValidationError({ message, visible, className }) {
  return visible ? getVisible(message, className) : null;
}

ValidationError.propTypes = {
  message: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  className: PropTypes.string
};

export default ValidationError;
