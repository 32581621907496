import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DateInput from './DateInput';

class DateInputFlyout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutside, true);
  }

  onClickOutside = event => {
    const { domNode } = this;
    if (!domNode || !domNode.contains(event.target)) {
      this.close();
    }
  };

  changeHandler = e => {
    const { onChange } = this.props;
    onChange(e);
  };

  show = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    if (!open) {
      return null;
    }

    const ref = element => {
      this.domNode = element;
    };

    return (
      <div className="DateInputFlyout__Wrapper">
        <div className="DateInputFlyout ArrowBox ArrowUp" ref={ref}>
          <DateInput {...this.props} />
        </div>
      </div>
    );
  }
}

DateInputFlyout.propTypes = {
  onChange: PropTypes.func
};

export default DateInputFlyout;
