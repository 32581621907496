/* eslint-disable react/button-has-type */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isString from 'lodash/fp/isString';
import Icon from '../Icon/Icon';
import Loader from '../Loader/Loader';

import './_style.css';

const renderIcon = icon => {
  if (icon && isString(icon)) {
    return <Icon icon={icon} />;
  }

  return icon;
};

function Button(props) {
  const {
    primary,
    secondary,
    confirm,
    outlined,
    full,
    fixed,
    responsive,
    disabled,
    type,
    label,
    icon,
    className,
    loading,
    onClick,
    ...other
  } = props;

  const styled = primary || secondary || confirm;
  const classes = classNames(
    {
      button: true,
      'button--primary': primary || !styled,
      'button--secondary': secondary,
      'button--outlined': outlined,
      'button--confirm': confirm,
      'button--full': full,
      'button--fixed': fixed,
      'button--responsive': responsive,
      'button--loading': loading,
      button__icon: icon,
      disabled
    },
    className
  );

  return (
    <button
      {...other}
      className={classes}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {!loading && (
        <>
          {renderIcon(icon)}
          {label}
        </>
      )}
      {loading && <Loader visible={loading} size="small" />}
    </button>
  );
}

Button.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  confirm: PropTypes.bool,
  outlined: PropTypes.bool,
  full: PropTypes.bool,
  fixed: PropTypes.bool,
  responsive: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  onClick: PropTypes.func
};

Button.defaultProps = {
  primary: false,
  secondary: false,
  confirm: false,
  full: false,
  responsive: false,
  loading: false,
  disabled: false,
  fixed: false,
  type: 'button'
};

export default Button;
