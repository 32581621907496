import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { authSelectors } from '../../../state/auth';
import { withTranslation, Button } from '../../common';
import Header from '../../Header';
import { newsService } from '../../../services';

import styles from './News.module.css';

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingNews: true,
      errorLoadingNews: false,
      allNews: []
    };
  }

  async componentDidMount() {
    const { isAdmin, history } = this.props;
    if (!isAdmin) {
      history.replace('/unauthorized');
    }
    this.loadNews();
  }

  async loadNews() {
    this.setState({
      loadingNews: true
    });

    try {
      const data = await newsService.getNewsAdmin();
      this.setState({
        loadingNews: false,
        allNews: data
      });
    } catch {
      this.setState({
        loadingNews: false,
        errorLoadingNews: true
      });
    }
  }

  render() {
    const { history, translate } = this.props;
    const { allNews, loadingNews, errorLoadingNews } = this.state;
    return (
      <div>
        <Header onClose={() => { history.push('/'); }} title={translate('menu.news')} />
        <div className={`${styles.body} table table--responsive`}>
          <div className={styles.tableHead}>
            <h3 className={styles.tableHeadTitle}>{translate('admin.news.table.title')}</h3>
            <Button
              className={styles.buttonNewNews}
              label={translate('admin.news.table.newNews')}
              onClick={() => { history.push('/admin/news/new'); }}
            />
          </div>
          <table>
            <thead>
              <tr>
                <th>{translate('admin.news.table.titles.startPublish')}</th>
                <th>{translate('admin.news.table.titles.stopPublish')}</th>
                <th>{translate('admin.news.table.titles.titleDe')}</th>
                <th>{translate('admin.news.table.titles.titleEn')}</th>
                <th>{translate('admin.news.table.titles.titleFr')}</th>
                <th>{translate('admin.news.table.titles.titleIt')}</th>
                <th aria-label="Link" />
              </tr>
            </thead>
            <tbody>
              {!!allNews && allNews.map(news => (
                <tr key={news.id}>
                  <td>{news.startPublish ? moment(news.startPublish).format('DD.MM.YYYY') : ''}</td>
                  <td>{news.stopPublish ? moment(news.stopPublish).format('DD.MM.YYYY') : ''}</td>
                  <td>{news.germanTitle}</td>
                  <td>{news.englishTitle}</td>
                  <td>{news.frenchTitle}</td>
                  <td>{news.italianTitle}</td>
                  <td><a href={`/admin/news/${news.id}`}>{translate('admin.news.table.details')}</a></td>
                </tr>
              ))}
            </tbody>
          </table>
          { loadingNews
            && <div>{translate('common.loading')}</div>}
          { errorLoadingNews
              && (
                <div>
                  {translate('common.errorLoading')}
                </div>
              )}
        </div>
      </div>
    );
  }
}

News.propTypes = {
  isAdmin: PropTypes.bool,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

News.defaultProps = {
  isAdmin: false
};

const mapStateToProps = state => ({
  isAdmin: authSelectors.isAdministrator(state)
});

export default connect(mapStateToProps, null)(
  withTranslation(withRouter(News))
);
