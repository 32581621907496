import React from 'react';
import { Translate } from '../common';

import styles from './WelcomeImprovements.module.css';

function WelcomeImprovements() {
  return (
    <ul className={styles.list}>
      <li>
        <Translate path="welcome.improvements.view" />
      </li>
      <li>
        <Translate path="welcome.improvements.roomsearch" />
      </li>
      <li>
        <Translate path="welcome.improvements.series" />
      </li>
      <li>
        <Translate path="welcome.improvements.mybookings" />
      </li>
      <li>
        <Translate path="welcome.improvements.paging" />
      </li>
      <li>
        <Translate path="welcome.improvements.bugs" />
      </li>
    </ul>
  );
}

export default WelcomeImprovements;
