import React from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/fp/includes';

import { CustomPropTypes, EquipmentCard } from '../../../common';
import FormSubmit from '../FormSubmit';
import PrivateInformation from '../PrivateInformation';
import ProfileInformation from '../ProfileInformation';
import RecurringInformation from '../RecurringInformation';

import { EquipmentNameAndType } from '../NameAndType';
import BookingTime from '../BookingTime';

const width = amount => ({ width: amount });

function BookingResourceCardTabletAndDesktop({
  booking,
  selected,
  onOpenOccurrence,
  onOpenMaster,
  style,
  isProfile3,
  isProfile2
}) {
  const { resource, isPrivate, isRecurring, roomMeetingResponseType } = booking;
  const { resourceSubType } = resource;

  const renderPrivateInformation = () => {
    if (!isPrivate || selected) {
      return null;
    }

    return (
      <div className="BookingCard__Information" key="Private">
        <PrivateInformation />
      </div>
    );
  };

  const renderRoomNameAndType = () => {
    if (selected) {
      return null;
    }

    return [
      <div style={width('50%')} key="EquipmentNameAndType">
        <EquipmentNameAndType equipment={booking.resource} />
      </div>,
      <div style={width('50%')} key="BookingTime">
        <BookingTime booking={booking} />
      </div>
    ];
  };

  const renderFormSubmit = () => {
    if (!selected) {
      return null;
    }
    return (
      <div style={width('100%')} key="FormSubmit">
        <FormSubmit openDetailView={onOpenOccurrence} openMasterView={onOpenMaster} />
      </div>
    );
  };

  const renderProfileInformation = () => {
    if (selected || !(isProfile2 || isProfile3)) {
      return null;
    }

    return (
      <div className="BookingCard__Information" key="Profile2">
        <ProfileInformation resource={resource} responseType={roomMeetingResponseType} />
      </div>
    );
  };

  const renderRecurringInformation = () => {
    if (selected || !isRecurring) {
      return null;
    }

    return (
      <div className="BookingCard__Information" key="Recurring">
        <RecurringInformation />
      </div>
    );
  };

  const onCardClick = () => {
    const isBookedForMe = !includes('Owner', booking.permissionTypes);

    if (!isRecurring || isBookedForMe) {
      onOpenOccurrence();
    }
  };

  return (
    <EquipmentCard resourceSubType={resourceSubType} onClick={onCardClick} style={style}>
      {renderRoomNameAndType()}
      {renderPrivateInformation()}
      {renderRecurringInformation()}
      {renderProfileInformation()}
      {renderFormSubmit()}
    </EquipmentCard>
  );
}

BookingResourceCardTabletAndDesktop.propTypes = {
  booking: CustomPropTypes.booking,
  selected: PropTypes.bool.isRequired,
  style: PropTypes.shape(),
  onOpenOccurrence: PropTypes.func.isRequired,
  onOpenMaster: PropTypes.func.isRequired,
  isProfile3: PropTypes.bool.isRequired,
  isProfile2: PropTypes.bool.isRequired
};

export default BookingResourceCardTabletAndDesktop;
