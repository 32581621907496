import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Input, Icon } from '../../common';

const parse = value => moment(value, ['HH', 'Hmm', 'HHmm']);
const convert = value => moment(value, ['HHmm', 'HH:mm'], true);
const format = time => moment(time).format('HH:mm');

const isValid = value => {
  const time = convert(value);
  return time.isValid();
};

function MaintenanceTime({ value, showError, onChange, ...props }) {
  const handleBlur = ({ target }) => {
    let time = convert(target.value);
    if (!time.isValid()) {
      const parsed = parse(target.value);
      if (parsed.isValid()) {
        time = parsed;
      }
    }

    if (time.isValid()) {
      onChange(format(time));
    }
  };

  const error = showError && !isValid(value);
  const handleChange = ({ target }) => onChange(target.value);

  return (
    <div className="MaintenanceFormInput MaintenanceFormInput--centered">
      <Icon icon="021-clock" className="MaintenanceFormInput__Icon" />
      <Input error={error} value={value} {...props} onChange={handleChange} onBlur={handleBlur} />
    </div>
  );
}

MaintenanceTime.propTypes = {
  value: PropTypes.string,
  showError: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MaintenanceTime;
