import React from 'react';

import { ShowAt } from '../../common';
import RecurrenceDeleteDialogDesktop from './RecurrenceDeleteDialogDesktop';
import RecurrenceDeleteDialogMobile from './RecurrenceDeleteDialogMobile';

function RecurrenceDeleteDialog() {
  return (
    <>
      <ShowAt breakpoint="900AndAbove">
        <RecurrenceDeleteDialogDesktop />
      </ShowAt>
      <ShowAt breakpoint="900AndBelow">
        <RecurrenceDeleteDialogMobile />
      </ShowAt>
    </>
  );
}

export default RecurrenceDeleteDialog;
