import types from './types';

const initialState = {
  changeRequests: [],
  isLoading: false
};

const myChangeRequestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_CHANGEREQUESTS: {
      return { ...state, changeRequests: action.changeRequests };
    }

    case types.UPDATE_ISLOADING: {
      return { ...state, isLoading: action.isLoading };
    }

    default:
      return state;
  }
};

export default myChangeRequestsReducer;
