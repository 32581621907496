import { authOperations } from '../state/auth';

const loadUser = (store, userManager) => {
  if (!store || !store.dispatch) {
    throw new Error('You need to pass the redux store into the loadUser helper!');
  }

  if (!userManager || !userManager.getUser) {
    throw new Error('You need to pass the userManager into the loadUser helper!');
  }

  store.dispatch(authOperations.updateUserIsLoading(true));

  return userManager.getUser().then(user => {
    if (user && !user.expired) {
      store.dispatch(authOperations.updateUser(user));
    }
    store.dispatch(authOperations.updateUserIsLoading(false));
  });
};

export default loadUser;
