import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Input, Icon } from '../../common';

const parseDate = date => moment(date, ['D.M.YYYY', 'DD.MM.YYYY'], true);

function MaintenanceDate({ onChange, showError, value, ...props }) {
  const handleChange = ({ target }) => {
    onChange(target.value);
  };

  const isValid = value && parseDate(value).isValid();
  const error = showError && !isValid;

  return (
    <div className="MaintenanceFormInput MaintenanceFormInput--centered">
      <Icon className="MaintenanceFormInput__Icon" icon="009-calendar" />
      <Input
        value={value}
        error={error}
        {...props}
        onChange={handleChange}
        className="MaintenanceFormInput__Input"
      />
    </div>
  );
}

MaintenanceDate.propTypes = {
  value: PropTypes.string,
  showError: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default MaintenanceDate;
