import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Icon, withTranslation } from '../../common';

import './_style.css';

function BookDetailPrivate({ className, translate }) {
  return (
    <div className={cx('BookDetailPrivate', className)}>
      <Icon icon="049-lock" className="BookDetailPrivate__Icon" />
      {translate('common.private')}
    </div>
  );
}

BookDetailPrivate.propTypes = {
  className: PropTypes.string,
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookDetailPrivate);
