import React from 'react';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';

import './Highlight.css';

function Highlight({ word, text }) {
  const searchWords = [word];
  return (
    <Highlighter
      autoEscape
      highlightClassName="DropDown__option--highlight"
      searchWords={searchWords}
      textToHighlight={text}
    />
  );
}

export default Highlight;

Highlight.propTypes = {
  word: PropTypes.string,
  text: PropTypes.string
};
