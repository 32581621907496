// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecurrenceFilter_container__e9sM9 {\n  display: flex;\n  background-color: var(--color-horizon);\n  border-bottom: 1px solid var(--color-gray-20);\n}\n\n.RecurrenceFilter_panel__Ik0Fd {\n  display: flex;\n  flex-direction: column;\n}\n\n.RecurrenceFilter_button__yraFx {\n  min-width: 360px;\n  margin: 20px;\n}\n\n.RecurrenceFilter_empty__y7uGG {\n  background-color: var(--color-theme);\n  border-bottom: 1px solid var(--color-theme);\n}\n", "",{"version":3,"sources":["webpack://./src/views/Search/SearchDesktop/RecurrenceFilter/RecurrenceFilter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sCAAsC;EACtC,6CAA6C;AAC/C;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,oCAAoC;EACpC,2CAA2C;AAC7C","sourcesContent":[".container {\n  display: flex;\n  background-color: var(--color-horizon);\n  border-bottom: 1px solid var(--color-gray-20);\n}\n\n.panel {\n  display: flex;\n  flex-direction: column;\n}\n\n.button {\n  min-width: 360px;\n  margin: 20px;\n}\n\n.empty {\n  background-color: var(--color-theme);\n  border-bottom: 1px solid var(--color-theme);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RecurrenceFilter_container__e9sM9",
	"panel": "RecurrenceFilter_panel__Ik0Fd",
	"button": "RecurrenceFilter_button__yraFx",
	"empty": "RecurrenceFilter_empty__y7uGG"
};
export default ___CSS_LOADER_EXPORT___;
