import React from 'react';
import PropTypes from 'prop-types';

import { Input, Translate } from '../../common';

function WeekIndex({ index, updateIndex }) {
  const onChange = event => {
    const { value } = event.target;
    updateIndex(value);
  };

  const onBlur = event => {
    const { value } = event.target;
    if (value === '') {
      updateIndex(1);
    }
  };

  return (
    <table>
      <tbody>
        <tr>
          <td aria-label="Before">
            <Translate path="search.recurrence.weekDescription.before" />
          </td>
          <td aria-label="Button">
            <Input
              style={{ maxWidth: 65 }}
              type="number"
              value={index}
              onChange={onChange}
              onBlur={onBlur}
            />
          </td>
          <td aria-label="After">
            <Translate path="search.recurrence.weekDescription.after" />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

WeekIndex.propTypes = {
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  updateIndex: PropTypes.func.isRequired
};

export default WeekIndex;
