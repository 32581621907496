import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, withTranslation } from '../../../common';
import BookingTime from '../BookingTime';

function RoomCapacityAndTime({ booking, translate }) {
  const { resource } = booking;

  const persons = `${resource.capacity || '?'} ${translate('common.people')}`;

  return (
    <div>
      <div>{persons}</div>
      <div>
        <BookingTime booking={booking} />
      </div>
    </div>
  );
}

RoomCapacityAndTime.propTypes = {
  booking: CustomPropTypes.booking,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RoomCapacityAndTime);
