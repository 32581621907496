import React from 'react';

import NoConnectionState from './NoConnectionState';

const withOfflineState = Component => {
  class enhancedComponent extends React.Component {
    constructor(props) {
      super(props);

      if ('onLine' in navigator) {
        this.state = {
          isSupported: true,
          isOnline: navigator.onLine
        };
      } else {
        this.state = {
          isSupported: false,
          isOnline: true
        };
      }

      this.onOnline = this.onOnline.bind(this);
      this.onOffline = this.onOffline.bind(this);
    }

    UNSAFE_componentWillMount() {
      const { isSupported } = this.state;
      if (isSupported) {
        window.addEventListener('online', this.onOnline);
        window.addEventListener('offline', this.onOffline);
      }
    }

    componentWillUnmount() {
      const { isSupported } = this.state;
      if (isSupported) {
        window.removeEventListener('online', this.onOnline);
        window.removeEventListener('offline', this.onOffline);
      }
    }

    onOnline() {
      this.setState({ isOnline: true });
    }

    onOffline() {
      this.setState({ isOnline: false });
    }

    render() {
      const { isSupported, isOnline } = this.state;

      if (!isSupported || isOnline) {
        return <Component />;
      }
      return <NoConnectionState />;
    }
  }

  enhancedComponent.displayName = `withOfflineState(${Component.displayName || Component.name})`;
  enhancedComponent.WrappedComponent = Component;

  return enhancedComponent;
};

export default withOfflineState;
