import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, withTranslation } from '../../../common';
import '../_style.css';

function NewRegionButton({ translate, history }) {
  const redirect = () => {
    history.push('/admin/settings/regions/create');
  };

  return (
    <Button
      icon="054-new"
      label={translate('regions.table.new')}
      onClick={redirect} />
  );
}

NewRegionButton.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(withRouter(NewRegionButton));
