import curry from 'lodash/fp/curry';

class TextAreaDimensions {
  static getStyles(element) {
    return window.getComputedStyle(element);
  }

  static getLineHeight(element) {
    const css = curry((property, elem) => TextAreaDimensions.getStyles(elem).getPropertyValue(property));

    const lineHeight = css('line-height', element);
    const value = parseInt(lineHeight, 10);
    return Number.isNaN(value) ? null : value;
  }

  static isFocused(element) {
    return element === document.activeElement;
  }

  static isHidden = (element, includeParents = false) => {
    if (element == null) {
      return false;
    }

    const { display } = TextAreaDimensions.getStyles(element);
    const result = display === 'none' || element.offsetLeft < 0;
    if (!includeParents) {
      return result;
    }

    return result || TextAreaDimensions.isHidden(element.parentElement, true);
  };

  constructor(element) {
    this.element = element;
    this.calculateBaseHeight = this.calculateBaseHeight.bind(this);
    this.calculateBaseHeight();
  }

  calculateBaseHeight() {
    // temporary clear the content to take measurements
    const { value } = this.element;
    this.element.value = '';

    this.lineHeight = TextAreaDimensions.getLineHeight(this.element);
    this.baseHeight = this.element.offsetHeight - this.lineHeight;
    this.baseScrollHeight = this.element.scrollHeight - this.lineHeight;
    this.updateBaseHeight = this.lineHeight == null;

    this.element.value = value;
  }

  updateDimensions(minRows, maxRows) {
    let rowsCount = 0;
    let maxRowsCount = 0;

    if (this.updateBaseHeight && !TextAreaDimensions.isHidden(this.element, true)) {
      this.calculateBaseHeight();
    }

    this.element.style.height = 'auto';
    const size = this.element.scrollHeight - this.baseScrollHeight;
    rowsCount = Math.ceil(size / this.lineHeight) + 1;
    maxRowsCount = Math.max(Math.min(maxRows, rowsCount), minRows);

    const overflow = rowsCount > maxRowsCount ? 'auto' : 'hidden';
    const rowsHeight = (maxRowsCount - 1) * this.lineHeight;
    const height = rowsHeight + this.baseHeight;

    this.element.style.overflow = overflow;
    this.element.style.height = `${height}px`;
  }
}

export default TextAreaDimensions;
