import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { withTranslation } from '../../common';
import Header from '../../Header';
import MyChangeRequestsHelp from './MyChangeRequestsHelp';

function MyChangeRequestsHeader({ showHelp, history, translate }) {
  const title = translate('changeRequests.my.title');

  const onClose = () => history.push('/');

  const props = {
    title,
    fixed: false,
    onClose
  };

  if (!showHelp) {
    return <Header {...props} />;
  }

  return (
    <Header {...props}>
      <MyChangeRequestsHelp />
    </Header>
  );
}

MyChangeRequestsHeader.propTypes = {
  translate: PropTypes.func.isRequired,
  showHelp: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withTranslation(withRouter(MyChangeRequestsHeader));
