import React from 'react';
import { CustomPropTypes, EquipmentType } from '../../../../common';
import './_style.css';

function EquipmentNameAndType({ resource }) {
  const { displayName, resourceSubType } = resource;

  return (
    <div>
      <div className="EquipmentNameAndType__Equipment">
        <span className="EquipmentNameAndType__EquipmentName">{displayName}</span>
      </div>
      <p>
        <EquipmentType resourceSubType={resourceSubType} />
      </p>
    </div>
  );
}

EquipmentNameAndType.propTypes = {
  resource: CustomPropTypes.resource
};

export default EquipmentNameAndType;
