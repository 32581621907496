import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import { searchOperations } from '../../../../../state/search';
import SearchResult from '../../../SearchResults';
import News from './News';
import styles from './Now.module.css';
import { availabilityOperations } from '../../../../../state/availability';
import { locationsOperations } from '../../../../../state/locations';
import { emitter } from '../../../../../util';

class Now extends Component {
  constructor(props) {
    super(props);

    this.subscription = null;
  }

  componentDidMount() {
    const { loadAvailabilities, updateDate, updateTime, setToBuildingById } = this.props;
    const { match, location, history } = this.props;

    const queryStringParams = queryString.parse(location.search, { ignoreQueryPrefix: true });
    const { buildingId, sortingList, startTime, endTime } = queryStringParams;

    if (startTime && endTime) {
      const startMoment = moment(queryStringParams.startTime);
      const endMoment = moment(queryStringParams.endTime);

      if (startMoment.isValid() && endMoment.isValid()) {
        const date = startMoment.format('DD.MM.YYYY');
        updateDate(date);
        updateTime({
          from: startMoment.format('HH:mm'),
          to: endMoment.format('HH:mm')
        });
      }
    }

    if (buildingId) {
      setToBuildingById(buildingId);
    }

    history.replace(match.url);

    // NOTE: the sortingList parameter will only be passed at the very first request (not on next pages/after reload)
    // since it is a sorting criteria and those preferred room lists only contain 2-3 rooms, this is not yet a problem
    // the issue with saving it to the state; when would we remove it? because it is only activated via url-param
    loadAvailabilities(true, sortingList);

    this.subscription = emitter.addListener('onSearchFilterChanged', () => {
      loadAvailabilities(true, sortingList);
    });
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  render() {
    return (
      <div className={styles.body}>
        <SearchResult />
        <News />
      </div>
    );
  }
}

Now.propTypes = {
  loadAvailabilities: PropTypes.func.isRequired,
  updateDate: PropTypes.func.isRequired,
  updateTime: PropTypes.func.isRequired,
  setToBuildingById: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

const mapDispatchToProps = dispatch => ({
  loadAvailabilities: (ignoreChanges = false, sortingList) => dispatch(
    availabilityOperations.loadAvailabilities(ignoreChanges, sortingList)
  ),
  updateDate: date => dispatch(searchOperations.updateDate(date)),
  updateTime: time => dispatch(searchOperations.updateTime(time)),
  setToBuildingById: buildingId => dispatch(locationsOperations.setToBuildingById(buildingId))
});

export default connect(
  null,
  mapDispatchToProps
)(withRouter(Now));
