import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { roomFilterSelectors } from '../../../../../state/roomFilter';
import { exceptionsSelectors } from '../../../../../state/exceptions';
import { modalSelectors } from '../../../../../state/modal';
import { TrayIcons } from '../../../../common';

import modalKeys from '../../../../BookModal/modalKeys';

const isEqual = (equipment, other) => {
  const type = equipment.roomEquipmentType || equipment.name;
  const otherType = other.roomEquipmentType || other.name;
  return type && otherType && type.toUpperCase() === otherType.toUpperCase();
};

const mapRoomEquipmentType = roomEquipment => ({
  ...roomEquipment,
  type: roomEquipment.roomEquipmentType
});

function RoomEquipment({ equipment, selectedEquipment, showAlternativeEquipment }) {
  const disabledEquipment = showAlternativeEquipment
    ? selectedEquipment.filter(selectedItem => !equipment.some(item => isEqual(selectedItem, item)))
    : [];

  const items = [
    ...disabledEquipment.map(item => ({
      name: item.name,
      id: item.name,
      roomEquipmentType: item.roomEquipmentType || item.name,
      disabled: true
    })),
    ...equipment
  ];

  return (
    <div>
      <TrayIcons equipment={items} />
    </div>
  );
}

const mapStateToProps = state => {
  const currentlySelectedEquipment = roomFilterSelectors.getSelectedEquipmentFilters(state);
  const namespace = roomFilterSelectors.getNamespace(state);
  const exceptionResource = exceptionsSelectors.getResource(state);
  const activeModal = modalSelectors.getActiveModalKey(state);
  const isExceptionsModalActive = activeModal === modalKeys.RECURRENCE_EXCEPTION_MODAL;

  if (
    isExceptionsModalActive
    && exceptionResource
    && exceptionResource.roomEquipment
    && namespace !== 'bookingCollection'
  ) {
    return {
      selectedEquipment: exceptionResource.roomEquipment.map(mapRoomEquipmentType)
    };
  }
  return { selectedEquipment: currentlySelectedEquipment };
};

RoomEquipment.propTypes = {
  equipment: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedEquipment: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showAlternativeEquipment: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(RoomEquipment);
