/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { styles, lineStyles } from './styles';

function AudioIcon({ disabled, ...otherProps }) {
  return (
    <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" {...otherProps} style={styles}>
      <path d="M19.41,10.46a8,8,0,0,1,0,9.58,1,1,0,0,1-1.6-1.2,6,6,0,0,0,0-7.18,1,1,0,0,1,1.6-1.2Zm3.51-3.6a1,1,0,0,0-1.53,1.29,11,11,0,0,1,0,14.2,1,1,0,1,0,1.53,1.29,13,13,0,0,0,0-16.78ZM14,5.26v20a1,1,0,0,1-.53.88,1,1,0,0,1-1,0L6.7,22.25H1a1,1,0,0,1-1-1v-12a1,1,0,0,1,1-1H6.7l5.75-3.83A1,1,0,0,1,14,5.26ZM12,7.12l-4.45,3a1,1,0,0,1-.55.17H2v10H7a1,1,0,0,1,.56.17l4.44,3Z" />
      {disabled && <line style={lineStyles} x1="4.35" y1="1.5" x2="21.65" y2="29.01" />}
    </svg>
  );
}

AudioIcon.propTypes = {
  disabled: PropTypes.bool
};

export default AudioIcon;
