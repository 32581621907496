import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Slider from 'react-slick';

import './carousel/slick.css';
import './_style.css';

import ResourceImage from './ResourceImage';
import { Icon } from '..';

const isEquipment = resource => resource.resourceType.toLowerCase() === 'equipment';
const hasPhotos = resource => resource.photoIds && resource.photoIds.length > 0;

class ResourceImages extends Component {
  renderNextArrow = () => {
    const { showArrows } = this.props;
    if (!this.slider || !showArrows) {
      return null;
    }
    const { slickNext: onClick, state } = this.slider;
    const { slideCount, currentSlide } = state;

    if (currentSlide === slideCount - 1) {
      return null;
    }

    return (
      <div
        aria-label="Next"
        className="ResourceImages__Arrow ResourceImages__Arrow--next"
        onClick={onClick}
        role="button"
        tabIndex="-1"
      >
        <Icon icon="015-chevron-right" />
      </div>
    );
  };

  renderPrevArrow = () => {
    const { showArrows } = this.props;
    if (!this.slider || !showArrows) {
      return null;
    }
    const { slickPrev: onClick, state } = this.slider;
    const { currentSlide } = state;

    if (currentSlide === 0) {
      return null;
    }

    return (
      <div
        aria-label="Previous"
        className="ResourceImages__Arrow ResourceImages__Arrow--prev"
        onClick={onClick}
        role="button"
        tabIndex="-1"
      >
        <Icon icon="014-chevron-left" />
      </div>
    );
  };

  render() {
    const { resource, autoPlay, autoPlayInterval, small, width } = this.props;
    const { photoIds } = resource;

    if (isEquipment(resource) || !hasPhotos(resource)) {
      return null;
    }

    const imageClasses = cx('ResourceImages__Image', {
      'ResourceImages__Image--small': small
    });

    const imageWidth = width
      ? {
        width
      }
      : null;

    const images = photoIds.map(photoId => (
      <div key={photoId} className={imageClasses}>
        <ResourceImage photoId={photoId} style={imageWidth} />
      </div>
    ));

    const rerenderAfterInit = () => {
      this.forceUpdate();
    };

    const sliderRef = slickElement => {
      this.slider = slickElement ? slickElement.innerSlider : null;
    };

    const NextArrow = this.renderNextArrow;
    const PrevArrow = this.renderPrevArrow;

    const showDots = photoIds.length > 1;

    return (
      <Slider
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        ref={sliderRef}
        dots={showDots}
        dotsClass="ResourceImages__Dots slick-dots"
        className="ResourceImages"
        lazyLoad={autoPlay}
        autoplay={autoPlay && showDots}
        infinite={autoPlay && showDots}
        draggable={showDots}
        autoplaySpeed={autoPlay ? autoPlayInterval : null}
        init={rerenderAfterInit}
      >
        {images}
      </Slider>
    );
  }
}

ResourceImages.propTypes = {
  resource: PropTypes.shape({
    photoIds: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  showArrows: PropTypes.bool,
  autoPlay: PropTypes.bool,
  autoPlayInterval: PropTypes.number,
  small: PropTypes.bool,
  width: PropTypes.number
};

ResourceImages.defaultProps = {
  showArrows: true,
  autoPlay: false,
  autoPlayInterval: 2000,
  small: false
};

export default ResourceImages;
