import React from 'react';
import PropTypes from 'prop-types';

import Availability from '../Availability';
import { CustomPropTypes } from '../../../../common';

function ResourceAvailability({ resource, showRelativeTime, showAlternativeTime }) {
  const { availabilities } = resource;

  return (
    <div>
      <Availability
        availabilities={availabilities}
        showRelativeTime={showRelativeTime}
        showAlternativeTime={showAlternativeTime}
      />
    </div>
  );
}

ResourceAvailability.propTypes = {
  resource: CustomPropTypes.resource,
  showRelativeTime: PropTypes.bool.isRequired,
  showAlternativeTime: PropTypes.bool
};

export default ResourceAvailability;
