import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { Button, withTranslation } from '../../../common';

import MasterDataForm from './MasterDataForm';

import './_style.css';

import {
  changeMasterDataSelectors,
  changeMasterDataOperations
} from '../../../../state/changeMasterData';

import { editResourceSelectors } from '../../../../state/editResource';
import { resourcesSubTypesSelectors } from '../../../../state/resourcesSubTypes';

class MasterDataPage extends Component {
  renderSubmitButton = () => {
    const { submit, isSubmitting, hasChanges, history, translate } = this.props;
    const send = translate('common.send');
    const onClick = event => {
      event.preventDefault();
      event.stopPropagation();
      submit().then(result => {
        if (result) {
          history.replace('/changerequests/my');
        }
      });
    };
    const disabled = isSubmitting || !hasChanges;
    return (
      <div className="MasterDataForm__Submit">
        <Button label={send} disabled={disabled} onClick={onClick} />
      </div>
    );
  };

  render() {
    return (
      <div className="MasterDataForm">
        <MasterDataForm {...this.props} />
        {this.renderSubmitButton()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  resource: editResourceSelectors.getResource(state),
  floor: changeMasterDataSelectors.getFloor(state),
  sector: changeMasterDataSelectors.getSector(state),
  resourceSubType: changeMasterDataSelectors.getResourceSubType(state),
  resourcesSubTypes: resourcesSubTypesSelectors.getAll(state),
  capacity: changeMasterDataSelectors.getCapacity(state),
  roomEquipment: changeMasterDataSelectors.getRoomEquipment(state),
  roomEquipmentOptions: changeMasterDataSelectors.getRoomEquipmentOptions(state),
  daylight: changeMasterDataSelectors.getDaylight(state),
  confidentialMeetings: changeMasterDataSelectors.getConfidentialMeetings(state),
  notes: changeMasterDataSelectors.getNotes(state),
  contact: changeMasterDataSelectors.getContact(state),
  reason: changeMasterDataSelectors.getReason(state),
  contactSuggestions: changeMasterDataSelectors.getContactSuggestions(state),
  isContactLoading: changeMasterDataSelectors.isContactLoading(state),
  hasChanges: changeMasterDataSelectors.hasChanges(state),
  isValid: changeMasterDataSelectors.isValid(state),
  isSubmitted: changeMasterDataSelectors.isSubmitted(state),
  isSubmitting: changeMasterDataSelectors.isSubmitting(state)
});

const mapDispatchToProps = dispatch => ({
  submit: () => dispatch(changeMasterDataOperations.submit()),
  updateFormValue: (field, value) => dispatch(changeMasterDataOperations.updateFormValue(field, value)),
  loadContactSuggestions: term => dispatch(changeMasterDataOperations.loadContactSuggestions(term))
});

MasterDataPage.propTypes = {
  hasChanges: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

const MasterDataPageComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(MasterDataPage);
export default withTranslation(withRouter(MasterDataPageComponent));
