/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { styles, lineStyles } from './styles';

function FlipchartIcon({ disabled, ...otherProps }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="3.5 0 24 30" {...otherProps} style={styles}>
      <path d="M20.9,2.5H9.6c-1.7,0-3,1.3-3,3l0,0v14c0,0.5,0.5,1,1,1h2.5l-2,5.7c-0.2,0.5,0.1,1.1,0.6,1.3c0,0,0,0,0,0l0,0c0.5,0.2,1.1-0.1,1.3-0.6c0,0,0,0,0,0l0,0l2.2-6.4h2.2v4.6c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9l0,0v-4.6h2.3l2.2,6.4c0.2,0.5,0.8,0.8,1.3,0.6c0.5-0.2,0.8-0.8,0.6-1.3l0,0l-2-5.7h2.3c0.5,0,1-0.5,1-1v-14C23.9,3.8,22.6,2.5,20.9,2.5z M21.9,18.5H8.6v-13c0-0.5,0.4-1,0.9-1c0,0,0,0,0,0h11.4c0.6,0,1,0.4,1,1V18.5z" />
      {disabled && <line style={lineStyles} x1="6.6" y1="1.7" x2="23.4" y2="28.3" />}
    </svg>
  );
}

FlipchartIcon.propTypes = {
  disabled: PropTypes.bool
};

export default FlipchartIcon;
