import { createSelector } from 'reselect';
import isBoolean from 'lodash/fp/isBoolean';
import isEmpty from 'lodash/fp/isEmpty';
import isNumber from 'lodash/fp/isNumber';
import isEqual from 'lodash/fp/isEqual';
import omit from 'lodash/fp/omit';
import values from 'lodash/fp/values';
import { roomFilterSelectors } from '../roomFilter';
import { editResourceSelectors } from '../editResource';

const getFormValue = (state, field, defaultValue = '') => {
  const initial = defaultValue;
  return state.changeMasterDataState.values[field] || { old: initial, new: initial };
};

const getResource = state => editResourceSelectors.getResource(state);
const getFloor = state => getFormValue(state, 'floor');
const getSector = state => getFormValue(state, 'sector');
const getResourceSubType = state => getFormValue(state, 'resourceSubType');
const getCapacity = state => getFormValue(state, 'capacity');
const getRoomEquipment = state => getFormValue(state, 'roomEquipment', []);
const getDaylight = state => getFormValue(state, 'daylight', null);
const getConfidentialMeetings = state => getFormValue(state, 'confidentialMeetings');
const getNotes = state => getFormValue(state, 'notes');
const getContact = state => getFormValue(state, 'contact');
const getReason = state => getFormValue(state, 'reason');
const isSubmitted = state => state.changeMasterDataState.submitted;
const isSubmitting = state => state.changeMasterDataState.isSubmitting;

const getRoomEquipmentOptions = createSelector(
  roomFilterSelectors.getRoomFilters,
  roomFilters => roomFilters.filter(filter => filter.type === 'equipment').map(filter => filter.name)
);

const isContactLoading = state => state.changeMasterDataState.contactIsLoading;
const getSuggestions = state => state.changeMasterDataState.contactSuggestions;

const getContactSuggestions = createSelector(
  getSuggestions,
  suggestions => suggestions.map(person => ({
    name: person.displayName,
    value: person.accountName,
    id: person.accountName,
    email: person.email
  }))
);

const isValueEmpty = value => {
  if (isBoolean(value)) {
    return false;
  }
  if (!value) {
    return true;
  }
  if (isNumber(value) && value > 0) {
    return false;
  }
  return isEmpty(value);
};

const getValues = (state, prop) => {
  const resource = getResource(state);

  const contactMail = getContact(state)[prop] && getContact(state)[prop].email;

  const commonValues = {
    resourceId: resource.id,
    resourceType: resource.resourceType,
    contact: contactMail,
    notes: getNotes(state)[prop],
    reason: getReason(state)[prop]
  };

  /* eslint-disable indent */
  const specificValues = resource.resourceType && resource.resourceType.toLowerCase() === 'room'
      ? {
          resourceSubType: getResourceSubType(state)[prop],
          floor: getFloor(state)[prop],
          sector: getSector(state)[prop],
          capacity: getCapacity(state)[prop],
          daylight: getDaylight(state)[prop],
          confidentialMeetings: getConfidentialMeetings(state)[prop],
          roomEquipment: getRoomEquipment(state)[prop]
        }
      : {
          resourceSubType: getResourceSubType(state)[prop]
        };

  return { ...commonValues, ...specificValues };
};

const getNewValues = state => getValues(state, 'new');
const getOldValues = state => getValues(state, 'old');

const isValid = createSelector(
  [getNewValues],
  allValues => {
    const requiredValues = values(
      omit(['resourceId', 'resourceType', 'roomEquipment', 'sector', 'notes'], allValues)
    );
    const allValid = requiredValues.reduce((prev, value) => {
      if (prev === true) {
        return !isValueEmpty(value);
      }
      return false;
    }, true);

    const validateCapacity = allValues.resourceType === 'Room';
    if (!validateCapacity) {
      return allValid;
    }

    const { capacity } = allValues;
    const isCapacityValid = isNumber(capacity) && capacity > 0 && capacity <= 1000;
    return allValid && isCapacityValid;
  }
);

const hasChanges = createSelector(
  [getOldValues, getNewValues],
  (oldValues, newValues) => {
    const oldItems = omit('reason', oldValues);
    const newItems = omit('reason', newValues);
    return !isEqual(oldItems, newItems);
  }
);

export default {
  getFloor,
  getSector,
  getResourceSubType,
  getCapacity,
  getRoomEquipment,
  getRoomEquipmentOptions,
  getDaylight,
  getConfidentialMeetings,
  getNotes,
  getContact,
  getReason,
  isContactLoading,
  getContactSuggestions,
  getValues: getNewValues,
  hasChanges,
  isValid,
  isSubmitted,
  isSubmitting
};
