import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ls from 'local-storage';
import moment from 'moment';

import { i18nSelectors } from '../../../../../../state/i18n';
import { newsService } from '../../../../../../services';
import NewsBarBell from './NewsBarBell';
import NewsContent from './NewsContent';

import styles from './News.module.css';

const localStorageKey = 'newsSettings';
const localStorageCache = ls(localStorageKey) || {
  isOpen: true,
  lastViewedNewsTimestamp: 0,
  lastViewedNewsId: null
};

const localStorageItem = {
  isOpen: localStorageCache.isOpen,
  lastViewedNewsTimestamp: localStorageCache.lastViewedNewsTimestamp,
  lastViewedNewsId: localStorageCache.lastViewedNewsId
};

function News({ language }) {
  const [visible, setVisible] = useState(localStorageItem.isOpen);
  const [news, setNews] = useState([]);
  const [nextSkipCount, setNextSkipCount] = useState(0);
  const [totalCount, setTotalCount] = useState(Infinity);
  const [isLoadingNews, setIsLoadingNews] = useState(false);
  const pageSize = 5;

  useEffect(() => {
    async function fetchData() {
      setIsLoadingNews(true);
      const data = await newsService.getNews(pageSize);
      setNews(data.results);
      setNextSkipCount(data.nextSkipCount);
      setTotalCount(data.totalCount);
      setIsLoadingNews(false);

      if (data.results.length === 0) {
        setVisible(false);
        return;
      }

      const newestNews = data.results[0];
      const currentNewsTimestamp = moment(newestNews.startPublish).valueOf();
      const isNewerNews = (localStorageItem.lastViewedNewsTimestamp || null) < currentNewsTimestamp;
      const isNewNewsAtSameDay = (localStorageItem.lastViewedNewsTimestamp || null) === currentNewsTimestamp;
      const isLastViewedNews = localStorageItem.lastViewedNewsId !== newestNews.id;
      if (isNewerNews || (isNewNewsAtSameDay && isLastViewedNews)) {
        setVisible(true);
        localStorageItem.lastViewedNewsTimestamp = currentNewsTimestamp;
        localStorageItem.lastViewedNewsId = newestNews.id;
        ls(localStorageKey, localStorageItem);
      }
    }
    fetchData();
  }, []);

  const loadMoreNews = async () => {
    setIsLoadingNews(true);
    const data = await newsService.getNews(pageSize, nextSkipCount);
    setNews(news.concat(data.results));
    setNextSkipCount(data.nextSkipCount);
    setTotalCount(data.totalCount);
    setIsLoadingNews(false);
  };

  const toggleVisibility = () => {
    setVisible(!visible);
    localStorageItem.isOpen = !visible;
    ls(localStorageKey, localStorageItem);
  };

  return (
    <div className={styles.body}>
      <NewsContent
        open={visible}
        news={news}
        language={language}
        loadMore={loadMoreNews}
        totalCount={totalCount}
        nextSkipCount={nextSkipCount}
        isLoadingNews={isLoadingNews}
      />
      <div className={styles.sidebar}>
        <NewsBarBell open={visible} onClick={toggleVisibility} alerts={news.length} />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  language: i18nSelectors.getLanguage(state)
});

export default connect(
  mapStateToProps,
  null
)(News);

News.propTypes = {
  language: PropTypes.string.isRequired
};
