import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';
import moment from 'moment';

import { withTranslation } from '../../../../../common';

import styles from './NewsItem.module.css';

const allowedTypes = ['paragraph', 'emphasis', 'strong', 'link'];

function NewsItem({ title, text, date, translate }) {
  const dateText = moment(date).calendar(null, {
    sameDay: `[${translate('common.today')}]`,
    nextDay: `[${translate('common.tomorrow')}]`,
    nextWeek: 'dddd',
    lastDay: `[${translate('common.yesterday')}]`,
    lastWeek: `[${translate('common.last')}] dddd`,
    sameElse: 'DD.MM.YYYY'
  });
  return (
    <div className={styles.item}>
      {!!date && <div className={styles.dateText}>{dateText}</div>}
      {!!title && <div className={styles.header}>{title}</div>}
      {!!text && <Markdown skipHtml allowedTypes={allowedTypes} source={text} />}
    </div>
  );
}

export default withTranslation(NewsItem);

NewsItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  date: PropTypes.string,
  translate: PropTypes.func.isRequired
};
