import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { roomPickerSelectors, roomPickerOperations } from '../../../state/roomPicker';

import AutoComplete from '../Input/AutoComplete/AutoComplete';
import withTranslation from '../I18n/withTranslation';

class RoomPicker extends Component {
  onRoomPickerChange = event => {
    const {
      fetchRooms,
      updateSearchTerm,
      updateSelectedRooms,
      updateSuggestedRooms,
      selectedRooms,
      includePhotoIds,
      onChange
    } = this.props;
    updateSearchTerm(event.target.value);
    if (event.values !== selectedRooms) {
      updateSelectedRooms(event.values);
      if (onChange) {
        onChange(event);
      }
      if (includePhotoIds) {
        updateSuggestedRooms([]);
      }
      return;
    }
    fetchRooms(includePhotoIds);
  };

  render() {
    const {
      suggestedRooms,
      selectedRooms,
      searchTerm,
      multipleSelection,
      translate,
      isLoading
    } = this.props;

    const placeholder = translate('search.where.roomName');

    return (
      <div className="RoomPicker">
        <AutoComplete
          placeholder={placeholder}
          value={searchTerm}
          onChange={this.onRoomPickerChange}
          suggestions={suggestedRooms}
          selectedValues={selectedRooms}
          multipleSelection={multipleSelection}
          withTags={multipleSelection}
          showLoader
          loading={isLoading}
        />
      </div>
    );
  }
}

RoomPicker.propTypes = {
  suggestedRooms: PropTypes.arrayOf(PropTypes.shape()),
  selectedRooms: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  searchTerm: PropTypes.string,
  fetchRooms: PropTypes.func,
  updateSearchTerm: PropTypes.func,
  updateSelectedRooms: PropTypes.func,
  updateSuggestedRooms: PropTypes.func,
  multipleSelection: PropTypes.bool,
  includePhotoIds: PropTypes.bool,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

RoomPicker.defaultProps = {
  multipleSelection: true,
  includePhotoIds: false
};

const mapState = state => ({
  isLoading: roomPickerSelectors.isLoading(state),
  suggestedRooms: roomPickerSelectors.getSuggestedRooms(state),
  searchTerm: roomPickerSelectors.getSearchTerm(state)
});

const mapDispatch = dispatch => ({
  updateSearchTerm: searchTerm => dispatch(roomPickerOperations.updateSearchTerm(searchTerm)),
  updateSuggestedRooms: rooms => dispatch(roomPickerOperations.updateSuggestedRooms(rooms)),
  fetchRooms: includePhotoIds => dispatch(roomPickerOperations.fetchRooms(includePhotoIds))
});

export default connect(mapState, mapDispatch)(withTranslation(RoomPicker));
