const UPDATE_SEARCH_TERM = 'roomPickerState/UPDATE_SEARCH_TERM';
const UPDATE_SUGGESTED_ROOMS = 'roomPickerState/UPDATE_SUGGESTED_ROOMS';
const UPDATE_SELECTED_ROOMS = 'roomPickerState/UPDATE_SELECTED_ROOMS';
const UPDATE_IS_LOADING = 'roomPickerState/UPDATE_IS_LOADING';

export default {
  UPDATE_SEARCH_TERM,
  UPDATE_SUGGESTED_ROOMS,
  UPDATE_SELECTED_ROOMS,
  UPDATE_IS_LOADING
};
