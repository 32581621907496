import React from 'react';
import PropTypes from 'prop-types';
import { Translate, withLanguageSwitch } from '../common';

function LanguageSelectionItem(props) {
  const { selectedLanguage, className, switchLanguage } = props;
  return (
    <button
      aria-label="Language"
      className={className}
      key={selectedLanguage}
      type="button"
      onClick={() => switchLanguage(selectedLanguage)}
    >
      <Translate path={`language.${selectedLanguage}`} />
    </button>
  );
}

LanguageSelectionItem.propTypes = {
  className: PropTypes.string,
  selectedLanguage: PropTypes.string.isRequired,
  switchLanguage: PropTypes.func.isRequired
};

export default withLanguageSwitch(LanguageSelectionItem);
