import React from 'react';
import PropTypes from 'prop-types';
import RoomSizeBox from './RoomSizeBox';

import { withTranslation } from '../common';

function RoomFilterSize({ capacities, onChange, translate }) {
  const classes = 'SizeBox';

  const renderBox = capacity => (
    <RoomSizeBox
      key={capacity.key}
      className={classes}
      text={capacity.description}
      selected={capacity.enabled}
      onClick={() => onChange(capacity)}
    />
  );

  return (
    <div className="RoomFilter__Size">
      <div className="RoomFilter__Title">{translate('roomFilter.capacityTitle')}</div>
      <div className="RoomFilter__Section RoomFilter__Section--person">
        <div className="RoomFilter__Content RoomSizeBoxes">
          {capacities.map(capacity => renderBox(capacity))}
        </div>
      </div>
    </div>
  );
}

RoomFilterSize.propTypes = {
  capacities: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RoomFilterSize);
