import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { authSelectors } from '../../../state/auth';
import { photoAdminSelectors, photoAdminOperations } from '../../../state/photoAdmin';
import { withTranslation, RoomPicker, EmptyState, Icon } from '../../common';
import Header from '../../Header';

import './_styles.css';
import PhotoAdminItem from './PhotoAdminItem';
import PhotoAdminUpload from './PhotoAdminUpload';

class PhotoAdmin extends Component {
  componentDidMount() {
    const { isAdmin, history } = this.props;
    if (!isAdmin) {
      history.replace('/unauthorized');
    }
  }

  renderHeader = () => {
    const { history, translate } = this.props;
    const title = translate('menu.photoAdmin');
    return <Header onClose={history.goBack} title={title} iconRight icon="022-close" />;
  };

  renderRoomPicker = () => {
    const { updateRoom, selectedRoom, translate } = this.props;
    const selectedRoomsArray = selectedRoom ? [selectedRoom] : [];

    return (
      <>
        <RoomPicker
          onChange={this.onChange}
          selectedRooms={selectedRoomsArray}
          multipleSelection={false}
          includePhotoIds
          updateSelectedRooms={rooms => updateRoom(rooms[0])}
        />
        <div className="PhotoAdmin__Info">
          <Icon icon="046-information-circle" />
          <span>{translate('admin.photos.infoText')}</span>
        </div>
      </>
    );
  };

  renderImages = () => {
    const { selectedRoom, removePhoto, loadingPhotos, translate } = this.props;
    const { photoIds } = selectedRoom;
    const photos = photoIds.map(id => (
      <PhotoAdminItem
        key={id}
        photoId={id}
        removePhoto={removePhoto}
        isLoading={loadingPhotos.includes(id)}
      />
    ));

    if (photos.length === 0) {
      return (
        <div className="PhotoAdmin__Items">
          <EmptyState centered visible content={translate('admin.photos.emptyState')} />
        </div>
      );
    }
    return <div className="PhotoAdmin__Items">{photos}</div>;
  };

  renderBody = () => {
    const { selectedRoom, uploadPhoto, isLoading, translate } = this.props;
    if (!selectedRoom) {
      return null;
    }

    const maxSize = translate('admin.photos.maxSize');

    return (
      <div className="PhotoAdmin__Content">
        <div className="PhotoAdmin__Upload">
          <PhotoAdminUpload uploadPhoto={uploadPhoto} isLoading={isLoading} />
          <div className="PhotoAdmin__MaxSize">
            <Icon icon="046-information-circle" />
            <span>{maxSize}</span>
          </div>
        </div>
        <hr className="PhotoAdmin__Divider" />
        {this.renderImages()}
      </div>
    );
  };

  render() {
    return (
      <div className="PhotoAdmin">
        {this.renderHeader()}
        <div className="PhotoAdmin__RoomPicker">{this.renderRoomPicker()}</div>
        <hr className="PhotoAdmin__Divider" />
        {this.renderBody()}
      </div>
    );
  }
}

PhotoAdmin.propTypes = {
  isAdmin: PropTypes.bool,
  selectedRoom: PropTypes.shape(),
  updateRoom: PropTypes.func.isRequired,
  uploadPhoto: PropTypes.func.isRequired,
  removePhoto: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadingPhotos: PropTypes.arrayOf(PropTypes.string).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

PhotoAdmin.defaultProps = {
  isAdmin: false
};

const mapState = state => ({
  isAdmin: authSelectors.isAdministrator(state),
  selectedRoom: photoAdminSelectors.getSelectedRoom(state),
  isLoading: photoAdminSelectors.isLoading(state),
  loadingPhotos: photoAdminSelectors.getLoadingPhotos(state)
});

const mapDispatch = dispatch => ({
  updateRoom: room => dispatch(photoAdminOperations.updateRoom(room)),
  uploadPhoto: formData => dispatch(photoAdminOperations.uploadPhoto(formData)),
  removePhoto: photoId => dispatch(photoAdminOperations.removePhoto(photoId))
});

export default connect(
  mapState,
  mapDispatch
)(withTranslation(withRouter(PhotoAdmin)));
