import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation, HelpButton } from '../../common';

import './_style.css';

class MyChangeRequestsHelp extends Component {
  renderInfo = () => {
    const { translate } = this.props;
    const detail = translate('changeRequests.my.empty.detail');
    const detailLink = translate('changeRequests.my.empty.detailLink');

    return (
      <div className="MyChangeRequestsHelpInfo">
        <div className="MyChangeRequestsHelpInfo__Section">{detail}</div>
        <div>{detailLink}</div>
      </div>
    );
  };

  render() {
    const content = this.renderInfo();
    return <HelpButton className="Header__Item Header__Item--right" content={content} />;
  }
}

MyChangeRequestsHelp.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslation(MyChangeRequestsHelp);
