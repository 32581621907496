/* eslint-disable max-len */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TimeLine from './TimeLine';
import CalendarLoader from './CalendarLoader';
import CalendarEmptyState from './CalendarEmptyState';
import CalendarBookingTooltip from './CalendarBookingTooltip';
import LoadMoreButton from './LoadMoreButton';
import { searchSelectors } from '../../../../../state/search';
import {
  timelineBookingsSelectors,
  timelineBookingsOperations
} from '../../../../../state/timelineBookings';
import { modalOperations } from '../../../../../state/modal';
import { timelineSelectors, timelineOperations } from '../../../../../state/timeline';
import { bookOperations } from '../../../../../state/book';
import { emitter, toDate } from '../../../../../util';
import modalKeys from '../../../../BookModal/modalKeys';

import {
  BookDetailModal,
  BookCreateModal,
  BookUpdateModal,
  RecurrenceDetailModal,
  RecurrenceUpdateModal,
  RecurrenceDeleteDialog,
  RecurrencePatternModal,
  RecurrenceExceptionDeleteModal,
  RecurrenceExceptionModal
} from '../../../../BookModal';

import styles from './Calendar.module.css';

const getType = param => (param.includes('weekly') ? 'week' : 'day');

function Calendar(props) {
  const {
    groups,
    isLoading,
    isLoadingMore,
    hasMoreRooms,
    totalCount,
    loadBookings,
    loadMoreBookings,
    refreshBookings,
    createBooking,
    deleteBooking,
    updateCalendarBooking,
    openBookingCollectionDialog,
    openRecurrenceDeleteDialog,
    updateMaster,
    deleteMaster,
    match,
    compactModeEnabled
  } = props;

  useEffect(() => {
    loadBookings();
  }, [match, loadBookings]);

  useEffect(() => {
    const subscription = emitter.addListener('onSearchFilterChanged', () => {
      loadBookings();
    });

    return () => {
      subscription.remove();
    };
  }, [loadBookings]);

  const showTimeline = groups && groups.length > 0;

  return (
    <div className={styles.wrapper}>
      <CalendarBookingTooltip />

      {!isLoading && showTimeline && (
        <>
          <TimeLine type={getType(match.path)} {...props} />
          {hasMoreRooms && (
            <LoadMoreButton
              onClick={loadMoreBookings}
              isSubmitting={isLoadingMore}
              totalCount={totalCount}
              currentCount={groups.length}
              compactMode={compactModeEnabled}
            />
          )}
        </>
      )}
      <CalendarEmptyState />
      <CalendarLoader visible={isLoading} />

      <BookDetailModal deleteBooking={deleteBooking} deleteMaster={deleteMaster} />
      <BookUpdateModal updateBooking={updateCalendarBooking} />
      <BookCreateModal
        createBooking={createBooking}
        openBookingCollectionDialog={openBookingCollectionDialog}
      />
      <RecurrencePatternModal />
      <RecurrenceExceptionModal />
      <RecurrenceDetailModal confirmDeletion={openRecurrenceDeleteDialog} />
      <RecurrenceUpdateModal updateMaster={updateMaster} />
      <RecurrenceDeleteDialog deleteMaster={deleteMaster} />

      <RecurrenceExceptionDeleteModal deleteCallback={refreshBookings} />
    </div>
  );
}

const mapStateToProps = state => ({
  date: toDate(searchSelectors.getDate(state)),
  items: timelineBookingsSelectors.getBookingItems(state),
  groups: timelineBookingsSelectors.getRoomGroups(state),
  isLoading: timelineBookingsSelectors.isLoading(state),
  isLoadingMore: timelineBookingsSelectors.isLoadingMore(state),
  hasMoreRooms: timelineBookingsSelectors.hasMoreRooms(state),
  totalCount: timelineBookingsSelectors.getTotalCount(state),
  compactModeEnabled: timelineSelectors.isCompactModeEnabled(state)
});

const mapDispatchToProps = dispatch => ({
  loadBookings: () => dispatch(timelineBookingsOperations.loadRoomBookings()),
  loadMoreBookings: () => dispatch(timelineBookingsOperations.loadMoreRoomBookings()),
  refreshBookings: () => dispatch(timelineBookingsOperations.refreshRoomBookings()),
  setDirty: () => dispatch(timelineOperations.setDirty()),
  updateDate: date => dispatch(bookOperations.updateDate(date)),
  updateTime: time => dispatch(bookOperations.updateTime(time)),
  createBooking: () => dispatch(timelineOperations.createBooking()),
  updateBooking: booking => dispatch(bookOperations.updateBooking(booking)),
  deleteBooking: (id, calendarId, resourceId) => dispatch(timelineOperations.deleteBooking(id, calendarId, resourceId)),
  updateMaster: () => dispatch(timelineOperations.updateMaster()),
  deleteMaster: (id, calendarId) => dispatch(timelineOperations.deleteMaster(id, calendarId)),
  updateCalendarBooking: () => dispatch(timelineOperations.updateBooking()),
  addStateBooking: booking => dispatch(timelineBookingsOperations.addBookings(booking)),
  updateStateBooking: bookings => dispatch(timelineBookingsOperations.updateBooking(bookings)),
  removeStateBooking: (calendarId, resourceId) => dispatch(timelineBookingsOperations.removeBooking(calendarId, resourceId)),
  cacheBookingsState: () => dispatch(timelineBookingsOperations.cacheBookings()),
  resetBookingsStateFromCache: () => dispatch(timelineBookingsOperations.resetBookingsFromCache()),
  openCreateModal: room => dispatch(bookOperations.loadEmptyBooking(room, modalKeys.BOOK_CREATE_MODAL)),
  openRecurrenceDeleteDialog: () => dispatch(modalOperations.pushModal(modalKeys.RECURRENCE_DELETE_DIALOG)),
  openBookingCollectionDialog: () => dispatch(modalOperations.pushModal(modalKeys.BOOKING_COLLECTION_INFORMATION_DIALOG))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Calendar));

Calendar.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool.isRequired,
  hasMoreRooms: PropTypes.bool.isRequired,
  totalCount: PropTypes.number.isRequired,
  loadBookings: PropTypes.func.isRequired,
  loadMoreBookings: PropTypes.func.isRequired,
  refreshBookings: PropTypes.func.isRequired,
  createBooking: PropTypes.func.isRequired,
  deleteBooking: PropTypes.func.isRequired,
  updateCalendarBooking: PropTypes.func.isRequired,
  openBookingCollectionDialog: PropTypes.func.isRequired,
  openRecurrenceDeleteDialog: PropTypes.func.isRequired,
  updateMaster: PropTypes.func.isRequired,
  deleteMaster: PropTypes.func.isRequired,
  match: PropTypes.shape({
    path: PropTypes.string.isRequired
  }),
  compactModeEnabled: PropTypes.bool.isRequired
};
