import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ResourcesSubTypes from '../ResourcesSubTypes/ResourcesSubTypes';
import Buildings from '../Buildings/Buildings';
import Regions from '../Regions/Regions';
import Config from '../Config/Config';

function Content() {
  return (
    <Switch>
      <Route exact path="/admin/settings/config" component={Config} />
      <Route exact path="/admin/settings/regions" component={Regions} />
      <Route exact path="/admin/settings/buildings" component={Buildings} />
      <Route exact path="/admin/settings/resourcesSubTypes" component={ResourcesSubTypes} />
      <Redirect from="/admin/settings/" to="/admin/settings/config" />
    </Switch>
  );
}

export default Content;
