import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CustomPropTypes, RoomType } from '../../../../common';

import './_style.css';

function RoomNameAndType({ resource, alternativeType }) {
  const { resourceSubType, displayName } = resource;

  const classes = cx('RoomNameAndType__Room', {
    'RoomNameAndType__Room--warning': alternativeType === 'sector' || alternativeType === 'floor'
  });

  return (
    <div>
      <div className={classes}>
        <span className="RoomNameAndType__RoomName">{displayName}</span>
      </div>
      <br />
      <p>
        <RoomType resourceSubType={resourceSubType} />
      </p>
    </div>
  );
}

RoomNameAndType.propTypes = {
  resource: CustomPropTypes.resource,
  alternativeType: PropTypes.string
};

RoomNameAndType.defaultProps = {
  alternativeType: ''
};

export default RoomNameAndType;
