import React, { Component } from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/fp/values';
import moment from 'moment';

import { Button, InlineLoader, LinkButton, EmptyState, withTranslation } from '../common';
import MyBookingsLoader from './MyBookingsLoader';
import MyBookingsDate from './MyBookingsDate';
import MyBooking from './MyBooking';

import './_style.css';

class MyBookings extends Component {
  getPreposition = () => {
    const locale = moment.locale();
    if (locale !== 'fr' && locale !== 'it') {
      return undefined;
    }

    const { currentMonthIndex } = this.props;
    const isSpecialMonth = index => index === 3 || index === 7 || index === 9;
    if (locale === 'fr') {
      return isSpecialMonth(currentMonthIndex) ? "d'" : 'de ';
    }

    return isSpecialMonth(currentMonthIndex) ? "d'" : 'di ';
  };

  createDailyRows = bookingsByDay => Object.keys(bookingsByDay).reduce((allRows, date) => {
    const titleRow = { type: 'day', value: date, key: date };
    const bookings = bookingsByDay[date].map(booking => ({
      type: 'myBooking',
      value: booking,
      key: booking.globalId + booking.calendarId
    }));
    return [...allRows, titleRow, ...bookings];
  }, []);

  createEmptyMonthRow = ({ month, year }) => {
    const { translate } = this.props;
    const text = translate('myBookings.noBookings');
    const title = `${month} ${year} - ${text}`;
    return { type: 'month', value: title, key: year + month };
  };

  createRows = bookingsByMonth => {
    const months = values(bookingsByMonth);
    return months.reduce((allRows, currentMonth) => {
      const hasBookings = Object.keys(currentMonth.bookings).length > 0;
      return hasBookings
        ? [...allRows, ...this.createDailyRows(currentMonth.bookings)]
        : [...allRows, this.createEmptyMonthRow(currentMonth)];
    }, []);
  };

  renderInlineLoader = () => {
    const { translate, nextMonth } = this.props;
    const text = translate('myBookings.loading', { month: nextMonth });

    return (
      <InlineLoader
        wrapperClassName="MyBookingsInlineLoader"
        className="MyBookingsInlineLoader__Loader"
        text={text}
      />
    );
  };

  renderLoadNextMonth = () => {
    const { nextMonth, hasMore, loadBookings, translate } = this.props;
    if (!hasMore) return null;
    const label = translate('myBookings.loadNextMonth', { month: nextMonth });
    return (
      <LinkButton label={label} className="MyBookings__LoadNextMonth" onClick={loadBookings} />
    );
  };

  renderEmptyState = () => {
    const { currentMonth, nextMonth, hasMore, loadBookings, translate } = this.props;

    const preposition = this.getPreposition();
    const text = translate('myBookings.emptyState', {
      preposition,
      month: currentMonth
    });
    const buttonLabel = translate('myBookings.loadNextMonth', { month: nextMonth });
    const content = (
      <div>
        <div className="MyBookings__EmptyStateText">{text}</div>
        <div>
          {hasMore && (
            <Button
              className="MyBookings__EmptyStateButton"
              onClick={loadBookings}
              label={buttonLabel}
            />
          )}
        </div>
      </div>
    );

    const props = {
      content,
      visible: true,
      centered: true,
      className: 'MyBookings__EmptyState'
    };

    return <EmptyState {...props} />;
  };

  renderRow = row => {
    const { openBookingModal, openMasterModal } = this.props;
    const isMonthRow = row.type === 'month';
    const isDayRow = row.type === 'day';

    const renderDayRow = () => <MyBookingsDate date={row.value} key={row.key} />;

    const renderMonthRow = () => (
      <div className="MyBookingsMonth" key={row.key}>
        {row.value}
      </div>
    );

    const renderMyBooking = () => (
      <MyBooking
        booking={row.value}
        onOpenOccurrence={openBookingModal}
        onOpenMaster={openMasterModal}
        key={row.key}
      />
    );

    if (isMonthRow) {
      return renderMonthRow();
    }
    if (isDayRow) {
      return renderDayRow();
    }
    return renderMyBooking();
  };

  render() {
    const { myBookingsByMonth, isLoading, hasBookings } = this.props;
    const rows = this.createRows(myBookingsByMonth);

    const showInitialLoader = !hasBookings && isLoading;
    const showInlineLoader = hasBookings && isLoading;
    const showEmptyState = !hasBookings && !isLoading;
    const showLoadNextButton = hasBookings && !isLoading;

    return (
      <div className="MyBookings__Results">
        <MyBookingsLoader visible={showInitialLoader} />
        {showEmptyState && this.renderEmptyState()}
        {hasBookings && rows.map(this.renderRow)}
        {showLoadNextButton && this.renderLoadNextMonth()}
        {showInlineLoader && this.renderInlineLoader()}
      </div>
    );
  }
}

MyBookings.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  hasBookings: PropTypes.bool.isRequired,
  currentMonth: PropTypes.string.isRequired,
  currentMonthIndex: PropTypes.number.isRequired,
  nextMonth: PropTypes.string.isRequired,
  myBookingsByMonth: PropTypes.shape(),
  loadBookings: PropTypes.func.isRequired,
  openBookingModal: PropTypes.func.isRequired,
  openMasterModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(MyBookings);
