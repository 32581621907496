import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RecurrenceSwitch from './RecurrenceSwitch';
import RecurrenceWeekly from './RecurrenceWeekly';
import RecurrenceMonthly from './RecurrenceMonthly';
import RecurrenceEnd from './RecurrenceEnd';
import { recurrenceSelectors } from '../../state/recurrence';

function Recurrence({ type }) {
  return (
    <div>
      <RecurrenceSwitch />
      {type === 'weekly' && <RecurrenceWeekly />}
      {type === 'monthly' && <RecurrenceMonthly />}
      {type !== 'never' && <RecurrenceEnd />}
    </div>
  );
}

const mapStateToProps = state => ({ type: recurrenceSelectors.getRecurrenceType(state) });

Recurrence.propTypes = { type: PropTypes.string.isRequired };

export default connect(mapStateToProps)(Recurrence);
