import React from 'react';

import { Loader, CustomPropTypes, Translate } from '../../../../common';

import './_style.css';

function RecurrenceAvailability({ resource }) {
  const availability = `${resource.numberOfAvailabilities}/${resource.numberOfAvailabilities
    + resource.numberOfNonAvailabilities}`;

  return (
    <div className="RecurrenceAvailability">
      <Loader visible={!resource.availabilitiesLoaded} size="small" />
      {resource.availabilitiesLoaded && (
        <p>
          <Translate path="search.recurrence.availability" availability={availability} />
        </p>
      )}
    </div>
  );
}

RecurrenceAvailability.propTypes = {
  resource: CustomPropTypes.resource
};

export default RecurrenceAvailability;
