import React from 'react';
import PropTypes from 'prop-types';

import {
  Tooltip,
  RoomType,
  Profile2,
  Profile3,
  CustomPropTypes,
  ResourceImages
} from '../../../../../common';

import { getProfile } from '../../../../../../util';

function CalendarRoomTooltip({ id, active, room }) {
  if (!active) {
    return null;
  }

  const { roomEquipment, building, resourceSubType, typeDisplayName, photoIds } = room;
  const equipment = roomEquipment.map(e => e.name).join(', ');
  const profile = getProfile(room);

  const isProfile = profile > 1;

  return (
    <Tooltip effect="solid" className="RoomTooltip" id={id}>
      <div className="CalendarRoomTooltip">
        {photoIds && photoIds.length > 0 && (
          <div className="CalendarRoomTooltip__Images">
            <ResourceImages resource={room} showArrows={false} autoPlay small width={300} />
          </div>
        )}
        <div className="CalendarRoomTooltip__Body">
          <div className="CalendarRoomTooltip__Header">
            <span className="CalendarRoomTooltip__Location">
              <span>{room.name}</span>
              <span>, </span>
              <span className="CalendarRoomTooltip__LocationKey">{building.key}</span>
            </span>
          </div>
          <span className="CalendarRoomTooltip__Equipment">{equipment}</span>
          <div>
            <RoomType resourceSubType={resourceSubType} roomTypeDisplayName={typeDisplayName} />
          </div>
        </div>
        {isProfile && (
          <div className="CalendarRoomTooltip__Footer">
            <div className="CalendarRoomTooltip__ManagedBy">
              {profile === 3 && <Profile3 resource={room} book />}
              {profile === 2 && <Profile2 resource={room} />}
            </div>
          </div>
        )}
      </div>
    </Tooltip>
  );
}

CalendarRoomTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  room: CustomPropTypes.room
};

export default CalendarRoomTooltip;
