/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { styles, lineStyles } from './styles';

function TelepresenceIcon({ disabled, ...otherProps }) {
  const styles1 = {
    fill: 'none',
    stroke: '#000',
    strokeMiterlimit: '10'
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...otherProps} style={styles}>
      <path
        style={styles1}
        d="M13,5.25h0A1.32,1.32,0,0,1,11.7,4h0A1.32,1.32,0,0,1,13,2.65h0A1.32,1.32,0,0,1,14.3,4h0A1.32,1.32,0,0,1,13,5.25Z"
      />
      <path d="M23,6.35H3a3,3,0,0,0-3,3v14a1.08,1.08,0,0,0,1,1H12v2H7a1,1,0,1,0,0,2H19a1,1,0,0,0,0-2H14v-2H25a1.08,1.08,0,0,0,1-1v-14A3.08,3.08,0,0,0,23,6.35Zm1,16H18.88A3.65,3.65,0,0,0,15.2,19H10.9a3.79,3.79,0,0,0-3.76,3.4H2v-13a.94.94,0,0,1,.88-1H23a1,1,0,0,1,1,1Z" />
      <path d="M13,10.35a3.8,3.8,0,0,0-3.8,3.8h0a3.71,3.71,0,0,0,3.72,3.7H13a3.8,3.8,0,0,0,3.8-3.8,3.71,3.71,0,0,0-3.72-3.7Z" />
      {disabled && <line style={lineStyles} x1="4.57" y1="1.5" x2="21.87" y2="29.01" />}
    </svg>
  );
}

TelepresenceIcon.propTypes = {
  disabled: PropTypes.bool
};

export default TelepresenceIcon;
