import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Measure from 'react-measure';

import Translate from '../I18n/Translate';

import './_style.css';

class CollapsibleText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsable: false,
      collapsed: true
    };
  }

  onResize = ({ client: { height: clientHeight } }) => {
    const { height } = this.props;
    if (clientHeight > height) {
      this.setState({ collapsable: true });
    }
  };

  expand = () => {
    this.setState({ collapsed: false });
  };

  collapse = () => {
    this.setState({ collapsed: true });
  };

  render() {
    const { text, height, className } = this.props;
    const { collapsable, collapsed } = this.state;

    const showMore = collapsable && collapsed;
    const showLess = collapsable && !collapsed;

    const props = {
      className: cx('CollapsibleText', className),
      role: 'presentation',
      style: collapsable && collapsed ? { height } : undefined
    };

    return (
      <Measure client onResize={this.onResize}>
        {({ measureRef }) => (
          <>
            <div {...props} ref={measureRef}>
              {text}
            </div>
            {showMore && (
              <button aria-label="Show more" className="link" onClick={this.expand} type="button">
                <Translate path="common.showMore" />
              </button>
            )}
            {showLess && (
              <button aria-label="Show less" className="link" onClick={this.collapse} type="button">
                <Translate path="common.showLess" />
              </button>
            )}
          </>
        )}
      </Measure>
    );
  }
}

CollapsibleText.propTypes = {
  text: PropTypes.string.isRequired,
  height: PropTypes.number,
  className: PropTypes.string
};

CollapsibleText.defaultProps = {
  height: 72
};

export default CollapsibleText;
