import React from 'react';
import PropTypes from 'prop-types';
import Highlight from './Highlight';
import { withTranslation } from '..';

import './MenuLabel.css';

function MenuLabel({ location, inputValue, translate }) {
  const { type, name, buildingKey, city } = location;

  switch (type) {
    case 'Region': {
      return (
        <div className="DropDown__option--region">
          <Highlight word={inputValue} text={name} />
        </div>
      );
    }

    case 'Building': {
      return (
        <div className="DropDown__option--building">
          <Highlight word={inputValue} text={`${city}, ${name}`} />
        </div>
      );
    }

    case 'Room': {
      return (
        <div className="DropDown__option--room">
          <Highlight word={inputValue} text={name} />
          {', '}
          <span className="DropDown__option--light">
            <Highlight word={inputValue} text={buildingKey} />
          </span>
        </div>
      );
    }

    case 'Floor':
    case 'Sector': {
      return (
        <div className={`DropDown__option--${type.toLowerCase()}`}>
          <Highlight word={inputValue} text={translate(`common.${type.toLowerCase()}`, { name })} />
          {', '}
          <span className="DropDown__option--light">
            <Highlight word={inputValue} text={buildingKey} />
          </span>
        </div>
      );
    }

    default:
      return null;
  }
}

export default withTranslation(MenuLabel);

MenuLabel.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    buildingKey: PropTypes.any,
    city: PropTypes.any
  }),
  inputValue: PropTypes.string,
  translate: PropTypes.func.isRequired
};
