import actions from './actions';

const { changeFilter, toggleFilter, resetDirty, resetFilter, updateEquipmentTypes } = actions;

export default {
  changeFilter,
  toggleFilter,
  resetDirty,
  resetFilter,
  updateEquipmentTypes
};
