const devices = ['iPad', 'iPhone', 'iPod'];

const { platform } = navigator;

const iOS = () => {
  if (!platform) {
    return false;
  }

  return devices.reduce((iOSDetected, device) => (iOSDetected ? true : platform === device), false);
};

export default iOS;
