import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button, withTranslation } from '../../../../common';

import '../../_style.css';

function AddResourceNodeButton({ translate, actionOnClick }) {
  const label = translate('admin.settings.floors.map.addResourceNodeButtonLabel');
  return (
    <Button
      icon="054-new"
      label={label}
      onClick={actionOnClick} />
  );
}

AddResourceNodeButton.propTypes = {
  translate: PropTypes.func.isRequired,
  actionOnClick: PropTypes.func.isRequired
};

export default withTranslation(withRouter(AddResourceNodeButton));
