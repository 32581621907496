/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { styles, lineStyles } from './styles';

function SmartphoneIcon({ disabled, ...otherProps }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.000000 30.000000" {...otherProps} style={styles}>
      <g transform="translate(-0.500000,25.500000) scale(0.030,-0.030)">
        <path d="M716.8 795.7333333333333H307.2C250.88 795.7333333333333 204.8 749.6533333333333 204.8 693.3333333333334V10.6666666666666C204.8 -45.6533333333333 250.88 -91.7333333333333 307.2 -91.7333333333333H716.8C773.1199999999999 -91.7333333333333 819.2 -45.6533333333333 819.2 10.6666666666666V693.3333333333334C819.2 749.6533333333333 773.1199999999999 795.7333333333333 716.8 795.7333333333333zM750.9333333333333 10.6666666666666C750.9333333333333 -8.1066666666667 735.5733333333334 -23.4666666666667 716.8 -23.4666666666667H307.2C288.4266666666666 -23.4666666666667 273.0666666666667 -8.1066666666667 273.0666666666667 10.6666666666666V693.3333333333334C273.0666666666667 712.1066666666667 288.4266666666666 727.4666666666667 307.2 727.4666666666667H716.8C735.5733333333334 727.4666666666667 750.9333333333333 712.1066666666667 750.9333333333333 693.3333333333334V10.6666666666666zM341.3333333333333 659.2H682.6666666666666V181.3333333333334H341.3333333333333V659.2zM614.4 78.9333333333334C614.4 60.16 599.04 44.8 580.2666666666667 44.8H443.7333333333334C424.96 44.8 409.6 60.16 409.6 78.9333333333334S424.96 113.0666666666667 443.7333333333334 113.0666666666667H580.2666666666667C599.04 113.0666666666667 614.4 97.7066666666667 614.4 78.9333333333334z" fill="#333" />
      </g>
      {disabled && <line style={lineStyles} x1="3.45" y1="2.5" x2="24.75" y2="29.01" />}
    </svg>
  );
}

SmartphoneIcon.propTypes = {
    disabled: PropTypes.bool
};

export default SmartphoneIcon;
