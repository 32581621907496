import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ChromePicker } from 'react-color';
import { authSelectors } from '../../../../state/auth';
import {
  configSelectors,
  configOperations
} from '../../../../state/config';
import { withTranslation, Spacing, Button } from '../../../common';
import ConfigLoader from './ConfigLoader';

class Config extends Component {
  static referenceFunction = div => {
    if (div) {
      div.focus();
    }
  };

  constructor(props) {
    super(props);
    const { config } = this.props;

    this.state = {
      id: config?.id,
      displayColorPicker: false,
      themeColor: config == null ? '#0060AE' : config.themeColor
    };
  }

  componentDidMount() {
    const { loadConfig, isAdmin, history } = this.props;
    if (!isAdmin) {
      history.replace('/unauthorized');
    }
    loadConfig();
  }

  componentDidUpdate() {
    const { id } = this.state;
    const { config } = this.props;
    if (config && config.id && !id) {
      this.setState({
        id: config.id
      });
    }
  }

  handleClick = () => {
    const { displayColorPickerState } = this.state;
    this.setState({ displayColorPicker: !displayColorPickerState });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  renderLoader = () => {
    const { isLoaded } = this.props;
    return <ConfigLoader visible={!isLoaded} />;
  };

  renderThemeColor = () => {
    const { isLoaded, translate } = this.props;
    const { themeColor, displayColorPicker } = this.state;

    const showConfig = isLoaded;

    if (!showConfig) {
      return null;
    }

    return (
      <>
        <label htmlFor="ColorPickerButton">
          {`${ translate('admin.settings.config.colorPickerLabel') }
         ${ translate('common.requiredField') }`}
        </label>
        <br />
        <div className="ColorPickerContainer">
          <input
            id="ColorPickerButton"
            type="button"
            onClick={this.handleClick}
            className="BlockColorPicker"
            style={{
              backgroundColor: `${ themeColor }`
            }}
          />
        </div>
        {displayColorPicker
          && (
            <div
              className="ColorPicker"
              ref={this.referenceFunction}
              onMouseLeave={this.handleClose}
              onBlur={this.handleClose}>
              <ChromePicker
                color={themeColor}
                display={displayColorPicker}
                onChange={color => {
                  this.setState({ themeColor: color.hex });
                }}
              />
            </div>
          )}
      </>
    );
  };

  renderSubmitButton = () => {
    const { translate, submit } = this.props;
    const { id, themeColor } = this.state;
    const isButtonDisabled = themeColor === '';

    const onClick = async event => {
      event.preventDefault();
      event.stopPropagation();

      await submit(id, themeColor);
    };

    return (
      <div className="button--right">
        <Button
          icon="074-save"
          label={translate('common.save')}
          onClick={onClick}
          disabled={isButtonDisabled}
          data-testid="theme-color-save"
        />
      </div>
    );
  };

  render() {
    const { translate } = this.props;

    return (
      <Spacing horizontal="1" vertical="1">
        <div className="Settings__TableHead">
          <h3 className="Settings__Title">
            {translate('admin.settings.config.table.title')}
          </h3>
        </div>
        {this.renderLoader()}
        <div className="Create__Form" style={{ marginTop: 0 }}>
          {this.renderThemeColor()}
          <div className="button--right--container">
            {this.renderSubmitButton()}
          </div>
        </div>
      </Spacing>
    );
  }
}

Config.defaultProps = {
  isAdmin: false
};

const mapStateToProps = state => ({
  isLoaded: configSelectors.isLoaded(state),
  config: configSelectors.getConfig(state),
  isAdmin: authSelectors.isAdministrator(state)
});

const mapDispatchToProps = dispatch => ({
  loadConfig: () => dispatch(configOperations.getConfig()),
  submit: (id, themeColor) => dispatch(configOperations.updateConfig(id, themeColor))
});

Config.propTypes = {
  isAdmin: PropTypes.bool,
  isLoaded: PropTypes.bool,
  config: PropTypes.objectOf(PropTypes.any),
  loadConfig: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  submit: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(withRouter(Config)));
