import actions from '../actions';
import selectors from '../selectors';
import { roomFilterSelectors } from '../../roomFilter';
import { notificationsSelectors, notificationsOperations } from '../../notifications';
import { alternativesService } from '../../../services';
import mapCapacity from './mapCapacity';

const pageSize = 4;

const createPayload = state => {
  const capacityFilters = roomFilterSelectors.getCapacityFilters(state);
  const resource = selectors.getResource(state);

  const { start, end } = selectors.getException(state);
  const { buildingId, capacity, resourceType, roomEquipment, equipmentType, roomType } = resource;
  const locations = { buildingIds: [buildingId] };

  const mapEquipment = item => item.roomEquipmentType;

  return {
    start,
    end,
    exactTime: true,
    locations,
    roomTypes: [roomType],
    capacities: mapCapacity(capacityFilters, capacity),
    equipment: roomEquipment ? roomEquipment.map(mapEquipment) : [],
    equipmentTypes: equipmentType ? [equipmentType] : [],
    resourceProfiles: ['allUsersAllowed'],
    recurrence: false,
    resourceTypes: [resourceType],
    pageSize,
    skipCount: 0
  };
};

const loadAlternativeResources = (dispatch, state) => (section, payload) => {
  dispatch(actions.updateIsLoading(section, true));
  return alternativesService
    .post(payload, section)
    .then(response => {
      const { totalCount, nextSkipCount } = response;
      const hasMore = totalCount > nextSkipCount;
      dispatch(actions.updateHasMoreResults(section, hasMore));
      dispatch(actions.updateSkipCount(section, nextSkipCount));
      dispatch(
        payload.skipCount === 0
          ? actions.updateAvailability(section, response.results)
          : actions.addAvailability(section, response.results)
      );
    })
    .catch(serverError => {
      const getNotification = notificationsSelectors.getNotification(state);
      const error = getNotification('search.load.error', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => {
      dispatch(actions.updateIsLoading(section, false));
    });
};

const loadAlternatives = () => (dispatch, getState) => {
  const state = getState();
  const load = loadAlternativeResources(dispatch, state);

  const { resourceType } = selectors.getResource(state);
  if (resourceType === 'Equipment') {
    return Promise.resolve();
  }

  const payload = createPayload(state);
  payload.skipCount = 0;
  payload.pageSize = pageSize;

  const filterSections = section => section !== 'sector' && section !== 'floor';
  const loadSection = section => {
    dispatch(actions.updateAvailability(section, []));
    dispatch(actions.updateSkipCount(section, 0));
    return load(section, payload);
  };

  const sections = selectors.getSectionNames(state);
  const promises = sections.filter(filterSections).map(loadSection);
  return Promise.all(promises);
};

const loadMoreAlternatives = section => (dispatch, getState) => {
  const state = getState();
  const load = loadAlternativeResources(dispatch, state);

  const hasMore = selectors.getHasMore(section)(state);
  if (!hasMore) {
    return Promise.reject(new Error('no more results available'));
  }

  const nextSkipCount = selectors.getSkipCount(section)(state);

  const payload = createPayload(state);
  payload.skipCount = nextSkipCount;
  payload.pageSize = pageSize;
  return load(section, payload);
};

export default {
  loadAlternatives,
  loadMoreAlternatives
};
