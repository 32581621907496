import types from '../types';

const initialState = {
  resource: null,
  exception: null,
  exceptions: [],
  isInProgress: false
};

const isSameException = (exception, other) => {
  if (exception && other) {
    return exception.calendarId && other.calendarId
      ? exception.calendarId === other.calendarId
      : exception.start === other.start && exception.end === other.end;
  }
  return false;
};

const areExceptionsEqual = (exceptions, other) => exceptions.length === other.length
  && exceptions.reduce(
    (result, exception, idx) => result || isSameException(exception, other[idx]),
    false
  );

const exceptionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_RESOURCE: {
      const { exceptions, ...resource } = action.resource;

      if (!exceptions) {
        return { ...state, resource };
      }

      const exceptionsChanged = !areExceptionsEqual(state.exceptions, exceptions);
      return exceptionsChanged ? { ...state, resource, exceptions } : { ...state, resource };
    }

    case types.INITIALIZE_EXCEPTIONS: {
      return { ...state, exceptions: action.exceptions, exception: null };
    }

    case types.UPDATE_EXCEPTION: {
      return { ...state, exception: action.exception };
    }

    case types.PATCH_EXCEPTION: {
      const { exceptions: previousExceptions } = state;
      const exceptions = previousExceptions.map(current => {
        const isEqual = isSameException(current, action.exception);
        return isEqual ? action.exception : current;
      });
      return { ...state, exceptions };
    }

    case types.RESET_EXCEPTION: {
      const { start, end, calendarId } = action.exception;
      const { exceptions: previousExceptions } = state;
      const exceptions = previousExceptions.map(current => {
        const isEqual = isSameException(current, action.exception);
        return isEqual ? { start, end, calendarId } : current;
      });
      return { ...state, exceptions };
    }

    case types.REMOVE_EXCEPTION: {
      const { exceptions: previousExceptions } = state;
      const exceptions = previousExceptions.filter(
        exception => !isSameException(exception, action.exception)
      );
      return { ...state, exceptions };
    }

    case types.UPDATE_IS_IN_PROGRESS: {
      return { ...state, isInProgress: action.isInProgress };
    }

    default:
      return state;
  }
};

export default exceptionsReducer;
