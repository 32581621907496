import React, { memo } from 'react';
import { NodeResizer } from 'reactflow';
import PropTypes from 'prop-types';

function ResourceSubTypeNode({ data }) {
  return (
    <>
      <NodeResizer
        minWidth={30}
        minHeight={10}
        onResizeEnd={data.onResizeEnd}
        handleStyle={{
          borderColor: 'transparent',
          backgroundColor: 'transparent'
        }}
        lineStyle={{
          borderColor: 'transparent',
          backgroundColor: 'transparent'
        }}
      />
      {data.debug && (
        <>
          <div style={{ position: 'absolute', top: '-20px', left: '-2px' }}>
            {data.position}
          </div>
          <div style={{ position: 'absolute', top: '-20px', left: '47%' }}>
            {data.width}
          </div>
          <div style={{ position: 'absolute', top: '45%', left: '-25px' }}>
            {data.height}
          </div>
        </>
      )}

    </>
  );
}

export function getResourceSubTypeNode({
  resourceSubType,
  onResourceSubTypeResizeEnd,
  debug
}) {
  const width = resourceSubType ? resourceSubType.width : 30;
  const height = resourceSubType ? resourceSubType.height : 10;
  const color = resourceSubType ? resourceSubType.color : '#086ADB';
  const backgroundColor = resourceSubType ? resourceSubType.color : 'hsla(0, 0%, 100%, 0.6)';

  return {
    id: 'resourceSubTypeNode',
    type: 'ResourceSubTypeNode',
    position: { x: 0, y: 0 },
    draggable: false,
    deletable: false,
    width,
    height,
    data: {
      onResizeEnd: onResourceSubTypeResizeEnd,
      debug,
      position: `(${ 0 },${ 0 })`,
      width,
      height
    },
    style: {
      background: backgroundColor,
      border: '2px solid',
      borderColor: color,
      borderRadius: '5px',
      fontSize: 12,
      width,
      height
    }
  };
}

ResourceSubTypeNode.propTypes = {
  data: PropTypes.objectOf(PropTypes.any)
};

export default memo(ResourceSubTypeNode);
