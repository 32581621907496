import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Translate } from '../../../../../common';
import styles from './NewsContent.module.css';
import NewsItem from './NewsItem';

const languages = {
  de: 'german',
  en: 'english',
  fr: 'french',
  it: 'italian'
};

const getNewsItem = (language, newsItem) => {
  const lang = languages[language];
  const item = {
    title: newsItem[`${lang}Title`],
    text: newsItem[`${lang}Text`]
  };
  if (!!item.title || !!item.text) return item;

  item.title = newsItem[`${languages.en}Title`];
  item.text = newsItem[`${languages.en}Text`];
  if (!!item.title || !!item.text) return item;

  item.title = newsItem[`${languages.de}Title`];
  item.text = newsItem[`${languages.de}Text`];
  if (!!item.title || !!item.text) return item;

  item.title = newsItem[`${languages.fr}Title`];
  item.text = newsItem[`${languages.fr}Text`];
  if (!!item.title || !!item.text) return item;

  item.title = newsItem[`${languages.it}Title`];
  item.text = newsItem[`${languages.it}Text`];
  if (!!item.title || !!item.text) return item;

  return item;
};

function NewsContent({ open, news, language, nextSkipCount, totalCount, loadMore, isLoadingNews }) {
  const classes = cx(styles.content, { [styles.visible]: open });
  const showLoadMore = (nextSkipCount < totalCount) && !isLoadingNews;
  const showEmptyState = !news.length && !showLoadMore;

  return (
    <div className={classes}>
      {open && (
        <>
          <div className={styles.header}>
            <Translate path="news.title" />
          </div>

          <ul className={styles.list}>
            {news.map(newsItem => {
              const { title, text } = getNewsItem(language, newsItem);
              return <NewsItem key={newsItem.id} title={title} text={text} date={newsItem.startPublish} />;
            })}
          </ul>
          {showLoadMore && (
            <div className={styles.buttonLink}>
              <button aria-label="Load more" className="link" type="button" onClick={loadMore}>
                <Translate path="common.loadMore" />
              </button>
            </div>
          )}
          {isLoadingNews && (
            <div className={styles.loading}>
              <Translate path="common.loading" />
            </div>
          )}
          {(showEmptyState && !isLoadingNews) && (
            <div className={styles.emptyState}>
              <Translate path="news.noNews" />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default NewsContent;

NewsContent.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  open: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  nextSkipCount: PropTypes.number,
  totalCount: PropTypes.number,
  loadMore: PropTypes.func.isRequired,
  isLoadingNews: PropTypes.bool
};

NewsContent.defaultProps = {
  nextSkipCount: 0,
  totalCount: Infinity,
  isLoadingNews: false
};
