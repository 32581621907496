import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation, LinkButton } from '../../common';

import './_style.css';

function AlternativeLoadMore({ visible, onClick, translate }) {
  if (!visible) {
    return null;
  }

  const linkText = translate('search.alternativeResults.loadmore');
  return <LinkButton label={linkText} className="AlternativeLoadMore" onClick={onClick} />;
}

AlternativeLoadMore.propTypes = {
  visible: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

AlternativeLoadMore.defaultProps = {
  visible: false
};

export default withTranslation(AlternativeLoadMore);
