import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withTranslation, Translate } from '../../../common';
import { formatRecurrence } from '../../../../util';

import './_style.css';

class RecurrenceInfo extends Component {
  renderUpdateLink = () => {
    const { openRecurrencePatternModal, translate } = this.props;
    if (!openRecurrencePatternModal) {
      return null;
    }

    const linkProps = {
      tabIndex: '-1',
      className: 'link BookFormRecurrence__Update',
      onClick: openRecurrencePatternModal
    };

    return (
      <button {...linkProps} type="button">
        {translate('common.update')}
        ...
      </button>
    );
  };

  static renderIntro = () => {};

  renderRecurrence = () => {
    const { translate, type, pattern, resourceType, language, showAsAvailable } = this.props;
    const miniscule = value => value.charAt(0).toLowerCase() + value.slice(1);
    const recurrence = formatRecurrence(translate, type, pattern);

    const getRecurrence = () => {
      if (language === 'de' || language === 'en') {
        return miniscule(recurrence);
      }
      return recurrence.toLowerCase();
    };

    const availabilityInfo = showAsAvailable
      ? translate('search.recurrence.format.available')
      : translate('search.recurrence.format.booked');

    const resource = resourceType.toLowerCase() === 'room'
      ? translate('search.recurrence.format.recurrenceInfo.room')
      : translate('search.recurrence.format.recurrenceInfo.resource');

    switch (language) {
      case 'fr':
      case 'it':
      case 'en': {
        const intro = translate('search.recurrence.format.recurrenceInfo.intro', {
          availabilityInfo,
          resource
        });
        return (
          <span>
            {intro}
            {' '}
            <strong>{getRecurrence()}</strong>
          </span>
        );
      }
      case 'de': {
        const intro = translate('search.recurrence.format.recurrenceInfo.intro', {
          resource
        });
        return (
          <span>
            {intro}
            {' '}
            <strong>{getRecurrence()}</strong>
            {' '}
            {availabilityInfo}
          </span>
        );
      }
      default: {
        throw new Error('no valid language selected');
      }
    }
  };

  renderDate = () => {
    const { startDate, endDate } = this.props;
    const formatDate = date => moment(date).format('DD.MM.YYYY');
    const props = {
      path: 'search.recurrence.format.recurrenceInfo.fromTo',
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    };
    return <Translate {...props} />;
  };

  renderTime = () => {
    const { startDate, endDate } = this.props;

    const startTime = moment(startDate).format('HH:mm');
    const endTime = moment(endDate).format('HH:mm');
    const time = `${startTime} - ${endTime}`;

    return <strong style={{ whiteSpace: 'nowrap' }}>{time}</strong>;
  };

  renderInfo = () => (
    <>
      {this.renderRecurrence()}
      {'\u00A0'}
      {this.renderDate()}
      {'\u00A0'}
      {this.renderTime()}
      {'\u002E'}
    </>
  );

  render() {
    const { translate } = this.props;
    return (
      <div className="BookModalInput">
        <div>
          <h5 className="BookModalRecurrence__Title">{translate('search.recurrence.title')}</h5>
          <div>
            <p className="BookModalRecurrence__Text">{this.renderInfo()}</p>
            {this.renderUpdateLink()}
          </div>
        </div>
      </div>
    );
  }
}

RecurrenceInfo.propTypes = {
  type: PropTypes.oneOf(['daily', 'weekly', 'monthly']),
  pattern: PropTypes.oneOfType([
    PropTypes.shape({
      index: PropTypes.number,
      daysOfWeek: PropTypes.arrayOf(PropTypes.string)
    }),
    PropTypes.shape({
      index: PropTypes.number,
      weekOfMonth: PropTypes.string,
      dayOfMonth: PropTypes.string
    }),
    PropTypes.shape({})
  ]),
  resourceType: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  showAsAvailable: PropTypes.bool.isRequired,
  openRecurrencePatternModal: PropTypes.func,
  language: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurrenceInfo);
