import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button, withTranslation } from '../../../common';

import '../_style.css';

function NewFloorButton({ translate, actionOnClick }) {
  return (
    <Button
      icon="054-new"
      label={translate('admin.settings.floors.add.newFloorButtonLabel')}
      onClick={actionOnClick} />
  );
}

NewFloorButton.propTypes = {
  translate: PropTypes.func.isRequired,
  actionOnClick: PropTypes.func.isRequired
};

export default withTranslation(withRouter(NewFloorButton));
