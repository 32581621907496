import React, { Component } from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/fp/groupBy';

import { CustomPropTypes, withTranslation } from '../../../common';
import AlternativeEmptyState from './AlternativeEmptyState';
import AlternativeSearchResult from './AlternativeSearchResult';
import BookAlternativeResult from './BookAlternativeResult';
import AlternativeSearchResultAccordion from '../../../Search/AlternativeSearchResults/AlternativeSearchResultsAccordion';
import AlterSearchResultSection from './AlternativeSearchResultSection';

import './_style.css';

const isOrdinaryResource = ({ resource }) => resource.alternativeType == null;
const isAlternativeResource = ({ resource }) => resource.alternativeType != null;
const groupBySection = groupBy(({ resource }) => resource.alternativeType);
const forEachSection = (fn, sections) => Object.keys(sections).map(section => fn(section, sections[section]));

class AlternativeSearchResults extends Component {
  renderSearchResult = selectableResource => {
    const { onBook, onSelect, isMobile, isLastException, isInProgress } = this.props;
    const { resource, selected } = selectableResource;
    const { alternativeType } = resource;

    const select = () => onSelect(resource);
    const book = onBook ? () => onBook(resource) : undefined;

    const commonProps = {
      key: resource.id,
      resource,
      alternativeType
    };

    if (isMobile && selected) {
      return (
        <BookAlternativeResult
          {...commonProps}
          onClick={book}
          isLastException={isLastException}
          isInProgress={isInProgress}
        />
      );
    }

    if (!isMobile && selected) {
      return <AlternativeSearchResult {...commonProps} onSelect={select} selected={selected} />;
    }

    return <AlternativeSearchResult {...commonProps} onSelect={select} />;
  };

  renderAlternativeSection = (type, resources) => {
    const { translate } = this.props;
    const title = translate(`search.alternativeResults.${type}.title`);

    return (
      <AlternativeSearchResultAccordion key={type} title={title}>
        <AlterSearchResultSection type={type}>
          {resources.map(resource => this.renderSearchResult(resource))}
        </AlterSearchResultSection>
      </AlternativeSearchResultAccordion>
    );
  };

  render() {
    const { resources, className } = this.props;

    const ordinaryResources = resources.filter(isOrdinaryResource);
    const alternativeResources = resources.filter(isAlternativeResource);
    const sections = groupBySection(alternativeResources);

    const showAlternativeEmptyState = ordinaryResources.length < 1 && alternativeResources.length > 0;

    return (
      <div className={className}>
        <div className="AlternativeSearchResults__Resources">
          {ordinaryResources.map(resource => this.renderSearchResult(resource))}
        </div>

        <AlternativeEmptyState visible={showAlternativeEmptyState} />

        {forEachSection(this.renderAlternativeSection, sections)}
      </div>
    );
  }
}

AlternativeSearchResults.propTypes = {
  isMobile: PropTypes.bool,
  isLastException: PropTypes.bool,
  isInProgress: PropTypes.bool,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      resource: CustomPropTypes.resource,
      selected: PropTypes.bool
    })
  ),
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onBook: PropTypes.func,
  translate: PropTypes.func.isRequired
};

AlternativeSearchResult.defaultProps = {
  isMobile: false
};

export default withTranslation(AlternativeSearchResults);
