/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, Spacing } from '../../../common';
import { authSelectors } from '../../../../state/auth';
import {
  buildingsSelectors,
  buildingsOperations
} from '../../../../state/buildings';

import { floorsOperations } from '../../../../state/floors';

import BuildingsTable from './BuildingsTable';
import { modalOperations } from '../../../../state/modal';
import modalKeys from './modalKeys';
import BuildingsLoader from './BuildingsLoader';

import NewBuildingButton from './NewBuildingButton';

class Buildings extends Component {
  constructor() {
    super();
    this.state = {
      hasScroll: false
    };
  }

  componentDidMount() {
    const { loadBuildings, isAdmin, history, resetFloors } = this.props;
    if (!isAdmin) {
      history.replace('/unauthorized');
    }

    loadBuildings();

    // Initialize floorState and selectedFloor
    resetFloors();
  }

  renderLoader = () => {
    const { isLoaded } = this.props;
    return <BuildingsLoader visible={!isLoaded} />;
  };

  separatorShadow = { boxShadow: 'none' };

  onTableScrollBar = hasScroll => {
    if (hasScroll) {
      this.separatorShadow = {
        boxShadow: '0px -2px 8px 0px rgba(0, 0, 0, 0.2)'
      };
    } else {
      this.separatorShadow = { boxShadow: 'none' };
    }
    this.setState({ hasScroll });
  };

  renderBuildings = () => {
    const {
      isLoaded,
      hasBuildings,
      buildings,
      openBuildingDeleteDialog,
      setSelectedBuilding } = this.props;
    const showBuildings = isLoaded && hasBuildings;
    if (!showBuildings) {
      return null;
    }

    return (
      <div className="Settings__Table">
        <BuildingsTable
          onTableScrollBar={this.onTableScrollBar}
          buildings={buildings}
          selectBuilding={setSelectedBuilding}
          openDeleteModal={openBuildingDeleteDialog} />
      </div>
    );
  };

  render() {
    const { translate } = this.props;

    return (
      <Spacing horizontal="1" vertical="1">
        <div className="Settings__TableHead">
          <h3 className="Settings__Title">
            {translate('admin.settings.buildings.table.title')}
          </h3>
        </div>
        {this.renderLoader()}
        {this.renderBuildings()}
        <div className="button--right--container" style={this.separatorShadow}>
          <div className="button--right">
            <NewBuildingButton />
          </div>
        </div>
      </Spacing>
    );
  }
}

const mapStateToProps = state => ({
  isLoaded: buildingsSelectors.isLoaded(state),
  buildings: buildingsSelectors.getBuildings(state),
  hasBuildings: buildingsSelectors.hasBuildings(state),
  isAdmin: authSelectors.isAdministrator(state),
  selectedBuilding: state.selectedBuilding
});

const mapDispatchToProps = dispatch => ({
  loadBuildings: () => dispatch(buildingsOperations.updateBuildings()),
  openBuildingDeleteDialog: () => dispatch(modalOperations.pushModal(modalKeys.BUILDING_DELETE_DIALOG)),
  setSelectedBuilding: building => dispatch(buildingsOperations.setSelectedBuilding(building)),
  resetFloors: () => dispatch(floorsOperations.resetFloors())
});

Buildings.propTypes = {
  isAdmin: PropTypes.bool,
  isLoaded: PropTypes.bool,
  buildings: PropTypes.arrayOf(PropTypes.any),
  hasBuildings: PropTypes.bool,
  loadBuildings: PropTypes.func.isRequired,
  setSelectedBuilding: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  openBuildingDeleteDialog: PropTypes.func.isRequired,
  resetFloors: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(withRouter(Buildings)));
