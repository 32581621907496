import React, { Component } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/fp/omit';
import cx from 'classnames';

import identifier from '../identifier';

import './_style.css';

class Radio extends Component {
  constructor(props) {
    super(props);

    const { id } = props;
    this.state = {
      id: id || identifier()
    };
  }

  render() {
    const { id } = this.state;
    const { label, className, ...props } = this.props;

    const otherProps = omit(['id', 'className'], props);
    const classes = cx('radio', className);

    const { radioGroup } = this.context;
    if (radioGroup) {
      otherProps.disabled = props.disabled || radioGroup.disabled;
      otherProps.checked = radioGroup.value === props.value;
      otherProps.onChange = radioGroup.onChange;
    }

    return (
      <div className={classes}>
        <input type="radio" id={id} {...otherProps} />

        <label htmlFor={id}>{label}</label>
      </div>
    );
  }
}

Radio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  onChange: PropTypes.func
};

Radio.contextTypes = {
  radioGroup: PropTypes.shape({})
};

export default Radio;
