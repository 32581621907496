import { combineReducers } from 'redux';
import sortBy from 'lodash/fp/sortBy';
import moment from 'moment';

import types from './types';

const getEndOfMonth = date => moment(date).endOf('month');
const getNextMonth = date => moment(date).add(1, 'month');

const initialState = {
  myBookings: [],
  isLoading: false,
  lastDate: getEndOfMonth(getNextMonth(new Date())),
  searched: false
};

const myBookingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_MYBOOKINGS: {
      return { ...initialState };
    }

    case types.ADD_MYBOOKINGS: {
      return { ...state, myBookings: [...state.myBookings, ...action.myBookings], searched: true };
    }

    case types.UPDATE_MYBOOKING: {
      const { myBooking } = action;
      const sameGlobalId = item => item.globalId === myBooking.globalId;
      const sameCalendarId = item => item.calendarId === myBooking.calendarId;
      const isSameItem = item => sameGlobalId(item) && sameCalendarId(item);
      const oldItems = state.myBookings.filter(item => !isSameItem(item));

      const lastDate = moment(state.lastDate);
      const updatedDate = moment(myBooking.start);
      const newItems = updatedDate < lastDate ? [...oldItems, myBooking] : oldItems;

      return { ...state, myBookings: sortBy(['start'], newItems) };
    }

    case types.UPDATE_MYBOOKINGS_DATE: {
      return { ...state, lastDate: action.date };
    }

    case types.UPDATE_MYBOOKINGS_LOADING: {
      return { ...state, isLoading: action.isLoading };
    }

    case types.REMOVE_MYBOOKING: {
      return {
        ...state,
        myBookings: state.myBookings.filter(booking => booking.calendarId !== action.id)
      };
    }

    case types.REMOVE_RECURRENCE: {
      return {
        ...state,
        myBookings: state.myBookings.filter(booking => booking.globalId !== action.id)
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  myBookings: myBookingsReducer
});
