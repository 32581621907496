import store from 'store2';

import selectors from './selectors';
import actions from './actions';
import floorsActions from '../floors/actions';
import { buildingsService } from '../../services';

import { notificationsOperations, notificationsSelectors } from '../notifications';
import { locationsOperations, locationsSelectors } from '../locations';
import { availabilityOperations } from '../availability';

const persistData = (dispatch, state) => buildings => {
  store('buildingsData', buildings);
  dispatch(actions.updateBuildings(buildings));
  const floors = [];
  dispatch(floorsActions.updateFloors(floors));
  dispatch(actions.updateIsLoaded(Date.now()));

  const bootstrapped = store('bootstrapped');
  if (locationsSelectors.getLocationNames(state).length === 0 && !bootstrapped) {
    dispatch(locationsOperations.setToDefault());
    dispatch(availabilityOperations.loadAvailabilities());
    store('bootstrapped', true);
  }
};

const updateBuildings = () => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const persist = persistData(dispatch, state);

  const age = selectors.getAge(state);
  const tenMinutes = 1000 * 60 * 10;

  if (!age || age + tenMinutes < Date.now()) {
    return buildingsService
      .getAll()
      .then(response => {
        // Todo: use 'normalizeData' to format as an array (see regions/operations.js line 38)
        const data = response;
        persist(data);
      })
      .catch(serverError => {
        const error = getNotification('buildings.error', serverError);
        dispatch(notificationsOperations.showError(error));
      });
  }
  return null;
};

const addBuilding = payload => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return buildingsService
    .addBuilding(payload)
    .then(() => {
      const success = getNotification('admin.settings.buildings.add.success');
      dispatch(notificationsOperations.showSuccess(success));
      const persist = persistData(dispatch, state);
      return buildingsService
        .getAll()
        .then(response => {
          const data = response;
          persist(data);
          return 'Ok';
        })
        .catch(serverError => {
          const error = getNotification('admin.settings.buildings.add.error.serverError', serverError);
          dispatch(notificationsOperations.showError(error));
          return 'Error';
        });
    })
    .catch(serverError => {
      const error = getNotification('admin.settings.buildings.add.error.serverError', serverError);
      dispatch(notificationsOperations.showError(error));
      return 'Error';
    });
};

const setSelectedBuilding = building => dispatch => {
  dispatch(actions.setSelectedBuilding(building));
};

const updateBuilding = (id, payload) => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return buildingsService
    .updateBuilding(id, payload)
    .then(() => {
      const success = getNotification('admin.settings.buildings.update.success');
      dispatch(notificationsOperations.showSuccess(success));
      const persist = persistData(dispatch, state);
      return buildingsService
        .getAll()
        .then(response => {
          const data = response;
          persist(data);
          return 'Ok';
        })
        .catch(serverError => {
          const error = getNotification('admin.settings.buildings.update.error.serverError', serverError);
          dispatch(notificationsOperations.showError(error));
          return 'Error';
        });
    })
    .catch(serverError => {
      const error = getNotification('admin.settings.buildings.update.error.serverError', serverError);
      dispatch(notificationsOperations.showError(error));
      return 'Error';
    });
};

const deleteBuilding = () => (dispatch, getState) => {
  const state = getState();
  const { selectedBuilding } = state.buildingsState.buildingsData;

  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return buildingsService
    .remove(selectedBuilding.key)
    .then(async () => {
      const success = getNotification('admin.settings.buildings.delete.success');
      dispatch(notificationsOperations.showSuccess(success));
      const buildings = await buildingsService.getAll();
      dispatch(actions.updateBuildings(buildings));
      dispatch(locationsOperations.removeBuildingFromLocation(selectedBuilding.key));
      return Promise.resolve();
    })
    .catch(serverError => {
      const error = getNotification('admin.settings.buildings.delete.error', serverError);
      error.text = serverError.message;
      dispatch(notificationsOperations.showError(error));
      return Promise.resolve(serverError);
    });
};

export default {
  updateBuildings,
  addBuilding,
  updateBuilding,
  setSelectedBuilding,
  deleteBuilding
};
