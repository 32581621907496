import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, Icon, withTranslation, CustomPropTypes } from '../../../common';
import { bookSelectors, bookOperations } from '../../../../state/book';

import styles from './SearchResultJournal.module.css';

class SearchResultJournal extends Component {
  renderBooking(booking, i) {
    const { translate, resource } = this.props;

    const phoneLink = `tel:${booking.organizer.phoneNumber}`;
    const organizerName = booking?.organizer?.displayName;

    return (
      <div key={i} className={[styles.bodyBooking, styles[resource.roomType.toLowerCase()]].join(' ')}>
        <div className={styles.firstColumn}>
          <div className={styles.time}>
            {moment(booking.start).format('HH:mm')}
            {' '}
            -
            {' '}
            {moment(booking.end).format('HH:mm')}
          </div>
          <div>{booking.subject}</div>
        </div>
        <div className={styles.secondColumn}>
          <span title={organizerName} className={styles.journalText}>{organizerName}</span>
        </div>
        <div className={styles.thirdColumn}>
          <a className={styles.call} href={phoneLink} onClick={event => { event.stopPropagation(); }}>
            <Icon icon="137-handset" />
            <p className={styles.textCall}>{translate('search.call')}</p>
          </a>
        </div>
      </div>
    );
  }

  renderAvailability(availability, i) {
    const {
      isSubmitting,
      resource,
      updateSelectedResource,
      translate,
      updateDate,
      updateTime,
      openCard
    } = this.props;

    const disabled = isSubmitting;

    const bookNow = async () => {
      updateSelectedResource(resource);

      const start = moment(availability.start);
      const end = moment(availability.end);

      updateDate(start.format('DD.MM.YYYY'));
      updateTime({
        from: start.format('HH:mm'),
        to: end.format('HH:mm')
      });
      openCard();
    };

    return (
      <div key={i} className={[styles.bodyBooking, styles[resource.roomType.toLowerCase()]].join(' ')}>
        <div className={styles.firstColumn}>
          <div className={styles.time}>
            {moment(availability.start).format('HH:mm')}
            {' '}
            -
            {' '}
            {moment(availability.end).format('HH:mm')}
          </div>
          <div>{translate('search.free')}</div>
        </div>
        <div className={styles.columnBookButton}>
          <Button
            data-ignore="true"
            disabled={disabled}
            onClick={bookNow}
            label={translate('search.select')}
          />
        </div>
      </div>
    );
  }

  render() {
    const { resource } = this.props;
    const style = {
      height: (resource.bookings.length + resource.availabilities.length) * 80
    };

    const journalObjects = [...resource.bookings, ...resource.availabilities];
    journalObjects.sort((a, b) => new Date(a.start) - new Date(b.start));

    return (
      <div className={styles.bodyResults} style={style}>
        {journalObjects.map((obj, i) => {
          if (obj.organizer) {
            return this.renderBooking(obj, i);
          }
            return this.renderAvailability(obj, i);
        })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isSubmitting: bookSelectors.isSubmitting(state)
});

const mapDispatchToProps = dispatch => ({
  updateSelectedResource: room => dispatch(bookOperations.updateSelectedResource(room)),
  updateTime: time => dispatch(bookOperations.updateTime(time)),
  updateDate: date => dispatch(bookOperations.updateDate(date))
});

SearchResultJournal.propTypes = {
    resource: CustomPropTypes.resource,
    openCard: PropTypes.func,
    translate: PropTypes.func,
    isSubmitting: PropTypes.bool,
    updateSelectedResource: PropTypes.func,
    updateDate: PropTypes.func,
    updateTime: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(SearchResultJournal));
