import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { authSelectors } from '../../../state/auth';
import { withTranslation, Button } from '../../common';
import Header from '../../Header';
import { newsService } from '../../../services';

import styles from './NewsNew.module.css';
import NewsForm from '../NewsForm';
import validateNewsForm from '../../../util/news';

class NewsDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        germanTitle: '',
        germanText: '',
        frenchTitle: '',
        frenchText: '',
        italianTitle: '',
        italianText: '',
        englishTitle: '',
        englishText: '',
        startPublish: '',
        stopPublish: ''
      }
    };
  }

  componentDidMount() {
    const { isAdmin, history } = this.props;
    if (!isAdmin) {
      history.replace('/unauthorized');
    }
  }

  createNews = async () => {
    const { history } = this.props;
    const { form } = this.state;
    const payload = {
      ...form
    };
    await newsService.createNews(payload);
    history.push('/admin/news');
  };

  onChange = event => {
    this.setState(
      {
        form: event
      }
    );
  };

  render() {
    const { history, translate } = this.props;
    const { form } = this.state;
    const title = translate('menu.news');
    let isFormValid = !!form.germanTitle && !!form.germanText && !!form.startPublish;
    isFormValid = isFormValid && validateNewsForm(form);
    return (
      <div>
        <Header onClose={() => { history.push('/admin/news'); }} title={title} iconRight icon="022-close" />
        <div className={styles.body}>
          <div className={styles.bodyForm}>
            <NewsForm
              value={form}
              onChange={this.onChange}
            />
            <div className={styles.buttonGroup}>
              <Button
                disabled={!isFormValid}
                className={styles.button}
                label={translate('common.create')}
                onClick={this.createNews}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NewsDetails.propTypes = {
  isAdmin: PropTypes.bool,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

NewsDetails.defaultProps = {
  isAdmin: false
};

const mapStateToProps = state => ({
  isAdmin: authSelectors.isAdministrator(state)
});

export default connect(mapStateToProps, null)(
  withTranslation(withRouter(NewsDetails))
);
