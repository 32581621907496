import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { recurrenceSelectors, recurrenceOperations } from '../../../../state/recurrence';
import { Icon } from '../../../common';

import SearchBarLocationPicker from './SearchBarLocationPicker';
import SearchBarDatePicker from './SearchBarDatePicker';
import SearchBarRecurrence from './SearchBarRecurrence';
import styles from './SearchBar.module.css';
import SearchBarTimePicker from './SearchBarTimePicker';

function SearchBar({ location, history, isRecurrencePending, toggleRecurrence }) {
  const isDayCalendar = location.pathname.includes('daily');
  const isWeekCalendar = location.pathname.includes('weekly');
  const isCalendar = isDayCalendar || isWeekCalendar;

  const onRecurrenceClick = () => {
    toggleRecurrence();
    if (isCalendar) {
      history.push('/search/now');
    }
  };

  return (
    <div className={styles.body}>
      <div className={styles.item}>
        <Icon icon="223-map-pointer" />
        <div className={styles.inputLocationPicker}>
          <SearchBarLocationPicker />
        </div>
      </div>

      <div className={`${styles.item} ${styles.itemInputDate}`}>
        <Icon icon="009-calendar" />
        <div className={styles.inputDate}>
          <SearchBarDatePicker showWeekPicker={isWeekCalendar} />
        </div>
      </div>

      <div className={styles.item}>
        <Icon icon="021-clock" />
        <div className={styles.inputTime}>
          <SearchBarTimePicker />
        </div>
      </div>

      <div className={styles.item}>
        <SearchBarRecurrence active={isRecurrencePending} onClick={onRecurrenceClick} />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  isRecurrencePending: recurrenceSelectors.isPending(state)
});

const mapDispatchToProps = dispatch => ({
  toggleRecurrence: () => dispatch(recurrenceOperations.toggleActive())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchBar));

SearchBar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }),
  isRecurrencePending: PropTypes.bool.isRequired,
  toggleRecurrence: PropTypes.func.isRequired
};
