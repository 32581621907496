import actions from './actions';
import { changeBrowserLanguage } from '../../i18n';

const updateLanguage = (language, translations) => dispatch => {
  dispatch(actions.updateLanguage(language));
  dispatch(actions.updateTranslations(translations));

  changeBrowserLanguage(language);
};

export default {
  updateLanguage
};
