import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Spacing, Icon, Checkbox, Button, withTranslation } from '../../common';

import './_style.css';

class BookingCollectionDialogContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false
    };
  }

  onClose = () => {
    const { close, ignore } = this.props;
    const { checked } = this.state;
    if (checked) {
      ignore();
    }
    close();
  };

  toggleIgnore = () => {
    const { checked } = this.state;
    this.setState({ checked: !checked });
  };

  render() {
    const { translate } = this.props;
    const { checked } = this.state;

    const title = translate('bookingCollection.title');
    const description = translate('bookingCollection.dialog.description');
    const ignore = translate('bookingCollection.dialog.ignore');
    const okay = translate('bookingCollection.dialog.okay');

    return (
      <div className="BookingCollectionDialog">
        <div className="BookingCollectionDialog__Content">
          <Spacing horizontal="2" vertical="2">
            <div className="BookingCollectionDialog__Title">{title}</div>
            <div className="BookingCollectionDialog__Description">
              <Icon className="BookingCollectionDialogDescription__Icon" icon="006-attachment" />
              <div className="BookingCollectionDialogDescription__Text">{description}</div>
            </div>
            <div className="BookingCollectionDialog__Ignore">
              <Checkbox
                id="reservationhub-bcd-1"
                label={ignore}
                checked={checked}
                onChange={this.toggleIgnore}
              />
            </div>
            <div className="BookingCollectionDialog__Action">
              <Button label={okay} onClick={this.onClose} />
            </div>
          </Spacing>
        </div>
      </div>
    );
  }
}

BookingCollectionDialogContent.propTypes = {
  close: PropTypes.func.isRequired,
  ignore: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookingCollectionDialogContent);
