import { normalize, schema } from 'normalizr';

const bookings = new schema.Entity(
  'bookings',
  {},
  {
    idAttribute: (value, parent) => value.calendarId + parent.resource.id,
    processStrategy: (entity, parent) => ({ ...entity, resourceId: parent.resource.id })
  }
);

const resource = new schema.Entity('resources', {
  bookings: [bookings]
});

const roomsList = [{ resource, bookings: [bookings] }];

const normalizeData = data => normalize(data, roomsList);

export default normalizeData;
