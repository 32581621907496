import React from 'react';
import PropTypes from 'prop-types';

import Welcome from '../../assets/images/Welcome.png';
import styles from './WelcomeImage.module.css';

function WelcomeImage({ mobile }) {
  const classes = mobile ? styles.modal : styles.dialog;
  return <img src={Welcome} alt="Welcome" className={classes} />;
}

WelcomeImage.propTypes = { mobile: PropTypes.bool };

export default WelcomeImage;
