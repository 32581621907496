import get from 'lodash/fp/get';
import template from 'lodash/fp/template';
import contains from 'lodash/fp/contains';
import curry from 'lodash/fp/curry';

const isTemplated = contains('${');

const translate = (translations, path, values = null) => {
  if (!translations) {
    throw new Error('No translations provided');
  }

  const text = get(path, translations) || path;

  if (!isTemplated(text)) {
    return text;
  }

  if (!values) {
    return text;
  }

  const compiled = template(text);
  return compiled(values);
};

export default curry(translate);
