import cloneDeep from 'lodash/fp/cloneDeep';
import types from './types';

const initializeField = (name, value) => ({
  [name]: { new: value }
});

const initialState = {
  values: {
    ...initializeField('resourceType', null),
    ...initializeField('name', ''),
    ...initializeField('building', null),
    ...initializeField('changeRequestInfo', null),
    ...initializeField('region', ''),
    ...initializeField('street', ''),
    ...initializeField('zipCode', ''),
    ...initializeField('floor', ''),
    ...initializeField('sector', ''),
    ...initializeField('resourceSubType', ''),
    ...initializeField('equipmentType', ''),
    ...initializeField('capacity', ''),
    ...initializeField('roomEquipment', []),
    ...initializeField('daylight', null),
    ...initializeField('confidentialMeetings', false),
    ...initializeField('contact', null),
    ...initializeField('notes', ''),
    ...initializeField('reason', ''),
    ...initializeField('profile', null),
    ...initializeField('ownersProfile2', []),
    ...initializeField('ownersProfile3', []),
    ...initializeField('ownersProfile4', [])
  },
  changeRequestInfoSuggestions: [],
  isChangeRequestInfoLoading: false,
  buildingSuggestions: [],
  isBuildingLoading: false,
  peopleSuggestions: [],
  peopleIsLoading: false,
  submitted: false,
  isSubmitting: false
};

const formatPerson = person => person && {
  name: person.displayName,
  value: person.accountName,
  id: person.accountName,
  email: person.email
};

const createResourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_FORM_VALUE: {
      const newState = cloneDeep(state);
      newState.values[action.field] = {
        ...newState.values[action.field],
        new: action.value
      };
      return newState;
    }

    case types.INITIALIZE_DETAIL: {
      const {
        resourceType,
        resourceName,
        building,
        floor,
        sector,
        resourceSubType,
        capacity,
        roomEquipment,
        daylight,
        confidentialMeetings,
        contact,
        notes,
        reason,
        profile,
        owners
      } = action.changeRequest;

      const decapitalizeFirstLetter = string => string && string.charAt(0).toLowerCase() + string.slice(1);

      const values = {
        ...initializeField('resourceType', resourceType),
        ...initializeField('name', resourceName),
        ...initializeField('building', building),
        ...initializeField('floor', floor),
        ...initializeField('sector', sector),
        ...initializeField('resourceSubType', resourceSubType.id),
        ...initializeField('capacity', capacity),
        ...initializeField(
          'roomEquipment',
          roomEquipment.map(equipment => decapitalizeFirstLetter(equipment.roomEquipmentType))
        ),
        ...initializeField('daylight', daylight),
        ...initializeField('confidentialMeetings', confidentialMeetings),
        ...initializeField('contact', formatPerson(contact)),
        ...initializeField('notes', notes),
        ...initializeField('reason', reason),
        ...initializeField('profile', profile && profile.toString()),
        ...initializeField('ownersProfile2', profile === 2 ? owners.map(formatPerson) : []),
        ...initializeField('ownersProfile3', profile === 3 ? owners.map(formatPerson) : []),
        ...initializeField('ownersProfile4', profile === 4 ? owners.map(formatPerson) : [])
      };

      return {
        ...initialState,
        values
      };
    }

    case types.UPDATE_SUBMITTED: {
      return { ...state, submitted: true };
    }

    case types.UPDATE_PEOPLE_ISLOADING: {
      return { ...state, peopleIsLoading: action.isLoading };
    }

    case types.UPDATE_PEOPLE_SUGGESTIONS: {
      return { ...state, peopleSuggestions: action.suggestions };
    }

    case types.UPDATE_CHANGEREQUESTINFO_ISLOADING: {
      return { ...state, changeRequestInfoIsLoading: action.isChangeRequestInfoLoading };
    }

    case types.UPDATE_CHANGEREQUESTINFO_SUGGESTIONS: {
      return { ...state, changeRequestInfoSuggestions: action.changeRequestInfoSuggestions };
    }

    case types.UPDATE_BUILDING_ISLOADING: {
      return { ...state, buildingIsLoading: action.isBuildingLoading };
    }

    case types.UPDATE_BUILDING_SUGGESTIONS: {
      return { ...state, buildingSuggestions: action.buildingSuggestions };
    }

    case types.UPDATE_IS_SUBMITTING: {
      return { ...state, isSubmitting: action.isSubmitting };
    }

    case types.RESET_STATE: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default createResourceReducer;
