import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Header';
import FeedbackForm from './FeedbackForm';
import { Spacing, withTranslation } from '../common';
import { getPropTypes } from './Feedback';

import './_styles.css';

function FeedbackMobile({ translate, ...props }) {
  const title = translate('feedback.title');
  return (
    <div>
      <Header backlink title={title} />
      <Spacing horizontal="1" vertical="1">
        <FeedbackForm {...props} />
      </Spacing>
    </div>
  );
}

FeedbackMobile.propTypes = {
  ...getPropTypes,
  translate: PropTypes.func.isRequired
};

export default withTranslation(FeedbackMobile);
