import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { bookSelectors, bookOperations } from '../../../../../state/book';
import { CustomPropTypes } from '../../../../common';
import TimeSection from './TimeSection';

function FormTime(props) {
  return <TimeSection {...props} />;
}

const mapStateToProps = state => ({
  time: bookSelectors.getTime(state)
});

const mapDispatchToProps = dispatch => ({
  updateTime: time => dispatch(bookOperations.updateTime(time)),
  updateIsValidTimeRange: isTimeValid => dispatch(bookOperations.updateIsValidTimeRange(isTimeValid))
});

FormTime.propTypes = {
  time: CustomPropTypes.time,
  updateTime: PropTypes.func.isRequired,
  updateIsValidTimeRange: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(FormTime);
