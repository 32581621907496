import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';

import { ShowAt, Button, withTranslation } from '../../common';
import { bookSelectors } from '../../../state/book';
import { authSelectors } from '../../../state/auth';
import { modalOperations } from '../../../state/modal';
import { recurrenceMasterOperations } from '../../../state/recurrenceMaster';

import modalKeys from '../modalKeys';

function RecurrenceDetailActions(props) {
  const {
    isSubmitting,
    confirmDeletion,
    openUpdateModal,
    user,
    bookFor,
    isMyBooking,
    translate
  } = props;
  const deleteBookingText = translate('common.delete');
  const updateBookingText = translate('common.update');

  const hasEmail = user.profile.email != null;
  let email = '';
  if (hasEmail) {
      if (typeof user.profile.email === 'string') {
          email = user.profile.email;
      }
  }

  if (Array.isArray(user.profile.email)) {
    const uniqueEmails = new Set(user.profile.email.map(e => e.toLowerCase()));

    if (uniqueEmails.size === 1) {
      email = [...uniqueEmails];
    } else {
      email = user.profile.email[0].toLowerCase();
    }
  }

  if (bookFor && bookFor.email.toLowerCase() === email && !isMyBooking) {
    return null;
  }

  const renderButtons = className => {
    const classes = cx('BookDetailActions__ButtonGroup', className);
    const buttonClasses = 'BookDetailActions__Button';
    return (
      <div className={classes}>
        <Button
          className={buttonClasses}
          loading={isSubmitting}
          label={deleteBookingText}
          onClick={confirmDeletion}
          secondary
        />
        <Button className={buttonClasses} onClick={openUpdateModal} label={updateBookingText} />
      </div>
    );
  };

  return (
    <div className="RecurrenceDetailActionsWrapper">
      <ShowAt breakpoint="900AndBelow">{renderButtons('button--fixed')}</ShowAt>
      <ShowAt breakpoint="900AndAbove">{renderButtons()}</ShowAt>
    </div>
  );
}

const mapStateToProps = state => ({
  isSubmitting: bookSelectors.isSubmitting(state),
  globalId: bookSelectors.getGlobalId(state),
  calendarId: bookSelectors.getCalendarId(state),
  start: bookSelectors.getStartTime(state),
  end: bookSelectors.getEndTime(state),
  user: authSelectors.getUser(state),
  bookFor: bookSelectors.getBookForPerson(state),
  isMyBooking: bookSelectors.isMyBooking(state)
});

const mapDispatchToProps = dispatch => ({
  openUpdateModal: () => {
    dispatch(modalOperations.pushModal(modalKeys.RECURRENCE_UPDATE_MODAL));
    dispatch(recurrenceMasterOperations.cacheSelectedMaster());
  }
});

RecurrenceDetailActions.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  confirmDeletion: PropTypes.func.isRequired,
  openUpdateModal: PropTypes.func.isRequired,
  user: PropTypes.shape({
    profile: PropTypes.shape({
      email: PropTypes.string.isRequired
    })
  }),
  bookFor: PropTypes.shape({
    email: PropTypes.string
  }),
  isMyBooking: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withTranslation(RecurrenceDetailActions)
);
