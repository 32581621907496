const RESET = 'deleteResource/RESET';
const UPDATE_REASON = 'deleteResource/UPDATE_REASON';
const UPDATE_SUBMITTED = 'deleteResource/UPDATE_SUBMITTED';
const UPDATE_IS_SUBMITTING = 'deleteResource/UPDATE_IS_SUBMITTING';

export default {
  RESET,
  UPDATE_REASON,
  UPDATE_SUBMITTED,
  UPDATE_IS_SUBMITTING
};
