import { normalize, schema } from 'normalizr';

// Define floors
const floors = new schema.Entity(
  'floors'
);

const floorList = [floors];

const normalizeData = data => normalize(data, floorList);

export default normalizeData;
