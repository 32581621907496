import React from 'react';

import { DataQualityInformation } from '../../../../common';

import './_style.css';

function SearchFormDataQuality() {
  return (
    <DataQualityInformation
      collapsible
      width="100%"
      path="resourceDataQuality"
      className="SearchFormDataQuality"
  />
  );
}

export default SearchFormDataQuality;
