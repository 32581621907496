import React from 'react';
import PropTypes from 'prop-types';

import './_style.css';

function Loader({ visible, size, children, className }) {
  if (!visible) {
    return null;
  }

  const loaderClass = `Loader--${size}`;
  return (
    <div className={className}>
      <div className={loaderClass} />
      {children}
    </div>
  );
}

Loader.propTypes = {
  visible: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'large']),
  children: PropTypes.node,
  className: PropTypes.string
};

Loader.defaultProps = {
  size: 'large'
};

export default Loader;
