/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import { styles } from './styles';

function DiverseIcon({ disabled, ...otherProps }) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" {...otherProps} style={styles}>
      <g id="production" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="difference" fill="#000000">
          <path d="M15,15 L15,19 C15,20.1045695 14.1045695,21 13,21 L5,21 C3.8954305,21 3,20.1045695 3,19 L3,11 C3,9.8954305 3.8954305,9 5,9 L9,9 L9,5 C9,3.8954305 9.8954305,3 11,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,13 C21,14.1045695 20.1045695,15 19,15 L15,15 Z M11,9 L15,9 L15,13 L19,13 L19,5 L11,5 L11,9 Z M5,11 L5,19 L13,19 L13,15 L9,15 L9,11 L5,11 Z" id="Shape" />
        </g>
      </g>
    </svg>
  );
}

DiverseIcon.propTypes = {
    disabled: PropTypes.bool
};

export default DiverseIcon;
