import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../../common';

import './_style.css';

function BookingCollectionLoader({ visible }) {
  if (!visible) {
    return null;
  }

  return (
    <div className="BookingCollectionLoader">
      <Loader visible />
    </div>
  );
}

BookingCollectionLoader.propTypes = {
  visible: PropTypes.bool
};

export default BookingCollectionLoader;
