import React from 'react';
import PropTypes from 'prop-types';

import { FormField, withTranslation } from '../../common';

function BookDetailSubject({ subject, translate }) {
  const subjectTitle = translate('search.detail.subject.name');
  const label = <strong>{subjectTitle}</strong>;
  return <FormField className="BookDetail__FormField" label={label} element={subject} />;
}

BookDetailSubject.propTypes = {
  subject: PropTypes.string,
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookDetailSubject);
