import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon } from '../../../../../common';

import styles from './NewsBarBell.module.css';

function NewsBarBell({ open, onClick, alerts }) {
  const classes = cx(styles.box, { [styles.active]: open });
  return (
    <div className={classes} tabIndex="0" role="button" onClick={onClick}>
      <Icon className={styles.NewsBarBellicon} icon="258_bell" />
      {/* Remove false for enable badge */}
      { false && alerts && alerts > 0 && <span className={styles.badge}>{alerts}</span> }
    </div>
  );
}

export default NewsBarBell;

NewsBarBell.propTypes = {
  alerts: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};
