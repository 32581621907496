import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation, Input, Button, Select, Option, PeoplePicker, ChangeDetector } from '../../../common';
import { regionsSelectors, regionsOperations } from '../../../../state/regions';
import { createResourceOperations, createResourceSelectors } from '../../../../state/createResource';
import { buildingsOperations, buildingsSelectors } from '../../../../state/buildings';
import { authSelectors } from '../../../../state/auth';
import { modalOperations } from '../../../../state/modal';
import Header from '../../../Header';
import formValidation from '../common';
import FloorsTable from '../Floors/FloorsTable';
import modalKeys from '../Floors/modalKeys';
import FloorsDeleteDialog from '../Floors/FloorsDeleteDialog';
import NewFloorButton from '../Floors/NewFloorButton';
import FloorsCreateModal from '../Floors/FloorsCreateModal';
import FloorUpdateModal from '../Floors/FloorUpdateModal';

class BuildingsUpdate extends Component {
  constructor(props) {
    super(props);
    const { match, buildings, regions } = this.props;
    const { buildingId } = match.params;
    const building = buildings.find(b => b.id === buildingId);
    const buildingRegion = Object.values(regions).filter(r => r.name === building.region).map(r => (
      {
        id: r.id,
        name: r.name
      }
    ))[0];
    const buildingResponsibles = building.responsibles.map(responsible => (
      {
        email: responsible.email,
        id: responsible.id,
        name: responsible.displayName,
        value: responsible.accountName,
        accountName: responsible.accountName
      }
    ));
    const buildingResponsibleOwner = building.responsibles.filter(r => r.isResponsibleOwner).map(responsible => (
      {
        key: responsible.id,
        name: responsible.accountName
      }
    ))[0];
    this.state = {
      form: {
        id: buildingId,
        key: building.key,
        name: building.name,
        region: buildingRegion,
        cip: building.cip,
        city: building.city,
        buildingManagers: buildingResponsibles,
        buildingOwner: buildingResponsibleOwner,
        floors: building.floors ? building.floors : null
      },
      isValid: {
        key: true,
        name: true,
        region: true,
        cip: true,
        city: true,
        buildingManagers: true,
        buildingOwner: true,
        floors: true
      },
      term: '',
      formIsValid: false,
      rendering: 0
    };

    this.validation = {
      key: value => value !== '' && /^[^\s]+$/.test(value), // not empty and no spaces
      name: value => value !== '', // not empty
      region: value => value.name !== '' && value.name !== '-', // not empty and not the initial '-' value
      cip: value => value !== '' && /^\d+$/.test(value), // not empty and only digits
      city: value => value !== '', // not empty
      buildingManagers: value => value.length > 0, // at least one manager is required
      buildingOwner: value => (value.name !== '' && value.name !== '-') // not empty and not the initial '-' value
        /* eslint-disable react/destructuring-assignment */
        || this.state.form.buildingManagers.length === 0, // or valid when there is not any buildingManager
      /* eslint-enable react/destructuring-assignment */
      floors: value => value !== null
    };
  }

  componentDidMount() {
    const { loadRegions, isAdmin, history } = this.props;
    if (!isAdmin) {
      history.replace('/unauthorized');
    }
    loadRegions();
  }

  componentDidUpdate() {
    // Set buildingOwner if there is ONLY one manager
    const { form } = this.state;
    if (form.buildingManagers.length === 1 && form.buildingManagers[0].id !== form.buildingOwner.key) {
      const buildingManager = form.buildingManagers[0];
      formValidation.validateField(this, 'buildingOwner', {
        key: buildingManager.id,
        name: buildingManager.name
      });
      formValidation.validateForm(this, 'buildingOwner', {
        key: buildingManager.id,
        name: buildingManager.name
      });
    }
  }

  renderKey = () => {
    const { form, isValid } = this.state;
    const { translate } = this.props;

    const handleChange = event => {
      const { value } = event.target;
      formValidation.validateField(this, 'key', value);
      formValidation.validateForm(this, 'key', value);
    };

    return (
      <>
        <Input
          label={`${translate('common.key')} ${translate('common.requiredField')}`}
          placeholder={translate('admin.settings.buildings.update.placeholder.key')}
          onChange={handleChange}
          error={!isValid.key}
          value={form.key}
          className={['input', 'input_key'].join(' ')}
          disabled
        />
        {!isValid.key && formValidation.renderValidationError(this, 'common.requiredAndNoSpace')}
      </>
    );
  };

  renderName = () => {
    const { form, isValid } = this.state;
    const { translate } = this.props;

    const handleChange = event => {
      const { value } = event.target;
      formValidation.validateField(this, 'name', value);
      formValidation.validateForm(this, 'name', value);
    };

    return (
      <>
        <Input
          label={`${translate('common.name')} ${translate('common.requiredField')}`}
          placeholder={translate('admin.settings.buildings.update.placeholder.name')}
          onChange={handleChange}
          error={!isValid.name}
          value={form.name}
          className="input"
        />
        {!isValid.name && formValidation.renderValidationError(this, 'common.required')}
      </>
    );
  };

  renderRegion = () => {
    const { regions, translate } = this.props;
    const options = regions
      ? Object.entries(regions).map(region => ({ id: region[1].id, name: region[1].name }))
      : [];

    options.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      return a.name < b.name ? -1 : 0;
    }); // sorted by name asc

    const renderOptions = () => options.map(option => (
      <Option value={option.id} key={option.id}>
        {option.name}
      </Option>
    ));

    const { form, isValid } = this.state;

    const handleChange = event => {
      formValidation.validateField(this, 'region', {
        id: event.selectedOption.value,
        name: event.selectedOption.text
      });
      formValidation.validateForm(this, 'region', {
        id: event.selectedOption.value,
        name: event.selectedOption.text
      });
    };

    return (
      <>
        <Select
          label={`${translate('common.region')} ${translate('common.requiredField')}`}
          className="input"
          placeholder={form.region.name}
          options={options}
          onChange={handleChange}
          value={form.region}
        >
          {renderOptions()}
        </Select>
        {!isValid.region && formValidation.renderValidationError(this, 'common.required')}
      </>
    );
  };

  renderZipAndCity = () => {
    const { form, isValid } = this.state;
    const { translate } = this.props;
    const values = { old: form.cip, new: '' };

    const handleChange = (event, fieldName) => {
      const { value } = event.target;
      values.new = value;
      formValidation.validateField(this, fieldName, value);
      formValidation.validateForm(this, fieldName, value);
    };

    return (
      <>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <ChangeDetector detectChanges={false} invalid={!isValid.cip} values={values}>
              <Input
                label={`${translate('common.cip')} ${translate('common.requiredField')}`}
                placeholder={translate('admin.settings.buildings.update.placeholder.cip')}
                onChange={event => handleChange(event, 'cip')}
                error={!isValid.cip}
                value={form.cip}
                className="input"
              />
            </ChangeDetector>
          </div>
          <div style={{ flex: 2, paddingLeft: 30 }}>
            <Input
              label={`${translate('common.city')} ${translate('common.requiredField')}`}
              placeholder={translate('admin.settings.buildings.update.placeholder.city')}
              onChange={event => handleChange(event, 'city')}
              error={!isValid.city}
              value={form.city}
              className="input"
            />
          </div>
        </div>
        {!isValid.cip && formValidation.renderValidationError(this, 'common.required')}
        {!isValid.city && formValidation.renderValidationError(this, 'common.required')}
      </>
    );
  };

  renderBuildingManagers = () => {
    const { loadManagerSuggestions, updateManagers, managersSuggestions,
      isResponsiblesLoading, translate } = this.props;
    const { form, term, isValid } = this.state;
    const field = 'buildingManagers';
    const labelText = `${translate('admin.settings.buildings.buildingManagers')}
     ${translate('common.requiredField')}`;
    const placeholder = form.buildingManagers.length === 0
      ? translate('admin.settings.buildings.buildingManagers') : '';

    const updateSelectedManagers = people => {
      updateManagers(field, people);
      formValidation.validateField(this, field, people);
      formValidation.validateForm(this, field, people);
    };

    const updateTerm = enteredTerm => {
      this.setState({ term: enteredTerm });
      loadManagerSuggestions(enteredTerm);
    };

    return (
      <>
        <div className="ChangeRequestFormField">
          <label htmlFor={field}>{labelText}</label>
          <PeoplePicker
            placeholder={placeholder}
            term={term}
            updateTerm={updateTerm}
            selectedPeople={form.buildingManagers}
            updateSelectedPeople={updateSelectedManagers}
            people={managersSuggestions}
            fetchPeople={() => { }}
            classes="input"
            loading={isResponsiblesLoading}
          />
        </div>
        {!isValid.buildingManagers
          && formValidation.renderValidationError(this, 'common.required')}
      </>
    );
  };

  renderBuildingOwner = () => {
    const { form, isValid } = this.state;
    const { translate } = this.props;
    const labelText = `${translate('admin.settings.buildings.buildingOwner')}
     ${translate('common.requiredField')}`;

    const options = form.buildingManagers.map(manager => (
      {
        key: manager.id,
        name: manager.name
      }
    ));

    // sorted by name asc
    options.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

    const renderOptions = () => options.map(option => (
      <Option value={option.key} key={option.key}>
        {option.name}
      </Option>
    ));

    // Check and handle selected buildingOwner based on existing buildingManagers
    let isSelectedOwnerAmanager = false;
    form.buildingManagers.forEach(({ id }) => {
      if (id === form.buildingOwner.key) {
        isSelectedOwnerAmanager = true;
      }
    });

    // Set buildingOwner with default values when none buildingManager has been selected
    if (!isSelectedOwnerAmanager && form.buildingOwner.key !== '-') {
      formValidation.validateField(this, 'buildingOwner', {
        key: '-',
        name: '-'
      });
      formValidation.validateForm(this, 'buildingOwner', {
        key: '-',
        name: '-'
      });
    }

    const handleChange = event => {
      formValidation.validateField(this, 'buildingOwner', {
        key: event.selectedOption.value,
        name: event.selectedOption.text
      });
      formValidation.validateForm(this, 'buildingOwner', {
        key: event.selectedOption.value,
        name: event.selectedOption.text
      });
    };

    return (
      <>
        <Select
          label={labelText}
          className="input"
          placeholder={form.buildingOwner.name}
          options={options}
          onChange={handleChange}
          value={form.buildingOwner}
        >
          {renderOptions()}
        </Select>
        {!isValid.buildingOwner
          && formValidation.renderValidationError(this, 'common.required')}
      </>
    );
  };

  renderFloors = () => {
    const { openFloorDeleteDialog, openFloorCreateModal } = this.props;
    const { form, rendering } = this.state;

    const getNewFloor = data => {
      form.floors.push(data);

      formValidation.validateField(this, 'floors', form.floors);
      formValidation.validateForm(this, 'floors', form.floors);

      this.setState(prevState => ({
        rendering: prevState.rendering + 1
      }));
    };

    const removeSelectedFloor = data => {
      const index = form.floors.indexOf(data);
      if (index > -1) { // only splice array when item is found
        form.floors.splice(index, 1);
      }

      formValidation.validateField(this, 'floors', form.floors);
      formValidation.validateForm(this, 'floors', form.floors);

      this.setState(prevState => ({
        rendering: prevState.rendering + 1
      }));
    };

    const updateFloor = data => {
      const index = form.floors.indexOf(form.floors.find(floor => floor.name === data.previousName));

      if (index > -1) { // only splice array when item is found
        form.floors[index].name = data.name;
      }

      formValidation.validateField(this, 'floors', form.floors);
      formValidation.validateForm(this, 'floors', form.floors);

      this.setState(prevState => ({
        rendering: prevState.rendering + 1
      }));
    };

    return (
      <div className="FloorsTableForm">
        <FloorsTable
          key={rendering}
          floors={form.floors}
          actionOnClick={openFloorDeleteDialog}
          isNewBuilding
          updateFloor={updateFloor} />
        <NewFloorButton actionOnClick={openFloorCreateModal} />
        <FloorsDeleteDialog isNewBuilding removeFloor={removeSelectedFloor} />
        <FloorsCreateModal floors={form.floors} isNewBuilding sendNewFloor={getNewFloor} />
        <FloorUpdateModal floors={form.floors} isNewBuilding updateFloor={updateFloor} />
      </div>
    );
  };

  renderUpdateButton = () => {
    const { submit, isSubmitting, history, translate } = this.props;
    const { form, formIsValid } = this.state;

    const onClick = async event => {
      event.preventDefault();
      event.stopPropagation();

      if (formIsValid) {
        const payload = {
          key: form.key,
          name: form.name,
          zip: form.cip,
          city: form.city,
          regionId: form.region.id,
          managers: form.buildingManagers.map(responsible => ({
            accountName: responsible.accountName,
            displayName: responsible.name,
            email: responsible.email,
            isResponsibleOwner: responsible.id === form.buildingOwner.key
          })),
          floors: form.floors
        };

        const result = await submit(form.id, payload);
        if (result === 'Ok') {
          history.replace('/admin/settings/buildings');
        }
      }
    };

    return (
      <div className="button--right">
        <Button
          icon="074-save"
          label={translate('common.update')}
          loading={isSubmitting}
          onClick={onClick}
          disabled={!formIsValid} />
      </div>
    );
  };

  render() {
    const { history, translate } = this.props;
    return (
      <>
        <Header
          onClose={() => { history.push('/admin/settings/buildings'); }}
          title={translate('admin.settings.buildings.update.title')}
          iconRight
          icon="022-close" />
        <div className="Update__Form">
          {this.renderKey()}
          {this.renderName()}
          {this.renderRegion()}
          {this.renderZipAndCity()}
          {this.renderBuildingManagers()}
          {this.renderBuildingOwner()}
          {this.renderFloors()}
          {this.renderUpdateButton()}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: authSelectors.isAdministrator(state),
  regions: regionsSelectors.getRegions(state),
  buildings: buildingsSelectors.getBuildings(state),
  isResponsiblesLoading: createResourceSelectors.isPeopleLoading(state),
  managersSuggestions: createResourceSelectors.getPeopleSuggestions(state)
});

const mapDispatchToProps = dispatch => ({
  loadRegions: () => dispatch(regionsOperations.updateRegions()),
  loadManagerSuggestions: term => dispatch(createResourceOperations.loadPeopleSuggestions(term)),
  updateManagers: (field, value) => dispatch(createResourceOperations.updateFormValue(field, value)),
  submit: (id, payload) => dispatch(buildingsOperations.updateBuilding(id, payload)),
  openFloorDeleteDialog: () => dispatch(modalOperations.pushModal(modalKeys.FLOOR_DELETE_DIALOG)),
  openFloorCreateModal: () => dispatch(modalOperations.pushModal(modalKeys.FLOOR_CREATE_DIALOG))
});

BuildingsUpdate.propTypes = {
  match: PropTypes.shape().isRequired,
  buildings: PropTypes.objectOf(PropTypes.any),
  isAdmin: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  regions: PropTypes.objectOf(PropTypes.any),
  isResponsiblesLoading: PropTypes.bool,
  managersSuggestions: PropTypes.arrayOf(PropTypes.any),
  loadRegions: PropTypes.func.isRequired,
  loadManagerSuggestions: PropTypes.func.isRequired,
  updateManagers: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired,
  openFloorCreateModal: PropTypes.func.isRequired,
  openFloorDeleteDialog: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(BuildingsUpdate));
