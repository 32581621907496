import config from '../config';
import fetch from './fetch';

const getUrl = path => `${config.remoteUrl}/${path}`;

async function post(searchRequest, type) {
  const url = getUrl(`availability/alternatives/${type}`);
  return fetch(url, 'POST', searchRequest);
}

export default {
  post
};
