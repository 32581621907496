import React, { Component } from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/fp/includes';

import { BookFormSubmit, BookFormMaster, BookFormNotes, BookFormProfile2 } from '../BookForm';
import { BookDetailSidebar } from '../BookDetail';
import BookDetailPrivate from '../BookDetail/BookDetailPrivate';
import BookFormRecurrenceUpdate from '../BookForm/BookFormRecurrence/BookFormRecurrenceUpdate';

import Header from '../../Header';

import { ResourceImages, Spacing } from '../../common';

class RecurrenceUpdateModalMobile extends Component {
  renderBody = () => (
    <div className="BookModal__Content">
      <BookFormMaster mobile {...this.props} update />
    </div>
  );

  renderSidebar = () => {
    const { selectedMaster } = this.props;
    const isProfile2 = includes('Request', selectedMaster.resource.permissionTypes);
    const {
      resource: { notes }
    } = selectedMaster;

    return (
      <>
        <BookDetailSidebar resource={selectedMaster.resource} seating={selectedMaster.seating} />
        {selectedMaster.isPrivate && <BookDetailPrivate />}
        {isProfile2 && <BookFormProfile2 resource={selectedMaster.resource} />}
        {notes && (
          <Spacing horizontal="1">
            <BookFormNotes notes={notes} />
          </Spacing>
        )}
      </>
    );
  };

  renderSubmit = () => {
    const { updateMaster, isLoading } = this.props;
    return (
      <BookFormSubmit
        onClick={updateMaster}
        isReadyToSubmit
        isSubmitting={isLoading}
        profile2={false}
        mobile
        update
      />
    );
  };

  render() {
    const { selectedMaster, goBackToDetail } = this.props;
    return (
      <>
        <Header
          title={selectedMaster.resource.displayName}
          onClose={goBackToDetail}
          dynamic
          fixedMobile
          icon="014-chevron-left"
        />

        <div className="BookModalMobile">
          <ResourceImages resource={selectedMaster.resource} />
          <div className="BookModal__Main">
            {this.renderBody()}
            <hr className="BookModal__Divider" style={{ marginBottom: 20 }} />
            <div className="BookModal__Content">
              <BookFormRecurrenceUpdate />
            </div>
            <hr className="BookModal__Divider" style={{ marginTop: 20, marginBottom: 20 }} />
            <div className="BookModal__Content">{this.renderSidebar()}</div>
          </div>
        </div>

        {this.renderSubmit()}
      </>
    );
  }
}

RecurrenceUpdateModalMobile.propTypes = {
  selectedMaster: PropTypes.shape(),
  updateMaster: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  goBackToDetail: PropTypes.func.isRequired
};

export default RecurrenceUpdateModalMobile;
