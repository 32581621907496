import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, withTranslation } from '../common';
import styles from './WelcomeDoNotShow.module.css';

function WelcomeDoNotShow({ doNotShow, toggleDoNotShow, translate }) {
  const ignore = translate('welcome.ignore');
  return (
    <div className={styles.checkbox}>
      <Checkbox label={ignore} checked={doNotShow} onChange={toggleDoNotShow} />
    </div>
  );
}

WelcomeDoNotShow.propTypes = {
  doNotShow: PropTypes.bool.isRequired,
  toggleDoNotShow: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(WelcomeDoNotShow);
