import store from 'store2';

import selectors from './selectors';
import actions from './actions';
import { regionsService } from '../../services';
import normalizeData from './schema';

import { notificationsOperations, notificationsSelectors } from '../notifications';
import { locationsOperations, locationsSelectors } from '../locations';
import { availabilityOperations } from '../availability';

const persistData = (dispatch, state) => ({ regions }) => {
  store('regions', regions);
  dispatch(actions.updateRegions(regions));
  dispatch(actions.updateIsLoaded(Date.now()));

  const bootstrapped = store('bootstrapped');
  if (locationsSelectors.getLocationNames(state).length === 0 && !bootstrapped) {
    dispatch(locationsOperations.setToDefault());
    dispatch(availabilityOperations.loadAvailabilities());
    store('bootstrapped', true);
  }
};

const updateRegions = () => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const persist = persistData(dispatch, state);

  const age = selectors.getAge(state);
  const tenMinutes = 1000 * 60 * 10;

  if (!age || age + tenMinutes < Date.now()) {
    return regionsService
      .get()
      .then(response => {
        const data = normalizeData(response);
        persist(data.entities);
      })
      .catch(serverError => {
        const error = getNotification('regions.error', serverError);
        dispatch(notificationsOperations.showError(error));
      });
  }
  return null;
};

const addRegion = (key, name) => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return regionsService
    .addRegion({ Key: key, Name: name })
    .then(() => {
      const persist = persistData(dispatch, state);
      return regionsService
        .get()
        .then(response => {
          const success = getNotification('admin.settings.regions.add.success');
          dispatch(notificationsOperations.showSuccess(success));
          const data = normalizeData(response);
          persist(data.entities);
          return 'Ok';
        })
        .catch(serverError => {
          const error = getNotification('admin.settings.regions.add.error', serverError);
          dispatch(notificationsOperations.showError(error));
          return 'Error';
        });
    })
    .catch(serverError => {
      const error = getNotification('regions.error', serverError);
      dispatch(notificationsOperations.showError(error));
      return 'Error';
    });
};

const updateRegion = (id, key, name) => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return regionsService
    .updateRegion(id, { Key: key, Name: name })
    .then(() => {
      const persist = persistData(dispatch, state);
      return regionsService
        .get()
        .then(response => {
          const success = getNotification('admin.settings.regions.update.success');
          dispatch(notificationsOperations.showSuccess(success));
          const data = normalizeData(response);
          persist(data.entities);
          return 'Ok';
        })
        .catch(serverError => {
          const error = getNotification('admin.settings.regions.update.error', serverError);
          dispatch(notificationsOperations.showError(error));
          return 'Error';
        });
    })
    .catch(serverError => {
      const error = getNotification('regions.error', serverError);
      dispatch(notificationsOperations.showError(error));
      return 'Error';
    });
};

const setSelectedRegion = region => dispatch => {
  dispatch(actions.setSelectedRegion(region));
};

const deleteRegion = () => (dispatch, getState) => {
  const state = getState();
  const { selectedRegion } = state.regionsState.regions;

  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return regionsService
    .remove(selectedRegion.key)
    .then(async () => {
      const success = getNotification('admin.settings.regions.delete.success');
      dispatch(notificationsOperations.showSuccess(success));
      const regions = await regionsService.get();
      dispatch(actions.updateRegions(regions));
      return Promise.resolve();
    })
    .catch(serverError => {
      const error = getNotification('admin.settings.regions.delete.error', serverError);
      error.text = serverError.message;
      dispatch(notificationsOperations.showError(error));
      return Promise.resolve(serverError);
    });
};

export default {
  updateRegions,
  addRegion,
  updateRegion,
  setSelectedRegion,
  deleteRegion
};
