/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import withTranslation from '../I18n/withTranslation';
import Profile3Info from './Profile3Info';

import './_style.css';

function Profile3({ resource, book, path, translate, responseType }) {
  const { managedBy, roomType } = resource;

  const isRoomType = type => roomType && roomType.toLowerCase() === type;
  if (isRoomType('surfacehub') || isRoomType('telepresence')) {
    return (
      <Profile3Info>
        <span>{translate(`${path}.${roomType.toLowerCase()}`)}</span>
      </Profile3Info>
    );
  }

  if (!managedBy) {
    return (
      <Profile3Info>
        <span>{translate(`${path}.managedNull`)}</span>
      </Profile3Info>
    );
  }

  if (responseType.toLowerCase() === 'declined') {
    const { displayName } = managedBy;
    const resourceType = resource.resourceType.toLowerCase();
    const info = translate(`search.detail.profile2.declined.${resourceType}`, {
      displayName
    });
    return (
      <Profile3Info className="Profile3--declined">
        <span>{info}</span>
      </Profile3Info>
    );
  }

  const { phoneNumber, displayName } = managedBy;
  const key = book ? 'book' : 'managed';
  return (
    <Profile3Info>
      <span
        dangerouslySetInnerHTML={{
          __html: translate(`${path}.${key}`, { phoneNumber, displayName })
        }}
      />
    </Profile3Info>
  );
}

Profile3.propTypes = {
  resource: PropTypes.shape({
    managedBy: PropTypes.shape({
      phoneNumber: PropTypes.string,
      displayName: PropTypes.string
    }),
    roomType: PropTypes.string,
    resourceType: PropTypes.string
  }),
  path: PropTypes.string,
  translate: PropTypes.func.isRequired,
  book: PropTypes.bool,
  responseType: PropTypes.string
};

Profile3.defaultProps = {
  path: 'search.detail.profile3.room',
  responseType: 'Accepted'
};

export default withTranslation(Profile3);
