const TOGGLE_FILTER = 'roomFilter/TOGGLE_FILTER';
const CHANGE_FILTER = 'roomFilter/CHANGE_FILTER';
const RESET_DIRTY = 'roomFilter/RESET_DIRTY';
const RESET_FILTER = 'roomFilter/RESET_FILTER';
const UPDATE_FLOORS = 'roomFilter/UPDATE_FLOORS';
const UPDATE_SECTORS = 'roomFilter/UPDATE_SECTORS';
const UPDATE_ROOMTYPES = 'roomFilter/UPDATE_ROOMTYPES';

export default {
  TOGGLE_FILTER,
  CHANGE_FILTER,
  RESET_DIRTY,
  RESET_FILTER,
  UPDATE_FLOORS,
  UPDATE_SECTORS,
  UPDATE_ROOMTYPES
};
