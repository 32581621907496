import { createUserManager } from './auth';
import { detectLanguage, changeBrowserLanguage } from './i18n';
import renderApp from './renderApp';
import registerServiceWorker from './registerServiceWorker';
import loadConfiguration from './services/loadConfiguration';

let cache = null;

const importTranslation = lang => import(`./i18n/translations.${lang}`);
const loadTranslations = lang => () => importTranslation(lang).then(({ translations }) => translations);

const load = (...loaders) => Promise.all(loaders.map(loader => loader()));

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept('./views/App', () => {
    const { translations, userManager } = cache;
    renderApp(translations, userManager);
  });
}

const language = detectLanguage();
changeBrowserLanguage(language);

load(loadTranslations(language), createUserManager).then(([translations, userManager]) => {
  cache = { translations, userManager };
  renderApp(translations, userManager);
});

loadConfiguration();

registerServiceWorker();
