import React from 'react';
import PropTypes from 'prop-types';
import { ShowAt } from '../common';
import FeedbackMobile from './FeedbackMobile';
import FeedbackDesktop from './FeedbackDesktop';

function Feedback(props) {
  return (
    <div className="Feedback">
      <ShowAt breakpoint="mobileAndBelow">
        <FeedbackMobile {...props} />
      </ShowAt>

      <ShowAt breakpoint="desktopAndAbove">
        <FeedbackDesktop {...props} />
      </ShowAt>
    </div>
  );
}

const getPropTypes = {
  happyComment: PropTypes.string.isRequired,
  sadComment: PropTypes.string.isRequired,
  submitted: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  updateHappyComment: PropTypes.func.isRequired,
  updateSadComment: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  hasFeedback: PropTypes.bool.isRequired,
  hasTriedToSubmit: PropTypes.bool.isRequired
};

export { getPropTypes };
export default Feedback;
