import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon, TimeInputRange, CustomPropTypes } from '../../../../common';

import './_style.css';

function TimeSection({ time, updateTime, className, updateIsValidTimeRange }) {
  const props = {
    values: time,
    onChange: updateTime,
    updateIsValidTimeRange
  };

  const classes = cx('TimeSection', className);
  return (
    <div className={classes}>
      <div className="TimeSection__Icon">
        <Icon icon="021-clock" />
      </div>
      <div className="TimeSection__Input">
        <TimeInputRange {...props} />
      </div>
    </div>
  );
}

TimeSection.propTypes = {
  date: PropTypes.string,
  time: CustomPropTypes.time,
  updateTime: PropTypes.func.isRequired,
  className: PropTypes.string,
  updateIsValidTimeRange: PropTypes.func.isRequired
};

export default TimeSection;
