import { createSelector } from 'reselect';
import { buildingsSelectors } from '../../buildings';

const getMaster = state => state.recurrenceMasterState.master;
const getCachedMaster = state => state.recurrenceMasterState.cachedMaster;
const isLoading = state => state.recurrenceMasterState.isLoading;
const getBuildings = state => buildingsSelectors.getBuildings(state);

const isSubjectValid = state => {
  if (!state.recurrenceMasterState.master) {
    return true;
  }
  const { subject } = state.recurrenceMasterState.master;
  return subject && subject.length > 0 && subject.length < 255;
};

const isMessageValid = state => {
  if (!state.recurrenceMasterState.master) {
    return true;
  }
  const { message } = state.recurrenceMasterState.master;
  return message.length < 2047;
};

const getSelectedMaster = createSelector(
  [getMaster, getBuildings],
  (master, buildings) => {
    if (master == null) {
      return null;
    }

    const { resource } = master;
    const building = buildings[resource.buildingId];
    return { ...master, resource: { ...resource, building } };
  }
);

export default {
  getSelectedMaster,
  getCachedMaster,
  isLoading,
  isSubjectValid,
  isMessageValid
};
