import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { withTranslation, Icon } from '../../../common';

import '../_style.css';

function UpdateFloorButton({ actionOnClick }) {
  return <Icon icon="025-edit" className="Edit__Icon" onClick={actionOnClick} />;
}

UpdateFloorButton.propTypes = {
  actionOnClick: PropTypes.func.isRequired
};

export default withTranslation(withRouter(UpdateFloorButton));
