// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsFormInputGroup_body__8Ee8m {\n    padding: 10px 5px;\n}\n\n.NewsFormInputGroup_heading__IB8rQ {\n    margin-bottom: 4px;\n    max-width: 100%;\n    color: var(--color-black);\n    font-weight: 500;\n    font-size: 1rem;\n}\n\n.NewsFormInputGroup_input__hiNbP {\n    padding-bottom: 10px;\n}\n\n.NewsFormInputGroup_requiredText__xhYbR {\n    font-size: 13px;\n    color: var(--color-theme);\n}", "",{"version":3,"sources":["webpack://./src/views/Admin/NewsForm/NewsFormInputGroup/NewsFormInputGroup.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":[".body {\n    padding: 10px 5px;\n}\n\n.heading {\n    margin-bottom: 4px;\n    max-width: 100%;\n    color: var(--color-black);\n    font-weight: 500;\n    font-size: 1rem;\n}\n\n.input {\n    padding-bottom: 10px;\n}\n\n.requiredText {\n    font-size: 13px;\n    color: var(--color-theme);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "NewsFormInputGroup_body__8Ee8m",
	"heading": "NewsFormInputGroup_heading__IB8rQ",
	"input": "NewsFormInputGroup_input__hiNbP",
	"requiredText": "NewsFormInputGroup_requiredText__xhYbR"
};
export default ___CSS_LOADER_EXPORT___;
