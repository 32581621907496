import last from 'lodash/fp/last';

const append = (prev, current) => {
  if (prev.length && current.length) {
    return `${prev}, ${current}`;
  }

  return current.length ? current : prev;
};

const getTranslatedResources = (collection, key, translate) => collection
  .map(item => translate(`${key}.${item.name}`))
  .reduce((prev, current) => (prev.length > 0 ? `${prev} & ${current}` : current), '');

const getCapacitySummary = (roomFilter, translate) => {
  const selectedCapacities = roomFilter.filter(filter => filter.type === 'capacity');
  const capacities = selectedCapacities.reduce((prev, current) => {
    let result = '';
    if (prev.length === 0) {
      result = current.description;
    } else if (last(prev) === current.min.toString()) {
      result = prev.slice(0, -1) + current.max;
    } else {
      result = `${prev}, ${current.description}`;
    }
    return result;
  }, '');

  return capacities.length > 0 ? translate('common.persons', { count: capacities }) : '';
};

const getRoomTypeSummary = (roomFilter, translate) => {
  const selectedRoomTypes = roomFilter.filter(filter => filter.type === 'roomType');
  return getTranslatedResources(selectedRoomTypes, 'roomTypes', translate);
};

const getEquipmentSummary = (roomFilter, translate) => {
  const selectedEquipments = roomFilter.filter(filter => filter.type === 'equipment');
  return getTranslatedResources(selectedEquipments, 'equipment', translate);
};

const getRoomFilterSummary = (roomFilter, translate) => {
  const capacitySummary = getCapacitySummary(roomFilter, translate);
  const roomTypeSummary = getRoomTypeSummary(roomFilter, translate);
  const equipmentSummary = getEquipmentSummary(roomFilter, translate);

  let result = '';
  result = capacitySummary.length > 0 ? capacitySummary : '';
  result = append(result, roomTypeSummary);
  result = append(result, equipmentSummary);

  return result;
};

export default getRoomFilterSummary;
