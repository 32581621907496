/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { authSelectors } from '../../../../state/auth';
import {
  regionsSelectors,
  regionsOperations
} from '../../../../state/regions';
import RegionsLoader from './RegionsLoader';
import { withTranslation, Spacing } from '../../../common';
import RegionsTable from './RegionsTable';
import { modalOperations } from '../../../../state/modal';
import modalKeys from './modalKeys';

import NewRegionButton from './NewRegionButton';

class Regions extends Component {
  constructor() {
    super();
    this.state = {
      hasScroll: false
    };
  }

  componentDidMount() {
    const { loadRegions, isAdmin, history } = this.props;
    if (!isAdmin) {
      history.replace('/unauthorized');
    }
    loadRegions();
  }

  renderLoader = () => {
    const { isLoaded } = this.props;
    return <RegionsLoader visible={!isLoaded} />;
  };

  separatorShadow = { boxShadow: 'none' };

  onTableScrollBar = hasScroll => {
    if (hasScroll) {
      this.separatorShadow = {
        boxShadow: '0px -2px 8px 0px rgba(0, 0, 0, 0.2)'
      };
    } else {
      this.separatorShadow = { boxShadow: 'none' };
    }
    this.setState({ hasScroll });
  };

  renderRegions = () => {
    const { isLoaded, hasRegions, regions, openRegionDeleteDialog } = this.props;
    const showRegions = isLoaded && hasRegions;
    if (!showRegions) {
      return null;
    }

    return (
      <div className="Settings__Table">
        <RegionsTable
          onTableScrollBar={this.onTableScrollBar}
          regions={regions}
          actionOnClick={openRegionDeleteDialog} />
      </div>
    );
  };

  render() {
    const { translate } = this.props;
    return (
      <Spacing horizontal="1" vertical="1">
        <div className="Settings__TableHead">
          <h3 className="Settings__Title">
            {translate('regions.table.title')}
          </h3>
        </div>
        {this.renderLoader()}
        {this.renderRegions()}
        <div className="button--right--container" style={this.separatorShadow}>
          <div className="button--right">
            <NewRegionButton />
          </div>
        </div>
      </Spacing>
    );
  }
}

Regions.defaultProps = {
  isAdmin: false
};

const mapStateToProps = state => ({
  isLoaded: regionsSelectors.isLoaded(state),
  regions: regionsSelectors.getRegions(state),
  hasRegions: regionsSelectors.hasRegions(state),
  isAdmin: authSelectors.isAdministrator(state)
});

const mapDispatchToProps = dispatch => ({
  loadRegions: () => dispatch(regionsOperations.updateRegions()),
  openRegionDeleteDialog: () => dispatch(modalOperations.pushModal(modalKeys.REGION_DELETE_DIALOG))
});

Regions.propTypes = {
  isAdmin: PropTypes.bool,
  isLoaded: PropTypes.bool,
  regions: PropTypes.objectOf(PropTypes.any),
  hasRegions: PropTypes.bool,
  loadRegions: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  openRegionDeleteDialog: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(withRouter(Regions)));
