/* eslint-disable class-methods-use-this */

import store from 'store2';
import * as StackTrace from 'stacktrace-js';

import config from '../config';
import fetch from './fetch';

const levels = ['debug', 'info', 'warn', 'error', 'fatal'];

const isValidLevel = level => levels.some(current => current === level);

const getLogs = () => store('logs');
const setLogs = logs => store('logs', logs);
const clearLogs = () => setLogs([]);
const appendLog = log => setLogs([...getLogs, log]);

const getUrl = path => `${config.remoteUrl}/${path}`;

async function post(payload) {
  const url = getUrl('errors');
  return fetch(url, 'POST', payload);
}

class Logger {
  constructor() {
    this.options = {
      level: 'error',
      timeout: 3000
    };
  }

  get level() {
    const { level } = this.options;
    const userdefined = window.LOG_LEVEL;
    return userdefined && typeof userdefined === 'string' && isValidLevel(userdefined)
      ? userdefined
      : level;
  }

  get timeout() {
    const { timeout } = this.options;
    return timeout;
  }

  scheduleSending = () => {
    if (!this.pending) {
      setTimeout(this.sendAll, this.timeout);
      this.pending = true;
    }
  };

  sendAll = () => {
    const logs = getLogs();
    clearLogs();

    const promises = logs.map(log => post(log).catch(error => {
      // eslint-disable-next-line
        console.error(error, 'could not send log to server');
      appendLog(log);
    }));

    return Promise.all(promises).then(() => {
      this.pending = false;
    });
  };

  append = log => {
    const { level } = log;
    if (levels.indexOf(level) >= levels.indexOf(this.level)) {
      appendLog(log);
    }
  };

  log = (level, message, error, componentStack) => {
    if (!isValidLevel(level)) {
      throw new Error('not a valid log level');
    }

    const dateTime = new Date();

    const getStackFrame = error ? StackTrace.fromError(error) : Promise.resolve();
    getStackFrame
      .then(stacktrace => {
        this.append({
          dateTime,
          level,
          message,
          stacktrace,
          componentStack
        });
      })
      .then(() => this.scheduleSending());
  };

  debug = message => {
    this.log('debug', message);
  };

  info = message => {
    this.log('info', message);
  };

  warn = message => {
    this.log('warn', message);
  };

  error = (message, error, componentStack) => {
    const msg = error ? `${message}. ${error.message}` : message;
    this.log('error', msg, error, componentStack);
  };

  fatal = (message, error, componentStack) => {
    const msg = error ? `${message}. ${error.message}` : message;
    this.log('fatal', msg, error, componentStack);
  };
}

const logger = new Logger();

export default {
  debug: logger.debug,
  info: logger.info,
  warn: logger.warn,
  error: logger.error,
  fatal: logger.fatal
};
