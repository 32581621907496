import React from 'react';

import { CustomPropTypes, EquipmentType } from '../../../common';

import './_style.css';

function EquipmentNameAndType({ equipment }) {
  const { displayName, resourceSubType, building } = equipment;

  return (
    <div>
      <div className="RoomNameAndType__Room">
        <span className="RoomNameAndType__RoomName">{`${displayName},`}</span>
        <span>&nbsp;</span>
        <span className="RoomNameAndType__RoomBuilding">{building.key}</span>
      </div>
      <p>
        <EquipmentType resourceSubType={resourceSubType} />
      </p>
    </div>
  );
}

EquipmentNameAndType.propTypes = {
  equipment: CustomPropTypes.resource
};

export default EquipmentNameAndType;
