import config from '../config';
import fetch from './fetch';

const getUrl = path => `${config.remoteUrl}/${path}`;

async function get(id) {
  const url = getUrl(`bookings/recurrence?calendarid=${encodeURIComponent(id)}`);
  return fetch(url, 'GET');
}

export default get;
