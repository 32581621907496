import types from './types';

const reset = () => ({
  type: types.RESET
});

const updateReason = reason => ({
  type: types.UPDATE_REASON,
  reason
});

const updateIsSubmitting = isSubmitting => ({
  type: types.UPDATE_IS_SUBMITTING,
  isSubmitting
});

const updateSubmitted = () => ({
  type: types.UPDATE_SUBMITTED
});

export default {
  reset,
  updateReason,
  updateSubmitted,
  updateIsSubmitting
};
