import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../../common';

import './_style.css';

function MyChangeRequestsLoader({ visible }) {
  if (!visible) {
    return null;
  }

  return (
    <div className="MyChangeRequests__Loader">
      <Loader visible />
    </div>
  );
}

MyChangeRequestsLoader.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default MyChangeRequestsLoader;
