// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WelcomeButton_modal__25aaU {\n  width: 100vw;\n  height: 60px;\n}\n\n.WelcomeButton_modal__25aaU:active {\n  -webkit-transform: none;\n          transform: none;\n}\n\n.WelcomeButton_dialog__DNKat {\n  position: absolute;\n  left: 0;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/views/Welcome/WelcomeButton.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,uBAAe;UAAf,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,WAAW;AACb","sourcesContent":[".modal {\n  width: 100vw;\n  height: 60px;\n}\n\n.modal:active {\n  transform: none;\n}\n\n.dialog {\n  position: absolute;\n  left: 0;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "WelcomeButton_modal__25aaU",
	"dialog": "WelcomeButton_dialog__DNKat"
};
export default ___CSS_LOADER_EXPORT___;
