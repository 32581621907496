import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  FormField,
  FormFieldNumber,
  FormFieldTextArea,
  FormFieldSelect,
  FormFieldBoolean,
  FormFieldReason,
  FormFieldPerson,
  FormFieldReadOnly
} from '../../FormElements';
import {
  getResourceSubTypeNameTranslation,
  getResourceSubTypeOptions
} from '../../../../util';

class MasterDataForm extends Component {
  renderFloor = () => {
    const { floor, isSubmitted, readOnly, updateFormValue } = this.props;

    if (readOnly) {
      return <FormFieldReadOnly values={floor} field="floor" text={floor.new} />;
    }
    return (
      <FormField field="floor" values={floor} update={updateFormValue} isSubmitted={isSubmitted} />
    );
  };

  renderSector = () => {
    const { sector, isSubmitted, readOnly, updateFormValue } = this.props;

    if (readOnly) {
      return <FormFieldReadOnly values={sector} field="sector" text={sector.new} />;
    }
    return (
      <FormField
        field="sector"
        values={sector}
        update={updateFormValue}
        isSubmitted={isSubmitted}
        optional
      />
    );
  };

  renderRoomType = () => {
    const {
      resourceSubType,
      resourcesSubTypes,
      updateFormValue,
      readOnly,
      isSubmitted
    } = this.props;

    const placeholder = ' - ';

    if (readOnly) {
      const resourceSubTypeObject = Object.values(resourcesSubTypes).filter(_ => _.id === resourceSubType.new)[0];
      return (
        <FormFieldReadOnly
          values={resourceSubType}
          field="resourceSubType"
          text={getResourceSubTypeNameTranslation(resourceSubTypeObject)} />
      );
    }
    return (
      <FormFieldSelect
        field="resourceSubType"
        placeholder={placeholder}
        options={getResourceSubTypeOptions(resourcesSubTypes, 'Room')}
        values={resourceSubType}
        update={updateFormValue}
        isSubmitted={isSubmitted}
      />
    );
  };

  renderCapacity = () => {
    const { capacity, isSubmitted, readOnly, updateFormValue } = this.props;
    if (readOnly) {
      return <FormFieldReadOnly values={capacity} field="capacity" text={capacity.new} />;
    }
    return (
      <FormFieldNumber
        field="capacity"
        max={1000}
        values={capacity}
        update={updateFormValue}
        isSubmitted={isSubmitted}
      />
    );
  };

  renderEquipmentType = () => {
    const {
      resourceSubType,
      resourcesSubTypes,
      isSubmitted,
      updateFormValue,
      readOnly,
      resource
    } = this.props;

    const placeholder = ' - ';

    if (readOnly) {
      return (
        <FormFieldReadOnly
          values={resourceSubType}
          field="resourceSubType"
          text={getResourceSubTypeNameTranslation(resource.resourceSubType)} />
);
    }
    return (
      <FormFieldSelect
        field="resourceSubType"
        placeholder={placeholder}
        options={getResourceSubTypeOptions(resourcesSubTypes, 'Equipment')}
        values={resourceSubType}
        update={updateFormValue}
        isSubmitted={isSubmitted}
      />
    );
  };

  renderRoomEquipment = () => {
    const {
      roomEquipment,
      roomEquipmentOptions,
      isSubmitted,
      updateFormValue,
      readOnly,
      translate
    } = this.props;
    const options = roomEquipmentOptions.map(option => ({
      key: option,
      name: translate(`equipment.${option}`)
    }));
    const placeholder = ' - ';

    if (readOnly) {
      const equipments = (roomEquipment && roomEquipment.new)
        .map(equipment => translate(`equipment.${equipment}`))
        .join('; ');
      return <FormFieldReadOnly values={roomEquipment} field="roomEquipment" text={equipments} />;
    }
    return (
      <FormFieldSelect
        field="roomEquipment"
        placeholder={placeholder}
        options={options}
        values={roomEquipment}
        update={updateFormValue}
        isSubmitted={isSubmitted}
        optional
        multiple
      />
    );
  };

  renderNotes = () => {
    const { notes, updateFormValue, readOnly } = this.props;
    if (readOnly) {
      return <FormFieldReadOnly values={notes} field="notes" text={notes.new} />;
    }
    return <FormFieldTextArea field="notes" values={notes} update={updateFormValue} optional />;
  };

  renderDaylight = () => {
    const { daylight, readOnly, translate, isSubmitted, updateFormValue } = this.props;
    if (readOnly) {
      const text = daylight.new ? translate('common.yes') : translate('common.no');
      return <FormFieldReadOnly values={daylight} field="daylight" text={text} />;
    }
    return (
      <FormFieldBoolean
        values={daylight}
        field="daylight"
        update={updateFormValue}
        isSubmitted={isSubmitted}
      />
    );
  };

   renderConfidentialMeetings = () => {
  //   const { confidentialMeetings, isSubmitted, readOnly, translate, updateFormValue } = this.props;
  //   if (readOnly) {
  //     const text = confidentialMeetings.new ? translate('common.yes') : translate('common.no');
  //     return (
  //       <FormFieldReadOnly values={confidentialMeetings} field="confidentialMeetings" text={text} />
  //     );
  //   }
  //   return (
  //     <FormFieldBoolean
  //       values={confidentialMeetings}
  //       field="confidentialMeetings"
  //       isSubmitted={isSubmitted}
  //       update={updateFormValue}
  //     />
  //    );
   };

  renderContact = () => {
    const {
      contact,
      contactSuggestions,
      loadContactSuggestions,
      isContactLoading,
      updateFormValue,
      readOnly,
      isSubmitted
    } = this.props;

    if (readOnly) {
      return (
        <FormFieldReadOnly
          values={contact}
          field="contact"
          text={contact.new && contact.new.name}
        />
      );
    }

    return (
      <FormFieldPerson
        field="contact"
        values={contact}
        suggestions={contactSuggestions}
        loadSuggestions={loadContactSuggestions}
        update={updateFormValue}
        isSubmitted={isSubmitted}
        isLoading={isContactLoading}
      />
    );
  };

  renderReason = () => {
    const { reason, isSubmitted, readOnly, updateFormValue } = this.props;
    if (readOnly) {
      return <FormFieldReadOnly values={reason} field="reason" text={reason.new} />;
    }
    return (
      <FormFieldReason value={reason.new} update={updateFormValue} isSubmitted={isSubmitted} />
    );
  };

  renderValidation = () => {
    const { isSubmitted, isValid, translate } = this.props;
    if (isSubmitted) {
      if (!isValid) {
        const validationError = translate('common.validationError');
        return <div className="MasterDataForm__ValidationError">{validationError}</div>;
      }
    }

    return null;
  };

  renderRoomFields = () => (
    <>
      {this.renderFloor()}
      {this.renderSector()}
      {this.renderRoomType()}
      {this.renderCapacity()}
      {this.renderRoomEquipment()}
      {this.renderDaylight()}
      {this.renderConfidentialMeetings()}
    </>
  );

  renderEquipmentFields = () => <>{this.renderEquipmentType()}</>;

  renderSpecificFields = () => {
    const { resource } = this.props;
    if (resource.resourceType === 'Room') {
      return this.renderRoomFields();
    }
    return this.renderEquipmentFields();
  };

  render() {
    return (
      <>
        {this.renderSpecificFields()}
        {this.renderContact()}
        {this.renderNotes()}
        {this.renderReason()}
        {this.renderValidation()}
      </>
    );
  }
}

MasterDataForm.propTypes = {
  resource: PropTypes.shape({
    resourceType: PropTypes.string.isRequired,
    resourceSubType: PropTypes.shape()
  }).isRequired,
  floor: PropTypes.shape(),
  sector: PropTypes.shape(),
  daylight: PropTypes.shape(),
  contact: PropTypes.shape(),
  resourceSubType: PropTypes.shape(),
  resourcesSubTypes: PropTypes.arrayOf(PropTypes.shape()),
  // confidentialMeetings: PropTypes.shape(),
  roomEquipment: PropTypes.shape(),
  roomEquipmentOptions: PropTypes.arrayOf(PropTypes.string),
  capacity: PropTypes.shape(),
  notes: PropTypes.shape(),
  reason: PropTypes.shape(),
  isValid: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  contactSuggestions: PropTypes.arrayOf(PropTypes.shape()),
  isContactLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  loadContactSuggestions: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

MasterDataForm.defaultProps = {
  readOnly: false
};

export default MasterDataForm;
