import exceptionsSelectors from './exceptionsSelectors';
import resourcesSelectors from './resourcesSelectors';
import emptyStateSelectors from './emptyStateSelectors';
import alternativesSelectors from './alternativesSelectors';

export default {
  ...exceptionsSelectors,
  ...resourcesSelectors,
  ...emptyStateSelectors,
  ...alternativesSelectors
};
