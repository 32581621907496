import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/fp/get';
import cx from 'classnames';

import { Tooltip, withTranslation, Icon } from '../../../../../common';
import { emitter, toTwoDigits, toLocaleDateString } from '../../../../../../util';

import TooltipProfileInformation from './TooltipProfileInformation';
import UnhandledRecurrenceException from './UnhandledRecurrenceException';

import './_style.css';

class CalendarBookingTooltip extends Component {
  constructor(props) {
    super(props);

    this.subscription = null;
    this.onShowTooltip = this.onShowTooltip.bind(this);
    this.state = { booking: null };
  }

  componentDidMount() {
    this.subscription = emitter.addListener('onCalendarBookingShow', this.onShowTooltip);
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  onShowTooltip(booking) {
    this.setState({ booking });
  }

  formatTime = (start, end) => {
    const hours = date => `${toTwoDigits(date.getHours())}`;
    const minutes = date => `${toTwoDigits(date.getMinutes())}`;
    const format = date => `${hours(date)}:${minutes(date)}`;
    return `${format(start)} - ${format(end)}`;
  };

  renderBody = booking => {
    const { subject } = booking;
    const building = get('resource.building.key', booking);
    const start = new Date(booking.start);
    const end = new Date(booking.end);
    const date = toLocaleDateString(start);
    const time = this.formatTime(start, end);

    return (
      <div className="CalendarBookingTooltip__Body">
        <div className="CalendarBookingTooltip__Subject">{subject}</div>

        <div className="CalendarBookingTooltip__SubHeader">
          <div className="CalendarBookingTooltip__Building">
            <span>{building}</span>
          </div>
        </div>

        <div className="CalendarBookingTooltip__DateTime">
          <div>{date}</div>
          <div>{time}</div>
        </div>
      </div>
    );
  };

  renderOrganizer = booking => {
    const { translate } = this.props;

    const { organizer, bookedFor } = booking;

    const organizerLabel = translate('search.organizer');
    const bookedForLabel = translate('search.detail.bookedFor.name');
    const showBookedFor = bookedFor != null;

    const classes = cx('CalendarBookingTooltip__Persons', {
      'CalendarBookingTooltip__Persons--big': showBookedFor
    });

    return (
      <div className={classes}>
        {showBookedFor && (
          <div className="CalendarBookingTooltip__Organizer">
            <strong>{bookedForLabel}</strong>
            {' '}
            {bookedFor.displayName}
          </div>
        )}
        <div className="CalendarBookingTooltip__Organizer">
          <strong>{organizerLabel}</strong>
          {' '}
          {organizer.displayName}
        </div>
      </div>
    );
  };

  renderProfileInformation = booking => {
    const { resource, roomMeetingResponseType, isUnhandledRecurrenceException } = booking;
    if (isUnhandledRecurrenceException) {
      return null;
    }
    return <TooltipProfileInformation resource={resource} responseType={roomMeetingResponseType} />;
  };

  renderUnhandledRecurrenceException = booking => {
    const { resource, isUnhandledRecurrenceException } = booking;
    if (!isUnhandledRecurrenceException) {
      return null;
    }
    return <UnhandledRecurrenceException resource={resource} />;
  };

  renderRecurring = booking => {
    const { isRecurring } = booking;
    if (!isRecurring) {
      return null;
    }

    const { translate } = this.props;
    const text = translate('search.detail.recurring.text');
    return (
      <div className="CalendarBookingTooltip__Recurring">
        <Icon icon="046-information-circle" className="CalendarBookingTooltip__RecurringIcon" />
        {text}
      </div>
    );
  };

  renderBookingCollection = booking => {
    const { isBookingCollection } = booking;
    if (!isBookingCollection) {
      return null;
    }

    const { translate } = this.props;
    const text = translate('bookingCollection.detail.tooltip');
    return (
      <div className="CalendarBookingTooltip__BookingCollection">
        <div className="CalendarBookingTooltip__BookingCollectionContent">
          <Icon icon="006-attachment" className="CalendarBookingTooltip__BookingCollectionIcon" />
          {text}
        </div>
      </div>
    );
  };

  renderPrivate = booking => {
    const { translate } = this.props;

    const { isPrivate } = booking;
    if (!isPrivate) {
      return null;
    }

    const text = translate('common.private');
    return (
      <div className="CalendarBookingTooltip__Private">
        <Icon icon="049-lock" className="CalendarBookingTooltip__PrivateIcon" />
        {text}
      </div>
    );
  };

  renderSeating = booking => {
    const { translate } = this.props;

    const { seating } = booking;
    if (!seating) {
      return null;
    }

    const text = translate('common.seating');
    return (
      <div className="CalendarBookingTooltip__Seating">
        <strong>
          {text}
          {': '}
        </strong>
        {seating.displayName}
      </div>
    );
  };

  render() {
    const { booking } = this.state;
    if (!booking) {
      return null;
    }

    return (
      <Tooltip id="CalendarBookingTooltip" className="CalendarBookingTooltip">
        <div className="CalendarBookingTooltip__Wrapper">
          {this.renderBody(booking)}
          {this.renderOrganizer(booking)}
          {this.renderSeating(booking)}
          {this.renderRecurring(booking)}
          {this.renderBookingCollection(booking)}
          {this.renderProfileInformation(booking)}
          {this.renderUnhandledRecurrenceException(booking)}
          {this.renderPrivate(booking)}
        </div>
      </Tooltip>
    );
  }
}

CalendarBookingTooltip.propTypes = {
  translate: PropTypes.func.isRequired
};

export default withTranslation(CalendarBookingTooltip);
