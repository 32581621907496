const SET_ACTIVE = 'recurrence/SET_ACTIVE';

const UPDATE_RECURRENCE_TYPE = 'recurrence/UPDATE_RECURRENCE_TYPE';

const UPDATE_INDEX_OF_WEEK = 'recurrence/UPDATE_INDEX_OF_WEEK';
const TOGGLE_DAY_OF_WEEK = 'recurrence/TOGGLE_DAY_OF_WEEK';

const UPDATE_INDEX_OF_MONTH = 'recurrence/UPDATE_INDEX_OF_MONTH';
const TOGGLE_WEEK_OF_MONTH = 'recurrence/TOGGLE_WEEK_OF_MONTH';
const TOGGLE_DAY_OF_MONTH = 'recurrence/TOGGLE_DAY_OF_MONTH';

const UPDATE_NUMBER_OF_BOOKINGS = 'recurrence/UPDATE_NUMBER_OF_BOOKINGS';
const UPDATE_RECURRENCE_END_DATE = 'recurrence/UPDATE_END_DATE';
const UPDATE_RECURRENCE_END_TYPE = 'recurrence/UPDATE_RECURRENCE_END_TYPE';

const CLEAR = 'recurrence/CLEAR';

const RESET_CHANGED = 'recurrence/RESET_CHANGED';

const CACHE_STATE = 'recurrence/CACHE_STATE';
const RESET_STATE = 'recurrence/RESET_STATE';

export default {
  SET_ACTIVE,
  UPDATE_RECURRENCE_TYPE,
  UPDATE_INDEX_OF_WEEK,
  TOGGLE_DAY_OF_WEEK,
  UPDATE_INDEX_OF_MONTH,
  TOGGLE_WEEK_OF_MONTH,
  TOGGLE_DAY_OF_MONTH,
  UPDATE_NUMBER_OF_BOOKINGS,
  UPDATE_RECURRENCE_END_DATE,
  UPDATE_RECURRENCE_END_TYPE,
  CLEAR,
  RESET_CHANGED,
  CACHE_STATE,
  RESET_STATE
};
