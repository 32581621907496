import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Button, withTranslation } from '../../../../../common';

import '../../../_style.css';

function AddResourcesToMapButton({ translate, actionOnClick, disabled }) {
  const label = translate('admin.settings.floors.map.addDialog.addResourcesToMapButtonLabel');
  return (
    <Button
      label={label}
      onClick={actionOnClick}
      disabled={disabled}
    />
  );
}

AddResourcesToMapButton.propTypes = {
  translate: PropTypes.func.isRequired,
  actionOnClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default withTranslation(withRouter(AddResourcesToMapButton));
