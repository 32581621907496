const UPDATE_LOCATIONS = 'locations/UPDATE_LOCATIONS';
const CLEAR_LOCATIONS = 'locations/CLEAR_LOCATIONS';
const SET_FLOORS = 'locations/SET_FLOORS';
const SET_SECTORS = 'locations/SET_SECTORS';
const SET_TO_DEFAULT = 'locations/SET_TO_DEFAULT';
const UPDATE_CHANGED = 'locations/UPDATE_CHANGED';
const RESET_CHANGED = 'locations/RESET_CHANGED';
const BUILDING_REMOVED = 'locations/BUILDING_REMOVED';

const CACHE_LOCATIONS = 'locations/CACHE_LOCATIONS';
const RESET_TO_CACHE = 'locations/RESET_TO_CACHE';

export default {
  UPDATE_LOCATIONS,
  CLEAR_LOCATIONS,
  UPDATE_CHANGED,
  SET_FLOORS,
  SET_SECTORS,
  SET_TO_DEFAULT,
  RESET_CHANGED,
  CACHE_LOCATIONS,
  RESET_TO_CACHE,
  BUILDING_REMOVED
};
