import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Icon, withTranslation, CustomPropTypes } from '../../../common';

import './_style.css';

function BookFormCollection({ history, resource, resourceType, translate }) {
  const onClick = () => {
    history.push(`/resources/${resource.id}/add${resourceType}s`);
  };

  const label = translate(`bookingCollection.addBookings.${resourceType}`);

  return (
    <div className="BookFormCollectionButton" onClick={onClick} role="button" tabIndex="0">
      <p className="BookFormCollectionButton__Label">{label}</p>
      <Icon className="BookFormCollectionButton__Icon" icon="015-chevron-right" />
    </div>
  );
}

BookFormCollection.propTypes = {
  resource: CustomPropTypes.resource,
  translate: PropTypes.func.isRequired,
  resourceType: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default withRouter(withTranslation(BookFormCollection));
