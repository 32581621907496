import { normalize, schema } from 'normalizr';

// Define regions
const regions = new schema.Entity(
  'regions',
  {
    idAttribute: 'id'
  }
);

const regionList = [regions];

const normalizeData = data => normalize(data, regionList);

export default normalizeData;
