import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/fp/isEqual';
import { connect } from 'react-redux';
import cx from 'classnames';

import {
  bookingCollectionSelectors,
  bookingCollectionOperations
} from '../../../state/bookingCollection';

import { locationsOperations, locationsSelectors } from '../../../state/locations';
import { roomFilterSelectors, roomFilterOperations } from '../../../state/roomFilter';

import {
  equipmentFilterSelectors,
  equipmentFilterOperations
} from '../../../state/equipmentFilter';

import BookingCollectionResourceFilter from './BookingCollectionResourceFilter/BookingCollectionResourceFilter';

import {
  LocationPicker,
  Icon,
  TimeInputRange,
  CustomPropTypes,
  withTranslation
} from '../../common';

import './_style.css';

class BookingCollectionFilter extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = { isFilterPanelActive: false };
  }

  toggleFilterPanel = () => {
    const { isFilterPanelActive } = this.state;
    this.setState({ isFilterPanelActive: !isFilterPanelActive });
  };

  updateLocations = locations => {
    const { updateLocations, loadResources, resourceType } = this.props;
    updateLocations(locations, () => {
      if (locations && locations.length) {
        loadResources(resourceType);
      }
    });
  };

  focusLocationPicker = () => {
    this.inputRef.current.focus();
  };

  renderLocationPicker = () => {
    const { selectedLocations, searchLocations, translate } = this.props;

    const locationPickerProps = {
      locations: selectedLocations,
      loadLocations: searchLocations,
      updateLocations: this.updateLocations,
      placeholder: translate('search.where.placeholder')
    };

    return (
      <div
        className="BookingCollectionFilter__Item BookingCollectionFilter__Location"
        role="presentation"
        onClick={this.focusLocationPicker}
      >
        <Icon icon="223-map-pointer" />
        <LocationPicker ref={this.inputRef} {...locationPickerProps} />
      </div>
    );
  };

  renderTimeInput = () => {
    const { updateTime, loadResources, resourceType, time } = this.props;
    const timeInputRangeProps = {
      values: time,
      onChange: newTime => {
        updateTime(newTime);
        if (!isEqual(time, newTime)) {
          loadResources(resourceType);
        }
      }
    };

    return (
      <div className="BookingCollectionFilter__Item BookingCollectionFilter__Time">
        <Icon icon="021-clock" />
        <TimeInputRange {...timeInputRangeProps} />
      </div>
    );
  };

  renderResourceFilter = () => {
    const { translate } = this.props;
    const { isFilterPanelActive } = this.state;
    const filter = translate('search.filter.label');
    return (
      <div className="BookingCollectionFilter__Resource">
        <button
          className={cx('link', 'ResourceFilterIcon', {
            'ResourceFilterIcon--active': false
          })}
          onClick={this.toggleFilterPanel}
          type="button"
        >
          <Icon icon="253-filter" className="ResourceFilterIcon__Icon" />
          <div className="ResourceFilterIcon__Label">{filter}</div>
        </button>
        <BookingCollectionResourceFilter
          isFilterPanelActive={isFilterPanelActive}
          toggleFilterPanel={this.toggleFilterPanel}
          {...this.props}
        />
      </div>
    );
  };

  render() {
 return (
   <div className="BookingCollectionFilter">
     {this.renderLocationPicker()}
     {this.renderTimeInput()}
     {this.renderResourceFilter()}
   </div>
 );
}
}

const mapStateToProps = state => ({
  time: bookingCollectionSelectors.getTime(state),
  selectedLocations: locationsSelectors.getSelectedLocations(state),
  roomFilter: roomFilterSelectors.getRoomFilters(state),
  equipmentFilter: equipmentFilterSelectors.getEquipmentFilters(state)
});

const mapDispatchToProps = dispatch => ({
  updateTime: time => dispatch(bookingCollectionOperations.updateTime(time)),
  searchLocations: input => dispatch(locationsOperations.searchLocations(input)),
  updateLocations: (locations, callback) => dispatch(locationsOperations.updateLocations(locations, callback, false)),
  toggleRoomFilter: item => dispatch(roomFilterOperations.toggleFilter(item)),
  toggleEquipmentFilter: item => dispatch(equipmentFilterOperations.toggleFilter(item)),
  loadResources: resourceType => {
    dispatch(bookingCollectionOperations.loadResources(resourceType)).then(() => {
      dispatch(bookingCollectionOperations.loadAlternatives(resourceType));
    });
  }
});

BookingCollectionFilter.propTypes = {
  time: CustomPropTypes.time,
  updateTime: PropTypes.func,
  selectedLocations: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string
    })
  ),
  searchLocations: PropTypes.func.isRequired,
  updateLocations: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  roomFilter: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  equipmentFilter: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loadResources: PropTypes.func.isRequired,
  resourceType: PropTypes.string.isRequired,
  toggleRoomFilter: PropTypes.func.isRequired,
  toggleEquipmentFilter: PropTypes.func.isRequired
};

export default withTranslation(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BookingCollectionFilter)
);
