import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withTranslation } from '../common';

import './_style.css';

class Refresh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  componentDidMount() {
    document.addEventListener('newContentAvailable', this.onNewContent);
  }

  componentWillUnmount() {
    document.removeEventListener('newContentAvailable', this.onNewContent);
  }

  onNewContent = () => {
    this.setState({ visible: true });
  };

  refresh = () => {
    window.location.reload(true);
  };

  render() {
    const { translate } = this.props;
    const { visible } = this.state;

    if (!translate) return null;

    const text = translate('newContent.text');
    const refresh = translate('newContent.refresh');

    const classes = cx('Refresh', { 'Refresh--show': visible });

    return (
      <div className={classes}>
        <div className="Refresh__Content">
          <span className="Refresh__Text">{text}</span>
          <button tabIndex="0" onClick={this.refresh} className="Refresh__Link" type="button">
            {refresh}
          </button>
        </div>
      </div>
    );
  }
}

Refresh.propTypes = { translate: PropTypes.func };

export default withTranslation(Refresh);
