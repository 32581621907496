const MAP_OWNER_DATA = 'changeOwner/MAP_OWNER_DATA';
const INITIALIZE_DATA = 'changeOwner/INITIALIZE_DATA';
const INITIALIZE_OWNERS = 'changeOwner/INITIALIZE_OWNERS';
const SELECT_PROFILE = 'changeOwner/SELECT_PROFILE';
const UPDATE_CONTACT = 'changeOwner/UPDATE_CONTACT';
const UPDATE_OWNERS = 'changeOwner/UPDATE_OWNERS';
const UPDATE_REASON = 'changeOwner/UPDATE_REASON';

const UPDATE_CONTACT_ISLOADING = 'changeOwner/UPDATE_CONTACT_ISLOADING';
const UPDATE_CONTACT_SUGGESTIONS = 'changeOwner/UPDATE_CONTACT_SUGGESTIONS';

const UPDATE_OWNER_ISLOADING = 'changeOwner/UPDATE_OWNER_ISLOADING';
const UPDATE_OWNER_SUGGESTIONS = 'changeOwner/UPDATE_OWNER_SUGGESTIONS';

const UPDATE_SUBMITTED = 'changeOwner/UPDATE_SUBMITTED';
const UPDATE_IS_SUBMITTING = 'changeOwner/UPDATE_IS_SUBMITTING';

const UPDATE_OWNER_MODE = Object.freeze({ old: 1, new: 2, both: 3 });

export default {
  MAP_OWNER_DATA,
  INITIALIZE_DATA,
  INITIALIZE_OWNERS,
  UPDATE_OWNER_MODE,
  SELECT_PROFILE,
  UPDATE_CONTACT,
  UPDATE_OWNERS,
  UPDATE_REASON,

  UPDATE_CONTACT_ISLOADING,
  UPDATE_CONTACT_SUGGESTIONS,

  UPDATE_OWNER_SUGGESTIONS,
  UPDATE_OWNER_ISLOADING,

  UPDATE_SUBMITTED,
  UPDATE_IS_SUBMITTING
};
