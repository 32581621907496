import get from 'lodash/fp/get';
import set from 'lodash/fp/set';
import cloneDeep from 'lodash/fp/cloneDeep';
import { getResourceSubTypesObject } from '../../util';
import types from './types';

const equipmentTypes = {};

const initialState = {
  equipment: {
    book: cloneDeep(equipmentTypes),
    calendar: cloneDeep(equipmentTypes),
    bookingCollection: cloneDeep(equipmentTypes)
  },
  dirty: {
    book: false,
    calendar: false
  },
  namespace: 'book'
};

const equipmentFilterReducer = (state = initialState, action) => {
  const { namespace } = state;

  switch (action.type) {
    case types.CHANGE_FILTER: {
      return { ...state, namespace: action.name };
    }

    case types.TOGGLE_FILTER: {
      const filter = action.name;
      const path = `${ namespace }.${ filter }.enabled`;
      const value = get(path, state.equipment);
      return {
        ...state,
        equipment: set(path, !value, state.equipment),
        dirty: set(namespace, true, state.dirty)
      };
    }

    case types.CACHE_FILTER: {
      return { ...state, cache: { equipment: state.equipment, dirty: state.dirty } };
    }

    case types.RESET_TO_CACHE: {
      return { ...state, ...state.cache, cache: null };
    }

    case types.RESET_DIRTY: {
      return {
        ...state,
        dirty: set(namespace, false, state.dirty)
      };
    }

    case types.RESET_FILTER: {
      const newState = { ...state };
      newState.equipment[action.namespace] = cloneDeep(equipmentTypes);
      return newState;
    }

    case types.UPDATE_EQUIPMENTTYPES: {
      const newState = { ...state };
      const newEquipmentTypes = [];
      const oldValues = Object.values(newState.equipment[action.namespace]);
      const oldEquipmentTypes = oldValues.filter(p => p.type === 'equipmentType');

      if (!action.resourcesSubTypes) {
        return newState;
      }

      oldEquipmentTypes.forEach(oldEquipmentType => {
        delete newState.equipment[action.namespace][oldEquipmentType.key];
      });

      action.resourcesSubTypes.forEach(newEquipmentType => {
        newEquipmentTypes[newEquipmentType.key] = newEquipmentType;
      });

      const resourcesSubTypes = getResourceSubTypesObject(action.resourcesSubTypes, 'Equipment');
      newState.equipment[action.namespace] = { ...resourcesSubTypes };

      return newState;
    }

    default:
      return state;
  }
};

export default equipmentFilterReducer;
