import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, EquipmentCard, Favorite } from '../../../../common';
import EquipmentNameAndType from '../EquipmentNameAndType';
import ResourceAvailability from '../ResourceAvailability';
import FormTime from '../FormTime';
import FormSubmit from '../FormSubmit';
import ProfileInformation from '../ProfileInformation';

const width = amount => ({ width: amount });

function EquipmentCardTablet(props) {
  const { resource, selected, showTimeInput, onSelect, isProfile3, isProfile2 } = props;
  const { alternativeType, showRelativeTime } = props;

  const showAlternativeTime = alternativeType === 'time';

  const renderEquipmentInformation = () => {
    const { id } = resource;
    if (!selected || isProfile3) {
      return [
        <div style={width('35px')} key="Favorite">
          <Favorite resourceId={id} tooltip={false} medium />
        </div>,
        <div style={width('calc(50% - 35px')} key="EquipmentNameAndType">
          <EquipmentNameAndType resource={resource} />
        </div>,
        <div style={width('50%')} key="ResourceAvailability">
          <ResourceAvailability
            resource={resource}
            showRelativeTime={showRelativeTime}
            showAlternativeTime={showAlternativeTime}
          />
        </div>
      ];
    }
    return null;
  };

  const renderSelectedNoTimeInput = () => {
    if (selected && !showTimeInput && !isProfile3) {
      return [
        <div style={width('40%')} key="EquipmentNameAndType">
          <EquipmentNameAndType resource={resource} />
        </div>,
        <div style={width('60%')} key="FormSubmit">
          <FormSubmit small openModal={onSelect} resource={resource} />
        </div>
      ];
    }
    return null;
  };

  const renderSelectedTimeInput = () => {
    if (selected && showTimeInput && !isProfile3) {
      return [
        <div style={width('40%')} key="FormTime">
          <FormTime />
        </div>,
        <div style={width('60%')} key="FormSubmit">
          <FormSubmit small openModal={onSelect} resource={resource} />
        </div>
      ];
    }
    return null;
  };

  const renderProfileInformation = () => {
    if (isProfile3 || isProfile2) {
      return <ProfileInformation resource={resource} />;
    }
    return null;
  };

  const onCardClick = event => {
    if (isProfile3) {
      onSelect(event);
    }
  };

  return (
    <div>
      <EquipmentCard resourceSubType={resource.resourceSubType} onClick={onCardClick}>
        {renderEquipmentInformation()}
        {renderSelectedNoTimeInput()}
        {renderSelectedTimeInput()}
        {renderProfileInformation()}
      </EquipmentCard>
    </div>
  );
}

EquipmentCardTablet.propTypes = {
  resource: CustomPropTypes.resource,
  selected: PropTypes.bool.isRequired,
  showTimeInput: PropTypes.bool.isRequired,
  showRelativeTime: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  isProfile3: PropTypes.bool.isRequired,
  isProfile2: PropTypes.bool.isRequired,
  alternativeType: PropTypes.string
};

export default EquipmentCardTablet;
