import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';

import './_style.css';

import { EmptyState, withTranslation } from '../common';

function ErrorPage({ onClose, translate }) {
  const title = translate('errorPage.title');
  const text = translate('errorPage.text');
  return (
    <div className="ErrorPage">
      <Header onClose={onClose} title={title} />
      <div className="ErrorPage__Content">
        <EmptyState visible content={text} icon="239-robot-confused" />
      </div>
    </div>
  );
}

ErrorPage.propTypes = {
  onClose: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(ErrorPage);
