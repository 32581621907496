import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { Icon } from '../common';

import './_style.css';

function MenuLink({ icon, to, label, exact, className }) {
  const renderIcon = () => {
    if (React.isValidElement(icon)) {
      return icon;
    }
    return <Icon className="MenuLink__Icon" icon={icon} />;
  };

  const labelClasses = cx('MenuLink__Label', {
    'MenuLink__Label--offset': React.isValidElement(icon)
  });

  const wrapperClasses = cx('MenuLink', className);

  return (
    <div>
      <NavLink exact={exact} to={to} className={wrapperClasses} activeClassName="MenuLink--active">
        {renderIcon()}
        <div className={labelClasses}>{label}</div>
      </NavLink>
    </div>
  );
}

MenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  className: PropTypes.string
};

export default MenuLink;
