/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { styles, lineStyles } from './styles';

function ClickShareIcon({ disabled, ...otherProps }) {
  return (
    <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" {...otherProps} style={styles}>
      <g id="Calque_2_1_">
        <g id="Calque_1-2_1_">
          <path d="M27.1,14.5h-0.8c-0.1-0.5-0.5-0.8-1-0.8h-4.4c-0.6,0-1,0.4-1,1V15h-3.2v-4.1c0-1.4-1.1-2.5-2.5-2.5H4.1c-1.4,0-2.5,1.1-2.5,2.5v10c0,1.4,1.1,2.5,2.5,2.5h10.1c1.4,0,2.5-1.1,2.5-2.5l0.1-4.1H20v0.3c0,0.6,0.4,1,1,1h4.4c0.5,0,0.9-0.3,1-0.8h0.8c0.4,0,0.7-0.3,0.7-0.7l0,0v-1.5C27.8,14.8,27.5,14.5,27.1,14.5z M15,20.9c0,0.5-0.4,0.8-0.8,0.8l0,0H4.1c-0.5,0-0.8-0.4-0.8-0.8l0,0v-10c0-0.5,0.4-0.8,0.8-0.8h10.1c0.5,0,0.8,0.4,0.8,0.8l0,0C15,10.9,15,20.9,15,20.9z M25.2,16.6c0,0.2-0.2,0.4-0.4,0.4l0,0h-3.3c-0.2,0-0.4-0.2-0.4-0.4l0,0v-1.4c0-0.2,0.2-0.4,0.4-0.4l0,0h3.3c0.2,0,0.4,0.2,0.4,0.3l0,0V16.6zM27,16.3c0,0.1-0.1,0.2-0.2,0.2h-0.4v-1.3h0.4c0.1,0,0.2,0.1,0.2,0.2V16.3z" />
          <path d="M9.2,12.5c-1.9,0-3.4,1.5-3.4,3.4s1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4S11.1,12.5,9.2,12.5L9.2,12.5z M9.2,17.9c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S10.3,17.9,9.2,17.9L9.2,17.9L9.2,17.9z" />
        </g>
      </g>
      {disabled && <line className="st0" style={lineStyles} x1="6.7" y1="1.7" x2="23.5" y2="28.3" />}
    </svg>
  );
}

ClickShareIcon.propTypes = {
  disabled: PropTypes.bool
};

export default ClickShareIcon;
