/* eslint-disable arrow-body-style */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Translate from '../I18n/Translate';

import './_style.css';

const getVisible = (message, className) => (
  <div className={cx('ValidationSuccess', className)}>
    <Translate path={message} />
  </div>
);

function ValidationSuccess({ message, visible, className }) {
  return visible ? getVisible(message, className) : null;
}

ValidationSuccess.propTypes = {
  message: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  className: PropTypes.string
};

export default ValidationSuccess;
