import moment from 'moment';

const calculateAbsoluteAvailability = availabilities => {
  if (!availabilities || availabilities.length <= 0) {
    return '';
  }

  const firstAvailability = availabilities[0];

  const start = moment(firstAvailability.start).format('HH:mm');
  const end = moment(firstAvailability.end).format('HH:mm');

  return `${start} - ${end}`;
};

export default calculateAbsoluteAvailability;
