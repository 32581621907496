import actions from '../actions';
import selectors from '../selectors';
import createPayload from '../payload';
import { regionsSelectors } from '../../regions';
import { notificationsSelectors, notificationsOperations } from '../../notifications';
import { locationsSelectors, locationsOperations } from '../../locations';
import { equipmentFilterSelectors, equipmentFilterOperations } from '../../equipmentFilter';
import { roomFilterSelectors, roomFilterOperations } from '../../roomFilter';
import { recurrenceSelectors, recurrenceOperations } from '../../recurrence';
import { searchOperations, searchSelectors } from '../../search';
import { alternativesOperations } from '../../alternatives';
import { favoriteOperations } from '../../favorite';
import { availabilityService } from '../../../services';
import config from '../../../config';

const {
  updateLoading,
  updateSkipCount,
  updateTotalCount,
  updateHasMore,
  addAvailability,
  updateAvailability,
  updateIsRecurrence
} = actions;

const { hideError } = notificationsOperations;

const getFavorites = availabilities => availabilities.reduce((prev, availability) => {
  const { resource } = availability;
  return { ...prev, [resource.id]: resource.isFavorite };
}, {});

const loadResources = async (dispatch, state, searchRequest, withRecurrence) => {
  const getNotification = notificationsSelectors.getNotification(state);

  dispatch(updateLoading(true));
  dispatch(hideError());

  return availabilityService
    .post(searchRequest, withRecurrence)
    .then(response => {
      const availabilities = withRecurrence ? response.resourceAvailabilities : response;
      const { totalCount, nextSkipCount, results: items } = availabilities;
      const hasMore = totalCount > nextSkipCount;

      const isExactTime = searchSelectors.isExactTime(state);
      dispatch(searchOperations.updateSearchedExactTime(isExactTime));

      dispatch(favoriteOperations.resetFavorites(getFavorites(items)));
      dispatch(updateIsRecurrence(withRecurrence));
      dispatch(updateHasMore(hasMore));
      dispatch(updateTotalCount(totalCount));
      dispatch(updateSkipCount(nextSkipCount));
      dispatch(searchRequest.skipCount === 0 ? updateAvailability(items) : addAvailability(items));
    })
    .catch(serverError => {
      const error = getNotification('search.load.error', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => dispatch(updateLoading(false)));
};

const loadAvailabilities = (ignoreChanges, sortingList) => (dispatch, getState) => {
  const state = getState();

  const withRecurrence = recurrenceSelectors.getRecurrenceType(state) !== 'never';
  const locations = locationsSelectors.getLocationsByType(state);

  if (locations.length < 1) {
    availabilityService.cancel();
    dispatch(updateLoading(false));
    dispatch(updateAvailability([]));
    return false;
  }

  const isDateInPast = searchSelectors.getIsDateInPast(state);
  if (isDateInPast) {
    return false;
  }

  const isValidTime = searchSelectors.isValidDateWithTime(state);
  if (!isValidTime) {
    return false;
  }

  const hasAvailabilities = selectors.hasAvailabilities(state);
  const equipmentChanged = equipmentFilterSelectors.isDirty(state);
  const roomFiltersChanged = roomFilterSelectors.isDirty(state);
  const dateChanged = searchSelectors.wasChanged(state);
  const locationsChanged = locationsSelectors.isChanged(state);
  const recurrenceChanged = recurrenceSelectors.recurrenceHasChanged(state);
  const hasChanged = equipmentChanged || roomFiltersChanged || dateChanged || locationsChanged || recurrenceChanged;
  if (!hasChanged && hasAvailabilities && !ignoreChanges) {
    return false;
  }

  dispatch(searchOperations.resetChanged());
  dispatch(equipmentFilterOperations.resetDirty());
  dispatch(roomFilterOperations.resetDirty());
  dispatch(locationsOperations.resetChanged());
  dispatch(recurrenceOperations.resetChanged());
  dispatch(searchOperations.resetChanged());

  dispatch(updateTotalCount(0));
  dispatch(updateAvailability([]));
  const searchRequest = createPayload(state, sortingList);
  searchRequest.pageSize = config.pageSize;
  searchRequest.skipCount = 0;

  const loadAlternatives = alternativesOperations.loadAlternatives(dispatch, getState);
  return loadResources(dispatch, state, searchRequest, withRecurrence).then(loadAlternatives);
};

const loadMoreAvailabilitites = () => (dispatch, getState) => {
  const state = getState();

  const isDateInPast = searchSelectors.getIsDateInPast(state);
  if (isDateInPast) {
    return Promise.reject(new Error('Date is in past'));
  }

  if (!regionsSelectors.isLoaded(state)) {
    return Promise.reject(new Error('no regions available'));
  }

  const isValidTime = searchSelectors.isValidDateWithTime(state);
  if (!isValidTime) {
    return Promise.reject(new Error('Time is not valid'));
  }

  const hasMore = selectors.hasMoreResults(state);
  if (!hasMore) {
    return Promise.resolve(false);
  }

  const nextSkipCount = selectors.getSkipCount(state);

  const searchRequest = createPayload(state);
  searchRequest.pageSize = config.pageSize;
  searchRequest.skipCount = nextSkipCount;

  const withRecurrence = recurrenceSelectors.getRecurrenceType(state) !== 'never';
  return loadResources(dispatch, state, searchRequest, withRecurrence);
};

export default {
  loadAvailabilities,
  loadMoreAvailabilitites,
  updateIsRecurrence,
  updateAvailability
};
