import bookActions from './bookActions';
import bookForActions from './bookForActions';
import attendeesActions from './attendeesActions';
import singleAvailabilityActions from './singleAvailabilityActions';

export default {
  ...bookActions,
  ...bookForActions,
  ...attendeesActions,
  ...singleAvailabilityActions
};
