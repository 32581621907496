import React, { memo } from 'react';
import { NodeResizer } from 'reactflow';
import PropTypes from 'prop-types';

function FloorNode({ data }) {
  return (
    <>
      <NodeResizer
        minWidth={100}
        minHeight={50}
        onResizeStart={data.onResizeStart}
        shouldResize={data.shouldResize}
        onResize={data.onResize}
        onResizeEnd={data.onResizeEnd}
        handleStyle={{
          borderColor: 'transparent',
          backgroundColor: 'transparent'
        }}
      />
      {data.debug && (
        <>
          <div style={{ position: 'absolute', top: '-20px', left: '-2px' }}>
            {data.position}
          </div>
          <div style={{ position: 'absolute', top: '-20px', left: '47%' }}>
            {data.width}
          </div>
          <div style={{ position: 'absolute', top: '45%', left: '-25px' }}>
            {data.height}
          </div>
        </>
      )}

    </>
  );
}

export function getFloorNode({
  onFloorMapResizeStart,
  onFloorMapResize,
  onFloorMapResizeEnd,
  shouldFloorMapResize,
  selectedFloor,
  debug
}) {
  const x = selectedFloor ? selectedFloor.map.positionX : 0;
  const y = selectedFloor ? selectedFloor.map.positionY : 0;
  const width = selectedFloor ? selectedFloor.map.width : 300;
  const height = selectedFloor ? selectedFloor.map.height : 200;

  return {
    id: 'floorMap',
    type: 'FloorNode',
    position: { x, y },
    draggable: false,
    deletable: false,
    data: {
      onResizeStart: onFloorMapResizeStart,
      onResize: onFloorMapResize,
      onResizeEnd: onFloorMapResizeEnd,
      shouldResize: shouldFloorMapResize,
      debug,
      position: `(${ x },${ y })`,
      width,
      height
    },
    style: {
      background: 'rgba(238, 243, 246, 0.50)',
      border: '4px solid var(--RnR-Primary-Color, #086ADB)',
      borderRadius: '10px',
      fontSize: 12,
      width,
      height
    }
  };
}

FloorNode.propTypes = {
  data: PropTypes.objectOf(PropTypes.any)
};

export default memo(FloorNode);
