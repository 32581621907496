import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import cx from 'classnames';

import { withTranslation, CustomPropTypes } from '../../common';
import NewResourceButton from './NewResourceButton';

class MyChangeRequestsTable extends Component {
  renderHeaderRow = () => {
    const { translate } = this.props;

    const columns = [
      translate('changeRequests.my.table.header.created'),
      translate('changeRequests.my.table.header.modified'),
      translate('changeRequests.my.table.header.location'),
      translate('common.name'),
      translate('changeRequests.my.table.header.resourceType'),
      translate('changeRequests.my.table.header.changeRequestType'),
      translate('changeRequests.my.table.header.assignee'),
      translate('changeRequests.my.table.header.status'),
      ''
    ];

    const renderCell = value => <th key={value}>{value}</th>;

    return <tr>{columns.map(renderCell)}</tr>;
  };

  renderRow = changeRequest => {
    const { translate, history } = this.props;
    const getDate = date => date && moment(date).format('DD.MM.YYYY HH:mm');
    const { resourceType } = changeRequest;
    const resourceTypeDisplayName = translate(`changeRequests.${resourceType.toLowerCase()}`);
    const cells = [
      { created: getDate(changeRequest.createdOn) },
      { modified: getDate(changeRequest.modifiedOn) },
      { building: changeRequest.buildingKey },
      { name: changeRequest.resourceName },
      { type: resourceTypeDisplayName },
      { changeType: changeRequest.changeRequestTypeDisplayName },
      { assignee: changeRequest.assignee ? changeRequest.assignee.accountName : '' },
      { status: changeRequest.statusDisplayName }
    ];

    const renderCell = (item, index) => {
      const name = Object.keys(item)[0];
      const value = Object.values(item)[0];
      const cellClass = cx({
        'MyChangeRequestCell--building': name === 'building',
        'MyChangeRequestCell--name': name === 'name'
      });
      return (
        <td className={cellClass} key={index}>
          {value}
        </td>
      );
    };

    const isAssignedToMe = changeRequest.readOnly === false;
    const important = changeRequest.status === 'New' && isAssignedToMe;
    const rejected = changeRequest.status === 'Rejected';
    const approved = changeRequest.status === 'Approved';
    const failed = changeRequest.status === 'Failed';

    const classes = cx('MyChangeRequestRow', {
      'MyChangeRequestRow--important': important,
      'MyChangeRequestRow--rejected': rejected,
      'MyChangeRequestRow--approved': approved,
      'MyChangeRequestRow--failed': failed
    });

    const linkClasses = cx('link', 'MyChangeRequestRow');

    const redirect = () => {
      const url = `/changerequests/${changeRequest.id}`;
      history.push(url);
    };

    const details = translate('changeRequests.details');

    return (
      <tr className={classes} key={changeRequest.id}>
        {cells.map((value, index) => renderCell(value, index))}
        <td>
          <button className={linkClasses} onClick={redirect} role="link" tabIndex={0} type="button">
            {details}
          </button>
        </td>
      </tr>
    );
  };

  render() {
    const { changeRequests, translate } = this.props;

    const title = translate('changeRequests.my.title');
    return (
      <div className="table table--responsive">
        <div className="MyChangeRequests__TableHead">
          <h3 className="MyChangeRequests__Title">{title}</h3>
          <div>
            <NewResourceButton />
          </div>
        </div>
        <div className="table__wrapper">
          <table>
            <thead>{this.renderHeaderRow()}</thead>
            <tbody>{changeRequests.map(this.renderRow)}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

MyChangeRequestsTable.propTypes = {
  changeRequests: PropTypes.arrayOf(CustomPropTypes.changeRequest),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

export default withRouter(withTranslation(MyChangeRequestsTable));
