import types from './types';

const notProcessed = {
  approve: false,
  reject: false,
  discard: false,
  complete: false
};

const initialState = {
  changeRequest: null,
  isLoading: false,
  isProcessing: notProcessed,
  isProcessed: notProcessed
};

const createResourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_CHANGE_REQUEST: {
      return {
        ...state,
        changeRequest: action.changeRequest,
        isProcessed: notProcessed,
        isProcessing: notProcessed
      };
    }

    case types.UPDATE_IS_LOADING: {
      return { ...state, isLoading: action.isLoading };
    }

    case types.UPDATE_ACTION_ISPROCESSING: {
      const isProcessing = { ...state.isProcessing };
      isProcessing[action.actionType] = action.isProcessing;
      return { ...state, isProcessing };
    }

    case types.UPDATE_ACTION_PROCESSED: {
      const isProcessed = { ...state.isProcessed };
      isProcessed[action.actionType] = action.processed;
      return { ...state, isProcessed };
    }

    default:
      return state;
  }
};

export default createResourceReducer;
