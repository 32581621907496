import types from './types';

const clearLocations = () => ({ type: types.CLEAR_LOCATIONS });

const updateLocations = locations => ({
  type: types.UPDATE_LOCATIONS,
  locations
});

const setFloors = building => ({
  type: types.SET_FLOORS,
  building
});

const setSectors = building => ({
  type: types.SET_SECTORS,
  building
});

const updateChanged = () => ({
  type: types.UPDATE_CHANGED
});

const resetChanged = () => ({
  type: types.RESET_CHANGED
});

const cacheLocationState = () => ({
  type: types.CACHE_LOCATIONS
});

const resetToCache = () => ({
  type: types.RESET_TO_CACHE
});

const removeBuildingFromLocation = buildingKey => ({
  type: types.BUILDING_REMOVED,
  buildingKey
});

export default {
  updateLocations,
  clearLocations,
  setFloors,
  setSectors,
  updateChanged,
  resetChanged,
  cacheLocationState,
  resetToCache,
  removeBuildingFromLocation
};
