import store from 'store2';
import actions from '../actions';
import selectors from '../selectors';
import { bookService } from '../../../services';
import { availabilityOperations, availabilitySelectors } from '../../availability';
import { alternativesOperations } from '../../alternatives';
import { modalOperations } from '../../modal';
import { notificationsOperations, notificationsSelectors } from '../../notifications';
import { recurrenceOperations } from '../../recurrence';
import {
  bookingCollectionOperations,
  bookingCollectionSelectors as collectionSelectors
} from '../../bookingCollection';
import { emitter } from '../../../util';
import { buildingsSelectors } from '../../buildings';

const {
  updateShown,
  updateIsSubmitting,
  updateSelectedResource,
  updateDate,
  updateTime,
  updateIsValidTimeRange,
  updateMessage,
  updateSubject,
  updateReminder,
  cacheState,
  resetStateFromCache,
  updateSelectedBooking,
  updateSeatingId
} = actions;

const resetState = () => dispatch => {
  dispatch(actions.resetState());
  dispatch(actions.clearAttendees());
  dispatch(bookingCollectionOperations.resetCollectionState());
};

const confirmSuccess = booking => (dispatch, getState) => {
  const state = getState();
  const resourceType = (booking.resource && booking.resource.resourceType) || 'room';
  const getNotification = notificationsSelectors.getNotification(state);
  const profile2 = (booking.resource && booking.resource.profile) === 2;
  const path = profile2 ? `profile2.${resourceType.toLowerCase()}` : resourceType.toLowerCase();
  const success = getNotification(`search.create.success.${path}`);
  dispatch(notificationsOperations.showSuccess(success));
};

const createBookingError = serverError => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);

  const error = getNotification('search.create.error', serverError);
  dispatch(notificationsOperations.showError({ ...error, ...serverError }));
  dispatch(actions.updateIsSubmitting(false));
};

const submitBooking = () => async (dispatch, getState) => {
  const state = getState();
  if (!selectors.isReadyToSubmit(state)) {
    return Promise.reject(new Error('Form is not valid'));
  }

  const bookingCollections = collectionSelectors.getSelectedResources(state);
  const hasBookingCollections = bookingCollections && bookingCollections.length > 0;

  const bookPayload = {
    ...selectors.getBookRequest(state),
    isBookingCollection: hasBookingCollections
  };

  const collectionPayloads = collectionSelectors.getCollectionPayloads(bookPayload)(state);

  const payloads = [bookPayload, ...collectionPayloads];
  const submit = bookService.post;
  const requests = payloads.map(payload => submit(payload));

  dispatch(actions.updateIsSubmitting(true));
  dispatch(notificationsOperations.hideError());

  try {
    const bookings = await Promise.all(requests);

    dispatch(confirmSuccess(bookings[0]));
    dispatch(recurrenceOperations.resetState());
    store('reminder', bookPayload.reminder);
    dispatch(modalOperations.clearModals());
    dispatch(actions.updateIsSubmitting(false));

    return bookings.map(booking => ({ ...booking, resourceId: booking.resource.id }));
  } catch (serverError) {
    dispatch(createBookingError(serverError));
    return Promise.reject(serverError);
  }
};

const createJournalBooking = () => async (dispatch, getState) => {
  const state = getState();
  const isRecurrence = availabilitySelectors.isRecurrence(state);
  const bookings = await dispatch(submitBooking());
  if (isRecurrence) {
    dispatch(availabilityOperations.loadAvailabilities(true));
  } else {
    dispatch(availabilityOperations.loadJournal(bookings[0].resourceId));
  }
};

const createBooking = () => async dispatch => {
  const bookings = await dispatch(submitBooking());
  bookings.forEach(booking => {
    emitter.emit('onResourceBooked', booking.resourceId);
    dispatch(availabilityOperations.removeResourceById(booking.resourceId));
    dispatch(alternativesOperations.removeResourceById(booking.resourceId));
  });
};

const updateBooking = payload => async dispatch => {
  dispatch(actions.updateIsSubmitting(true));
  const booking = await bookService.put(payload);

  dispatch(confirmSuccess(booking));
  dispatch(recurrenceOperations.resetState());
  dispatch(modalOperations.clearModals());
  dispatch(actions.updateIsSubmitting(false));
  return booking;
};

const updateSelectedBookingWithBuildings = booking => (dispatch, getState) => {
  const buildings = buildingsSelectors.getBuildings(getState());
  dispatch(updateSelectedBooking(booking, buildings));
};

const updateBookingFromState = () => (dispatch, getState) => {
  const state = getState();
  const bookRequest = selectors.getBookRequest(state);
  const payload = {
    ...bookRequest,
    ...selectors.getRecurrenceRequestInfo(state)
  };

  return bookService.put(payload);
};

const loadEmptyBooking = (resource, modalKey) => dispatch => {
  dispatch(recurrenceOperations.cacheState());
  dispatch(actions.updateSubject('Booked with ReservationHub'));
  dispatch(actions.updateSelectedResource(resource));
  dispatch(actions.updateBookForPerson(null));
  dispatch(actions.clearAttendees());
  dispatch(actions.updateMessage(''));
  dispatch(bookingCollectionOperations.resetCollectionState());
  dispatch(modalOperations.pushModal(modalKey));
};

const loadBooking = (booking, modalKey) => dispatch => {
  dispatch(actions.loadBooking(booking));
  const mapAttendee = attendee => ({
    ...attendee,
    name: attendee.displayName,
    value: attendee.email
  });
  const mapBookedFor = attendee => (attendee ? mapAttendee(attendee) : null);
  const attendees = booking.attendees.map(mapAttendee);
  const person = mapBookedFor(booking.bookedFor);
  dispatch(actions.updateSelectedAttendees(attendees));
  dispatch(actions.updateBookForPerson(person));
  dispatch(modalOperations.pushModal(modalKey));
};

export default {
  updateShown,
  createBooking,
  createJournalBooking,
  updateBooking,
  updateBookingFromState,
  updateIsSubmitting,
  updateSelectedResource,
  updateDate,
  updateTime,
  updateIsValidTimeRange,
  updateMessage,
  updateSubject,
  updateReminder,
  resetState,
  loadEmptyBooking,
  loadBooking,
  updateSelectedBooking: updateSelectedBookingWithBuildings,
  cacheState,
  resetStateFromCache,
  updateSeatingId,
  submitBooking
};
