/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ShowAt } from '../common';
import { useBreakpointCrossing } from '../../hooks';
import SearchMobile from './SearchMobile';
import SearchDesktop from './SearchDesktop';
import { locationsSelectors } from '../../state/locations';
import { roomFilterOperations } from '../../state/roomFilter';
import { buildingsService } from '../../services';
import { resourcesSubTypesSelectors } from '../../state/resourcesSubTypes';
import { equipmentFilterOperations } from '../../state/equipmentFilter';

function Search({
    location,
    history,
    match,
    selectedLocations,
    updateFloors,
    updateSectors,
    updateRoomTypes,
    updateEquipmentTypes,
    resourcesSubTypes
  }) {
  const onBreakpointCrossing = useCallback(() => {
    const isNestedRoute = location.pathname !== match.url;
    if (isNestedRoute) {
      history.replace(match.url);
    }
  }, [location, history, match]);

  useBreakpointCrossing(900, onBreakpointCrossing);

  const updateFloorsAndSectors = () => {
    if (selectedLocations?.length === 1) {
      const isBuilding = selectedLocations.some(p => p.type === 'Building');

      if (isBuilding) {
        const buildingId = selectedLocations[0].id;

        buildingsService.get(buildingId).then(data => {
          const newFloors = data?.floors?.map(floor => ({
            key: floor.id,
            name: floor.name,
            enabled: false,
            type: 'floor'
          }));

          const newSectors = data?.sectors?.map(sector => ({
            key: sector.id,
            name: sector.name,
            enabled: false,
            type: 'sector'
          }));

          if (newFloors) {
            updateFloors(newFloors);
          }
          if (newSectors) {
            updateSectors(newSectors);
          }
        });
      } else {
        updateFloors([]);
        updateSectors([]);
      }
    } else {
      updateFloors([]);
      updateSectors([]);
    }

    updateRoomTypes(resourcesSubTypes);
    updateEquipmentTypes(resourcesSubTypes);
  };

  useEffect(() => {
    updateFloorsAndSectors();
  }, [selectedLocations]);

  return (
    <div>
      <ShowAt breakpoint="900AndBelow">
        <SearchMobile />
      </ShowAt>

      <ShowAt breakpoint="900AndAbove">
        <SearchDesktop />
      </ShowAt>
    </div>
  );
}

const mapStateToProps = state => ({
  selectedLocations: locationsSelectors.getSelectedLocations(state),
  resourcesSubTypes: resourcesSubTypesSelectors.getResourcesSubTypes(state)
});

const mapDispatchToProps = dispatch => ({
  updateFloors: floors => dispatch(roomFilterOperations.changeFloors('book', floors)),
  updateSectors: sectors => dispatch(roomFilterOperations.changeSectors('book', sectors)),
  updateRoomTypes: resourcesSubTypes => dispatch(roomFilterOperations.updateRoomTypes('book', resourcesSubTypes)),
  updateEquipmentTypes: resourcesSubTypes => dispatch(
    equipmentFilterOperations.updateEquipmentTypes('book', resourcesSubTypes)
)
});

export default connect(
mapStateToProps,
  mapDispatchToProps
)(Search);

Search.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired,
  selectedLocations: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.string
    })
  ),
  updateFloors: PropTypes.func,
  updateSectors: PropTypes.func,
  updateRoomTypes: PropTypes.func,
  updateEquipmentTypes: PropTypes.func,
  resourcesSubTypes: PropTypes.arrayOf(PropTypes.shape())
};
