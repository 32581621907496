import types from './types';
import { changeRequestDetailTypes } from '../changeRequestDetail';

const initialState = {
  resource: null,
  loadingResource: false
};

const editResourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_RESOURCE: {
      return { ...state, resource: action.resource };
    }

    case types.UPDATE_LOADING_RESOURCE: {
      return { ...state, loadingResource: action.loading };
    }

    case changeRequestDetailTypes.UPDATE_CHANGE_REQUEST: {
      return { ...state, resource: action.changeRequest.resource };
    }

    default:
      return state;
  }
};

export default editResourceReducer;
