import config from '../config';
import fetch, { fetchRaw } from './fetch';

const getUrl = path => `${config.remoteUrl}/${path}`;

async function uploadRoomPhoto(resourceId, formData) {
  const url = getUrl(`rooms/${resourceId}/assets`);

  const response = await fetchRaw(url, 'POST', formData);
  if (response.status === 204) {
    return {};
  }

  const responseBody = await response.json();

  if (response.status >= 300) {
    const error = new Error(responseBody.message);
    error.response = responseBody;
    error.status = response.status;
    return Promise.reject(error);
  }

  return responseBody;
}

async function deleteAsset(id) {
  const url = getUrl(`assets/${id}`);
  return fetch(url, 'DELETE');
}

export default { uploadRoomPhoto, deleteAsset };
