import omitBy from 'lodash/fp/omitBy';

const mapProfile = profile => {
  const location = profile ? profile['http://reservationhub.swisscom.com/claims/office'] : '';

  return {
    office: location,
    ...omitBy((value, key) => key === 'http://reservationhub.swisscom.com/claims/office', profile)
  };
};

export default mapProfile;
