import curry from 'lodash/fp/curry';

const formatOrdinal = curry((translate, index, plural) => {
  switch (index) {
    case 1: {
      return plural
        ? translate('common.ordinals.plural.first')
        : translate('common.ordinals.singular.first');
    }
    case 2: {
      return plural
        ? translate('common.ordinals.plural.second')
        : translate('common.ordinals.singular.second');
    }
    case 3: {
      return plural
        ? translate('common.ordinals.plural.third')
        : translate('common.ordinals.singular.third');
    }
    case 4: {
      return plural
        ? translate('common.ordinals.plural.fourth')
        : translate('common.ordinals.singular.fourth');
    }
    default: {
      return `${index}.`;
    }
  }
});

const formatRecurrences = curry((translate, recurrences) => {
  if (!recurrences || recurrences.length < 1) {
    return '';
  }

  if (recurrences.length === 1) {
    return recurrences[0];
  }

  const and = translate('search.recurrence.format.and');
  if (recurrences.length === 2) {
    return recurrences.join(` ${and} `);
  }

  return `${recurrences.slice(0, -1).join(', ')} ${and} ${recurrences.slice(-1)}`;
});

const formatRecurrenceNever = curry(translate => translate('search.recurrence.format.never'));

const formatRecurrenceDaily = curry(translate => translate('search.recurrence.format.daily'));

const formatRecurrenceWeekly = curry((translate, pattern) => {
  const translateRecurrences = recurrences => formatRecurrences(translate, recurrences);
  const translateOrdinals = index => formatOrdinal(translate, index, true);
  const translateWeekday = weekday => translate(`common.days.${weekday}`);

  const weekdays = translateRecurrences(pattern.daysOfWeek.map(day => translateWeekday(day)));
  const index = translateOrdinals(pattern.index);
  const params = { index, weekdays };
  return pattern.index === 1
    ? translate('search.recurrence.format.weekly', params)
    : translate('search.recurrence.format.nthweekly', params);
});

const formatRecurrenceMonthly = curry((translate, pattern) => {
  const translateOrdinals = index => formatOrdinal(translate, index, true);
  const translateWeekday = weekday => translate(`common.days.${weekday}`);

  const isLast = pattern.weekOfMonth === 'last';
  const weekday = translateWeekday(pattern.dayOfMonth);
  const index = translateOrdinals(pattern.index);
  const params = { index, weekday };

  if (isLast) {
    return pattern.index === 1
      ? translate('search.recurrence.format.monthly.last', params)
      : translate('search.recurrence.format.nthmonthly.last', params);
  }

  params.weekIndex = translate(`common.ordinals.plural.${pattern.weekOfMonth}`);
  return pattern.index === 1
    ? translate('search.recurrence.format.monthly.weekday', params)
    : translate('search.recurrence.format.nthmonthly.weekday', params);
});

const formatRecurrence = curry((translate, type, pattern) => {
  if (type === 'daily') return formatRecurrenceDaily(translate);
  if (type === 'weekly') return formatRecurrenceWeekly(translate, pattern);
  if (type === 'monthly') return formatRecurrenceMonthly(translate, pattern);
  return formatRecurrenceNever(translate);
});

export {
  formatRecurrence,
  formatRecurrenceNever,
  formatRecurrenceDaily,
  formatRecurrenceWeekly,
  formatRecurrenceMonthly
};
