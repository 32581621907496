/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';

import { styles, lineStyles } from './styles';

function BeamerIcon({ disabled, ...otherProps }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...otherProps} style={styles}>
      <rect fill="none" />
      <path d="M27.6,9.2C27.3,9,27,8.9,26.7,9L22,10.6V10c0-1.7-1.3-3-3-3H5c-1.7,0-3,1.3-3,3v12c0,1.7,1.3,3,3,3h14c1.7,0,3-1.3,3-3v-0.6l4.7,1.6c0.3,0.1,0.6,0,0.9-0.1c0.3-0.2,0.4-0.5,0.4-0.8V10C28,9.7,27.8,9.4,27.6,9.2z M20,22c0,0.6-0.4,1-1,1H5c-0.6,0-1-0.4-1-1V10c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1V22z M26,20.6l-4-1.3v-6.5l4-1.3V20.6z" />
      {disabled && <line style={lineStyles} x1="7" y1="1.8" x2="23.8" y2="28.4" />}
    </svg>
  );
}

BeamerIcon.propTypes = {
  disabled: PropTypes.bool
};

export default BeamerIcon;
