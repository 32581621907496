import React from 'react';
import PropTypes from 'prop-types';

import { EmptyState, withTranslation } from '../common';

import './_styles.css';

function NoConnectionState({ translate }) {
  return (
    <EmptyState
      visible
      centered
      content={(
        <span>
          <h2 className="NoConnectionState__Title">{translate('common.noConnection.title')}</h2>
          {translate('common.noConnection.text')}
        </span>
    )}
      className="NoConnectionState"
      icon="241-robot-broken"
  />
  );
}

NoConnectionState.propTypes = { translate: PropTypes.func.isRequired };

export default withTranslation(NoConnectionState);
