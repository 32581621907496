import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Header from '../../Header';
import BookingCollectionFilter from '../BookingCollectionFilter';
import BookingCollectionLoader from '../BookingCollectionLoader';
import BookingCollectionEmptyState from '../BookingCollectionEmptyState';
import CollectionSearchResults from '../BookingCollectionSearchResults';
import BookingCollectionSidebar from '../BookingCollectionSidebar';
import { withTranslation, CustomPropTypes } from '../../common';

import {
  getFirstSelected,
  markOneSelected
} from '../../BookModal/RecurrenceExceptionModal/resourceSelectionUtil';

import './_style.css';

class BookingCollectionDesktop extends Component {
  renderHeader = () => {
    const { history, resourceType, translate } = this.props;
    const title = translate(`bookingCollection.addBookings.${resourceType.toLowerCase()}`);
    return <Header title={title} onClose={history.goBack} fixed={false} />;
  };

  renderFilter = () => {
    const { resourceType } = this.props;
    return <BookingCollectionFilter resourceType={resourceType} />;
  };

  renderLoader = () => {
    const { resources, isLoading } = this.props;
    const visible = resources && resources.length < 1 && isLoading;
    return <BookingCollectionLoader visible={visible} />;
  };

  renderEmptyState = () => {
    const { resources, isLoading, resourceType } = this.props;
    const visible = resources && resources.length < 1 && !isLoading;
    return <BookingCollectionEmptyState visible={visible} resourceType={resourceType} />;
  };

  renderBody = () => {
    const {
      isLoading,
      hasMoreResults,
      loadMore,
      addResource,
      selectResource,
      visibleAlternatives,
      resourceType,
      date
    } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    const resources = markOneSelected(this.props.resources);
    const selectedResource = getFirstSelected(resources);

    const loadMoreResources = () => loadMore(resourceType);

    if (resources && resources.length > 0) {
      return (
        <div className="BookingCollectionDesktop__Content">
          <div className="BookingCollectionDesktop__Results">
            <Scrollbars style={{ height: '100%' }} autoHide>
              <CollectionSearchResults
                resources={resources}
                isLoading={isLoading}
                hasMoreResults={hasMoreResults}
                loadMore={loadMoreResources}
                onSelect={selectResource}
                visibleAlternatives={visibleAlternatives}
              />
            </Scrollbars>
          </div>
          <div className="BookingCollectionDesktop__Sidebar">
            <BookingCollectionSidebar
              resource={selectedResource}
              onSelect={() => addResource(selectedResource)}
              date={date}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  render() {
    return (
      <div className="BookingCollectionDesktop">
        {this.renderHeader()}
        {this.renderFilter()}
        {this.renderLoader()}
        {this.renderEmptyState()}
        {this.renderBody()}
      </div>
    );
  }
}

BookingCollectionDesktop.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      resource: CustomPropTypes.resource,
      selected: PropTypes.bool.isRequired
    })
  ),
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired,
  hasMoreResults: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  resourceType: PropTypes.string.isRequired,
  selectResource: PropTypes.func.isRequired,
  addResource: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  visibleAlternatives: PropTypes.shape({
    roomtype: PropTypes.bool.isRequired,
    roomequipment: PropTypes.bool.isRequired,
    capacity: PropTypes.bool.isRequired
  })
};

export default withTranslation(withRouter(BookingCollectionDesktop));
