import React from 'react';
import PropTypes from 'prop-types';

function LinkButton({ label, visible, onClick, className }) {
  if (!visible) {
    return null;
  }

  const handleClick = event => {
    event.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  return (
    <div className={className}>
      <div>
        <button className="link" onClick={handleClick} onKeyPress={handleClick} type="button">
          {label}
        </button>
      </div>
    </div>
  );
}

LinkButton.propTypes = {
  visible: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string
};

LinkButton.defaultProps = {
  visible: true
};

export default LinkButton;
