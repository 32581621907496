import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Profile3, ShowAt } from '../../common';

function BookFormProfile3({ resource }) {
  const { resourceType, notes } = resource;
  const path = resourceType.toLowerCase() === 'equipment'
    ? 'search.detail.profile3.equipment'
    : 'search.detail.profile3.room';

  return (
    <div className="BookFormProfile3">
      <div>
        <div className="BookFormProfile3__InformationIcon">
          <Icon icon="137-handset" className="BookFormProfile3__Icon" />
        </div>
        <div className="BookFormProfile3__InformationText">
          <Profile3 resource={resource} path={path} book />
        </div>
        <ShowAt breakpoint="900AndAbove">
          {notes && (
            <div className="BookFormProfile3__Notes">
              <Icon icon="046-information-circle" className="BookFormProfile3__NotesIcon" />
              <span>{notes}</span>
            </div>
          )}
        </ShowAt>
      </div>
    </div>
  );
}

BookFormProfile3.propTypes = {
  resource: PropTypes.shape({
    managedBy: PropTypes.shape(),
    resourceType: PropTypes.string,
    notes: PropTypes.any
  }).isRequired
};

export default BookFormProfile3;
