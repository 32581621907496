import { combineReducers } from 'redux';

import exceptionsReducer from './exceptionsReducer';
import resourcesReducer from './resourcesReducer';
import alternativesReducer from './alternativesReducer';

export default combineReducers({
  exceptions: exceptionsReducer,
  resources: resourcesReducer,
  alternatives: alternativesReducer
});
