import debounce from 'debounce-promise';
import latest from 'promise-latest';
import config from '../config';
import fetch from './fetch';

const getUrl = path => `${config.remoteUrl}/${path}`;

async function post(searchRequest, recurrence) {
  const url = getUrl(`availability${recurrence ? '/recurring' : ''}`);
  return fetch(url, 'POST', searchRequest);
}

async function single(id, requestBody) {
  const url = getUrl(`availability/recurring/${id}`);
  return fetch(url, 'POST', requestBody);
}

export default {
  post: debounce(latest(post), 200, { leading: true }),
  single
};
