import omitBy from 'lodash/fp/omitBy';

import types from './types';

const initialState = {
  roomBookings: [],
  rooms: {},
  bookings: {},
  cachedBookings: {},
  isLoading: false,
  isLoadingMore: false,
  skipCount: 0,
  totalCount: 0,
  exportLoading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_ROOMS: {
      return { ...state, rooms: { ...state.rooms, ...action.rooms } };
    }

    case types.ADD_BOOKINGS: {
      return { ...state, bookings: { ...state.bookings, ...action.bookings } };
    }

    case types.ADD_ROOM_BOOKINGS: {
      return {
        ...state,
        roomBookings: [
          ...state.roomBookings,
          ...action.items.map(roomBooking => roomBooking.resource)
        ]
      };
    }

    case types.UPDATE_ROOMS: {
      return { ...state, rooms: action.rooms };
    }

    case types.UPDATE_BOOKINGS: {
      return { ...state, bookings: action.bookings };
    }

    case types.UPDATE_ROOM_BOOKINGS: {
      return { ...state, roomBookings: action.items.map(roomBooking => roomBooking.resource) };
    }

    case types.UPDATE_BOOKING: {
      const bookings = { ...state.bookings };
      const bookingKey = action.booking.calendarId + action.booking.resource.id;
      const oldBooking = bookings[bookingKey];
      bookings[bookingKey] = { ...oldBooking, ...action.booking };
      return { ...state, bookings };
    }

    case types.REMOVE_BOOKING: {
      const bookings = { ...state.bookings };
      const bookingKey = action.calendarId + action.resourceId;
      delete bookings[bookingKey];
      return { ...state, bookings };
    }

    case types.REMOVE_RECURRING: {
      const bookings = omitBy(booking => booking.globalId === action.globalId, state.bookings);

      return { ...state, bookings };
    }

    case types.UPDATE_SKIPCOUNT: {
      return { ...state, skipCount: action.skipCount };
    }

    case types.UPDATE_TOTALCOUNT: {
      return { ...state, totalCount: action.totalCount };
    }

    case types.UPDATE_LOADING: {
      return { ...state, isLoading: action.isLoading };
    }

    case types.UPDATE_LOADING_MORE: {
      return { ...state, isLoadingMore: action.isLoadingMore };
    }

    case types.CACHE_BOOKINGS: {
      return { ...state, cachedBookings: state.bookings };
    }

    case types.RESET_BOOKINGS_FROM_CACHE: {
      return { ...state, bookings: state.cachedBookings, cachedBookings: {} };
    }

    case types.EXPORT_LOADING: {
      return { ...state, exportLoading: action.loading };
    }

    default:
      return state;
  }
};

export default reducer;
