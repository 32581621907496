import config from '../config';
import fetch from './fetch';

const getUrl = id => `${config.remoteUrl}/resources/${id}/owners`;

async function get(id) {
  const url = getUrl(id);
  return fetch(url, 'GET');
}

export default { get };
