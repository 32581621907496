import types from './types';

const updateHappyComment = comment => ({
  type: types.UPDATE_HAPPY_COMMENT,
  comment
});

const updateSadComment = comment => ({
  type: types.UPDATE_SAD_COMMENT,
  comment
});

const hasTriedToSubmit = {
  type: types.HAS_TRIED_TO_SUBMIT
};

const submitting = isSubmitting => ({
  type: types.SUBMITTING,
  isSubmitting
});

const clear = {
  type: types.CLEAR
};

export default {
  updateHappyComment,
  updateSadComment,
  hasTriedToSubmit,
  submitting,
  clear
};
