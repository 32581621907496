import React from 'react';
import PropTypes from 'prop-types';

import { BookFormSubject, BookFor, BookFormPeople, BookFormMessage, BookFormReminder } from '.';

import './_style.css';

function BookForm({
  updateMessage,
  message,
  updateSubject,
  subject,
  updateReminder,
  reminder,
  isMessageValid,
  isSubjectValid,
  updateTerm,
  updateSelectedPeople,
  fetchPeople,
  attendeesSuggestions,
  attendeesSearchTerm,
  selectedAttendees,
  attendeesLoading,
  bookForPerson,
  bookForLoading,
  updatePerson,
  loadSuggestions,
  bookForSuggestions
}) {
  return (
    <div>
      <BookFormSubject
        updateSubject={updateSubject}
        isSubjectValid={isSubjectValid}
        subject={subject}
    />
      <BookFor
        updatePerson={updatePerson}
        loadSuggestions={loadSuggestions}
        person={bookForPerson}
        isLoading={bookForLoading}
        suggestions={bookForSuggestions}
    />
      <BookFormPeople
        term={attendeesSearchTerm}
        people={attendeesSuggestions}
        selectedPeople={selectedAttendees}
        loading={attendeesLoading}
        updateTerm={updateTerm}
        updateSelectedPeople={updateSelectedPeople}
        fetchPeople={fetchPeople}
    />
      <BookFormMessage
        updateMessage={updateMessage}
        isMessageValid={isMessageValid}
        message={message}
    />
      <BookFormReminder updateReminder={updateReminder} reminder={reminder} />
    </div>
  );
}
BookForm.propTypes = {
  isMessageValid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isSubjectValid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  updateSubject: PropTypes.func.isRequired,
  subject: PropTypes.string.isRequired,
  updateMessage: PropTypes.func.isRequired,
  message: PropTypes.string,
  updateReminder: PropTypes.func.isRequired,
  reminder: PropTypes.bool.isRequired,
  bookForPerson: PropTypes.shape({}),
  bookForLoading: PropTypes.bool.isRequired,
  bookForSuggestions: PropTypes.arrayOf(PropTypes.shape({})),
  loadSuggestions: PropTypes.func.isRequired,
  updatePerson: PropTypes.func.isRequired,
  updateTerm: PropTypes.func.isRequired,
  updateSelectedPeople: PropTypes.func.isRequired,
  fetchPeople: PropTypes.func.isRequired,
  attendeesSearchTerm: PropTypes.string.isRequired,
  attendeesSuggestions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedAttendees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  attendeesLoading: PropTypes.bool.isRequired
};

export default BookForm;
