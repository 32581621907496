import React from 'react';
import PropTypes from 'prop-types';

import { Icon, withTranslation } from '..';

import './_style.css';

function Tag({ item, onClick, translate }) {
  const { translation } = item;
  const needsTranslation = translation !== null && typeof translation === 'object';
  const name = needsTranslation ? translate(translation.path, translation.args) : translation;

  return (
    <div className="Tag">
      <span className="Tag__Content">{name}</span>
      <button aria-label="Close" className="Tag__Close" onClick={onClick} type="button">
        <Icon icon="022-close" />
      </button>
    </div>
  );
}

Tag.propTypes = {
  item: PropTypes.shape({
    translation: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        path: PropTypes.string,
        arg: PropTypes.string
      })
    ])
  }),
  onClick: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(Tag);
