import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Modal, withTranslation, Input, Button } from '../../../common';
import { floorsOperations, floorsSelectors } from '../../../../state/floors';
import { authSelectors } from '../../../../state/auth';
import Header from '../../../Header';
import formValidation from '../common';
import keys from './modalKeys';
import { modalOperations } from '../../../../state/modal';

class FloorUpdateModal extends Component {
  constructor(props) {
    super(props);

    const { floor } = this.props;

    this.state = {
      form: {
        id: floor?.id,
        name: floor?.name,
        buildingKey: floor?.buildingKey
      },
      isValid: {
        name: true
      },
      isLoading: true,
      formIsValid: false
    };
    this.validation = {
      name: (value, extraFieldValue) => (
        value !== ''
        && extraFieldValue?.indexOf(extraFieldValue?.find(x => x.name === value)) === -1)

    };
  }

  handleNameChange = (event, floors) => {
    const { value } = event.target;
    this.setState({ isLoading: false });
    formValidation.extraValidateField(this, 'name', value, floors);
    formValidation.extraValidateForm(this, 'name', value, floors);
  };

  renderName = () => {
    const { form, isValid } = this.state;
    const { translate, floors } = this.props;

    return (
      <>
        <Input
          label={`${ translate('common.name') } ${ translate('common.requiredField') }`}
          placeholder={translate('admin.settings.floors.update.placeholder.name')}
          onChange={event => this.handleNameChange(event, floors)}
          value={form?.name}
          error={!isValid.name}
          className="input"
        />
        {!isValid.name
          && floors.indexOf(floors.find(x => x.name === form.name)) === -1
          && formValidation.renderValidationError(this, 'admin.settings.floors.update.error.name')}
        {!isValid.name
          && floors.indexOf(floors.find(x => x.name === form.name)) !== -1
          && formValidation.renderValidationError(this, 'admin.settings.floors.update.error.duplicate')}
      </>
    );
  };

  renderUpdateButton = () => {
    const { submit,
      isSubmitting,
      history,
      translate,
      isNewBuilding,
      clearModals,
      updateFloor,
      selectedFloor } = this.props;
    const { form, formIsValid } = this.state;

    const onClick = async event => {
      event.preventDefault();
      event.stopPropagation();
      if (formIsValid) {
        if (isNewBuilding) {
          updateFloor({ name: form.name, previousName: selectedFloor?.name });
          clearModals();
          this.setState({ form: { name: '' }, isLoading: true });
        } else {
          const result = await submit(form);
          if (result === 'Ok') {
            history.push({
              pathname: './floors',
              buildingKey: form.buildingKey
            });
            clearModals();
            this.setState({ form: { name: '' }, isLoading: true });
          }
        }
      }
    };
    return (
      <div className="button--right">
        <Button
          icon="074-save"
          label={translate('common.update')}
          loading={isSubmitting}
          onClick={onClick}
          disabled={!formIsValid} />
      </div>
    );
  };

  render() {
    const { translate, clearModals, selectedFloor } = this.props;
    const { form, isLoading } = this.state;

    if (selectedFloor?.name && isLoading) {
      form.id = selectedFloor?.id;
      form.buildingKey = selectedFloor?.buildingKey;
      form.name = selectedFloor?.name;
    }

    return (
      <Modal modalKey={keys.FLOOR_UPDATE_DIALOG}>
        <>
          <Header
            onClose={() => { clearModals(); this.setState({ form: { name: '' }, isLoading: true }); }}
            title={translate('admin.settings.floors.update.title')}
            iconRight
            icon="022-close" />
          <div className="BookModalDesktop">
            <div className="BookModal__Main">
              <div className="BookModal__Body">
                <div className="BookModal__Content">
                  <div className="Create__Form">
                    {this.renderName()}
                    {this.renderUpdateButton()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </Modal>
    );
  }
}
const mapStateToProps = state => ({
  isAdmin: authSelectors.isAdministrator(state),
  selectedFloor: floorsSelectors.getSelectedFloor(state)
});

const mapDispatchToProps = dispatch => ({
  clearModals: () => {
    dispatch(modalOperations.clearModals());
  },
  submit: floor => dispatch(floorsOperations
    .updateFloor(floor))
});

FloorUpdateModal.propTypes = {
  clearModals: PropTypes.func.isRequired,
  floors: PropTypes.arrayOf(PropTypes.any),
  isSubmitting: PropTypes.bool,
  submit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  isNewBuilding: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  selectedFloor: PropTypes.any,
  floor: PropTypes.any,
  updateFloor: PropTypes.func
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation(FloorUpdateModal)));
