// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecurrenceFilterEmptyState_container__UO3S\\+ {\n  height: 60px;\n  display: flex;\n  align-items: center;\n  padding: 0 20px;\n  color: var(--color-white);\n}\n\n.RecurrenceFilterEmptyState_icon__zrRD0 {\n  font-size: 34px;\n  margin-right: 10px;\n  margin-top: -3px;\n}\n", "",{"version":3,"sources":["webpack://./src/views/Search/SearchDesktop/RecurrenceFilter/RecurrenceFilterEmptyState/RecurrenceFilterEmptyState.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".container {\n  height: 60px;\n  display: flex;\n  align-items: center;\n  padding: 0 20px;\n  color: var(--color-white);\n}\n\n.icon {\n  font-size: 34px;\n  margin-right: 10px;\n  margin-top: -3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "RecurrenceFilterEmptyState_container__UO3S+",
	"icon": "RecurrenceFilterEmptyState_icon__zrRD0"
};
export default ___CSS_LOADER_EXPORT___;
