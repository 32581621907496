import React from 'react';
import PropTypes from 'prop-types';

import { Icon, withTranslation, TextArea } from '../../common';

function BookFormMessage({ updateMessage, isMessageValid, message, translate }) {
  const onChange = event => {
    updateMessage(event.target.value);
  };

  const error = !isMessageValid ? translate('search.detail.message.invalid') : false;
  const placeholder = translate('search.detail.message.title');

  return (
    <div className="BookModalInput BookFormMessage">
      <Icon icon="080-speech-bubble" className="BookModalInput__Icon" />
      <TextArea
        placeholder={placeholder}
        onChange={onChange}
        error={error}
        value={message}
        fixed
        minRows={2}
        maxRows={6}
      />
    </div>
  );
}

BookFormMessage.propTypes = {
  updateMessage: PropTypes.func.isRequired,
  message: PropTypes.string,
  translate: PropTypes.func.isRequired,
  isMessageValid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default withTranslation(BookFormMessage);
