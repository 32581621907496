import actions from '../actions';
import { exceptionsService } from '../../../services';
import { notificationsSelectors, notificationsOperations } from '../../notifications';

const {
  updateResource,
  removeException,
  initializeExceptions,
  updateException,
  patchException,
  resetException,
  updateIsInProgress
} = actions;

const { hideError } = notificationsOperations;

const containsReason = reason => reasons => reasons.some(r => r.toUpperCase() === reason.toUpperCase());

const exceptionOperation = (dispatch, getState) => (action, callback) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);

  dispatch(updateIsInProgress(true));
  dispatch(hideError);
  return action
    .then(response => {
      const success = getNotification('search.recurrence.exceptions.notifications.success');
      callback(response);
      dispatch(notificationsOperations.showSuccess(success));
    })
    .catch(serverError => {
      const error = getNotification(
        'search.recurrence.exceptions.notifications.error',
        serverError
      );
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => dispatch(updateIsInProgress(false)));
};

const handleResponse = (dispatch, defaultAction) => exception => {
  const hasReasonUnknown = containsReason('Unknown');
  const { reasons, resourceMeetingResponseType } = exception;
  const isNoMoreAnException = hasReasonUnknown(reasons) && resourceMeetingResponseType === 'Accepted';

  const action = isNoMoreAnException
    ? () => dispatch(removeException(exception))
    : () => defaultAction(exception);

  action();
};

const patch = exception => (dispatch, getState) => {
  const { exceptionStart, exceptionEnd, resource, calendarId } = exception;
  const defaultAction = () => dispatch(patchException(exception));

  if (calendarId) {
    const operation = exceptionOperation(dispatch, getState);

    const payload = {
      start: exceptionStart,
      end: exceptionEnd,
      resourceId: resource.id
    };

    const action = exceptionsService.put(calendarId, payload);
    const callback = handleResponse(dispatch, defaultAction);
    return operation(action, callback);
  }

  defaultAction();
  return Promise.resolve();
};

const reset = exception => (dispatch, getState) => {
  const { calendarId } = exception;
  const defaultAction = exceptionToReset => dispatch(resetException(exceptionToReset));

  if (calendarId) {
    const operation = exceptionOperation(dispatch, getState);
    const action = exceptionsService.reset(calendarId);
    const callback = handleResponse(dispatch, defaultAction);
    return operation(action, callback);
  }

  defaultAction(exception);
  return Promise.resolve();
};

export default {
  updateResource,
  initializeExceptions,
  updateException,
  patchException: patch,
  resetException: reset
};
