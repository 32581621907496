import { createUserManager } from '../auth';

async function logout() {
  localStorage.clear();
  const userManager = await createUserManager();
  await userManager.signoutRedirect();
}

export default {
  logout
};
