const UPDATE_FLOORS = 'floors/UPDATE_FLOORS';
const UPDATE_LOADED = 'floors/UPDATE_LOADED';
const SET_SELECTED_FLOOR = 'floors/SET_SELECTED_FLOOR';
const RESET_FLOORS = 'floors/RESET_FLOORS';

export default {
  UPDATE_FLOORS,
  UPDATE_LOADED,
  SET_SELECTED_FLOOR,
  RESET_FLOORS
};
