/* eslint-disable class-methods-use-this */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { bookOperations } from '../../../../../../state/book';
import { authSelectors } from '../../../../../../state/auth';
import keys from '../../../../../BookModal/modalKeys';
import { emitter } from '../../../../../../util';

import { Icon } from '../../../../../common';

import './_style.css';

class CalendarBooking extends Component {
  constructor(props) {
    super(props);

    this.editSubscription = null;
    this.selectSubscription = null;
    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  componentDidMount() {
    this.editSubscription = emitter.addListener('onCalendarBookingEdit', this.onEdit);
    this.selectSubscription = emitter.addListener('onCalendarBookingSelect', this.onSelect);
  }

  componentWillUnmount() {
    this.editSubscription.remove();
    this.selectSubscription.remove();
  }

  onEdit(item) {
    if (this.isMyBooking(item)) {
      const { booking, openBookingModal } = this.props;
      openBookingModal(booking, keys.BOOK_DETAIL_MODAL);
    }
  }

  onSelect(item) {
    if (this.isMyBooking(item)) {
      this.hideTooltip();
    }
  }

  isMyBooking = item => {
    const { booking, user } = this.props;
    const { isMyBooking, id } = booking;
    const hasEmail = user.profile.email != null;
    let email = '';
    if (hasEmail) {
      if (typeof user.profile.email === 'string') {
        email = user.profile.email;
      }
    }

    if (Array.isArray(user.profile.email)) {
      const uniqueEmails = new Set(user.profile.email.map(e => e.toLowerCase()));

      if (uniqueEmails.size === 1) {
        email = [...uniqueEmails];
      } else {
        email = user.profile.email[0].toLowerCase();
      }
    }
    const isBookedForMe = email === (item.bookedFor && item.bookedFor.email.toLowerCase());
    return ((isMyBooking && item.isMyBooking) || isBookedForMe) && id === item.id;
  };

  showTooltip() {
    const { booking } = this.props;
    emitter.emit('onCalendarBookingShow', booking);
    ReactTooltip.show(this.container);
    ReactTooltip.rebuild();
  }

  hideTooltip() {
    emitter.emit('onCalendarBookingShow', null);
    ReactTooltip.show(this.container);
    ReactTooltip.rebuild();
  }

  render() {
    const { booking } = this.props;
    const { subject, organizer, isRecurring, isBookingCollection } = booking;

    const containerRef = node => {
      this.container = node;
    };

    return (
      <div
        ref={containerRef}
        data-tip
        data-for="CalendarBookingTooltip"
        onMouseEnter={this.showTooltip}
        onMouseLeave={this.hideTooltip}
        className="CalendarBooking"
      >
        <div className="CalendarBooking__Content">
          <p>
            <strong>{subject}</strong>
          </p>
          <p className="CalendarBooking__Organizer">
            <small>{organizer.displayName}</small>
          </p>
        </div>

        {isRecurring && (
          <div className="CalendarBooking__Icon">
            <Icon icon="007-backup" />
          </div>
        )}

        {isBookingCollection && (
          <div className="CalendarBooking__Icon CalendarBooking__Icon--BookingCollection">
            <Icon icon="006-attachment" />
          </div>
        )}

        <div style={{ clear: 'both' }} />
      </div>
    );
  }
}

CalendarBooking.propTypes = {
  booking: PropTypes.shape().isRequired,
  openBookingModal: PropTypes.func.isRequired,
  user: PropTypes.shape({
    profile: PropTypes.shape({
      email: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
      ])
    })
  }).isRequired
};

const mapStateToProps = state => ({
  user: authSelectors.getUser(state)
});

const mapDispatchToProps = dispatch => ({
  openBookingModal: (booking, modalKey) => dispatch(bookOperations.loadBooking(booking, modalKey))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalendarBooking);
