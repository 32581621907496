import store from 'store2';
import { createSelector } from 'reselect';

import { toDateTime, toDate } from '../../../util/time';

import { bookSelectors } from '../../book';
import { roomFilterSelectors } from '../../roomFilter';

const getTime = state => state.bookingCollectionState.bookingCollection.time;
const getSelectedResources = state => state.bookingCollectionState.bookingCollection.resources;

const getResource = (resourceId, state) => {
  const resources = getSelectedResources(state);
  return resources.find(({ id }) => id === resourceId.toLowerCase());
};

const getSelectedRooms = createSelector(
  getSelectedResources,
  resources => resources.filter(resource => resource.resourceType.toLowerCase() === 'room')
);

const getSelectedEquipments = createSelector(
  getSelectedResources,
  resources => resources.filter(resource => resource.resourceType.toLowerCase() === 'equipment')
);

const getStartDateTime = createSelector(
  [bookSelectors.getDate, getTime],
  (date, { from }) => toDateTime(toDate(date), from)
);

const getEndDateTime = createSelector(
  [bookSelectors.getDate, getTime],
  (date, { to }) => toDateTime(toDate(date), to)
);

const getDates = createSelector(
  [getStartDateTime, getEndDateTime],
  (start, end) => ({
    start,
    end
  })
);

const showWarning = () => {
  const key = 'bookingCollection.ignore.warning';
  const hasFlagInStore = store.has(key);
  return !hasFlagInStore || store(key) === false;
};

const visibleAlternatives = createSelector(
  [
    roomFilterSelectors.getSelectedFloorFilters,
    roomFilterSelectors.getSelectedSectorFilters,
    roomFilterSelectors.getSelectedCapacityFilters,
    roomFilterSelectors.getSelectedEquipmentFilters,
    roomFilterSelectors.getSelectedRoomTypeFilters
  ],
  (floorFilters, sectorFilters, capacityFilters, equipmentFilters, roomTypeFilters) => ({
    floor: floorFilters.length > 0,
    sector: sectorFilters.length > 0,
    capacity: capacityFilters.length > 0,
    roomequipment: equipmentFilters.length > 0,
    roomtype: roomTypeFilters.length > 0
  })
);

const isBookingCollection = createSelector(
  getSelectedResources,
  resources => resources.length > 0
);

const showBookingCollectionWarning = createSelector(
  [isBookingCollection],
  isCollection => isCollection && showWarning()
);

const getCollectionPayloads = payload => createSelector(
  getSelectedResources,
  selectedResources => selectedResources.map(({ id, seatingId, availabilities }) => {
    const hasAvailabilities = availabilities && availabilities.length > 0;
    const { start, end } = hasAvailabilities ? availabilities[0] : payload;
    return {
      ...payload,
      resourceId: id,
      seatingId,
      start,
      end
    };
  })
);

export default {
  getTime,
  showWarning,
  getDates,
  visibleAlternatives,
  isBookingCollection,
  showBookingCollectionWarning,
  getResource,
  getSelectedResources,
  getSelectedRooms,
  getSelectedEquipments,
  getCollectionPayloads
};
