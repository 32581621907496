import React from 'react';
import PropTypes from 'prop-types';

import { PersonPicker, ChangeDetector, withTranslation, Icon } from '../../common';

function FormFieldPerson({
  values,
  update,
  field,
  isSubmitted,
  detectChanges,
  translate,
  ...otherProps
}) {
  const updateValue = person => {
    update(field, person);
  };

  const labelText = translate(`changeRequests.fieldNames.${field}`);
  const infoText = translate(`changeRequests.infoTexts.${field}`);

  const invalid = isSubmitted && !values.new;

  return (
    <div className="ChangeRequestFormField">
      <ChangeDetector values={values} invalid={invalid} detectChanges={detectChanges}>
        <label htmlFor={field}>{labelText}</label>
        <div className="ChangeRequestFormField__Information">
          <Icon icon="046-information-circle" className="Information__Icon" />
          {infoText}
        </div>
        <PersonPicker
          id={field}
          label={labelText}
          person={values.new}
          updatePerson={updateValue}
          invalid={invalid}
          required
          {...otherProps}
        />
      </ChangeDetector>
    </div>
  );
}

const personPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
});

FormFieldPerson.propTypes = {
  values: PropTypes.shape({
    old: personPropType,
    new: personPropType
  }).isRequired,
  field: PropTypes.string.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  detectChanges: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired
};

FormFieldPerson.defaultProps = {
  detectChanges: true
};

export default withTranslation(FormFieldPerson);
