/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, Spacing } from '../../../common';
import { authSelectors } from '../../../../state/auth';
import ResourcesSubTypesLoader from './ResourcesSubTypesLoader';

import { resourcesSubTypesOperations, resourcesSubTypesSelectors } from '../../../../state/resourcesSubTypes';
import ResourcesSubTypesTable from './ResourcesSubTypesTable';
import { modalOperations } from '../../../../state/modal';
import modalKeys from './modalKeys';

import NewResourcesSubTypesButton from './NewResourcesSubTypesButton';

class ResourcesSubTypes extends Component {
  constructor() {
    super();
    this.state = {
      hasScroll: false
    };
  }

  componentDidMount() {
    const { loadResourcesSubTypes, isAdmin, history } = this.props;
    if (!isAdmin) {
      history.replace('/unauthorized');
    }
    loadResourcesSubTypes();
  }

  renderLoader = () => {
    const { isLoaded } = this.props;
    return <ResourcesSubTypesLoader visible={!isLoaded} />;
  };

  separatorShadow = { boxShadow: 'none' };

  onTableScrollBar = hasScroll => {
    if (hasScroll) {
      this.separatorShadow = {
        boxShadow: '0px -2px 8px 0px rgba(0, 0, 0, 0.2)'
      };
    } else {
      this.separatorShadow = { boxShadow: 'none' };
    }
    this.setState({ hasScroll });
  };

  renderResourcesSubTypes = () => {
    const {
      isLoaded,
      hasResourcesSubTypes,
      resourcesSubTypes,
      openResourcesSubTypeDeleteDialog
    } = this.props;
    const showResourcesSubTypes = isLoaded && hasResourcesSubTypes;
    if (!showResourcesSubTypes) {
      return null;
    }

    return (
      <div className="Settings__Table">
        <ResourcesSubTypesTable
          onTableScrollBar={this.onTableScrollBar}
          resourcesSubTypes={resourcesSubTypes}
          actionOnClick={openResourcesSubTypeDeleteDialog} />
      </div>
    );
  };

  render() {
    const { openResourcesSubTypeCreateModal, translate } = this.props;

    return (
      <Spacing horizontal="1" vertical="1">
        <div className="Settings__TableHead">
          <h3 className="Settings__Title">
            {translate('admin.settings.resourcesSubTypes.table.title')}
          </h3>
        </div>
        {this.renderLoader()}
        {this.renderResourcesSubTypes()}
        <div className="button--right--container" style={this.separatorShadow}>
          <div className="button--right">
            <NewResourcesSubTypesButton actionOnClick={openResourcesSubTypeCreateModal} />
          </div>
        </div>
      </Spacing>
    );
  }
}

ResourcesSubTypes.propTypes = {
  isAdmin: PropTypes.bool,
  isLoaded: PropTypes.bool,
  resourcesSubTypes: PropTypes.objectOf(PropTypes.any),
  hasResourcesSubTypes: PropTypes.bool,
  loadResourcesSubTypes: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  openResourcesSubTypeCreateModal: PropTypes.func.isRequired,
  openResourcesSubTypeDeleteDialog: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

ResourcesSubTypes.defaultProps = {
  isAdmin: false,
  isLoaded: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoaded: resourcesSubTypesSelectors.isLoaded(state),
  resourcesSubTypes: resourcesSubTypesSelectors.getAll(state),
  hasResourcesSubTypes: resourcesSubTypesSelectors.hasResourcesSubTypes(state),
  isAdmin: authSelectors.isAdministrator(state)
});

const mapDispatchToProps = dispatch => ({
  loadResourcesSubTypes: () => dispatch(resourcesSubTypesOperations.loadResourcesSubTypes()),
  openResourcesSubTypeDeleteDialog: () => {
    dispatch(
      modalOperations.pushModal(modalKeys.RESOURCESSUBTYPE_DELETE_DIALOG)
    );
  },
  openResourcesSubTypeCreateModal: () => dispatch(modalOperations.pushModal(modalKeys.RESOURCESSUBTYPE_CREATE_DIALOG))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(withRouter(ResourcesSubTypes)));
