import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation, Icon } from '../../../common';
import BuildingsDeleteDialog from './BuildingsDeleteDialog';

class BuildingsTable extends Component {
  constructor() {
    super();
    this.tableWrapper = React.createRef();
    this.setHasScroll = this.setHasScroll.bind(this);
  }

  componentDidMount() {
    this.setHasScroll();
    window.addEventListener('resize', this.setHasScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHasScroll);
  }

  setHasScroll() {
    const { onTableScrollBar } = this.props;
    const maxHeight = window.innerHeight - 300; // same maxHeight as style .scrollable_table__wrapper
    const box = this.tableWrapper.current?.getBoundingClientRect();
    const hasScroll = box.height >= maxHeight;
    onTableScrollBar(hasScroll);
  }

  renderHeaderRow = () => {
    const { translate } = this.props;

    const columns = [
      translate('common.key'),
      translate('common.name'),
      translate('admin.settings.buildings.table.header.region'),
      translate('admin.settings.buildings.table.header.cip'),
      translate('common.city'),
      translate('admin.settings.buildings.buildingManagers'),
      translate('admin.settings.buildings.buildingOwner'),
      translate('admin.settings.buildings.table.header.floors'),
      ''
    ];

    const renderCell = value => <th key={value}>{value}</th>;
    return <tr>{columns.map(renderCell)}</tr>;
  };

  renderRow = building => {
    const responsibles = building.responsibles
      .reduce((accumulator, responsible) => `${ accumulator } ${ responsible?.displayName?.trim() === ''
        ? responsible?.accountName : responsible?.displayName },`, '')
      .slice(0, -1).trim();
    const owner = (Object.values(building.responsibles).filter(_ => _.isResponsibleOwner))[0]?.accountName;

    const cells = [
      { key: building.key },
      { name: building.name },
      { name: building.region },
      { name: building.cip },
      { name: building.city },
      { name: responsibles },
      { name: owner }
    ];

    const setUpModal = () => {
      const { selectBuilding, openDeleteModal } = this.props;
      selectBuilding(building);
      openDeleteModal();
    };

    const renderCell = (item, index) => {
      const value = Object.values(item)[0];
      return (
        <td key={index}>
          {value}
        </td>
      );
    };

    const redirect = () => {
      const { history } = this.props;
      const url = `/admin/settings/buildings/${ building.id }`;
      history.push(url);
    };

    return (
      <tr key={building.key}>
        {cells.map((value, index) => renderCell(value, index))}
        <td>
          <Link to={{ pathname: './Floors', buildingKey: building.key }}>
            {' '}
            {building.floors.length}
            {' '}
          </Link>
        </td>
        <td aria-label="Delete">
          <Icon icon="008-bin" className="Delete__Icon" onClick={setUpModal} />
          <Icon
            icon="025-edit"
            className="Edit__Icon"
            onClick={redirect} />
        </td>
      </tr>
    );
  };

  render() {
    const { buildings } = this.props;
    buildings.sort((a, b) => {
      if (a.key > b.key) return 1;
      if (a.key < b.key) return -1;
      return 0;
    });

    return (
      <>
        <div className="table table--responsive" ref={this.tableWrapper}>
          <div className="scrollable_table__wrapper">
            <table>
              <thead>{this.renderHeaderRow()}</thead>
              <tbody>{buildings.map(this.renderRow)}</tbody>
            </table>
          </div>
        </div>
        <BuildingsDeleteDialog />
      </>
    );
  }
}

BuildingsTable.propTypes = {
  buildings: PropTypes.arrayOf(PropTypes.any),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired,
  selectBuilding: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  onTableScrollBar: PropTypes.func.isRequired
};

export default withRouter(withTranslation(BuildingsTable));
