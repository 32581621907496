import actions from '../actions';
import selectors from '../selectors';
import { roomFilterSelectors } from '../../roomFilter';
import { equipmentFilterSelectors } from '../../equipmentFilter';
import { notificationsSelectors, notificationsOperations } from '../../notifications';
import { locationsSelectors } from '../../locations';
import { alternativesService } from '../../../services';

const pageSize = 4;

const clearAlternatives = actions.clearAvailabilities;

const createPayload = state => {
  const { start, end } = selectors.getDates(state);

  const capacities = roomFilterSelectors.getSelectedCapacitiesNumbers(state);
  const roomTypes = roomFilterSelectors.getSelectedRoomTypesIds(state);
  const equipment = roomFilterSelectors.getSelectedEquipmentNames(state);
  const equipmentTypes = equipmentFilterSelectors.getSelectedEquipmentTypeIds(state);
  const locations = locationsSelectors.getLocationsByType(state);
  const floors = roomFilterSelectors.getSelectedFloorIds(state);
  const sectors = roomFilterSelectors.getSelectedSectorIds(state);

  return {
    start,
    end,
    exactTime: true,
    locations,
    roomTypes,
    capacities,
    equipment,
    floors,
    sectors,
    equipmentTypes,
    recurrence: false,
    resourceTypes: ['room'],
    pageSize,
    skipCount: 0
  };
};

const loadAlternativeResources = (dispatch, state) => (section, payload) => {
  dispatch(actions.updateIsLoading(section, true));
  return alternativesService
    .post(payload, section)
    .then(response => {
      const { totalCount, nextSkipCount } = response;
      const hasMore = totalCount > nextSkipCount;
      dispatch(actions.updateHasMoreResults(section, hasMore));
      dispatch(actions.updateSkipCount(section, nextSkipCount));
      dispatch(
        payload.skipCount === 0
          ? actions.updateAvailability(section, response.results)
          : actions.addAvailability(section, response.results)
      );
    })
    .catch(serverError => {
      const getNotification = notificationsSelectors.getNotification(state);
      const error = getNotification('search.load.error', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => {
      dispatch(actions.updateIsLoading(section, false));
    });
};

const loadAlternatives = resourceType => (dispatch, getState) => {
  const state = getState();
  const load = loadAlternativeResources(dispatch, state);
  const visibleAlternatives = selectors.visibleAlternatives(state);

  if (resourceType.toLowerCase() === 'equipment') {
    return Promise.resolve();
  }

  const payload = createPayload(state);
  payload.skipCount = 0;
  payload.pageSize = pageSize;

  const filterSections = section => section !== 'time';
  const filterActiveSections = section => visibleAlternatives[section];
  const loadSection = section => {
    dispatch(actions.updateAvailability(section, []));
    dispatch(actions.updateSkipCount(section, 0));
    return load(section, payload);
  };

  const sections = selectors.getSectionNames(state);
  const promises = sections
    .filter(filterSections)
    .filter(filterActiveSections)
    .map(loadSection);
  return Promise.all(promises);
};

const loadMoreAlternatives = section => (dispatch, getState) => {
  const state = getState();
  const load = loadAlternativeResources(dispatch, state);

  const hasMore = selectors.getHasMore(section)(state);
  if (!hasMore) {
    return Promise.reject(new Error('no more results available'));
  }

  const nextSkipCount = selectors.getSkipCount(section)(state);

  const payload = createPayload(state);
  payload.skipCount = nextSkipCount;
  payload.pageSize = pageSize;
  return load(section, payload);
};

export default {
  createPayload,
  loadAlternatives,
  loadMoreAlternatives,
  clearAlternatives
};
