import config from '../config';
import fetch from './fetch';

const getUrl = path => `${ config.remoteUrl }/${ path }`;

async function get() {
  const url = getUrl('regions');
  return fetch(url, 'GET');
}

async function addRegion(payload) {
  const url = getUrl('regions/add');
  return fetch(url, 'POST', payload);
}

async function updateRegion(regionId, payload) {
  const url = getUrl(`regions/update/${regionId}`);
  return fetch(url, 'PUT', payload);
}

async function remove(regionKey) {
  const url = getUrl(`regions/delete?regionKey=${regionKey}`);
  return fetch(url, 'DELETE');
}

export default {
  get,
  addRegion,
  updateRegion,
  remove
};
