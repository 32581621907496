import isEqual from 'lodash/fp/isEqual';
import types from './types';
import { getCurrentDate } from '../../util';

const initialState = {
  resourceType: 'room',
  date: getCurrentDate(),
  time: {
    from: '',
    to: ''
  },
  changed: false,
  searchedExactTime: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SWITCH_RESOURCE_TYPE: {
      const { resourceType } = state;
      return { ...state, resourceType: resourceType === 'room' ? 'equipment' : 'room' };
    }

    case types.UPDATE_DATE: {
      const { date } = action;
      if (isEqual(date, state.date)) {
        return { ...state };
      }
      return { ...state, date, changed: true };
    }

    case types.UPDATE_TIME: {
      const { time } = action;
      if (isEqual(time, state.time)) {
        return { ...state };
      }
      return { ...state, time, changed: true };
    }

    case types.RESET_CHANGED: {
      return { ...state, roomsChanged: false };
    }

    case types.UPDATE_SEARCHED_EXACT_TIME: {
      return {
        ...state,
        searchedExactTime: action.searchedExactTime
      };
    }

    default:
      return state;
  }
};

export default reducer;
