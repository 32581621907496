import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { bookOperations } from '../../../state/book';

import { Icon, Input, DateInput, DateInputFlyout, withTranslation } from '../../common';

class BookFormDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPicker: false
    };

    this.onChange = this.onChange.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    this.showFlyout = this.showFlyout.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutside, true);
  }

  onClickOutside(event) {
    const { domNode } = this;
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ showPicker: false });
    }
  }

  onChange(value) {
    const { updateDate, loadSingleAvailability, onBlur } = this.props;
    updateDate(value);
    if (onBlur) {
      onBlur({ target: { value } });
    }
    loadSingleAvailability();
  }

  showFlyout() {
    if (this.flyout) {
      this.flyout.show();
    }
  }

  render() {
    const ref = element => {
      this.domNode = element;
    };

    const showPopup = () => {
      this.setState({ showPicker: true });
    };

    const { date, isValidDate, isDateAfterMax, translate, mobile, onBlur } = this.props;
    const { showPicker } = this.state;
    let error = !isValidDate ? translate('search.when.invalid') : '';

    if (isDateAfterMax) {
      error = translate('common.invalidInFuture');
    }

    const placeholder = translate('search.when.chooseDate');

    return (
      <div className="BookModalInputDateWrapper" ref={ref}>
        <div className="BookModalInput BookModalInputDate">
          <Icon icon="009-calendar" className="BookModalInput__Icon" />
          <Input
            placeholder={placeholder}
            onClick={mobile ? showPopup : this.showFlyout}
            value={date}
            onBlur={onBlur}
            onChange={e => this.onChange(e.target.value)}
            error={error}
          />
        </div>
        {showPicker && mobile && (
          <DateInput
            placeholder={placeholder}
            className="BookDetailDatePicker"
            value={date}
            error={error}
            onChange={this.onChange}
            onBlur={this.onBlur}
            showInput={false}
          />
        )}
        {!mobile && (
          <DateInputFlyout
            placeholder={placeholder}
            className="BookDetailDatePicker"
            value={date}
            error={error}
            onChange={this.onChange}
            onBlur={this.onBlur}
            showInput={false}
            ref={elem => {
              this.flyout = elem;
            }}
          />
        )}
      </div>
    );
  }
}

BookFormDate.propTypes = {
  date: PropTypes.string,
  isValidDate: PropTypes.bool.isRequired,
  isDateAfterMax: PropTypes.bool,
  translate: PropTypes.func.isRequired,
  updateDate: PropTypes.func.isRequired,
  loadSingleAvailability: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  mobile: PropTypes.bool
};

const mapDispatchToProps = dispatch => ({
  loadSingleAvailability: () => dispatch(bookOperations.loadSingleAvailability())
});

export default connect(
  null,
  mapDispatchToProps
)(withTranslation(BookFormDate));
