import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withTranslation, Icon, FormField } from '../../common';

const DECLINED = 'Declined';
const ACCEPTED = 'Accepted';
const TENTATIVE = 'Tentative';
const UNKNOWN = 'Unknown';

class BookDetailAttendees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
  }

  toggleAttendees = collapsed => () => {
    this.setState({
      collapsed
    });
  };

  render() {
    const { collapsed } = this.state;
    const { attendees, translate } = this.props;
    const attendeesLabel = translate('search.detail.attendees.name');

    const getIcon = responseType => {
      switch (responseType) {
        case DECLINED:
          return (
            <Icon icon="202-clear-circle" className="BookDetailResponseType ResponseTypeRed" />
          );
        case ACCEPTED:
          return (
            <Icon
              icon="012-check-mark-circle"
              className="BookDetailResponseType ResponseTypeGreen"
            />
          );
        case TENTATIVE:
          return (
            <Icon
              icon="026-exclamation-mark-circle"
              className="BookDetailResponseType ResponseTypeOrange"
            />
          );
        case UNKNOWN:
          return (
            <Icon
              icon="062-question-mark-circle"
              className="BookDetailResponseType ResponseTypeOrange"
            />
          );

        default:
          return null;
      }
    };

    const attendeeList = attendees.map(attendee => (
      <span key={attendee.email} className="Attendee">
        {getIcon(attendee.responseType)}
        {`${attendee.name}`}
        <br />
      </span>
    ));

    if (attendeeList.length === 0) {
      attendeeList.push(<span key="defaultAttendee">-</span>);
    }

    if (attendeeList.length > 3 && collapsed) {
      const moreAttendees = attendeeList.length - 3;
      const more = translate('search.detail.attendees.more', { moreAttendees });

      attendeeList.splice(3, attendeeList.length);
      attendeeList.push(
        <button onClick={this.toggleAttendees(false)} key="moreAttendees" type="button">
          {more}
        </button>
      );
    }

    if (attendeeList.length > 3 && !collapsed) {
      const less = translate('search.detail.attendees.less');
      attendeeList.push(
        <button onClick={this.toggleAttendees(true)} key="moreAttendees" type="button">
          {less}
        </button>
      );
    }

    const label = <strong>{attendeesLabel}</strong>;
    return <FormField className="BookDetail__FormField" label={label} element={attendeeList} />;
  }
}

BookDetailAttendees.propTypes = {
  attendees: PropTypes.arrayOf(PropTypes.shape({})),
  translate: PropTypes.func.isRequired
};

export default withTranslation(BookDetailAttendees);
