import PropTypes from 'prop-types';
import resourcePropType from '../resource/propTypes';

export default PropTypes.shape({
  end: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  resource: resourcePropType.isRequired,
  recurrencePattern: PropTypes.oneOf(['Daily', 'Weekly', 'Monthly']),
  recurrenceEnd: PropTypes.shape({
    endDate: PropTypes.string,
    endType: PropTypes.oneOf(['EndDate', 'EndAfter']).isRequired,
    numberOfOccurrences: PropTypes.number
  })
});
