import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import onClickOutside from 'react-onclickoutside';

import './_style.css';

class DialogContent extends Component {
  handleClickOutside() {
    const { onOverlayClick } = this.props;
    if (onOverlayClick) {
      onOverlayClick();
    }
  }

  render() {
    const { className, children } = this.props;

    const classes = cx('Dialog__Container', className);

    return (
      <div className="Dialog">
        <div className={classes}>
          <div className="Dialog__Content">{children}</div>
        </div>
      </div>
    );
  }
}

DialogContent.propTypes = {
  onOverlayClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.element.isRequired
};

export default onClickOutside(DialogContent);
