import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Spacing, Button, withTranslation } from '../../common';
import Header from '../../Header';

import './_style.css';

class ChangeRequestRejectModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: ''
    };
  }

  componentDidMount() {
    setTimeout(() => this.textInput.focus(), 0);
  }

  updateReason = event => {
    const { value } = event.target;
    this.setState({ reason: value });
  };

  submit = () => {
    const { onSubmit } = this.props;
    const { reason } = this.state;
    if (reason) {
      onSubmit(reason);
    }
  };

  render() {
    const { onClose, translate } = this.props;
    const { reason } = this.state;
    const title = translate('changeRequests.reject.title');
    const label = translate('changeRequests.reject.text');
    const reject = translate('common.reject');
    const disabled = !reason || !reason.length;
    return (
      <div className="ChangeRequestRejectModal">
        <Header title={title} onClose={onClose} icon="022-close" iconRight fixed={false} />
        <Spacing vertical="1" horizontal="1">
          <div className="ChangeRequestRejectModal__Label">{label}</div>
          <textarea
            onChange={this.updateReason}
            value={reason}
            rows={4}
            ref={input => {
              this.textInput = input;
            }}
          />
          <div className="ChangeRequestRejectModal__Actions">
            <Button
              className="ChangeRequestRejectModal__Button"
              disabled={disabled}
              label={reject}
              onClick={this.submit}
            />
          </div>
        </Spacing>
      </div>
    );
  }
}

ChangeRequestRejectModalContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(ChangeRequestRejectModalContent);
