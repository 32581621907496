import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, RoomCard } from '../../../../common';
import Room from '../Room';
import FormTimeAndSubmit from '../FormTimeAndSubmit';
import FormSubmit from '../FormSubmit';
import ProfileInformation from '../ProfileInformation';

function RoomCardMobile(props) {
  const { resource, selected, showTimeInput, onSelect, isProfile3, isProfile2 } = props;
  const { alternativeType, showRelativeTime, showJournal } = props;

  const renderRoomInformation = () => {
    if (!selected || isProfile3) {
      return (
        <Room
          resource={resource}
          alternativeType={alternativeType}
          key="Room"
          showRelativeTime={showRelativeTime}
        />
      );
    }
    return null;
  };

  const renderSelectedNoTimeInput = () => {
    if (selected && !showTimeInput && !isProfile3 && !showJournal) {
      return <FormSubmit small openModal={onSelect} resource={resource} key="FormSubmit" />;
    }
    return null;
  };

  const renderSelectedTimeInput = () => {
    if (selected && (showTimeInput || showJournal) && !isProfile3) {
      return <FormTimeAndSubmit openModal={onSelect} resource={resource} key="FormTimeAndSubmit" />;
    }
    return null;
  };

  const renderProfileInformation = () => {
    if ((isProfile2 || isProfile3) && !selected) {
      return <ProfileInformation resource={resource} />;
    }
    return null;
  };

  const onClick = () => {
    if (isProfile3) {
      onSelect();
    }
  };

  return (
    <div>
      <RoomCard resourceSubType={resource.resourceSubType} onClick={onClick}>
        {renderRoomInformation()}
        {renderSelectedNoTimeInput()}
        {renderSelectedTimeInput()}
        {renderProfileInformation()}
      </RoomCard>
    </div>
  );
}

RoomCardMobile.propTypes = {
  resource: CustomPropTypes.resource,
  selected: PropTypes.bool.isRequired,
  showTimeInput: PropTypes.bool.isRequired,
  showRelativeTime: PropTypes.bool.isRequired,
  isProfile3: PropTypes.bool.isRequired,
  isProfile2: PropTypes.bool.isRequired,
  alternativeType: PropTypes.string,
  showJournal: PropTypes.bool,
  onSelect: PropTypes.func
};

export default RoomCardMobile;
