import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { lookupTranslation } from '../../../util';
import { i18nSelectors } from '../../../state/i18n';

function Translate({ path, className, style, translations, ...props }) {
  const text = lookupTranslation(translations, path, props);

  return (
    <span className={className} style={style}>
      {text}
    </span>
  );
}

Translate.propTypes = {
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
  translations: PropTypes.shape()
};

const mapStateToProps = state => ({
  translations: i18nSelectors.getTranslations(state)
});

export default connect(mapStateToProps)(Translate);
