import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon, Input, withTranslation } from '../../common';

class BookFormSubject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dirty: false
    };
  }

  render() {
    const { updateSubject, subject, isSubjectValid, translate } = this.props;
    const { dirty } = this.state;

    const onChange = event => {
      this.setState({ dirty: true });
      updateSubject(event.target.value);
    };

    const error = !isSubjectValid && dirty ? translate('search.detail.subject.invalid') : false;
    const placeholder = translate('search.detail.subject.title');

    return (
      <div className="BookModalInput">
        <Icon icon="065-rename" className="BookModalInput__Icon" />
        <Input
          placeholder={placeholder}
          onChange={onChange}
          error={error}
          defaultValue={subject}
          ref={inp => {
            this.input = inp;
          }}
        />
      </div>
    );
  }
}

BookFormSubject.propTypes = {
  updateSubject: PropTypes.func.isRequired,
  isSubjectValid: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  subject: PropTypes.string,
  translate: PropTypes.func
};
export default withTranslation(BookFormSubject);
