import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  changeRequestDetailSelectors,
  changeRequestDetailOperations
} from '../../../state/changeRequestDetail';

import { Dialog } from '../../common';
import ChangeRequestRejectModalComponent from './ChangeRequestRejectModalContent';

function ChangeRequestRejectModal({ dialogKey, onClose, onSubmit, history }) {
  const submit = reason => {
    onSubmit(reason).then(result => {
      if (result) {
        history.replace('/changerequests/my');
      }
    });
  };

  return (
    <div>
      <Dialog dialogKey={dialogKey} onOverlayClick={onClose}>
        <ChangeRequestRejectModalComponent onClose={onClose} onSubmit={submit} />
      </Dialog>
    </div>
  );
}

const mapStateToProps = () => ({
  dialogKey: changeRequestDetailSelectors.getRejectModalKey()
});

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(changeRequestDetailOperations.abortReject()),
  onSubmit: reason => dispatch(changeRequestDetailOperations.submitReject(reason))
});

ChangeRequestRejectModal.propTypes = {
  dialogKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChangeRequestRejectModal));
