import { createSelector } from 'reselect';

const getLanguage = state => state.i18nState.i18n.language;
const getTranslations = state => state.i18nState.i18n.translations;
const getSupportedLanguages = state => state.i18nState.i18n.supportedLanguages;

const getLocale = createSelector(getLanguage, language => {
  switch (language) {
    case 'de': {
      return 'de-CH';
    }

    case 'fr': {
      return 'fr-CH';
    }

    case 'it': {
      return 'it-CH';
    }

    case 'en': {
      return 'en-US';
    }

    default:
      return 'de-CH';
  }
});

export default {
  getLocale,
  getLanguage,
  getTranslations,
  getSupportedLanguages
};
