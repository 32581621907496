import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { RadioGroup, Radio, Icon } from '../../common';

import { FormFieldPeople } from '../FormElements';

class ProfileSwitcher extends Component {
  switchProfile = event => {
    const { updateFormValue } = this.props;
    updateFormValue('profile', event.target.value);
  };

  renderOwnersProfile = ({ profile }) => {
    const {
      isPeopleLoading,
      peopleSuggestions,
      loadPeopleSuggestions,
      updateFormValue,
      profile: selectedProfile,
      isSubmitted
    } = this.props;

    // eslint-disable-next-line react/destructuring-assignment
    const values = this.props[`ownersProfile${profile}`];

    const active = selectedProfile.new === profile.toString();
    if (!active) {
      return null;
    }

    const props = {
      label: 'owners',
      field: `ownersProfile${profile}`,
      values,
      loading: isPeopleLoading,
      suggestions: peopleSuggestions,
      loadSuggestions: loadPeopleSuggestions,
      update: updateFormValue,
      isSubmitted: isSubmitted && active,
      detectChanges: false
    };

    return <FormFieldPeople {...props} />;
  };

  renderProfile = ({ profile, children }) => {
    const { translate } = this.props;
    const label = translate(`changeRequests.profile.title.profile${profile}`);
    const info = translate(`changeRequests.profile.description.profile${profile}`);

    return (
      <div className="ProfileSwitcher__Option">
        <Radio label={label} value={`${profile}`} />
        <div className="ProfileSwitcher__Item">
          <p className="ProfileSwitcher__Info">
            <Icon className="ProfileSwitcher__Icon" icon="046-information-circle" />
            <span>{info}</span>
          </p>
          {children}
        </div>
      </div>
    );
  };

  render() {
    const { profile, isSubmitted, translate } = this.props;
    const invalid = !profile.new && isSubmitted;
    const label = translate('changeRequests.fieldNames.profile');
    return (
      <div className="ChangeRequestFormField">
        <RadioGroup label={label} onChange={this.switchProfile} value={profile.new}>
          {this.renderProfile({ profile: 1 })}
          {this.renderProfile({ profile: 2, children: this.renderOwnersProfile({ profile: 2 }) })}
          {this.renderProfile({ profile: 3, children: this.renderOwnersProfile({ profile: 3 }) })}
          {this.renderProfile({ profile: 4, children: this.renderOwnersProfile({ profile: 4 }) })}
        </RadioGroup>
        {invalid && (
          <p className="ChangeRequestFormField__Required">{translate('common.required')}</p>
        )}
      </div>
    );
  }
}

ProfileSwitcher.propTypes = {
  profile: PropTypes.shape().isRequired,
  isPeopleLoading: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  peopleSuggestions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loadPeopleSuggestions: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

export default ProfileSwitcher;
