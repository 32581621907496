const CLEAR_MYBOOKINGS = 'myBookings/CLEAR_MYBOOKINGS';
const ADD_MYBOOKINGS = 'myBookings/ADD_MYBOOKINGS';
const UPDATE_MYBOOKING = 'myBookings/UPDATE_MYBOOKING';
const UPDATE_MYBOOKINGS_DATE = 'myBookings/UPDATE_MYBOOKINGS_DATE';
const UPDATE_MYBOOKINGS_LOADING = 'myBookings/UPDATE_MYBOOKINGS_LOADING';
const REMOVE_MYBOOKING = 'myBookings/REMOVE_MYBOOKING';
const REMOVE_RECURRENCE = 'myBookings/REMOVE_RECURRENCE';

export default {
  CLEAR_MYBOOKINGS,
  ADD_MYBOOKINGS,
  UPDATE_MYBOOKING,
  UPDATE_MYBOOKINGS_DATE,
  UPDATE_MYBOOKINGS_LOADING,
  REMOVE_MYBOOKING,
  REMOVE_RECURRENCE
};
