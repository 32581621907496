/* eslint-disable no-param-reassign */
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import initSubscriber from 'redux-subscriber';

import global from './global';
import auth from './auth';
import alternatives from './alternatives';
import availability from './availability';
import exceptions from './exceptions';
import i18n from './i18n';
import feedback from './feedback';
import config from './config';
import regions from './regions';
import floors from './floors';
import buildingsData from './buildings';
import locations from './locations';
import myBookings from './myBookings';
import recurrence from './recurrence';
import recurrenceMaster from './recurrenceMaster';
import search from './search';
import timeline from './timeline';
import timelineBookings from './timelineBookings';
import book from './book';
import favorite from './favorite';
import roomFilter from './roomFilter';
import equipmentFilter from './equipmentFilter';
import modal from './modal';
import notifications from './notifications';
import myChangeRequests from './myChangeRequests';
import editResource from './editResource';
import changeMasterData from './changeMasterData';
import createResource from './createResource';
import changePermissions from './changePermissions';
import changeOwner from './changeOwner';
import deleteResource from './deleteResource';
import changeRequestDetail from './changeRequestDetail';
import bookingCollection from './bookingCollection';
import roomPicker from './roomPicker';
import maintenanceMessage from './maintenanceMessage';
import photoAdmin from './photoAdmin';
import resourcesSubTypes from './resourcesSubTypes';
import { initialState as i18nInitialState } from './i18n/reducers';
import types from './auth/types';

let store = null;

const configureStore = (translations = {}) => {
  if (store) {
    return store;
  }

  const reducers = {
    globalState: global,
    authState: auth,
    alternativesState: alternatives,
    availabilityState: availability,
    exceptionsState: exceptions,
    myBookingsState: myBookings,
    i18nState: i18n,
    feedbackState: feedback,
    configState: config,
    regionsState: regions,
    floorsState: floors,
    resourcesSubTypesState: resourcesSubTypes,
    buildingsState: buildingsData,
    locationsState: locations,
    searchState: search,
    timelineState: timeline,
    timelineBookingsState: timelineBookings,
    bookState: book,
    favoriteState: favorite,
    equipmentFilterState: equipmentFilter,
    roomFilterState: roomFilter,
    recurrenceState: recurrence,
    recurrenceMasterState: recurrenceMaster,
    modalState: modal,
    notificationsState: notifications,
    myChangeRequestsState: myChangeRequests,
    editResourceState: editResource,
    changeMasterDataState: changeMasterData,
    changePermissionsState: changePermissions,
    deleteResourceState: deleteResource,
    createResourceState: createResource,
    changeOwnerState: changeOwner,
    changeRequestDetailState: changeRequestDetail,
    bookingCollectionState: bookingCollection,
    roomPickerState: roomPicker,
    maintenanceMessageState: maintenanceMessage,
    photoAdminState: photoAdmin
  };

  const initialState = {
    i18nState: {
      i18n: { translations, ...i18nInitialState }
    }
  };

  const appReducer = combineReducers(reducers);

  const rootReducer = (state, action) => {
    if (action.type === types.USER_LOGOUT) {
      // Reset the entire state to its initial state when logging out
      state = undefined;
    }

    return appReducer(state, action);
  };

  store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunk)));
  initSubscriber(store);
  return store;
};

export const getState = () => {
  if (store) {
    return store.getState();
  }

  return {};
};

export default configureStore;
