import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/fp/sortBy';
import { Select, Option, withTranslation } from '../common';

function RoomFilterFloor({ floors, onChange, translate }) {
  const selectedFloors = floors ? floors.filter(p => p.enabled === true).map(p => p.key) : [];

  const renderOption = () => {
    const options = sortBy(p => p.name, floors)
      .map(floor => {
        const option = (
          <Option id={floor.key} key={floor.key} value={floor.key} selected={floor.selected}>{floor.name}</Option>);
        return option;
      });
    return options;
  };

  const onSelectionChanged = event => {
    if (floors) {
      const floor = floors.filter(p => p.key === event.selectedOption.key);

      if (floor && floor.length === 1) {
        onChange(floor[0]);
      }
    }
  };

  const noAvailableFloors = floors.length === 0;

  return (
    <div className="RoomFilter__Floor">
      <div className="RoomFilter__Section Floor__Filter">
        <div className="RoomFilter__Content">
          <Select
            disabled={noAvailableFloors}
            multiple
            value={selectedFloors}
            onChange={onSelectionChanged}
            placeholder={`${translate('roomFilter.floorTitleSelect')}`}>
            {renderOption()}
          </Select>
        </div>
      </div>
    </div>
  );
}

RoomFilterFloor.propTypes = {
  floors: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RoomFilterFloor);
