import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '../../../common';

import styles from './Config.module.css';

function ConfigLoader({ visible }) {
  if (!visible) {
    return null;
  }

  return (
    <div className={styles.Config__Loader}>
      <Loader visible />
    </div>
  );
}

ConfigLoader.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default ConfigLoader;
