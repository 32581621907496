// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchBar_body__qAqsV {\n  display: flex;\n  align-items: center;\n  min-height: 60px;\n  background-color: var(--color-horizon);\n  padding: 9px 20px;\n  border-bottom: 1px solid var(--color-gray-20);\n}\n\n.SearchBar_item__KgaSN {\n  display: flex;\n  align-items: center;\n  padding-left: 20px;\n}\n\n.SearchBar_item__KgaSN:first-child {\n  padding-left: 0;\n}\n\n.SearchBar_itemInputDate__DfzuR {\n  flex: 1 1;\n  min-width: 264px;\n  max-width: 362px;\n}\n\n.SearchBar_item__KgaSN > i {\n  font-size: 32px;\n  padding-right: 10px;\n}\n\n.SearchBar_inputLocationPicker__A6ufc {\n  min-width: 300px;\n  max-width: 100%;\n}\n\n.SearchBar_inputDate__aaUQ8 {\n  width: 100%;\n}\n\n.SearchBar_inputTime__cCGfr {\n  min-width: 180px;\n}\n", "",{"version":3,"sources":["webpack://./src/views/Search/SearchDesktop/SearchBar/SearchBar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,sCAAsC;EACtC,iBAAiB;EACjB,6CAA6C;AAC/C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,SAAW;EACX,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".body {\n  display: flex;\n  align-items: center;\n  min-height: 60px;\n  background-color: var(--color-horizon);\n  padding: 9px 20px;\n  border-bottom: 1px solid var(--color-gray-20);\n}\n\n.item {\n  display: flex;\n  align-items: center;\n  padding-left: 20px;\n}\n\n.item:first-child {\n  padding-left: 0;\n}\n\n.itemInputDate {\n  flex: 1 1 0;\n  min-width: 264px;\n  max-width: 362px;\n}\n\n.item > i {\n  font-size: 32px;\n  padding-right: 10px;\n}\n\n.inputLocationPicker {\n  min-width: 300px;\n  max-width: 100%;\n}\n\n.inputDate {\n  width: 100%;\n}\n\n.inputTime {\n  min-width: 180px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "SearchBar_body__qAqsV",
	"item": "SearchBar_item__KgaSN",
	"itemInputDate": "SearchBar_itemInputDate__DfzuR",
	"inputLocationPicker": "SearchBar_inputLocationPicker__A6ufc",
	"inputDate": "SearchBar_inputDate__aaUQ8",
	"inputTime": "SearchBar_inputTime__cCGfr"
};
export default ___CSS_LOADER_EXPORT___;
