const UPDATE_CHANGE_REQUEST = 'changeRequestDetail/UPDATE_CHANGE_REQUEST';
const UPDATE_IS_LOADING = 'changeRequestDetail/UPDATE_IS_LOADING';
const UPDATE_ACTION_ISPROCESSING = 'changeRequestDetail/UPDATE_ACTION_ISPROCESSING';
const UPDATE_ACTION_PROCESSED = 'changeRequestDetail/UPDATE_ACTION_PROCESSED';

export default {
  UPDATE_CHANGE_REQUEST,
  UPDATE_IS_LOADING,
  UPDATE_ACTION_ISPROCESSING,
  UPDATE_ACTION_PROCESSED
};
