/* eslint-disable arrow-body-style */

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const isAuthenticating = location => {
  return (location && location.pathname === '/callback') || location.pathname === '/login';
};

function LoginRoute({ component, signedIn, isLoading }) {
  return (
    <Route
      render={({ location, ...props }) => {
      if (isLoading) {
        return null;
      }

      const authenticating = isAuthenticating(location);
      if (authenticating) {
        return null;
      }

      if (signedIn) {
        return React.createElement(component, props);
      }

      return <Redirect to="/login" push={false} />;
    }}
  />
  );
}

LoginRoute.propTypes = {
  component: PropTypes.func.isRequired,
  signedIn: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default LoginRoute;
