import store from 'store2';
import includes from 'lodash/fp/includes';

const defaultLanguage = 'de';
export const supportedLanguages = ['de', 'fr', 'it', 'en'];

const isSupportedLanguage = language => language && includes(language, supportedLanguages);

const getBrowserLanguage = () => {
  const lang = window.navigator.userLanguage || window.navigator.language;
  if (lang) {
    const pos = lang.indexOf('-');
    const twoLetterLanguageCode = pos > -1 ? lang.substring(0, pos) : lang;
    return twoLetterLanguageCode.toLowerCase();
  }

  return null;
};

const getUserLanguage = () => store('language');

const detectLanguage = () => {
  let language = getUserLanguage();
  if (isSupportedLanguage(language)) {
    return language;
  }

  language = getBrowserLanguage();
  if (isSupportedLanguage(language)) {
    return language;
  }

  return defaultLanguage;
};

export default detectLanguage;
