import React from 'react';
import PropTypes from 'prop-types';

import { InlineLoader, withTranslation } from '../../common';

function SearchResultsInlineLoader({ visible, translate }) {
  if (!visible) {
    return null;
  }

  const text = translate('common.loadmore');
  return (
    <InlineLoader
      wrapperClassName="SearchResultsInlineLoader"
      className="SearchResultsInlineLoader__Loader"
      text={text}
    />
  );
}

SearchResultsInlineLoader.propTypes = {
  visible: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

export default withTranslation(SearchResultsInlineLoader);
