import types from './types';

const initialState = {
  error: {
    title: '',
    text: '',
    active: false
  },
  success: {
    title: '',
    text: '',
    active: false
  }
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_ERROR: {
      const error = {
        ...action.error,
        active: true
      };
      return { ...state, error };
    }
    case types.HIDE_ERROR: {
      const error = {
        ...state.error,
        active: false
      };
      return { ...state, error };
    }
    case types.SHOW_SUCCESS: {
      const success = {
        ...action.success,
        active: true
      };
      return { ...state, success };
    }
    case types.HIDE_SUCCESS: {
      const success = {
        ...state.success,
        active: false
      };
      return { ...state, success };
    }
    default:
      return state;
  }
};

export default notificationsReducer;
