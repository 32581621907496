import { combineReducers } from 'redux';
import store from 'store2';

import types from './types';

const loadInitialState = () => {
  const resourcesSubTypes = store('resourcesSubTypes');
  const isLoaded = store.has('resourcesSubTypes');
  const selectedResourcesSubType = null;
  return {
    isLoaded,
    resourcesSubTypes,
    selectedResourcesSubType
  };
};

const initialState = loadInitialState();

const resourcesSubTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_RESOURCESSUBTYPE: {
      return {
        ...state,
        resourcesSubTypes: action.resourcesSubTypes
      };
    }

    case types.UPDATE_LOADED: {
      return {
        ...state,
        isLoaded: true,
        age: action.age
      };
    }

    case types.SET_SELECTED_RESOURCESSUBTYPE: {
      return {
        ...state,
        selectedResourcesSubType: action.selectedResourcesSubType
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  resourcesSubTypes: resourcesSubTypesReducer
});
