import moment from 'moment';

export const getMinDate = (type, date) => {
  if (type === 'day') {
    return moment(date).startOf('day');
  }

  return moment(date).startOf('isoweek');
};

export const getMaxDate = (type, date) => {
  if (type === 'day') {
    return moment(date).endOf('day');
  }

  return moment(date).endOf('isoweek');
};

export const getStartDate = (type, date) => {
  if (type === 'week') {
    return getMinDate(type, date);
  }

  return moment(date).startOf('day').hour(7).minute(0);
};

export const getEndDate = (type, date) => {
  if (type === 'day') {
    return moment(date).startOf('day').hour(18).minute(0);
  }

  const friday = moment(date).startOf('isoweek').add(4, 'days');
  return friday.hour(23).minute(59);
};

export const getZoomMin = type => {
  const duration = type === 'day' ? moment.duration(2, 'hours') : moment.duration(4, 'hours');
  return duration.asMilliseconds();
};

export const getZoomMax = type => {
  const duration = type === 'day' ? moment.duration(1, 'days') : moment.duration(5, 'days');
  return duration.asMilliseconds();
};

/*
export const hideNightlyHours = () => {
  const start = moment().endOf('day').hour(20).minutes(0);
  const tomorrow = moment().endOf('day').add(1, 'days');
  const end = tomorrow.hour(6).minutes(0);
  return { start, end, repeat: 'daily' };
};
*/

export const formatLabels = (date, scale) => {
  if (scale === 'weekday') {
    return moment(date.toDate()).format('dddd D.M.YYYY');
  }

  return moment(date).format('HH:mm');
};
