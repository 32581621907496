import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import { enUS, fr, de, it } from 'date-fns/locale';
import config from '../../../config';
import { Icon, Input, DayPicker, WeekPicker, withTranslation } from '..';
import { isDateValid, toDate } from '../../../util/time';
import './_style.css';
import { detectLanguage } from '../../../i18n';

class DateInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maxHeight: '100%'
    };
    this.domNode = null;
  }

  render() {
    const {
      placeholder,
      value,
      startDate,
      error,
      onChange,
      onBlur,
      translations,
      icon,
      today,
      onTodayClick,
      className,
      showInput,
      showWeekPicker,
      enablePastDates,
      enableUnlimitedFuture,
      translate
    } = this.props;
    const { maxHeight } = this.state;

    let actualError = error;
    const selectedDay = isDateValid(value) ? toDate(value) : null;

    const { daysShort, months } = translations.common;
    const disabledDays = {};

    if (!enablePastDates) {
      disabledDays.before = isDateValid(startDate) ? toDate(startDate) : new Date();
    }

    if (!enableUnlimitedFuture) {
      const maxDate = moment()
        .add(config.maxDaysInFuture, 'day')
        .startOf('isoweek')
        .toDate();

      disabledDays.after = maxDate;

      if (selectedDay) {
        if (moment(selectedDay).isAfter(maxDate)) {
          actualError = translate('common.invalidInFuture');
        }
      }
    }

    const ref = elem => {
      this.domNode = elem;
    };

    const dateInputClasses = cx('DateInput__Input', {
      'DateInput__Input--scroll': maxHeight !== '100%'
    });

    const todayButton = today ? translate('common.today') : undefined;

    const language = () => {
      switch (detectLanguage()) {
        case 'en': return enUS;
        case 'fr': return fr;
        case 'de': return de;
        case 'it': return it;
        default: return enUS;
      }
    };

    return (
      <div className={cx('DateInput DateInput--date', className)} ref={ref} style={{ maxHeight }}>
        {icon && (
          <div className="DateInput__Icon">
            <Icon icon="009-calendar" />
          </div>
        )}
        <div className={dateInputClasses}>
          {showInput && (
            <Input
              type="text"
              placeholder={placeholder}
              value={value}
              onChange={e => onChange(e.target.value)}
              onBlur={onBlur}
              error={actualError}
            />
          )}

          {!showWeekPicker && (
            <DayPicker
              month={selectedDay}
              months={months}
              weekdaysShort={daysShort}
              disabledDays={disabledDays}
              todayButton={todayButton}
              onTodayButtonClick={onTodayClick}
              firstDayOfWeek={1}
              selectedDays={selectedDay}
              onDayClick={onChange}
              locale={language()}
            />
          )}

          {showWeekPicker && (
            <WeekPicker
              month={selectedDay}
              months={months}
              weekdaysShort={daysShort}
              disabledDays={disabledDays}
              todayButton={todayButton}
              onTodayButtonClick={onTodayClick}
              firstDayOfWeek={1}
              selectedDays={selectedDay}
              onDayClick={onChange}
              locale={language()}
            />
          )}
        </div>
      </div>
    );
  }
}

DateInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  startDate: PropTypes.string,
  error: PropTypes.string,
  icon: PropTypes.bool,
  className: PropTypes.string,
  showInput: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  translations: PropTypes.shape().isRequired,
  today: PropTypes.bool,
  onTodayClick: PropTypes.func,
  showWeekPicker: PropTypes.bool,
  enablePastDates: PropTypes.bool,
  enableUnlimitedFuture: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

DateInput.defaultProps = {
  startDate: '',
  icon: false,
  showInput: true,
  showWeekPicker: false,
  enablePastDates: false,
  enableUnlimitedFuture: false
};

export default withTranslation(DateInput);
