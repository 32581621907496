const UPDATE_AVAILABILITY = 'availability/UPDATE_AVAILABILITY';
const ADD_AVAILABILITY = 'availability/ADD_AVAILABILITY';
const UPDATE_LOADING = 'availability/UPDATE_LOADING';
const UPDATE_SKIPCOUNT = 'availability/UPDATE_SKIPCOUNT';
const UPDATE_TOTALCOUNT = 'availability/UPDATE_TOTALCOUNT';
const UPDATE_HASMORE = 'availability/UPDATE_HASMORE';
const UPDATE_ISRECURRENCE = 'availability/UPDATE_ISRECURRENCE';
const REMOVE_RESOURCE_BY_ID = 'availability/REMOVE_ROOM_BY_ID';
const UPDATE_RESOURCE_AVAILABILITIES = 'availability/UPDATE_RESOURCE_AVAILABILITIES';

export default {
  UPDATE_AVAILABILITY,
  ADD_AVAILABILITY,
  UPDATE_LOADING,
  UPDATE_SKIPCOUNT,
  UPDATE_TOTALCOUNT,
  UPDATE_HASMORE,
  UPDATE_ISRECURRENCE,
  REMOVE_RESOURCE_BY_ID,
  UPDATE_RESOURCE_AVAILABILITIES
};
