import React from 'react';
import PropTypes from 'prop-types';

import { FormField, withTranslation } from '../../../common';

function SidebarFormField({ label: path, element, translate }) {
  const title = translate(path);
  const label = (
    <strong>
      {title}
      :
    </strong>
  );
  return <FormField className="BookDetailSidebar__FormField" label={label} element={element} />;
}

SidebarFormField.propTypes = {
  label: PropTypes.string.isRequired,
  element: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  translate: PropTypes.func.isRequired
};

export default withTranslation(SidebarFormField);
