import moment from 'moment';
import mapValues from 'lodash/fp/mapValues';
import download from 'downloadjs';

import { bookingsService } from '../../services';
import { notificationsSelectors, notificationsOperations } from '../notifications';

import normalize from './schema';
import selectors from './selectors';
import createPayload from './payload';

import {
  addRoomBookings,
  addRooms,
  addBookings,
  updateRoomBookings,
  updateRooms,
  updateBookings,
  updateBooking,
  removeBooking,
  removeRecurring,
  updateSkipCount,
  updateTotalCount,
  updateIsLoading,
  updateIsLoadingMore,
  cacheBookings,
  resetBookingsFromCache,
  exportLoading
} from './actions';
import { favoriteOperations } from '../favorite';

const updateLoading = (isLoading, loadMore) => dispatch => {
  if (!loadMore) {
    return dispatch(updateIsLoading(isLoading));
  }
  return dispatch(updateIsLoadingMore(isLoading));
};

const loadPage = (dispatch, state, payload) => {
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return bookingsService
    .post(payload)
    .then(response => {
      dispatch(updateSkipCount(response.nextSkipCount));
      dispatch(updateTotalCount(response.totalCount));

      const data = normalize(response.results);
      const { resources, bookings } = data.entities;

      const favorites = mapValues('isFavorite', resources);
      dispatch(favoriteOperations.resetFavorites(favorites));

      if (payload.skipCount === 0) {
        dispatch(updateRoomBookings(data.result));
        dispatch(updateRooms(resources || []));
        dispatch(updateBookings(bookings || []));
      } else {
        dispatch(addRoomBookings(data.result));
        dispatch(addRooms(resources || []));
        dispatch(addBookings(bookings || []));
      }
      return Promise.resolve();
    })
    .catch(serverError => {
      const error = getNotification('search.error.load', serverError);
      dispatch(notificationsOperations.showError(error));
      return Promise.reject();
    });
};

const loadRoomBookings = (loadMore, skipCount = 0) => (dispatch, getState) => {
  const state = getState();

  const payload = createPayload(state);
  payload.skipCount = skipCount;

  // ToDo Hier sollten wir einen globalen (für SofortBuchen und TimeLine) Selector haben, um
  // prüfen zu können, ob etwas geändert hat.
  const hasChanges = true;

  const totalCount = selectors.getTotalCount(state);

  if (!hasChanges && totalCount !== 0 && !loadMore) {
    return Promise.reject(new Error('no changes detected.'));
  }

  dispatch(updateLoading(true, loadMore));

  return loadPage(dispatch, state, payload).finally(() => {
    dispatch(updateLoading(false, loadMore));
  });
};

const loadMoreRoomBookings = () => (dispatch, getState) => {
  const state = getState();
  const nextSkipCount = selectors.getSkipCount(state);
  const load = loadRoomBookings(true, nextSkipCount);
  load(dispatch, getState);
};

const refreshRoomBookings = () => (dispatch, getState) => {
  const state = getState();
  const payload = createPayload(state);
  const rooms = selectors.getRoomBookings(state);
  payload.pageSize = rooms.length;
  payload.skipCount = 0;

  return loadPage(dispatch, state, payload);
};

const exportRoomBookings = () => async (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);

  const searchRequest = createPayload(state);
  dispatch(exportLoading(true));

  try {
    const blob = await bookingsService.exportData(searchRequest);
    const date = moment().format('DDMMYYYY-HHmm');
    download(
      blob,
      `reservationhubexport-${date}.xlsx`,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    dispatch(exportLoading(false));
  } catch (serverError) {
    dispatch(exportLoading(false));
    const error = getNotification('search.export.error', serverError);
    dispatch(notificationsOperations.showError(error));
  }
};

export default {
  loadRoomBookings,
  loadMoreRoomBookings,
  refreshRoomBookings,
  addBookings,
  updateBooking,
  removeBooking,
  removeRecurring,
  cacheBookings,
  resetBookingsFromCache,
  exportRoomBookings
};
