import React from 'react';
import PropTypes from 'prop-types';

import { SelectBox } from '../common';

function RoomSizeBox({ text, ...props }) {
  const childContent = () => (
    <div className="RoomSizeBox">
      {text}
    </div>
  );

  return <SelectBox {...props}>{childContent()}</SelectBox>;
}

RoomSizeBox.propTypes = {
  text: PropTypes.string.isRequired
};

export default RoomSizeBox;
