import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from '../../common';

import styles from './NewsForm.module.css';

import NewsFormInputGroup from './NewsFormInputGroup';
import NewsFormCalendar from './NewsFormCalendar';

class NewsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      germanTitle: props.value.germanTitle,
      germanText: props.value.germanText,
      frenchTitle: props.value.frenchTitle,
      frenchText: props.value.frenchText,
      italianTitle: props.value.italianTitle,
      italianText: props.value.italianText,
      englishTitle: props.value.englishTitle,
      englishText: props.value.englishText,
      startPublish: props.value.startPublish,
      stopPublish: props.value.stopPublish
    };
  }

  onChangeGerman = async event => {
    await this.setState({
      germanTitle: event.title,
      germanText: event.text
    });
    this.onChange();
  };

  onChangeFrench = async event => {
    await this.setState({
      frenchTitle: event.title,
      frenchText: event.text
    });
    this.onChange();
  };

  onChangeItalian = async event => {
    await this.setState({
      italianTitle: event.title,
      italianText: event.text
    });
    this.onChange();
  };

  onChangeEnglish = async event => {
    await this.setState({
      englishTitle: event.title,
      englishText: event.text
    });
    this.onChange();
  };

  onChangeStartPublish = async value => {
    await this.setState({
      startPublish: value
    });
    this.onChange();
  };

  onChangeStopPublish = async value => {
    await this.setState({
      stopPublish: value
    });
    this.onChange();
  };

  onChange = () => {
    const { onChange } = this.props;
    const {
      germanTitle,
      germanText,
      frenchTitle,
      frenchText,
      italianTitle,
      italianText,
      englishTitle,
      englishText,
      startPublish,
      stopPublish
    } = this.state;

    if (onChange) {
      onChange({
        germanTitle,
        germanText,
        frenchTitle,
        frenchText,
        italianTitle,
        italianText,
        englishTitle,
        englishText,
        startPublish,
        stopPublish
      });
    }
  };

  render() {
    const { translate } = this.props;
    const {
      germanTitle,
      germanText,
      frenchTitle,
      frenchText,
      italianTitle,
      italianText,
      englishTitle,
      englishText,
      startPublish,
      stopPublish
    } = this.state;

    return (
      <div>
        <NewsFormInputGroup
          value={{
            title: germanTitle,
            text: germanText
          }}
          onChange={this.onChangeGerman}
          heading={translate('admin.language.german')}
          isRequired
        />
        <NewsFormInputGroup
          value={{
            title: frenchTitle,
            text: frenchText
          }}
          onChange={this.onChangeFrench}
          heading={translate('admin.language.french')}
        />
        <NewsFormInputGroup
          value={{
            title: italianTitle,
            text: italianText
          }}
          onChange={this.onChangeItalian}
          heading={translate('admin.language.italian')}
        />
        <NewsFormInputGroup
          value={{
            title: englishTitle,
            text: englishText
          }}
          onChange={this.onChangeEnglish}
          heading={translate('admin.language.english')}
        />
        <hr className={styles.divider} />
        <NewsFormCalendar
          heading={translate('admin.news.form.startPublish')}
          value={startPublish}
          onChange={this.onChangeStartPublish}
          isRequired
        />
        <NewsFormCalendar
          heading={translate('admin.news.form.stopPublish')}
          value={stopPublish}
          onChange={this.onChangeStopPublish}
        />
      </div>
    );
  }
}

NewsForm.propTypes = {
  translate: PropTypes.func.isRequired,
  value: PropTypes.shape({
    englishText: PropTypes.string,
    englishTitle: PropTypes.string,
    frenchText: PropTypes.string,
    frenchTitle: PropTypes.string,
    germanText: PropTypes.string,
    germanTitle: PropTypes.string,
    italianText: PropTypes.string,
    italianTitle: PropTypes.string,
    startPublish: PropTypes.string,
    stopPublish: PropTypes.string
  }),
  onChange: PropTypes.func
};

NewsForm.defaultProps = {
  value: {
    germanTitle: '',
    germanText: '',
    frenchTitle: '',
    frenchText: '',
    italianTitle: '',
    italianText: '',
    englishTitle: '',
    englishText: '',
    startPublish: '',
    stopPublish: ''
  }
};

export default withTranslation(NewsForm);
