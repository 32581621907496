import { combineReducers } from 'redux';
import set from 'lodash/fp/set';
import filter from 'lodash/fp/filter';
import mapValues from 'lodash/fp/mapValues';

import types from './types';
import locationsTypes from '../locations/types';
import roomFilterTypes from '../roomFilter/types';
import searchTypes from '../search/types';

const defaultSectionState = {
  availability: [],
  isLoading: false,
  skipCount: 0,
  hasMore: false
};

const initialState = {
  sections: {
    location: { ...defaultSectionState },
    time: { ...defaultSectionState },
    roomequipment: { ...defaultSectionState },
    roomtype: { ...defaultSectionState },
    capacity: { ...defaultSectionState }
  }
};

const updateState = state => (section, name, value) => {
  const path = `${section}.${name}`;
  return set(path, value, state.sections);
};

const alternativesReducer = (state = initialState, action) => {
  const updateSections = updateState(state);

  switch (action.type) {
    case types.ADD_AVAILABILITY: {
      const { section } = action;
      const availabilities = [...state.sections[section].availability, ...action.availability];
      const sections = updateSections(section, 'availability', availabilities);
      return {
        ...state,
        sections: { ...sections }
      };
    }

    case types.UPDATE_AVAILABILITY: {
      const sections = updateSections(action.section, 'availability', action.availability);
      return {
        ...state,
        sections: { ...sections }
      };
    }

    case types.UPDATE_LOADING: {
      const sections = updateSections(action.section, 'isLoading', action.isLoading);
      return {
        ...state,
        sections: { ...sections }
      };
    }

    case types.UPDATE_SKIPCOUNT: {
      const sections = updateSections(action.section, 'skipCount', action.skipCount);
      return {
        ...state,
        sections: { ...sections }
      };
    }

    case types.HAS_MORE_RESULTS: {
      const sections = updateSections(action.section, 'hasMore', action.hasMore);
      return {
        ...state,
        sections: { ...sections }
      };
    }

    case types.CLEAR_ALL_AVAILABILITIES: {
      return { ...initialState };
    }

    case types.REMOVE_BY_ID: {
      const remove = filter(item => item.resource.id !== action.resourceId);
      const removeFromSection = section => ({
        ...section,
        availability: remove(section.availability)
      });
      const removeFromAllSections = sections => mapValues(section => removeFromSection(section), sections);

      const { sections } = state;

      return {
        ...state,
        sections: removeFromAllSections(sections)
      };
    }

    case locationsTypes.CLEAR_LOCATIONS:
    case locationsTypes.UPDATE_LOCATIONS:
    case roomFilterTypes.TOGGLE_FILTER:
    case searchTypes.UPDATE_DATE:
    case searchTypes.UPDATE_TIME: {
      return initialState;
    }

    default:
      return state;
  }
};

export default combineReducers({
  alternatives: alternativesReducer
});
