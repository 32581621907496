const encodeSearchTerm = searchTerm => {
  const lastCharacter = searchTerm.slice(-1);

  if (lastCharacter === '.') {
    return encodeSearchTerm(searchTerm.slice(0, -1));
  }

  const lastIndexOfSpace = searchTerm.lastIndexOf('%20');
  const spaceAtLast = lastIndexOfSpace > 0 && lastIndexOfSpace + 3 === searchTerm.length;

  if (spaceAtLast) {
    return encodeSearchTerm(searchTerm.slice(0, searchTerm.length - 3));
  }

  const indexOfSpace = searchTerm.indexOf('%20');

  if (indexOfSpace === 0) {
    return encodeSearchTerm(searchTerm.slice(3, searchTerm.length));
  }

  return searchTerm;
};

export default encodeSearchTerm;
