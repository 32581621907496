import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation, EmptyState } from '../../common';

import './_style.css';

function CollectionEmptyState({ visible, translate }) {
  if (!visible) {
    return null;
  }

  const text = translate('search.recurrence.exceptions.emptyState');

  return (
    <EmptyState
      icon="232-robot-neutral"
      className="CollectionEmptyState"
      content={text}
      visible
      centered
    />
  );
}

CollectionEmptyState.propTypes = {
  visible: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(CollectionEmptyState);
