import config from '../config';
import fetch from './fetch';

const getUrl = path => `${ config.remoteUrl }/${ path }`;

async function getConfig() {
  const url = getUrl('config');
  return fetch(url, 'GET');
}

async function updateConfig(payload) {
  const url = getUrl('config/update');
  return fetch(url, 'PUT', payload);
}

export default {
  getConfig,
  updateConfig
};
