/* eslint-disable react/no-did-update-set-state, no-plusplus */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import SelectDropdownItem from './SelectDropdownItem';
import { getStyles, css, text, textWidth } from './dom';

const KEY_ESCAPE = 27;
const KEY_ENTER = 13;
const KEY_ARROW_LEFT = 37;
const KEY_ARROW_UP = 38;
const KEY_ARROW_RIGHT = 39;
const KEY_ARROW_DOWN = 40;

const isEscape = keyCode => keyCode === KEY_ESCAPE;
const isEnter = keyCode => keyCode === KEY_ENTER;
const isUpwards = keyCode => keyCode === KEY_ARROW_UP || keyCode === KEY_ARROW_LEFT;
const isDownwards = keyCode => keyCode === KEY_ARROW_DOWN || keyCode === KEY_ARROW_RIGHT;

const getFont = css('font');

class SelectDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { minWidth: 0 };
    this.placeholder = null;
    this.dropdown = null;

    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props;
    if (prevProps.options === options) {
      return;
    }

    const styles = getStyles(this.placeholder);
    const paddingLeft = parseFloat(styles.paddingLeft);
    const paddingRight = parseFloat(styles.paddingRight);
    const font = getFont(this.placeholder);
    const placeholderWidth = textWidth(this.placeholder, font);
    let maxWidth = paddingLeft + paddingRight + placeholderWidth;

    const items = [...this.dropdown.querySelectorAll('.dropdown-item')];
    maxWidth = items.reduce((prev, current) => {
      const width = textWidth(text(current), font) + paddingLeft + paddingRight;
      return width > maxWidth ? width : prev;
    }, maxWidth);

    const { minWidth } = this.state;
    if (minWidth !== maxWidth) {
      this.setState({
        minWidth: maxWidth
      });
    }
  }

  onKeyDown(event) {
    const { keyCode } = event;
    const { onKeyDown, onBlur, onSelect, options } = this.props;

    if (isEscape(keyCode)) {
      event.preventDefault();
      onBlur();
      return;
    }

    if (isEnter(keyCode)) {
      event.preventDefault();
      const option = options.find(o => o.focused);
      if (option) {
        onSelect(event, option);
        return;
      }
    }

    if (isUpwards(keyCode) || isDownwards(keyCode)) {
      event.preventDefault();

      const enabledOptions = options.filter(o => !o.disabled);
      if (enabledOptions.length < 1) {
        return;
      }

      const direction = isDownwards(keyCode) ? 1 : -1;
      let newIndex = 0;

      for (let index = 0; index < options.length; index++) {
        if (options[index].focused) {
          newIndex = index;
          for (let count = 0; count < options.length; count++) {
            newIndex += direction;
            newIndex %= options.length;

            if (newIndex < 0) {
              newIndex = options.length - 1;
            }

            if (!options[newIndex].disabled) {
              break;
            }
          }
        }
      }

      onKeyDown(options[newIndex]);
    }
  }

  render() {
    const { minWidth } = this.state;
    const {
      value,
      options,
      disabled,
      error,
      tabIndex,
      onClick,
      onSelect,
      onFocus,
      onBlur
    } = this.props;

    const style = {
      minWidth: `${minWidth}px`,
      cursor: disabled ? 'not-allowed' : null
    };

    const placeholderRef = ref => {
      this.placeholder = ref;
    };

    const dropdownRef = ref => {
      this.dropdown = ref;
    };

    const placeholderClasses = cx('select__placeholder', {
      'select__placeholder--invalid': error
    });

    return (
      <div tabIndex={tabIndex} onFocus={onFocus} onBlur={onBlur}>
        <button
          type="button"
          className="select__button"
          onClick={onClick}
          style={style}
          onKeyDown={this.onKeyDown}>
          <div className="select__thumb">
            <div className="thumb-icon" />
          </div>
          <span className={placeholderClasses} ref={placeholderRef}>
            {value}
          </span>
        </button>

        <div className="select__dropdown" ref={dropdownRef}>
          {options
            && options.map(option => (
              <SelectDropdownItem
                id={option.key}
                key={option.key}
                text={option.text}
                disabled={option.disabled}
                selected={option.selected}
                focused={option.focused}
                onSelect={event => onSelect(event, option)}
              />
            ))}
        </div>
      </div>
    );
  }
}

SelectDropdown.propTypes = {
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.node,
      disabled: PropTypes.bool,
      selected: PropTypes.bool,
      focused: PropTypes.any
    })
  ),
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired
};

SelectDropdown.defaultProps = {
  options: [],
  tabIndex: '0'
};

export default SelectDropdown;
