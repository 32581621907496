import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';

import { Input, Popup, DateInput } from '../../../../common';
import { searchSelectors, searchOperations } from '../../../../../state/search';
import { emitter } from '../../../../../util';

import styles from './SearchBarDatePicker.module.css';

class SearchBarDatePicker extends Component {
  showPopup = () => {
    this.textinput.focus();
    this.popup.show();
  };

  hidePopup = () => {
    this.textinput.blur();
    this.popup.hide();
  };

  dateChanged = date => {
    const { updateDate } = this.props;
    updateDate(date);
    this.popup.hide();
    emitter.emit('onSearchFilterChanged', null);
  };

  selectCurrentDate = () => {
    const today = moment().format('DD.MM.YYYY');
    this.dateChanged(today);
  };

  onChange = date => {
    this.dateChanged(date);
  };

  render() {
    const formatDate = dateToFormat => moment(dateToFormat, 'DD.MM.YYYY').format('dddd, DD.MM.YYYY');
    const { date, showWeekPicker } = this.props;
    const input = (
      <div onClick={this.showPopup} role="presentation">
        <Input
          value={formatDate(date)}
          onChange={() => {
            /* do nothing */
          }}
          ref={inp => {
            this.textinput = inp;
          }}
        />
      </div>
    );

    const ref = element => {
      this.popup = element;
    };

    return (
      <div>
        <Popup
          component={input}
          ref={ref}
          className={styles.searchBarDatePickerPopUp}
          showSaveButton={false}
        >
          <DateInput
            showInput={false}
            onChange={this.onChange}
            className={styles.searchBarDatePicker}
            enablePastDates
            value={date}
            showWeekPicker={showWeekPicker}
            today
            onTodayClick={this.selectCurrentDate}
          />
        </Popup>
      </div>
    );
  }
}

SearchBarDatePicker.propTypes = {
  date: PropTypes.string.isRequired,
  updateDate: PropTypes.func.isRequired,
  showWeekPicker: PropTypes.bool
};

const mapStateToProps = state => ({
  date: searchSelectors.getDate(state)
});

const mapDispatchToProps = dispatch => ({
  updateDate: date => dispatch(searchOperations.updateDate(date))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBarDatePicker);
