import { createSelector } from 'reselect';

const getBuildings = state => state.buildingsState.buildingsData.buildings;
const isLoaded = state => state.buildingsState.buildingsData.isLoaded;
const getAge = state => state.buildingsState.buildingsData.age;
const getSelectedBuilding = state => state.buildingsState.buildingsData.selectedBuilding;

const hasBuildings = createSelector(
  getBuildings,
  buildings => {
    const buildingIDs = [];
    if (buildings !== null) {
      Object.entries(buildings).forEach(building => buildingIDs.push(building));
    }
    return buildings != null && buildingIDs.length > 0;
  }
);

export default {
  hasBuildings,
  getBuildings,
  isLoaded,
  getAge,
  getSelectedBuilding
};
