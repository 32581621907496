import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, EquipmentCard } from '../../../../common';
import Equipment from '../Equipment';
import FormTimeAndSubmit from '../FormTimeAndSubmit';
import FormSubmit from '../FormSubmit';
import ProfileInformation from '../ProfileInformation';

function EquipmentCardMobile(props) {
  const { resource, selected, showTimeInput, onSelect, isProfile3, isProfile2 } = props;
  const { alternativeType, showRelativeTime } = props;

  const renderEquipmentInformation = () => {
    if (!selected || isProfile3) {
      return (
        <Equipment
          resource={resource}
          showRelativeTime={showRelativeTime}
          alternativeType={alternativeType}
          key="Equipment"
        />
      );
    }
    return null;
  };

  const renderSelectedNoTimeInput = () => {
    if (selected && !showTimeInput && !isProfile3) {
      return <FormSubmit small openModal={onSelect} resource={resource} key="FormSubmit" />;
    }
    return null;
  };

  const renderSelectedTimeInput = () => {
    if (selected && showTimeInput && !isProfile3) {
      return <FormTimeAndSubmit openModal={onSelect} resource={resource} key="FormTimeAndSubmit" />;
    }
    return null;
  };

  const renderProfileInformation = () => {
    if ((isProfile2 || isProfile3) && !selected) {
      return <ProfileInformation resource={resource} />;
    }
    return null;
  };

  const onCardClick = () => {
    if (isProfile3) {
      onSelect();
    }
  };

  return (
    <div>
      <EquipmentCard resourceSubType={resource.resourceSubType} onClick={onCardClick}>
        {renderEquipmentInformation()}
        {renderSelectedNoTimeInput()}
        {renderSelectedTimeInput()}
        {renderProfileInformation()}
      </EquipmentCard>
    </div>
  );
}

EquipmentCardMobile.propTypes = {
  resource: CustomPropTypes.resource,
  selected: PropTypes.bool.isRequired,
  showTimeInput: PropTypes.bool.isRequired,
  showRelativeTime: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  isProfile3: PropTypes.bool.isRequired,
  isProfile2: PropTypes.bool.isRequired,
  alternativeType: PropTypes.string
};

EquipmentCardMobile.defaultProps = {
  onSelect: () => {}
};

export default EquipmentCardMobile;
