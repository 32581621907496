import React from 'react';
import PropTypes from 'prop-types';
import Header from '../Header';
import { withTranslation } from '../common';
import MyBookings from './MyBookings';

import './_style.css';

function MyBookingsPageMobile(props) {
  const { translate, ...otherProps } = props;
  const title = translate('myBookings.title');

  return (
    <div>
      <Header title={title} />
      <MyBookings {...otherProps} />
    </div>
  );
}

MyBookingsPageMobile.propTypes = { translate: PropTypes.func.isRequired };

export default withTranslation(MyBookingsPageMobile);
