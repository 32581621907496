import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

function Tab({ className, activeClassName, children, value, isDisabled, disabledClassName }, { tabBar }) {
  const classes = cx(className, tabBar.value === value ? activeClassName : '', isDisabled ? disabledClassName : '');

  const onClick = isDisabled
    ? () => {}
    : () => tabBar.onChange(value);

  return (
    <button className={classes} type="button" onClick={onClick}>
      {children}
    </button>
  );
}

Tab.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  disabledClassName: PropTypes.string,
  children: PropTypes.node,
  isDisabled: PropTypes.bool
};

Tab.contextTypes = {
  tabBar: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
  })
};

export default Tab;
