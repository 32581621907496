import pick from 'lodash/fp/pick';

import actions from '../actions';
import selectors from '../selectors';

import { modalOperations } from '../../modal';
import { exceptionsOperations } from '../../exceptions';
import { notificationsOperations, notificationsSelectors } from '../../notifications';
import { bookSelectors } from '../../book';
import { masterRecurrenceService, bookService } from '../../../services';
import modalKeys from '../../../views/BookModal/modalKeys';

import attendeesOperations from './attendeesOperations';
import bookForOperations from './bookForOperations';

const {
  updateSelectedMaster,
  updateSubject,
  updateMessage,
  updateReminder,
  updateIsLoading,
  cacheSelectedMaster,
  restoreSelectedMaster
} = actions;

const loadRecurrenceMaster = calendarId => (dispatch, getState) => {
  const state = getState();

  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  dispatch(updateIsLoading(true));
  const mapNameToAttendees = attendees => attendees.map(attendee => ({ ...attendee, name: attendee.displayName }));

  return masterRecurrenceService(calendarId)
    .then(response => {
      const attendees = mapNameToAttendees(response.attendees);
      dispatch(attendeesOperations.updateSelectedAttendees(attendees));
      dispatch(bookForOperations.updateBookForPerson(response.bookedFor));
      dispatch(updateSelectedMaster({ ...response, attendees }));
      dispatch(exceptionsOperations.initializeExceptions(response.exceptions));
    })
    .catch(serverError => {
      const error = getNotification('search.detail.recurring.error', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => {
      dispatch(updateIsLoading(false));
    });
};

const loadAndOpenRecurrenceMaster = calendarId => dispatch => {
  dispatch(modalOperations.pushModal(modalKeys.RECURRENCE_DETAIL_MODAL));
  dispatch(loadRecurrenceMaster(calendarId)).catch(() => {
    dispatch(modalOperations.popModal());
  });
};

const updateRecurrenceMaster = () => (dispatch, getState) => {
  const state = getState();

  const master = selectors.getSelectedMaster(state);

  const bookedForPerson = selectors.getBookForPerson(state);
  const attendees = selectors.getSelectedAttendees(state);
  const seatingId = bookSelectors.getSeatingId(state);

  const payload = {
    ...pick(['globalId', 'calendarId', 'subject', 'start', 'end', 'message', 'reminder'], master),
    resourceId: master.resource.id,
    attendeeMails: attendees.map(attendee => attendee.email),
    bookedFor: (bookedForPerson && bookedForPerson.email) || null,
    isRecurring: true,
    isOccurrence: false,
    seatingId
  };

  dispatch(updateIsLoading(true));

  return bookService
    .put(payload)
    .then(newMaster => {
      dispatch(updateIsLoading(false));
      return Promise.resolve(newMaster);
    })
    .catch(serverError => {
      dispatch(updateIsLoading(false));
      return Promise.reject(serverError);
    });
};

export default {
  loadRecurrenceMaster,
  loadAndOpenRecurrenceMaster,
  updateSubject,
  updateMessage,
  updateReminder,
  cacheSelectedMaster,
  restoreSelectedMaster,
  updateRecurrenceMaster
};
