import React from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';

const icons = { down: '017-chevron-flat-down', up: '020-chevron-flat-up' };
const style = {
  fontSize: '20px'
};

function DropdownIndicator(props) {
  const { selectProps } = props;
  const { menuIsOpen } = selectProps;

  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Icon icon={menuIsOpen ? icons.up : icons.down} style={style} />
      </components.DropdownIndicator>
    )
  );
}

DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.any
  })
};

export default DropdownIndicator;
