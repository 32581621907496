import types from '../types';

const updateResource = resource => ({
  type: types.UPDATE_RESOURCE,
  resource
});

const initializeExceptions = exceptions => ({
  type: types.INITIALIZE_EXCEPTIONS,
  exceptions
});

const updateException = exception => ({
  type: types.UPDATE_EXCEPTION,
  exception
});

const patchException = exception => ({
  type: types.PATCH_EXCEPTION,
  exception
});

const resetException = exception => ({
  type: types.RESET_EXCEPTION,
  exception
});

const removeException = exception => ({
  type: types.REMOVE_EXCEPTION,
  exception
});

const updateIsInProgress = isInProgress => ({
  type: types.UPDATE_IS_IN_PROGRESS,
  isInProgress
});

export default {
  updateResource,
  initializeExceptions,
  updateException,
  patchException,
  resetException,
  removeException,
  updateIsInProgress
};
