import React from 'react';
import PropTypes from 'prop-types';

import { Icon, withTranslation } from '../../common';

import './_style.css';

function RecurrenceUnhandledException({ resourceType, translate }) {
  const type = resourceType.toLowerCase();

  const text = translate(`search.detail.recurring.unhandled.${type}`);
  const resolve = translate('search.detail.recurring.unhandled.resolve');

  return (
    <div className="BookDetailRecurrenceUnhandledException">
      <Icon icon="046-information-circle" className="RecurrenceUnhandledException__Icon" />
      {text}
      {' '}
      {resolve}
    </div>
  );
}

RecurrenceUnhandledException.propTypes = {
  resourceType: PropTypes.oneOf(['Room', 'Resource']).isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(RecurrenceUnhandledException);
