import { subscribe } from 'redux-subscriber';

const toggleScrolling = ({ globalState }) => {
  if (globalState.scrollingEnabled) {
    document.documentElement.classList.remove('no-scroll');
  } else {
    document.documentElement.classList.add('no-scroll');
  }
};

const watch = () => {
  subscribe('globalState.scrollingEnabled', toggleScrolling);
};

export default watch;
