import config from '../config';
import fetch from './fetch';

const getUrl = id => `${config.remoteUrl}/resources/${id}/favorite`;

async function post(id) {
  const url = getUrl(id);
  return fetch(url, 'POST');
}

async function remove(id) {
  const url = getUrl(id);
  return fetch(url, 'DELETE');
}

export default { post, remove };
