import memoize from 'lodash/fp/memoize';
import debounce from 'debounce-promise';

import config from '../config';
import fetch from './fetch';

const getUrl = path => `${config.remoteUrl}/${path}`;

async function search(query) {
  if (query == null) {
    return Promise.resolve([]);
  }

  const url = getUrl(`locations/search?query=${encodeURIComponent(query)}`);
  return fetch(url, 'GET');
}

export default {
  search: debounce(memoize(search), 150)
};
