import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  getResourceSubTypeNameTranslation,
  getResourceSubTypeOptions
} from '../../../util';
import {
  FormFieldSelect,
  FormFieldPerson,
  FormFieldTextArea,
  FormFieldReadOnly
} from '../FormElements';

class CreateEquipment extends Component {
  renderEquipmentType = () => {
    const {
      resourceSubType,
      resourcesSubTypes,
      changeRequest,
      isSubmitted,
      updateFormValue,
      readOnly
    } = this.props;

    const placeholder = ' - ';

    if (readOnly) {
      return (
        <FormFieldReadOnly
          values={resourceSubType}
          field="resourceSubType"
          text={getResourceSubTypeNameTranslation(changeRequest.resourceSubType)}
        />
      );
    }
    return (
      <FormFieldSelect
        field="resourceSubType"
        placeholder={placeholder}
        options={getResourceSubTypeOptions(resourcesSubTypes, 'Equipment')}
        values={resourceSubType}
        update={updateFormValue}
        isSubmitted={isSubmitted}
        detectChanges={false}
      />
    );
  };

  renderContact = () => {
    const {
      contact,
      peopleSuggestions,
      loadPeopleSuggestions,
      isPeopleLoading,
      updateFormValue,
      readOnly,
      isSubmitted
    } = this.props;

    if (readOnly) {
      return (
        <FormFieldReadOnly
          values={contact}
          field="contact"
          text={contact.new && contact.new.name}
        />
      );
    }

    return (
      <FormFieldPerson
        field="contact"
        values={contact}
        suggestions={peopleSuggestions}
        loadSuggestions={loadPeopleSuggestions}
        update={updateFormValue}
        isSubmitted={isSubmitted}
        isLoading={isPeopleLoading}
        detectChanges={false}
      />
    );
  };

  renderNotes = () => {
    const { notes, updateFormValue, readOnly } = this.props;
    if (readOnly) {
      return <FormFieldReadOnly values={notes} field="notes" text={notes.new} />;
    }
    return (
      <FormFieldTextArea
        field="notes"
        values={notes}
        update={updateFormValue}
        optional
        detectChanges={false}
      />
    );
  };

  render() {
 return (
   <>
     {this.renderEquipmentType()}
     {this.renderContact()}
     {this.renderNotes()}
   </>
 );
}
}

CreateEquipment.propTypes = {
  notes: PropTypes.shape().isRequired,
  contact: PropTypes.shape().isRequired,
  peopleSuggestions: PropTypes.arrayOf(PropTypes.shape()),
  resourceSubType: PropTypes.shape().isRequired,
  resourcesSubTypes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isPeopleLoading: PropTypes.bool,
  loadPeopleSuggestions: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool,
  updateFormValue: PropTypes.func.isRequired,
  changeRequest: PropTypes.shape()
};

export default CreateEquipment;
