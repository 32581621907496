const equipmentTypeTranslation = filter => ({
  path: `equipment.${filter.name}`
});

const capacityTranslation = filter => ({
  path: 'common.persons',
  args: {
    count: filter.description
  }
});

const floorTranslation = filter => ({
  path: 'common.floor',
  args: {
    name: filter.name
  }
});

const sectorTranslation = filter => ({
  path: 'common.sector',
  args: {
    name: filter.name
  }
});

const getFilterTranslation = filter => {
  switch (filter.type) {
    case 'roomType':
    case 'equipmentType': {
      return filter.name;
    }

    case 'capacity': {
      return capacityTranslation(filter);
    }

    // RoomEquipment
    case 'equipment': {
      return equipmentTypeTranslation(filter);
    }

    case 'floor':
      return floorTranslation(filter);

    case 'sector':
      return sectorTranslation(filter);

    default: {
      return null;
    }
  }
};

export default getFilterTranslation;
