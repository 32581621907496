import { createSelector } from 'reselect';
import { isProfile3 } from '../../../util';
import { buildingsSelectors } from '../../buildings';

const areResourcesLoading = state => state.bookingCollectionState.resources.isLoading;
const getResources = state => state.bookingCollectionState.resources.resources;
const getResourcesCount = state => getResources(state).length;
const hasMore = state => state.bookingCollectionState.resources.hasMore;
const getSkipCount = state => state.bookingCollectionState.resources.skipCount;
const getTotalCount = state => state.bookingCollectionState.resources.totalCount;

const { getBuildings } = buildingsSelectors;

const getResourcesWithBuilding = createSelector(
  [getResources, getBuildings],
  (resources, buildings) => resources
    .map(resource => ({
      ...resource,
      building: buildings.find(building => building.id === resource.buildingId)
    }))
    .filter(resource => !isProfile3(resource))
);

const hasMoreResults = createSelector(
  [hasMore, getResourcesCount],
  (moreResults, count) => count > 0 && moreResults
);

export default {
  getResources: getResourcesWithBuilding,
  areResourcesLoading,
  getSkipCount,
  getTotalCount,
  hasMoreResults
};
