import findIndex from 'lodash/fp/findIndex';
import cloneDeep from 'lodash/fp/cloneDeep';
import unionBy from 'lodash/fp/unionBy';
import find from 'lodash/fp/find';
import some from 'lodash/fp/some';
import remove from 'lodash/fp/remove';

export const findByValue = (item, collection) => find(['value', item.value], collection);
export const someByValue = (item, collection) => some(['value', item.value], collection);
export const format = items => items.map(i => i.name).join(', ');

const getFocusedItems = (currentSuggestions, next) => {
  const suggestions = cloneDeep(currentSuggestions);

  if (suggestions.length < 1) {
    return [];
  }

  const index = findIndex('focused', suggestions);
  if (index === -1) {
    if (next) {
      suggestions[0].focused = true;
    } else {
      suggestions[suggestions.length - 1].focused = true;
    }

    return suggestions;
  }

  const direction = next ? 1 : -1;
  let newIndex = index;
  newIndex += direction;
  newIndex %= suggestions.length;
  newIndex = newIndex === -1 ? suggestions.length - 1 : newIndex;

  suggestions[index].focused = false;
  suggestions[newIndex].focused = true;

  return suggestions;
};

export const joinItems = (suggestions = [], selectedValues = []) => {
  const selection = selectedValues.map(item => ({ ...item, selected: true }));
  return unionBy('value', selection, suggestions);
};

export const filterItems = (suggestions = [], selectedValues = []) => {
  if (selectedValues.length > 0) {
    return unionBy('value', selectedValues, suggestions).filter(
      item => selectedValues.indexOf(item) < 0
    );
  }
  return suggestions;
};

export const removeByValue = (item, suggestions = [], selectedValues = []) => {
  const newSelectedValues = remove(selectedValues, item);
  return filterItems(suggestions, newSelectedValues);
};

export const getSuggestions = withTags => (withTags ? filterItems : joinItems);

export const getPreviousFocusedItems = items => getFocusedItems(items, false);

export const getNextFocusedItems = items => getFocusedItems(items, true);
