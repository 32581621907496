import types from './types';

const updateAvailability = availability => ({
  type: types.UPDATE_AVAILABILITY,
  availability
});

const addAvailability = availability => ({
  type: types.ADD_AVAILABILITY,
  availability
});

const updateLoading = isLoading => ({
  type: types.UPDATE_LOADING,
  isLoading
});

const updateSkipCount = skipCount => ({
  type: types.UPDATE_SKIPCOUNT,
  skipCount
});

const updateTotalCount = totalCount => ({
  type: types.UPDATE_TOTALCOUNT,
  totalCount
});

const updateHasMore = hasMore => ({
  type: types.UPDATE_HASMORE,
  hasMore
});

const updateIsRecurrence = isRecurrence => ({
  type: types.UPDATE_ISRECURRENCE,
  isRecurrence
});

const removeResourceById = resourceId => ({
  type: types.REMOVE_RESOURCE_BY_ID,
  resourceId
});

const updateResourceAvailabilities = (resource, availabilities) => ({
  type: types.UPDATE_RESOURCE_AVAILABILITIES,
  resource,
  availabilities
});

export default {
  updateAvailability,
  addAvailability,
  updateLoading,
  updateSkipCount,
  updateTotalCount,
  updateHasMore,
  updateIsRecurrence,
  removeResourceById,
  updateResourceAvailabilities
};
