import config from '../config';
import fetch from './fetch';

const getUrl = path => `${config.remoteUrl}/${path}`;

async function get() {
  const url = getUrl('changeRequests/my');
  return fetch(url, 'GET');
}

async function getById(id) {
  const url = getUrl(`changeRequests/${id}`);
  return fetch(url, 'GET');
}

async function approve(type, id, payload) {
  const url = getUrl(`changeRequests/${type}/${id}/approve`);
  return fetch(url, 'PUT', payload);
}

async function reject(type, id, payload) {
  const url = getUrl(`changeRequests/${type}/${id}/reject`);
  return fetch(url, 'PUT', payload);
}

async function discard(type, id) {
  const url = getUrl(`changeRequests/${type}/${id}/discard`);
  return fetch(url, 'PUT');
}

async function complete(type, id) {
  const url = getUrl(`changeRequests/${type}/${id}/complete`);
  return fetch(url, 'PUT');
}

export default {
  get,
  getById,
  approve,
  reject,
  discard,
  complete
};
