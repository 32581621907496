import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  maintenanceMessageSelectors,
  maintenanceMessageOperations
} from '../../state/maintenanceMessage';

import MaintenanceMessage from './MaintenanceMessage';

import './_style.css';

class MaintenanceMessages extends Component {
  renderMessage = () => {
    const { message, hideMessages, messagesHidden } = this.props;
    if (!message || messagesHidden) {
      return null;
    }
    const props = {
      text: message.message,
      type: message.type,
      onClose: hideMessages
    };
    return <MaintenanceMessage {...props} />;
  };

  render() { return <div className="MaintenanceMessages">{this.renderMessage()}</div>; }
}

MaintenanceMessages.propTypes = {
  message: PropTypes.shape(),
  hideMessages: PropTypes.func.isRequired,
  messagesHidden: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  message: maintenanceMessageSelectors.getCurrentTranslatedMessage(state),
  messagesHidden: maintenanceMessageSelectors.areMessagesHidden(state)
});

const mapDispatchToProps = dispatch => ({
  hideMessages: () => dispatch(maintenanceMessageOperations.hideMessages())
});

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceMessages);
