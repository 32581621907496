import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { withTranslation } from '../../common';

import { createResourceSelectors, createResourceOperations } from '../../../state/createResource';
import { changeMasterDataSelectors } from '../../../state/changeMasterData';

import CreateResource from './CreateResource';

import './_style.css';
import { resourcesSubTypesSelectors } from '../../../state/resourcesSubTypes';

class CreateResourceDetail extends Component {
  UNSAFE_componentWillMount() {
    const { changeRequest, initializeDetail } = this.props;
    initializeDetail(changeRequest);
  }

  render() {
    const { changeRequest } = this.props;
    const { readOnly, status } = changeRequest;
    const isReadonly = readOnly || status === 'Approved' || status === 'Rejected';
    return (
      <div className="CreateResource CreateResourceDetail">
        <CreateResource {...this.props} readOnly={isReadonly} />
      </div>
    );
  }
}

CreateResourceDetail.propTypes = {
  initializeDetail: PropTypes.func.isRequired,
  changeRequest: PropTypes.shape().isRequired,
  resetState: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  resourceType: PropTypes.shape().isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  name: createResourceSelectors.getName(state),
  resourceType: createResourceSelectors.getResourceType(state),
  building: createResourceSelectors.getBuilding(state),
  isBuildingLoading: createResourceSelectors.isBuildingLoading(state),
  buildingSuggestions: createResourceSelectors.getBuildingSuggestions(state),
  region: createResourceSelectors.getRegion(state),
  street: createResourceSelectors.getStreet(state),
  zipCode: createResourceSelectors.getZipCode(state),
  floor: createResourceSelectors.getFloor(state),
  sector: createResourceSelectors.getSector(state),
  resourceSubType: createResourceSelectors.getResourceSubType(state),
  resourcesSubTypes: resourcesSubTypesSelectors.getAll(state),
  owners: createResourceSelectors.getOwners(state),
  capacity: createResourceSelectors.getCapacity(state),
  daylight: createResourceSelectors.getDaylight(state),
  roomEquipment: createResourceSelectors.getRoomEquipment(state),
  roomEquipmentOptions: changeMasterDataSelectors.getRoomEquipmentOptions(state),
  confidentialMeetings: createResourceSelectors.getConfidentialMeetings(state),
  contact: createResourceSelectors.getContact(state),
  notes: createResourceSelectors.getNotes(state),
  reason: createResourceSelectors.getReason(state),
  profile: createResourceSelectors.getProfile(state),
  isSubmitting: createResourceSelectors.isSubmitting(state),
  isPeopleLoading: createResourceSelectors.isPeopleLoading(state),
  peopleSuggestions: createResourceSelectors.getPeopleSuggestions(state),
  ownersProfile2: createResourceSelectors.getOwnersProfile2(state),
  ownersProfile3: createResourceSelectors.getOwnersProfile3(state),
  ownersProfile4: createResourceSelectors.getOwnersProfile4(state),
  isValid: createResourceSelectors.isValid(state)
});

const mapDispatchToProps = dispatch => ({
  updateFormValue: (field, value) => dispatch(createResourceOperations.updateFormValue(field, value)),
  loadBuildingSuggestions: term => dispatch(createResourceOperations.loadBuildingSuggestions(term)),
  loadPeopleSuggestions: term => dispatch(createResourceOperations.loadPeopleSuggestions(term)),
  submit: () => dispatch(createResourceOperations.submit()),
  resetState: () => dispatch(createResourceOperations.resetState()),
  initializeDetail: changeRequest => dispatch(createResourceOperations.initializeDetail(changeRequest))
});

const CreateResourceDetailComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateResourceDetail);

export default withTranslation(withRouter(CreateResourceDetailComponent));
