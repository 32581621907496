import React from 'react';
import PropTypes from 'prop-types';

import { CustomPropTypes, RoomCard, Favorite } from '../../../../common';
import RoomCapacity from '../RoomCapacity';
import ResourceAvailability from '../ResourceAvailability';
import RoomNameAndType from '../RoomNameAndType';
import FormSubmit from '../FormSubmit';
import FormTime from '../FormTime';
import ProfileInformation from '../ProfileInformation';
import RoomEquipment from '../RoomEquipment';

const width = amount => ({ width: amount });

function RoomCardDesktop(props) {
  const { resource, selected, showTimeInput, onSelect, isProfile3, isProfile2 } = props;
  const { alternativeType, showRelativeTime } = props;

  const showAlternativeTime = alternativeType === 'time';
  const showAlternativeEquipment = alternativeType === 'roomequipment';

  const renderFavorite = () => {
    const { id } = resource;
    if (!selected || (selected && !showTimeInput) || isProfile3) {
      return (
        <div style={width('35px')} key="Favorite">
          <Favorite resourceId={id} medium />
        </div>
      );
    }
    return null;
  };

  const renderRoomCapacityNameAndTime = () => {
    if (!selected || (selected && !showTimeInput) || isProfile3) {
      return [
        <div style={width('calc(33% - 35px')} key="RoomName">
          <RoomNameAndType resource={resource} alternativeType={alternativeType} />
        </div>,
        <div style={width('17%')} key="RoomCapacity">
          <RoomCapacity resource={resource} alternativeType={alternativeType} />
        </div>
      ];
    }
    return null;
  };

  const renderSelectedTimeInput = () => {
    if (selected && showTimeInput && !isProfile3) {
      return [
        <div style={width('50%')} key="FormTime">
          <FormTime />
        </div>,
        <div style={width('50%')} key="STI__FormSubmit">
          <FormSubmit openModal={onSelect} resource={resource} />
        </div>
      ];
    }
    return null;
  };

  const renderSelectedNoTimeInput = () => {
    if (selected && !showTimeInput && !isProfile3) {
      return (
        <div style={width('50%')} key="SNTI__FormSubmit">
          <FormSubmit openModal={onSelect} resource={resource} />
        </div>
      );
    }
    return null;
  };

  const renderRoomAvailabilityAndTrayIcons = () => {
    if (!selected || isProfile3) {
      return [
        <div style={width('25%')} key="RoomAvailability">
          <ResourceAvailability
            resource={resource}
            showAlternativeTime={showAlternativeTime}
            showRelativeTime={showRelativeTime}
          />
        </div>,
        <div style={width('25%')} key="TrayIcons">
          <div className="SearchResultCard__Tray">
            <RoomEquipment
              equipment={resource.roomEquipment}
              showAlternativeEquipment={showAlternativeEquipment}
            />
          </div>
        </div>
      ];
    }
    return null;
  };

  const renderProfileInformation = () => {
    if (isProfile3 || isProfile2) {
      return <ProfileInformation resource={resource} />;
    }
    return null;
  };

  const onClick = event => {
    if (isProfile3) {
      onSelect(event);
    }
  };

  return (
    <div>
      <RoomCard resourceSubType={resource.resourceSubType} onClick={onClick}>
        {renderFavorite()}
        {renderRoomCapacityNameAndTime()}
        {renderSelectedTimeInput()}
        {renderSelectedNoTimeInput()}
        {renderRoomAvailabilityAndTrayIcons()}
        {renderProfileInformation()}
      </RoomCard>
    </div>
  );
}

RoomCardDesktop.propTypes = {
  resource: CustomPropTypes.resource,
  selected: PropTypes.bool.isRequired,
  showTimeInput: PropTypes.bool.isRequired,
  showRelativeTime: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  isProfile3: PropTypes.bool.isRequired,
  isProfile2: PropTypes.bool.isRequired,
  alternativeType: PropTypes.string
};

export default RoomCardDesktop;
