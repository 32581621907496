import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, withTranslation } from '../../common';

class PhotoAdminUpload extends Component {
  constructor() {
    super();
    this.state = {
      file: null
    };
    this.formRef = React.createRef();
    this.uploadButtonRef = React.createRef();
  }

  onFormSubmit = async event => {
    event.preventDefault();

    const { file } = this.state;
    const { uploadPhoto } = this.props;

    const formData = new FormData();
    formData.append('files', file);

    await uploadPhoto(formData);
    this.resetForm();
  };

  onChange = event => {
    this.setState({ file: event.target.files[0] });
  };

  onUploadClick = () => {
    this.uploadButtonRef.current.click();
  };

  resetForm = () => {
    this.formRef.current.reset();
    this.setState({ file: null });
  };

  render() {
    const { isLoading, translate } = this.props;
    const { file } = this.state;
    return (
      <form className="PhotoAdminUpload" ref={this.formRef}>
        <input
          className="PhotoAdminUpload__Input"
          type="file"
          onChange={this.onChange}
          accept=".jpg, .jpeg, .png"
          ref={this.uploadButtonRef}
        />
        <div className="PhotoAdminUpload__Uploader">
          <Button
            secondary
            onClick={this.onUploadClick}
            label={translate('admin.photos.selectFile')}
          />
          {file && <p>{file.name}</p>}
          {!file && <p>{translate('admin.photos.noFile')}</p>}
        </div>
        <Button
          onClick={this.onFormSubmit}
          loading={isLoading}
          label={translate('admin.photos.upload')}
          disabled={!file}
        />
      </form>
    );
  }
}

PhotoAdminUpload.propTypes = {
  uploadPhoto: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(PhotoAdminUpload);
