import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { CustomPropTypes, withTranslation } from '../../../../common';

function RoomCapacity({ resource, alternativeType, translate }) {
  const { capacity } = resource;

  const persons = `${capacity || '?'} ${translate('common.people')}`;
  const personsClasses = cx({ 'RoomCapacity--warning': alternativeType === 'capacity' });

  return <div className={personsClasses}>{persons}</div>;
}

RoomCapacity.propTypes = {
  resource: CustomPropTypes.resource,
  alternativeType: PropTypes.string,
  translate: PropTypes.func.isRequired
};

RoomCapacity.defaultProps = {
  alternativeType: ''
};

export default withTranslation(RoomCapacity);
