// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsForm_divider__Z-OJb {\n  border: 0;\n  background-color: var(--color-gray-20);\n  height: 1px;\n}\n", "",{"version":3,"sources":["webpack://./src/views/Admin/NewsForm/NewsForm.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,sCAAsC;EACtC,WAAW;AACb","sourcesContent":[".divider {\n  border: 0;\n  background-color: var(--color-gray-20);\n  height: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "NewsForm_divider__Z-OJb"
};
export default ___CSS_LOADER_EXPORT___;
