const UPDATE_RESOURCE = 'exceptions/UPDATE_RESOURCE';
const INITIALIZE_EXCEPTIONS = 'exceptions/INITIALIZE_EXCEPTIONS';
const UPDATE_EXCEPTION = 'exceptions/UPDATE_EXCEPTION';
const PATCH_EXCEPTION = 'exceptions/PATCH_EXCEPTION';
const RESET_EXCEPTION = 'exceptions/RESET_EXCEPTION';
const REMOVE_EXCEPTION = 'exceptions/REMOVE_EXCEPTION';
const UPDATE_IS_IN_PROGRESS = 'exceptions/UPDATE_IS_IN_PROGRESS';

const UPDATE_RESOURCES = 'exceptions/UPDATE_RESOURCES';
const UPDATE_RESOURCES_LOADING = 'exceptions/UPDATE_RESOURCES_LOADING';

const ADD_ALTERNATIVE_AVAILABILITY = 'exceptions/ADD_ALTERNATIVE_AVAILABILITY';
const UPDATE_ALTERNATIVE_AVAILABILITY = 'exceptions/UPDATE_ALTERNATIVE_AVAILABILITY';
const UPDATE_ALTERNATIVE_SKIPCOUNT = 'exceptions/UPDATE_ALTERNATIVE_SKIPCOUNT';
const UPDATE_ALTERNATIVE_LOADING = 'exceptions/UPDATE_ALTERNATIVE_LOADING';
const HAS_MORE_ALTERNATIVE_RESULTS = 'exceptions/HAS_MORE_ALTERNATIVE_RESULTS';

export default {
  UPDATE_RESOURCE,
  INITIALIZE_EXCEPTIONS,
  UPDATE_EXCEPTION,
  PATCH_EXCEPTION,
  RESET_EXCEPTION,
  REMOVE_EXCEPTION,
  UPDATE_IS_IN_PROGRESS,
  UPDATE_RESOURCES,
  UPDATE_RESOURCES_LOADING,
  ADD_ALTERNATIVE_AVAILABILITY,
  UPDATE_ALTERNATIVE_AVAILABILITY,
  UPDATE_ALTERNATIVE_SKIPCOUNT,
  UPDATE_ALTERNATIVE_LOADING,
  HAS_MORE_ALTERNATIVE_RESULTS
};
