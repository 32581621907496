import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';
import validator from 'validator';

import cx from 'classnames';
import AutoCompleteText from './AutoCompleteText/AutoCompleteText';
import AutoCompleteTags from './AutoCompleteTags/AutoCompleteTags';

import identifier from '../../identifier';
import AutoCompleteDropdown from './AutoCompleteDropdown';
import { isEscape, isEnter, isSpace, isUpwards, isDownwards, isBackspace } from '../../../../util';
import {
  getNextFocusedItems,
  getPreviousFocusedItems,
  getSuggestions,
  findByValue,
  someByValue,
  format
} from './autocompleteUtil';

import '../_style.css';

class AutoComplete extends Component {
  constructor(props) {
    super(props);
    const { id, label, autoFocus, defaultValue, suggestions, selectedValues, withTags } = props;
    this.state = {
      items: getSuggestions(withTags)(suggestions, selectedValues),
      focused: autoFocus,
      hasValue: !isEmpty(defaultValue),
      id: id || identifier(label),
      selectedValues
    };

    this.onBlur = this.onBlur.bind(this);
    this.focus = this.focus.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
    this.clearInput = this.clearInput.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickOutside, true);
    const { autoFocus } = this.props;
    if (autoFocus) {
      this.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { withTags } = this.props;
    const { suggestions: prevSuggestions } = this.props;
    const { selectedValues: prevSelectedValues } = this.props;
    const { suggestions, selectedValues } = nextProps;
    const suggestionsEqual = suggestions === prevSuggestions;
    const selectedValuesEqual = selectedValues === prevSelectedValues;

    if (suggestionsEqual && selectedValuesEqual) {
      return;
    }

    const searchTerm = this.textInput.value;

    const getItems = () => {
      if (validator.isEmail(searchTerm)) {
        const userInput = {
          email: this.textInput.value,
          id: identifier(),
          name: this.textInput.value,
          value: this.textInput.value
        };
        return [userInput, ...getSuggestions(withTags)(suggestions, selectedValues)];
      }
      return getSuggestions(withTags)(suggestions, selectedValues);
    };

    this.setState({
      items: getItems(),
      selectedValues
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutside, true);
  }

  onChange(selected = {}) {
    const { suggestions, selectedValues, multipleSelection, withTags } = this.props;
    const itemSelected = !isEmpty(selected);
    const hasValue = !itemSelected && !isEmpty(this.textInput.value);

    const composeEvent = () => {
      const itemAdded = itemSelected && !someByValue(selected, selectedValues);
      const itemRemoved = itemSelected && !itemAdded;
      let values = selectedValues;
      let selection;
      if (itemAdded) {
        selection = findByValue(selected, suggestions);

        if (selection) {
          if (multipleSelection) {
            values = [...selectedValues, selected];
          } else {
            values = [selected];
          }
        } else if (validator.isEmail(selected.name)) {
          values = [...selectedValues, selected];
        }

        if (withTags) {
          this.clearInput();
        }
      }

      if (itemRemoved) {
        selection = findByValue(selected, selectedValues);
        values = selectedValues.filter(item => item !== selection);
      }

      const inputValue = hasValue ? this.textInput.value : '';
      const target = { ...this.textInput, value: inputValue };
      return { target, selection, values };
    };

    const event = composeEvent();
    const { onChange } = this.props;
    if (onChange) {
      onChange(event);
    }

    this.setState({
      hasValue,
      focused: !itemSelected,
      selectedValues: event.values
    });
  }

  onFocus() {
    this.focus();
  }

  onBlur() {
    const { withTags } = this.props;
    this.setState({ focused: false });
    if (this.textInput) {
      this.textInput.blur();
    }

    if (withTags) {
      this.clearInput();
    }
  }

  onKeyDown(event) {
    const { keyCode } = event;
    const { items: prevItems } = this.state;
    const { suggestions, selectedValues, withTags, value, onEnter } = this.props;
    const items = getSuggestions(withTags)(suggestions, selectedValues);

    if (isEscape(keyCode)) {
      event.preventDefault();
      if (withTags) {
        this.clearInput();
      }
      this.onBlur();
    }

    if (isUpwards(keyCode) || isDownwards(keyCode)) {
      event.preventDefault();
      const newSuggestions = isDownwards(keyCode)
        ? getNextFocusedItems(prevItems)
        : getPreviousFocusedItems(prevItems);
      this.setState({ items: newSuggestions, focused: true });
    }

    if (isEnter(keyCode)) {
      event.preventDefault();
      const selectedItem = prevItems.find(s => s.focused);
      if (selectedItem) {
        const item = findByValue(selectedItem, items);
        if (item) {
          this.onChange(item);
          this.onBlur();
        }
      }
    }

    if (
      this.textInput.value.length >= 1
      && validator.isEmail(value.replace(' ', ''))
      && !prevItems.find(s => s.focused)
    ) {
      if (isEnter(keyCode) || isSpace(keyCode)) {
        onEnter(event);
        event.preventDefault();
        this.clearInput();
      }
    }

    if (isBackspace(keyCode)) {
      if (withTags && this.textInput.value.length < 1) {
        const selectedItem = selectedValues.slice(-1)[0];
        this.onChange(selectedItem);
      }
    }
  }

  onClickOutside(event) {
    const { domNode } = this;
    if (!domNode || !domNode.contains(event.target)) {
      this.onBlur();
    }
  }

  focus() {
    const { suggestions, selectedValues, withTags } = this.props;
    this.setState({
      focused: true,
      items: getSuggestions(withTags)(suggestions, selectedValues)
    });

    this.textInput.focus();
  }

  clearInput() {
    this.textInput.value = '';
  }

  render() {
    const { id, hasValue, selectedValues, focused, items } = this.state;
    const {
      label,
      placeholder,
      value,
      fixed,
      className,
      withTags,
      loading,
      showLoader,
      ...props
    } = this.props;
    const otherProps = omit(['selectedValues', 'onChange', 'suggestions', 'selectedValues'], props);

    const wrapperClasses = cx(className, 'input-field input-field--autocomplete', {
      'input-field--fixed': fixed,
      'is-open': focused && items.length > 0
    });

    const contentClasses = cx('AutoComplete__Content', {
      'AutoComplete__Content--focused': focused
    });

    const classes = cx({
      'is-fixed': value || hasValue
    });

    const domRef = node => {
      this.domNode = node;
    };

    const inputRef = node => {
      this.textInput = node;
    };

    const input = focused ? value : format(selectedValues);

    const autoCompleteProps = omit(['onKeyDown', 'onEnter', 'multipleSelection'], otherProps);

    return (
      <div className={wrapperClasses} ref={domRef}>
        <div className={contentClasses}>
          {!withTags && (
            <AutoCompleteText
              id={id}
              placeholder={placeholder}
              value={input}
              onChange={this.onChange}
              onFocus={this.onFocus}
              classes={classes}
              input={inputRef}
              onKeyDown={this.onKeyDown}
              {...autoCompleteProps}
            />
          )}
          {withTags && (
            <AutoCompleteTags
              id={id}
              placeholder={placeholder}
              items={selectedValues}
              onChange={this.onChange}
              onFocus={this.onFocus}
              classes={classes}
              input={inputRef}
              onKeyDown={this.onKeyDown}
              {...autoCompleteProps}
            />
          )}
        </div>

        <label htmlFor={id} className="AutoCompleteLabel">
          {label}
        </label>

        <AutoCompleteDropdown
          items={items}
          value={value}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          showLoader={withTags || showLoader}
          loading={loading}
        />

        <div className="message">
          <i className="icon icon-026-exclamation-mark-circle" aria-hidden="true" />
          Error Message
        </div>
      </div>
    );
  }
}

const suggestionProps = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })
);

AutoComplete.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  suggestions: suggestionProps,
  selectedValues: suggestionProps,
  defaultValue: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  fixed: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onEnter: PropTypes.func,
  className: PropTypes.string,
  withTags: PropTypes.bool,
  multipleSelection: PropTypes.bool,
  showLoader: PropTypes.bool,
  loading: PropTypes.bool
};

AutoComplete.defaultProps = {
  autoFocus: false,
  onEnter: () => {},
  suggestions: [],
  selectedValues: [],
  withTags: false,
  multipleSelection: true,
  loading: false
};

export default AutoComplete;
