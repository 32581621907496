import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, withTranslation } from '../../../../common';

import styles from './SearchBarRecurrence.module.css';

function SearchBarRecurrence({ onClick, translate, active }) {
  const classes = active ? styles.isActive : null;
  const RecurrenceIcon = <Icon icon="007-backup" className={styles.icon} />;

  const label = translate('search.when.recurrence.title');

  return (
    <Button className={classes} label={label} outlined onClick={onClick} icon={RecurrenceIcon} />
  );
}

export default withTranslation(SearchBarRecurrence);

SearchBarRecurrence.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
};
