const UPDATE_SELECTED_MASTER = 'recurrenceMaster/UPDATE_SELECTED_MASTER';
const UPDATE_SUBJECT = 'recurrenceMaster/UPDATE_SUBJECT';
const UPDATE_MESSAGE = 'recurrenceMaster/UPDATE_MESSAGE';
const UPDATE_REMINDER = 'recurrenceMaster/UPDATE_REMINDER';
const UPDATE_IS_LOADING = 'recurrenceMaster/UPDATE_IS_LOADING';
const CACHE_SELECTED_MASTER = 'recurrenceMaster/CACHE_SELECTED_MASTER';
const RESTORE_SELECTED_MASTER = 'recurrenceMaster/RESTORE_SELECTED_MASTER';

export default {
  UPDATE_SELECTED_MASTER,
  UPDATE_SUBJECT,
  UPDATE_MESSAGE,
  UPDATE_REMINDER,
  UPDATE_IS_LOADING,
  CACHE_SELECTED_MASTER,
  RESTORE_SELECTED_MASTER
};
