import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from '../Icon/Icon';
import './_style.css';

function Profile3Info({ children, className }) {
  const classes = cx('Profile3', className);
  return (
    <div className={classes}>
      <Icon icon="046-information-circle" className="Profile3__Icon" />
      {children}
    </div>
  );
}

Profile3Info.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Profile3Info;
