import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from '../../common';
import { authSelectors } from '../../../state/auth';
import SettingsSwitch from './SettingsSwitch/SettingsSwitch';
import Header from '../../Header';
import Content from './Content/Content';

class Settings extends Component {
  componentDidMount() {
    const { isAdmin, history, location, match } = this.props;
    if (!isAdmin) {
      history.replace('/unauthorized');
    }
    const isNestedRoute = location.pathname !== match.url;
    if (isNestedRoute) {
      history.replace(match.url);
    }
  }

  render() {
    const { history, translate } = this.props;
    return (
      <div>
        <Header onClose={() => { history.push('/'); }} title={translate('menu.settings')} />
        <div>
          <SettingsSwitch />
          <Content />
        </div>
      </div>
    );
  }
}

Settings.propTypes = {
  isAdmin: PropTypes.bool,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  match: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

Settings.defaultProps = {
  isAdmin: false
};

const mapStateToProps = state => ({
  isAdmin: authSelectors.isAdministrator(state)
});

export default connect(mapStateToProps, null)(withTranslation(Settings));
