const MAP_RESOURCE_DATA = 'changeMasterData/MAP_RESOURCE_DATA';
const UPDATE_FORM_VALUE = 'changeMasterData/UPDATE_FORM_VALUE';
const UPDATE_SUBMITTED = 'changeMasterDate/UPDATE_SUBMITTED';
const UPDATE_IS_SUBMITTING = 'changeMasterDate/UPDATE_IS_SUBMITTING';

const UPDATE_CONTACT_ISLOADING = 'changeMasterData/UPDATE_CONTACT_ISLOADING';
const UPDATE_CONTACT_SUGGESTIONS = 'changeMasterData/UPDATE_CONTACT_SUGGESTIONS';

const INITIALIZE_DETAIL = 'changeMasterData/INITIALIZE_DETAIL';

export default {
  MAP_RESOURCE_DATA,
  UPDATE_FORM_VALUE,
  UPDATE_CONTACT_ISLOADING,
  UPDATE_CONTACT_SUGGESTIONS,
  UPDATE_SUBMITTED,
  UPDATE_IS_SUBMITTING,
  INITIALIZE_DETAIL
};
