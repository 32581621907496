import last from 'lodash/fp/last';

const getActiveModalKey = state => last(state.modalState.modalHistory);
const getModalHistory = state => state.modalState.modalHistory;

const getOffset = state => state.modalState.offset;

const isFakeModalActive = state => state.modalState.fakeModalActive;

export default {
  getActiveModalKey,
  getModalHistory,
  getOffset,
  isFakeModalActive
};
