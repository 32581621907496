import store from 'store2';
import actions from './actions';
import { configService } from '../../services';
import { notificationsOperations, notificationsSelectors } from '../notifications';
import selectors from './selectors';
import loadConfiguration from '../../services/loadConfiguration';

const persistData = dispatch => config => {
  store('config', config);
  dispatch(actions.updateConfig(config));
  dispatch(actions.updateIsLoaded(Date.now()));
};

const getConfig = () => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());
  const persist = persistData(dispatch, state);

  const age = selectors.getAge(state);
  const tenMinutes = 1000 * 60 * 10;

  if (!age || age + tenMinutes < Date.now()) {
    return configService
      .getConfig()
      .then(response => {
        persist(response);
      })
      .catch(serverError => {
        const error = getNotification('config.error', serverError);
        dispatch(notificationsOperations.showError(error));
      });
  }
  return null;
};

const updateConfig = (id, themeColor) => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);
  dispatch(notificationsOperations.hideError());

  return configService
    .updateConfig({ Id: id, ThemeColor: themeColor })
    .then(() => {
      const persist = persistData(dispatch, state);
      return configService
        .getConfig()
        .then(response => {
          const success = getNotification('admin.settings.config.update.success');
          dispatch(notificationsOperations.showSuccess(success));
          persist(response);
          loadConfiguration();
          return 'Ok';
        })
        .catch(serverError => {
          const error = getNotification('admin.settings.config.update.error', serverError);
          dispatch(notificationsOperations.showError(error));
          return 'Error';
        });
    })
    .catch(serverError => {
      const error = getNotification('config.error', serverError);
      dispatch(notificationsOperations.showError(error));
      return 'Error';
    });
};

export default {
  updateConfig,
  getConfig
};
