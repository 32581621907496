import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Header from '../../../Header';
import SearchFormDateDay from './SearchFormDateDay';
import SearchFormDateTime from './SearchFormDateTime';
import SearchFormDateRecurrence from './SearchFormDateRecurrence';
import SearchFormDateValidation from './SearchFormDateValidation';
import { Button, withTranslation } from '../../../common';
import { searchSelectors } from '../../../../state/search';
import { availabilityOperations } from '../../../../state/availability';

import './_style.css';

class SearchFormDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showValidationSummary: false
    };
  }

  onSave = () => {
    const { isValid, loadAvailabilities, history } = this.props;
    this.setState({ showValidationSummary: true });
    if (isValid) {
      loadAvailabilities();
      history.goBack();
    }
  };

  renderHeader = () => {
    const { translate } = this.props;
    const title = translate('search.when.title');
    return <Header onClose={this.onSave} title={title} />;
  };

  renderValidationSummary = () => {
    const { isValid } = this.props;
    const { showValidationSummary } = this.state;
    const visible = showValidationSummary && !isValid;
    const onClose = () => this.setState({ showValidationSummary: false });
    return <SearchFormDateValidation visible={visible} onClose={onClose} />;
  };

  renderButton = () => {
    const { translate } = this.props;
    const label = translate('common.search');
    return <Button label={label} className="button--fixed" onClick={this.onSave} />;
  };

  renderSection = child => (
    <div className="SearchFormDate__Section">
      <div className="SearchFormDate__Content">{child}</div>
    </div>
  );

  render() {
    return (
      <>
        {this.renderHeader()}

        {this.renderValidationSummary()}
        <div className="SearchFormDate">
          {this.renderSection(<SearchFormDateDay />)}
          {this.renderSection(<SearchFormDateTime />)}
          {this.renderSection(<SearchFormDateRecurrence />)}
        </div>

        {this.renderButton()}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isValid: searchSelectors.isValidDate(state)
});

const mapDispatchToProps = dispatch => ({
  loadAvailabilities: () => dispatch(availabilityOperations.loadAvailabilities())
});

SearchFormDate.propTypes = {
  isValid: PropTypes.bool.isRequired,
  loadAvailabilities: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired
};

const SearchFormDateComponent = withTranslation(withRouter(SearchFormDate));
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFormDateComponent);
