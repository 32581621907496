import types from './types';

const updateResourcesSubTypes = resourcesSubTypes => ({
  type: types.UPDATE_RESOURCESSUBTYPE,
  resourcesSubTypes
});

const setSelectedResourcesSubType = selectedResourcesSubType => ({
  type: types.SET_SELECTED_RESOURCESSUBTYPE,
  selectedResourcesSubType
});

const updateIsLoaded = age => ({
  type: types.UPDATE_LOADED,
  age
});

export default {
  updateResourcesSubTypes,
  setSelectedResourcesSubType,
  updateIsLoaded
};
