// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewsContent_content__6nfxh {\n  background-color: var(--color-horizon);\n  border-left: 1px solid var(--color-gray-20);\n  display: block;\n  width: 0;\n  transition: width 0.3s ease-out;\n  will-change: width;\n  height: calc(100vh - 240px);\n  overflow: auto;\n}\n\n.NewsContent_visible__ueLDJ {\n  width: 315px;\n}\n\n.NewsContent_list__ppwBy {\n  padding: 0 20px;\n}\n\n.NewsContent_header__RsctR {\n  padding: 0 20px;\n  line-height: 60px;\n  font-weight: 700;\n}\n\n.NewsContent_buttonLink__zRcZ7 {\n  padding: 0 20px 10px 20px;\n  float: right;\n}\n\n.NewsContent_loading__D9gqV {\n  padding: 0 20px 10px 20px;\n}\n\n.NewsContent_emptyState__27Rrp {\n  padding: 0 20px 10px 20px;\n}", "",{"version":3,"sources":["webpack://./src/views/Search/SearchDesktop/Content/Now/News/NewsContent.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,2CAA2C;EAC3C,cAAc;EACd,QAAQ;EACR,+BAA+B;EAC/B,kBAAkB;EAClB,2BAA2B;EAC3B,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".content {\n  background-color: var(--color-horizon);\n  border-left: 1px solid var(--color-gray-20);\n  display: block;\n  width: 0;\n  transition: width 0.3s ease-out;\n  will-change: width;\n  height: calc(100vh - 240px);\n  overflow: auto;\n}\n\n.visible {\n  width: 315px;\n}\n\n.list {\n  padding: 0 20px;\n}\n\n.header {\n  padding: 0 20px;\n  line-height: 60px;\n  font-weight: 700;\n}\n\n.buttonLink {\n  padding: 0 20px 10px 20px;\n  float: right;\n}\n\n.loading {\n  padding: 0 20px 10px 20px;\n}\n\n.emptyState {\n  padding: 0 20px 10px 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "NewsContent_content__6nfxh",
	"visible": "NewsContent_visible__ueLDJ",
	"list": "NewsContent_list__ppwBy",
	"header": "NewsContent_header__RsctR",
	"buttonLink": "NewsContent_buttonLink__zRcZ7",
	"loading": "NewsContent_loading__D9gqV",
	"emptyState": "NewsContent_emptyState__27Rrp"
};
export default ___CSS_LOADER_EXPORT___;
