/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toTwoDigits } from '../../../../../util';
import { searchSelectors } from '../../../../../state/search';

import { withTranslation } from '../../../../common';
import calculateAbsoluteAvailability from './calculateAbsoluteAvailability';
import calculateRelativeAvailability from './calculateRelativeAvailability';

import './_style.css';

const formatTime = date => {
  const getHours = () => toTwoDigits(date.getHours());
  const getMinutes = () => toTwoDigits(date.getMinutes());
  return `${getHours()}:${getMinutes()}`;
};

const dateEqual = (date, other) => date && other && date.valueOf() === other.valueOf();

class Availability extends Component {
  renderAlternativeTime = availabilities => {
    const { startTime, endTime } = this.props;
    if (!availabilities || availabilities.length <= 0) {
      return <span />;
    }

    const firstAvailability = availabilities[0];
    const start = new Date(firstAvailability.start);
    const end = new Date(firstAvailability.end);

    const sameStartTime = dateEqual(start, startTime);
    const sameEndTime = dateEqual(end, endTime);

    const formattedStart = formatTime(start);
    const formattedEnd = formatTime(end);

    const warning = 'Availability--warning';
    const startClasses = sameStartTime ? '' : warning;
    const endClasses = sameEndTime ? '' : warning;
    const dashClasses = !sameStartTime && !sameEndTime ? warning : '';

    return (
      <span>
        <span className={startClasses}>{formattedStart}</span>
        <span className={dashClasses}> - </span>
        <span className={endClasses}>{formattedEnd}</span>
      </span>
    );
  };

  render() {
    const { availabilities, showRelativeTime, showAlternativeTime, translate } = this.props;

    if (showAlternativeTime) {
      return this.renderAlternativeTime(availabilities);
    }

    if (showRelativeTime) {
      return <span>{calculateRelativeAvailability(availabilities, translate)}</span>;
    }

    return <span>{calculateAbsoluteAvailability(availabilities)}</span>;
  }
}

const mapStateToProps = state => ({
  startTime: searchSelectors.getStartDateTime(state),
  endTime: searchSelectors.getEndDateTime(state)
});

Availability.propTypes = {
  startTime: PropTypes.instanceOf(Date),
  endTime: PropTypes.instanceOf(Date),
  availabilities: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.string,
      end: PropTypes.string
    })
  ),
  showRelativeTime: PropTypes.bool.isRequired,
  showAlternativeTime: PropTypes.bool,
  translate: PropTypes.func.isRequired
};

const AvailabilityComponent = withTranslation(Availability);
export default connect(mapStateToProps)(AvailabilityComponent);
