import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import { authSelectors } from '../../../state/auth';
import { withTranslation, Button } from '../../common';
import Header from '../../Header';
import { newsService } from '../../../services';
import styles from './NewsDetails.module.css';
import NewsForm from '../NewsForm';
import validateNewsForm from '../../../util/news';

class NewsDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingNews: true,
      errorLoadingNews: false,
      news: null
    };
  }

  componentDidMount() {
    const { isAdmin, history, match } = this.props;
    const { newsId } = match.params;
    if (!isAdmin) {
      history.replace('/unauthorized');
    }
    this.loadNews(newsId);
  }

  deleteNews = async () => {
    const { history } = this.props;
    const { news } = this.state;
    await newsService.removeNews(news.id);
    history.push('/admin/news');
  };

  saveNews = async () => {
    const { history } = this.props;
    const { news } = this.state;
    await newsService.updateNews(news.id, news);
    history.push('/admin/news');
  };

  onChange = event => {
    const { news } = this.state;
    this.setState({
      news: {
        ...news,
        ...event
      }
    });
  };

  async loadNews(id) {
    this.setState({
      loadingNews: true
    });
    try {
      const data = await newsService.getNewsById(id);
      data.startPublish = !data.startPublish ? null : moment(data.startPublish).format('DD.MM.YYYY');
      data.stopPublish = !data.stopPublish ? null : moment(data.stopPublish).format('DD.MM.YYYY');
      this.setState({
        loadingNews: false,
        news: data
      });
    } catch {
      this.setState({
        loadingNews: false,
        errorLoadingNews: true
      });
    }
  }

  render() {
    const { history, translate } = this.props;
    const { news, loadingNews, errorLoadingNews } = this.state;
    let isNewsValid = !!news && !!news.germanTitle && !!news.germanText && !!news.startPublish;
    if (isNewsValid) {
      isNewsValid = isNewsValid && validateNewsForm(news);
    }
    const title = translate('menu.news');
    return (
      <div>
        <Header onClose={() => { history.push('/admin/news'); }} title={title} iconRight icon="022-close" />
        <div className={styles.body}>
          <div>
            {!!news
              && (
                <div className={styles.bodyForm}>
                  <NewsForm
                    value={news}
                    onChange={this.onChange}
                  />
                  <div className={styles.buttonGroup}>
                    <Button
                      secondary
                      className={styles.button}
                      label={translate('common.delete')}
                      onClick={this.deleteNews}
                    />
                    <Button
                      disabled={!isNewsValid}
                      className={styles.button}
                      label={translate('common.save')}
                      onClick={this.saveNews}
                    />
                  </div>
                </div>
              )}
            {loadingNews
              && (
                <div>
                  {translate('common.loading')}
                </div>
              )}
            {errorLoadingNews
              && (
                <div>
                  {translate('common.errorLoading')}
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

NewsDetails.propTypes = {
  isAdmin: PropTypes.bool,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  translate: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      newsId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

NewsDetails.defaultProps = {
  isAdmin: false
};

const mapStateToProps = state => ({
  isAdmin: authSelectors.isAdministrator(state)
});

export default connect(mapStateToProps, null)(
  withTranslation(withRouter(NewsDetails))
);
