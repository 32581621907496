import actions from '../actions/resourcesActions';
import selectors from '../selectors/resourcesSelectors';
import bookingCollectionSelectors from '../selectors/bookingCollectionSelectors';
import alternativesOperations from './alternativesOperations';
import config from '../../../config';
import { roomFilterSelectors, roomFilterOperations } from '../../roomFilter';
import { equipmentFilterSelectors } from '../../equipmentFilter';
import { notificationsSelectors, notificationsOperations } from '../../notifications';
import { availabilityService } from '../../../services';
import { locationsSelectors } from '../../locations';

const {
  addResources,
  updateResources,
  updateResourcesLoading,
  updateHasMore,
  updateSkipCount,
  updateTotalCount
} = actions;

const { hideError } = notificationsOperations;

const createPayload = (state, resourceType) => {
  const { start, end } = bookingCollectionSelectors.getDates(state);

  const capacities = roomFilterSelectors.getSelectedCapacitiesNumbers(state);
  const roomTypes = roomFilterSelectors.getSelectedRoomTypesIds(state);
  const equipment = roomFilterSelectors.getSelectedEquipmentNames(state);
  const equipmentTypes = equipmentFilterSelectors.getSelectedEquipmentTypeIds(state);
  const locations = locationsSelectors.getLocationsByType(state);
  const floors = roomFilterSelectors.getSelectedFloorIds(state);
  const sectors = roomFilterSelectors.getSelectedSectorIds(state);

  return {
    start,
    end,
    exactTime: true,
    locations,
    roomTypes,
    capacities,
    floors,
    sectors,
    equipment,
    equipmentTypes,
    recurrence: false,
    resourceTypes: [resourceType],
    pageSize: config.pageSize,
    skipCount: 0
  };
};

const load = payload => (dispatch, getState) => {
  const state = getState();
  const getNotification = notificationsSelectors.getNotification(state);

  dispatch(updateResourcesLoading(true));
  dispatch(hideError());

  return availabilityService
    .post(payload, false)
    .then(response => {
      const { totalCount, nextSkipCount, results: items } = response;
      const hasMore = totalCount > nextSkipCount;

      const resources = items.map(result => ({
        ...result.resource,
        availabilities: result.availabilities
      }));

      dispatch(updateHasMore(hasMore));
      dispatch(updateTotalCount(totalCount));
      dispatch(updateSkipCount(nextSkipCount));
      dispatch(payload.skipCount === 0 ? updateResources(resources) : addResources(resources));
    })
    .catch(serverError => {
      const error = getNotification('search.load.error', serverError);
      dispatch(notificationsOperations.showError(error));
    })
    .then(() => dispatch(updateResourcesLoading(false)));
};

const loadResources = resourceType => (dispatch, getState) => {
  const state = getState();
  dispatch(updateTotalCount(0));
  dispatch(updateResources([]));
  dispatch(alternativesOperations.clearAlternatives());
  dispatch(roomFilterOperations.resetDirty());
  const payload = createPayload(state, resourceType);
  return load(payload)(dispatch, getState);
};

const loadMoreResources = resourceType => (dispatch, getState) => {
  const state = getState();
  const hasMore = selectors.hasMoreResults(state);
  if (!hasMore) {
    return Promise.reject(new Error('no more results available'));
  }

  const nextSkipCount = selectors.getSkipCount(state);
  const payload = createPayload(state, resourceType);
  payload.skipCount = nextSkipCount;
  return load(payload)(dispatch, getState);
};

export default {
  loadResources,
  loadMoreResources
};
