import React from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash/fp/includes';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { BookFormMaster, BookFormSubmit, BookFormNotes, BookFormProfile2 } from '../BookForm';
import { BookDetailSidebar } from '../BookDetail';
import BookDetailPrivate from '../BookDetail/BookDetailPrivate';

import Header from '../../Header';

import { ResourceImages, Spacing } from '../../common';
import SidebarChangeRequest from '../BookDetail/BookDetailSidebar/SidebarChangeRequest';
import SidebarSeatingPicker from '../BookDetail/BookDetailSidebar/SidebarSeatingPicker';
import BookFormRecurrenceUpdate from '../BookForm/BookFormRecurrence/BookFormRecurrenceUpdate';

import '../_style.css';

function RecurrenceUpdateModalDesktop(props) {
  const { selectedMaster, goBackToDetail, updateMaster, isLoading } = props;

  const isProfile2 = includes('Request', selectedMaster.resource.permissionTypes);

  const {
    resource: { notes }
  } = selectedMaster;

  return (
    <>
      <Header
        title={selectedMaster.resource.displayName}
        onClose={goBackToDetail}
        fixed={false}
        icon="014-chevron-left"
      />
      <div className="BookModalDesktop">
        <div className="BookModal__Main">
          <Scrollbars autoHide>
            <div className="BookModal__Body">
              <div className="BookModal__Content">
                <BookFormMaster update {...props} />
              </div>
              <hr className="BookModal__Divider" style={{ marginBottom: 20 }} />
              <div className="BookModal__Content">
                <BookFormRecurrenceUpdate />
              </div>
              <hr className="BookModal__Divider" style={{ marginBottom: 20, marginTop: 20 }} />
              <div className="BookModal__Content">
                {selectedMaster.isPrivate && <BookDetailPrivate />}
                {isProfile2 && <BookFormProfile2 resource={selectedMaster.resource} />}
                {notes && (
                  <Spacing bottom="1">
                    <BookFormNotes notes={notes} />
                  </Spacing>
                )}
                <BookFormSubmit
                  onClick={updateMaster}
                  isReadyToSubmit
                  isSubmitting={isLoading}
                  profile2={false}
                  update
                />
              </div>
            </div>
          </Scrollbars>
        </div>
        <div className="BookModal__Sidebar">
          <Scrollbars autoHide>
            <ResourceImages resource={selectedMaster.resource} />
            <div className="BookModal__SidebarInfo">
              <BookDetailSidebar
                resource={selectedMaster.resource}
                seating={selectedMaster.seating}
              />
              <SidebarSeatingPicker resource={selectedMaster.resource} seating={selectedMaster.seating} />
              <SidebarChangeRequest resource={selectedMaster.resource} />
            </div>
          </Scrollbars>
        </div>
      </div>
    </>
  );
}

RecurrenceUpdateModalDesktop.propTypes = {
  selectedMaster: PropTypes.shape(),
  updateMaster: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  goBackToDetail: PropTypes.func.isRequired
};

export default RecurrenceUpdateModalDesktop;
