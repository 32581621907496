import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Search from '../Search';
import Settings from '../Admin/Settings';
import { Maintenance, PhotoAdmin, News, NewsDetails, NewsNew } from '../Admin';
import FeedbackPage from '../Feedback';
import MyBookingsPage from '../MyBookings';
import LanguagePage from '../Language';
import NotAuthorized from '../ErrorPage/NotAuthorized';
import HelpPage from '../Help';

import {
  EditResource,
  CreateResource,
  ChangeRequestDetail,
  MyChangeRequests
} from '../ChangeRequests';

import RegionsCreate from '../Admin/Settings/Regions/RegionsCreate';
import RegionsUpdate from '../Admin/Settings/Regions/RegionsUpdate';
import BuildingsCreate from '../Admin/Settings/Buildings/BuildingsCreate';
import BuildingsUpdate from '../Admin/Settings/Buildings/BuildingsUpdate';
import ResourcesSubTypesCreate from '../Admin/Settings/ResourcesSubTypes/ResourcesSubTypesCreate';
import ResourcesSubTypesUpdate from '../Admin/Settings/ResourcesSubTypes/ResourcesSubTypesUpdate';
import Floors from '../Admin/Settings/Floors/Floors';
import { BookingCollection, BookingCollectionDelete } from '../BookingCollection';

import './_styles.css';

function Content() {
  return (
    <Switch>
      <Route path="/search" component={Search} />
      <Route path="/feedback" component={FeedbackPage} />
      <Route path="/mybookings" component={MyBookingsPage} />
      <Route path="/language" component={LanguagePage} />
      <Route path="/changerequests/my" exact component={MyChangeRequests} />
      <Route path="/changerequests/new" exact component={CreateResource} />
      <Route path="/changerequests/:changeRequestId" exact component={ChangeRequestDetail} />
      <Route path="/resources/:resourceId/changerequest" exact component={EditResource} />
      <Route
        path="/resources/:resourceId/addrooms"
        exact
        component={() => <BookingCollection resourceType="room" />}
    />
      <Route
        path="/resources/:resourceId/addequipments"
        exact
        component={() => <BookingCollection resourceType="equipment" />}
    />
      <Route path="/bookingcollection/:resourceId/remove" exact component={BookingCollectionDelete} />
      <Route path="/help" exact component={HelpPage} />
      <Route path="/unauthorized" exact component={NotAuthorized} />
      <Route path="/admin/maintenance" exact component={Maintenance} />
      <Route path="/admin/photos" exact component={PhotoAdmin} />
      <Route path="/admin/news" exact component={News} />

      {/* The order matters */}
      <Route path="/admin/settings/regions/create" exact component={RegionsCreate} />
      <Route path="/admin/settings/buildings/create" exact component={BuildingsCreate} />
      <Route path="/admin/settings/resourcesSubTypes/create" exact component={ResourcesSubTypesCreate} />

      <Route path="/admin/settings/regions/:regionId" exact component={RegionsUpdate} />
      <Route path="/admin/settings/buildings/:buildingId" exact component={BuildingsUpdate} />
      <Route path="/admin/settings/resourcesSubTypes/:resourceSubTypeId" exact component={ResourcesSubTypesUpdate} />

      <Route path="/admin/settings/config" exact component={Settings} />
      <Route path="/admin/settings/regions" exact component={Settings} />
      <Route path="/admin/settings/buildings" exact component={Settings} />
      <Route path="/admin/settings/floors" exact component={Floors} />
      <Route path="/admin/settings/resourcesSubTypes" exact component={Settings} />
      <Route path="/admin/settings" component={Settings} />

      {/* /new needs to be before /:newsId */}
      <Route path="/admin/news/new" exact component={NewsNew} />
      <Route path="/admin/news/:newsId" exact component={NewsDetails} />
      <Redirect from="/book" to="/search" />
      <Redirect from="/calendar" to="search/daily" />
      <Redirect from="/" to="/search" />
    </Switch>
  );
}

export default Content;
