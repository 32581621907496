import config from 'react-global-configuration';
import store from 'store2';

config.set({ logoUrl: '', name: '' }, { freeze: false });

const applyColorConfiguration = () => {
    const themeColor = store.get('config') == null ? '#0060AE' : store.get('config').themeColor;
    const css = `:root {  --color-theme: ${themeColor};`;
    const style = document.createElement('style');
    style.innerHTML = css;
    document.head.appendChild(style);
    document.title = config.get('name');
};

export default function loadConfiguration() {
    fetch('/manifest.json').then(response => {
        response.json().then(result => {
            config.set(
{ logoUrl: `/images/${result.logo_url}`, name: result.name },
                        { freeze: false }
);
            applyColorConfiguration();
        });
    });
}
