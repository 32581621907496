import { createSelector } from 'reselect';
import moment from 'moment';
import { roomFilterSelectors } from '../roomFilter';
import { equipmentFilterSelectors } from '../equipmentFilter';

import config from '../../config';
import {
  isDateValid,
  isDateInPast,
  isDateTimeInPast,
  toDate,
  toDateTime,
  isTimeValid,
  isTimeRangeValid
} from '../../util';

const getResourceType = state => state.searchState.resourceType;
const getDate = state => state.searchState.date;
const getTime = state => state.searchState.time;

const isEmpty = value => value == null || value.length == null || value.length < 1;

const convertToDate = (date, time) => {
  if (!time) {
    return null;
  }
  return toDateTime(toDate(date), time);
};

const isValidDate = createSelector(
  getDate,
  date => {
    if (!isDateValid(date) || isDateInPast(date)) {
      return false;
    }

    const momentDate = moment(toDate(date));
    const maxDate = moment().add(config.maxDaysInFuture, 'day');
    if (momentDate.isAfter(maxDate)) {
      return false;
    }

    return true;
  }
);

const isValidTime = createSelector(
  getTime,
  ({ from, to }) => (from === '' && to === '')
    || (isTimeValid(from) && isTimeValid(to) && isTimeRangeValid(from, to))
);

const getDateTime = createSelector(
  [getDate, getTime, isValidDate],
  (date, time, valid) => {
    const { from, to } = time;

    const validDate = valid && date !== '';
    const validTime = from !== '' && to !== '';
    if (validDate && validTime) {
      return `${date} (${from}-${to})`;
    }

    if (validDate) {
      return date;
    }

    if (validTime) {
      return `${from}-${to}`;
    }

    return '';
  }
);

const isValidDateWithTime = createSelector(
  [getDate, getTime, isValidTime],
  (date, time, timeIsValid) => {
    const { from, to } = time;
    if (!isDateValid(date)) {
      return false;
    }
    const dateWithTime = moment(convertToDate(date, from));
    const acceptedTime = moment().subtract(15, 'minutes');
    const empty = isEmpty(from) && isEmpty(to);
    const isTooFarBehind = dateWithTime.isBefore(acceptedTime);
    return empty || (!isTooFarBehind && timeIsValid);
  }
);

const getStartDateTime = createSelector(
  [getDate, getTime, isValidDateWithTime],
  (date, time, isValid) => (isValid ? convertToDate(date, time.from) : null)
);

const getEndDateTime = createSelector(
  [getDate, getTime, isValidDateWithTime],
  (date, time, isValid) => (isValid ? convertToDate(date, time.to) : null)
);

const isExactTime = createSelector(
  [getTime, isValidTime],
  (time, isValid) => {
    const { from, to } = time;
    return !isEmpty(from) && !isEmpty(to) && isValid;
  }
);

const isRelativeTime = createSelector(
  getTime,
  ({ from, to }) => isEmpty(from) && isEmpty(to)
);

const wasChanged = state => state.searchState.changed;

const getTags = createSelector(
  [
    roomFilterSelectors.getRoomFilterTags,
    equipmentFilterSelectors.getEquipmentFilterTags,
    getResourceType
  ],
  (roomTags, equipmentTags, resourceType) => {
    const allTags = [...roomTags, ...equipmentTags];
    return allTags.filter(tag => tag.resourceType === resourceType);
  }
);

const getSearchedExactTime = state => state.searchState.searchedExactTime;

const getIsDateInPast = createSelector(
  [getDate, getTime],
  (date, time) => {
    if (time.from) {
      return isDateTimeInPast(date, time);
    }
    return isDateInPast(date);
  }
);

export default {
  getDate,
  getTime,
  getResourceType,
  getDateTime,
  isValidDate,
  isValidDateWithTime,
  getStartDateTime,
  getEndDateTime,
  isExactTime,
  isRelativeTime,
  wasChanged,
  getTags,
  getSearchedExactTime,
  getIsDateInPast
};
