import React, { memo } from 'react';
import { NodeResizer } from 'reactflow';
import PropTypes from 'prop-types';

function BlockNode({ data }) {
  return (
    <>
      <NodeResizer
        minWidth={50}
        minHeight={20}
        onResizeStart={data.onResizeStart}
        shouldResize={data.shouldResize}
        onResize={data.onResize}
        onResizeEnd={data.onResizeEnd}
        handleStyle={{
          borderColor: 'transparent',
          backgroundColor: 'transparent'
        }}
        lineStyle={{
          borderColor: 'transparent',
          backgroundColor: 'transparent'
        }}
      />
      {data.debug
        && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: '7px', display: 'flex', height: '10px', marginTop: '-5px' }}>{data.position}</div>
            <div style={{ fontSize: '7px', display: 'flex', height: '10px' }}>{data.positionAbsolute}</div>
            <div style={{ fontSize: '7px' }}>{data.width}</div>
            <div style={{ fontSize: '7px' }}>{data.height}</div>
          </div>
        )}
    </>
  );
}

export function getBlockNode() {
  return {
    id: '',
    type: 'BlockNode',
    position: { x: 0, y: 0 },
    positionAbsolute: { x: 0, y: 0 },
    parentNode: 'floorMap',
    width: 50,
    height: 30,
    extent: 'parent',
    style: {
      background: '#e9e9e9',
      border: '2px solid #e9e9e9',
      borderRadius: '5px',
      fontSize: 8,
      width: 50,
      height: 30
    }
  };
}

BlockNode.propTypes = {
  data: PropTypes.objectOf(PropTypes.any)
};

export default memo(BlockNode);
