import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Now from './Now';
import Calendar from './Calendar';

function Content() {
  return (
    <Switch>
      <Route exact path="/search/now" component={Now} />
      <Route exact path="/search/daily" component={Calendar} />
      <Route exact path="/search/weekly" component={Calendar} />
      <Redirect from="/search" to="/search/now" />
    </Switch>
  );
}

export default Content;
