import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from '../../../common';

function ResourceDetailField({ label, children, translate }) {
  return (
    <div className="ResourceDetailField">
      <div className="ResourceDetailField__Label">
        {translate(label)}
        :
      </div>
      <div className="ResourceDetailField__Value">{children}</div>
    </div>
  );
}

ResourceDetailField.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  translate: PropTypes.func.isRequired
};

export default withTranslation(ResourceDetailField);
