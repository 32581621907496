import types from './types';

const pushModal = modalKey => ({
  type: types.PUSH_MODAL,
  modalKey
});

const popModal = () => ({
  type: types.POP_MODAL
});

const clearModals = () => ({
  type: types.CLEAR_MODALS
});

const removeModal = modalKey => ({
  type: types.REMOVE_MODAL,
  modalKey
});

const setOffset = offset => ({
  type: types.SET_OFFSET,
  offset
});

const setFakeModalState = fakeModalActive => ({
  type: types.SET_FAKE_MODAL_STATE,
  fakeModalActive
});

export default {
  pushModal,
  popModal,
  clearModals,
  removeModal,
  setOffset,
  setFakeModalState
};
