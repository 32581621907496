import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import SearchTime from './SearchTime';
import { searchSelectors, searchOperations } from '../../../../../state/search';

function SearchFormDateTime(props) {
  return <SearchTime {...props} />;
}

const mapStateToProps = state => ({
  date: searchSelectors.getDate(state),
  time: searchSelectors.getTime(state)
});

const mapDispatchToProps = dispatch => ({
  updateTime: time => dispatch(searchOperations.updateTime(time))
});

SearchFormDateTime.propTypes = {
  date: PropTypes.string.isRequired,
  time: PropTypes.shape({}).isRequired,
  updateTime: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFormDateTime);
