import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isString from 'lodash/fp/isString';
import Icon from '../Icon/Icon';

import './_style.css';

function InputField({ id, label, input, multiline, valid, error }) {
  const classes = classNames('input-field', {
    'input-multiline': multiline
  });

  return (
    <div className={classes} data-init="auto">
      {label && <label htmlFor={id}>{label}</label>}
      {input}

      {error && isString(error) && (
        <div className="message">
          <Icon icon="026-exclamation-mark-circle" />
          {error}
        </div>
      )}

      {valid && !error && <Icon icon="011-check-mark" />}
    </div>
  );
}

InputField.propTypes = {
  input: PropTypes.element.isRequired,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  valid: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  multiline: PropTypes.bool
};

InputField.defaultProps = {
  id: '',
  label: '',
  valid: false,
  error: undefined,
  multiline: false
};

export default InputField;
