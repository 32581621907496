import values from 'lodash/fp/values';
import { createSelector } from 'reselect';

import { getFilterTranslation } from '../../util';

const getNamespace = state => state.equipmentFilterState.namespace;

const isDirty = state => {
  const namespace = getNamespace(state);
  return state.equipmentFilterState.dirty[namespace];
};

const getEquipmentFilters = state => {
  const namespace = getNamespace(state);
  return values(state.equipmentFilterState.equipment[namespace]);
};

const getSelectedEquipmentFilters = createSelector(
  getEquipmentFilters,
  equipment => equipment.filter(item => item.enabled)
);

const getSelectedEquipmentTypeIds = createSelector(
  getSelectedEquipmentFilters,
  equipment => equipment.map(item => item.key)
);

const getEquipmentFilterTags = createSelector(
  getSelectedEquipmentFilters,
  selectedFilters => selectedFilters.map(filter => {
    const translation = getFilterTranslation(filter);
    return {
      key: filter.key,
      type: filter.type,
      resourceType: 'equipment',
      name: filter.name,
      translation
    };
  })
);

export default {
  isDirty,
  getEquipmentFilters,
  getSelectedEquipmentFilters,
  getSelectedEquipmentTypeIds,
  getEquipmentFilterTags
};
