const KEY_ESCAPE = 27;
const KEY_ENTER = 13;
const KEY_SPACE = 32;
const KEY_BACKSPACE = 8;
const KEY_ARROW_LEFT = 37;
const KEY_ARROW_UP = 38;
const KEY_ARROW_RIGHT = 39;
const KEY_ARROW_DOWN = 40;

export const isEscape = keyCode => keyCode === KEY_ESCAPE;
export const isSpace = keyCode => keyCode === KEY_SPACE;
export const isEnter = keyCode => keyCode === KEY_ENTER;
export const isBackspace = keyCode => keyCode === KEY_BACKSPACE;
export const isUpwards = keyCode => keyCode === KEY_ARROW_UP || keyCode === KEY_ARROW_LEFT;
export const isDownwards = keyCode => keyCode === KEY_ARROW_DOWN || keyCode === KEY_ARROW_RIGHT;
