import React, { Component } from 'react';
import cx from 'classnames';

import { Icon, Favorite, CustomPropTypes } from '../../../../../common';
import CalendarRoomTooltip from './CalendarRoomTooltip';

import './_style.css';

class CalendarRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTooltip: false
    };
  }

  activateTooltip = () => {
    const { activeTooltip } = this.state;
    if (!activeTooltip) {
      this.setState({
        activeTooltip: true
      });
    }
  };

  deactivateTooltip = () => {
    const { activeTooltip } = this.state;
    if (activeTooltip) {
      this.setState({
        activeTooltip: false
      });
    }
  };

  render() {
    const { room } = this.props;
    const { activeTooltip } = this.state;
    const { id, displayName, building, capacity, disabled, resourceSubType } = room;

    const tooltipId = 'CalendarRoomTooltip';

    const props = {
      className: cx('CalendarRoom', {
        'CalendarRoom--disabled': disabled
      }),
      onMouseEnter: this.activateTooltip,
      onMouseLeave: this.deactivateTooltip
    };

    return (
      <div style={{ borderLeft: `solid 8px ${ resourceSubType.color }` }}>
        <CalendarRoomTooltip id={tooltipId} room={room} active={activeTooltip} />
        <div data-tip data-for={tooltipId} {...props}>
          <div className="CalendarRoom__Info">
            <span className="CalendarRoom__Name">{displayName}</span>
            <span className="CalendarRoom__Building">{building.key}</span>
          </div>

          <div className="CalendarRoom__Favorite">
            <Favorite small resourceId={id} />
          </div>

          <div className="CalendarRoom__Capacity">
            {capacity != null && capacity > 0 && (
              <>
                <span>{capacity}</span>
                <Icon icon="042-group" className="CalenderRoom__CapacityIcon" />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CalendarRoom.propTypes = {
  room: CustomPropTypes.room
};

export default CalendarRoom;
