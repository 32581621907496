import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import RoomFilterSize from './RoomFilterSize';
import RoomFilterType from './RoomFilterType';
import RoomFilterEquipment from './RoomFilterEquipment';
import RoomFilterFloor from './RoomFilterFloor';
import RoomFilterSector from './RoomFilterSector';
import { withTranslation } from '../common';
import './_style.css';

function RoomFilter({ filter, onChange, variant, translate }) {
  const capacities = filter.filter(item => item.type === 'capacity');
  const equipment = filter.filter(item => item.type === 'equipment');
  const floors = filter.filter(item => item.type === 'floor');
  const sectors = filter.filter(item => item.type === 'sector');
  const roomTypes = filter.filter(item => item.type === 'roomType');

  return (
    <div className={`RoomFilter ${variant}`}>
      <div>
        <div className="RoomFilter__Title">
          {
            `${translate('roomFilter.floorTitle')} / ${translate('roomFilter.sectorTitle')}`
          }
        </div>
        <RoomFilterFloor floors={floors} onChange={onChange} />
        <RoomFilterSector sectors={sectors} onChange={onChange} />
      </div>
      <hr className="RoomFilter__Divider" />
      <RoomFilterSize capacities={capacities} onChange={onChange} />
      <hr className="RoomFilter__Divider" />
      <RoomFilterType roomTypes={roomTypes} onChange={onChange} />
      <hr className="RoomFilter__Divider" />
      <RoomFilterEquipment equipment={equipment} onChange={onChange} />
    </div>
  );
}

RoomFilter.propTypes = {
  filter: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['vertical', 'horizontal', 'mobile']),
  translate: PropTypes.func.isRequired
};

export default
  connect()(withTranslation(RoomFilter));
